import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {VelocityTransitionGroup} from 'velocity-react';

import {connectAppEnv} from '../../app-env';
import {withUILevel} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEMediaCenterCategoryController from './oe-media-center-category-controller';
import OEMediaCenterItemCollectionController from './oe-media-center-item-collection-controller';
import OEMediaCenterControlBarPanel from './oe-media-center-control-bar-panel';
import OESlider from '../../elements/oe-slider';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEAnimationMode, OEAnimationControlAdapterStd, OEAnimationControlAdapterMediaViewer} from '../../animation-control/oe-animation-adapter';
import OEAnimationControl from '../../animation-control/oe-animation-control';
import {OEMediaViewerContentType, OEMediaViewerContentSizeMode, OEMediaViewerContent, OEMediaViewerDataSourceIntrinsic} from '../../media-viewer/oe-media-viewer-model';
import OEMediaViewerController from '../../media-viewer/oe-media-viewer-controller';
import OEMediaCenterMediaViewerDataSource from './oe-media-center-media-viewer-data-source';
import OEPresentationSlideList from '../../controller/presentation/oe-presentation-slide-list';
import {OEModal} from '../../modals/oe-modal';
import OEMediaCenterPlayModeControls from './oe-media-center-play-mode-controls';
import {retardUpdate} from '../../../lib/update-retarder';
import {UIControllerType, OEManualViewLinks} from '../../../lib/oe-types';
import {OEMediaCenterItemTypes, OEMediaCenterMode} from './oe-media-center-model';
import OEWidgetHeader from '../../elements/oe-widget-header';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import OEResizeObserver from '../../../lib/oe-resize-observer';

export class OEMediaCenter extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        // next to fields configuring play mode control btns & presentation slide preview should be considered as constant
        this.isPlayerPreview = true;
        this.isWrap = false;

        this.uiVisible = false;
        this.playMode = false;

        this.blurring = false;
        this.uiBlocking = false;

        this.mediaViewerVisible = false;

        this.centralView = {showAnimationControl: false, noForwarBackwardButtons: false};

        this.bottomBar = {
            hide: false,
            showPresSlidePreview: false,
            showAnimationControl: this.props.config.hideBottomBarControlsForItems.findIndex((type) => type === OEMediaCenterItemTypes.none) >= 0,
            slider: {visible: false, enabled: false, progress: 0}
        };

        this.animationControl = {enabled: false, adapter: null};

        this.categories = [];
        this.items = [];
        this.mediaViewerDataSource = new OEMediaCenterMediaViewerDataSource();
        this.mediaViewerContentIndex = {index: 0, applied: {animated: false, progress: 0}};
        this.mediaViewerAdapter = new OEAnimationControlAdapterMediaViewer();

        this.actualItemId = {item: -1, subItem: -1};
        this.actualItem = null;

        this.progress = 0;

        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};
        this.maxEdgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.itemType = OEMediaCenterItemTypes.none;
        this.presID = -1;
        this.presSlidePreviewSize = {w: 0, h: 0};
        this.bottomBarSize = {w: 0, h: 0};

        this.hasAudio = false;
        this.playEnabled = true;

        this.state = {
            uiVisible: this.uiVisible,
            uiEnabled: false,
            enabled: false,
            playMode: this.playMode,
            blurring: false,
            centralView: clone(this.centralView),
            bottomBar: clone(this.bottomBar),
            animationControl: clone(this.animationControl),
            mediaViewerVisible: this.mediaViewerVisible,
            edgeOffsets: this.edgeOffsets,
            animated: false,
            maxEdgeOffsets: this.maxEdgeOffsets,
            maxAnimated: false,
            itemType: this.itemType,
            presID: this.presID,
            hasAudio: this.hasAudio,
            playEnabled: this.playEnabled,
            showMediaDataSource: null,
            presSlidePreviewSize: this.presSlidePreviewSize
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onWindowResized = this.onWindowResized.bind(this);
        
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onEnabledStateChanged = this.onEnabledStateChanged.bind(this);
        this.onActualMediaCenterItemChanged = this.onActualMediaCenterItemChanged.bind(this);
        this.onActualMediaCenterSubItemChanged = this.onActualMediaCenterSubItemChanged.bind(this);
        this.onMediaCenterAnimationModeChanged = this.onMediaCenterAnimationModeChanged.bind(this);
        this.onMediaCenterProgressChanged = this.onMediaCenterProgressChanged.bind(this);
        this.onMediaCenterItemSliderConfigChanged = this.onMediaCenterItemSliderConfigChanged.bind(this);
        this.onMediaCenterShortCut = this.onMediaCenterShortCut.bind(this);

        this.onRightBarRef = this.onRightBarRef.bind(this);
        //this.onBottomBarRef = this.onBottomBarRef.bind(this);

        this.onBottomBarResize = this.onBottomBarResize.bind(this);
        this.onPresSlidePreviewResize = this.onPresSlidePreviewResize.bind(this);

        this.onMediaViewerRef = this.onMediaViewerRef.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);

        this.onItemCollectionSelected = this.onItemCollectionSelected.bind(this);

        this.onProgressSliderChanged = this.onProgressSliderChanged.bind(this);
        this.onAnimationControlPlay = this.onAnimationControlPlay.bind(this);
        this.onAnimationControlPause = this.onAnimationControlPause.bind(this);
        this.onAnimationControlStop = this.onAnimationControlStop.bind(this);
        this.onPlayModeAnimationControlPlay = this.onPlayModeAnimationControlPlay.bind(this);
        this.onPlayModeAnimationControlPause = this.onPlayModeAnimationControlPause.bind(this);
        this.onPlayModeAnimationControlStop = this.onPlayModeAnimationControlStop.bind(this);
        this.onPlayModeAnimationControlBackward = this.onPlayModeAnimationControlBackward.bind(this);
        this.onPlayModeAnimationControlForward = this.onPlayModeAnimationControlForward.bind(this);

        this.onMediaItemChanged = this.onMediaItemChanged.bind(this);
        this.onMediaViewerProgressChanged = this.onMediaViewerProgressChanged.bind(this);
        this.shouldChangePrev = this.shouldChangePrev.bind(this);
        this.shouldChangeNext = this.shouldChangeNext.bind(this);
        this.onGoToItem = this.onGoToItem.bind(this);
        this.onShowMedia = this.onShowMedia.bind(this);
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
    }

    itemTypeFrom(coreItemType)  {
        if(!this.oe.isReady() || !coreItemType)  return OEMediaCenterItemTypes.none;
        return coreItemType.value;
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    componentDidMount()    {
        this.mounted = true;

        this.updateBlur();
        this.updateEdgeOffsets(false);
        
        window.addEventListener('resize', this.onWindowResized);

        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;

        this.updateBlur();
        this.updateEdgeOffsets(false);

        window.removeEventListener('resize', this.onWindowResized);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }

        if(OEToolbox.jsonEqual(nextProps.config, this.props.config) === false)     {
            if(this.oe.isReady()) {
                this.updateForItem(nextProps);
            }
            this.updateBlur(nextProps);
            this.updateBottomBar(nextProps);
        }
    }

    componentDidUpdate(prevProps) {
        this.updateEdgeOffsets();
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.mediaCenterEnabledStateChanged, this.onEnabledStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterItemChanged, this.onActualMediaCenterItemChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterSubItemChanged, this.onActualMediaCenterSubItemChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.mediaCenterAnimationModeChanged, this.onMediaCenterAnimationModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.mediaCenterProgressChanged, this.onMediaCenterProgressChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.mediaCenterItemSliderConfigChanged, this.onMediaCenterItemSliderConfigChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.mediaCenterShortCut, this.onMediaCenterShortCut);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.mediaCenterEnabledStateChanged, this.onEnabledStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterItemChanged, this.onActualMediaCenterItemChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterSubItemChanged, this.onActualMediaCenterSubItemChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.mediaCenterAnimationModeChanged, this.onMediaCenterAnimationModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.mediaCenterProgressChanged, this.onMediaCenterProgressChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.mediaCenterItemSliderConfigChanged, this.onMediaCenterItemSliderConfigChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.mediaCenterShortCut, this.onMediaCenterShortCut);
    }

    onWindowResized()   {
        this.updateEdgeOffsets(false);
    }

    updateEdgeOffsets(animated, props) {
        animated = typeof(this.animatedOverride) === 'boolean' ? this.animatedOverride : animated;
        props = props || this.props;

        var edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};
        var maxEdgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        //if(this.$bottomBar) maxEdgeOffsets.bottom = this.$bottomBar.outerHeight();
        maxEdgeOffsets.bottom = this.bottomBarSize.h;

        if(this.$rightBar) {
            maxEdgeOffsets.right = this.$rightBar.outerWidth();
        }

        if(this.mounted && this.uiVisible)    {
            //if(this.$bottomBar) edgeOffsets.bottom = !this.bottomBar.hide ? this.$bottomBar.outerHeight() : (this.bottomBar.showPresSlidePreview ? this.presSlidePreviewSize.h : 0);
            edgeOffsets.bottom = !this.bottomBar.hide ? this.bottomBarSize.h : (this.bottomBar.showPresSlidePreview ? this.presSlidePreviewSize.h : 0);

            if(this.$rightBar)  edgeOffsets.right = (props.config.bigPlayBtn && this.playMode) || props.config.mode === OEMediaCenterMode.bottomBar ? 0 : this.$rightBar.outerWidth();
        }

        let newState = {};

        if(!OEToolbox.jsonEqual(edgeOffsets, this.edgeOffsets)) {
            this.edgeOffsets = edgeOffsets;
            newState.edgeOffsets = this.edgeOffsets;
            if(typeof(animated) === 'boolean') newState.animated = animated;
            props.onEdgeOffsetsChanged(edgeOffsets, animated);
        }

        if(!OEToolbox.jsonEqual(maxEdgeOffsets, this.maxEdgeOffsets)) {
            this.maxEdgeOffsets = maxEdgeOffsets;
            newState.maxEdgeOffsets = this.maxEdgeOffsets;
            if(typeof(animated) === 'boolean') newState.maxAnimated = animated;
        }

        if(!OEToolbox.jsonEqual({}, newState))  {
            this.setState(newState);
        }
    }

    shouldBlur(props)   {
        if(!this.mounted || !this.uiVisible)    return false;
        props = props || this.props;
        if(this.mediaViewerVisible) return true;
        if(this.playMode || !this.centralView.showAnimationControl) return false;
        //if(this.animationControl.adapter && this.animationControl.adapter.getAnimationMode() === OEAnimationMode.play) return false;
        return true;
    }

    updateBlur(props, override)   {
        let shouldBlur = typeof(override) === 'boolean' ? override : this.shouldBlur(props);
        if(shouldBlur == this.blurring) return;
        this.blurring = shouldBlur;
        this.onBlurringChanged(props);
    }

    onBlurringChanged(props) {
        props = props || this.props;
        if(props.appComponent && props.appComponent.blurCoreLayer)    props.appComponent.blurCoreLayer.setBlur(this.blurring);
        if(this.oe.isReady() && this.uiBlocking != this.blurring)   {   // track blocking calls since setManUIBlocking is incremental
            this.uiBlocking = this.blurring;
            this.oe.sharedInterface.getUIControllerMediaCenter().setManUIBlocking(this.uiBlocking);
        }
        this.setState({blurring: this.blurring});
        this.updateBottomBar(props);
        this.updateEdgeOffsets(true, props);
    }

    updateBottomBar(props)   {
        props = props || this.props;
        const config = props.config;

        const isPresentation = this.itemType === OEMediaCenterItemTypes.presentation;
        const showPresSlidePreview = isPresentation && (!config.presPreviewOnlyInPlayMode || this.playMode);

        let bottomBar = {
            hide: false,
            showPresSlidePreview: showPresSlidePreview,
            showAnimationControl: config.hideBottomBarControlsForItems.findIndex((type) => type === this.itemType) < 0,
            slider: {visible: false, enabled: false, progress: 0}
        };

        const mediaItem = this.actualItem;

        if(mediaItem)   {
            let sliderConfig = mediaItem.sliderConfig;

            bottomBar.slider = {
                visible: sliderConfig.getVisible() && config.hideSliderForItems.findIndex((type) => type === this.itemType) < 0,
                enabled: sliderConfig.getEnabled(),
                progress: this.progress
            };

            if(this.itemType === OEMediaCenterItemTypes.animation || this.itemType === OEMediaCenterItemTypes.presentation) {
                bottomBar.hide = config.bigPlayBtn && (this.playMode || this.blurring) && (!this.playMode || (isPresentation && !bottomBar.slider.visible));
            }
        }

        if(OEToolbox.shallowEqual(bottomBar, this.bottomBar))   return;

        this.bottomBar = bottomBar;
        this.setState({bottomBar: clone(this.bottomBar)});

        this.onBottomBarChanged(props);
    }

    onBottomBarChanged(props)    {
        this.updateEdgeOffsets(true, props);
    }

    setPlayMode(play)   {
        if(this.playMode === play) return;
        
        this.playMode = play;
        this.setState({playMode: this.playMode});

        this.onPlayModeChanged();
    }

    onPlayModeChanged() {
        this.updateBlur();
        this.updateBottomBar();
        this.updateEdgeOffsets(true);

        if(this.playMode && this.oe.sharedInterface.getUIControllerMediaCenter().getUIVisible() && !this.oe.sharedInterface.getUIControllerPresentation().getUIVisible())    {
            this.props.appComponent.uiLayer.closeAllPopovers();
            if(this.props.config.hideLeftSlideInInPlayMode) {
                this.props.appComponent.uiLayer.showLeftSlideIn(false, true);
            }
        }
    }

    updateLanguage()   {}

    updateUIState()   {
        let controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        this.uiVisible = controller.getUIVisible();
        this.setState({ uiVisible: this.uiVisible, uiEnabled: controller.getUIEnabled() });
        this.updateBlur();
        this.updateBottomBar();
        this.updateEdgeOffsets();
        controller.setEnabled(this.uiVisible);
    }

    updateEnabledState(enabled)    {
        let controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        let uiVisible = controller.getUIVisible();
        if(uiVisible && !enabled)   {
            controller.setEnabled(uiVisible);
            enabled = controller.getEnabled();  // continue with updated value here!
        }
        this.setState({ enabled: typeof(enabled) === 'boolean' ? enabled : controller.getEnabled() });
    }

    updateCategories()  {
        this.categories = this.oe.sharedInterface.getUIControllerMediaCenter().getCategories();
    }

    updateItems()  {
        this.items = this.oe.sharedInterface.getUIControllerMediaCenter().getMediaItemDataList();
    }

    findItem(itemId)  {
        if(!itemId || !itemId.item || itemId.item < 0) return;
        let item = this.items.find((item) => item.id == itemId.item);
        if(!item || !itemId.subItem || itemId.subItem < 0) return item;
        return item.subItems.find((item) => item.id == itemId.subItem);
    }

    updateActualMediaCenterItem()   {
        let controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        this.actualItemId = {item: controller.getActualMediaItemID(), subItem: -1};
        this.updateActualMediaCenterSubItem();
    }

    updateActualMediaCenterSubItem()    {
        let controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        this.actualItemId.subItem = controller.getActualMediaSubItemID();
        this.actualItem = controller.mediaItemID(this.actualItemId.item, this.actualItemId.subItem);
        this.updateForItem();
    }

    updateForItem(props) {
        let props_ = props || this.props;
        let config = props_.config;
        let controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        let mediaItem = this.actualItem;
        let mediaItemId = this.actualItemId;

        if(this.adapter)    {
            this.adapter.unregisterControl(this); this.adapter = null;
        }

        this.itemType = OEMediaCenterItemTypes.none;
        this.presID = -1;
        this.hasAudio = false;
        this.playEnabled = true;
        
        if(!mediaItem)   {
            this.mediaViewerVisible = false;
            this.centralView = {showAnimationControl: false, noForwarBackwardButtons: false};
            this.animationControl = {enabled: false, adapter: null};
            this.setState({
                centralView: clone(this.centralView),
                animationControl: clone(this.animationControl),
                mediaViewerVisible: this.mediaViewerVisible,
                itemType: this.itemType,
                presID: this.presID,
                hasAudio: this.hasAudio,
                playEnabled: this.playEnabled
            });

            this.setPlayMode(false);

        } else {
            this.itemType = this.itemTypeFrom(mediaItem.type);

            this.mediaViewerVisible = false;

            this.centralView = {showAnimationControl: false, noForwarBackwardButtons: false};
            this.animationControl = {enabled: false, adapter: null};
    
            this.progress = controller.getProgress();

            if(this.mediaViewerDataSource.indexForItem(mediaItemId) >= 0)   {
                this.setMediaViewerContent(mediaItemId, this.progress);
                this.mediaViewerVisible = true;
                this.animationControl.enabled = true;
                this.animationControl.adapter = this.mediaViewerAdapter;
            } else if(this.itemType === OEMediaCenterItemTypes.animation) {
                this.centralView.showAnimationControl = config.bigPlayBtn && mediaItem.enableAnimationControls;
                this.centralView.noForwarBackwardButtons = true;
                this.animationControl.enabled = mediaItem.enableAnimationControls;
                this.animationControl.adapter = new OEAnimationControlAdapterStd();
                this.animationControl.adapter.disabledWhenStop = false;
                this.animationControl.adapter.setBinding(this.oe, OEAnimationControlAdapterStd.Binding.animation, mediaItem.index);
            } else if(this.itemType === OEMediaCenterItemTypes.presentation) {
                this.centralView.showAnimationControl = config.bigPlayBtn && mediaItem.sliderConfig.getEnabled(); // no big play button for play mode if not animateable
                this.animationControl.enabled = mediaItem.sliderConfig.getEnabled();
                this.animationControl.adapter = new OEAnimationControlAdapterStd();
                this.animationControl.adapter.disabledWhenStop = false;
                this.animationControl.adapter.setBinding(this.oe, OEAnimationControlAdapterStd.Binding.presentation, mediaItem.index);
                this.presID = mediaItem.index;

                this.hasAudio = this.oe.sharedInterface.getUIControllerPresentation().hasAudioTrackID(this.presID);
                this.playEnabled = this.hasAudio || !config.doNotPlayPresentationWithoutAudio;
            }

            this.adapter = this.animationControl.adapter;
            this.adapter.registerControl(this);

            this.setState({
                centralView: clone(this.centralView),
                animationControl: clone(this.animationControl),
                mediaViewerVisible: this.mediaViewerVisible,
                itemType: this.itemType,
                presID: this.presID,
                hasAudio: this.hasAudio,
                playEnabled: this.playEnabled
            });

            if(this.itemType !== OEMediaCenterItemTypes.presentation && this.itemType !== OEMediaCenterItemTypes.animation && !this.animationControl.adapter)   {
                this.setPlayMode(false);
            }
        }

        this.updateBlur();
        this.updateBottomBar();
        this.updateEdgeOffsets();

        this.oe.sharedInterface.getUIControllerMediaCenter().setPlayEnabled(this.playEnabled);
    }

    updateForProgress(progress) {
        this.progress = progress;
        this.bottomBar.slider.progress = this.progress;
        this.setState({bottomBar: clone(this.bottomBar)});
    }

    updateMediaViewerDataSource(reload)  {
        this.mediaViewerDataSource = new OEMediaCenterMediaViewerDataSource(this.items, this.categories, this.oe);
        if(this.mediaViewer)    this.mediaViewer.setDataSource(this.mediaViewerDataSource, reload);
    }

    setMediaViewerContentIndex(index, progress)   {
        this.mediaViewerContentIndex = {index: index, applied: {animated: false, progress: progress}};
        if(this.mediaViewer)    this.mediaViewer.set(index, false, progress);
    }

    setMediaViewerContent(itemId, progress)   {
        this.setMediaViewerContentIndex(this.mediaViewerDataSource.indexForItem(itemId), progress);
    }

    updateState_(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.uiVisible = false;
            this.playMode = false;
            this.mediaViewerVisible = false;
            this.centralView = {showAnimationControl: false, noForwarBackwardButtons: false};
            this.bottomBar = {
                hide: false,
                showPresSlidePreview: false,
                showAnimationControl: this.props.config.hideBottomBarControlsForItems.findIndex((type) => type === OEMediaCenterItemTypes.none) >= 0,
                slider: {visible: false, enabled: false, progress: 0}
            };
            this.animationControl = {enabled: false, adapter: null};
            this.categories = [];
            this.items = [];
            this.mediaViewerDataSource = new OEMediaCenterMediaViewerDataSource();
            this.actualItemId = {item: -1, subItem: -1};
            this.actualItem = null;
            this.progress = 0;
            this.itemType = OEMediaCenterItemTypes.none;
            this.presID = -1;
            this.hasAudio = false;
            this.playEnabled = true;
            this.setState({
                uiVisible: this.uiVisible,
                uiEnabled: false,
                enabled: false,
                playMode: this.playMode,
                centralView: clone(this.centralView),
                bottomBar: clone(this.bottomBar),
                animationControl: clone(this.animationControl),
                mediaViewerVisible: this.mediaViewerVisible,
                itemType: this.itemType,
                presID: this.presID,
                hasAudio: this.hasAudio,
                playEnabled: this.playEnabled,
                showMediaDataSource: null
            });
            this.updateBlur(this.props, false);
            this.updateEdgeOffsets(true);
            if(this.mediaViewer)    this.mediaViewer.setDataSource(this.mediaViewerDataSource);
            if(this.adapter)    this.adapter.unregisterControl(this); this.adapter = null;
            return;
        }

        this.animatedOverride = false;

        this.updateLanguage();
        this.updateUIState();
        this.updateEnabledState();
        this.updateCategories();
        this.updateItems();
        this.updateMediaViewerDataSource(true);
        this.updateActualMediaCenterItem();

        delete this.animatedOverride;
    }

    updateState(released)   {
        retardUpdate(this, () => {
            this.updateState_(released)
        });
    }

    onAnimationModeChanged(sender, mode)    {   // animationControl.adapter callback 
        this.updateBlur();
    }

    onProgressChanged(sender, progress)    {   // animationControl.adapter callback
    }

    onLanguageChanged() {
        let changes = this.mediaViewerDataSource.updateContentLocalization(this.oe);
        if(this.mediaViewer)    {
            for(let i = 0; i < changes.length; ++i) {
                this.mediaViewer.update(changes[i], false);
            }
        }
        this.updateLanguage();
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.media_center) {
            this.updateUIState();
        }
        if(userInfo.type === this.oe.Module.UIControllerType.media_center || userInfo.type === this.oe.Module.UIControllerType.presentation)  {
            this.onPlayModeChanged();
        }
    }

    onEnabledStateChanged(message, userInfo)   {
        this.updateEnabledState(userInfo.enabled);
    }

    onActualMediaCenterItemChanged(message, userInfo)    {
        this.updateActualMediaCenterItem();
    }

    onActualMediaCenterSubItemChanged(message, userInfo)    {
        this.updateActualMediaCenterSubItem();
    }

    onMediaCenterAnimationModeChanged(message, userInfo)   {
        if(this.itemType !== OEMediaCenterItemTypes.animation && this.itemType !== OEMediaCenterItemTypes.presentation) return;

        if(userInfo.mode === this.oe.Module.AnimationMode.play) {
            this.setPlayMode(true);
        } else if(userInfo.mode === this.oe.Module.AnimationMode.disabled) {
            this.setPlayMode(false);
        }
    }

    onMediaCenterProgressChanged(message, userInfo)  {
        this.updateForProgress(userInfo.progress);

        if(this.mediaViewerDataSource.indexForItem(this.actualItemId) >= 0)   {
            this.mediaViewerContentIndex.applied.progress = this.progress;
            if(this.mediaViewer)    {
                this.mediaViewer.setProgress(this.progress);
            }
        }
    }

    onMediaCenterItemSliderConfigChanged(message, userInfo)  {
        if(this.actualItemId.item === userInfo.item && this.actualItemId.subItem === userInfo.subItem)   {
            this.actualItem.sliderConfig = userInfo.config;
            this.updateForItem();
        }
    }

    onMediaCenterShortCut(message, userInfo) {
        if(userInfo.result > 0 || userInfo.shortcut !== this.oe.Module.MediaCenterShortcutType.toggle_play) return;

        if(this.itemType === OEMediaCenterItemTypes.video)  {
            if(this.adapter)    this.adapter.toggle();
        } else if(this.itemType === OEMediaCenterItemTypes.animation || this.itemType === OEMediaCenterItemTypes.presentation)  {

            this.oe.sharedInterface.getUIControllerMediaCenter().setAnimationMode(this.oe.Module.AnimationMode.pause);
            if(!this.playMode && this.itemType === OEMediaCenterItemTypes.presentation) this.oe.sharedInterface.getUIControllerPresentation().apply(true);
            this.setPlayMode(!this.playMode);
        }

        // note that userInfo.result <= 0 for toggle_play shortcut, i.e., no change was applied by the media center controller due to playEnabled = false
        // but we have reacted here for this shortcut and additionally there are tutor configurations awaiting a shortcut event with result 1 for toggle play
        // so we send a new shortcut event with result 1 signaling that it was reacted to the initial event 
        let userInfo_ = new this.oe.Module.Dictionary();
        this.oe.sharedInterface.postNotification(message, userInfo_.setMCShortcutValue('shortcut', userInfo.shortcut).setIntValue('result', 1));
        userInfo_.delete();
    }

    onMediaViewerRef(ref)   {
        if(this.mediaViewer === ref) return;

        this.mediaViewer = ref;
        if(this.mediaViewer)    {
            this.mediaViewer.setDataSource(this.mediaViewerDataSource);

            let ci = this.mediaViewerContentIndex;
            this.mediaViewer.set(ci.index, ci.applied.animated, ci.applied.progress);
        }

        this.mediaViewerAdapter.setBinding(ref);
    }

    onRightBarRef(ref)  {
        this.$rightBar = $(ref)
    }

    /*
    onBottomBarRef(ref)  {
        this.$bottomBar = $(ref)
    }
    */

    onBottomBarResize(sender, size)  {
        if(OEToolbox.jsonEqual(size, this.bottomBarSize))   return;
        this.bottomBarSize = {w: size.w, h: size.h};
        this.updateEdgeOffsets(true);
    }

    onPresSlidePreviewResize(size)  {
        if(OEToolbox.jsonEqual(size,  this.presSlidePreviewSize))   return;
        this.presSlidePreviewSize = {w: size.w, h: size.h};
        this.setState({presSlidePreviewSize: this.presSlidePreviewSize});
        this.updateEdgeOffsets(true);
    }

    renderBigButtonControl(config)    {
        let enabled = this.state.uiEnabled && this.state.enabled;

        return (
            <VelocityTransitionGroup enter={{animation: 'fadeIn', duration: 333, easing: 'ease-in-out'}} leave={{animation: 'fadeOut', duration: 333, easing: 'ease-in-out'}}>
                {!(this.state.uiVisible && this.state.centralView.showAnimationControl && !this.state.playMode) ? null :
                    <OEAnimationControl
                        className="big-play-btn"
                        enabled={enabled && this.state.animationControl.enabled}
                        slider={{visible: false, enabled: false}}
                        control={{stopBtnVisible: false}}
                        iconCodes={{play: OEIconCodes.play, pause: OEIconCodes.play}}
                        adapter={this.state.animationControl.adapter}
                        onPlayBtnPressed={this.onPlayModeAnimationControlPlay}
                    />
                }
            </VelocityTransitionGroup>
        );
    }

    renderPlayModeControls()    {
        let enabled = this.state.uiEnabled && this.state.enabled;

        return (
            <VelocityTransitionGroup enter={{animation: 'fadeIn', duration: 333, easing: 'ease-in-out'}} leave={{animation: 'fadeOut', duration: 333, easing: 'ease-in-out'}}>
                {!(this.state.uiVisible && this.state.centralView.showAnimationControl && this.state.playMode) ? null : // big play button xor play mode controls
                    <OEMediaCenterPlayModeControls
                        moduleId={this.props.moduleId}
                        enabled={enabled && this.state.animationControl.enabled}
                        isPlayerPreview={this.isPlayerPreview}
                        wrap={this.wrap}
                        adapter={this.state.animationControl.adapter}
                        onPlayBtnPressed={this.onPlayModeAnimationControlPlay}
                        onPauseBtnPressed={this.onPlayModeAnimationControlPause}
                        onStopBtnPressed={this.onPlayModeAnimationControlStop}
                        onBackwardBtnPressed={this.onPlayModeAnimationControlBackward}
                        onForwardBtnPressed={this.onPlayModeAnimationControlForward}
                        playBtnVisible={this.state.playEnabled}
                        doNotDisableStopBtn={true}
                        noForwarBackwardButtons={this.state.centralView.noForwarBackwardButtons}
                    />
                }
            </VelocityTransitionGroup>
        );
    }

    renderBottomBar(config) {
        const enabled = this.state.uiEnabled && this.state.enabled;
        const uiVisible = this.state.uiVisible;
        
        let translateY = 'translateY(100%)';
        if(uiVisible)   {
            if(!this.state.bottomBar.hide)  {
                translateY = 'translateY(0)';
            } else if(this.state.bottomBar.showPresSlidePreview)    {
                const presSlidePreviewHeightStr = this.state.presSlidePreviewSize.h.toString() + 'px';
                translateY = 'translateY(100%) translateY( -' + presSlidePreviewHeightStr + ')';
            }
        }

        let bottomBarStyle = {
            transform: translateY,
            transition: this.state.animated ? 'transform 0.333s ease, right 0.333s ease' : '',
            right: this.state.edgeOffsets.right.toString() + 'px'
        };

        return (<div
            className="bottom-bar light-bg"
            style={bottomBarStyle}
        >
            <OEResizeObserver onResize={this.onBottomBarResize}/>

            {!this.state.bottomBar.showPresSlidePreview ? null :
                <OEPresentationSlideList
                    magnificationClassName="media-center-slide-preview-magni-border"
                    moduleId={this.props.moduleId}
                    enabled={enabled}
                    presID={this.state.presID}
                    isPlayerPreview={this.isPlayerPreview}
                    magnification={3.33}
                    onResize={this.onPresSlidePreviewResize}
                />
            }

            {config.mode !== OEMediaCenterMode.bottomBar ? null :
                <OEMediaCenterItemCollectionController
                    moduleId={this.props.moduleId}
                    config={{
                        usePresItems: false,
                        showDurationInSlidesForPresWithoutAudio: false
                    }}
                    enabled={enabled}
                    onSelected={this.onItemCollectionSelected}
                />
            }

            {!this.state.bottomBar.slider.visible ? null :
                <div className="slider-bar">
                    <OESlider
                        disabled={!(enabled && this.state.bottomBar.slider.enabled)}
                        min={0}
                        max={1}
                        step={0.001}
                        value={this.state.bottomBar.slider.progress}
                        onStart={this.onProgressSliderChanged}
                        onSlide={this.onProgressSliderChanged}
                        onEnd={this.onProgressSliderChanged}
                    />
                </div>
            }

            {!this.state.bottomBar.showAnimationControl ? null :
                <div className="control-bar d-flex justify-content-between flex-wrap">
                    <div className="left">
                        <OEAnimationControl
                            enabled={enabled && this.state.animationControl.enabled}
                            slider={{visible: false, enabled: false}}
                            adapter={this.state.animationControl.adapter}
                            onPlayBtnPressed={this.onAnimationControlPlay}
                            onPauseBtnPressed={this.onAnimationControlPause}
                            onStopBtnPressed={this.onAnimationControlStop}
                        />
                    </div>

                    <div className="flex-grow-1 middle">
                        <OEMediaCenterControlBarPanel moduleId={this.props.moduleId} enabled={enabled} />
                    </div>

                    <div className="right">

                    </div>
                </div>
            }
        
        </div>);
    }

    render() {
        const config = this.props.config;
        const enabled = this.state.uiEnabled && this.state.enabled;
        const animated = this.state.animated;
        const maxAnimated = this.state.maxAnimated;

        return (
            <div 
                className={'media-center ' + this.props.className + ' std-label-text-color'}
                style={{
                    left: this.props.insets.left.toString() + 'px',
                    transition: this.props.animate ? 'left 0.333s ease' : ''
                }}
            >
                
                <div
                    className="right-bar"
                    style={{
                        transform: 'translateX(' + (!this.state.uiVisible || (config.bigPlayBtn && this.state.playMode) || config.mode === OEMediaCenterMode.bottomBar ? '100%' : '0px') + ')',
                        transition: animated ? 'transform 0.333s ease' : ''
                    }}
                    ref={this.onRightBarRef}
                >

                    <OEWidgetHeader
                        className="std-bg popover-control"
                        moduleId={this.props.moduleId}
                        buttonClassName="transparent-btn"
                        uiControllerType={UIControllerType.media_center}
                        onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                    />

                    <div className="body light-bg d-flex flex-column">

                        <OEMediaCenterCategoryController
                            moduleId={this.props.moduleId}
                            enabled={enabled}
                            hideIfNotNeeded={config.hideCategoryBarIfNotNeeded}
                        />
                        <OEMediaCenterItemCollectionController
                            className="flex-grow-1"
                            moduleId={this.props.moduleId}
                            config={{
                                usePresItems: true,
                                showDurationInSlidesForPresWithoutAudio: config.doNotPlayPresentationWithoutAudio
                            }}
                            enabled={enabled}
                            onSelected={this.onItemCollectionSelected}
                        />
                    </div>
                </div>

                <div
                    className="central-view"
                    style={{top: this.state.edgeOffsets.top, right: this.state.edgeOffsets.right, bottom: this.state.edgeOffsets.bottom, left: this.state.edgeOffsets.left,
                            transition: !animated ? '' : 'top 0.333s ease, right 0.333s ease, bottom 0.333s ease, left 0.333s ease'}}
                >

                    <VelocityTransitionGroup enter={{animation: 'fadeIn', duration: 333, easing: 'ease-in-out'}} leave={{animation: 'fadeOut', duration: 333, easing: 'ease-in-out'}}>
                        {!(this.state.uiVisible && this.state.mediaViewerVisible) ? null :
                        <OEMediaViewerController
                            showControlsWhenWindow={false}
                            showNextPrevButtons={false}
                            onMediaItemChanged={this.onMediaItemChanged}
                            onProgressChanged={this.onMediaViewerProgressChanged}
                            shouldChangePrev={this.shouldChangePrev}
                            shouldChangeNext={this.shouldChangeNext}
                            onGoToItem={this.onGoToItem}
                            onShowMedia={this.onShowMedia}
                            ref={this.onMediaViewerRef}
                        />}
                    </VelocityTransitionGroup>

                    <OEModal
                        className="fullscreen-modal"
                        isOpen={Boolean(this.state.uiVisible && this.state.showMediaDataSource)}
                        noHeader={true}
                        hasCloseBtn={false}
                    >
                        <OEMediaViewerController
                            className="show-media-media-viewer"
                            dataSource={this.state.showMediaDataSource}
                            showCloseBtn={true}
                            showControlsWhenWindow={true}
                            controlsContentAlignedWhenWindowed={false}
                            showNextPrevButtons={false}
                            onCloseBtnPressed={this.onCloseBtnPressed}
                        />
                    </OEModal>

                </div>

                <div
                    className="central-view"
                    style={{top: this.state.maxEdgeOffsets.top, right: this.state.maxEdgeOffsets.right, bottom: this.state.maxEdgeOffsets.bottom, left: this.state.maxEdgeOffsets.left,
                            transition: !maxAnimated ? '' : 'top 0.333s ease, right 0.333s ease, bottom 0.333s ease, left 0.333s ease'}}
                >
                    {this.renderBigButtonControl(config)}
                </div>

                <div
                    className="central-view"
                    style={{top: this.state.edgeOffsets.top, right: 0, bottom: this.state.edgeOffsets.bottom, left: this.state.edgeOffsets.left,
                            transition: !animated ? '' : 'top 0.333s ease, right 0.333s ease, bottom 0.333s ease, left 0.333s ease'}}
                >   
                    {this.renderPlayModeControls()}
                </div>

                {this.renderBottomBar(config)}
                
            </div>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.mediaCenter});
    }

    onItemCollectionSelected(index, id, subId)  {
        if(!this.oe.isReady()) return;
        let item = this.findItem({item: id, subItem: subId});
        if(!item) return;
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterItemSelected, {item: id, subItem: subId}, item);
    }

    onProgressSliderChanged(progress)   {
        this.updateForProgress(progress);
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerMediaCenter().setProgress(progress, false, this.actualItemId.item, this.actualItemId.subItem);
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterProgressSliderChanged);
    }

    postNotificationWithItemInfo(name, itemId_, item_, userInfo_)  {
        if(!this.oe.isReady()) return;
        let itemId = itemId_ || this.actualItemId;
        let item = item_ || this.actualItem;
        let userInfo = userInfo_ || new this.oe.Module.Dictionary();
        userInfo.setIntValue('item', itemId.item ? itemId.item : -1);
        userInfo.setIntValue('subItem', itemId.subItem ? itemId.subItem : -1);
        userInfo.setIntValue('type', (item ? item.type : this.oe.Module.MediaCenterItemType.dummy).value);
        userInfo.setIntValue('index', item ? item.index : -1);

        let hasAudio = false;
        if(item && item.type === this.oe.Module.MediaCenterItemType.presentation) {
            hasAudio = this.oe.sharedInterface.getUIControllerPresentation().hasAudioTrackID(item.index);
        }

        userInfo.setIntValue('hasAudio', hasAudio);

        this.oe.sharedInterface.postNotification(name, userInfo);
        userInfo.delete();
    }

    onAnimationControlPlay(sender, adapter)    {
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterPlayBtnPressed);
        if(this.itemType !== OEMediaCenterItemTypes.animation && this.itemType !== OEMediaCenterItemTypes.presentation)   return;
        
        let ret = false;

        if(!this.playEnabled)    {
            if(adapter) adapter.setAnimationMode(OEAnimationMode.pause);
            if(this.itemType === OEMediaCenterItemTypes.presentation)   this.oe.sharedInterface.getUIControllerPresentation().apply(true);
            ret = true;
        }

        this.setPlayMode(true);
        return ret;
    }

    onAnimationControlPause()   {
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterPauseBtnPressed);
    }

    onAnimationControlStop()    {
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterStopBtnPressed);
    }

    onPlayModeAnimationControlPlay(sender, adapter)    {
        return this.onAnimationControlPlay(sender, adapter);
    }

    onPlayModeAnimationControlPause()   {
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterPauseBtnPressed);
    }

    onPlayModeAnimationControlStop(sender, adapter)    {
        if(adapter) adapter.pause();
        this.setPlayMode(false);

        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterStopBtnPressed);
        return true;
    }

    onPlayModeAnimationControlBackward()  {
        if(!this.oe.isReady()) return;
        let controller = this.oe.sharedInterface.getUIControllerPresentation();
        controller.gotoPrevSlide(this.isPlayerPreview, this.wrap);
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterPlayModeBackwardBtnPressed);
    }

    onPlayModeAnimationControlForward()  {
        if(!this.oe.isReady()) return;
        let controller = this.oe.sharedInterface.getUIControllerPresentation();
        controller.gotoNextSlide(this.isPlayerPreview, this.wrap);
        this.postNotificationWithItemInfo(this.oe.NotificationName.uiInstanceMediaCenterPlayModeForwardBtnPressed);
    }

    // media viewer
    onMediaItemChanged(index)   {
        this.mediaViewerContentIndex.index = index;
        if(!this.oe.isReady()) return;
        let item = this.mediaViewerDataSource.mediaViewerContentForIndex(index);
        if(!item) return;
        this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItemSubItemID(item.itemId.item, item.itemId.subItem);
    }

    onMediaViewerProgressChanged(progress) {
        if(!(this.state.mediaViewerVisible && this.state.uiVisible)) return;
        this.mediaViewerContentIndex.applied.progress = progress;
        this.updateForProgress(progress);
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerMediaCenter().setProgress(progress, true, this.actualItemId.item, this.actualItemId.subItem);
    }

    shouldChangePrev(index) {
        let item = this.mediaViewerDataSource.mediaViewerContentForIndex(index);
        return !item ? false : !item.blockPrev;
    }

    shouldChangeNext(index) {
        let item = this.mediaViewerDataSource.mediaViewerContentForIndex(index);
        return !item ? false : !item.blockNext;
    }

    onGoToItem(name)    {
        this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItem(name);
    }

    onShowMedia(path)   {
        let resourcePath = 'app/oe/' + this.oe.Module.filePackagePathURL;

        let content = new OEMediaViewerContent(
            OEMediaViewerContentType.image,
            resourcePath + path,
            false,
            null,
            null,
            {mode: OEMediaViewerContentSizeMode.fill}
        ); 

        this.setState({showMediaDataSource: new OEMediaViewerDataSourceIntrinsic(content)});
    }

    onCloseBtnPressed() {
        this.setState({showMediaDataSource: null});
    }
}

OEMediaCenter.defaultProps = {
    className: '',
    moduleId: '',
    config: OEDefaultConfigFactory.mediaCenter(),
    insets: {left: 0},
    animate: false,
    onEdgeOffsetsChanged: () => {}
};

OEMediaCenter.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        showHelpBtn: PropTypes.bool,
        mode: PropTypes.oneOf([OEMediaCenterMode.std, OEMediaCenterMode.bottomBar]),
        hideBottomBarControlsForItems: PropTypes.array,
        hideSliderForItems: PropTypes.array,
        bigPlayBtn: PropTypes.bool,
        hideCategoryBarIfNotNeeded: PropTypes.bool,
        doNotPlayPresentationWithoutAudio: PropTypes.bool,
        presPreviewOnlyInPlayMode: PropTypes.bool,
        hideLeftSlideInInPlayMode: PropTypes.bool
    }).isRequired,
    insets: PropTypes.object,
    animate: PropTypes.bool,
    onEdgeOffsetsChanged: PropTypes.func
};

export default connectAppEnv((env) => { 
    const ui = env.config.module.uiLayerConfig;
    const ret = {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.mediaCenterConfig, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.mediaCenter)
    };
    return ret;
})(withUILevel(OEMediaCenter));