import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-bootstrap-toggle';

import {connectModuleEnv} from '../oe-module-env';

export class OEToggleUnconnected extends React.PureComponent {
    render() {
        return (
            <Toggle
                className={'toggle-switch ' + this.props.className}
                onClick={this.props.onClick}
                on={this.props.strings.on}
                off={this.props.strings.off}
                size="small"
                disabled={this.props.disabled}
                active={this.props.active}
            />
        );
    }
}

OEToggleUnconnected.defaultProps = {
    className: '',
    strings: {
        on: 'On',
        off: 'Off'
    },
    disabled: false,
    active: true
};

OEToggleUnconnected.propTypes = {
    className: PropTypes.string,
    strings: PropTypes.shape({
        on: PropTypes.string,
        off: PropTypes.string
    }),
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func
};

export const OEToggle = connectModuleEnv((env) => {
    return {
        strings: env.strings.toggle
    };
})(OEToggleUnconnected);

export default OEToggle;