import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from './app-env';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEToolbox} from '../lib/oe-toolbox';
import OEInfoViewController from './info-view/oe-info-view-controller';

export class OELicensingManager extends React.PureComponent {

    constructor(props) {
        super(props);

        this.props.appComponent.licensingManager = this;

        this.restored = false;

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.onLicensingRestore = this.onLicensingRestore.bind(this);
        this.onLicensingProductActivationChanged = this.onLicensingProductActivationChanged.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingRestore, this.onLicensingRestore);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingRestore, this.onLicensingRestore);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.validate(null, null, this.oe.Module.LicenseValidationType.release_session, false, function(result)   {
            }, false);
            return;
        }

        let controller = this.oe.sharedInterface.getUIControllerLicensingManager();
        let result = controller.getRestoreResult();

        if(result.getIsSet())    {
            if(this.restored === false)  {
                this.onLicensingRestore();
            } else {
                if(controller.isValid())    {   // we do not want automatic reactivation of manually deactivated licenses at start up
                    this.validate(null, null, this.oe.Module.LicenseValidationType.std, false);
                }
            }
        }
    }

    addModal(title, msg, buttonFunc, options)  {
        if(this.props.appComponent.module)    this.props.appComponent.module.addModal(title, msg, buttonFunc, options);
    }

    validateBroadcast(key, validationType, errorAlert, callback)  {
        if(!this.oe.isReady()) return;
        let si = this.oe.sharedInterface;
        let controller = si.getUIControllerLicensingManager();

        this.props.appComponent.showWaitingController();
        
        let showErrorAlert = typeof(errorAlert) === 'boolean' ? errorAlert : true;

        let completion = function(result) {
            this.props.appComponent.hideWaitingController();

            //
            if(!this.oe.isReady()) {
                if(typeof(callback) === 'function') callback(result);
                return;
            }

            //
            if(result.getError() === this.oe.Module.LicensingValidationRequestResultError.no_error || !showErrorAlert)  {
                if(typeof(callback) === 'function') callback(result);
                return;
            }

            //
            let title = si.getLocalizedStringEnc('licensing_alert_validation_failed_title');
            let message = OEToolbox.decode_utf8(result.getLocalizedInfo());
            let buttons = (id, container) => [{title: si.getLocalizedStringEnc('alert_ok'), onClick: () => {container.closeModal(id)}, className: 'btn-primary'}];
            this.addModal(title, message, buttons);

            if(typeof(callback) === 'function') callback(result);

        }.bind(this);

        if(typeof(key) === 'string') {
            controller.validateBroadcastWithKey(key, validationType, completion, true);
        } else {
            controller.validateBroadcast(validationType, completion, true);
        }
    }

    validate(product, key, validationType, errorAlert, callback, async)  {
        if(!this.oe.isReady()) return;
        let si = this.oe.sharedInterface;
        let controller = si.getUIControllerLicensingManager();

        this.props.appComponent.showWaitingController();

        let showErrorAlert = typeof(errorAlert) === 'boolean' ? errorAlert : true;
        let async_ = typeof(async) === 'boolean' ? async : true;

        let completion = function(result) {
            this.props.appComponent.hideWaitingController();

            //
            if(!this.oe.isReady()) {
                if(typeof(callback) === 'function') callback(result);
                return;
            }

            //
            if(result.getError() === this.oe.Module.LicensingValidationRequestResultError.no_error || !showErrorAlert)  {
                if(typeof(callback) === 'function') callback(result);
                return;
            }

            //
            let title = si.getLocalizedStringEnc('licensing_alert_validation_failed_title');
            let message = OEToolbox.decode_utf8(result.getLocalizedInfo());
            let buttons = (id, container) => [{title: si.getLocalizedStringEnc('alert_ok'), onClick: () => {container.closeModal(id)}, className: 'btn-primary'}];
            this.addModal(title, message, buttons);

            if(typeof(callback) === 'function') callback(result);

        }.bind(this);

        /*if(!async_) {
            let promise = new Promise(resolve => {

                let completion_ = function(result)    {
                    resolve();
                    completion(result);
                }

                if(typeof(product) === 'string' && typeof(key) === 'string') {
                    controller.validateProductWithKey(product, key, validationType, completion_, true);
                } else if(typeof(product) !== 'string' && typeof(key) !== 'string') {
                    controller.validate(validationType, completion_, true);
                } else if(typeof(product) === 'string') {
                    controller.validateProduct(product, validationType, completion_, true);
                } else if(typeof(key) === 'string') {
                    controller.validateWithKey(key, validationType, completion_, true);
                }
            });

            await promise;
        
            return;
        }*/

        if(typeof(product) === 'string' && typeof(key) === 'string') {
            controller.validateProductWithKey(product, key, validationType, completion, async_);
        } else if(typeof(product) !== 'string' && typeof(key) !== 'string') {
            controller.validate(validationType, completion, async_);
        } else if(typeof(product) === 'string') {
            controller.validateProduct(product, validationType, completion, async_);
        } else if(typeof(key) === 'string') {
            controller.validateWithKey(key, validationType, completion, async_);
        }
    }

    onLicensingRestore()    {
        if(this.restored)   return;
        let appComponent = this.props.appComponent;
        let si = this.oe.sharedInterface;
        let controller = si.getUIControllerLicensingManager();
        let result = controller.getRestoreResult();

        this.restored = true;
        
        if(result.getError() !== this.oe.Module.LicensingRestoreResultError.no_error && typeof(appComponent.module) === 'object')   {
            let title = si.getLocalizedStringEnc('licensing_alert_restore_failed_title');
            let message = OEToolbox.decode_utf8(result.getLocalizedInfo());

            let buttons = (id, container) => [
                {title: si.getLocalizedStringEnc('alert_ok'), onClick: () => {container.closeModal(id)}, className: 'btn-primary'},
                {title: si.getLocalizedStringEnc('licensing_alert_manage'), onClick: () => {
                    container.closeModal(id); 

                    if(typeof(appComponent.uiLayer.infoView) === 'object')   {
                        appComponent.uiLayer.infoView.openItem(OEInfoViewController.itemTypes.licensing);
                    }
                    
                }, className: 'btn-primary'}];

            appComponent.module.addModal(title, message, buttons);
        }

        let key = OEToolbox.getUrlParameter('license');
        if(key) {
            this.validateBroadcast(key, this.oe.Module.LicenseValidationType.std, true, function(result) {
                if(result.getError() !== this.oe.Module.LicensingValidationRequestResultError.no_error) return;
                let id = result.getLicense().getProductId();
                let activeProductId = controller.getProductId();
                if(id === activeProductId)   return;
                this.validate(id, null, this.oe.Module.LicenseValidationType.release_session, false, null);
            }.bind(this));
        } else if(controller.isValid())    {   // we do not want automatic reactivation of manually deactivated licenses at start up
            this.validate(null, null, this.oe.Module.LicenseValidationType.std, false);
        }
    }

    onLicensingProductActivationChanged(message, userInfo)    {
        let si = this.oe.sharedInterface;
        let appComponent = this.props.appComponent;
        let isActiveProduct = productId === si.getUIControllerLicensingManager().getProductId();
        if(!isActiveProduct || typeof(appComponent.module) !== 'object') return;

        let productId = userInfo.productId;
        let isValid = userInfo.isValid;
        let changeReason = userInfo.changeReason;

        let strBtnOk = si.getLocalizedStringEnc('alert_ok');
        let strBtnManage = si.getLocalizedStringEnc('licensing_alert_manage');

        if(isValid){
            /*
            let title = si.getLocalizedStringEnc('licensing_activated');
            let message = si.getLocalizedStringEnc('licensing_message_activated');
            let buttons = (id, container) => [{title: strBtnOk, onClick: () => {container.closeModal(id)}, className: 'btn-primary'}];
            appComponent.module.addModal(title, message, buttons);
            */
        } else {
            if(changeReason === this.oe.Module.LicenseActivationChangeReason.deactivated)    {
                /*
                let title = si.getLocalizedStringEnc('licensing_deactivated');
                let message = si.getLocalizedStringEnc('licensing_message_deactivated');
                let buttons = (id, container) => [{title: strBtnOk, onClick: () => {container.closeModal(id)}, className: 'btn-primary'}];
                appComponent.module.addModal(title, message, buttons);
                */
            } else if(changeReason === this.oe.Module.LicenseActivationChangeReason.sessionExpired)    {
                let title = si.getLocalizedStringEnc('licensing_deactivated');

                let message = si.getLocalizedStringEnc('licensing_message_session_expired');
                let buttons = (id, container) => [
                    {title: strBtnOk, onClick: () => {container.closeModal(id)}, className: 'btn-primary'},
                    {title: strBtnManage, onClick: () => {
                        container.closeModal(id); 
    
                        if(typeof(appComponent.uiLayer.infoView) === 'object')   {
                            appComponent.uiLayer.infoView.openItem(OEInfoViewController.itemTypes.licensing);
                        }
                        
                    }, className: 'btn-primary'}];

                appComponent.module.addModal(title, message, buttons);

            } else {
                let title = si.getLocalizedStringEnc('licensing_deactivated');
                let message = si.getLocalizedStringEnc('licensing_message_license_expired');

                let buttons = (id, container) => [
                    {title: strBtnOk, onClick: () => {container.closeModal(id)}, className: 'btn-primary'},
                    {title: strBtnManage, onClick: () => {
                        container.closeModal(id); 
    
                        if(typeof(appComponent.uiLayer.infoView) === 'object')   {
                            appComponent.uiLayer.infoView.openItem(OEInfoViewController.itemTypes.licensing);
                        }
                        
                    }, className: 'btn-primary'}];

                appComponent.module.addModal(title, message, buttons);
            }
        }
    }

    render() {
        return (
            <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
        );
    }
}

OELicensingManager.defaultProps = {
    moduleId: 'oe-embed'
};

OELicensingManager.propTypes = {
    moduleId: PropTypes.string
};

export default connectAppEnv((env) => { 
    return {
        appComponent: env.component
    };
})(OELicensingManager);