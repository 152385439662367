import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';

import {withIsOpenState} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEIcon from '../../elements/oe-icon';
import OEButton from '../../elements/oe-button';
import OEScrollbars from '../../oe-scrollbars';
import OEPopover from '../../oe-popover';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEAssetSelectionType} from './oe-asset-selection-model';
import {OEAssetSelectionCell} from './oe-asset-selection-cells';

export class OEAssetSelectionController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.animations = [];
        this.presentations = [];
        this.selection = [];

        this.state = {
            animations: clone(this.animations),
            presentations: clone(this.presentations),
            hasSelection: false
        };

        this.onPresentationAdded = this.onPresentationAdded.bind(this);
        this.onPresentationRemoved = this.onPresentationRemoved.bind(this);

        this.onCellClick = this.onCellClick.bind(this);
        this.onOkBtnPressed = this.onOkBtnPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.allowsMultipleSelection !== this.props.allowsMultipleSelection && !nextProps.allowsMultipleSelection && this.selection.length > 1)  {
            this.setSelection(this.selection[0]);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationRemoved, this.onPresentationRemoved);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationRemoved, this.onPresentationRemoved);
    }

    getIndex(item)  {
        if(!item)   return -1;

        let indexOffset = 0;
        if(this.props.types.includes(OEAssetSelectionType.animation))   {
            if(item.type === OEAssetSelectionType.animation)    {
                let index = this.animations.findIndex(item_ => item_.ids.first === item.ids.first && item_.ids.second === item.ids.second);
                if(index >= 0)  return indexOffset + index;
            }
            indexOffset += this.animations.length;
        }

        if(this.props.types.includes(OEAssetSelectionType.presentation))   {
            if(item.type === OEAssetSelectionType.presentation)    {
                let index = this.presentations.findIndex(item_ => item_.ids.first === item.ids.first && item_.ids.second === item.ids.second);
                if(index >= 0)  return indexOffset + index;
            }
            indexOffset += this.presentations.length;
        }

        return -1;
    }

    getItem(index)  {
        if(index < 0)   return undefined;

        if(this.props.types.includes(OEAssetSelectionType.animation))   {
            if(index < this.animations.length)  return {type: OEAssetSelectionType.animation, ids: clone(this.animations[index].ids)};
            index -= this.animations.length;
        }

        if(this.props.types.includes(OEAssetSelectionType.presentation))   {
            if(index < this.presentations.length)  return {type: OEAssetSelectionType.presentation, ids: clone(this.presentations[index].ids)};
            index -= this.presentations.length;
        }
    }

    getSortedSelection()    {
        let selection = this.selection.map(item => {
            return {
                type: item.type,
                ids: clone(item.ids),
                index: this.getIndex(item)
            };
        });

        selection = selection.sort((l, r) => l.index - r.index);

        // filter out duplicates & items with invalid index -> this should not be necessary but we do it nevertheless for the sake of a forgiving coding paradigm
        selection = selection.filter((item, i) => item.index >= 0 && (!i || item.index != selection[i - 1].index));

        return selection;
    }

    hasItem(item)   {
        if(!item || typeof(item) !== 'object' || typeof(item.type) !== 'string' || typeof(item.ids) !== 'object' || ![OEAssetSelectionType.animation, OEAssetSelectionType.presentation].includes(item.type))   return false;
        let searchList = item.type === OEAssetSelectionType.animation ? this.animations : this.presentations;
        return searchList.findIndex(searchItem => searchItem.ids.first === item.ids.first && searchItem.ids.second === item.ids.second) >= 0;
    }

    setSelection(selection) {
        let newSelection = [];

        if(selection)  {
            if(Array.isArray(selection))    {
                newSelection = selection.filter(selItem => this.hasItem(selItem)).map(selItem => clone(selItem));
            } else if(this.hasItem(selection))  {
                newSelection.push(clone(selection));
            }
        }

        if(!this.props.allowsMultipleSelection && newSelection.length > 1)  newSelection = [newSelection[0]];

        if(OEToolbox.shallowEqual(newSelection, this.selection, true))  return;
        this.selection = newSelection;
        this.onSelectionChanged();
    }

    addToSelection(items)   {
        let itemsToAdd = [];

        if(items)  {
            if(Array.isArray(items))    {
                itemsToAdd = items.filter(selItem => this.hasItem(selItem) && !this.isSelected(selItem)).map(selItem => clone(selItem));
            } else if(this.hasItem(items) && !this.isSelected(items))  {
                itemsToAdd.push(clone(items));
            }
        }

        if(!itemsToAdd.length)    return;

        let newSelection = this.props.allowsMultipleSelection ? this.selection.concat(itemsToAdd) : [itemsToAdd[0]];

        if(OEToolbox.shallowEqual(newSelection, this.selection, true))  return;
        this.selection = newSelection;
        this.onSelectionChanged();
    }

    removeFromSelection(items)  {
        let itemsToRemove = [];

        if(items)  {
            if(Array.isArray(items))    {
                itemsToRemove = items;
            } else if(typeof(items) === 'object')  {
                itemsToRemove.push(items);
            }
        }

        if(!itemsToRemove.length)    return;

        let newSelection = this.selection.filter((selItem) => itemsToRemove.findIndex(item => selItem.type === item.type && selItem.ids.first === item.ids.first && selItem.ids.second === item.ids.second) < 0);
        if(OEToolbox.shallowEqual(newSelection, this.selection, true))  return;
        this.selection = newSelection;
        this.onSelectionChanged();
    }

    isSelected(item) {
        return this.selection.findIndex(selItem => selItem.type === item.type && selItem.ids.first === item.ids.first && selItem.ids.second === item.ids.second) >= 0;
    }

    outputSelection()   {
        let selection = this.getSortedSelection();
        return selection.length === 0 ? null : (selection.length === 1 ? selection[0] : selection);
    }

    onSelectionChanged()    {
        //console.log('onSelectionChanged - ' + this.selection.length);
        this.updateSelection();
    }

    updateAnimations()  {
        if(!this.oe.isReady())  return;

        let animationDataList = this.oe.sharedInterface.getUIControllerAnimation().getAnimations();

        this.animations = animationDataList.map((anim, index) => {
            let item = {
                type: OEAssetSelectionType.animation,
                ids: {first: index, second: -1},
                thumbImagePath: anim.previewImagePath,
                name: anim.label
            };
            item.selected = this.isSelected(item);
            return item;
        });

        this.setState({animations: clone(this.animations)});

        this.selection = this.selection.filter(selItem => this.hasItem(selItem));
    }

    updatePresentations()   {
        if(!this.oe.isReady())  return;

        let presentationDataList = this.oe.sharedInterface.getUIControllerPresentation().getPresentationDataList();

        this.presentations = presentationDataList.map((pres) => {
            let item = {
                type: OEAssetSelectionType.presentation,
                ids: {first: pres.id, second: -1}
            };
            item.selected = this.isSelected(item);
            return item;
        });

        this.setState({presentations: clone(this.presentations)});

        this.selection = this.selection.filter(selItem => this.hasItem(selItem));
    }

    updateSelection()   {
        this.animations.forEach((item) => {
            item.selected = this.isSelected(item);
            return item;
        });

        this.presentations.forEach((item) => {
            item.selected = this.isSelected(item);
            return item;
        });

        this.setState({
            animations: clone(this.animations),
            presentations: clone(this.presentations),
            hasSelection: this.selection.length > 0
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.animations = [];
            this.presentations = [];
            this.setState({
                animations: clone(this.animations),
                presentations: clone(this.presentations)
            });
            return;
        }

        retardUpdate(this, () => {
            this.updateAnimations();
            this.updatePresentations();
        });
    }

    onPresentationAdded()   {
        this.updatePresentations();
    }

    onPresentationRemoved() {
        this.updatePresentations();
    }

    renderCellFn(indexOffset)    {
        return (item, index) => 
            <OEAssetSelectionCell
                key={indexOffset + index}
                moduleId={this.props.moduleId}
                index={index}
                {...item}
                onClick={this.onCellClick}
            />;
    }

    render()    {
        let animationCells = this.props.types.includes(OEAssetSelectionType.animation) ? this.state.animations.map(this.renderCellFn(0)) : null;
        let presentationCells = this.props.types.includes(OEAssetSelectionType.presentation) ? this.state.presentations.map(this.renderCellFn(animationCells.length)) : null;

        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="asset-selection-controller">
                    <div className="container">
                        <OEScrollbars>
                            {animationCells}
                            {presentationCells}
                        </OEScrollbars>
                    </div>
                    <div className="group-control-separator std-separator-border-color"/>
                    <div className="bottom-bar">
                        <OEButton
                            className="transparent-btn ok-btn"
                            onPressed={this.onOkBtnPressed}
                            disabled={!this.state.hasSelection}
                        >
                            <OEIcon code={OEIconCodes.assetSelection.ok}/>
                        </OEButton>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onCellClick(type, ids, index, e)   {
        let item = {type: type, ids: ids};

        if(!this.props.allowsMultipleSelection || (!e.ctrlKey && !e.shiftKey) || (!e.ctrlKey && e.shiftKey && this.selection.length === 0)) {
            this.setSelection(item);
            this.pivotItem = item;
        } else if(e.ctrlKey)   {
            if(this.isSelected(item))   {
                this.removeFromSelection(item);
            } else {
                this.addToSelection(item);
                this.pivotItem = item;
            }
        } else if(e.shiftKey)   {
            let sortedSelection = this.getSortedSelection();
            if(!sortedSelection.length) return;

            let pivotIndex = this.getIndex(this.pivotItem);
            if(pivotIndex < 0 || !this.isSelected(this.pivotItem))  {
                pivotIndex = sortedSelection[0].index;
                this.pivotItem = this.getItem(pivotIndex);
            }

            let minMax = {
                min: pivotIndex,
                max: index
            };

            if(minMax.min > minMax.max) {
                let i = minMax.min;
                minMax.min = minMax.max; minMax.max = i;
            }

            let selection = [];
            for(let i = minMax.min; i <= minMax.max; ++i)   {
                let item = this.getItem(i);
                if(!item)   continue;
                selection.push(item);
            }

            this.setSelection(selection);
        }
    }

    onOkBtnPressed()   {
        if(this.props.onOkBtnPressed)   this.props.onOkBtnPressed(this.outputSelection());
    }
}

OEAssetSelectionController.defaultProps = {
    moduleId: '',
    types: [OEAssetSelectionType.animation, OEAssetSelectionType.presentation],
    allowsMultipleSelection: false
};

OEAssetSelectionController.propTypes = {
    moduleId: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.oneOf([OEAssetSelectionType.dummy, OEAssetSelectionType.animation, OEAssetSelectionType.presentation])),
    allowsMultipleSelection: PropTypes.bool,
    onOkBtnPressed: PropTypes.func
};

export class OEAssetSelectionPopover extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                title: 'Attributes'
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()    {
        this.setState({strings: {
            title: this.oe.sharedInterface.getLocalizedStringEnc('asset_selection_view')
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    render() {
        return  (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover
                    className="popover-control"
                    moduleId={this.props.moduleId}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    placement={this.props.placement}
                    hideArrow={this.props.hideArrow}
                    noHeader={this.props.noHeader}
                    buttonClassName="transparent-btn"
                    headerSeparator={this.props.headerSeparator}
                    isOpen={this.props.isOpen}
                    onToggle={this.props.onToggle}
                    title={this.state.strings.title}
                    backdrop={this.props.backdrop}
                >
                    <OEAssetSelectionController
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        types={this.props.types}
                        allowsMultipleSelection={this.props.allowsMultipleSelection}
                        onOkBtnPressed={this.props.onOkBtnPressed}
                    />
                </OEPopover>
            </React.Fragment>
        );
    }
}

OEAssetSelectionPopover.defaultProps = {
    moduleId: '',
    placement: 'right',
    hideArrow: false,
    noHeader: false,
    backdrop: false,
    headerSeparator: true,
    types: [OEAssetSelectionType.animation, OEAssetSelectionType.presentation],
    allowsMultipleSelection: false
};

OEAssetSelectionPopover.propTypes = {
    moduleId: PropTypes.string,
    hideArrow: PropTypes.bool,
    noHeader: PropTypes.bool,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    backdrop: PropTypes.bool,
    headerSeparator: PropTypes.bool,
    types: PropTypes.arrayOf(PropTypes.oneOf([OEAssetSelectionType.dummy, OEAssetSelectionType.animation, OEAssetSelectionType.presentation])),
    allowsMultipleSelection: PropTypes.bool,
    onOkBtnPressed: PropTypes.func
};

export default withIsOpenState(OEAssetSelectionPopover);