import React from 'react';
import PropTypes from 'prop-types';

import { Card, Button, CardImg, CardTitle, CardColumns, CardSubtitle, CardBody } from 'reactstrap';

import {slInterface} from '../../react-oe/sl-interface';
import {OEProductId} from '../../react-oe/oe-product-id';
import OEIcon from '../elements/oe-icon';
import {OEIconCodes} from '../../lib/oe-icon-codes';

export default class OELicensingProductCollection extends React.PureComponent {

    imageForModuleType(moduleType)  {
        let lmt = slInterface.Module.LicenseModuleType;
        let image = 'demo';

        switch(moduleType)  {
            case lmt.basic: image = 'basic'; break;
            case lmt.extended: image = 'extended'; break;
            case lmt.professional: image = 'professional'; break;
            case lmt.master: image = 'professional'; break;
        }

        return 'images/licensing-app/item_' + image + '.png';
    }

    imageForEdition(edition)  {
        let image = 'demo';

        if(edition !== '')  {
            image = 'professional';
        }

        return 'images/licensing-app/item_' + image + '.png';
    }

    imageForProduct(productId)  {
        let image = 'logo';

        switch(productId)  {
            case OEProductId.humanEye: image = 'logo_eye'; break;
            case OEProductId.humanEye2: image = 'logo_eye'; break;
            case OEProductId.humanSkull: image = 'logo_skull'; break;
            case OEProductId.humanSkin: image = 'logo_skin'; break;
            case OEProductId.humanKnee: image = 'logo_knee'; break;
            case OEProductId.humanEar: image = 'logo_ear'; break;
            case OEProductId.equineHoof: image = 'logo_hoof'; break;
            case OEProductId.equineHead: image = 'logo'; break;
            case OEProductId.mouseBrain: image = 'logo'; break;
            case OEProductId.combustionEngine: image = 'logo'; break;
            case OEProductId.snail: image = 'logo_snail'; break;
            case OEProductId.toxNetz: image = 'logo'; break;
            case OEProductId.zeissOpti: image = 'logo_eye'; break;
            case OEProductId.zeissUV: image = 'logo_eye'; break;
            case OEProductId.sensorySystems: image = 'logo'; break;
            case OEProductId.humanFoot: image = 'logo_foot'; break;
            case OEProductId.animalCell: image = 'logo_animalcell'; break;
// @ADD_TARGET_BEGIN@
//            case OEProductId.$TARGET_cC$: image = 'logo'; break;
// @ADD_TARGET_END@
        }

        return 'images/logo/' + image + '.png';
    }

    render() {
        const items = this.props.products ? this.props.products.map((product, index) =>
            <Card key={index} className="bg-light">

                {product.edition === '' ? <CardImg top width="100%" src={this.imageForModuleType(product.moduleType)} /> : <CardImg top width="100%" src={this.imageForEdition(product.edition)} />}
                {product.edition === '' ? <CardSubtitle>{product.moduleType !== slInterface.Module.LicenseModuleType.none ? product.moduleName : this.props.strings.demo}</CardSubtitle> : <CardSubtitle>{product.edition}</CardSubtitle>}
                
                <CardImg src={this.imageForProduct(product.id)} />
                <CardBody className="text-center">
                    <CardTitle>{product.name}</CardTitle>
                    <Button className="text-center btn-block" size="sm" onClick={() => this.onPlayBtn(index, product.id)}>
                        <OEIcon code={OEIconCodes.licensingPlay}/>{this.props.strings.start}
                    </Button>
                    {product.moduleType !== slInterface.Module.LicenseModuleType.none ? null : 
                        <Button className="text-center btn-block" size="sm" onClick={() => this.onBuyBtn(index, product.id)}>
                            {<OEIcon code={product.isInShop ? OEIconCodes.licensingBuy : OEIconCodes.licensingEnvelope}/>}
                            {product.isInShop ? this.props.strings.buy : this.props.strings.contactUs}
                        </Button>
                    }
                </CardBody>
            </Card>
        ) : null;

        return (
            <CardColumns>
                {items}
            </CardColumns>
        );
    }

    onPlayBtn(index, id) {
        if(this.props.onPlayBtnPressed)  this.props.onPlayBtnPressed(index, id);
    }

    onBuyBtn(index, id)  {
        if(this.props.onBuyBtnPressed)  this.props.onBuyBtnPressed(index, id);
    }
}

OELicensingProductCollection.defaultProps = {
    strings: {
        start: 'Start',
        buy: 'Buy license',
        demo: 'Demo',
        contactUs: 'Contact us'
    },
};

OELicensingProductCollection.propTypes = {
    strings: PropTypes.shape({
        start: PropTypes.string,
        buy: PropTypes.string,
        demo: PropTypes.string,
        contactUs: PropTypes.string
    }).isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        isInShop: PropTypes.bool,
        moduleType: PropTypes.object,
        name: PropTypes.string,
        moduleName: PropTypes.string,
        edition: PropTypes.string,
        expDate: PropTypes.string
    })),
    onPlayBtnPressed: PropTypes.func,
    onBuyBtnPressed: PropTypes.func
};