import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';

import {withIsOpenState} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEIconButton} from '../elements/oe-button';
import OEScrollbars from '../oe-scrollbars';
import OEPopover from '../oe-popover';
import OEOpenStateElementContainer from '../../lib/oe-open-state-element-container';
import {retardUpdate} from '../../lib/update-retarder';
import {OEToolbox} from '../../lib/oe-toolbox';
import {oeUniqueIdGen} from '../../lib/oe-unique-id-gen';

export class OENumberPickerCell extends React.PureComponent   {

    constructor(props)  {
        super(props);
        this.onPressed = this.onPressed.bind(this);
    }

    componentWillUnmount()  {
        if(this.props.elementRef)   this.props.elementRef({id: this.props.row}, true);
    }

    render()    {
        return (
            <div
                id={this.props.row}
                className={'number-picker-cell' + (this.props.disabled ? ' disabled' : '') + (this.props.selected ? ' active' : '')}
                onClick={this.onPressed}
                ref={this.props.elementRef}
            >
                <span>{this.props.value.toString()}</span>
            </div>
        );
    }

    onPressed()    {
        if(this.props.disabled) return;
        if(this.props.onPressed)   this.props.onPressed(this);
    }
}

OENumberPickerCell.defaultProps = {
    disabled: false,
    selected: false,
    row: 0,
    value: 0
};

OENumberPickerCell.propTypes = {
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    row: PropTypes.number,
    value: PropTypes.number,
    onPressed: PropTypes.func
};

export class OENumberPickerComponentController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.cellElementRefs = {};

        this.selected = this.getSelected();

        this.state = {
            rows: this.createRows(),
            selected: this.selected
        };

        this.onScrollbarRef = this.onScrollbarRef.bind(this);
        this.onCellElementRef = this.onCellElementRef.bind(this);

        this.onPressed = this.onPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.min !== this.props.min || nextProps.max !== this.props.max || nextProps.inc !== this.props.inc || nextProps.precision !== this.props.precision)   {
            this.updateRows(nextProps);
        }
        
        if(nextProps.min !== this.props.min || nextProps.max !== this.props.max || nextProps.inc !== this.props.inc || nextProps.value !== this.props.value) {
            this.updateSelected(nextProps);
        }
    }

    componentDidUpdate(prevProps, prevState)    {
        if(prevProps.min !== this.props.min || prevProps.max !== this.props.max || prevProps.inc !== this.props.inc)    {
            this.scrollTo();
        }
    }

    componentDidMount()    {
        this.mounted = true;

        if(this.deferedScroll)  {
            this.scrollTo(this.deferedScroll.row, this.deferedScroll.completed);
        } else {
            this.scrollTo();
        }
    }

    roundToPrec(value, precision)   {
        let dec = 1;
        for(let i = 0; i < precision; ++i)  dec *= 10;
        return Math.round(value * dec) / dec;
    }

    valueForIntValue(val, props)   {
        props = props || this.props;
        return this.roundToPrec(val * props.inc + props.min, props.precision);
    }

    valueToIntValue(val, props) {
        props = props || this.props;
        return Math.floor((val - props.min) / props.inc + 1e-6);
    }

    createRows(props) {
        props = props || this.props;
        let rowCount = Math.round((props.max - props.min) / props.inc) + 1;
        let rows = [];

        for(let i = 0; i < rowCount; ++i)   {
            rows.push({value: this.valueForIntValue(i, props)});
        }
        return rows;
    }

    updateRows(props)   {
        this.setState({rows: this.createRows(props)});
    }

    getSelected(props) {
        props = props || this.props;
        if(typeof(props.value) !== 'number')    return 0;
        let rowCount = Math.round((props.max - props.min) / props.inc) + 1;
        return Math.min(Math.max(this.valueToIntValue(props.value, props), 0), rowCount - 1);
    }

    updateSelected(props)   {
        let lastSelected = this.selected ;
        this.selected = this.getSelected(props);
        this.setState({selected: this.selected});
        if(this.selected  !== lastSelected) this.scrollTo();
    }

    scrollTo(row, completed)   {
        if(!this.mounted)   {
            this.deferedScroll = {row: row, completed: completed};
            return;
        }
        if(typeof(row) === 'undefined') row = this.selected;
        if(!this.scrollbarRef || !this.cellElementRefs[row]) return;

        let node = this.cellElementRefs[row];
        let element = node;
        let offsetTop = 0;
        while(element && element != this.scrollbarRef.container)    {
            offsetTop += element.offsetTop;
            element = element.parentElement;
        }

        let itemCenter = offsetTop + 0.5 * node.offsetHeight;
        let scrollViewCenter = 0.5 * this.scrollbarRef.getClientHeight();
        let scrollTop = itemCenter - scrollViewCenter;
        this.scrollbarRef.scrollTop(scrollTop, completed);
    }

    onScrollbarRef(ref) {
        this.scrollbarRef = ref;
    }

    onCellElementRef(ref, release)   {
        if(!ref)    return;
        if(!release)    {
            this.cellElementRefs[ref.id] = ref;
        } else {
            delete this.cellElementRefs[ref.id];
        }
    }

    render()    {
        let cells = this.state.rows.map((row, i) =>
            <OENumberPickerCell
                key={i}
                disabled={this.props.disabled}
                selected={this.state.selected === i}
                row={i}
                value={row.value}
                onPressed={this.onPressed}
                elementRef={this.onCellElementRef}
            />
        );

        return (
            <div className={'number-picker-component'}>
                {this.props.label ? <span className="label">{this.props.label}</span> : null}
                <div className="cell-container">
                    <OEScrollbars ref={this.onScrollbarRef}>
                        {cells}
                    </OEScrollbars>
                </div>
            </div>
        );
    }

    onPressed(cell)    {
        if(this.props.disabled) return;
        if(this.props.onPressed)   this.props.onPressed(this, cell.props.row, this.valueForIntValue(cell.props.row));
    }
}

OENumberPickerComponentController.defaultProps = {
    disabled: false,
    min: 0,
    max: 255,
    inc: 1,
    precision: 0
};

OENumberPickerComponentController.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    inc: PropTypes.number,
    precision: PropTypes.number,
    value: PropTypes.number,
    onPressed: PropTypes.func
};

export class OENumberPickerController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.values = this.sanitizedValues(this.props.values ? this.props.values : this.props.value);

        this.state = {
            values: clone(this.values),
            labels: this.getLabels()
        };

        this.onComponentCellPressed = this.onComponentCellPressed.bind(this);
    }

    setValues(values)   {
        if(typeof(values) === 'number' && !Array.isArray(values))  return;
        this.values = this.sanitizedValues(values);
        this.setState({values: clone(this.values)});
    }

    sanitizedValues(values_, props) {
        let values;
        if(Array.isArray(values_))    {
            values = values_.map(v => typeof(v) === 'number' ? v : 0);
        } else if(typeof(values_) === 'number')  {
            values = [values_];
        } else {
            values = [];
        }

        props = props || this.props;
        if(props.components.length > values.length) {
            for(let i = 0; i < props.components.length - values.length; ++i)    values.push(props.components[i + values.length].minValue);
        }
        return values;
    }

    updateValues(values, props) {
        this.values = this.sanitizedValues(values, props);
        this.setState({values: clone(this.values)});
    }

    setValue(value) {
        this.setValues(value);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.value !== this.props.value || !OEToolbox.shallowEqual(nextProps.values, this.props.values))   {
            this.updateValues(nextProps.values ? nextProps.values : nextProps.value, nextProps);
        } else if(!OEToolbox.shallowEqual(nextProps.components, this.props.values))   {
            this.updateValues(this.values, nextProps);
            this.updateLabels(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    getLabels(props) {
        props = props || this.props;
        return props.components.map(component => {
            return !component.labelId || !this.oe.isReady() ? component.label : this.oe.sharedInterface.getLocalizedStringEnc(component.labelId)
        });
    }

    updateLabels(props) {
        this.setState({labels: this.getLabels(props)});
    }

    updateLanguage()    {
        this.updateLabels();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    render()    {
        let components = this.props.components.map((component, i) => 
            <OENumberPickerComponentController
                key={i}
                component={i}
                disabled={this.props.disabled}
                label={this.state.labels[i]}
                min={component.minValue}
                max={component.maxValue}
                inc={component.inc}
                precision={component.precision}
                value={this.state.values[i]}
                onPressed={this.onComponentCellPressed}
            />
        );

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'number-picker ' + this.props.className}>
                    {components}
                </div>
            </React.Fragment>
        );
    }

    onComponentCellPressed(component, row, value)    {
        if(this.props.disabled) return;
        
        this.onChange(component.props.component, row, value);
    }

    onChange(component, row, value)  {
        if(this.values[component] === this.value)  return;
        let values = clone(this.values);
        values[component] = value;

        if(this.props.onChange) {
            this.props.onChange(values);
        } else {
            this.setValues(values);
        }
    }
}

OENumberPickerController.defaultProps = {
    moduleId: '',
    className: '',
    disabled: false,
    components: [{
        minValue: 0,
        maxValue: 255,
        inc: 1,
        precision: 0
    }],
    values:[0]
};

OENumberPickerController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    components: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        labelId: PropTypes.string,
        minValue: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        inc: PropTypes.number.isRequired,
        precision: PropTypes.number.isRequired
    })).isRequired,
    values: PropTypes.arrayOf(PropTypes.number),
    value: PropTypes.number,
    onChange: PropTypes.func
};

export const OENumberPickerPopover = OEPopover.coat(OENumberPickerController, {}, {
    placement: 'right',
    noHeader: true,
    buttonClassName: 'transparent-btn',
    backdrop: true,
    backdropOptions: {color: 'rgba(0, 0, 0, 0)', animated: false, duration: 0.333},
    controllerClassName: '',
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OENumberPickerPopover);

const numberPickerButtonContainer = new OEOpenStateElementContainer();

export class OENumberPickerButton extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.uid = oeUniqueIdGen.get();

        this.isOpen = false;

        this.values = this.props.values ? this.props.values : (typeof(this.props.value) === 'number' ? [this.props.value] : undefined);

        this.state = {
            isOpen: this.isOpen,
            values: clone(this.values)
        };

        this.onPressed = this.onPressed.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    setOpen(isOpen)   {
        if(this.isOpen == isOpen || (this.props.disabled && isOpen))   return;
        this.isOpen = isOpen;
        this.setState({isOpen: this.isOpen});

        if(this.props.closeOther && this.isOpen)    numberPickerButtonContainer.closeAll(this);

        if(this.isOpen && this.props.onShow)    this.props.onShow(this.values);
        if(!this.isOpen && this.props.onHide)    this.props.onHide(this.values);
    }

    setValues(values)   {
        if((!Array.isArray(values) && typeof(values) !== 'number')) return;
        let values_ = Array.isArray(values) ? values : [values];
        if(OEToolbox.shallowEqual(this.values, values_))  return;
        this.values = values_;
        this.setState({values: clone(this.values)});
    }

    componentWillUnmount()    {
        numberPickerButtonContainer.unregister(this);
    }

    componentDidMount()    {
        numberPickerButtonContainer.register(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.disabled)     this.setOpen(false);

        if(nextProps.value !== this.props.value || !OEToolbox.shallowEqual(nextProps.values, this.props.values))   {
            this.setValues(nextProps.values ? nextProps.values : nextProps.value);
        }
    }

    render()    {
        const {className, popoverClassName, values, value, onChange, onShow, onHide, ...rest} = this.props;

        const targetId = 'number-picker-button-target-id-' + this.uid;

        let picker =
            <OENumberPickerPopover
                className={popoverClassName}
                target={this.props.target ? this.props.target : targetId}
                {...rest}
                isOpen={this.state.isOpen}
                onToggle={this.onToggle}
                values={this.state.values}
                onChange={this.onChange}
            />;

        return (
            <React.Fragment>
                <OEIconButton
                    id={targetId}
                    className={'number-picker-btn ' + this.props.className}
                    disabled={this.props.disabled}
                    activated={this.props.highlightButton && this.state.isOpen}
                    icon={this.props.icon}
                    onPressed={this.onPressed}
                />
                {picker}
            </React.Fragment>
        );
    }

    onPressed() {
        if(this.props.disabled)     return;
        this.setOpen(!this.isOpen);
    }

    onToggle()  {
        this.setOpen(false);
    }

    onChange(values)   {
        if(OEToolbox.shallowEqual(values, this.values))  return;

        if(this.props.onChange) {
            this.props.onChange(values);
        } else {
            this.setValues(values);
        }
    }
}

OENumberPickerButton.defaultProps = Object.assign({}, OENumberPickerButton.defaultProps, {
    popoverClassName: '',
    icon: '\uF162',
    closeOther: true,
    highlightButton: true
});

OENumberPickerButton.propTypes = Object.assign({}, OENumberPickerButton.defaultProps, {
    popoverClassName: PropTypes.string,
    icon: PropTypes.string,
    closeOther: PropTypes.bool,
    highlightButton: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
});