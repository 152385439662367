import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {withIsOpenState, frefFromRef, frefToRef} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OENotification from '../../lib/oe-notification';
import {OEViewType} from '../../lib/oe-types';
import OEPopover from '../oe-popover';
import {OEIconButton} from '../elements/oe-button';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OETextView from './oe-text-view';
import { OEToolbox } from '../../lib/oe-toolbox';

export class OETextViewController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.setControllerToAdapter();

        this.src = this.props.adapter ? this.props.adapter.getSrc() : null;

        this.isPresentable_ = !!this.src;

        this.contentSize = {w: 0, h: 0};

        this.state = {
            src: this.src
        };

        this.onTextViewRef = this.onTextViewRef.bind(this)

        this.onContentSizeChanged = this.onContentSizeChanged.bind(this);
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
    }

    isPresentable() {
        return this.isPresentable_;
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.config.adapter !== nextProps.config.adapter) {
            this.releaseControllerFromAdapter();
            this.releaseAdapter();
            this.setControllerToAdapter(nextProps);
            this.connectAdapter(nextProps);
            this.updateSrc(nextProps);
        }
    }

    setControllerToAdapter(props)   {
        props = props || this.props;
        let adapter = props.config.adapter;
        if(!adapter)    return;
        adapter.controller = this;
    }

    releaseControllerFromAdapter(props)   {
        props = props || this.props;
        let adapter = props.config.adapter;
        if(!adapter)    return;
        adapter.controller = null;
    }

    connectAdapter(props)    {
        if(!this.oe.isReady()) return;
        props = props || this.props;
        let adapter = props.config.adapter;
        if(!adapter)    return;

        if(adapter._connectedModuleId)  {
            adapter.onRelease(this.oe, this);
        }
        
        adapter.oe = this.oe;
        adapter._connectedModuleId = this.oe.moduleId;
        adapter.onConnect(this.oe, this);
    }

    releaseAdapter(props)   {
        props = props || this.props;
        let adapter = props.config.adapter;
        if(!adapter || !adapter._connectedModuleId)  return;
        adapter.onRelease(this.oe, this);
        adapter._connectedModuleId = undefined;
    }

    onConnect()  {
        this.connectAdapter();
    }

    onRelease()    {
        this.releaseAdapter();
    }

    setSrc(src) {
        if(this.src === src)    return;
        this.src = src;
        this.setState({src: this.src});
        this.updateIsPresentable();
    }

    onTextViewSrcChanged(sender, src)   {
        this.setSrc(src);
    }

    updateSrc(props)    {
        props = props || this.props;
        this.setSrc(props.adapter ? props.adapter.getSrc() : null);
    }

    updateIsPresentable()   {
        if(this.isPresentable_ === !!this.src)  return;
        this.isPresentable_ = !!this.src;

        if(this.props.appComponent) {
            this.props.appComponent.uiLayer.notificationCenter.post(OENotification.viewPresentableStateChanged, {
                type: OEViewType.text,
                isPresentable: this.isPresentable_,
                sender: this
            });
        }
    }

    updateStyle()   {
        let style = {height: this.contentSize.h + 8};
        this.setState({style: style});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
    }

    onTextViewRef(ref)  {
        if(this.textViewRef === ref)    return;
        this.textViewRef = ref;
        if(!ref)    return;
        if(OEToolbox.shallowEqual(this.contentSize, ref.getContentSize()))   return;
        this.contentSize = ref.getContentSize();
        this.updateStyle();
    }

    render() {
        if(!this.props.config.enabled) return null;
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control" 
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    isOpen={this.props.isOpen}
                    noHeader={true}
                >
                    <div className="text-view-controller">

                        <OETextView
                            style={this.state.style}
                            src={this.state.src}
                            ref={this.onTextViewRef}
                            onContentSizeChanged={this.onContentSizeChanged}
                        />

                        <OEIconButton
                            className={'close-btn'}
                            icon={OEIconCodes.textViewClose}
                            onPressed={this.onCloseBtnPressed}
                        />

                    </div>
                </OEPopover>
            </React.Fragment>
        );
    }

    onContentSizeChanged(sender, contentSize)  {
        if(OEToolbox.shallowEqual(this.contentSize, contentSize))   return;
        this.contentSize = contentSize;
        this.updateStyle();
    }

    onCloseBtnPressed()   {
        if(this.props.onToggle) this.props.onToggle();
    }
}

OETextViewController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.textViewController()
};

OETextViewController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        adapter: PropTypes.object
    })
};

export default frefFromRef(connectAppEnv((env) => {
    return {
        appComponent: env.component,
        config: env.config.module.uiLayerConfig.widgetConfig.textViewController
    }
})(frefToRef(withIsOpenState(OETextViewController, (sender, isOpen) => {
    let appComponent = sender.props.appComponent;
    if(!appComponent || !appComponent.uiLayer) return;
    appComponent.uiLayer.notificationCenter.post(OENotification.viewOpenStateChanged, {
        type: OEViewType.text,
        isOpen: isOpen,
        sender: sender
    });
}, (sender, ref) => {
    let appComponent = sender.props.appComponent;
    if(!appComponent || !appComponent.uiLayer) return;
    appComponent.uiLayer.notificationCenter.post(OENotification.viewPresentableStateChanged, {
        type: OEViewType.text,
        isPresentable: sender.isPresentable(),
        sender: sender
    });
}))));