import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInfoViewItemView from './oe-info-view-item-view';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {retardUpdate} from '../../lib/update-retarder';
import {OETransitionGroup} from '../../lib/oe-transitions';

export default class OEInfoViewItemLicensing extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                title: 'Licensing',
                expDate: 'Expiration Date',
                keyInputPlaceholder: 'License key'
            },
            activeProductId: '',
            newKey: '',
            licenses: []
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onLicensingRestore = this.onLicensingRestore.bind(this);
        this.onLicensingProductActivationChanged = this.onLicensingProductActivationChanged.bind(this);
        this.onLicensingLicenseRemoved = this.onLicensingLicenseRemoved.bind(this);

        this.onLicenseValidate = this.onLicenseValidate.bind(this);
        this.onLicenseRelease = this.onLicenseRelease.bind(this);
        this.onLicenseRemove = this.onLicenseRemove.bind(this);
        this.onNewKeyChanged = this.onNewKeyChanged.bind(this);
        this.onNewValidate = this.onNewValidate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingRestore, this.onLicensingRestore);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingLicenseRemoved, this.onLicensingLicenseRemoved);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingRestore, this.onLicensingRestore);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingLicenseRemoved, this.onLicensingLicenseRemoved);
    }

    updateLanguage()   {
        this.setState({ strings: {
            title: this.oe.sharedInterface.getLocalizedStringEnc('info_view_licensing_label'),
            expDate: this.oe.sharedInterface.getLocalizedStringEnc('info_view_licensing_expiration_date'),
            keyInputPlaceholder: this.oe.sharedInterface.getLocalizedStringEnc('info_view_licensing_key_field_placeholder')
        }}); 

        this.updateForLicensing();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            var controller = this.oe.sharedInterface.getUIControllerLicensingManager();
            this.setState({activeProductId: controller.getProductId()});
            
            this.updateLanguage();
        });
    }

    onLicensingRestore(message, userInfo)    {
        this.updateForLicensing();
    }

    onLicensingProductActivationChanged(message, userInfo)    {
        this.updateForLicensing();
    }

    onLicensingLicenseRemoved(message, userInfo)    {
        this.updateForLicensing();
    }

    updateForLicensing()    {
        let controller = this.oe.sharedInterface.getUIControllerLicensingManager();
        let licenses = controller.getLicenses();
        let stateLicenses = [];
        let activeProductId = controller.getProductId();

        for (let i = 0; i < licenses.size(); i++) {
            let license = licenses.get(i);

            let expDate = OEToolbox.stdTMToData(license.getExpirationDate()).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'});

            let newEntry = {id: license.getProductId(), key: license.getKey(), isValid: license.getIsValid(), name: controller.getProductName(license.getProductId()), moduleName: controller.moduleName(license.getModuleType()), edition: license.getEdition(), expDate: expDate};

            if(license.getProductId() === activeProductId)   {
                stateLicenses.unshift(newEntry);
            } else {
                stateLicenses.push(newEntry);
            }
        }
    
        this.setState({licenses: stateLicenses})
    }

    render() {
        let licenseItems = null;
        const activeProductId = this.state.activeProductId;

        if(this.oe.isReady())  {

            licenseItems = this.state.licenses.map((license, index) => {
                const isActiveProduct = license.id === activeProductId;
                const showRemoveBtn = !isActiveProduct || !license.isValid;
                
                return (
                    <div className="licensing-entry" key={index}>
                    
                        <div className="licensing-entry-left">
                            <div className="licensing-product-badge">
                                <span className="licensing-product-badge-name">{OEToolbox.decode_utf8(license.name)}</span>
                                {license.edition === '' ? 
                                    (license.moduleName === '' ? null : <span className="licensing-product-badge-module-name">&nbsp;{'- ' + OEToolbox.decode_utf8(license.moduleName)}</span>) :
                                    <span className="licensing-product-badge-edition">&nbsp;{'- ' + OEToolbox.decode_utf8(license.edition)}</span>
                                }
                                <br/><span className="licensing-product-badge-expiration-date">{this.state.strings.expDate + ': ' + license.expDate}</span>
                            </div>
                        </div>

                        <div className={'licensing-entry-right' + (!showRemoveBtn ? ' single' : '')}>
                            <OEButton
                                className="btn themecolor"
                                disabled={!isActiveProduct}
                                onPressed={license.isValid ? this.onLicenseRelease : this.onLicenseValidate}
                                license={license}
                            >
                                <OEIcon code={isActiveProduct && license.isValid ? OEIconCodes.licensingInfoDeactivate : OEIconCodes.licensingInfoRefresh} />
                            </OEButton>
                        </div>

                        {!showRemoveBtn ? null : 
                            <div className="licensing-entry-right">
                                <OEButton
                                    className="btn themecolor"
                                    onPressed={this.onLicenseRemove}
                                    license={license}
                                >
                                    <OEIcon code={OEIconCodes.licensingInfoRemove} />
                                </OEButton>
                            </div>
                        }

                    </div>
                );
            });
        }

        return (
            <OEInfoViewItemView
                moduleId={this.props.moduleId}
                elementRef={this.props.elementRef}
                isMaximizeable={true}
                notMinimizeable={this.props.notMinimizeable}
                isMaximized={this.props.isMaximized}
                hasSeparator={this.props.hasSeparator}
                className="ivi-licensing"
                title={this.state.strings.title}
                onToggle={this.props.onToggle}
                onAnimFinished={this.props.onAnimFinished}
            >
                <div className="licensing-container">
                    <OETransitionGroup duration={333}>
                        {licenseItems}
                    </OETransitionGroup>
                    <div className="licensing-entry" key={licenseItems.length}>
                
                        <div className="licensing-entry-left">
                            <input 
                                type="text"
                                value={this.state.newKey} 
                                placeholder={this.state.strings.keyInputPlaceholder} 
                                onChange={this.onNewKeyChanged} 
                            />
                        </div>

                        <div className="licensing-entry-right single">
                            <OEButton
                                className="btn themecolor"
                                disabled={this.state.newKey === ''}
                                onPressed={this.onNewValidate}
                            >
                                <OEIcon code={OEIconCodes.licensingInfoAdd} />
                            </OEButton>
                        </div>

                    </div>
                </div>

            </OEInfoViewItemView>
        );
    }

    onLicenseValidate(e, sender) {
        const license = sender.props.license;
        if(license.id !== this.state.activeProductId)  return;
        this.props.appComponent.licensingManager.validate(license.id, null, this.oe.Module.LicenseValidationType.std, true);
    }

    onLicenseRelease(e, sender)   {
        const license = sender.props.license;
        if(license.id !== this.state.activeProductId)  return;
        this.props.appComponent.licensingManager.validate(license.id, null, this.oe.Module.LicenseValidationType.release, true);
    }

    onLicenseRemove(e, sender)   {
        const license = sender.props.license;
        this.props.appComponent.licensingManager.validate(license.id, null, this.oe.Module.LicenseValidationType.remove, true);
    }

    onNewKeyChanged(event) {
        this.setState({newKey: event.target.value.toUpperCase()});
    }

    onNewValidate() {
        const key = this.state.newKey.trim();
        this.setState({newKey: key});

        this.props.appComponent.licensingManager.validateBroadcast(key, this.oe.Module.LicenseValidationType.std, true, function(result) {
            if(result.getError() !== this.oe.Module.LicensingValidationRequestResultError.no_error) return;
            this.setState({newKey: ''});

            let id = result.getLicense().getProductId();
            if(id === this.state.activeProductId)   return;
            this.props.appComponent.licensingManager.validate(id, null, this.oe.Module.LicenseValidationType.release_session, false, null);
        }.bind(this));
    }
}

OEInfoViewItemLicensing.defaultProps = {
    moduleId: '',
    elementRef: '',
    isMaximized: false,
    notMinimizeable: false,
    hasSeparator: true
};

OEInfoViewItemLicensing.propTypes = {
    moduleId: PropTypes.string,
    isMaximized: PropTypes.bool,
    notMinimizeable: PropTypes.bool,
    hasSeparator: PropTypes.bool
};