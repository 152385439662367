import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEColor from './color-picker/oe-color';
import {OEColorHelper} from './oe-color-helper';
import {retardUpdate} from '../lib/update-retarder';

export default class OEBGLoadingDisplay extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.show = false;
        this.progress = 100.0;
        this.timer = null;

        this.state = {
            show: this.show,
            progress: this.progress,
            strings:   {
                message: ''
            },
            tick: 0,
            textColor: OEColorHelper.viewPortLabelTextColor()
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onFetchProgress = this.onFetchProgress.bind(this);
        this.onBgColorChanged = this.onBgColorChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
        this.stopTimer();
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.fetchProgress, this.onFetchProgress);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.vignetteStrengthChanged, this.onBgColorChanged);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.fetchProgress, this.onFetchProgress);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.vignetteStrengthChanged, this.onBgColorChanged);
    }

    tick() {
        this.setState((prevState, props) => {
            var newState = clone(prevState);
            newState.tick = (prevState.tick + 1) % 4; 
            return newState;
        });
    }

    startTimer() {
        if(this.timer) return;
        this.timer = setInterval(this.tick.bind(this), 330);
    }

    stopTimer() {
        if(!this.timer) return;
        clearInterval(this.timer);
        this.timer = null;
    }

    updateLanguage()   {
        this.setState({strings: {
            message: this.oe.sharedInterface.getLocalizedStringEnc('texture_loading_display')
        }});
    }

    onFetchProgress(message, userInfo)   {
        const progress = userInfo.progress;
        const show = progress >= 0 && progress < 100; // progress of -1 is signalling a problem
        if(progress === this.progress && show === this.show) return;
        this.show = show;
        this.progress = progress;
    
        retardUpdate(this, () => {
            if(show) {
                this.startTimer();
            } else {
                this.setState({tick: 0});
                this.stopTimer();
            }

            this.setState({
                show: this.show,
                progress: this.progress,
            });
        });
    }

    onBgColorChanged()  {
        var brightness = this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundBorderBrightness();

        this.setState({
            textColor: OEColorHelper.viewPortLabelTextColor(brightness)
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.show = false;
            this.setState({ show: this.show });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.onBgColorChanged();
        });
    }

    render() {
        var show = this.props.show && this.state.show;
        if(!show)   return null;

        var style = {color: OEColor.toDOMStr(this.state.textColor)};

        var tickString = '';
        for(var i = 0; i < this.state.tick; ++i) tickString = tickString + '. ';

        var fillerTickString = '';
        for(var i = this.state.tick; i < 4; ++i) fillerTickString = fillerTickString + '. ';

        return (
            <div className = {'bg-loading-display ' + this.props.className} style={style}>
                <span>{this.state.strings.message + ' ' + tickString}</span><span className='opacity-0'>{fillerTickString}</span>
                <div className = 'bg-loading-display-progress-bar'>
                    <div className = 'bg-loading-display-progress-bar-inner' style={{width: String(this.state.progress)+'%', backgroundColor: style.color}}></div>
                </div>
            </div>
        );
    }
}

OEBGLoadingDisplay.defaultProps = {
    className: '',
    moduleId: '',
    show: true
};

OEBGLoadingDisplay.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    show: PropTypes.bool
};