import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OENotification from '../../lib/oe-notification';
import OEStdModalController from '../modals/oe-std-modal-controller';
import {OEViewType} from '../../lib/oe-types';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEScrollbars from '../oe-scrollbars';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import OEInfoViewItemHeader from './oe-info-view-item-header';
import OEInfoViewItemLicensing from './oe-info-view-item-licensing';
import OEInfoViewItemTutorials from './oe-info-view-item-tutorials';
import OEInfoViewItemMedia from './oe-info-view-item-media';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEInfoViewController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_,
            strings: {
            },
            items: {
                header: {isMaximized: false},
                tutorials: {isMaximized: false},
                licensing: {isMaximized: false},
                media: {isMaximized: false},
            }
        };

        this.items = clone(this.state.items);
        this.itemRefs = {};
        this.completions = {};

        this.onOpenedClosedCompletion = null;

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.updateLanguage = this.updateLanguage.bind(this);

        this.onRef = this.onRef.bind(this);
        this.onScrollbarRef = this.onScrollbarRef.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onItemToggle = this.onItemToggle.bind(this);
        this.onItemAnimFinished = this.onItemAnimFinished.bind(this);

        this.onOpened = this.onOpened.bind(this);
        this.onClosed = this.onClosed.bind(this);

        const itemTypes = OEInfoViewController.itemTypes;
        Object.keys(itemTypes).forEach(key => {
            const type = itemTypes[key];
            const typeC = OEToolbox.capitalize(type);
            this['onRef' + typeC] = ((ref) => { this.onRef(type, ref); }).bind(this);
            this['onItemToggle' + typeC] = (() => { this.onItemToggle(type); }).bind(this);
            this['onItemAnimFinished' + typeC] = (() => { this.onItemAnimFinished(type); }).bind(this);
        });
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();

        this.executeDeferredScrolling();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
        this.itemRefs = {};
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);      
        
        //setTimeout(this.onTimeout.bind(this), 500);
    }

    onTimeout() {
        if(this.oe.isReady())   this.setOpen(true);
    }

    setOpen(open, completion)   {
        if(!this.oe.isReady()) return;
        if(this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.info) === open)  return;

        if(open === this.oe.sharedInterface.setUIControllerVisible(open, this.oe.Module.UIControllerType.info)) {
            if(typeof(completion) === 'function')   this.onOpenedClosedCompletion = completion;
        }
    }

    isOpen()    {
        return this.isOpen_;
    }

    onOpenStateChanged(sender, isOpen)  {
        if(this.props.onOpenStateChanged)  this.props.onOpenStateChanged(sender, isOpen);
        if(!this.props.appComponent || !this.props.appComponent.uiLayer) return;
        this.props.appComponent.uiLayer.notificationCenter.post(OENotification.viewOpenStateChanged, {
            type: OEViewType.info,
            isOpen: isOpen,
            sender: sender
        });
    }

    scrollTo(item)    {
        if(!this.scrollbar || !this.itemRefs[item]) {
            //console.log("Scrolling deferred");
            this.scrollToItem = item;
            return;
        }

        let itemElement = this.itemRefs[item];

        //console.log("Scrolling");

        let itemCenter = itemElement.offsetTop + 0.5 * itemElement.offsetHeight;
        let scrollViewCenter = 0.5*this.scrollbar.getClientHeight();
        let scrollTop = itemCenter - scrollViewCenter;
        this.scrollbar.scrollTop(scrollTop);

        //console.log("Scrolling offsetTop: " + itemElement.offsetTop.toString() + ", offsetHeight: " + itemElement.offsetHeight.toString() + ", itemCenter: " + itemCenter.toString() + ", ScrollViewCenter: " + scrollViewCenter.toString());
        //console.log("Scrolling to " + scrollTop.toString());
    }

    executeDeferredScrolling(item)  {
        if(typeof(this.scrollToItem) !== 'string')  return;

        if(typeof(item) === 'undefined' || this.scrollToItem === item)  {
            let item_ = this.scrollToItem;
            this.scrollToItem = null;
            this.scrollTo(item_);
        }
    }

    setItemMaximized(item, maximized, complete)    {
        if(this.items[item].isMaximized === maximized)  {
            complete(maximized);
            return;
        }

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.items[item].isMaximized = maximized;
            return newState;
        });

        let callback = this.completions[item];
        if(typeof(callback) === 'function') {
            //console.log('setItemMaximized callback');
            callback(this.items[item].isMaximized);
        }
        
        this.completions[item] = typeof(complete) === 'function' ? complete : null;
    }

    openItem(type, complete)    {
        this.setOpen(true, () => {
            this.setItemMaximized(type, true, () => {
                this.scrollTo(type);
                if(complete)    complete(this, this.isOpen());
            });
        });
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type !== this.oe.Module.UIControllerType.info || this.isOpen_ === userInfo.visible)  return;
        this.updateUIControllerState(userInfo.visible);
    }

    updateLanguage()   {}

    updateUIControllerState(isOpen)   {
        let open = false;
        if(typeof(isOpen) === 'boolean')    {
            open = isOpen;
        } else if(this.oe.isReady())   {
            let info = this.oe.sharedInterface.getUIControllerInfo();
            open = info.getUIVisible();
        }
        if(this.isOpen_ === open) return;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
        this.onOpenStateChanged(this, this.isOpen_);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.updateUIControllerState();
            return;
        }

        retardUpdate(this, () => {
            this.updateUIControllerState();
            this.updateLanguage();
        });
    }

    onRef(item, e) {
        //console.log("onRef " + item);
        this.itemRefs[item] = e;
        this.executeDeferredScrolling(item);
    }

    onScrollbarRef(e) {
        this.scrollbar = e; this.executeDeferredScrolling(); 
    }

    renderContent() {
        const config = this.props.config;
        let a = [];

        if(config.header)   a.push('header');
        if(config.mediaContent)   a.push('media');
        if(config.tutorials)   a.push('tutorials');
        if(this.props.appComponent.licensingManager)   a.push('licensing');

        return a.map((key, index) => {

            if(key === 'header')    {
                return (
                    <OEInfoViewItemHeader
                        key={key}
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        onToggle={this.onItemToggleHeader}
                        onAnimFinished={this.onItemAnimFinishedHeader}
                        isMaximized={this.state.items[OEInfoViewController.itemTypes.header].isMaximized}
                        notMinimizeable={config.isMaximized}
                        hasSeparator={index > 0}
                        elementRef={this.onRefHeader}
                        indentForLogo={config.logo != null}
                    />
                );
            }

            if(key === 'media')    {
                return (
                    <OEInfoViewItemMedia
                        key={key}
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        onToggle={this.onItemToggleMedia}
                        onAnimFinished={this.onItemAnimFinishedMedia}
                        isMaximized={this.state.items[OEInfoViewController.itemTypes.media].isMaximized}
                        notMinimizeable={config.isMaximized}
                        hasSeparator={index > 0}
                        elementRef={this.onRefMedia}
                        content={config.mediaContent}
                    />
                );
            }

            if(key === 'tutorials')    {
                return (
                    <OEInfoViewItemTutorials
                        key={key}
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        onToggle={this.onItemToggleTutorials}
                        onAnimFinished={this.onItemAnimFinishedTutorials}
                        isMaximized={this.state.items[OEInfoViewController.itemTypes.tutorials].isMaximized}
                        notMinimizeable={config.isMaximized}
                        hasSeparator={index > 0}
                        elementRef={this.onRefTutorials}
                    />
                );
            }

            if(key === 'licensing')    {
                return (
                    <OEInfoViewItemLicensing
                        key={key}
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        onToggle={this.onItemToggleLicensing}
                        onAnimFinished={this.onItemAnimFinishedLicensing}
                        isMaximized={this.state.items[OEInfoViewController.itemTypes.licensing].isMaximized}
                        notMinimizeable={config.isMaximized}
                        hasSeparator={index > 0}
                        elementRef={this.onRefLicensing}
                    />
                );
            }
        });
    }

    render() {
        const config = this.props.config;
        let isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;

        return (
            <OEStdModalController 
                moduleId={this.props.moduleId}
                headerStringId="info_view"
                className="info-view-controller"
                isOpen={isOpen}
                onToggle={this.onToggle}
                logo={config.header ? config.logo : null}
                title=''
                onOpened={this.onOpened}
                onClosed={this.onClosed}
            >
                <OEScrollbars className="info-view-scrollbar" ref={this.onScrollbarRef}>
                    <div className="info-view-content">
                        {this.renderContent()}
                    </div>
                </OEScrollbars>
            </OEStdModalController>
        );
    }

    onToggle() { 
        if(typeof(this.props.onToggle) === 'function')  {
            this.props.onToggle();
        } else {
            this.setOpen(false);
        }
    }

    onItemToggle(item)  {
        this.items[item].isMaximized = !this.items[item].isMaximized;

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.items[item].isMaximized = !prevState.items[item].isMaximized;
            return newState;
        });

        //
        let callback = this.completions[item];
        if(typeof(callback) === 'function') {
            //console.log('onItemToggle callback');
            callback(this.items[item].isMaximized);
        }
            
        this.completions[item] = null;
    }

    onItemAnimFinished(item)    {
        let callback = this.completions[item];
        if(typeof(callback) === 'function') {
            //console.log('onItemAnimFinished callback');
            callback(this.items[item].isMaximized);
        }
            
        this.completions[item] = null;
    }

    onOpened()  {
        if(typeof(this.onOpenedClosedCompletion) === 'function')    {
            this.onOpenedClosedCompletion();
            this.onOpenedClosedCompletion = null;
        }
    }

    onClosed()  {
        if(typeof(this.onOpenedClosedCompletion) === 'function')    {
            this.onOpenedClosedCompletion();
            this.onOpenedClosedCompletion = null;
        }
    }
}

OEInfoViewController.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.startView()
};

OEInfoViewController.propTypes = {
    moduleId: PropTypes.string,
    onOpenStateChanged: PropTypes.func
};

OEInfoViewController.itemTypes = {
    header: 'header',
    tutorials: 'tutorials',
    licensing: 'licensing',
    media: 'media'
};