import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';

import {withIsOpenState} from '../../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../../react-oe/oe-interface-adapter';
import OEIcon from '../../../elements/oe-icon';
import {OEIconButton} from '../../../elements/oe-button';
import OENumberInput from '../../../elements/oe-number-input';
import OEPopover from '../../../oe-popover';
import {OEIconCodes} from '../../../../lib/oe-icon-codes';
import {retardUpdate} from '../../../../lib/update-retarder';
import {OEToolbox} from '../../../../lib/oe-toolbox';
import {oeUniqueIdGen} from '../../../../lib/oe-unique-id-gen';
import {OEAssetSelectionType} from '../../../controller/asset-selection/oe-asset-selection-model';
import {OEAssetSelectionPopover} from '../../../controller/asset-selection/oe-asset-selection-controller';
import OEPresentationAttributeHelper from './oe-presentation-attribute-helper';
import {OEPresentationAttributeControllerCell, OEPresentationAttributeCellFactory} from './oe-presentation-attribute-cells';

export class OEPresentationAttributeController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.isAssetSelectionControllerOpen = false;

        this.attributeData = [];

        this.state = {
            uiEnabled: false,
            isAssetSelectionControllerOpen: false,
            slideData: null,
            attributeData: clone(this.attributeData),
            attributeLength: -1,
            strings: {
                displayTime: 'Current Display Time of Slide'
            },
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onPresentationSlideDataChanged = this.onPresentationSlideDataChanged.bind(this);
        this.onSlideAttributeAdded = this.onSlideAttributeAdded.bind(this);
        this.onSlideAttributeRemoved = this.onSlideAttributeRemoved.bind(this);
        this.onSlideAttributeChanged = this.onSlideAttributeChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onCellDumpBtnPressed = this.onCellDumpBtnPressed.bind(this);
        this.onAddBtnPressed = this.onAddBtnPressed.bind(this);
        this.onDisplayTimeChanged = this.onDisplayTimeChanged.bind(this);
        this.onSlideViewTimeSyncBtnPressed = this.onSlideViewTimeSyncBtnPressed.bind(this);
        this.onAssetSelectionControllerToggle = this.onAssetSelectionControllerToggle.bind(this);
        this.onAssetSelectionOkBtnPressed = this.onAssetSelectionOkBtnPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(nextProps.ids, this.props.ids))     {
            this.updateSlideData(null, nextProps);
            this.updateAttributeData(nextProps);
            if(!this.isSlideValid(nextProps))   this.setAssetSelectionControllerOpen(false);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideDataChanged, this.onPresentationSlideDataChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAttributeAdded, this.onSlideAttributeAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAttributeRemoved, this.onSlideAttributeRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAttributeChanged, this.onSlideAttributeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideDataChanged, this.onPresentationSlideDataChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAttributeAdded, this.onSlideAttributeAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAttributeRemoved, this.onSlideAttributeRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAttributeChanged, this.onSlideAttributeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    setAssetSelectionControllerOpen(open)   {
        if(this.isAssetSelectionControllerOpen === open)    return;
        this.isAssetSelectionControllerOpen = open;
        this.setState({isAssetSelectionControllerOpen: open});
    }

    updateLanguage()    {
        this.setState({
            strings: {
                displayTime: this.oe.sharedInterface.getLocalizedStringEnc('presentation_attribute_view_slide_display_time'),
            }
        });
    }

    updateSlideData(data, props)   {
        data = data || null;
        props = props || this.props;
        if(this.oe.isReady() && !data && props.ids)  {
            let pres = this.oe.sharedInterface.getUIControllerPresentation();
            data = pres.getSlideDataID(props.ids.pres, props.ids.slide)
        }
        this.setState({slideData: data});
    }

    updateAttributeData(props)   {
        if(!this.oe.isReady())  return;
        props = props || this.props;
        this.attributeData = [];
        let attributeLength = -1;
        if(props.ids)  {
            let attributeData_ = this.oe.sharedInterface.getUIControllerPresentation().getSlideAttributeDataListID(props.ids.pres, props.ids.slide);
            if(attributeData_)  this.attributeData = attributeData_.map((attr) => {
                let attribute = clone(attr);
                // cloning of emscripten enum objects fails (value field is missing), hence we work with the integer values for type & linkType
                attribute.type = attr.type.value;
                if(attr.linkType)   attribute.linkType = attr.linkType.value;
                if(attr.interval && attr.interval.y > 0.1)   attributeLength = Math.max(attributeLength, attr.interval.y);
                return attribute;
            });
        }
        this.setState({attributeData: clone(this.attributeData), attributeLength: attributeLength});
    }

    updateUIState()   {
        let uiEnabled = this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled();
        if(!uiEnabled)  this.isAssetSelectionControllerOpen = false;
        this.setState({uiEnabled: uiEnabled, isAssetSelectionControllerOpen: this.isAssetSelectionControllerOpen});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.isAssetSelectionControllerOpen = false;
            this.attributeData = [];
            this.setState({
                uiEnabled: false,
                isAssetSelectionControllerOpen: false,
                slideData: null,
                attributeData: clone(this.attributeData),
                attributeLength: -1
            });
            if(this.assetSelectionControllerRef)  this.assetSelectionControllerRef.close();
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateSlideData();
            this.updateAttributeData();
            this.updateUIState();
        });
    }

    onPresentationSlideDataChanged(message, userInfo)    {
        if(!this.props.ids || this.props.ids.pres != userInfo.presID || this.props.ids.slide != userInfo.slideID) return;
        this.updateSlideData(userInfo.data);
    }

    onSlideAttributeAdded(message, userInfo)    {
        this.updateAttributeData();
    }

    onSlideAttributeRemoved(message, userInfo)    {
        this.updateAttributeData();
    }

    onSlideAttributeChanged(message, userInfo)    {
        this.updateAttributeData();
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
        }
    }

    isSlideValid(props) {
        props = props || this.props;
        return props.ids && props.ids.pres >= 0 && props.ids.slide >= 0;
    }

    render()    {
        const disabled = !this.isSlideValid() || !this.state.uiEnabled;

        let cells = null;
        if(this.isSlideValid())  {
            cells = this.state.attributeData.map((attribute, index) =>    {
                return (
                    <OEPresentationAttributeCellFactory
                        key={index}
                        moduleId={this.props.moduleId}
                        enabled={!disabled}
                        ids={{pres: this.props.ids.pres, slide: this.props.ids.slide, attr: attribute.id}}
                        attribute={attribute}
                        showIcons={this.props.showAttributeIcons}
                        onDumpBtnPressed={this.onCellDumpBtnPressed}
                    />
                );
            });
        }

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="presentation-attribute-controller">
                    {cells}

                    {!this.props.addButton ? null :
                        <OEPresentationAttributeControllerCell className="text-right" separator={true}>
                            <OEIconButton
                                id="presentation-attribute-controller-add-btn"
                                className="transparent-btn add-btn"
                                disabled={disabled}
                                activated={this.state.isAssetSelectionControllerOpen}
                                onPressed={this.onAddBtnPressed}
                                icon={OEIconCodes.presSlideAttribute.add}
                            />
                        </OEPresentationAttributeControllerCell>
                    }

                    <OEPresentationAttributeControllerCell className="slide-view-time" separator={false}>
                        <OENumberInput
                            disabled={disabled}
                            min={0.1}
                            max={86400}
                            step={0.01}
                            value={this.state.slideData ? this.state.slideData.slideViewTime : 0.0}
                            label={this.state.strings.displayTime}
                            unit="s"
                            onChange={this.onDisplayTimeChanged}
                        />
                        {!this.props.devFeatures ? null :
                            <OEIconButton
                                className="transparent-btn sync-slide-view-time-btn"
                                disabled={disabled || this.state.attributeLength <= 0 || this.state.attributeLength === this.state.slideData.slideViewTime}
                                onPressed={this.onSlideViewTimeSyncBtnPressed}
                                icon={OEIconCodes.presSlideAttribute.syncSlideView}
                            />
                        }
                    </OEPresentationAttributeControllerCell>
                </div>

                {!this.props.addButton ? null :
                    <OEAssetSelectionPopover
                        moduleId={this.props.moduleId}
                        target="presentation-attribute-controller-add-btn"
                        placement="right-start"
                        hideArrow={false}
                        isOpen={this.state.isAssetSelectionControllerOpen}
                        onToggle={this.onAssetSelectionControllerToggle}
                        backdrop={false}
                        types={[OEAssetSelectionType.animation]}
                        onOkBtnPressed={this.onAssetSelectionOkBtnPressed}
                    />
                }

            </React.Fragment>
        );
    }

    onCellDumpBtnPressed(ids)   {
        if(!ids || !this.oe.isReady())  return;
        let si = this.oe.sharedInterface;
        if(!si.getUIControllerPresentation().getUIEnabled())   return;
        si.getUIControllerPresentation().removeSlideAttributeID(ids.pres, ids.slide, ids.attr);
    }

    onAddBtnPressed()   {
        this.setAssetSelectionControllerOpen(!this.isAssetSelectionControllerOpen);
    }

    onDisplayTimeChanged(sender, value) {
        if(!this.oe.isReady() || !this.props.ids)  return;
        this.oe.sharedInterface.getUIControllerPresentation().setSlideViewTimeID(this.props.ids.pres, this.props.ids.slide, value);
    }

    onSlideViewTimeSyncBtnPressed() {
        if(!this.oe.isReady() || !this.props.ids || this.state.attributeLength <= 0)  return;
        this.oe.sharedInterface.getUIControllerPresentation().setSlideViewTimeID(this.props.ids.pres, this.props.ids.slide, this.state.attributeLength);
    }

    onAssetSelectionControllerToggle()  {
        this.setAssetSelectionControllerOpen(false);
    }

    onAssetSelectionOkBtnPressed(selection)  {
        this.setAssetSelectionControllerOpen(false);
        OEPresentationAttributeHelper.addSlideAttribute(this.oe, this.props.ids, selection);
    }
}

OEPresentationAttributeController.defaultProps = {
    moduleId: '',
    devFeatures: false,
    showAttributeIcons: true,
    addButton: true
};

OEPresentationAttributeController.propTypes = {
    moduleId: PropTypes.string,
    ids: PropTypes.shape({
        pres: PropTypes.number.isRequired,
        slide: PropTypes.number.isRequired,
    }),
    devFeatures: PropTypes.bool,
    showAttributeIcons: PropTypes.bool,
    addButton: PropTypes.bool
};

export class OEPresentationAttributePopover extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.popoverTargetId = 'presentation-attribute-popover-target-id-' + oeUniqueIdGen.get();

        this.isAssetSelectionControllerOpen = false;

        this.state = {
            uiEnabled: false,
            isAssetSelectionControllerOpen: false
        };

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.renderHeaderCenterBar = this.renderHeaderCenterBar.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onAddBtnPressed = this.onAddBtnPressed.bind(this);
        this.onAssetSelectionControllerToggle = this.onAssetSelectionControllerToggle.bind(this);
        this.onAssetSelectionOkBtnPressed = this.onAssetSelectionOkBtnPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(nextProps.ids, this.props.ids))     {
            if(!this.isSlideValid(nextProps))  this.setAssetSelectionControllerOpen(false);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    setAssetSelectionControllerOpen(open)   {
        if(this.isAssetSelectionControllerOpen === open)    return;
        this.isAssetSelectionControllerOpen = open;
        this.setState({isAssetSelectionControllerOpen: open});
    }


    updateUIState()   {
        let uiEnabled = this.oe.isReady() ? this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled() : false;
        if(!uiEnabled)  this.isAssetSelectionControllerOpen = false;
        this.setState({uiEnabled: uiEnabled, isAssetSelectionControllerOpen: this.isAssetSelectionControllerOpen});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.isAssetSelectionControllerOpen = false;
            this.setState({uiEnabled: false, isAssetSelectionControllerOpen: false});
            return;
        }

        retardUpdate(this, () => {
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
        }
    }

    isSlideValid(props) {
        props = props || this.props;
        return props.ids && props.ids.pres >= 0 && props.ids.slide >= 0;
    }

    renderHeaderCenterBar(props)    {
        return (
            <OEIconButton
                className="transparent-btn presentation-attribute-controller add-btn"
                disabled={!this.isSlideValid(props) || !props.uiEnabled}
                activated={props.isAssetSelectionControllerOpen}
                onPressed={this.onAddBtnPressed}
                icon={OEIconCodes.presSlideAttribute.add}
            />
        );
    }

    render() {
        return  (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover
                    className="popover-control"
                    moduleId={this.props.moduleId}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    placement={this.props.placement}
                    hideArrow={this.props.hideArrow}
                    noHeader={this.props.noHeader}
                    buttonClassName="transparent-btn"
                    headerSeparator={this.props.headerSeparator}
                    centerBar={this.renderHeaderCenterBar}
                    isOpen={this.props.isOpen}
                    onToggle={this.onToggle}
                    popoverTargetId={this.popoverTargetId}
                    icon={this.props.icon}
                    titleId={this.props.titleId}
                    backdrop={this.props.backdrop}
                    ids={this.props.ids}
                    uiEnabled={this.state.uiEnabled}
                    isAssetSelectionControllerOpen={this.state.isAssetSelectionControllerOpen}
                >
                    <OEPresentationAttributeController
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        ids={this.props.ids}
                        devFeatures={this.props.devFeatures}
                        showAttributeIcons={this.props.showAttributeIcons}
                        addButton={false}
                    />

                    <OEAssetSelectionPopover
                        moduleId={this.props.moduleId}
                        target={this.popoverTargetId}
                        placement="right-start"
                        hideArrow={true}
                        headerSeparator={this.props.headerSeparator}
                        isOpen={this.state.isAssetSelectionControllerOpen}
                        onToggle={this.onAssetSelectionControllerToggle}
                        backdrop={false}
                        types={[OEAssetSelectionType.animation]}
                        onOkBtnPressed={this.onAssetSelectionOkBtnPressed}
                    />
                </OEPopover>
            </React.Fragment>
        );
    }

    onToggle()  {
        if(this.props.onToggle) this.props.onToggle();
        this.setAssetSelectionControllerOpen(false);
    }

    onAddBtnPressed()   {
        this.setAssetSelectionControllerOpen(!this.isAssetSelectionControllerOpen);
    }

    onAssetSelectionControllerToggle()  {
        this.setAssetSelectionControllerOpen(false);
    }

    onAssetSelectionOkBtnPressed(selection)  {
        this.setAssetSelectionControllerOpen(false);
        OEPresentationAttributeHelper.addSlideAttribute(this.oe, this.props.ids, selection);
    }
}

OEPresentationAttributePopover.defaultProps = {
    moduleId: '',
    devFeatures: false,
    showAttributeIcons: true,
    placement: 'right',
    hideArrow: false,
    noHeader: false,
    headerSeparator: true,
    titleId: 'presentation_attribute_view',
    backdrop: false
};

OEPresentationAttributePopover.propTypes = {
    moduleId: PropTypes.string,
    ids: PropTypes.shape({
        pres: PropTypes.number,
        slide: PropTypes.number,
    }),
    devFeatures: PropTypes.bool,
    showAttributeIcons: PropTypes.bool,
    hideArrow: PropTypes.bool,
    noHeader: PropTypes.bool,
    headerSeparator: PropTypes.bool,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    icon: PropTypes.string,
    titleId: PropTypes.string,
    backdrop: PropTypes.bool,
};

export default withIsOpenState(OEPresentationAttributePopover);