import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {connectAppEnv} from '../app-env';
import {delegateOpenState} from '../../lib/oe-higher-order-components';
import OENotification from '../../lib/oe-notification';
import {OEViewType, OEHelpViewTypes} from '../../lib/oe-types';
import OEStartView from '../start-view/oe-start-view';
import OEHelpViewStd from './oe-help-view-std';
import OEManualView from './oe-manual-view';
import {OEDefaultConfigFactory} from '../oe-default-configs';

export function OEHelpViewFactory(props) {
    const {fref, ...rest} = props;
    const config = rest.config;

    const openFn = (sender, isOpen) => {
        if(!props.appComponent || !props.appComponent.uiLayer) return;
        props.appComponent.uiLayer.notificationCenter.post(OENotification.viewOpenStateChanged, {
            type: OEViewType.help,
            isOpen: isOpen,
            sender: sender
        });
    };

    if(config.type === OEHelpViewTypes.std) {

        return <OEHelpViewStd {...rest} config={config} ref={fref} onOpenStateChanged={openFn}/>;

    } else if(config.type === OEHelpViewTypes.start) {

        let startViewConfig = rest.startViewConfig ? clone(rest.startViewConfig) : OEDefaultConfigFactory.startView();
        startViewConfig.showAtStartUp = false;
        return <OEStartView {...rest} options="help" config={startViewConfig} ref={fref} onOpenStateChanged={openFn}/>;

    } else if(config.type === OEHelpViewTypes.manual) {

        let manualViewConfig = rest.manualViewConfig ? clone(rest.manualViewConfig) : OEDefaultConfigFactory.manualView();
        manualViewConfig.showAtStartUp = false;
        return <OEManualView {...rest} config={manualViewConfig} ref={fref} onOpenStateChanged={openFn}/>;
    }

    return null;
}

OEHelpViewFactory.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.helpView()
};

OEHelpViewFactory.propTypes = {
    moduleId: PropTypes.string,
    config: OEDefaultConfigFactory.helpViewDecl
};

export const OEHelpViewFactoryConnected = connectAppEnv((env) => { return {
    version: env.config.version,
    target: env.config.target,
    appComponent: env.component,
    config: env.config.module.uiLayerConfig.helpViewConfig,
    startViewConfig: env.config.module.uiLayerConfig.startViewConfig,
    manualViewConfig: env.config.module.uiLayerConfig.manualViewConfig
}})(OEHelpViewFactory);

export default delegateOpenState(OEHelpViewFactoryConnected);