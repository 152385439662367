import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OENotification from '../../../lib/oe-notification';
import {OEViewType, OEFNWelcomeEntryTypes, OEManualViewLinks} from '../../../lib/oe-types';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OENavigationViewComponent} from '../../../lib/oe-navigation-controller';
import OEFNElement from '../../elements/oe-flow-nav-elements';
import OEFNHelp, {OEFNHelpHandling} from './oe-flow-nav-help';
import OEInfoViewController from '../../info-view/oe-info-view-controller';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEFNWelcome extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.icons = {
            home: OEIconCodes.home,
            licensing: OEIconCodes.key,
            mediaCenter: OEIconCodes.mediaCenter,
            help: OEIconCodes.help,
            quickStart: OEIconCodes.helpView.quickStart,
            manual: OEIconCodes.helpView.manual
        };

        this.state = {
            dontShowStartViewAtStartUp: localStorage.getItem('dontShowStartViewAtStartUp') === 'true',
            strings: {
                home: '',
                licensing: '',
                mediaCenter: '',
                help: '',
                quickStart: '',
                manual: '',
                dontShowAgainTitle: '',
                dontShowAgainSubtitle: ''
            },
            texts: {},
            hasMediaCenterFeature: true,
            licenseValid: true
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onFeatureAccessChanged = this.onFeatureAccessChanged.bind(this);
        this.onLicensingProductActivationChanged = this.onLicensingProductActivationChanged.bind(this);

        this.onMenuEntryClick = this.onMenuEntryClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);

        this.onDontShowAgainClicked = this.onDontShowAgainClicked.bind(this);
    }

    componentWillReceiveProps(nextProps)   {
        if(nextProps.locStringPrefix !== this.props.locStringPrefix || nextProps.entries !== this.props.entries)  {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    onFeatureAccessChanged(message, userInfo)    {
        this.updateFeatureAccess(userInfo.newFeatureSet);
    }

    onLicensingProductActivationChanged(message, userInfo)   {
        this.updateLicenseValidity();
    }

    updateLanguage(props)   {
        if(!this.oe.isReady())  return;
        props = props || this.props;
        let si = this.oe.sharedInterface;

        let texts = {};
        props.entries.forEach((entry, i) => {
            if(!entry.stringId)   return;
            texts[i.toString()] = si.getLocalizedStringEnc(entry.stringId);
        });

        this.setState({
            strings: {
                home: si.getLocalizedStringEnc(props.locStringPrefix + 'welcome_view_home'),
                licensing: si.getLocalizedStringEnc(props.locStringPrefix + 'welcome_view_license_manager'),
                mediaCenter: si.getLocalizedStringEnc(props.locStringPrefix + 'welcome_view_pres'),
                help: si.getLocalizedStringEnc(props.locStringPrefix + 'welcome_view_help'),
                quickStart: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_help'),
                manual: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_manual'),
                dontShowAgainTitle: si.getLocalizedStringEnc(props.locStringPrefix + 'welcome_view_dont_show_again_title'),
                dontShowAgainSubtitle: si.getLocalizedStringEnc(props.locStringPrefix + 'welcome_view_dont_show_again_subtitle'),
            },
            texts: texts
        });
    }

    updateFeatureAccess(featureSet) {
        featureSet = featureSet || this.oe.sharedInterface.getUIControllerFeatureAccess().getFeatureSet();
        this.setState({hasMediaCenterFeature: featureSet.hasFeature(this.oe.Module.Feature.media)});
        featureSet.delete();
    }

    updateLicenseValidity()    {
        let si = this.oe.sharedInterface;
        let licenseValid = !si.getUIControllerFeatureAccess().isAccessType(this.oe.Module.FeatureAccessType.licensing) || si.getUIControllerLicensingManager().isValid();
        this.setState({licenseValid: licenseValid});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true) return;
        this.updateLanguage();

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateFeatureAccess();
            this.updateLicenseValidity();
        });
    }

    isMasked(entry) {
        if(entry.type === OEFNWelcomeEntryTypes.mediaCenter && !this.state.hasMediaCenterFeature)   return true;
        if(!entry.mask) return false;
        const mask = entry.mask;
        return typeof(mask.licenseValid) === 'boolean' && mask.licenseValid !== this.state.licenseValid;
    }

    renderEntries() {
        let ret = [];
        let nextWithSpacer = false;
        
        for(let index = 0; index < this.props.entries.length; ++index)  {
            let entry = this.props.entries[index];
            if(this.isMasked(entry))    continue;

            let spacer = nextWithSpacer;
            let element;

            if(entry.type === OEFNWelcomeEntryTypes.text)   {
                let style = {};
                if(entry.fontSize)  style.fontSize = entry.fontSize;
                if(entry.minHeight) style.minHeight = entry.minHeight;

                element = (
                    <OEFNElement.Block
                        id={entry.type}
                        style={style}
                        content={entry.string ? entry.string : this.state.texts[index.toString()]}
                        html={entry.html}
                    />
                );
                
                nextWithSpacer = false;

            } else if(entry.type === OEFNWelcomeEntryTypes.spacer)   {

                element = <OEFNElement.Spacer id={entry.type} height={entry.height}/>
                nextWithSpacer = false;

            } else if(entry.type === OEFNWelcomeEntryTypes.dontShowCheckbox)   {

                element = <OEFNElement.Checkbox
                    title={this.state.texts[index.toString()] ? this.state.texts[index.toString()] : this.state.strings.dontShowAgainTitle}
                    subtitle={entry.noSubtitle ? null : this.state.strings.dontShowAgainSubtitle}
                    checked={this.state.dontShowStartViewAtStartUp}
                    onClick={this.onDontShowAgainClicked}
                />
                
                nextWithSpacer = false;

            } else if(entry.type === OEFNWelcomeEntryTypes.menu)   {
                
                let text = entry.string ? entry.string : this.state.texts[index.toString()];

                element = (
                    <OEFNElement.Menu
                        id={entry.id}
                        iconCode={entry.icon}
                        html={entry.html ? text : null}
                        title={entry.html ? null : text}
                        entry={entry}
                        onClick={entry.onClick ? this.onMenuEntryClick : null}
                    >
                        {!entry.logo ? null :
                            <div className="logo">
                                <img src="images/zeiss_logo.png"/>
                            </div>
                        }
                    </OEFNElement.Menu>
                );
                nextWithSpacer = true;

            } else {

                let text = entry.string ? entry.string : (this.state.texts[index.toString()] ? this.state.texts[index.toString()] : this.state.strings[entry.type]);

                element = (
                    <OEFNElement.Menu
                        id={entry.type}
                        iconCode={this.icons[entry.type]}
                        html={entry.html ? text : null}
                        title={entry.html ? null : text}
                        link={entry.link}
                        onClick={this.onItemClick}
                    />
                );
                nextWithSpacer = true;
            }
            
            ret.push(
                <React.Fragment key={index.toString() + '_element'}>
                    {nextWithSpacer && spacer ? <OEFNElement.Spacer height={30}/> : null}
                    {element}
                </React.Fragment>
            );
        }

        return ret;
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFNElement.View>
                    {this.renderEntries()}
                </OEFNElement.View>

                <OENavigationViewComponent
                    navigationController={this.props.navigationController}
                    compId={'welcome_' + OEFNWelcomeEntryTypes.help}
                    deferred={true}
                >
                    <OEFNHelp
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        navigationController={this.props.navigationController}
                        locStringPrefix={this.props.locStringPrefix}
                    />
                </OENavigationViewComponent>

                <OENavigationViewComponent
                    navigationController={this.props.navigationController}
                    compId={'welcome_' + OEFNWelcomeEntryTypes.quickStart}
                    deferred={true}
                >
                    <OEFNHelpHandling
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        navigationController={this.props.navigationController}
                        locStringPrefix={this.props.locStringPrefix}
                    />
                </OENavigationViewComponent>

            </React.Fragment>
        );
    }

    onMenuEntryClick(id, sender)    {
        sender.props.entry.onClick(id, this, this.props.navigationController);
    }

    onItemClick(id, sender) {
        let uiLayer = this.props.appComponent.uiLayer;

        if(id === OEFNWelcomeEntryTypes.home)   {

            uiLayer.startView.close();
            if(!this.oe.isReady()) return;

            uiLayer.closeAllPopovers();
            this.oe.sharedInterface.reset(true, 0.5);

        } else if(id === OEFNWelcomeEntryTypes.licensing)   {

            const useInfoListener = this.props.parent && this.oe.isReady();

            if(useInfoListener)   {
                let listener = {parent: this.props.parent, oe: this.oe};

                listener.onOpenStateChanged = function(message, userInfo)    {
                    if(userInfo.type !== OEViewType.info || userInfo.isOpen || userInfo.sender !== uiLayer.infoView)   return;
                    this.parent.setOpen(true);
                    this.unregister();
                }.bind(listener);

                listener.onLicensingProductActivationChanged = function(message, userInfo)    {
                    if(!userInfo.isValid)   return;
                    this.unregister();
                    uiLayer.infoView.setOpen(false);
                    this.parent.setOpen(true);
                }.bind(listener);

                listener.unregister = function()    {
                    uiLayer.notificationCenter.unregister(OENotification.viewOpenStateChanged, this.onOpenStateChanged);
                    if(this.oe.isReady())  this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
                }.bind(listener);
            
                uiLayer.notificationCenter.register(OENotification.viewOpenStateChanged, listener.onOpenStateChanged);

                this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingProductActivationChanged, listener.onLicensingProductActivationChanged);
            }

            uiLayer.infoView.openItem(OEInfoViewController.itemTypes.licensing);

            if(useInfoListener)   this.props.parent.setOpen(false);

        } else if(id === OEFNWelcomeEntryTypes.mediaCenter)   {

            uiLayer.startView.close();
            if(!this.oe.isReady()) return;
            uiLayer.closeAllPopovers();
            this.oe.sharedInterface.setUIControllerVisible(true, this.oe.Module.UIControllerType.media_center);

        } else if(id === OEFNWelcomeEntryTypes.help) {

            this.props.navigationController.push('welcome_' + OEFNWelcomeEntryTypes.help);

        } else if(id === OEFNWelcomeEntryTypes.quickStart)   {

            this.props.navigationController.push('welcome_' + OEFNWelcomeEntryTypes.quickStart);

        } else if(id === OEFNWelcomeEntryTypes.manual)   {

            if(!this.props.appComponent) return;
            uiLayer.manualView.setOpen(true, {link: sender.props.link ? sender.props.link : OEManualViewLinks.app});

        }
    }

    onDontShowAgainClicked()    {
        let newState = !this.state.dontShowStartViewAtStartUp;
        localStorage.setItem('dontShowStartViewAtStartUp', newState ? 'true' : 'false');
        this.setState({dontShowStartViewAtStartUp: newState});
    }
}

OEFNWelcome.defaultProps = {
    moduleId: '',
    locStringPrefix: '',
    entries:  [
        {type: OEFNWelcomeEntryTypes.spacer, height: 102},
        {type: OEFNWelcomeEntryTypes.licensing, mask: {licenseValid: false}},
        {type: OEFNWelcomeEntryTypes.home},
        {type: OEFNWelcomeEntryTypes.mediaCenter},
        {type: OEFNWelcomeEntryTypes.help},
        {type: OEFNWelcomeEntryTypes.spacer, height: 102},
        {type: OEFNWelcomeEntryTypes.dontShowCheckbox},
        {type: OEFNWelcomeEntryTypes.spacer, height: 68}
    ]
};

OEFNWelcome.propTypes = {
    moduleId: PropTypes.string,
    locStringPrefix: PropTypes.string,
    entries: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf([
            OEFNWelcomeEntryTypes.text,
            OEFNWelcomeEntryTypes.menu,
            OEFNWelcomeEntryTypes.home,
            OEFNWelcomeEntryTypes.licensing,
            OEFNWelcomeEntryTypes.mediaCenter,
            OEFNWelcomeEntryTypes.help,
            OEFNWelcomeEntryTypes.quickStart,
            OEFNWelcomeEntryTypes.manual,
            OEFNWelcomeEntryTypes.spacer,
            OEFNWelcomeEntryTypes.dontShowCheckbox
        ]).isRequired,
        id: PropTypes.string,
        stringId: PropTypes.string,
        string: PropTypes.string,
        html: PropTypes.bool,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        logo: PropTypes.string,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        noSubtitle: PropTypes.bool,
        fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        mask: PropTypes.shape({
            licenseValid: PropTypes.bool
        })
    }))
};