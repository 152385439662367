
export const OEColor = new class {
    
    compareEps(a, b, noAlpha = false, epsilon = 1/1024) {
        if(typeof(a) !== 'object' || typeof(b) !== 'object')    return a === b;
        if(a === b || (!a && !b)) return true;
        if(!a || !b) return false;

        if(epsilon <= 0)    {
            return a.x === b.x && a.y === b.y && a.z === b.z && (noAlpha || a.w === b.w);
        }

        if(typeof(a.x) !== 'number')    {
            if(a.x !== b.x) return false;
        } else {
            if(typeof(b.x) !== 'number' || Math.abs(b.x - a.x) > epsilon)    return false;
        }

        if(typeof(a.y) !== 'number')    {
            if(a.y !== b.y) return false;
        } else {
            if(typeof(b.y) !== 'number' || Math.abs(b.y - a.y) > epsilon)    return false;
        }

        if(typeof(a.z) !== 'number')    {
            if(a.z !== b.z) return false;
        } else {
            if(typeof(b.z) !== 'number' || Math.abs(b.z - a.z) > epsilon)    return false;
        }

        if(noAlpha) return true;

        if(typeof(a.w) !== 'number')    {
            if(a.w !== b.w) return false;
        } else {
            if(typeof(b.w) !== 'number' || Math.abs(b.w - a.w) > epsilon)    return false;
        }

        return true;
    }

    compare(a, b, noAlpha = false)   {
        return this.compareEps(a, b, noAlpha, 0)
    }

    clone(color)    {
        if(typeof(color) !== 'object' || !color)    return null;
        return {x: color.x, y: color.y, z: color.z, w: color.w};
    }

    sanitize(color, isHSV = false)    {
        if(!color)  return color;
        let x = typeof(color.x) === 'number' ? color.x : 0;

        isHSV = typeof(color.isHSV) === 'boolean' ? color.isHSV : isHSV;

        if(isHSV && (x < 0 || x >= 6)) {
            x = x - 6 * floor(x / 6);
            if(x < 0)   x = 6 + x;
        }

        return {
            x: Math.max(0, Math.min(isHSV ? 6 : 1, x)),
            y: typeof(color.y) === 'number' ? Math.max(0, Math.min(1, color.y)) : 0,
            z: typeof(color.z) === 'number' ? Math.max(0, Math.min(1, color.z)) : 0,
            w: typeof(color.w) === 'number' ? Math.max(0, Math.min(1, color.w)) : undefined
        };
    }

    addAlpha(color, alpha = 1)  {
        if(!color)  return color;
        return {x: color.x, y: color.y, z: color.z, w: typeof(color.w) === 'number' ? color.w : alpha};
    }

    mapAlphaWithRange(input, range, sub) {
        if(!range)  return input;
        if(sub && range[sub])   range = range[sub];
        
        if(typeof(input) === 'number')  {
            return input * (range.max - range.min) + range.min;
        } if(typeof(input) === 'object' && typeof(input.w) === 'number') {
            let ret = Object.assign({}, input);
            ret.w = ret.w * (range.max - range.min) + range.min;
            return ret;
        }

        return input;
    }

    sanitizeAddAlpha(color, isHSV = false, alpha = 1)  {
        if(!color)  return color;
        return this.addAlpha(this.sanitize(color, isHSV), alpha);
    }

    numberToColor(color)    {
        if(typeof(color) !== 'number')  return color;
        return {
            x: ((color & 0x00FF0000) >> 16) / 255.0,
            y: ((color & 0x0000FF00) >> 8) / 255.0,
            z: ((color & 0x000000FF)) / 255.0,
            w: ((color - (color & 0x00FFFFFF)) / 0x01000000) / 255.0
        };
    }

    toHSV(color)    {
        color = this.numberToColor(color);

        const min = Math.min(color.x, Math.min(color.y, color.z));
        const max = Math.max(color.x, Math.max(color.y, color.z));

        let hsv = {x: 0, y: 0, z: max, w: color.w};

        if(max === min) return hsv;

        let range = max - min;
            
        if(max > 0) hsv.y = range / max;

        range = 1 / range;
        let color2 = {x: range * color.x, y: range * color.y, z: range * color.z};

        if(max === color.x)  {
            hsv.x = color2.y - color2.z;
        } else if(max === color.y) {
            hsv.x = 2 + (color2.z - color2.x);
        } else {
            hsv.x = 4 + (color2.x - color2.y);
        }

        if(hsv.x < 0)   hsv.x = 6 + hsv.x;
        return hsv;
    }

    fromHSV(color)  {
        let x = color.x;

        if(x < 0 || x >= 6) {
            x = x - 6 * floor(x / 6);
            if(x < 0)   x = 6 + x;
        }

        const ihi = Math.floor(x);
        const ff = x - ihi;

        let p = color.z * (1 - color.y);
        let q = color.z * (1 - ff * color.y);
        let t = color.z * (1 - (1 - ff) * color.y);

        switch(ihi) {
            case 4: return {x: t, y: p, z: color.z, w: color.w};
            case 5: return {x: color.z, y: p, z: q, w: color.w};
            case 2: return {x: p, y: color.z, z: t, w: color.w};
            case 3: return {x: p, y: q, z: color.z, w: color.w};
            case 1: return {x: q, y: color.z, z: p, w: color.w};
        }

        return {x: color.z, y: t, z: p, w: color.w};
    }

    toHSVPreservative(color, incumbentColorHSV)  {
        if(!incumbentColorHSV)   return this.toHSV(color);

        // constant
        let incumbentColor = this.fromHSV(incumbentColorHSV);
        if(this.compareEps(incumbentColor, color))   return this.clone(incumbentColorHSV);

        let colorHSV = this.toHSV(color);
        let colorHSVCandidate = this.clone(colorHSV);

        // hue constant
        colorHSVCandidate.x = incumbentColorHSV.x;
        let colorCandidate = this.fromHSV(colorHSVCandidate);
        if(this.compareEps(colorCandidate, color))   return colorHSVCandidate;

        //
        return colorHSV;
    }

    toByteColor(color)  {
        return {
            x: Math.max(0, Math.min(255, Math.round(color.x*255))),
            y: Math.max(0, Math.min(255, Math.round(color.y*255))),
            z: Math.max(0, Math.min(255, Math.round(color.z*255))),
            w: color.w
        };
    }

    fromByteColor(color) {
        const c = 1 / 255;
        return {x: c * color.x, y: c * color.y, z: c * color.z, w: color.w};
    }

    toDOMStr(color)  {
        color = this.numberToColor(color);

        if(typeof(color) === 'undefined' || color === null) {
            return 'rgb(0,0,0)';
        }
        
        let color_ = this.toByteColor(this.sanitize(color));

        if(typeof(color_.w) !== 'undefined')    {
            return 'rgba(' + color_.x.toString() + ',' + color_.y.toString() + ',' + color_.z.toString() + ',' + color_.w.toString() + ')';
        } else {
            return 'rgb(' + color_.x.toString() + ',' + color_.y.toString() + ',' + color_.z.toString() + ')';
        }
    }

    hsvToDOMStr(color) {
        return this.toDOMStr(this.fromHSV(color));
    }

    toStr(color, isHSV = false, byteColor = false, pretify = false, noAlpha = false)  {
        let prefix = isHSV ? 'hsv' : 'rgb';

        color = this.numberToColor(color);

        if(typeof(color) === 'undefined' || color === null) {
            return prefix + (pretify ? '(0, 0, 0)' : '(0,0,0)');
        }

        const separator = pretify ? ', ' : ',';
        
        let color_ = this.sanitize(color, isHSV);

        if(noAlpha)    color_.w = undefined;

        if(byteColor && !isHSV) color_ = this.toByteColor(color_);

        if(typeof(color_.w) !== 'undefined')    {
            let strAlpha = byteColor ? color_.w.toPrecision(4).toString() : color_.w.toString();

            return prefix + 'a(' + color_.x.toString() + separator + color_.y.toString() + separator + color_.z.toString() + separator + strAlpha + ')';
        } else {
            return prefix + '(' + color_.x.toString() + separator + color_.y.toString() + separator + color_.z.toString() + ')';
        }
    }

    fromStr(str, byteColor = false)   {
        str = str.replace(/\s+/g, '');  // remove all white space
        str = str.toLowerCase();

        let trim = -1;
        let isHSV = false;
        let strTest = str.substring(0, 5);

        if(strTest === 'hsva(' || strTest === 'rgba(')  {
            trim = 5;
            isHSV = strTest === 'hsva(';
        } else {
            strTest = str.substring(0, 4);
            if(strTest === 'hsv(' || strTest === 'rgb(')  {
                trim = 4;
                isHSV = strTest === 'hsv(';
            }
        }

        if(trim === -1) {
            strTest = str.substring(0, 1);
            let color;
            if(strTest === '#') {
                str = str.substring(1, str.length);
                color = Number.parseInt(str, 16);
            } else {
                color = Number.parseInt(str);
            }
            if(isNaN(color))    return null;
            if(color < 0)   color = 0;
            color = this.numberToColor(color);
            color.isHSV = false;
            return color;
        }

        str = str.substring(trim, str.length);
        if(str.endsWith(')'))   str = str.substring(0, str.length - 1);
        
        let components = str.split(',');

        if(components.length < trim - 1)    return null;

        components = components.map(str => Number.parseFloat(str));
        for(let i = 0; i < components.length; ++i)  if(isNaN(components[i]))   return null;

        let ret = {
            x: components[0],
            y: components[1],
            z: components[2],
            w: trim >= 5 ? components[3] : 1
        };

        if(byteColor && !isHSV) ret = this.fromByteColor(ret);

        ret = this.sanitize(ret, isHSV);

        ret.isHSV = isHSV;

        return ret;
    }

    debugStr(color) {
        return 'color - r: ' + color.x + ', g: ' + color.y + ', b: ' + color.z + (color.w ? ', a: ' + color.w : '');
    }

    print(color)    {
        console.log(this.debugStr(color));
    }

    debugStrHSV(color) {
        return 'color - h: ' + color.x + ', s: ' + color.y + ', v: ' + color.z + (color.w ? ', a: ' + color.w : '');
    }

    printHSV(color) {
        console.log(this.debugStrHSV(color));
    }
};

export default OEColor;