import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEButton from '../elements/oe-button';
import OEPopover from '../oe-popover';
import OEColor from './oe-color';
import {retardUpdate} from '../../lib/update-retarder';

export class OEColorPaletteController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.tableEntries = [
            [0xFF332479, 0xFF163C6E, 0xFF035D5B, 0xFF006017, 0xFF006418, 0xFF827300, 0xFF945800, 0xFF8D0925, 0xFF6F0D6F],
            [0xFF7366B2, 0xFF4A6D9C, 0xFF108683, 0xFF329249, 0xFF859F15, 0xFFBCA602, 0xFFCC7A02, 0xFFC3274C, 0xFF955D95],
            [0xFFADA3D5, 0xFF8AA5CA, 0xFF61B9B7, 0xFF73C386, 0xFFB2D034, 0xFFF0D300, 0xFFFDAC35, 0xFFDC7890, 0xFFC294C2],
            [0xFFD8D4F0, 0xFFC7D7EF, 0xFFA7DFDE, 0xFFACE6BA, 0xFFD6E88A, 0xFFFFED69, 0xFFFFD597, 0xFFF6B6C5, 0xFFE5C3E5],
            [0xFFF0ECFE, 0xFFE3EFFF, 0xFFD9F7F6, 0xFFDFFDE6, 0xFFECFECE, 0xFFFFF7B9, 0xFFFFEBCD, 0xFFFFDFE7, 0xFFFDE7FD]
        ];

        this.additionalEntries = [
            {string: 'infobox', color: 0xFFFFFDDA},
            {string: 'clinicalBox', color: 0xFF9FE8D3},
            {string: 'mnemonic', color: 0xFFB0D4EF},
            {string: 'background', color: 0xFFEAEAEA},
        ];

        this.state = {
            strings: {
                infobox: '',
                clinicalBox: '',
                mnemonic: '',
                background: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onTableEntryPressed = this.onTableEntryPressed.bind(this);
        this.onAdditionalEntryPressed = this.onAdditionalEntryPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {
        this.setState({strings: {
            infobox: this.oe.sharedInterface.getLocalizedStringEnc('color_palette_infobox'),
            clinicalBox: this.oe.sharedInterface.getLocalizedStringEnc('color_palette_clinical_box'),
            mnemonic: this.oe.sharedInterface.getLocalizedStringEnc('color_palette_mnemonic'),
            background: this.oe.sharedInterface.getLocalizedStringEnc('color_palette_background')
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    render()    {
        let tableRows = this.tableEntries.map((row, i) =>
            <div key={i} className="color-palette-row">
                {row.map((color, j) => 
                    <OEButton
                        key={j}
                        index={{i: i,j: j}}
                        style={{backgroundColor: OEColor.toDOMStr(color)}}
                        onPressed={this.onTableEntryPressed}
                    />
                )}
            </div>
        );

        let additionalEntries = this.additionalEntries.map((entry, i) =>
            <div key={i} className="additional-color-item">
                <OEButton
                    index={i}
                    style={{backgroundColor: OEColor.toDOMStr(entry.color)}}
                    onPressed={this.onAdditionalEntryPressed}
                />
                <br/>
                <span>{this.state.strings[entry.string]}</span>
            </div>
        );

        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'color-palette-controller ' + (this.props.className ? ' ' + this.props.className : '')}>
                    <div className="color-palette-table">
                        {tableRows}
                    </div>
                    <div className="color-palette-additional-color-container">
                        {additionalEntries}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onTableEntryPressed(event, sender)  {
        let index = sender.props.index;
        let color = this.tableEntries[index.i][index.j];

        if(this.props.onTableEntry) this.props.onTableEntry(index, color);
        if(this.props.onColor) this.props.onColor(color);
    }

    onAdditionalEntryPressed(event, sender) {
        let index = sender.props.index;
        let color = this.additionalEntries[index].color;

        if(this.props.onTableEntry) this.props.onAdditionalEntry(index, color);
        if(this.props.onColor) this.props.onColor(color);
    }
}

OEColorPaletteController.defaultProps = {
    moduleId: ''
};

OEColorPaletteController.propTypes = {
    moduleId: PropTypes.string,
    onTableEntry: PropTypes.func,
    onAdditionalEntry: PropTypes.func,
    onColor: PropTypes.func
};

export const OEColorPalettePopover = OEPopover.coat(OEColorPaletteController, {
    noHeader: true,
}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OEColorPalettePopover);