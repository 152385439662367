import React from 'react';
import PropTypes from 'prop-types';

import OEButton from './oe-button';
import OEIcon from './oe-icon';

export default class OECheckbox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange()   {
        if(this.props.disabled || !this.props.onPressed) return;
        this.props.onPressed(this, this.props.userData);
    }

    render() {
        return (
            <div className={'checkbox ' + this.props.className + (this.props.disabled === true ? ' disabled' : '')}>
                <input type="checkbox" disabled={this.props.disabled} checked={this.props.checked} onChange={this.onChange}/>
            </div>
        );
    }
}

OECheckbox.defaultProps = {
    className: '',
    disabled: false,
    checked: false
};

OECheckbox.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onPressed: PropTypes.func
};

export class OECustomCheckbox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onPressed = this.onPressed.bind(this);
    }

    onPressed()   {
        if(this.props.onPressed)    this.props.onPressed(this, this.props.userData);
    }

    render() {
        return (
            <OEButton className={'custom-checkbox ' + (this.props.iconUnchecked ? 'has-unchecked-icon ' : '') + this.props.className} disabled={this.props.disabled} activated={this.props.checked} onPressed={this.onPressed}>
                <OEIcon code={!this.props.checked && this.props.uncheckedIcon ? this.props.uncheckedIcon : this.props.icon}/>
            </OEButton>
        );
    }
}

OECustomCheckbox.defaultProps = {
    className: '',
    disabled: false,
    checked: false,
    icon: '\uF00C'
};

OECustomCheckbox.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    icon: PropTypes.string,
    uncheckedIcon: PropTypes.string,
    onPressed: PropTypes.func
};