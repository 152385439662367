import {OEMediaViewerContentType, OEMediaViewerContent, OEMediaViewerDataSource} from '../../media-viewer/oe-media-viewer-model';

export default class OEMediaCenterMediaViewerDataSource extends OEMediaViewerDataSource  {

    constructor(items, categories, oe) {
        super();
        this.updateContent(items, categories, oe);
    }

    mediaViewerContentCount() {
        return this.content.length;
    }

    mediaViewerContentForIndex(index)   {
        return index >= 0 && index < this.content.length ? this.content[index] : null;
    }

    updateContent(items, categories, oe)    {
        this.content = [];
        if(!items || !categories || !oe) return;

        let last = null;
        let lastLast = null;
        let newCategory = false;

        for(let categoryIndex = 0; categoryIndex < categories.length; ++categoryIndex)  {
            let category = categories[categoryIndex];
            newCategory = true;

            let itemsInCategory = items.filter((item) => (item.categoryFlag & category.categoryFlags) != 0);

            for(let itemIndex = 0; itemIndex < itemsInCategory.length; ++itemIndex)  {
                let item = itemsInCategory[itemIndex];

                let primaryItem = false;
                let newItem = this.mediaViewContent(item, categoryIndex, oe);
                
                if(newItem)    {
                    newItem.itemId = {item: item.id, subItem: -1};
                    this.content.push(newItem);
                    
                    if(newItem.type === OEMediaViewerContentType.html || newItem.type === OEMediaViewerContentType.pdf)    {
                        newItem.blockPrev = true;
                        newItem.blockNext = true;
                    }

                    if(newCategory) {
                        newItem.blockPrev = true;
                        newCategory = false;
                    }

                    if(last)    {
                        if(newItem.blockPrev)   {
                            last.blockNext = true;
                        } else if(last.blockNext)   {
                            newItem.blockPrev = true;
                        }
                    }

                    lastLast = last;
                    last = newItem;

                    primaryItem = true;
                }

                let hasSubItemsContent = false;

                for(let subItemIndex = 0; subItemIndex < item.subItems.length; ++subItemIndex)  {
                    let subItem = item.subItems[subItemIndex];

                    let newItem = this.mediaViewContent(item, categoryIndex, oe);

                    if(!newItem)    continue;

                    if(primaryItem && !hasSubItemsContent)  {
                        if(lastLast)    lastLast.blockNext = true;
                        last.blockPrev = true;
                        hasSubItemsContent = true;
                    }

                    newItem.itemId = {item: item.id, subItem: subItem.id};
                    this.content.push(newItem);

                    if(newItem.type === OEMediaViewerContentType.html || newItem.type === OEMediaViewerContentType.pdf)    {
                        newItem.blockPrev = true;
                        newItem.blockNext = true;
                    }

                    if(!hasSubItemsContent) {
                        newItem.blockPrev = true;
                        newCategory = false;
                        hasSubItemsContent = true;
                    }

                    if(last)    {
                        if(newItem.blockPrev)   {
                            last.blockNext = true;
                        } else if(last.blockNext)   {
                            newItem.blockPrev = true;
                        }
                    }

                    lastLast = last;
                    last = newItem;
                }

                if(hasSubItemsContent)  {
                    last.blockNext = true;
                }
            }
        }
    }

    updateContentLocalization(oe)   {
        let si = oe.sharedInterface;
        let resourcePath = 'app/oe/' + oe.Module.filePackagePathURL;

        let ret = [];

        for(let i = 0; i < this.content.length; ++i)    {
            let content = this.content[i];
            let item = content.item;
            let change = false;

            if(item.type === oe.Module.MediaCenterItemType.video)    {
                let path = resourcePath + si.getLocalizedStringEnc(item.path);
                let coverImagePath = si.getLocalizedStringEnc(item.previewImagePath);
                change = path !== content.path || content.coverImagePath !== coverImagePath;
                content.path = path;
                content.coverImagePath = coverImagePath;
            } else if(item.type === oe.Module.MediaCenterItemType.image)    {
                let path = resourcePath + si.getLocalizedStringEnc(item.path);
                change = path !== content.path;
                content.path = path;
            } else if(item.type === oe.Module.MediaCenterItemType.html)    {
                let path = resourcePath + si.getLocalizedStringEnc(item.path);
                change = path !== content.path;
                content.path = path;
            } else if(item.type === oe.Module.MediaCenterItemType.pdf)    {
                let path = resourcePath + si.getLocalizedStringEnc(item.path);
                change = path !== content.path;
                content.path = path;
            }

            if(change)  ret.push(i);
        }

        return ret;
    }

    mediaViewContent(item, categoryIndex, oe)   {
        let si = oe.sharedInterface;
        let resourcePath = 'app/oe/' + oe.Module.filePackagePathURL;

        let result = null;
        if(item.type === oe.Module.MediaCenterItemType.video)    {
            let path = resourcePath + si.getLocalizedStringEnc(item.path);
            let coverImagePath = si.getLocalizedStringEnc(item.previewImagePath);
            result = new OEMediaViewerContent(OEMediaViewerContentType.video, path, false, coverImagePath);
        } else if(item.type === oe.Module.MediaCenterItemType.image)    {
            let path = resourcePath + si.getLocalizedStringEnc(item.path);
            result = new OEMediaViewerContent(OEMediaViewerContentType.image, path);
            result.isCachingEnabled = false;
        } else if(item.type === oe.Module.MediaCenterItemType.html)    {
            let path = resourcePath + si.getLocalizedStringEnc(item.path);
            result = new OEMediaViewerContent(OEMediaViewerContentType.web, path);
            result.parameter = item.name;
            result.isCachingEnabled = false;
            result.isPreloadEnabled = false;
        } else if(item.type === oe.Module.MediaCenterItemType.pdf)    {
            let path = resourcePath + si.getLocalizedStringEnc(item.path);
            result = new OEMediaViewerContent(OEMediaViewerContentType.pdf, path);
            result.parameter = item.name;
            result.isCachingEnabled = false;
            result.isPreloadEnabled = false;
        }

        if(!result) return null;

        result.blockNext = false;
        result.blockPrev = false;
        result.item = item;
        
        return result;
    }

    indexForItem(id)    {
        return this.content.findIndex((item) => item.itemId.item === id.item && item.itemId.subItem === id.subItem);
    }
};