import React from 'react';
import PropTypes from 'prop-types';

import {translateToUILevel, withUILevel} from '../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import OENotification from '../lib/oe-notification';
import {OEMainMenuStdBtnType} from '../lib/oe-types';
import {OEDefaultConfigFactory} from './oe-default-configs';
import OEIcon from './elements/oe-icon';
import OEButton from './elements/oe-button';
import {OEToolbox} from '../lib/oe-toolbox';
import {OEIconCodes} from '../lib/oe-icon-codes';
import {retardUpdate} from '../lib/update-retarder';

export class OEMainMenu extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.maxWidthStep = 4;
        this.overMaxWidthStep = false;
        this.widthStep = 1;
        this.stepWidth = 56;
        this.width = this.widthStep * this.stepWidth;

        this.buttons = this.constructButtons(this.props, false, true);

        this.state = {
            width: this.width, 
            overMaxWidthStep: false,
            buttons: this.constructButtonsState(),
            style: {width: this.width.toString() + 'px'}
        };

        this.setListenerToButtons(this);

        this.onWindowResized = this.onWindowResized.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateForthBackButtons = this.updateForthBackButtons.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onExplosionFactorChanged = this.onExplosionFactorChanged.bind(this);
        this.onIsWorkingColorChanged = this.onIsWorkingColorChanged.bind(this);
        this.onLabelsStateChanged = this.onLabelsStateChanged.bind(this);
        this.onCutModeChanged = this.onCutModeChanged.bind(this);
        this.onViewOpenStateChanged = this.onViewOpenStateChanged.bind(this);

        this.onRef = this.onRef.bind(this);

        this.onAdapterBtnPressed = this.onAdapterBtnPressed.bind(this);
        this.onHomeBtnPressed = this.onHomeBtnPressed.bind(this);
        this.onLabelBtnPressed = this.onLabelBtnPressed.bind(this);
        this.onNoteBtnPressed = this.onNoteBtnPressed.bind(this);
        this.onArrowBtnPressed = this.onArrowBtnPressed.bind(this);
        this.onCutBtnPressed = this.onCutBtnPressed.bind(this);
        this.onSearchBtnPressed = this.onSearchBtnPressed.bind(this);
        this.onTreeBtnPressed = this.onTreeBtnPressed.bind(this);
        this.onSubstructureBtnPressed = this.onSubstructureBtnPressed.bind(this);
        this.onColorBtnPressed = this.onColorBtnPressed.bind(this);
        this.onExplodeBtnPressed = this.onExplodeBtnPressed.bind(this);
        this.onPresentationBtnPressed = this.onPresentationBtnPressed.bind(this);
        this.onMediaCenterBtnPressed = this.onMediaCenterBtnPressed.bind(this);
        this.onInfoBtnPressed = this.onInfoBtnPressed.bind(this);
        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onSettingsBtnPressed = this.onSettingsBtnPressed.bind(this);
        this.onBackBtnPressed = this.onBackBtnPressed.bind(this);
        this.onForthBtnPressed = this.onForthBtnPressed.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.arrayShallowEqual(this.props.buttons.top, nextProps.buttons.top) || !OEToolbox.arrayShallowEqual(this.props.buttons.bottom, nextProps.buttons.bottom) || this.props.uiLevel !== nextProps.uiLevel) {
            this.setListenerToButtons(null);
            this.releaseButtons();
            this.buttons = this.constructButtons(nextProps, false, true);
            this.updateButtonsState();
            this.setListenerToButtons(this);
            this.connectButtons();
            this.updateTooltips();
            this.updateButtons();
            this.layout(nextProps);
        }
    }

    forEachButton(fn)   {
        this.buttons.top.forEach(fn);
        this.buttons.bottom.forEach(fn);
    }

    findButtonForType(type) {
        if(!type || !this.buttons) return;
        let res = this.buttons.top.find(btn => btn.type === type)
        if(res) return res;
        return this.buttons.bottom.find(btn => btn.type === type)
    }

    constructButtons(props, activated, disabled) {
        let buttons = (props || this.props).buttons;

        let constructFn = btn => {
            let oldBtnState = this.findButtonForType(btn.type);
            let btnState = {
                type: btn.type,
                config: {
                    enabled: btn.enabled,
                    tooltip: btn.tooltip,
                    adapter: btn.adapter
                },
                activated: activated,
                disabled: !(btn.enabled && !disabled),
                hidden: oldBtnState ? oldBtnState.hidden : false,
                tooltip: '',
                icon: btn.icon
            }

            if(btnState.config.adapter) {
                btnState.activated = btnState.config.adapter.isActive(this);
                btnState.disabled = !(btnState.config.enabled && btnState.config.adapter.isEnabled(this));
            }
            return btnState;
        };

        let topButtons = buttons.top.map(constructFn);
        let bottomButtons = buttons.bottom.map(constructFn);
        return {top: topButtons, bottom: bottomButtons};
    }

    constructButtonsState() {
        let constructFn = btn => { return {
            type: btn.type,
            activated: btn.activated,
            disabled: btn.disabled,
            hidden: btn.hidden,
            tooltip: btn.tooltip,
            icon: btn.icon
        }};

        let topButtons = this.buttons.top.map(constructFn);
        let bottomButtons = this.buttons.bottom.map(constructFn);
        return {top: topButtons, bottom: bottomButtons};
    }

    updateButtonsState() {
        this.setState({buttons: this.constructButtonsState()});
    }

    setListenerToButtons(listener) {
        this.forEachButton(btn => {
            if(btn.config.adapter) btn.config.adapter.menu = listener;
        });
    }

    connectButtons()   {
        if(!this.oe.isReady()) return;
        this.forEachButton(btn => {
            if(!btn.config.adapter) return;
            let adapter = btn.config.adapter;

            if(adapter._connectedModuleId)  {
                adapter.onRelease(this.oe, this);
            }
            
            adapter.oe = this.oe;
            adapter._connectedModuleId = this.oe.moduleId;
            adapter.onConnect(this.oe, this);
        });
    }

    releaseButtons()   {
        this.forEachButton(btn => {
            if(!btn.config.adapter) return;
            let adapter = btn.config.adapter;
            if(!adapter._connectedModuleId)  return;
            adapter.onRelease(this.oe, this);
            adapter._connectedModuleId = undefined;
        });
    }

    componentDidMount()    {
        this.onWindowResized();
        window.addEventListener('resize', this.onWindowResized);
        this.setListenerToButtons(this);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
        this.setListenerToButtons(null);
    }

    onConnect()  {
        this.connectButtons();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.newModelStateSaved, this.updateForthBackButtons);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.modelStateWasApplied, this.updateForthBackButtons);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.modelStateCollectionWasReset, this.updateForthBackButtons);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.explosionFactorChanged, this.onExplosionFactorChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.isWorkingColorChanged, this.onIsWorkingColorChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.labelsStateChanged, this.onLabelsStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.cutModeChanged, this.onCutModeChanged);

        this.props.appComponent.uiLayer.notificationCenter.register(OENotification.viewOpenStateChanged, this.onViewOpenStateChanged);
    }

    onRelease()    {
        this.releaseButtons();
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.newModelStateSaved, this.updateForthBackButtons);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.modelStateWasApplied, this.updateForthBackButtons);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.modelStateCollectionWasReset, this.updateForthBackButtons);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.explosionFactorChanged, this.onExplosionFactorChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.isWorkingColorChanged, this.onIsWorkingColorChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.labelsStateChanged, this.onLabelsStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.cutModeChanged, this.onCutModeChanged);

        this.props.appComponent.uiLayer.notificationCenter.unregister(OENotification.viewOpenStateChanged, this.onViewOpenStateChanged);
    }

    onWindowResized()   {
        this.layout();
    }

    updateLanguage()   {
        this.updateTooltips();
    }

    menuHeight(num, widthStep)   {
        let n = Math.ceil(num / widthStep);
        return n * 56.0;
    }

    layout(props)    {
        if(!this.element) return;
        let props_ = props || this.props;

        let numTop = 0, numBottom = 0;
        this.buttons.top.forEach(btn => { if(btn.hidden !== true) numTop++; });
        this.buttons.bottom.forEach(btn => { if(btn.hidden !== true) numBottom++; });

        let height = this.element.offsetHeight;
        let widthStep = this.widthStep;
        let overMaxWidthStep = false;
        let iterate = true;

        while(iterate)  {
            iterate = false;

            let newWidthStep = widthStep;
            let topHeight = this.menuHeight(numTop, widthStep);
            let bottomHeight = this.menuHeight(numBottom, widthStep);

            if(height - bottomHeight < topHeight)   {
                if(widthStep + 1 > this.maxWidthStep) overMaxWidthStep = true;
                newWidthStep = Math.min(widthStep + 1, this.maxWidthStep);
            } else if(height - bottomHeight - topHeight > 50)   {
                if(widthStep > 1)    {
                    let widthStepTest = widthStep - 1;
                    let topHeightTest = this.menuHeight(numTop, widthStepTest);
                    let bottomHeightTest = this.menuHeight(numBottom, widthStepTest);

                    if(!(height - bottomHeightTest < topHeightTest))    {
                        newWidthStep = widthStepTest;
                    }
                }
            }

            iterate = newWidthStep != widthStep;
            widthStep = newWidthStep;
        }

        if(this.overMaxWidthStep !== overMaxWidthStep)  {
            this.overMaxWidthStep = overMaxWidthStep;
            this.setState({overMaxWidthStep: overMaxWidthStep});
        }

        this.widthStep = widthStep;
        let newWidth = this.widthStep * this.stepWidth;

        if(this.width !== newWidth)    {
            this.width = newWidth;
            this.setState({width: this.width, style: {width: this.width.toString() + 'px'}});
            
            if(props_.onWidthChanged) props_.onWidthChanged(newWidth);
        }
    }

    isLabelWidgetEnabled(props)  {
        const props_ = props || this.props;
        return !props_.widgetConfig || translateToUILevel(props_.widgetConfig.labelController, props_.uiLevel).enabled;
    }

    isCutWidgetEnabled(props)  {
        const props_ = props || this.props;
        return !props_.widgetConfig || translateToUILevel(props_.widgetConfig.cutController, props_.uiLevel).enabled;
    }

    updateTooltips()    {
        if(!this.oe.isReady()) return;
        let si = this.oe.sharedInterface;
        this.forEachButton(btn => {
            btn.tooltip = si.getLocalizedStringEnc(btn.config.tooltip ? btn.config.tooltip : ('main_menu_tooltip_' + OEToolbox.lowerCamelCaseToLowercaseUnderscore(btn.type)));
        });
        this.updateButtonsState();
    }

    updateForthBackButtons()  {
        if(!this.oe.isReady()) return;

        let modelStateController = this.oe.sharedInterface.getUIControllerModelState();

        const uiEnabled = modelStateController.getUIEnabled();
        const frontDis = modelStateController.frontDistance();
        const backDis = modelStateController.backDistance();

        let backBtn = this.findButtonForType(OEMainMenuStdBtnType.back);
        if(backBtn) {
            backBtn.disabled = !(backBtn.config.enabled && backDis > 0 && uiEnabled);
            if(backBtn.config.adapter)  {
                backBtn.activated = backBtn.config.adapter.isActive(this);
                backBtn.disabled = !(backBtn.config.enabled && backBtn.config.adapter.isEnabled(this));
            }
        }

        let forthBtn = this.findButtonForType(OEMainMenuStdBtnType.forth);
        if(forthBtn) {
            forthBtn.disabled = !(forthBtn.config.enabled && frontDis > 0 && uiEnabled);
            if(forthBtn.config.adapter)  {
                forthBtn.activated = forthBtn.config.adapter.isActive(this);
                forthBtn.disabled = !(forthBtn.config.enabled && forthBtn.config.adapter.isEnabled(this));
            }
        }

        this.updateButtonsState();
    }

    updateButtons(props) {
        if(!this.oe.isReady()) return;
        
        const label = this.oe.sharedInterface.getUIControllerLabel();
        const note = this.oe.sharedInterface.getUIControllerNote();
        const arrow = this.oe.sharedInterface.getUIControllerArrow();
        const cut = this.oe.sharedInterface.getUIControllerCut();
        const search = this.oe.sharedInterface.getUIControllerSearch();
        const component = this.oe.sharedInterface.getUIControllerComponent();
        const substructure = this.oe.sharedInterface.getUIControllerSubstructure();
        const color = this.oe.sharedInterface.getUIControllerColor();
        const explosion = this.oe.sharedInterface.getUIControllerExplosion();
        const presentation = this.oe.sharedInterface.getUIControllerPresentation();
        const mediaCenter = this.oe.sharedInterface.getUIControllerMediaCenter();
        const info = this.oe.sharedInterface.getUIControllerInfo();
        const settings = this.oe.sharedInterface.getUIControllerSettings();

        let buttons = {
            home: {activated: false, disabled: false},
            label: this.isLabelWidgetEnabled(props) ? {activated: label.getUIVisible(), disabled: !label.getUIPresentable()} : {activated: label.getEnabled(), disabled: !label.getUIEnabled()},
            note: {activated: note.getUIVisible(), disabled: !note.getUIPresentable()},
            arrow: {activated: arrow.getUIVisible(), disabled: !arrow.getUIPresentable()},
            cut: this.isCutWidgetEnabled(props) ? {activated: cut.getUIVisible(), disabled: !cut.getUIPresentable()} : {activated: cut.getMode() !== this.oe.Module.CutMode.disabled, disabled: !cut.getUIEnabled()},
            search: {activated: search.getUIVisible(), disabled: !search.getUIPresentable()},
            tree: {activated: component.getUIVisible(), disabled: !component.getUIPresentable()},
            substructure: {activated: substructure.getUIVisible(), disabled: !substructure.getUIPresentable(), hidden: substructure.numClasses() == 0},
            color: {activated: color.getIsWorkingColor(), disabled: !color.getUIEnabled()},
            explode: {activated: explosion.isExploded(), disabled: !explosion.getUIEnabled()},
            presentation: {activated: presentation.getUIVisible(), disabled: !presentation.getUIPresentable()},
            mediaCenter: {activated: mediaCenter.getUIVisible(), disabled: !mediaCenter.getUIPresentable()},
            info: {activated: info.getUIVisible(), disabled: !info.getUIPresentable()},
            settings: {activated: settings.getUIVisible(), disabled: !settings.getUIPresentable()}
        };

        this.forEachButton(btn => {
            let btn_ = buttons[btn.type];
            if(btn_) {
                btn.activated = btn_.activated;
                btn.disabled = !(btn.config.enabled && !btn_.disabled);
                if(typeof(btn_.hidden) === 'boolean') btn.hidden = btn_.hidden;
            }
            if(!btn.config.adapter) return;
            btn.activated = btn.config.adapter.isActive(this);
            btn.disabled = !(btn.config.enabled && btn.config.adapter.isEnabled(this));
        });
        
        this.updateForthBackButtons();
    }

    updateForAdapter(adapter)  {
        let btn = this.buttons.top.find(btn => btn.config.adapter === adapter);
        if(!btn) btn = this.buttons.bottom.find(btn => btn.config.adapter === adapter);
        if(!btn)    return;
        if(!btn.config.adapter) return;
        btn.activated = btn.config.adapter.isActive(this);
        btn.disabled = !(btn.config.enabled && btn.config.adapter.isEnabled(this));
        this.updateButtonsState();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true) {
            // do not overwrite hidden state here
            this.buttons = this.constructButtons(this.props, false, true);
            this.updateButtonsState();
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateButtons();
        });
    }
    
    onUIControllerStateChanged(message, userInfo)   {
        let btnType = null;
        switch(userInfo.type)   {
            case this.oe.Module.UIControllerType.label: btnType = OEMainMenuStdBtnType.label; break;
            case this.oe.Module.UIControllerType.note: btnType = OEMainMenuStdBtnType.note; break;
            case this.oe.Module.UIControllerType.arrow: btnType = OEMainMenuStdBtnType.arrow; break;
            case this.oe.Module.UIControllerType.cut: btnType = OEMainMenuStdBtnType.cut; break;
            case this.oe.Module.UIControllerType.search_controller: btnType = OEMainMenuStdBtnType.search; break;
            case this.oe.Module.UIControllerType.component: btnType = OEMainMenuStdBtnType.tree; break;
            case this.oe.Module.UIControllerType.substructure: btnType = OEMainMenuStdBtnType.substructure; break;
            case this.oe.Module.UIControllerType.color: btnType = OEMainMenuStdBtnType.color; break;
            case this.oe.Module.UIControllerType.explosion: btnType = OEMainMenuStdBtnType.explode; break;
            case this.oe.Module.UIControllerType.presentation: btnType = OEMainMenuStdBtnType.presentation; break;
            case this.oe.Module.UIControllerType.media_center: btnType = OEMainMenuStdBtnType.mediaCenter; break;
            case this.oe.Module.UIControllerType.info: btnType = OEMainMenuStdBtnType.info; break;
            case this.oe.Module.UIControllerType.settings: btnType = OEMainMenuStdBtnType.settings; break;
            case this.oe.Module.UIControllerType.model_state: this.updateForthBackButtons(); return;
        }

        let btn = this.findButtonForType(btnType);
        if(!btn || btn.config.adapter) return;

        let isFunctionalBtn = 
            (btnType === OEMainMenuStdBtnType.label && !this.isLabelWidgetEnabled()) ||
            (btnType === OEMainMenuStdBtnType.cut && !this.isCutWidgetEnabled()) ||
            (btnType === OEMainMenuStdBtnType.color || btnType === OEMainMenuStdBtnType.explode);

        if(!isFunctionalBtn)    {
            btn.activated = userInfo.visible;
            btn.disabled = !(btn.config.enabled && userInfo.presentable);
        } else {
            btn.disabled = !userInfo.enabled;
        }
        
        this.updateButtonsState();
    }
    
    onExplosionFactorChanged(message, userInfo)   {
        let btn = this.findButtonForType(OEMainMenuStdBtnType.explode);
        if(!btn) return;
        btn.activated = userInfo.exploded;
        this.updateButtonsState();
    }
    
    onIsWorkingColorChanged(message, userInfo)   {
        let btn = this.findButtonForType(OEMainMenuStdBtnType.color);
        if(!btn) return;
        btn.activated = userInfo.isWorkingColor;
        this.updateButtonsState();
    }

    onLabelsStateChanged(message, userInfo)    {
        if(this.isLabelWidgetEnabled()) return;
        let btn = this.findButtonForType(OEMainMenuStdBtnType.label);
        if(!btn) return;
        btn.activated = userInfo.enabled;
        this.updateButtonsState();
    }

    onCutModeChanged(message, userInfo)  {
        if(this.isCutWidgetEnabled()) return;
        let btn = this.findButtonForType(OEMainMenuStdBtnType.cut);
        if(!btn) return;
        btn.activated = userInfo.mode !== this.oe.Module.CutMode.disabled;
        this.updateButtonsState();
    }

    onViewOpenStateChanged(message, userInfo)   {
        this.updateButtons();
    }

    onRef(e) {
        if(typeof(this.props.elementRef) === 'function') {
            this.props.elementRef(e); 
        }

        this.element = e;
        this.layout();
    }

    renderButton(btn)  {
        let type = btn.type;
        let typeLU = OEToolbox.lowerCamelCaseToLowercaseUnderscore(type);
        let typeUC = OEToolbox.capitalize(type);
        let iconCode = (type == OEMainMenuStdBtnType.back || type == OEMainMenuStdBtnType.forth) ? ('state' + typeUC) : type;

        return (
            <OEButton
                id={'mm-' + typeLU + '-btn'}
                className="main-menu-button transparent-btn"
                activated={!btn.disabled && btn.activated}
                disabled={btn.disabled}
                onPressed={this.onAdapterBtnPressed}
                tooltip={btn.tooltip}
                userData={btn}
            >
                <OEIcon code={btn.icon || OEIconCodes[iconCode]} />
            </OEButton>
        );
    }

    render() {
        const topButtons = this.buttons.top.map((btn) => {
            let type = btn.type;
            let typeLU = OEToolbox.lowerCamelCaseToLowercaseUnderscore(type);
            let style = {}; if(btn.hidden) style.display = 'none';
            return <li key={typeLU} style={style}>{this.renderButton(btn)}</li>;
        });
        const bottomButtons = this.buttons.bottom.map((btn) => {
            let type = btn.type;
            let typeLU = OEToolbox.lowerCamelCaseToLowercaseUnderscore(type);
            let style = {}; if(btn.hidden) style.display = 'none';
            return <li key={typeLU} style={style}>{this.renderButton(btn)}</li>;
        });

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div 
                    id="main-menu" 
                    className="main-menu main-menu-bg main-menu-text-color" 
                    style={this.state.style} 
                    ref={this.onRef}
                >

                    <div className="main-menu-top">
                        <ul>{topButtons}{this.state.overMaxWidthStep ? bottomButtons : null}</ul>
                    </div>

                    <div className="main-menu-bottom">
                        <ul>{!this.state.overMaxWidthStep ? bottomButtons : null}</ul>           
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onAdapterBtnPressed(e, sender)   {
        let btn = this.findButtonForType(sender.props.userData.type);
        let adapter = btn.config.adapter;
        if(!adapter) {
            let typeUC = OEToolbox.capitalize(btn.type);
            if(this['on' + typeUC + 'BtnPressed']) this['on' + typeUC + 'BtnPressed']();
            return false;
        }
        btn.activated = adapter.onPressed(sender.props.activated, this);
        this.updateButtonsState();
        return true;
    }

    onHomeBtnPressed()  {
        if(!this.oe.isReady()) return;
        this.props.appComponent.uiLayer.closeAllPopovers();
        this.oe.sharedInterface.reset(true, 0.5);
    }
    
    onLabelBtnPressed() {
        if(!this.oe.isReady()) return;

        if(!this.isLabelWidgetEnabled()) {
            let label = this.oe.sharedInterface.getUIControllerLabel();
            label.setEnabled(!label.getEnabled(), true, 0.333);
            return;
        }

        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.label), this.oe.Module.UIControllerType.label))   {
            this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.label);
        }
    }

    onNoteBtnPressed()   {
        if(!this.oe.isReady()) return;
        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.note), this.oe.Module.UIControllerType.note))   {
            this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.note);
        }
    }

    onArrowBtnPressed()   {
        if(!this.oe.isReady()) return;
        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.arrow), this.oe.Module.UIControllerType.arrow))   {
            this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.arrow);
        }
    }
    
    onCutBtnPressed() {
        if(!this.oe.isReady()) return;

        if(!this.isCutWidgetEnabled()) {
            let cut = this.oe.sharedInterface.getUIControllerCut(); const CutMode = this.oe.Module.CutMode;
            cut.setMode(cut.getMode() !== CutMode.disabled ? CutMode.disabled : CutMode.touch);
            return;
        }

        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.cut), this.oe.Module.UIControllerType.cut))   {
            this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.cut);
        }
    }

    onSearchBtnPressed() {
        if(!this.oe.isReady()) return;

        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.search_controller), this.oe.Module.UIControllerType.search_controller))   {
            this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.search_controller);
        }
    }
    
    onTreeBtnPressed() {
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.component), this.oe.Module.UIControllerType.component);
    }

    onSubstructureBtnPressed() {
        if(!this.oe.isReady()) return;
        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.substructure), this.oe.Module.UIControllerType.substructure)) {
            this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.substructure);
        }
    }
    
    onColorBtnPressed() {
        if(!this.oe.isReady()) return;
        let btn = this.findButtonForType(OEMainMenuStdBtnType.color);
        this.oe.sharedInterface.getUIControllerColor().setIsWorkingColor(!btn.activated, true, 0.333);
    }
    
    onExplodeBtnPressed() {
        if(!this.oe.isReady()) return;
        let btn = this.findButtonForType(OEMainMenuStdBtnType.explode);
        this.oe.sharedInterface.getUIControllerExplosion().setExplosionFactorStd(btn.activated ? 0.0 : 1.0, true);
    }
    
    onPresentationBtnPressed() {
        if(!this.oe.isReady()) return;
        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.presentation), this.oe.Module.UIControllerType.presentation)) {
            if(this.props.widgetConfig && this.props.widgetConfig.presentationController.enabled) {
                this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.presentation);
            }
        }
    }

    onMediaCenterBtnPressed()   {
        if(!this.oe.isReady()) return;
        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.media_center), this.oe.Module.UIControllerType.media_center)) {
            //this.oe.sharedInterface.setUIControllerVisible(false, this.oe.Module.UIControllerType.presentation);
        }
    }

    onInfoBtnPressed(e, sender) {
        let appComponent = this.props.appComponent;
        appComponent.uiLayer.infoView.setOpen(true);
    }

    onHelpBtnPressed(e, sender) {
    }

    onSettingsBtnPressed() {
        if(!this.oe.isReady()) return;
        if(this.oe.sharedInterface.setUIControllerVisible(!this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.settings), this.oe.Module.UIControllerType.settings))    {
            this.props.appComponent.uiLayer.closeAllPopovers(this.oe.Module.UIControllerType.settings);
        }
    }
    
    onBackBtnPressed() {
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerModelState().inc(-1);
    }
    
    onForthBtnPressed() {
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerModelState().inc(1);
    }
}

OEMainMenu.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.mainMenu(),
    buttons: OEDefaultConfigFactory.mainMenuButtons()
};

OEMainMenu.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        initiallyVisible: PropTypes.bool
    }).isRequired,
    buttons: PropTypes.shape({
        top: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            enabled: PropTypes.bool.isRequired,
        })).isRequired,
        bottom: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            enabled: PropTypes.bool.isRequired,
        })).isRequired
    }).isRequired,
    onWidthChanged: PropTypes.func
};

export default withUILevel(OEMainMenu);