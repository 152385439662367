import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEIconButton} from '../elements/oe-button';
import {OECustomCheckbox} from '../elements/oe-checkbox';
import {OELanguageType} from '../../lib/oe-types';
import OEPopover from '../oe-popover';
import OEOpenStateElementContainer from '../../lib/oe-open-state-element-container';
import {retardUpdate} from '../../lib/update-retarder';

export class OELanguagePickerCell extends React.PureComponent   {

    constructor(props)  {
        super(props);

        this.onPressed = this.onPressed.bind(this);
    }

    render()    {
        let title = this.props.strings['lang_' + Math.max(this.props.language + 1, 0).toString()];
        if(!title)  title = 'Language ' + this.props.language.toString();

        return (
            <div className={'language-picker-cell' + (this.props.disabled ? ' disabled' : '')}>
                <OECustomCheckbox
                    disabled={this.props.disabled}
                    checked={this.props.selected}
                    onPressed={this.onPressed}
                />
                <span>{title}</span>
            </div>
        );
    }

    onPressed()    {
        if(this.props.onPressed)   this.props.onPressed(this);
    }
}

OELanguagePickerCell.defaultProps = {
    disabled: false,
    selected: false,
    language: OELanguageType.english
};

OELanguagePickerCell.propTypes = {
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    strings: PropTypes.object,
    language: PropTypes.number,
    onPressed: PropTypes.func
};

export class OELanguagePickerController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.language = this.props.language;

        this.state = {
            language: this.language,
            entries: [],
            strings: {
                languages: this.getTitleStrings()
            }
        };

        this.onCellPressed = this.onCellPressed.bind(this);
    }

    setLanguage(language)   {
        if(typeof(language) !== 'number' || language === this.language)   return;
        this.language = language;
        this.setState({language: this.language});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.language !== this.props.language)   {
            this.setLanguage(nextProps.language);
        }

        if(nextProps.onlyAvailable !== this.props.onlyAvailable || nextProps.allowUnsetLanguage !== this.props.allowUnsetLanguage)    {
            this.updateEntries(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    getTitleStrings() {
        let ret = {};
        if(this.oe.isReady())  {
            let numLanguages = this.oe.sharedInterface.getUIControllerSettings().getNumLanguages();
            for(let i = -1; i < numLanguages; ++i)  {
                ret['lang_' + (i + 1).toString()] = this.oe.sharedInterface.getLanguageTitleString({value: i});
            }
        }
        return ret;
    }

    updateLanguage()    {
        this.setState({strings: {languages: this.getTitleStrings()}});
    }

    updateEntries(props) {
        props = props || this.props;
        let entries = [];
        if(this.oe.isReady())  {

            if(props.allowUnsetLanguage && !props.unsetLanguageWithDeselection)    entries.push(OELanguageType.unset);

            if(props.onlyAvailable) {
                let availableLanguages = this.oe.sharedInterface.getUIControllerSettings().getAvailableLanguages();
                entries = entries.concat(availableLanguages.map(lang => lang.value));
            } else {
                let numLanguages = this.oe.sharedInterface.getUIControllerSettings().getNumLanguages();
                for(let i = 0; i < numLanguages; ++i)  entries.push(i);
            }
        }

        this.setState({entries: entries});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateEntries();
        });
    }

    render()    {
        let cells = this.state.entries.map((entry, index) => 
            <OELanguagePickerCell
                key={index}
                disabled={this.props.disabled}
                selected={entry === this.state.language}
                strings={this.state.strings.languages}
                language={entry}
                onPressed={this.onCellPressed}
            />
        );

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'language-picker ' + this.props.className}>
                    {cells}
                </div>
            </React.Fragment>
        );
    }

    onCellPressed(cell)    {
        if(this.props.disabled) return;
        
        let language = cell.props.language;
        if(this.props.allowUnsetLanguage && this.props.unsetLanguageWithDeselection && language === this.language)  {
            language = OELanguageType.unset;
        }    

        this.onChange(language);
    }

    onChange(language)  {
        if(language === this.language)  return;

        if(this.props.onChange) {
            this.props.onChange(language);
        } else {
            this.setLanguage(language);
        }
    }
}

OELanguagePickerController.defaultProps = {
    moduleId: '',
    className: '',
    disabled: false,
    onlyAvailable: false,
    allowUnsetLanguage: false,
    unsetLanguageWithDeselection: true,
    language: OELanguageType.english
};

OELanguagePickerController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onlyAvailable: PropTypes.bool,
    allowUnsetLanguage: PropTypes.bool,
    unsetLanguageWithDeselection: PropTypes.bool,
    language: PropTypes.number,
    onChange: PropTypes.func
};

export const OELanguagePickerPopover = OEPopover.coat(OELanguagePickerController, {}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    headerSeparator: true,
    titleId: 'language_picker_view',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OELanguagePickerPopover);

const languagePickerButtonContainer = new OEOpenStateElementContainer();

export class OELanguagePickerButton extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.isOpen = false;

        this.language = this.props.language;

        this.elementRef = null;

        this.state = {
            isOpen: this.isOpen,
            language: this.props.language,
            elementRef: null
        };

        this.onElementRef = this.onElementRef.bind(this);

        this.onPressed = this.onPressed.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    setOpen(isOpen)   {
        if(this.isOpen == isOpen || (this.props.disabled && isOpen))   return;
        this.isOpen = isOpen;
        this.setState({isOpen: this.isOpen});

        if(this.props.closeOther && this.isOpen)    languagePickerButtonContainer.closeAll(this);

        if(this.isOpen && this.props.onShow)    this.props.onShow(this.language);
        if(!this.isOpen && this.props.onHide)    this.props.onHide(this.language);
    }

    setLanguage(language)   {
        if(typeof(language) !== 'number' || language === this.language)   return;
        this.language = language;
        this.setState({language: this.language});
    }

    componentWillUnmount()    {
        languagePickerButtonContainer.unregister(this);
    }

    componentDidMount()    {
        languagePickerButtonContainer.register(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.disabled)     this.setOpen(false);

        if(nextProps.language !== this.props.language)   {
            this.setLanguage(nextProps.language);
        }
    }

    onElementRef(ref)    {
        if(this.elementRef === ref) return;
        this.elementRef = ref;
        this.setState({elementRef: ref});
    }

    render()    {
        const {className, popoverClassName, language, onChange, onShow, onHide, ...rest} = this.props;

        let picker = !this.props.target && !this.state.elementRef ? null :
            <OELanguagePickerPopover
                className={popoverClassName}
                target={this.props.target ? this.props.target : this.state.elementRef}
                {...rest}
                isOpen={this.state.isOpen}
                onToggle={this.onToggle}
                language={this.state.colorHSV}
                onChange={this.onChange}
            />;

        return (
            <React.Fragment>
                <OEIconButton
                    className={'language-picker-btn ' + this.props.className}
                    disabled={this.props.disabled}
                    activated={this.props.highlightButton && this.state.isOpen}
                    icon={this.props.icon}
                    onPressed={this.onPressed}
                    elementRef={this.onElementRef}
                />
                {picker}
            </React.Fragment>
        );
    }

    onPressed() {
        if(this.props.disabled)     return;
        this.setOpen(!this.isOpen);
    }

    onToggle()  {
        this.setOpen(false);
    }

    onChange(language)   {
        if(language === this.language)  return;

        if(this.props.onChange) {
            this.props.onChange(language);
        } else {
            this.setLanguage(language);
        }
    }
}

OELanguagePickerButton.defaultProps = Object.assign({}, OELanguagePickerPopover.defaultProps, {
    popoverClassName: '',
    icon: '\uF024',
    closeOther: true,
    highlightButton: true
});

OELanguagePickerButton.propTypes = Object.assign({}, OELanguagePickerPopover.defaultProps, {
    popoverClassName: PropTypes.string,
    icon: PropTypes.string,
    closeOther: PropTypes.bool,
    highlightButton: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func
});