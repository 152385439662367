import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEStdCapBarController from '../../bars/cap/std/oe-std-cap-bar-controller';
import {OEPhilipsIcarusCustomPropKey, OEPhilipsIcarusTargetType, OEPhilipsIcarusMode, OEPhilipsIcarusModel} from './oe-philips-icarus-model';

export class OEPhilipsIcarusCapBar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.connectFlag = false;

        this.model = OEPhilipsIcarusModel.shared();
        if(!this.model.data) this.model.setData(OEPhilipsIcarusModel.stdData());

        this.mode = OEPhilipsIcarusMode.uninitialized;
        this.animationId = '';

        this.targetTypes = [{
            id: OEPhilipsIcarusTargetType.patient,
            target: this.model.getTarget(),
            label: 'Positioning',
            labelId: 'target_menu_philips_icarus_positioning'
        },
        {
            id: OEPhilipsIcarusTargetType.xRay,
            target: this.model.getTarget(),
            label: 'X-Ray',
            labelId: 'target_menu_philips_icarus_x_ray',
            disabledTooltipId: 'target_menu_philips_icarus_disabled_target_type_tooltip'
        },
        {
            id: OEPhilipsIcarusTargetType.anatomy,
            label: '3D-Anatomy',
            labelId: 'target_menu_philips_icarus_anatomy',
            disabledTooltipId: 'target_menu_philips_icarus_disabled_target_type_tooltip'
        }];

        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);

        this.onControllerRef = this.onControllerRef.bind(this);

        this.targetTypeForTarget = this.targetTypeForTarget.bind(this);
        this.isTargetTypeDisabled = this.isTargetTypeDisabled.bind(this);
        this.onTargetBtnPressed = this.onTargetBtnPressed.bind(this);
    }

    componentWillUpdate(nextProps, nextState)   {
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    updateActiveTargetType()  {
        if(this.controllerRef)    this.controllerRef.updateActiveTargetType();
    }

    updateTargetTypesEnabledState() {
        if(this.controllerRef)    this.controllerRef.updateTargetTypesEnabledState();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.connectFlag = false;
            this.updateTargetTypesEnabledState();
            return;
        }

        const si = this.oe.sharedInterface;

        if(this.props.target === this.model.getTarget())    {
            let animationId;

            if(this.lastConnectedTarget && this.lastConnectedTarget !== this.props.target && (this.mode == OEPhilipsIcarusMode.xRay || !this.model.isIdleOrNull(this.animationId)) && this.controllerRef)  {
                let anatomyTarget = this.controllerRef.getLastTargetForType(OEPhilipsIcarusTargetType.anatomy);
                animationId = this.model.getAnimationForTarget(anatomyTarget, this.animationId);
                if(animationId) this.animationId = animationId;
            }

            animationId = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.animationId).value;
            if(!this.model.isNull(this.animationId) && animationId !== this.animationId)    {
                let index = si.getUIControllerAnimation().getAnimationIndex(this.animationId);
                si.getUIControllerAnimation().setActiveAnimation(index);
                si.getUIControllerMediaCenter().setActualMediaItemResourceID(this.animationId);
            }

            let mode = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
            if(this.mode != OEPhilipsIcarusMode.uninitialized && mode !== this.mode)  {
                si.setCustomIntProperty(OEPhilipsIcarusCustomPropKey.mode, this.mode);
            }

            this.mode = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
            this.animationId = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.animationId).value;
        }

        this.lastConnectedTarget = this.props.target;

        this.connectFlag = true;

        this.updateActiveTargetType();
        this.updateTargetTypesEnabledState();
    }

    onCustomPropertyChanged(message, userInfo)  {
        if(!this.connectFlag || this.props.target !== this.model.getTarget())   return;
        if(userInfo.key === OEPhilipsIcarusCustomPropKey.mode || userInfo.key === OEPhilipsIcarusCustomPropKey.animationId)   {
            this.mode = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
            this.animationId = this.oe.sharedInterface.getCustomProperty(OEPhilipsIcarusCustomPropKey.animationId).value;
            this.updateActiveTargetType();
            this.updateTargetTypesEnabledState();
        }
    }

    onControllerRef(ref)   {
        this.controllerRef = ref;
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEStdCapBarController
                    {...this.props}
                    className="philips-icarus-cap-bar"
                    targetTypes={this.targetTypes}
                    targetTypeForTarget={this.targetTypeForTarget}
                    isTargetTypeDisabled={this.isTargetTypeDisabled}
                    onTargetBtnPressed={this.onTargetBtnPressed}
                    ref={this.onControllerRef}
                />
            </React.Fragment>
        );
    }

    targetTypeForTarget(target) {
        return this.model.getTarget() === target ? (this.mode === OEPhilipsIcarusMode.xRay ? OEPhilipsIcarusTargetType.xRay : OEPhilipsIcarusTargetType.patient) : OEPhilipsIcarusTargetType.anatomy;
    }

    isTargetTypeDisabled(targetType, sender)    {
        if(!this.connectFlag || sender.activeTargetType !== OEPhilipsIcarusTargetType.patient || !this.model.isIdleOrNull(this.animationId))   return !this.connectFlag;
        return targetType !== OEPhilipsIcarusTargetType.patient;
    }

    onTargetBtnPressed(targetType, target, sender)  {
        if(targetType.id == OEPhilipsIcarusTargetType.anatomy)  {
            let target = this.model.getTarget(this.animationId);

            if(this.model.isIdleOrNull(this.animationId) && this.controllerRef)   {
                let lastAnatomyTarget = this.controllerRef.getLastTargetForType(OEPhilipsIcarusTargetType.anatomy);
                if(lastAnatomyTarget)   target = lastAnatomyTarget;
            }

            this.props.appComponent.app.changeTarget(target);
        } else {
            let mode = targetType.id == OEPhilipsIcarusTargetType.patient ? OEPhilipsIcarusMode.std : OEPhilipsIcarusMode.xRay;
            if(sender.activeTargetType == OEPhilipsIcarusTargetType.anatomy)  {
                this.mode = mode;
                this.props.appComponent.app.changeTarget(this.model.getTarget());
            } else {
                this.oe.sharedInterface.setCustomIntProperty(OEPhilipsIcarusCustomPropKey.mode, mode);
            }
        }
    }
}

OEPhilipsIcarusCapBar.defaultProps = {
    moduleId: ''
};

OEPhilipsIcarusCapBar.propTypes = {
    moduleId: PropTypes.string
};

export default connectAppEnv((env) => { return {
    target: env.config.target,
    appComponent: env.component
}})(OEPhilipsIcarusCapBar);