import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import OEScrollbars from '../oe-scrollbars';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEResizeObserver from '../../lib/oe-resize-observer';
import OEThemeWaitingController from '../oe-theme-waiting-controller';

export default class OEPDFView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.options = clone(this.props.options);

        this.scrollTop = null;

        if(typeof(this.props.pageIndex) === 'number')   {
            this.deferredScrollTo = {pageIndex: this.props.pageIndex};
        } else if(typeof(this.props.page) === 'number')   {
            this.deferredScrollTo = {pageIndex: this.props.page - 1};
        }
        //this.deferredScrollTo = {pageIndex: 43};    // for testing deferred scrolling

        this.initialDocumentState = {
            loaded: false,
            numPages: 0,
            pages: []
        };

        this.documentState = clone(this.initialDocumentState);
        
        this.numPagesLoaded = 0;
        this.numPagesRendered = 0;

        this.layoutSize = {size: null};

        this.state = {
            documentState: clone(this.documentState),
            numPagesLoaded: this.numPagesLoaded,
            numPagesRendered: this.numPagesRendered,
            layoutSize: clone(this.layoutSize)
        };

        this.onScrollbarRef = this.onScrollbarRef.bind(this);
        this.onPageRef = this.onPageRef.bind(this);

        this.onScrollbarResize = this.onScrollbarResize.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onScrollStop = this.onScrollStop.bind(this);

        this.onLoadSuccess = this.onLoadSuccess.bind(this);
        this.onPageLoadSuccess = this.onPageLoadSuccess.bind(this);
        this.onPageRenderSuccess = this.onPageRenderSuccess.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    getDocumentState()  {
        return this.documentState;
    }

    getDocumentPageState(index)    {
        return index >= 0 && index < this.documentState.numPages ? this.documentState.pages[index] : undefined;
    }

    componentWillReceiveProps(nextProps) {
        if(OEToolbox.shallowEqual(nextProps.options, this.props.options))   {
            this.options = clone(nextProps.options);
        }

        if(nextProps.fileURL != this.props.fileURL) {
            this.scrollTop = null;
            this.deferredScrollTo = null;
            let documentStateChanged = !OEToolbox.shallowEqual(this.documentState, this.initialDocumentState);
            this.documentState = clone(this.initialDocumentState);
            this.numPagesLoaded = 0;
            this.numPagesRendered = 0;
            this.setState({
                documentState: clone(this.documentState),
                numPagesLoaded: this.numPagesLoaded,
                numPagesRendered: this.numPagesRendered
            });
            if(documentStateChanged)    this.onDocumentStateChanged();
        }

        if(nextProps.page != this.props.page) {
            this.scrollTo(nextProps.page);
        }
    }

    getPageRef(index)    {
        if(!this.documentState.loaded || !this.documentState.numPages || !this.scrollbar || !this.scrollbar.container) return;
        let pageRef = $(this.scrollbar.container).find('[data-page-number="' + (index + 1).toString() + '"]');
        return pageRef.length ? pageRef[0] : undefined;
    }

    allPagesLoaded(toIndex)    {
        if(!this.documentState.loaded) return false;
        if(this.documentState.numPages === 0)   return true;
        toIndex = typeof(toIndex) === 'number' ? Math.min(Math.max(toIndex, 0), this.documentState.numPages - 1) : (this.documentState.numPages - 1);
        for(let i = 0; i <= toIndex; ++i)   {
            if(!this.documentState.pages[i].loaded) return false;
        }
        return true;
    }

    allPagesInDOM(toIndex)    {
        if(!this.documentState.loaded) return false;
        if(this.documentState.numPages === 0)   return true;
        toIndex = typeof(toIndex) === 'number' ? Math.min(Math.max(toIndex, 0), this.documentState.numPages - 1) : (this.documentState.numPages - 1);
        for(let i = 0; i <= toIndex; ++i)   {
            if(!this.getPageRef(i)) return false;
        }
        return true;
    }

    allPagesRendered(toIndex)    {
        if(!this.documentState.loaded) return false;
        if(this.documentState.numPages === 0)   return true;
        toIndex = typeof(toIndex) === 'number' ? Math.min(Math.max(toIndex, 0), this.documentState.numPages - 1) : (this.documentState.numPages - 1);
        for(let i = 0; i <= toIndex; ++i)   {
            if(!this.documentState.pages[i].rendered) return false;
        }
        return true;
    }

    allowScrollingAfterRendered()   {
        return !this.options.dynamicPageRendering && (this.options.renderModeSVG || this.options.explicitePageWidth);
    }

    allowScrolling(toIndex)    {
        let afterAllPagesRenderer = this.allowScrollingAfterRendered();
        //return afterAllPagesRenderer ? this.allPagesRendered(toIndex) : this.allPagesLoaded(toIndex);
        return afterAllPagesRenderer ? this.allPagesRendered(toIndex) : (this.allPagesLoaded(toIndex) && this.allPagesInDOM(toIndex));
    }

    scrollTo(index, completed)    {
        let node = this.getPageRef(index);
        if(!node || !this.allowScrolling(index) || !this.scrollbar || !this.scrollbar.container)    {
            this.deferredScrollTo = {pageIndex: index, completed: completed};
            this.updatePageVisibility();
            return;
        }

        this.scrollTop = null;
        this.deferredScrollTo = null;

        let element = node;
        let offsetTop = 0;
        while(element && element != this.scrollbar.container)    {
            offsetTop += element.offsetTop;
            element = element.parentElement;
        }

        //let itemCenter = offsetTop + 0.5 * node.offsetHeight;
        //let scrollViewCenter = 0.5 * this.scrollbar.getClientHeight();
        let scrollTop = offsetTop;//itemCenter - scrollViewCenter;
        this.scrollTop = scrollTop;
        this.scrollbar.scrollTop(scrollTop, completed);
    }

    tryDeferredScrollTo()   {
        if(!this.deferredScrollTo) return;
        let deferredScrollTo = clone(this.deferredScrollTo);
        this.scrollTo(deferredScrollTo.pageIndex, deferredScrollTo.completed);
    }

    updateDocument(pdf)    {
        this.documentState.loaded = true;
        this.documentState.numPages = pdf.numPages;
        this.documentState.pages = new Array(this.documentState.numPages);
        for(let i = 0; i < this.documentState.numPages; ++i)    {
            this.documentState.pages[i] = {loaded: false, rendered: false, index: i, number: i + 1, size: {w: 0, h: 0}};
        }
        this.setState({documentState: clone(this.documentState)});
        this.onDocumentStateChanged();
    }

    updatePage(page, rendered)    {
        if(page.pageNumber > this.documentState.numPages)   return;
        let index = page.pageNumber - 1;
        let pageState = this.documentState.pages[index];
        let oldPageState = clone(pageState);
        let oldNumPagesLoaded = this.numPagesLoaded;
        let oldNumPagesRendered = this.numPagesRendered;

        let pageSet = !pageState.loaded;
        if(pageSet) {
            pageState.loaded = true;
            pageState.size = {w: page.originalWidth, h: page.originalHeight};
            this.numPagesLoaded++;
        }

        if(rendered && !pageState.rendered) {
            pageState.rendered = true;
            this.numPagesRendered++;
        }

        if(pageSet && index == 0) this.updateLayout();

        let pageStateChanged = !OEToolbox.shallowEqual(pageState, oldPageState);

        if(!pageStateChanged && this.numPagesLoaded == oldNumPagesLoaded && this.numPagesRendered == oldNumPagesRendered)    return;
        
        this.setState({
            documentState: clone(this.documentState),
            numPagesLoaded: this.numPagesLoaded,
            numPagesRendered: this.numPagesRendered
        });

        if(pageStateChanged)    this.onDocumentPageStateChanged(index);
    }

    onDocumentStateChanged()    {
        if(this.props.onDocumentStateChanged)  this.props.onDocumentStateChanged(this.onDocumentStateChanged);
    }

    onDocumentPageStateChanged(pageIndex)    {
        if(this.props.onDocumentPageStateChanged)  this.props.onDocumentPageStateChanged(pageIndex, this.documentState.pages[pageIndex]);
        this.updatePageVisibility();
    }

    determineLayoutSize()   {
        let scrollbarSize = this.scrollbarSize;

        if(!scrollbarSize)  {
            if(!this.scrollbar || !this.scrollbar.container)  return;
            scrollbarSize = {w: this.scrollbar.container.clientWidth, h: this.scrollbar.container.clientHeight};
        }

        if(scrollbarSize.w === 0 && scrollbarSize.h === 0)  return;

        let height = scrollbarSize.w * 1296 / 1920.0;

        if(this.documentState.numPages && this.documentState.pages[0].loaded)    {
            let page = this.documentState.pages[0];
            height = scrollbarSize.w * page.size.h / page.size.w;
        }

        height *= 1.05;

        return {w: scrollbarSize.w, h: height}
    }

    updateLayout()  {
        let layoutSize = this.determineLayoutSize();
        if(!layoutSize || OEToolbox.shallowEqual(layoutSize, this.layoutSize.size)) return;
        this.layoutSize.size = layoutSize;
        this.setState({layoutSize: clone(this.layoutSize)});
    }

    onScrollbarResize(sender, size) {
        if(OEToolbox.shallowEqual(size, this.scrollbarSize)) return;
        let scrollTopScale = this.scrollbarSize && this.allowScrolling() && this.scrollbarSize.w > 0 ? size.w / this.scrollbarSize.w : 1;
        this.scrollbarSize = size;
        this.updateLayout();

        if(typeof(this.scrollTop) === 'number' && scrollTopScale != 1 && this.allowScrolling() && !this.deferredScrollTo)  {
            this.scrollTop *= scrollTopScale;
            if(this.scrollbar)   this.scrollbar.scrollTop(this.scrollTop);
        }

        this.updatePageVisibility();
    }

    updatePageVisibility(pageIndex)  {
        if(!this.documentState.loaded || !this.documentState.numPages || !this.scrollbar || !this.scrollbar.container)  return;
        let scrollRect = this.scrollbar.container.getBoundingClientRect();
        let changed = false;

        let updateVisibility = function(i)  {
            let visible = false;
            
            if(!this.deferredScrollTo)   {
                let pageRef = this.getPageRef(i);
                if(pageRef)    {
                    let pageRect = pageRef.getBoundingClientRect();
                    visible = !(pageRect.bottom < scrollRect.top || pageRect.top > scrollRect.bottom || pageRect.right < scrollRect.left || pageRect.left > scrollRect.right);
                }
            } else {
                visible = i == this.deferredScrollTo.pageIndex;
            }
            
            if(this.documentState.pages[i].visible === visible) return;
            this.documentState.pages[i].visible = visible;
            changed = true;
            //console.log('Page - ' + i.toString() + ' - ' + (visible ? 'visible' : 'unvisible'));
        }.bind(this);

        if(typeof(pageIndex) === 'number')   {
            updateVisibility(pageIndex);
        } else {
            for(let i = 0; i < this.documentState.numPages; ++i)    updateVisibility(i);
        }

        let pages = clone(this.documentState.pages);

        for(let i = 0; i < this.documentState.numPages; ++i)    {
            if(!pages[i].visible)    continue;
            let start = Math.max(i - 1, 0);
            let end = Math.min(i + 2, this.documentState.numPages);

            for(let j = start; j < end; ++j)    {
                if(this.documentState.pages[j].visible) continue;
                this.documentState.pages[j].visible = true;
                changed = true;
            }
        }

        if(!changed)    return;
        this.setState({documentState: clone(this.documentState)});
    }

    onScrollbarRef(ref) {
        if(this.scrollbar === ref)   return;
        this.scrollbar = ref;
        if(this.scrollbar)   {
            this.updateLayout();
            this.tryDeferredScrollTo();
            this.updatePageVisibility();
        }
    }

    onPageRef(ref)  {
        if(!ref)    return;
        if(!this.allowScrollingAfterRendered())  this.tryDeferredScrollTo();
        let pageNumber = ref.dataset.pageNumber;
        this.updatePageVisibility(pageNumber - 1);
    }

    render() {
        const doc = this.state.documentState;
        let explicitePageWidth = this.props.options.explicitePageWidth;
        let layoutWidth = this.state.layoutSize.size ? this.state.layoutSize.size.w : undefined;

        const pageProps = this.props.options.renderModeSVG ? 
                        {loading: null, width: layoutWidth, renderMode: 'svg', onLoadSuccess: this.onPageLoadSuccess, onRenderSuccess: this.onPageRenderSuccess, inputRef: this.onPageRef} :
                        {loading: null, width: explicitePageWidth ? layoutWidth : undefined, scale: 1, renderMode: 'canvas', onLoadSuccess: this.onPageLoadSuccess, onRenderSuccess: this.onPageRenderSuccess, inputRef: this.onPageRef};

        const pageClassName = !explicitePageWidth ? 'no-explicite-page-width' : ' ';
      
        let pages = doc.pages.map((page, i) =>  {
            let pageNumber = i + 1;
            let renderMode = !this.props.options.dynamicPageRendering || (page.visible && page.loaded) ? pageProps.renderMode : 'none';
            let width = renderMode === 'none' && !explicitePageWidth && layoutWidth ? layoutWidth : pageProps.width;
            let scale = renderMode === 'canvas' && !explicitePageWidth && layoutWidth && page.loaded && page.size.w ? layoutWidth / page.size.w : pageProps.scale;

            return (
                <Page
                    key={i}
                    className={pageClassName + (pageNumber === doc.numPages ? ' last' : '') + (renderMode === 'none' ? ' render-mode-none' : '')}
                    pageNumber={pageNumber}
                    {...pageProps}
                    renderMode={renderMode}
                    width={width}
                    scale={scale}
                />
            );
        });

        let style;
        if(this.props.layoutToPageHeight && this.state.layoutSize.size)  style = {height: this.state.layoutSize.size.h};

        let numPagesReady = this.props.options.dynamicPageRendering ? this.state.numPagesLoaded : this.state.numPagesRendered;
        let showWaitingSpinner = !doc.loaded || (doc.numPages > 0 && numPagesReady < doc.numPages);
        let progress = !doc.loaded ? 0 : (doc.numPages > 0 ? numPagesReady / doc.numPages : 1);

        return (
            <div className="pdf-view">
                
                <OEScrollbars style={style} onScroll={this.onScroll} onScrollStop={this.onScrollStop} onContentResize={this.onScrollbarResize} ref={this.onScrollbarRef}>
                    <Document
                        file={this.props.fileURL}
                        loading={null}
                        onLoadSuccess={this.onLoadSuccess}
                    >
                        {pages}
                    </Document>
                </OEScrollbars>

                <OEThemeWaitingController show={showWaitingSpinner} progress={progress} />
            </div>
        );
    }
    
    onScroll(sender) {
        //console.log('onScroll');
        //if(this.scrollbar)   this.scrollTop = this.scrollbar.getScrollTop();
        this.updatePageVisibility();
    }

    onScrollStop(sender) {
        //console.log('onScrollStop');
        if(this.scrollbar)   this.scrollTop = this.scrollbar.getScrollTop();
    }

    onLoadSuccess(pdf)  {
        this.updateDocument(pdf);
    }

    onPageLoadSuccess(page)    {
        this.updatePage(page);
        this.tryDeferredScrollTo(); // element of page already in DOM and appropriate sized
    }

    onPageRenderSuccess(page)   {
        this.updatePage(page, true);
        this.tryDeferredScrollTo(); // element of page already in DOM and appropriate sized
    }
}

OEPDFView.defaultProps = {
    fileURL: '',
    layoutToPageHeight: false,
    options: {
        renderModeSVG: false,
        explicitePageWidth: true,
        dynamicPageRendering: true
    }
};

OEPDFView.propTypes = {
    fileURL: PropTypes.string,
    pageIndex: PropTypes.number,
    page: PropTypes.number,
    layoutToPageHeight: PropTypes.bool,
    options: PropTypes.shape({
        renderModeSVG: PropTypes.bool.isRequired,
        explicitePageWidth: PropTypes.bool.isRequired,
        dynamicPageRendering: PropTypes.bool.isRequired,
    }),
    onDocumentStateChanged: PropTypes.func,
    onDocumentPageStateChanged: PropTypes.func
};