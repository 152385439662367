import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {connectAppEnv} from '../../app-env';
import {withUILevel} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {UIControllerType, OEManualViewLinks} from '../../../lib/oe-types';
import {OECustomCheckbox} from '../../elements/oe-checkbox';
import OEDropdown from '../../elements/oe-dropdown';
import OEPopover from '../../oe-popover';
import {OEGroupControl, OEControl, OEToggleControl} from '../../oe-controls';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import {retardUpdate} from '../../../lib/update-retarder';

export class OESubstructureController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.classes = [];

        this.state = {
            uiEnabled: false,
            enabled: false,
            classes: this.classes,
            strings: {
                title: '',
                enableLabel: '',
                enableDye: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateControlsState = this.updateControlsState.bind(this);
        this.onSubstructureComponentClassStateChanged = this.onSubstructureComponentClassStateChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onEnabledSwitchChanged = this.onEnabledSwitchChanged.bind(this);
        this.onCategorySelected = this.onCategorySelected.bind(this);
        this.onColorCheckboxPressed = this.onColorCheckboxPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.substructureEnabledStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.substructureComponentClassStateChanged, this.onSubstructureComponentClassStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.substructureEnabledStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.substructureComponentClassStateChanged, this.onSubstructureComponentClassStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        const si = this.oe.sharedInterface;
        this.setState({strings: {
            title: si.getLocalizedStringEnc('substructure_view'),
            enableLabel: si.getLocalizedStringEnc('substructure_view_enable'),
            enableDye: si.getLocalizedStringEnc('substructure_view_enable_dye')
        }});
        this.updateClassesState();
    }

    updateControlsState()   {
        this.setState({
            enabled: this.oe.sharedInterface.getUIControllerSubstructure().getEnabled(),
        });
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerSubstructure().getUIEnabled()
        });
    }

    createClassState(classData) {
        const activeCategory = classData.activeCategory >= 0 && classData.activeCategory < classData.categories.length ? classData.categories[classData.activeCategory] : null;

        const dyeControlEnabled = ((classData.hasDyeableComponents && activeCategory && activeCategory.switch >= 0) || !classData.options.includes(this.oe.Module.SubstructureComponentClassOption.disable_dyeing_when_empty)) &&
                            (!activeCategory || !activeCategory.options.includes(this.oe.Module.SubstructureComponentClassCategoryOption.disable_dyeing));

        return {
            id: classData.id,
            dyeControlEnabled: dyeControlEnabled,
            label: classData.names.name,
            activeCategory: classData.activeCategory,
            activeCategoryID: classData.activeCategoryID,
            isDyed: classData.isDyed,
            isApplied: classData.isApplied,
            categoryDropdownTitle: activeCategory ? activeCategory.names.name : '',
            categories: classData.categories.map(categoryData => {
                return {
                    id: categoryData.id,
                    label: categoryData.names.name,
                    key: categoryData.id,
                    content: <span className="label">{categoryData.names.name}</span>
                };
            })
        };
    }

    updateClass(id)    {
        let classIndex = this.classes.findIndex(cl => cl.id === id);
        if(classIndex < 0) return;
        let newClasses = clone(this.classes);
        newClasses[classIndex] = this.createClassState(this.oe.sharedInterface.getUIControllerSubstructure().getDataID(id));
        this.classes = newClasses;
        this.setState({classes: this.classes});
    }

    updateClassesState()    {
        let classesData = this.oe.sharedInterface.getUIControllerSubstructure().getDataList();
        this.classes = classesData.map(classData => this.createClassState(classData));
        this.setState({classes: this.classes});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateControlsState();
            this.updateUIState();
        });
    }

    onSubstructureComponentClassStateChanged(message, userInfo)  {
        this.updateClass(userInfo.ID);
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.substructure) {
            this.updateUIState();
        }
    }

    render() {
        if(!this.props.config.enabled) return null;
        const disabled = !(this.state.uiEnabled && this.state.enabled);

        const classes = this.state.classes.map(cl =>
            <OEControl key={cl.id} className="class">
                <div className="head">
                    <div className="label">{cl.label}</div>
                    <div className="color-checkbox">
                        <span>{this.state.strings.enableDye}</span>
                        <OECustomCheckbox
                            disabled={disabled || !cl.dyeControlEnabled}
                            userData={cl.id}
                            checked={cl.isDyed}
                            onPressed={this.onColorCheckboxPressed}
                        />
                    </div>
                </div>
                <OEDropdown
                    className={'category-dropdown ' + (!cl.isApplied ? 'not-applied' : '')}
                    title={cl.categoryDropdownTitle}
                    disabled={disabled}
                    chosenKey={cl.activeCategoryID}
                    entries={cl.categories}
                    userData={cl.id}
                    onClick={this.onCategorySelected}
                />
            </OEControl>
        );

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control" 
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.substructure}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <div className="substructure-controller">
                        <OEGroupControl separator={true}>
                            <OEToggleControl 
                                title={this.state.strings.enableLabel}
                                active={this.state.enabled} 
                                disabled={!this.state.uiEnabled} 
                                onClick={this.onEnabledSwitchChanged} 
                            />
                        </OEGroupControl>
                        <OEGroupControl>
                            {classes}
                        </OEGroupControl>
                    </div>
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.substructure});
    }

    onEnabledSwitchChanged() {
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerSubstructure().setEnabled(!this.state.enabled);
    }

    onCategorySelected(key, classID)    {
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerSubstructure().setActiveCategoryID(classID, key);
    }

    onColorCheckboxPressed(sender, classID) {
        if(!this.oe.isReady()) return;
        this.oe.sharedInterface.getUIControllerSubstructure().setIsDyedID(classID, !sender.props.checked);
    }
}

OESubstructureController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.substructureController()
};

OESubstructureController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.substructureController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.substructure)
    }
})(withUILevel(OESubstructureController));