import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'reactstrap';
import Hammer from 'react-hammerjs';

import OEIcon from './oe-icon';

export default class OEButton extends React.PureComponent {

    constructor(props) {
        super(props);

        this.gotTooltip = !!this.activeTooltip();

        this.state = {
            tooltipOpen: false,
            gotTooltip: this.gotTooltip
        };

        this.onRef = this.onRef.bind(this);

        this.onClicked = this.onClicked.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onLongPressed = this.onLongPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let nextTooltip = this.activeTooltip(nextProps);

        if(!nextTooltip && this.activeTooltip())  this.finishTooltip();

        if(!this.gotTooltip && nextTooltip)   {
            this.gotTooltip = true;
            this.setState({gotTooltip: this.gotTooltip});
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount()  {
        this.finishTooltip();
        this.mounted = false;
    }

    activeTooltip(props)    {
        props = props || this.props;
        return !props.disabled ? props.tooltip : props.disabledTooltip;
    }

    startTooltip(instantaneous)   {
        if(!this.mounted) return;
        if((this.timer && !instantaneous) || !this.activeTooltip()) return;

        if(instantaneous)   {
            if(this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.setState({tooltipOpen: true});
        } else {
            this.timer = setTimeout(() => { this.setState({tooltipOpen: true}); this.timer = null; }, 1000);
        }
    }

    finishTooltip()   {
        if(!this.mounted) return;
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.setState({tooltipOpen: false});
    }

    onRef(ref) {
        this.setState({ref: ref});
        if(this.props.elementRef)   this.props.elementRef(ref);
    }

    render() {
        let className = this.props.className + (this.props.disabled === true ? ' disabled' : '') + (this.props.activated === true ? ' active' : ' not-active');

        let tooltip = !this.state.ref || !this.state.gotTooltip ? null :
            <Tooltip
                boundariesElement="body"
                placement={this.props.tooltipPlacement}
                isOpen={this.state.tooltipOpen}
                target={this.state.ref}
                delay={{show: 500, hide: 0}}
            >
                {this.activeTooltip()}
            </Tooltip>;

        let button = (
            <a
                id={this.props.id}
                className={'btn button oe-button ' + className}
                style={this.props.style}
                onClick={this.onClicked}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                ref={this.onRef}
            >
                {this.props.children}
                {tooltip}
            </a>
        );

        return !this.props.onLongPressed ? button :
            <Hammer
                onPress={this.onLongPressed}
                options={{
                    recognizers: {
                        press: { time: this.props.longPressTime }
                    }
                }}
            >
                {button}
            </Hammer>
        ;
    }

    onClicked(e)   {
        if(this.props.disabled && this.props.disabledTooltip)    {
            this.startTooltip(true);
        } else {
            this.finishTooltip();
        }

        if(!this.props.disabled && this.props.onPressed)    this.props.onPressed(e, this);
    }

    onMouseEnter()    {
        this.startTooltip();
    }

    onMouseLeave()    {
        this.finishTooltip();
    }

    onLongPressed(e)    {
        this.finishTooltip();
        if(!this.props.disabled && this.props.onLongPressed)    if(this.props.onLongPressed)     this.props.onLongPressed(e, this);
    }
}

OEButton.defaultProps = {
    id: '',
    className: '',
    disabled: false,
    activated: false,
    tooltipPlacement: 'right',
    longPressTime: 1500
};

OEButton.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    activated: PropTypes.bool,
    tooltipPlacement: PropTypes.string,
    onPressed: PropTypes.func,
    onLongPressed: PropTypes.func,
    elementRef: PropTypes.func,
    longPressTime: PropTypes.number
};

export class OEIconButton extends React.PureComponent {
    render() {
        const {className, icon, ...rest} = this.props;
        return (
            <OEButton className={'icon-button ' + className} {...rest}>
                <OEIcon code={this.props.icon}/>
                {this.props.children}
            </OEButton>
        );
    }
}

OEIconButton.defaultProps = Object.assign({}, OEButton.defaultProps);

OEIconButton.propTypes = Object.assign({
    icon: PropTypes.string
}, OEButton.propTypes);
