
export const OEFeatureAccessTypes = {
    none: 'none',
    full: 'full',
    licensing: 'licensing',
    isOne: function(candidate)  {
        return candidate === this.none || candidate === this.full || candidate === this.licensing;
    }
};

export const OELicensingEdition = {
    none: 'None',
    viewer: 'Viewer',
    basic: 'Basic',
    singleUser: 'Single User',
    extended: 'Extended',
    professional: 'Professional',
    branchOffice: 'Branch Office',
    master: 'Master'
};

export const OEViewType = {
    start: 'start',
    welcome: 'welcome',
    info: 'info',
    help: 'help',
    manual: 'manual',
    screenshot: 'screenshot',
    text: 'text',
    getRef: (type, appComponent) => {
        return appComponent.uiLayer[type + 'View'];
    }
};

export const UIControllerType = {
    dummy: 0, cut: 1, arrow: 2, note: 3, component: 4, substructure: 5, search_tool: 6, selection_manager: 7, selection: 8, context_menu: 9, model_state: 10, label: 11,
    presentation: 12, animation: 13, timeline: 14, media_center: 15, settings: 16, info: 17, licensing_manager: 18, highlighting_tooltip: 19, feature_access: 20, color: 21,
    explosion: 22, media: 23, audio_player: 24, preset: 25, color_theme: 26, tutor: 27, search_controller: 28
};

export const AMErrorType = {
    noError: 0,
    unexpected: 1,
    couldNotOpenFile: 2,
    fileIsCorrupt: 3,
    unsupportedAudioFormat: 4,
    unsupportedFileType: 5,
    stringTable: ['noError', 'unexpected', 'couldNotOpenFile', 'fileIsCorrupt', 'unsupportedAudioFormat', 'unsupportedFileType'],
    toString: function(type) {
        return typeof(type) !== 'number' || type < 0 || type >= this.stringTable.length ? undefined : this.stringTable[type];
    }
};

export const OELanguageType = {
    unknown: -1,
    unset: -1,
    english: 0,
    german: 1,
    chinese: 2,
    italian: 3,
    dutch: 4,
    korean: 5,
    japanese: 6,
    finnish: 7,
    portugese: 8,
    swedish: 9,
    czech: 10,
    first: -1,
    last: 10,
    codeTable: ['Unset', 'EN', 'DE', 'ZH', 'IT', 'NL', 'KO', 'JA', 'FI', 'PT', 'SV', 'CS'],
    toCode: function(type)  {
        return typeof(type) !== 'number' || type + 1 < 0 || type + 1 >= this.codeTable.length ? undefined : this.codeTable[type + 1];
    },
    fromCode: function(code)    {
        let index = this.codeTable.findIndex(c => c === code);
        return index >= 0 ? index - 1 : undefined;
    },
    sanitize: function(type, allowUnset = true, defaultType = 0) {
        if(typeof(type) !== 'number' || type < this.first || type > this.last || (!allowUnset && type === this.unset))  {
            defaultType = typeof(defaultType) === 'number' && defaultType >= this.first && defaultType <= this.last ? defaultType : this.english;
            return defaultType;
        }
        return type;
    }
};

export const OEControlState = {
    normal: 0,
    highlighted: 1,
    disabled: 2,
    selected: 4,
    focused: 8,
    MAX: 15,
    COUNT: 16
};

export const OEPresetResult = {
    ok: 0,
    uiLock: 1,
    refToVoid: 2,
    fileCorrupt: 3,
    wrongType: 4,
    unknownType: 5,
    couldNotOpenFile: 6,
    invalidType: 7,
    noPresetsFound: 8,
    unexpected: 9,
    stringTable: ['ok', 'uiLock', 'refToVoid', 'fileCorrupt', 'wrongType', 'unknownType', 'couldNotOpenFile', 'invalidType', 'noPresetsFound', 'unexpected'],
    toString: function(type) {
        return typeof(type) !== 'number' || type < 0 || type >= this.stringTable.length ? undefined : this.stringTable[type];
    }
};

export const OEPresetType = {
    unknown: 0,
    any: 1,
    dummy: 2,
    note: 3,
    camera: 4,
    cut: 5,
    color: 6,
    stringTable: ['unknown', 'any', 'dummy', 'note', 'camera', 'cut', 'color'],
    toString: function(type) {
        return typeof(type) !== 'number' || type < 0 || type >= this.stringTable.length ? undefined : this.stringTable[type];
    }
};

export const OEMainMenuStdBtnType = {
    home: 'home',
    label: 'label',
    note: 'note',
    arrow: 'arrow',
    cut: 'cut',
    search: 'search',
    tree: 'tree',
    substructure: 'substructure',
    color: 'color',
    explode: 'explode',
    presentation: 'presentation',
    mediaCenter: 'mediaCenter',
    screenshot: 'screenshot',
    info: 'info',
    help: 'help',
    settings: 'settings',
    back: 'back',
	forth: 'forth'
};

export const OEContextMenuContentType = {
    none: 'none',
    infoBox: 'infoBox',
    array: ['none', 'infoBox']
};

export const OEStartViewTypes = {
    std: 'std',
    amboss: 'amboss',
    anatomy: 'anatomy',
    zeissOpti: 'zeissOpti',
    eyeOCT: 'eyeOCT',
    cambridgeCell: 'cambridgeCell',
    ionto: 'ionto',
    implantcast: 'implantcast',
    test: 'test'
};

export const OEFNWelcomeEntryTypes = {
    text: 'text',
    menu: 'menu',
    home: 'home',
    licensing: 'licensing',
    mediaCenter : 'mediaCenter',
    help : 'help',
    quickStart: 'quickStart',
    manual: 'manual',
    spacer: 'spacer',
    dontShowCheckbox: 'dontShowCheckbox'
};

export const OEHelpViewTypes = {
    std: 'std',
    start: 'start',
    manual: 'manual'
};

export const OEHelpViewEntryType = {
    advice: 'advice',
    manual: 'manual',
    quickStart: 'quickStart',
    videoTutorials: 'videoTutorials',
    tutorReset: 'tutorReset',
    credits: 'credits',
    feedback: 'feedback'
};

export const OEManualViewLinks = {
    app: 'app',
    welcome: 'welcome',
    controls: 'controls',
    functions: 'functions',
    cut: 'cut',
    arrow: 'arrow',
    note: 'note',
    component: 'component',
    substructure: 'substructure',
    search: 'search',
    selection: 'selection',
    contextMenu: 'contextMenu',
    modelState: 'modelState',
    label: 'label',
    presentation: 'presentation',
    screenshot: 'screenshot',
    mediaCenter: 'mediaCenter',
    settings: 'settings'
};

export const OEManualViewLinkType = {
    none: 'none',
    youtube: 'youtube',
    pdf: 'pdf'
};

export const OECutControllerStyle = {
    std: 'std',
    minimal: 'minimal'
};

export const OEFeature = {
    tree: 0, substructure: 1, color: 2, explosion: 3, cut: 4, arrows: 5, notes: 6, labels: 7, search: 8, selection: 9, context_menu: 10, presentation: 11, play_pres: 12, edit_pres: 13, media: 14, animations: 15, state_collection: 16, tooltip: 17, highlight: 18, screenshot: 19
};

// module independent ui level representation  
export const UILevel = {
    std: 'std',
    simple: 'simple',
    advanced: 'advanced',
    levelFromModuleValue: function(value) {
        return value == 2 ? UILevel.advanced : (value == 1 ? UILevel.simple : UILevel.std);
    },
    moduleValueFromLevel: function(level)   {
        return level === UILevel.advanced ? 2 : (level === UILevel.simple ? 1 : 0);
    }
};

// OESettingsControllerDefs
export const OESettingsControllerDefs = {};

OESettingsControllerDefs.controlGroupTypes = {
    language: 'language',
    background: 'background',
    inputSensitivity: 'input_sensitivity',
    graphics: 'graphics',
    ui: 'ui',
    tutor: 'tutor'
};

OESettingsControllerDefs.controlTypes = {
    language: 'language',
    languageSwitch: 'language_switch',
    vignette: 'vignette',
    backgroundColor: 'backgroundColor',
    vpGizmo: 'vp_gizmo',
    touchSensitivity: 'touch_sensitivity',
    ambientOcclusion: 'ambient_occlusion',
    rendererPreset: 'renderer_preset',
    uiLevel: 'ui_level',
    compHighlightingVP: 'comp_highlighting_vp',
    compHighlightingVPColor: 'comp_highlighting_vp_color',
    compHighlightingLabels: 'comp_highlighting_labels',
    tutorReset: 'tutor_reset'
};

OESettingsControllerDefs.defaultLanguageControl = {
    type: OESettingsControllerDefs.controlGroupTypes.language,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.language, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.languageSwitch, enabled: true}
    ]
};

OESettingsControllerDefs.defaultBackgroundControl = {
    type: OESettingsControllerDefs.controlGroupTypes.background,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.vignette, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.backgroundColor, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.vpGizmo, enabled: true}
    ]
};

OESettingsControllerDefs.defaultInputSensitivityControl = {
    type: OESettingsControllerDefs.controlGroupTypes.inputSensitivity,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.touchSensitivity, enabled: true}
    ]
};

OESettingsControllerDefs.defaultGraphicsControl = {
    type: OESettingsControllerDefs.controlGroupTypes.graphics,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.ambientOcclusion, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.compHighlightingVP, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.compHighlightingVPColor, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.compHighlightingLabels, enabled: true},
        {type: OESettingsControllerDefs.controlTypes.rendererPreset, enabled: true}
    ]
};

OESettingsControllerDefs.uiControlSimple = {
    type: OESettingsControllerDefs.controlGroupTypes.ui,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.uiLevel, enabled: true, data: [UILevel.simple, UILevel.std]}
    ]
};

OESettingsControllerDefs.uiControlAdvanced = {
    type: OESettingsControllerDefs.controlGroupTypes.ui,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.uiLevel, enabled: true, data: [UILevel.std, UILevel.advanced]}
    ]
};

OESettingsControllerDefs.uiControlSimpleAdvanced = {
    type: OESettingsControllerDefs.controlGroupTypes.ui,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.uiLevel, enabled: true, data: [UILevel.simple, UILevel.std, UILevel.advanced]}
    ]
};

OESettingsControllerDefs.defaultTutorControl = {
    type: OESettingsControllerDefs.controlGroupTypes.tutor,
    controls: [
        {type: OESettingsControllerDefs.controlTypes.tutorReset, enabled: true}
    ]
};

//
export const OEMainMenuControlBarEntryTypes = {
    mainMenuControlButton: 'main_menu_control_button',
    logo: 'logo',
    targetMenu: 'target_menu'
};

export const OERightTopBarEntryTypes = {
    targetMenu: 'target_menu'
};