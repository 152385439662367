import React from 'react';
import PropTypes from 'prop-types';

import OEResizeObserver from '../../lib/oe-resize-observer';

export default class OEMultiColumnContainer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            width: 'initial'
        };

        this.onWindowResized = this.onWindowResized.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onRef = this.onRef.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.maxHeight !== this.props.maxHeight)     {
            this.updateMaxHeight(); 
        }
    }
    
    componentDidMount()    {
        this.onWindowResized();
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
    }

    updateWidth()   {
        if(!this.ref) return;

        let left = 0, right = 0;
        let b = false;
        for(let i = 0; i < this.ref.children.length; i++) {
            let child = this.ref.children[i];
            if(child.tagName == 'IFRAME') continue;
            let rect = child.getBoundingClientRect();
            left = !b ? rect.left : Math.min(left, rect.left);
            right = !b ? rect.right : Math.max(right, rect.right);
            b = true;
        }

        let width = right - left;
        if(this.width === width) return;
        this.width = width;

        this.setState({width: width})
    }

    computeMaxHeight() {
        let type = typeof(this.props.maxHeight);
        if(type === 'number')  {
            return this.props.maxHeight; 
        } else if(type === 'object')  {
            let height;
            if(typeof(this.props.maxHeight.absolute) === 'number')  {
                height = this.props.maxHeight.absolute;
            }
            if(typeof(this.props.maxHeight.relative) === 'number')  {
                let height_ = this.props.maxHeight.relative * window.innerHeight;
                height = typeof(height) !== 'number' ? height_ : Math.min(height, height_);
            }
            return height;
        }
    }

    updateMaxHeight()   {
        let maxHeight = this.computeMaxHeight();
        if(this.maxHeight === maxHeight) return;
        this.maxHeight = maxHeight;
        this.setState({maxHeight: this.maxHeight})
    }

    onWindowResized()   {
        this.updateMaxHeight();
    }

    onResize(sender, size) {
        this.updateWidth();
        if(this.props.onResize) this.props.onResize(this, size);
    }

    onRef(ref)  {
        this.ref = ref;
        this.updateWidth();
    }

    render()    {
        let style = {};
        if(this.state.width !== 'initial')  style.width = this.state.width;
        if(this.state.maxHeight)  style.maxHeight = this.state.maxHeight;

        return (
            <div
                className={'multi-column-container ' + this.props.className}
                style={style}
                ref={this.onRef}
            >
                <OEResizeObserver onResize={this.onResize} />
                {this.props.children}
            </div>
        );
    }
}

OEMultiColumnContainer.defaultProps = {
    className: '',
    maxHeight: {relative: 0.85}
};

OEMultiColumnContainer.propTypes = {
    className: PropTypes.string,
    maxHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            absolute: PropTypes.number,
            relative: PropTypes.number
        })
    ])
};