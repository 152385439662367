import {OEToolbox} from './oe-toolbox';

export default class UpdateRetarder    {

    constructor(component) {
        this.component = component;
        this.inititated = false;
        this.initiate();
    }

    initiate()  {
        if(this.inititated) return;

        this.shouldUpdateBackup = this.component.shouldComponentUpdate;
        this.shouldUpdate = {value: false};
        var shouldUpdate = shouldUpdate;

        if(this.shouldUpdateBackup)  {
            const shouldUpdateBackupBound = this.shouldUpdateBackup.bind(this.component);
            this.component.shouldComponentUpdate = function(nextProps, nextState) {
                shouldUpdate.value = shouldUpdate.value || shouldUpdateBackupBound(nextProps, nextState);
                return false;
            };
        } else if(component.isPureReactComponent)  {
            this.component.shouldComponentUpdate = function(nextProps, nextState) {
                shouldUpdate.value = shouldUpdate.value || !OEToolbox.shallowEqual(nextProps, component.props) || !OEToolbox.shallowEqual(nextState, component.state);
                return false;
            };
        } else {
            this.component.shouldComponentUpdate = function() {
                shouldUpdate.value = true;
                return false;
            };
        }

        this.inititated = true;
    }

    finish()    {
        if(!this.inititated) return;
        this.component.shouldComponentUpdate = this.shouldUpdateBackup;
        if(this.shouldUpdate.value) this.component.forceUpdate();
        this.inititated = false;
    }
}

export function retardUpdate(component, fn)    {
    
    const shouldUpdateBackup = component.shouldComponentUpdate;
    const shouldUpdate = {value: false};

    if(shouldUpdateBackup)  {
        const shouldUpdateBackupBound = shouldUpdateBackup.bind(component);
        component.shouldComponentUpdate = function(nextProps, nextState) {
            shouldUpdate.value = shouldUpdate.value || shouldUpdateBackupBound(nextProps, nextState);
            return false;
        };
    } else if(component.isPureReactComponent)  {
        component.shouldComponentUpdate = function(nextProps, nextState) {
            shouldUpdate.value = shouldUpdate.value || !OEToolbox.shallowEqual(nextProps, component.props) || !OEToolbox.shallowEqual(nextState, component.state);
            return false;
        };
    } else {
        component.shouldComponentUpdate = function() {
            shouldUpdate.value = true;
            return false;
        };
    }

    fn();

    component.shouldComponentUpdate = shouldUpdateBackup;
    if(shouldUpdate.value) component.forceUpdate();
} 