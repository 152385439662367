import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEMediaCenterItemCollection from './oe-media-center-item-collection';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEMediaCenterItemCollectionController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.categoryItems = [];

        this.state = {
            categoryMediaItems: [],
            actualCategoryMediaItemIndex: -1
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);
        
        this.onActualMediaCenterCategoryChanged = this.onActualMediaCenterCategoryChanged.bind(this);
        this.onAddedToActualMediaCenterCategoryMediaItems = this.onAddedToActualMediaCenterCategoryMediaItems.bind(this);
        this.onRemovedFromActualMediaCenterCategoryMediaItems = this.onRemovedFromActualMediaCenterCategoryMediaItems.bind(this);
        this.onActualMediaCenterCategoryMediaItemMoved = this.onActualMediaCenterCategoryMediaItemMoved.bind(this);
        this.onActualMediaCenterItemChanged = this.onActualMediaCenterItemChanged.bind(this);
        this.onActualMediaCenterSubItemChanged = this.onActualMediaCenterSubItemChanged.bind(this);
        
        this.onCategoryMediaItemClicked = this.onCategoryMediaItemClicked.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterCategoryChanged, this.onActualMediaCenterCategoryChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.addedToActualMediaCenterCategoryMediaItems, this.onAddedToActualMediaCenterCategoryMediaItems);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.removedFromActualMediaCenterCategoryMediaItems, this.onRemovedFromActualMediaCenterCategoryMediaItems);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterCategoryMediaItemMoved, this.onActualMediaCenterCategoryMediaItemMoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterItemChanged, this.onActualMediaCenterItemChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterSubItemChanged, this.onActualMediaCenterSubItemChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterCategoryChanged, this.onActualMediaCenterCategoryChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.addedToActualMediaCenterCategoryMediaItems, this.onAddedToActualMediaCenterCategoryMediaItems);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.removedFromActualMediaCenterCategoryMediaItems, this.onRemovedFromActualMediaCenterCategoryMediaItems);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterCategoryMediaItemMoved, this.onActualMediaCenterCategoryMediaItemMoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterItemChanged, this.onActualMediaCenterItemChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterSubItemChanged, this.onActualMediaCenterSubItemChanged);
    }

    updateCategoryItems()  {

        var controller = this.oe.sharedInterface.getUIControllerMediaCenter();

        this.categoryItems = controller.getActualCategoryMediaItems();

        var categoryMediaItems = [];

        for (var i = 0; i < this.categoryItems.length; i++) {
            var itemId = this.categoryItems[i];
            var item = controller.mediaItemID(itemId.item, itemId.subItem);
            if(item !== null)   {
                categoryMediaItems.push(item);
            }
        }

        this.setState({categoryMediaItems: categoryMediaItems});

        this.updateActualCategoryMediaItemIndex();
    }

    updateActualCategoryMediaItemIndex()    {
        var controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        var index = controller.getActualMediaItemCategoryIndex();

        if(index >= this.categoryItems.length)   index = -1;

        this.setState({actualCategoryMediaItemIndex: index});
    }

    updateActualMediaCenterItem()   {
        this.updateActualMediaCenterSubItem();
    }

    updateActualMediaCenterSubItem()    {
        this.updateActualCategoryMediaItemIndex();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.categoryItems = [];
            this.setState({
                categoryMediaItems: [],
                actualCategoryMediaItemIndex: -1,
            });
            return;
        }

        retardUpdate(this, () => {
            this.updateCategoryItems();
        });
    }

    onActualMediaCenterCategoryChanged(message, userInfo)    {
        this.updateCategoryItems();
    }

    onAddedToActualMediaCenterCategoryMediaItems(message, userInfo)    {
        this.updateCategoryItems();
    }

    onRemovedFromActualMediaCenterCategoryMediaItems(message, userInfo)    {
        this.updateCategoryItems();
    }

    onActualMediaCenterCategoryMediaItemMoved(message, userInfo)    {
        this.updateCategoryItems();
    }

    onActualMediaCenterItemChanged(message, userInfo)    {
        this.updateActualMediaCenterItem();
    }

    onActualMediaCenterSubItemChanged(message, userInfo)    {
        this.updateActualMediaCenterSubItem();
    }

    render() {
        var enabled = this.state.uiEnabled && this.state.enabled;

        return (
            <OEMediaCenterItemCollection
                className={this.props.className}
                style={this.props.style}
                itemClassName={this.props.itemClassName}
                itemStyle={this.props.itemStyle}
                moduleId={this.props.moduleId}
                config={this.props.config}
                enabled={this.props.enabled}
                items={this.state.categoryMediaItems}
                selectedItemIndex={this.state.actualCategoryMediaItemIndex}
                onClick={this.onCategoryMediaItemClicked}
            />
        );
    }

    onCategoryMediaItemClicked(index, id, subId)   {
        if(this.oe.isReady() && (typeof(subId) === 'undefined' || subId === null || id != this.oe.sharedInterface.getUIControllerMediaCenter().getActualMediaItemID())) {
            this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItemByCategoryIndex(index);
            if(typeof(this.props.onSelected) === 'function') this.props.onSelected(index, id, subId);
        }

        this.props.onClick(index, id, subId);
    }
}

OEMediaCenterItemCollectionController.defaultProps = {
    className: '',
    moduleId: '',
    enabled: true,
    onClick: (index, id) => {},
    config: {
        usePresItems: true,
        showDurationInSlidesForPresWithoutAudio: false
    }
};

OEMediaCenterItemCollectionController.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    onClick: PropTypes.func,
    config: PropTypes.shape({
        usePresItems: PropTypes.bool,
        showDurationInSlidesForPresWithoutAudio: PropTypes.bool
    })
};