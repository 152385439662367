import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInfoViewItemView from './oe-info-view-item-view';
import {OEToolbox} from '../../lib/oe-toolbox';

export default class OEInfoViewItemHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                title: 'EFFIGOS EXPLORER',
                version: OEToolbox.versionString() //'Version: 0.0.1'
            }
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);  
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {

        var preHeader = this.oe.sharedInterface.getLocalizedStringEnc('info_view_pre_header')
        var header = this.oe.sharedInterface.getLocalizedStringEnc('info_view_header')

        this.setState({ strings: {
            title: {header: header, preHeader: preHeader},
            version: OEToolbox.versionString() //this.oe.sharedInterface.getLocalizedStringEnc('info_view_version')
        }}); 
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
        
        this.updateLanguage();
    }

    render() {

        var title;
        if(typeof(this.state.strings.title) === 'string')  {
            title = (<h5>{this.state.strings.title}</h5>);
        } else {
            if(this.state.strings.title.preHeader !== '#null') {
                title = (<h5>{this.state.strings.title.preHeader}<br/>{this.state.strings.title.header}</h5>);
            } else {
                title = (<h5>{this.state.strings.title.header}</h5>);
            }
        }

        return (
            <OEInfoViewItemView
                moduleId={this.props.moduleId}
                elementRef={this.props.elementRef}
                isMaximizeable={false}
                isMaximized={this.props.isMaximized}
                hasSeparator={this.props.hasSeparator}
                className={'ivi-header' + (!this.props.indentForLogo ? ' no-logo' : '')}
                onToggle={this.props.onToggle}
                onAnimFinished={this.props.onAnimFinished}
            >
                <div className="title-label">
                    {title}
                </div>

                <div className="version-label">
                    <span>{this.state.strings.version}</span>
                </div>

            </OEInfoViewItemView>
        );
    }
}

OEInfoViewItemHeader.defaultProps = {
    moduleId: '',
    elementRef: '',
    isMaximized: false,
    hasSeparator: false,
    indentForLogo: true
};

OEInfoViewItemHeader.propTypes = {
    moduleId: PropTypes.string,
    isMaximized: PropTypes.bool,
    hasSeparator: PropTypes.bool
};