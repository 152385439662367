import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {withUILevel} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {UIControllerType, OEManualViewLinks} from '../../../lib/oe-types';
import {OEComponentTree} from './oe-component-tree';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import {OEComponentSearchFlags, OEComponentSearchControl} from './oe-component-search-control';
import OEWidgetHeader from '../../elements/oe-widget-header';
import {retardUpdate} from '../../../lib/update-retarder';

export class OEComponentController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.props.appComponent.componentController = this;

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.connector = null;
        this.componentFilter = null;

        this.onComponentsRenderModeChanged = this.onComponentsRenderModeChanged.bind(this);
        this.onComponentsColorChanged = this.onComponentsColorChanged.bind(this);
        this.onComponentsColorPurityChanged = this.onComponentsColorPurityChanged.bind(this);
        this.onComponentsSwitchStateChanged = this.onComponentsSwitchStateChanged.bind(this);
        this.onComponentsActivatedStateChanged = this.onComponentsActivatedStateChanged.bind(this);
        this.onComponentsIsHiddenStateChanged = this.onComponentsIsHiddenStateChanged.bind(this);
        this.onComponentsLockStateChanged = this.onComponentsLockStateChanged.bind(this);
        this.onComponentsCategoriesChanged = this.onComponentsCategoriesChanged.bind(this);
        this.onComponentsActiveCategoriesChanged = this.onComponentsActiveCategoriesChanged.bind(this);
        this.onComponentsCategoryEnabledStateChanged = this.onComponentsCategoryEnabledStateChanged.bind(this);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.onIsWorkingColorChanged = this.onIsWorkingColorChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onSearchControlRef = this.onSearchControlRef.bind(this);
        this.onTreeRef = this.onTreeRef.bind(this);

        this.onSearchResultChanged = this.onSearchResultChanged.bind(this);
        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onColorPickerShow = this.onColorPickerShow.bind(this);
        this.onColorPickerChange = this.onColorPickerChange.bind(this);
        this.onColorPickerAlphaChange = this.onColorPickerAlphaChange.bind(this);
        this.onColorPickerHide = this.onColorPickerHide.bind(this);
        this.onRenderModeBtnPressed = this.onRenderModeBtnPressed.bind(this);
        this.onActivationBtnPressed = this.onActivationBtnPressed.bind(this);
        this.onSwitchBtnPressed = this.onSwitchBtnPressed.bind(this);
        this.onCatSwitchBtnPressed = this.onCatSwitchBtnPressed.bind(this);
        this.onCatActivationBtnPressed = this.onCatActivationBtnPressed.bind(this);

        this.state = {
            extra: {
                moduleId: this.props.moduleId,
                oe: this.oe,
                uiEnabled: false,
                isWorkingColor: false,
                devFeatures: this.props.devFeatures,
                config: this.props.config,
                boundariesElement: this.props.boundariesElement,
                onClick: this.onClick,
                onMouseOver: this.onMouseOver,
                onMouseOut: this.onMouseOut,
                onColorPickerShow: this.onColorPickerShow,
                onColorPickerChange: this.onColorPickerChange,
                onColorPickerAlphaChange: this.onColorPickerAlphaChange,
                onColorPickerHide: this.onColorPickerHide,
                onRenderModeBtnPressed: this.onRenderModeBtnPressed,
                onActivationBtnPressed: this.onActivationBtnPressed,
                onSwitchBtnPressed: this.onSwitchBtnPressed,
                onCatSwitchBtnPressed: this.onCatSwitchBtnPressed,
                onCatActivationBtnPressed: this.onCatActivationBtnPressed
            },
            connector: null
        };
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.moduleId != this.props.moduleId) {
            this.setStateUpdate({ extra: { moduleId: {$set: nextProps.moduleId} } });
        }

        if(nextProps.devFeatures != this.props.devFeatures) {
            this.setStateUpdate({ extra: { devFeatures: {$set: nextProps.devFeatures} } });
        }

        if(!OEToolbox.jsonEqual(nextProps.config, this.props.config))     {
            if(!nextProps.config.searchBar) this.setComponentsFilter(null);
            this.setStateUpdate({ extra: { config: {$set: nextProps.config} } });
        }

        if(nextProps.boundariesElement != this.props.boundariesElement) {
            this.setStateUpdate({ extra: { boundariesElement: {$set: nextProps.boundariesElement} } });
        }
    }
    
    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsRenderModeChanged, this.onComponentsRenderModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsColorChanged, this.onComponentsColorChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsColorPurityChanged, this.onComponentsColorPurityChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsIsHiddenStateChanged, this.onComponentsIsHiddenStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsSwitchStateChanged, this.onComponentsSwitchStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsActivatedStateChanged, this.onComponentsActivatedStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsLockStateChanged, this.onComponentsLockStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsCategoriesChanged, this.onComponentsCategoriesChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsActiveCategoriesChanged, this.onComponentsActiveCategoriesChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsCategoryEnabledStateChanged, this.onComponentsCategoryEnabledStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged); 
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.isWorkingColorChanged, this.onIsWorkingColorChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsRenderModeChanged, this.onComponentsRenderModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsColorChanged, this.onComponentsColorChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsColorPurityChanged, this.onComponentsColorPurityChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsIsHiddenStateChanged, this.onComponentsIsHiddenStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsSwitchStateChanged, this.onComponentsSwitchStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsActivatedStateChanged, this.onComponentsActivatedStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsLockStateChanged, this.onComponentsLockStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsCategoriesChanged, this.onComponentsCategoriesChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsActiveCategoriesChanged, this.onComponentsActiveCategoriesChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsCategoryEnabledStateChanged, this.onComponentsCategoryEnabledStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged); 
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.isWorkingColorChanged, this.onIsWorkingColorChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    scrollTo(id, completed)    {
        if(!this.treeRef) return;
        this.treeRef.scrollTo(id, completed);
    }

    findComponent(id)   {
        if(!this.connector)  return;

        // reset filtering in this.connector without updating state
        this.setComponentsFilter(null, true);

        // reset search string in search control
        if(this.searchControlRef)   this.searchControlRef.resetSearchString();

        // expand connector to id
        this.connector.expandTo(id);

        // update state
        this.updateConnectorState();

        // scrolling
        setTimeout(() => {
            this.scrollTo(id, () => {
                if(!this.treeRef) return;
                let node = this.treeRef.getNodeRef(id);
                if(!node) return;
                node.startHighlighting();
            });
        }, 350);
    }

    setComponentsFilter(filter, withoutUpdate)    {
        if(this.componentFilter === filter) return; // no real element wise compare yet, since it seems that OEToolbox.jsEqual does not work for Sets, but at least we compare against trivial cases, especially null === null optimises findComponent
        this.componentFilter = filter;
        this.updateComponentsFiltering(withoutUpdate);
    }

    updateComponentsFiltering(withoutUpdate)  {
        if(this.connector)  {
            if(!this.componentFilter)  {
                this.connector.iterate(con => { con.filtered = false; });
            } else {
                this.connector.iterateInv(con => {
                    let oneChildNotFiltered = false;
                    for(let i = 0; i < con.childs.length; ++i)  {
                        if(!con.childs[i].filtered) {
                            oneChildNotFiltered = true;
                            break;
                        }
                    }
                    con.isInFilter = this.componentFilter.has(con.id);
                    con.filtered = !(oneChildNotFiltered || con.isInFilter);
                });
                this.connector.iterate(con => {
                    if(con.parent && con.parent.isInFilter) {
                        con.isInFilter = true;
                        con.filtered = false;
                    }
                });
                this.connector.childs.forEach(con => {
                    if(con.isPrincipal() && con.parent && !con.parent.parent)   {  // keep first level principal nodes)
                        con.isInFilter = true;
                        con.filtered = false;
                    }
                });
            }
        }
        if(!withoutUpdate) this.updateConnectorState();
    }

    updateConnectorNumRemoteSwitch()    {
        let component = this.oe.sharedInterface.getUIControllerComponent();
        this.connector.iterate(con => {
            if(con.isRemoteSwitch())    con.numRemoteSwitchStates = component.getComponentNumRemoteSwitchStates(con.id);
        });
    }

    updateConnector()    {
        this.oe.sharedInterface.getUIControllerComponent().updateComponentTreeStates(this.connector);
    }

    updateConnectorState()   {
        if(this.connector === null)  {
            this.setState({connector: null});
        } else {
            this.setState({connector: this.connector.deepCopyFiltered()});
        }
    }

    updateUIState()    {
        let component = this.oe.sharedInterface.getUIControllerComponent();
        this.setStateUpdate({
            extra: {
                uiEnabled: {$set: component.getUIEnabled()}
            }
        });
    }

    updateState_(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.connector = null;
            this.componentFilter = null;
            this.updateConnectorState();

            this.setStateUpdate({
                extra: {
                    isWorkingColor: {$set: false},
                    uiEnabled: {$set: false}
                }
            });

            return;
        }

        this.setStateUpdate({extra: { oe: {$set: this.oe} } });

        let component = this.oe.sharedInterface.getUIControllerComponent();
        let color = this.oe.sharedInterface.getUIControllerColor();
        this.connector = component.getComponentTree();
        this.updateConnector();
        this.updateComponentsFiltering();

        this.setStateUpdate({
            extra: {
                isWorkingColor: {$set: color.getIsWorkingColor()},
                uiEnabled: {$set: component.getUIEnabled()}
            }
        });
    }

    updateState(released)   {
        retardUpdate(this, () => {
            this.updateState_(released)
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.component) {
            this.updateUIState();
        }
    }

    onComponentsRenderModeChanged()  {
        //let component = this.oe.sharedInterface.getUIControllerComponent();
        //this.connector.iterate(con => { con.renderMode = component.getComponentRenderMode(con.id); });
        this.updateConnector();
        this.updateConnectorState();
    }

    onComponentsColorChanged()  {
        //let component = this.oe.sharedInterface.getUIControllerComponent();
        //this.connector.iterate(con => { con.color = component.getComponentColor(con.id); });
        this.updateConnector();
        this.updateConnectorState();
    }

    onComponentsColorPurityChanged()    {
        //let component = this.oe.sharedInterface.getUIControllerComponent();
        //this.connector.iterate(con => { con.colorPurity = component.getComponentColorPurity(con.id); });
        this.updateConnector();
        this.updateConnectorState();
    }

    onComponentsIsHiddenStateChanged()  {
        this.updateConnector();
        this.updateConnectorNumRemoteSwitch();
        this.updateConnectorState();
    }

    onComponentsSwitchStateChanged()  {
        /*
        let component = this.oe.sharedInterface.getUIControllerComponent();
        this.connector.iterate(con => {
            con.switchState = component.getComponentSwitch(con.id);

            con.renderMode = component.getComponentRenderMode(con.id);
            con.color = component.getComponentColor(con.id);
            con.colorPurity = component.getComponentColorPurity(con.id);

            if(con.isRemoteSwitch())    {
                con.remoteSwitchState = component.getComponentRemoteSwitch(con.id);
                con.numRemoteSwitchStates = component.getComponentNumRemoteSwitchStates(con.id);
            }

            con.isPureSubstructure = component.getComponentIsPureSubstructure(con.id);

        });*/
        this.updateConnector();
        this.updateConnectorNumRemoteSwitch();
        this.updateConnectorState();
    }

    onComponentsActivatedStateChanged()  {
        /*
        let component = this.oe.sharedInterface.getUIControllerComponent();
        this.connector.iterate(con => {
            con.activated = component.getComponentActivated(con.id);

            con.renderMode = component.getComponentRenderMode(con.id);
            con.color = component.getComponentColor(con.id);
            con.colorPurity = component.getComponentColorPurity(con.id);

            if(con.isRemoteSwitch())    {
                con.remoteSwitchState = component.getComponentRemoteSwitch(con.id);
                con.numRemoteSwitchStates = component.getComponentNumRemoteSwitchStates(con.id);
            }

            con.isPureSubstructure = component.getComponentIsPureSubstructure(con.id);
        });
        this.updateConnectorState();
        */
        this.updateConnector();
        this.updateConnectorNumRemoteSwitch();
        this.updateConnectorState();
    }

    onComponentsLockStateChanged()  {
        //let component = this.oe.sharedInterface.getUIControllerComponent();
        //this.connector.iterate(con => { con.lock = component.getComponentLock(con.id); });
        this.updateConnector();
        this.updateConnectorState();
    }

    onComponentsCategoriesChanged()   {
        /*
        let component = this.oe.sharedInterface.getUIControllerComponent();
        this.connector.iterate(con => {
            con.categories = component.getComponentCategories(con.id);
            con.categoriesSwitch = component.getComponentCategoriesSwitch(con.id);
            con.categoriesActivation = component.getComponentCategoriesActivation(con.id);
        });
        */
        this.updateConnector();
        this.updateConnectorState();
    }

    onComponentsActiveCategoriesChanged()   {
        //let component = this.oe.sharedInterface.getUIControllerComponent();
        //this.connector.iterate(con => { con.activeCategories = component.getComponentActiveCategories(con.id); });
        this.updateConnector();
        this.updateConnectorState();
    }

    onComponentsCategoryEnabledStateChanged()   {
        /*
        let component = this.oe.sharedInterface.getUIControllerComponent();
        this.connector.iterate(con => {
            con.categoryEnabled = component.getComponentCategoryEnabled(con.id);
            
            con.renderMode = component.getComponentRenderMode(con.id);
            con.color = component.getComponentColor(con.id);
            con.colorPurity = component.getComponentColorPurity(con.id);
            
            if(con.isRemoteSwitch())    {
                con.remoteSwitchState = component.getComponentRemoteSwitch(con.id);
                con.numRemoteSwitchStates = component.getComponentNumRemoteSwitchStates(con.id);
            }

            con.isPureSubstructure = component.getComponentIsPureSubstructure(con.id);
        });
        */
       this.updateConnector();
       this.updateConnectorNumRemoteSwitch();
       this.updateConnectorState();
    }

    onLanguageChanged() {
        this.oe.sharedInterface.getUIControllerComponent().updateComponentTreeNames(this.connector);
        this.updateConnectorState();
    }

    onIsWorkingColorChanged(message, userInfo)   {
        this.setStateUpdate({
            extra: {
                isWorkingColor: {$set: userInfo.isWorkingColor}
            }
        });
    }

    onSearchControlRef(ref) {
        this.searchControlRef = ref;
    }

    onTreeRef(ref) {
        this.treeRef = ref;
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="component-controller">
                    <OEWidgetHeader
                        className="std-bg popover-control"
                        moduleId={this.props.moduleId}
                        buttonClassName="transparent-btn"
                        uiControllerType={UIControllerType.component}
                        onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                    />
                    <div className="std-toolbar-bg body">
                        {!this.props.config.searchBar ? null :
                        <OEComponentSearchControl
                            ref={this.onSearchControlRef}
                            moduleId={this.props.moduleId}
                            options={{
                                autoReset: true,
                                limit: 0,
                                flags: OEComponentSearchFlags.enabled | OEComponentSearchFlags.reachable | OEComponentSearchFlags.categoryEnabled | OEComponentSearchFlags.notHidden | OEComponentSearchFlags.matchAllWords
                            }}
                            onSearchResultChanged={this.onSearchResultChanged}
                        />
                        }
                        <OEComponentTree
                            ref={this.onTreeRef}
                            connector={this.state.connector}
                            extra={this.state.extra}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    postNotificationWithId(name, id, userInfo_)  {
        let userInfo = userInfo_ || new this.oe.Module.Dictionary();
        userInfo.setStringValue('ID', id);
        this.oe.sharedInterface.postNotification(name, userInfo);
        userInfo.delete();
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.component});
    }

    onSearchResultChanged(sender, result, searchString) {
        this.setComponentsFilter(searchString === '' ? null : new Set(result.map(entry => entry.id)));
    }

    onClick(id) {
        this.postNotificationWithId(this.oe.NotificationName.uiInstanceCompTreeClick, id);

        let con = this.connector.find(id);
        if(con && con.isCollapsable() && con.childs.length > 0 && con.activated)    {
            con.collapsed = !con.collapsed;
            this.updateConnectorState();

            this.postNotificationWithId(this.oe.NotificationName.uiInstanceCompTreeCollapsedStateChange, id, (new this.oe.Module.Dictionary()).setBoolValue('collapsed', con.collapsed));
        }
    }

    onMouseOver(id) {
        this.postNotificationWithId(this.oe.NotificationName.uiInstanceCompTreeBeginHover, id);

        this.oe.sharedInterface.getUIControllerHighlightingTooltip().addCustomHighlightedComponent(id);
    }

    onMouseOut(id)  {
        this.postNotificationWithId(this.oe.NotificationName.uiInstanceCompTreeEndHover, id);

        this.oe.sharedInterface.getUIControllerHighlightingTooltip().removeCustomHighlightedComponent(id);
    }

    onColorPickerShow(id, color)    {
        this.oe.sharedInterface.getUIControllerModelState().setUpdateEnabled(false);
    }

    onColorPickerChange(id, color, isDefault)    {
        if(isDefault)   {
            this.oe.sharedInterface.getUIControllerComponent().setComponentValueToDefault(id, this.oe.Module.ComponentDefaultFlags.color.value, false, 0.333);
        } else {
            this.oe.sharedInterface.getUIControllerComponent().setComponentColor(id, color, false, 0.333);
        }
    }

    onColorPickerAlphaChange(id, alpha, isDefault)  {
        if(isDefault)   {
            this.oe.sharedInterface.getUIControllerComponent().setComponentValueToDefault(id, this.oe.Module.ComponentDefaultFlags.color_purity.value, false, 0.333);
        } else {
            this.oe.sharedInterface.getUIControllerComponent().setComponentColorPurity(id, alpha, false, 0.333);
        }
    }

    onColorPickerHide(id, color)    {
        this.oe.sharedInterface.getUIControllerModelState().setUpdateEnabled(true);
    }

    onRenderModeBtnPressed(id)   {
        let con = this.connector.find(id);
        if(!con) return;
        this.oe.sharedInterface.getUIControllerComponent().setComponentRenderMode(id, con.toggledRenderMode(), false, 0);

        this.postNotificationWithId(this.oe.NotificationName.uiInstanceCompTreeRenderModeToggled, id, (new this.oe.Module.Dictionary()).setIntValue('renderMode', con.renderMode.value));
    }

    onActivationBtnPressed(id)  {
        let con = this.connector.find(id);
        if(con && !con.activated)  {
            this.oe.sharedInterface.getUIControllerComponent().setComponentActivated(id, !con.activated);
        }
    }

    onSwitchBtnPressed(id)  {
        let con = this.connector.find(id);
        if(!con) return;
        if(con.isSwitch && con.switchState >= 0)    {
            this.oe.sharedInterface.getUIControllerComponent().setComponentSwitch(id, (con.switchState + 1) % con.numSwitchStates());
        } else if(con.isRemoteSwitch()) {
            this.oe.sharedInterface.getUIControllerComponent().setComponentRemoteSwitch(id, (con.remoteSwitchState + 1) % con.numRemoteSwitchStates);
        }
    }

    onCatSwitchBtnPressed(id) {
        let con = this.connector.find(id);
        if(con && con.categoriesNumSwitchStates > 0)    {
            let s = ((con.categoriesSwitch + 1 + 1) % (con.categoriesNumSwitchStates + 1)) - 1;
            this.oe.sharedInterface.getUIControllerComponent().setComponentCategoriesSwitch(id, s);
        }
    }

    onCatActivationBtnPressed(id)   {
        let con = this.connector.find(id);
        if(con && con.categoryMode === this.oe.Module.ComponentCategoryMode.activation)    {
            this.oe.sharedInterface.getUIControllerComponent().setComponentCategoriesActivation(id, !con.categoriesActivation);
        }
    }
}

OEComponentController.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.componentController(),
    devFeatures: false
};

OEComponentController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        showHelpBtn: PropTypes.bool,
        searchBar: PropTypes.bool,
        colorBtnEnabled: PropTypes.bool,
        catSwitchBtnEnabled: PropTypes.bool,
        catActivationBtnEnabled: PropTypes.bool,
    }).isRequired,
    devFeatures: PropTypes.bool
};

export default connectAppEnv((env) => { 
    const ui = env.config.module.uiLayerConfig;
    const ret = {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.componentController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.component),
        devFeatures: env.config.module.flags && env.config.module.flags.includes('import')
    };
    return ret;
})(withUILevel(OEComponentController));