import React from 'react';
import PropTypes from 'prop-types';

import {OETransitionGroup} from '../../../lib/oe-transitions';
import {OEToolbox} from '../../../lib/oe-toolbox';
import OEIcon from '../../elements/oe-icon';
import {OEColorPickerNumericInputType, OEColorPickerButton} from '../../color-picker/oe-color-picker';
import OEScrollbars from '../../oe-scrollbars';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {AnimationTimer} from '../../../lib/animation-timer';

export class OEComponentTreeNode extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onHighlightAnimationFrame = this.onHighlightAnimationFrame.bind(this);

        this.animationTimer = new AnimationTimer(null, this.onHighlightAnimationFrame);

        this.onRef = this.onRef.bind(this);
        this.onLabelNameRef = this.onLabelNameRef.bind(this);

        this.onClick = this.onClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onCatSwitchBtnPressed = this.onCatSwitchBtnPressed.bind(this);
        this.onActivationBtnPressed = this.onActivationBtnPressed.bind(this);
        this.onSwitchBtnPressed = this.onSwitchBtnPressed.bind(this);
        this.onCatActivationBtnPressed = this.onCatActivationBtnPressed.bind(this);
        this.onRenderModeBtnPressed = this.onRenderModeBtnPressed.bind(this);

        this.onColorPickerShow = this.onColorPickerShow.bind(this);
        this.onColorPickerChange = this.onColorPickerChange.bind(this);
        this.onColorPickerAlphaChange = this.onColorPickerAlphaChange.bind(this);
        this.onColorPickerHide = this.onColorPickerHide.bind(this);
    }

    onHighlightAnimationFrame(sender, time) {
        let currentTime = 0.001 * Date.now();
        let phase = time / 0.9 * 2.0 * Math.PI;
        let intPhase = Math.trunc(phase/Math.PI) % 2;
        let endAnimation = currentTime - this.startTime > 3.0;

        if(endAnimation && this.lastPhase == 1 && intPhase == 0) {
            this.applyHighlighting(1.0);
            return true;
        }

        this.applyHighlighting(Math.pow(Math.cos(phase), 2.0));
        
        this.lastPhase = intPhase;
    }

    startHighlighting() {
        this.startTime = 0.001 * Date.now();
        this.lastPhase = 0;
        if(!this.animationTimer.isPlaying()) this.animationTimer.play();
    }

    stopHighlighting()  {
        if(this.animationTimer.isPlaying())    {
            this.startTime -= 3;
        }
    }

    applyHighlighting(alpha)    {
        if(!this.labelNameRef)  return;
        $(this.labelNameRef).css('opacity', alpha)
    }

    componentWillUnmount()  {
        if(this.props.onNodeRefs) this.props.onNodeRefs(this, true);
    }

    getRef()    {
        return this.ref;
    }

    onRef(ref) {
        this.ref = ref;
    }

    onLabelNameRef(ref) {
        this.labelNameRef = ref;
    }

    renderBtnContainer()    {
        let con = this.props.connector;
        let extra = this.props.extra;
        let disabled = !extra.uiEnabled || !con.activated;

        let rmIconClass = 'button-transparent';
        let locks = {activation: true, switch: true, renderMode: true};
        if(extra.oe.isReady())    {
            if(con.renderMode === extra.oe.Module.RenderMode.opaque)    {
                rmIconClass = 'button-visible';
            } else if(con.renderMode === extra.oe.Module.RenderMode.hide)   {
                rmIconClass = 'button-hide';
            }

            locks.activation = con.isFeatureLocked(extra.oe.Module.ComponentLockFlags.activation);
            locks.switch = con.isFeatureLocked(extra.oe.Module.ComponentLockFlags.switch);
            locks.renderMode = con.isFeatureLocked(extra.oe.Module.ComponentLockFlags.render_mode);
        }

        let hasActivationBtn = con.deactivateable;
        let hasSwitchBtn = con.isSwitch || con.isRemoteSwitch();
        let hasRMBtn = !con.isPureSubstructure;
        let hasCatSwitchBtn = (!extra.config || extra.config.catSwitchBtnEnabled) && (extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.switch && con.categoriesNumSwitchStates > 0 : false);
        let hasCatActivationBtn = (!extra.config || extra.config.catActivationBtnEnabled) && (extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.activation && con.categoriesChangeMask != 0 : false);

        let switchState = !hasSwitchBtn ? -1 : (con.isSwitch ? con.switchState : con.remoteSwitchState);

        let activationBtnDisabled = !extra.uiEnabled || locks.activation;
        let switchBtnDisabled = disabled || locks.switch;
        let rmBtnDisabeled = disabled || locks.renderMode;
        let catSwitchBtnDisabled = disabled || (con.parent && (con.parent.activeCategories & con.categoriesChangeMask) == 0);
        let catActivationBtnDisabled = disabled || (con.parent && (con.parent.activeCategories & con.categoriesChangeMask) == 0);

        return (
            <div className="ct_btn_container">

                {!hasCatSwitchBtn ? null :
                    <button
                        type="button"
                        className={'ct_btn ct_cat_btn ct_cat_switch_btn ct_cat_switch_' + (con.categoriesSwitch + 1).toString()}
                        onClick={this.onCatSwitchBtnPressed}
                        disabled={catSwitchBtnDisabled}
                    >
                        <OEIcon
                            className="button-visible"
                            code={OEIconCodes.ctCategoriesSwitch}
                        />
                    </button>
                }

                {!hasCatActivationBtn ? null :
                    <button
                        type="button"
                        className={'ct_btn ct_cat_btn ct_cat_activation_btn ct_cat_activation_' + (con.categoriesActivation ? 'on' : 'off')}
                        onClick={this.onCatActivationBtnPressed}
                        disabled={catActivationBtnDisabled}
                    >
                        <OEIcon
                            className="button-visible"
                            code={OEIconCodes.ctCategoriesActivation}
                        />
                    </button>
                }

                {!hasActivationBtn ? null :
                    <button
                        type="button"
                        className="ct_btn ct_switch_act_btn"
                        onClick={this.onActivationBtnPressed}
                        disabled={activationBtnDisabled}
                    >
                        <OEIcon
                            className={con.activated ? 'button-visible' : 'button-hide'}
                            code={con.activated ? OEIconCodes.ctActivationOn : OEIconCodes.ctActivationOff}
                        />
                    </button>
                }

                {!hasSwitchBtn ? null :
                    <button
                        type="button"
                        className="ct_btn ct_switch_act_btn"
                        onClick={this.onSwitchBtnPressed}
                        disabled={switchBtnDisabled}
                    >
                        <OEIcon
                            className="button-visible"
                            code={switchState === 0 ? OEIconCodes.ctSwitchOff : OEIconCodes.ctSwitchOn}
                        />
                    </button>
                }

                {!extra.isWorkingColor || (extra.config && !extra.config.colorBtnEnabled) ? null :
                    <OEColorPickerButton
                        moduleId={extra.moduleId}
                        className="ct_btn ct_color_btn"
                        disabled={disabled}
                        placement="right-start"
                        boundariesElement={extra.boundariesElement}
                        noHeader={false}
                        color={{x: con.color.x, y: con.color.y, z: con.color.z, w: con.colorPurity}}
                        defaultColor={{x: con.defaultColor.x, y: con.defaultColor.y, z: con.defaultColor.z, w: con.defaultColorPurity}}
                        hasAlphaControl={!extra.config || extra.config.colorPurityControl}
                        alphaDisplayRange={{min: 0.5, max: 1/*, palette: {min: 1, max: 1}*/, button: {min: 1, max: 1}}}
                        numericInput={extra.devFeatures ? OEColorPickerNumericInputType.textField : OEColorPickerNumericInputType.rgbInput}
                        hasPaletteButton={extra.devFeatures}
                        separatedDelegation={true}
                        closeWhenOutOfScreen={true}
                        onChange={this.onColorPickerChange}
                        onAlphaChange={this.onColorPickerAlphaChange}
                        onShow={this.onColorPickerShow}
                        onHide={this.onColorPickerHide}
                    />
                }

                {!hasRMBtn ? null :
                    <button
                        type="button"
                        className="ct_btn ct_rm_btn"
                        onClick={this.onRenderModeBtnPressed}
                        disabled={rmBtnDisabeled}
                    >
                        <OEIcon className={rmIconClass} code={OEIconCodes.eye}/>
                    </button>
                }

            </div>
        );
    }

    render() {
        let con = this.props.connector;
        let switchCon = con.switchConnector();
        let childs = switchCon ? switchCon.activeChilds() : undefined;

        let extra = this.props.extra;
        let disabled = !extra.uiEnabled || !con.activated;

        let hasChilds = switchCon && childs.length > 0;
        let drawChilds = !con.collapsed && con.activated && hasChilds;
        let terminal = !hasChilds;

        const toggle = terminal || !con.isCollapsable() ? null : (
            <div className={'rot-toggle ct_toggle' + (drawChilds ? ' rot-rotate-toggle' : '')}>
                <OEIcon code={OEIconCodes.caretRight}/>
            </div>
        );

        const childElements = drawChilds ? childs.map((child) =>
            <OEComponentTreeNode key={child.id} connector={child} extra={extra} ref={this.props.onNodeRefs} onNodeRefs={this.props.onNodeRefs}/>
        ) : null;

        let hasActivationBtn = con.deactivateable;
        let hasSwitchBtn = con.isSwitch || con.isRemoteSwitch();
        let hasCatSwitchBtn = extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.switch && con.categoriesNumSwitchStates > 0 : false;
        let hasCatActivationBtn = extra.oe.isReady() ? con.categoryMode === extra.oe.Module.ComponentCategoryMode.activation && con.categoriesChangeMask != 0 : false;

        let labelPaddingLeft = toggle || terminal ? 12 : 0; //toggle ? 12 : 0;
        let labelPaddingRight = 56 + (hasSwitchBtn ? 32 : 0) + (hasActivationBtn ? 32 : 0) + (hasCatSwitchBtn ? 32 : 0) + (hasCatActivationBtn ? 32 : 0);
        let indentation = !con.noIndentation() ? 12 : 0;

        return (
            <li ref={this.onRef}>
                <div className={'component-tree-node' + (con.isPrincipal() ? ' principal' : '')}>

                    <div className={'ct_header' + (drawChilds ? ' expanded' : '') + (disabled ? ' disabled' : '')}>

                        {toggle}

                        {terminal ? <div className="icon terminal"/> : null}

                        <div
                            className="ct_label"
                            style={{ paddingLeft: labelPaddingLeft.toString() + 'px', paddingRight: labelPaddingRight.toString() + 'px' }}
                        >
                            <span ref={this.onLabelNameRef}>{OEToolbox.decode_utf8(con.name)}</span>
                        </div>

                        <div
                            className="ct_click_target"
                            onClick={this.onClick}
                            onMouseOver={this.onMouseOver}
                            onMouseOut={this.onMouseOut}
                        />

                        {this.renderBtnContainer()}
                        
                    </div>

                    <OETransitionGroup style={{paddingLeft: indentation.toString() + 'px'}} component="ul" mountOnEnter={true} unmountOnExit={true}>
                        {childElements}
                    </OETransitionGroup>
                </div>
            </li>
        );
    }

    onClick()   {
        let con = this.props.connector;
        let extra = this.props.extra;
        let disabled = !extra.uiEnabled || !con.activated;
        if(!disabled) extra.onClick(con.id);
    }

    onMouseOver()   {
        this.props.extra.onMouseOver(this.props.connector.id);
    }

    onMouseOut()   {
        this.props.extra.onMouseOut(this.props.connector.id);
    }

    onCatSwitchBtnPressed() {
        this.props.extra.onCatSwitchBtnPressed(this.props.connector.id);
    }

    onActivationBtnPressed()    {
        this.props.extra.onActivationBtnPressed(this.props.connector.id);
    }

    onSwitchBtnPressed()    {
        this.props.extra.onSwitchBtnPressed(this.props.connector.id);
    }

    onCatActivationBtnPressed() {
        this.props.extra.onCatActivationBtnPressed(this.props.connector.id);
    }

    onRenderModeBtnPressed()    {
        this.props.extra.onRenderModeBtnPressed(this.props.connector.id);
    }

    onColorPickerShow(color)    {
        this.props.extra.onColorPickerShow(this.props.connector.id, color);
    }

    onColorPickerChange(color, colorHSV, isDefault)    {
        this.props.extra.onColorPickerChange(this.props.connector.id, color, isDefault);
    }

    onColorPickerAlphaChange(alpha, isDefault)    {
        this.props.extra.onColorPickerAlphaChange(this.props.connector.id, alpha, isDefault);
    }

    onColorPickerHide(color)    {
        this.props.extra.onColorPickerHide(this.props.connector.id, color);
    }
}

OEComponentTreeNode.defaultProps = {
};

OEComponentTreeNode.propTypes = {
    connector: PropTypes.object,
    extra: PropTypes.object,
    onNodeRefs: PropTypes.func
};

export class OEComponentTree extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.nodeRefs = {};
        
        this.onScrollbarRef = this.onScrollbarRef.bind(this);
        this.onNodeRefs = this.onNodeRefs.bind(this);
    }

    scrollTo(id, completed)    {
        if(!this.scrollbar || !this.nodeRefs[id]) return;
        let node = this.nodeRefs[id].getRef();

        let element = node;
        let offsetTop = 0;
        while(element && element != this.scrollbar.container)    {
            offsetTop += element.offsetTop;
            element = element.parentElement;
        }

        let itemCenter = offsetTop + 0.5 * node.offsetHeight;
        let scrollViewCenter = 0.5 * this.scrollbar.getClientHeight();
        let scrollTop = itemCenter - scrollViewCenter;
        this.scrollbar.scrollTop(scrollTop, completed);
    }

    getScrollbarRef()   {
        return this.scrollbar;
    }

    getNodeRef(id)   {
        return this.nodeRefs[id];
    }

    onScrollbarRef(e)   {
        this.scrollbar = e;
    }

    onNodeRefs(ref, release)    {
        if(!ref || !ref.props.connector) return;
        if(release) {
            delete this.nodeRefs[ref.props.connector.id];
        } else {
            this.nodeRefs[ref.props.connector.id] = ref;
        }

        if(this.props.onNodeRefs) this.props.onNodeRefs(ref, release);
    }
    
    render() {
        const con = this.props.connector;
        const childs = con ? con.activeChilds() : undefined;
        const extra = this.props.extra;

        const childElements = extra.oe.isReady() && childs && childs.length > 0 ? childs.map((child) =>
           <OEComponentTreeNode key={child.id} connector={child} extra={extra} ref={this.onNodeRefs} onNodeRefs={this.onNodeRefs}/>
        ) : null;
    
        return (
            <OEScrollbars className="component-tree" ref={this.onScrollbarRef}>
                <OETransitionGroup className="root" component="ul" mountOnEnter={true} unmountOnExit={true}>
                    {childElements}
                </OETransitionGroup>
            </OEScrollbars>
        );
    }
}

OEComponentTree.defaultProps = {
};

OEComponentTree.propTypes = {
    connector: PropTypes.object,
    extra: PropTypes.object,
    onNodeRefs: PropTypes.func
};