import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';
import {Tooltip} from 'reactstrap';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEMouseTracker from '../../lib/oe-mouse-tracker';
import OENumberInput from '../elements/oe-number-input';
import OETextField from '../elements/oe-text-field';
import OEScrollbars from '../oe-scrollbars';
import OEColor from './oe-color';

export const OEColorPickerViewType = {
    sv: 'sv',
    hue: 'hue',
    alpha: 'alpha',
    palette: 'palette',
    textField: 'textField',
    rgbInput: 'rgbInput'
};

export class OEColorPickerSVView extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.viewType = OEColorPickerViewType.sv;

        this.state = {
            canvasRef: null,
            markerRef: null
        }

        this.onCanvasRef = this.onCanvasRef.bind(this);
        this.onMarkerRef = this.onMarkerRef.bind(this);

        this.onMouse = this.onMouse.bind(this);
        this.onMouseState = this.onMouseState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.colorHSV.x !== this.props.colorHSV.x || !OEToolbox.shallowEqual(nextProps.canvasSize, this.props.canvasSize))     {
            this.draw(nextProps);
        }
    }

    draw(props)  {
        if(!this.ctx)   return;
        props = props || this.props;
        if(props.canvasSize.w <= 0 || props.canvasSize.h <= 0)  return;

        const imageData = this.ctx.createImageData(props.canvasSize.w, props.canvasSize.h);

        const colorHSV = OEColor.clone(props.colorHSV);

        const widthInv = 1 / (props.canvasSize.w - 1);
        const heightInv = 1 / (props.canvasSize.h - 1);
        let offset = 0;

        for(let y = 0; y < props.canvasSize.h; ++y) {
            colorHSV.z = 1 - heightInv * y;
            for(let x = 0; x < props.canvasSize.w; ++x) {
                colorHSV.y = widthInv * x;
                const color = OEColor.fromHSV(colorHSV);

                imageData.data[offset] = 255 * color.x;
                imageData.data[offset + 1] = 255 * color.y;
                imageData.data[offset + 2] = 255 * color.z;
                imageData.data[offset + 3] = 255;

                offset += 4;
            }
        }

        this.ctx.putImageData(imageData, 0, 0);
    }

    onCanvasRef(ref)   {
        if(this.canvasRef === ref)  return;
        this.canvasRef = ref;
        this.setState({canvasRef: this.canvasRef});
        this.ctx = this.canvasRef ? this.canvasRef.getContext('2d') : undefined;
        this.draw();
    }

    onMarkerRef(ref) {
        if(this.markerRef === ref)  return;
        this.markerRef = ref;
        this.setState({markerRef: this.markerRef});
    }

    render()    {
        const style = {top: Math.max(0, Math.min(100, (100 - this.props.colorHSV.z * 100))).toString() + '%', left: Math.max(0, Math.min(100, (this.props.colorHSV.y * 100))).toString() + '%'};
        return(
            <div className={'color-picker-sv-view ' + this.props.className + (this.props.disabled ? ' disabled' : '')}>
                <canvas width={this.props.canvasSize.w} height={this.props.canvasSize.h} ref={this.onCanvasRef}/>
                <div className="marker" style={style} ref={this.onMarkerRef}/>
                <OEMouseTracker name="color-picker-sv-view-canvas" disabled={this.props.disabled} element={this.state.canvasRef} onMouseDown={this.onMouse} onMouseMove={this.onMouse} onMouseUp={this.onMouse} onMouseState={this.onMouseState}/>
                <OEMouseTracker name="color-picker-sv-view-marker" disabled={this.props.disabled} element={this.state.markerRef} onMouseDown={this.onMouse} onMouseMove={this.onMouse} onMouseUp={this.onMouse} onMouseState={this.onMouseState}/>
            </div>
        );
    }

    getTargetRef()  {
        return this.canvasRef;
    }

    onMouse(event)  {
        let targetRef = this.getTargetRef();
        if(!targetRef) return;

        const targetRect = targetRef.getBoundingClientRect();
        const targetSize = {w: targetRect.right - targetRect.left, h: targetRect.bottom - targetRect.top};
        const pos = {x: event.clientX - targetRect.left, y: event.clientY - targetRect.top};

        let colorHSV = OEColor.clone(this.props.colorHSV);

        let relPos = {x: targetSize.w <= 0 ? 0 : pos.x / targetSize.w, y: targetSize.h <= 0 ? 0 : pos.y / targetSize.h}
        relPos = {x: Math.max(0, Math.min(1, relPos.x)), y: Math.max(0, Math.min(1, relPos.y))};
        colorHSV.y = relPos.x;
        colorHSV.z = 1 - relPos.y;

        if(colorHSV.y === this.props.colorHSV.y && colorHSV.z === this.props.colorHSV.z)    return;

        if(this.props.onChange) this.props.onChange(this, colorHSV);
    }

    onMouseState(state) {
        if(this.props.onMouseState) this.props.onMouseState(this, state);
    }
}

OEColorPickerSVView.defaultProps = {
    className: '',
    disabled: false,
    canvasSize: {w: 256, h: 256},
    colorHSV: {x: 0, y: 0, z: 1},
};

OEColorPickerSVView.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    canvasSize: PropTypes.shape({
        w: PropTypes.number.isRequired,
        h: PropTypes.number.isRequired,
    }),
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
    }),
    onChange: PropTypes.func,
    onMouseState: PropTypes.func
};

export class OEColorPickerHueView extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.viewType = OEColorPickerViewType.hue;

        this.state = {
            canvasRef: null
        };

        this.onCanvasRef = this.onCanvasRef.bind(this);

        this.onMouse = this.onMouse.bind(this);
        this.onMouseState = this.onMouseState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(nextProps.canvasSize, this.props.canvasSize))     {
            this.draw(nextProps);
        }
    }

    draw(props)  {
        if(!this.ctx)   return;
        props = props || this.props;
        if(props.canvasSize.w <= 0 || props.canvasSize.h <= 0)  return;

        const imageData = this.ctx.createImageData(props.canvasSize.w, props.canvasSize.h);

        const colorHSV = {x: 0, y: 1, z: 1};

        const heightInv = 1 / (props.canvasSize.h);
        let offset = 0;

        for(let y = 0; y < props.canvasSize.h; ++y) {
            colorHSV.x = 6 * heightInv * y;
            for(let x = 0; x < props.canvasSize.w; ++x) {
                const color = OEColor.fromHSV(colorHSV);

                imageData.data[offset] = 255 * color.x;
                imageData.data[offset + 1] = 255 * color.y;
                imageData.data[offset + 2] = 255 * color.z;
                imageData.data[offset + 3] = 255;

                offset += 4;
            }
        }

        this.ctx.putImageData(imageData, 0, 0);
    }

    onCanvasRef(ref)   {
        if(this.canvasRef === ref)  return;
        this.canvasRef = ref;
        this.setState({canvasRef: this.canvasRef});
        this.ctx = this.canvasRef ? this.canvasRef.getContext('2d') : undefined;
        this.draw();
    }

    render()    {
        const style = {top: Math.max(0, Math.min(100, this.props.colorHSV.x/6 * 100)).toString() + '%'};
        return(
            <div className={'color-picker-hue-view ' + this.props.className + (this.props.disabled ? ' disabled' : '')}>
                <canvas width={this.props.canvasSize.w} height={this.props.canvasSize.h} ref={this.onCanvasRef}/>
                <div className="marker" style={style}/>
                <OEMouseTracker name="color-picker-hue-view-canvas" element={this.state.canvasRef} disabled={this.props.disabled} onMouseDown={this.onMouse} onMouseMove={this.onMouse} onMouseUp={this.onMouse} onMouseState={this.onMouseState}/>
            </div>
        );
    }

    getTargetRef()  {
        return this.canvasRef;
    }

    onMouse(event)   {
        let targetRef = this.getTargetRef();
        if(!targetRef) return;

        const targetRect = targetRef.getBoundingClientRect();
        const targetSize = {w: targetRect.right - targetRect.left, h: targetRect.bottom - targetRect.top};
        const pos = {x: event.clientX - targetRect.left, y: event.clientY - targetRect.top};

        let colorHSV = OEColor.clone(this.props.colorHSV);

        let relPos = targetSize.h <= 0 ? 0 : Math.min(pos.y, targetSize.h - 1) / targetSize.h;
        relPos = Math.max(0, Math.min(1, relPos));
        colorHSV.x = 6 * relPos;

        if(colorHSV.x === this.props.colorHSV.x)    return;

        if(this.props.onChange) this.props.onChange(this, colorHSV);
    }

    onMouseState(state) {
        if(this.props.onMouseState) this.props.onMouseState(this, state);
    }
}

OEColorPickerHueView.defaultProps = {
    className: '',
    disabled: false,
    canvasSize: {w: 1, h: 512},
    colorHSV: {x: 0, y: 0, z: 1},
};

OEColorPickerHueView.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    canvasSize: PropTypes.shape({
        w: PropTypes.number.isRequired,
        h: PropTypes.number.isRequired,
    }),
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
    }),
    onChange: PropTypes.func,
    onMouseState: PropTypes.func
};

export class OEColorPickerAlphaView extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.viewType = OEColorPickerViewType.alpha;

        this.state = {
            canvasRef: null,
            handleRef: null
        };

        this.onCanvasRef = this.onCanvasRef.bind(this);
        this.onHandleRef = this.onHandleRef.bind(this);

        this.onMouse = this.onMouse.bind(this);
        this.onHandleMouseDown = this.onHandleMouseDown.bind(this);
        this.onHandleMouseMove = this.onHandleMouseMove.bind(this);
        this.onHandleMouseUp = this.onHandleMouseUp.bind(this);
        this.onMouseState = this.onMouseState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEColor.compare(nextProps.colorHSV, this.props.colorHSV, true) || !OEToolbox.shallowEqual(nextProps.canvasSize, this.props.canvasSize) || !OEToolbox.shallowEqual(nextProps.alphaDisplayRange, this.props.alphaDisplayRange))     {
            this.draw(nextProps);
        }

        if(nextProps.disabled != this.props.disabled && nextProps.disabled) {
            this.handleMouseStart = undefined;
        }
    }

    draw(props)  {
        if(!this.ctx)   return;
        props = props || this.props;
        if(props.canvasSize.w <= 0 || props.canvasSize.h <= 0)  return;

        const imageData = this.ctx.createImageData(props.canvasSize.w, props.canvasSize.h);

        const color = OEColor.fromHSV(props.colorHSV);

        const heightInv = 1 / (props.canvasSize.h - 1);
        let offset = 0;

        for(let y = 0; y < props.canvasSize.h; ++y) {
            let alpha = OEColor.mapAlphaWithRange(1 - heightInv * y, props.alphaDisplayRange, OEColorPickerViewType.alpha);
            for(let x = 0; x < props.canvasSize.w; ++x) {
                imageData.data[offset] = 255 * color.x;
                imageData.data[offset + 1] = 255 * color.y;
                imageData.data[offset + 2] = 255 * color.z;
                imageData.data[offset + 3] = 255 * alpha;

                offset += 4;
            }
        }

        this.ctx.putImageData(imageData, 0, 0);
    }

    onCanvasRef(ref)   {
        if(this.canvasRef === ref)  return;
        this.canvasRef = ref;
        this.setState({canvasRef: this.canvasRef});
        this.ctx = this.canvasRef ? this.canvasRef.getContext('2d') : undefined;
        this.draw();
    }

    onHandleRef(ref)   {
        if(this.handleRef === ref)  return;
        this.handleRef = ref;
        this.setState({handleRef: this.handleRef});
    }

    render()    {
        const style = {top: Math.max(0, Math.min(100, (100 - (typeof(this.props.colorHSV.w) === 'number' ? this.props.colorHSV.w : 1) * 100))).toString() + '%'};
        return(
            <div className={'color-picker-alpha-view ' + this.props.className + (this.props.disabled ? ' disabled' : '')}>
                <canvas className="checker-background" width={this.props.canvasSize.w} height={this.props.canvasSize.h} ref={this.onCanvasRef}/>
                <div className="handle" style={style} ref={this.onHandleRef}>
                    <div/>
                </div>
                <OEMouseTracker name="color-picker-alpha-view-canvas" element={this.state.canvasRef} disabled={this.props.disabled} onMouseDown={this.onMouse} onMouseMove={this.onMouse} onMouseUp={this.onMouse} onMouseState={this.onMouseState}/>
                <OEMouseTracker name="color-picker-alpha-view-handle" element={this.state.handleRef} disabled={this.props.disabled} onMouseDown={this.onHandleMouseDown} onMouseMove={this.onHandleMouseMove} onMouseUp={this.onHandleMouseUp} onMouseState={this.onMouseState}/>
            </div>
        );
    }

    getTargetRef()  {
        return this.canvasRef;
    }

    onMouse(event)   {
        let targetRef = this.getTargetRef();
        if(!targetRef) return;

        const targetRect = targetRef.getBoundingClientRect();
        const targetSize = {w: targetRect.right - targetRect.left, h: targetRect.bottom - targetRect.top};
        const pos = {x: event.clientX - targetRect.left, y: event.clientY - targetRect.top};

        let colorHSV = OEColor.clone(this.props.colorHSV);

        let relPos = targetSize.h <= 0 ? 0 : Math.min(pos.y, targetSize.h) / targetSize.h;
        relPos = Math.max(0, Math.min(1, relPos));
        colorHSV.w = 1 - relPos;

        if(colorHSV.w === this.props.colorHSV.w)    return;

        if(this.props.onChange) this.props.onChange(this, colorHSV);
    }

    onHandleMouseDown(event)    {
        if(this.handleMouseStart)    return;
        this.handleMouseStart = {x: event.clientX, y: event.clientY, alpha: this.props.colorHSV.w};
    }

    onHandleMouseMove(event)    {
        let targetRef = this.getTargetRef();
        if(!this.handleMouseStart || !targetRef)   return;
        const targetRect = targetRef.getBoundingClientRect();
        const targetHeight = targetRect.bottom - targetRect.top;

        let deltaPosY = event.clientY - this.handleMouseStart.y;

        let colorHSV = OEColor.clone(this.props.colorHSV);
        colorHSV.w = Math.max(0, Math.min(1, this.handleMouseStart.alpha - deltaPosY / targetHeight));

        if(colorHSV.w === this.props.colorHSV.w)    return;

        if(this.props.onChange) this.props.onChange(this, colorHSV);
    }

    onHandleMouseUp(event)    {
        this.handleMouseStart = undefined;
    }

    onMouseState(state) {
        if(this.props.onMouseState) this.props.onMouseState(this, state);
    }
}

OEColorPickerAlphaView.defaultProps = {
    className: '',
    disabled: false,
    canvasSize: {w: 1, h: 512},
    colorHSV: {x: 0, y: 0, z: 1},
};

OEColorPickerAlphaView.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    canvasSize: PropTypes.shape({
        w: PropTypes.number.isRequired,
        h: PropTypes.number.isRequired,
    }),
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    alphaDisplayRange: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
    onChange: PropTypes.func,
    onMouseState: PropTypes.func
};

export class OEColorPickerPaletteViewCell extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.mounted = false;

        this.state = {
            style: this.constructStyle(),
            tooltipOpen: false,
            ref: null
        }

        this.onRef = this.onRef.bind(this);

        this.onPressed = this.onPressed.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    constructStyle(props)    {
        props = props || this.props;
        let color = OEColor.mapAlphaWithRange(props.colorHSV, props.alphaDisplayRange, OEColorPickerViewType.palette);
        let colorOpaque = OEColor.clone(color); colorOpaque.w = 1;
        color = OEColor.hsvToDOMStr(color);
        colorOpaque = OEColor.hsvToDOMStr(colorOpaque);

        return {
            backgroundColor: color,
            backgroundImage: 'linear-gradient(135deg, ' + colorOpaque + ' 50%, ' + color + ' 50%)'
        };
    }

    componentWillReceiveProps(nextProps)    {
        if(!OEColor.compare(nextProps.colorHSV, this.props.colorHSV) || !OEToolbox.shallowEqual(nextProps.alphaDisplayRange, this.props.alphaDisplayRange))   {
            this.setState({style: this.constructStyle(nextProps)})
        }

        if((nextProps.disabled && !this.props.disabled) || (!nextProps.tooltip && nextProps.tooltip !== this.props.tooltip))     {
            this.finishTooltip();
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount()  {
        this.finishTooltip();
        this.mounted = false;
    }

    startTooltip()   {
        if(!this.mounted) return;
        if(this.timer || this.props.disabled) return;
        this.timer = setTimeout(() => { this.setState({tooltipOpen: true}); this.timer = null; }, 1000);
    }

    finishTooltip()   {
        if(!this.mounted) return;
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.setState({tooltipOpen: false});
    }

    onRef(ref)  {
        if(this.ref === ref)    return;
        this.ref = ref;
        this.setState({ref: this.ref});
    }

    render()    {
        let tooltip = !this.state.ref || !this.props.tooltip ? null :
            <Tooltip
                boundariesElement="body"
                placement="right"
                isOpen={this.state.tooltipOpen}
                target={this.state.ref}
                delay={{show: 500, hide: 0}}
            >
                {this.props.tooltip}
            </Tooltip>;

        return(
            <div
                className={'color-picker-palette-view-cell ' + (this.props.className ? this.props.className : '') + (this.props.isDefault ? ' default ' : ' ') + (!this.props.isSelectable ? 'not-selectable' : '')}
                onClick={this.onPressed}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                ref={this.onRef}
            >
                <div className="content">
                    <div className="background checker-background"/>
                    <div className="foreground" style={this.state.style}/>
                </div>
                {tooltip}
            </div>
        );
    }

    onPressed()   {
        this.finishTooltip();
        if(this.props.onPressed)    this.props.onPressed(this);
    }

    onMouseEnter()    {
        this.startTooltip();
    }

    onMouseLeave()    {
        this.finishTooltip();
    }
};

OEColorPickerPaletteViewCell.defaultProps = {
    disabled: false,
    colorHSV: {x: 0, y: 0, z: 1},
    isDefault: false,
    isSelectable: true
};

OEColorPickerPaletteViewCell.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    alphaDisplayRange: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
    isDefault: PropTypes.bool,
    isSelectable: PropTypes.bool,
    tooltip: PropTypes.string,
    onPressed: PropTypes.func
};

export const OEColorPickerPalette = new class {

    constructor()  {
        this.maxColors = 32;
        this.palette = this.load();

        if(this.palette.length)  this.lastPaletteColor = this.palette[0];

        this.onChangeListeners = [];
    }

    save()   {
        if(!this.palette)   return;

        let str = '';
        this.palette.forEach((color, i) => {
            if(i)   str += ';';
            str += OEColor.toStr(color, true);
        });

        localStorage.setItem('color-picker-palette', str);
    }

    load()   {
        let palette = [];
        let str = localStorage.getItem('color-picker-palette');
        if(!str || !str.length) return palette;
        let strColors = str.split(';');

        for(let i = 0; i < strColors.length; ++i)  {
            let color = OEColor.fromStr(strColors[i]);
            if(color)   {
                if(!color.isHSV)    color = OEColor.toHSV(color);
                if(typeof(color.w) === 'undefined') color.w = 1;
                palette.push(color);
            }
        }

        return palette;
    }

    registerOnChangeListener(listener)  {
        if(typeof(listener) !== 'function' || this.onChangeListeners.includes(listener))   return;
        this.onChangeListeners.push(listener);
    }

    unregisterOnChangeListener(listener)  {
        let index = this.onChangeListeners.findIndex(l => l === listener);
        if(index >= 0)  this.onChangeListeners.splice(index, 1);
    }

    addColor(colorHSV) {
        if(OEColor.compare(colorHSV, this.lastPaletteColor))   return false;

        this.palette = this.palette.filter(c => !OEColor.compareEps(c, colorHSV));
        this.palette.splice(0, 0, colorHSV);
        if(this.palette.length > this.maxColors)    this.palette.pop();
        this.lastPaletteColor = colorHSV;

        this.save();

        this.onChange();

        return true;
    }

    onChange()  {
        this.onChangeListeners.forEach(listener => { listener(this); });
    }
};

export class OEColorPickerPaletteView extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.viewType = OEColorPickerViewType.palette;

        this.state = {
            palette: [],
            strings: {
                current: 'current',
                default: 'original'
            }
        };
        
        this.onPaletteChange = this.onPaletteChange.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onCellPressed = this.onCellPressed.bind(this);
    }

    componentWillReceiveProps(nextProps)    {
        if(nextProps.track && !OEColor.compare(nextProps.colorHSV, this.props.colorHSV))    {
            this.addColor(this.props.colorHSV);
        }
    }

    componentWillMount()    {
        OEColorPickerPalette.registerOnChangeListener(this.onPaletteChange);
        this.updatePalette();
    }

    componentWillUnmount() {
        OEColorPickerPalette.unregisterOnChangeListener(this.onPaletteChange);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onPaletteChange()   {
        this.updatePalette();
    }

    updateLanguage()   {
        this.setState({strings: {
            current: this.oe.sharedInterface.getLocalizedStringEnc('color_picker_palette_view_current_color'),
            default: this.oe.sharedInterface.getLocalizedStringEnc('color_picker_palette_view_default_color'),
        }});
    }

    updatePalette() {
        this.setState({palette: clone(OEColorPickerPalette.palette)});
    }

    addColor(colorHSV)  {
        colorHSV = colorHSV || this.props.colorHSV;
        return OEColorPickerPalette.addColor(colorHSV);
    }

    render()    {
        let cells = (this.props.scrollable ? this.state.palette : this.state.palette.filter((color, i) => i < 8)).map((color, i) => 
            <OEColorPickerPaletteViewCell
                key={i}
                className={i % 2 > 0 ? 'second' : ''}
                disabled={this.props.disabled}
                colorHSV={color}
                alphaDisplayRange={this.props.alphaDisplayRange}
                onPressed={this.onCellPressed}
            />
        );

        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'color-picker-palette-view ' + this.props.className + (this.props.scrollable ? ' scrollable' : '') + (this.props.disabled ? ' disabled' : '')}>
                    <div className="content">
                        <div className="current-default">

                            <OEColorPickerPaletteViewCell
                                disabled={this.props.disabled}
                                colorHSV={this.props.colorHSV}
                                alphaDisplayRange={this.props.alphaDisplayRange}
                                isSelectable={false}
                                tooltip={this.state.strings.current}
                                onPressed={this.onCellPressed}
                            />

                            {!this.props.defaultColorHSV ? null :
                                <OEColorPickerPaletteViewCell
                                    className="second"
                                    disabled={this.props.disabled}
                                    colorHSV={this.props.defaultColorHSV}
                                    alphaDisplayRange={this.props.alphaDisplayRange}
                                    isDefault={true}
                                    tooltip={this.state.strings.default}
                                    onPressed={this.onCellPressed}
                                />
                            }
                        </div>
                        <div className="std-separator-border-color separator"/>
                        {this.props.scrollable ? <OEScrollbars>{cells}</OEScrollbars> : <div className="cells">{cells}</div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onCellPressed(sender) {
        if(!sender.props.isSelectable || !this.props.onSelect)  return;
        this.props.onSelect(sender, sender.props.colorHSV, sender.props.isDefault);
    }
}

OEColorPickerPaletteView.defaultProps = {
    moduleId: '',
    className: '',
    disabled: false,
    colorHSV: {x: 0, y: 0, z: 1},
    scrollable: false,
    track: false
};

OEColorPickerPaletteView.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    defaultColorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    alphaDisplayRange: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
    scrollable: PropTypes.bool,
    track: PropTypes.bool,
    onSelect: PropTypes.func
};

export class OEColorPickerTextField extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.viewType = OEColorPickerViewType.textField;

        this.textFieldValue = OEColor.toStr(OEColor.fromHSV(this.props.colorHSV), false, true, true, !this.props.hasAlphaControl);
        this.textFieldFocus = 0;

        this.state = {
            textFieldValue: this.textFieldValue
        };

        this.onTextFieldChange = this.onTextFieldChange.bind(this);
        this.onTextFieldFocus = this.onTextFieldFocus.bind(this);
        this.onTextFieldBlur = this.onTextFieldBlur.bind(this);
    }

    updateTextFieldValue(props)  {
        props = props || this.props;
        this.textFieldValue = OEColor.toStr(OEColor.fromHSV(props.colorHSV), false, true, true, !props.hasAlphaControl);
        this.setState({textFieldValue: this.textFieldValue});
    }

    componentWillReceiveProps(nextProps)    {
        if(!OEColor.compare(nextProps.colorHSV, this.props.colorHSV) || nextProps.hasAlphaControl !== this.props.hasAlphaControl)   {
            if(!this.textFieldFocus)    this.updateTextFieldValue(nextProps);
        }
    }

    componentWillUnmount()  {
        this.onTextFieldBlur();
    }

    render()    {
        return (
            <OETextField
                className={'std-label-border-color color-picker-text-field ' + this.props.className}
                disabled={this.props.disabled}
                value={this.state.textFieldValue}
                clearButton={false}
                onChange={this.onTextFieldChange}
                onFocus={this.onTextFieldFocus}
                onBlur={this.onTextFieldBlur}
            />
        );
    }

    onTextFieldChange(sender, value)    {
        if(this.props.disabled) return;
        this.textFieldValue = value;
        this.setState({textFieldValue: this.textFieldValue});
        let color = OEColor.fromStr(value, true);
        if(!color)  return;
        if(!color.isHSV)    color = OEColor.toHSV(color);
        color = OEColor.sanitizeAddAlpha(color, true);
        if(OEColor.compare(color, OEColor.sanitizeAddAlpha(this.props.colorHSV, true)))    return;
        if(this.props.onChange) this.props.onChange(this, color);
    }

    onTextFieldFocus(sender)  {
        if(this.textFieldFocus)    return;
        this.textFieldFocus = 1;
        if(this.props.onMouseState) this.props.onMouseState(this, 1);
    }

    onTextFieldBlur(sender)  {
        if(!this.textFieldFocus)    return;
        this.textFieldFocus = 0;
        if(this.props.onMouseState) this.props.onMouseState(this, 0);
        this.updateTextFieldValue();
    }
}

OEColorPickerTextField.defaultProps = {
    className: '',
    disabled: false,
    colorHSV: {x: 0, y: 0, z: 1},
    hasAlphaControl: true
};

OEColorPickerTextField.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    hasAlphaControl: PropTypes.bool,
    onChange: PropTypes.func,
    onMouseState: PropTypes.func
};

export class OEColorPickerRGBInput extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.viewType = OEColorPickerViewType.rgbInput;

        this.focus = {
            x: 0,
            y: 0,
            z: 0,
            w: 0,
            hasFocus: function()    {
                return this.x > 0 || this.y > 0 || this.z > 0 || this.w > 0;
            },
            reset: function()   { 
                this.x = 0; this.y = 0; this.z = 0; this.w = 0;
            }
        };

        this.isInFocus = 0;

        this.color = OEColor.fromHSV(this.props.colorHSV);
        this.state = {
            color: OEColor.clone(this.color)
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
    }

    componentWillReceiveProps(nextProps)    {
        if(!OEColor.compare(nextProps.colorHSV, this.props.colorHSV))   {
            this.color = OEColor.fromHSV(nextProps.colorHSV);
            this.setState({color: OEColor.clone(this.color)});
        }

        if(nextProps.hasAlphaControl !== this.props.hasAlphaControl && !nextProps.hasAlphaControl)   {
            this.onInputBlur({props: {type: 'w'}});
        }
    }

    componentWillUnmount()  {
        this.onInputBlur();
    }

    render()    {
        return (
            <div className={'std-label-border-color color-picker-rgb-input ' + this.props.className}>
                <div className="rgb-segment">
                    <OENumberInput
                        disabled={this.props.disabled}
                        min={0}
                        max={255}
                        step={1}
                        value={this.state.color.x * 255}
                        label="R"
                        type="x"
                        onChange={this.onInputChange}
                        onFocus={this.onInputFocus}
                        onBlur={this.onInputBlur}
                    />
                    <OENumberInput
                        disabled={this.props.disabled}
                        min={0}
                        max={255}
                        step={1}
                        value={this.state.color.y * 255}
                        label="G"
                        type="y"
                        onChange={this.onInputChange}
                        onFocus={this.onInputFocus}
                        onBlur={this.onInputBlur}
                    />
                    <OENumberInput
                        disabled={this.props.disabled}
                        min={0}
                        max={255}
                        step={1}
                        value={this.state.color.z * 255}
                        label="B"
                        type="z"
                        onChange={this.onInputChange}
                        onFocus={this.onInputFocus}
                        onBlur={this.onInputBlur}
                    />
                </div>
                <div className="center-segment"></div>
                {!this.props.hasAlphaControl ? null : 
                    <div className="alpha-segment">
                        <OENumberInput
                            disabled={this.props.disabled}
                            min={0}
                            max={1}
                            step={0.01}
                            value={this.state.color.w}
                            type="w"
                            onChange={this.onInputChange}
                            onFocus={this.onInputFocus}
                            onBlur={this.onInputBlur}
                            unit="A"
                        />
                    </div>
                }
            </div>
        );
    }

    onInputChange(sender, value)    {
        if(this.props.disabled) return;
        
        if(sender.props.type != 'w')    value *= 1/255;
        this.color[sender.props.type] = value;

        this.setState({color: clone(this.color)});
        let color = OEColor.sanitize(this.color);
        color = OEColor.toHSV(color);
        if(OEColor.compare(color, this.props.colorHSV))    return;
        if(this.props.onChange) this.props.onChange(this, color);
    }

    onInputFocus(sender)  {
        if(this.focus[sender.props.type])   return;
        this.focus[sender.props.type] = 1;
        this.onFocusChange();

        this.onFocusChange();
    }

    onInputBlur(sender)  {
        if(sender)  {
            if(!this.focus[sender.props.type])   return;
            this.focus[sender.props.type] = 0;
        } else {
            if(!this.focus.hasFocus())  return;
            this.focus.reset();
        }

        this.onFocusChange();
    }

    onFocusChange() {
        requestAnimationFrame(() => {   // we have to wait for a following focus or blur call for the case that the focus just changes to another input 
            let isInFocus = this.focus.hasFocus() ? 1 : 0;
            if(isInFocus === this.isInFocus)   return;
            this.isInFocus = isInFocus;
            if(this.props.onMouseState) this.props.onMouseState(this, this.isInFocus ? 1 : 0);
        });
    }
}

OEColorPickerRGBInput.defaultProps = {
    className: '',
    disabled: false,
    colorHSV: {x: 0, y: 0, z: 1},
    hasAlphaControl: true
};

OEColorPickerRGBInput.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    hasAlphaControl: PropTypes.bool,
    onChange: PropTypes.func,
    onMouseState: PropTypes.func
};