import React from 'react'; 
import PropTypes from 'prop-types';
import clone from 'clone';
import CustomEvent from 'custom-event';

import {connectAppEnv} from './app-env';
import {addModuleEnv} from './oe-module-env';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import {UILevel, OEMainMenuStdBtnType} from '../lib/oe-types';
import {translateToUILevel} from '../lib/oe-higher-order-components';
import {OEDefaultConfigFactory} from './oe-default-configs';
import OELeftWidgetToolbar from './bars/oe-left-widget-toolbar';
import OEBottomWidgetToolbar from './bars/oe-bottom-widget-toolbar';
import OEMainMenu from './oe-main-menu';
import OEContextMenu from './context-menu/oe-context-menu';
import {OEToolbox} from '../lib/oe-toolbox';
import OELabelController from './controller/oe-label-controller';
import OECutController from './controller/oe-cut-controller';
import OESettingsController from './controller/oe-settings-controller';
import OESubstructureController from './controller/component/oe-substructure-controller';
import OESearchController from './controller/search/oe-search-controller';
import OEPresentationController from './controller/presentation/oe-presentation-controller';
import OENoteController from './controller/notes/oe-note-controller';
import OENoteControllerPopover from './controller/notes/oe-note-popover-controller';
import OEArrowController from './controller/arrows/oe-arrow-controller';
import OEArrowPopoverController from './controller/arrows/oe-arrow-popover-controller';
import OEScreenshotController from './controller/oe-screenshot-controller';
import OETextViewController from './text-view/oe-text-view-controller';
import OEStartView from './start-view/oe-start-view';
import OEWelcomeView from './start-view/oe-welcome-view';
import OEInfoViewController from './info-view/oe-info-view-controller';
import OEHelpView from './help-view/oe-help-view';
import OEManualView from './help-view/oe-manual-view';
import OEBGLoadingDisplay from './oe-bg-loading-display';
import OEMediaCenter from './controller/media-center/oe-media-center';
import OEOverlayContainer from './overlays/oe-overlay-container';
import OEMediaViewerController from './media-viewer/oe-media-viewer-controller';
import OEResizeObserver from '../lib/oe-resize-observer';
import {OEBlurCoreLayer, OEBlurUILayer, OEBlurAllLayer} from './oe-blur-layer';
import OETutorController from './controller/oe-tutor-controller';
import OEMainMenuControlBar from './bars/oe-main-menu-control-bar';
import OERightTopBar from './bars/oe-right-top-bar';
import OEDevTools from './dev/oe-dev-tools';
import NotificationCenter from '../lib/notification-center';
import {retardUpdate} from '../lib/update-retarder';

export class OEUILayer extends React.PureComponent {
    
    constructor(props) {
        super(props);

        let config = this.props.config;

        this.props.appComponent.uiLayer = this;
        this.notificationCenter = new NotificationCenter();

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.justComponentLongTouched = false;

        this.leftSlideInShown = config.mainMenuConfig.initiallyVisible;
        this.leftWidgetToolbarShown = false;

        this.ref = null;
        this.mainMenuWidth = 56;

        this.state = {
            uiLevel: UILevel.std,
            leftSlideInContainer: {
                width: 250 + this.mainMenuWidth,
                offset: this.mainMenuWidth,
                animate: false
            },
            mediaCenterEdgeOffsets:    {
                top: 0, right: 0, bottom: 0, left: 0,
                animate: false
            },
            bottomBarWidgetToolbarEdgeOffsets:    {
                top: 0, right: 0, bottom: 0, left: 0,
                animate: false
            },
            bottomEdgeOffset: {
                value: 0, animate: false
            },
            tutorMargin: 46
        };

        this.onLeftSlideContainerTransitioned = this.onLeftSlideContainerTransitioned.bind(this);

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onWindowResized = this.onWindowResized.bind(this);

        this.onUILevelChanged = this.onUILevelChanged.bind(this);

        this.onRef = this.onRef.bind(this);
        this.onNotePopoverRef = this.onNotePopoverRef.bind(this);
        this.onArrowPopoverRef = this.onArrowPopoverRef.bind(this);
        this.onScreenshotViewRef = this.onScreenshotViewRef.bind(this);
        this.onTextViewRef = this.onTextViewRef.bind(this);
        this.onStartViewRef = this.onStartViewRef.bind(this);
        this.onWelcomeViewRef = this.onWelcomeViewRef.bind(this);
        this.onInfoViewRef = this.onInfoViewRef.bind(this);
        this.onHelpViewRef = this.onHelpViewRef.bind(this);
        this.onManualViewRef = this.onManualViewRef.bind(this);

        this.onMediaCenterEdgeOffsetsChanged = this.onMediaCenterEdgeOffsetsChanged.bind(this);
        this.onShouldBeVisibleLeftWidgetToolbar = this.onShouldBeVisibleLeftWidgetToolbar.bind(this);
        this.onLeftWidgetToolbarResize = this.onLeftWidgetToolbarResize.bind(this);
        this.onMainMenuWidthChanged = this.onMainMenuWidthChanged.bind(this);
        this.onMainMenuControlBtnPressed = this.onMainMenuControlBtnPressed.bind(this);
        this.onBottomBarWidgetToolbarEdgeOffsetsChanged = this.onBottomBarWidgetToolbarEdgeOffsetsChanged.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    showLeftSlideIn(show, animated)   {
        if(show === this.leftSlideInShown) return;
        this.leftSlideInShown = show;
        this.updateLeftSlideInContainer(animated);
    }

    closeAllPopovers(exceptions)  {
        if(this.notePopover) this.notePopover.close();
        if(this.arrowPopover) this.arrowPopover.close();
        if(this.screenshotView) this.screenshotView.close();

        if(!this.oe.isReady())   return;

        let si = this.oe.sharedInterface;
        let UIControllerType = this.oe.Module.UIControllerType;

        let config = this.props.config;
        let widgetConfig = translateToUILevel(config.widgetConfig || OEDefaultConfigFactory.widget(), this.state.uiLevel);
        let array = !exceptions ? [] : (Array.isArray(exceptions) ? exceptions : [exceptions]);

        if(widgetConfig.labelController.enabled && !array.includes(UIControllerType.label)) si.setUIControllerVisible(false, UIControllerType.label);
        if(widgetConfig.cutController.enabled && !array.includes(UIControllerType.cut)) si.setUIControllerVisible(false, UIControllerType.cut);
        if(widgetConfig.settingsController.enabled && !array.includes(UIControllerType.settings)) si.setUIControllerVisible(false, UIControllerType.settings);
        if(widgetConfig.substructureController.enabled && !array.includes(UIControllerType.substructure)) si.setUIControllerVisible(false, UIControllerType.substructure);
        if(widgetConfig.searchController.enabled && !array.includes(UIControllerType.search_controller)) si.setUIControllerVisible(false, UIControllerType.search_controller);
        if(widgetConfig.presentationController.enabled && !array.includes(UIControllerType.presentation)) si.setUIControllerVisible(false, UIControllerType.presentation);
        if(widgetConfig.arrowController.enabled && !array.includes(UIControllerType.arrow)) si.setUIControllerVisible(false, UIControllerType.arrow);
        if(widgetConfig.noteController.enabled && !array.includes(UIControllerType.note)) si.setUIControllerVisible(false, UIControllerType.note);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    onLeftSlideContainerTransitioned(event)  {
        let propName = event.originalEvent.propertyName;
        if(event.target !== event.currentTarget || (typeof(propName) === 'string' && propName !== 'left' && propName !== 'width')) return;
        window.dispatchEvent(new CustomEvent('oe-layout-changed'));
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();

        this.onWindowResized();
        window.addEventListener('resize', this.onWindowResized);

        let cssTransitionEnd = 'transitionend webkitTransitionEnd otransitionEnd oTransitionEnd msTransitionEnd MSTransitionEnd';
        $('#left-slide-in-container').on(cssTransitionEnd, this.onLeftSlideContainerTransitioned);
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;

        window.removeEventListener('resize', this.onWindowResized);

        let cssTransitionEnd = 'transitionend webkitTransitionEnd otransitionEnd oTransitionEnd msTransitionEnd MSTransitionEnd';
        $('#left-slide-in-container').off(cssTransitionEnd, this.onLeftSlideContainerTransitioned);
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiLevelChanged, this.onUILevelChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiLevelChanged, this.onUILevelChanged);
    }

    onWindowResized()   {
        this.updateLeftSlideInContainer(false);
        this.updateTutorMargin();
    }

    updateState_(released)   {
        let config = this.props.config;

        if(config.mainMenuConfig.initiallyVisible)  {
            this.showLeftSlideIn(true, true);
        }

        //
        if(!this.oe.isReady() || released === true)   {
            this.setState({
                //uiLevel: UILevel.std, // do not switch back at disconnect, instead wait for new state when connecting
            });
            return;
        }

        this.onUILevelChanged();
    }

    updateState(released)   {
        retardUpdate(this, () => {
            this.updateState_(released);
        });
    }

    onUILevelChanged()  {
        this.setState({
            uiLevel: UILevel.levelFromModuleValue(this.oe.sharedInterface.getUIControllerSettings().getUILevel().value)
        });
    }

    updateTutorMargin() {
        let size = Math.min($(window).width(), $(window).height());
        this.setState({tutorMargin: Math.min(46, size * 0.05) });
    }

    updateLeftSlideInContainer(animated)    {
        if(!this.leftWidgetSizeObserver) return;
        
        const toolbarWidth = this.leftWidgetSizeObserver.getWidth();
        const mainMenuWidth = this.mainMenuWidth;

        let offset = 0;
        let width = toolbarWidth + mainMenuWidth;

        if(this.leftSlideInShown)  {
            offset += mainMenuWidth;
            if(this.leftWidgetToolbarShown)   {
                offset += toolbarWidth;
            }
        }

        const leftSlideInContainer = { width: width, offset: offset, animate: typeof(animated) === 'undefined' ? false : animated };
        this.setState({leftSlideInContainer: leftSlideInContainer});
        
        this.props.moduleEnv.component.uiStateManager.updateState({leftSlideInContainer: {$set: leftSlideInContainer}});
        //this.props.updateModuleEnv({ui: {leftSlideInContainer: {$set: leftSlideInContainer}}});
    }

    onRef(ref)   {
        if(this.ref === ref) return;
        this.ref = ref;
        this.forceUpdate(); // we cannot include ref into state, due to clone(prevState) pattern in some state updates
    }

    onNotePopoverRef(ref)   {
        this.notePopover = ref;
    }

    onArrowPopoverRef(ref)   {
        this.arrowPopover = ref;
    }

    onScreenshotViewRef(ref)  {
        this.screenshotView = ref;
    }

    onTextViewRef(ref)  {
        this.textView = ref;
    }
    
    onStartViewRef(ref) {
        this.startView = ref;
    }

    onWelcomeViewRef(ref)   {
        this.welcomeView = ref;
    }

    onInfoViewRef(ref) {
        this.infoView = ref;
    }

    onHelpViewRef(ref)  {
        this.helpView = ref;
    }

    onManualViewRef(ref)  {
        this.manualView = ref;
    }

    mainMenuButtons()   {
        let config = this.props.config;
        return translateToUILevel(config.mainMenuButtons, this.state.uiLevel);
    }

    hasMainMenuBtn(type)    {
        return this.mainMenuButtons().top.find(b => b.type === type);
    }
    
    render() {
        let id = this.props.moduleId + '-ui-layer';
        let config = this.props.config;

        let leftWidth = this.state.leftSlideInContainer.width;
        let leftLeft = -leftWidth + this.state.leftSlideInContainer.offset;
        let controlButtonLeft = this.state.leftSlideInContainer.offset + 0;

        let popoverControllerProps = {moduleId: this.props.moduleId, boundariesElement: this.ref, uiLevel: this.state.uiLevel};
 
        return (
            <div id={id} className="ui-layer" ref={this.onRef}>

                <OEBGLoadingDisplay moduleId={this.props.moduleId}></OEBGLoadingDisplay>

                <OEOverlayContainer
                    moduleId={this.props.moduleId}
                    target={this.props.target}
                    insets={{top: 0, right: 0, bottom: this.state.bottomEdgeOffset.value, left: this.state.leftSlideInContainer.offset}}
                    animate={{top: false, right: false, bottom: this.state.bottomEdgeOffset.animate, left: this.state.leftSlideInContainer.animate}}
                    onEdgeOffsetsChanged={this.onOverlayEdgeOffsetsChanged}
                />

                {
                /*
                <OEMediaViewerController
                    className="test-media-viewer"
                    dataSource={OEMediaViewerController.getTestDataSource()}
                    showControlsWhenWindow={true}
                />
                */
                }

                {config.hasMediaCenter ? (
                    <OEMediaCenter
                        moduleId={this.props.moduleId}
                        insets={{left: this.state.leftSlideInContainer.offset}}
                        animate={this.state.leftSlideInContainer.animate}
                        onEdgeOffsetsChanged={this.onMediaCenterEdgeOffsetsChanged}
                    />
                ) : null}

                <div
                    id="left-slide-in-container"
                    className="left-slide-in-container"
                    style={{
                        width: leftWidth.toString() + 'px',
                        left: leftLeft.toString() + 'px',
                        transition: OEToolbox.transitionForInsets({left: this.state.leftSlideInContainer.animate})
                    }}
                >
                
                    <OELeftWidgetToolbar
                        uiLevel={this.state.uiLevel}
                        moduleId={this.props.moduleId} 
                        widgetConfig={config.widgetConfig}
                        boundariesElement={this.ref}
                        onShouldBeVisible={this.onShouldBeVisibleLeftWidgetToolbar}
                    >
                        <OEResizeObserver onResize={this.onLeftWidgetToolbarResize} />
                    </OELeftWidgetToolbar>

                    <OEMainMenu
                        uiLevel={this.state.uiLevel}
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        buttons={config.mainMenuButtons}
                        config={config.mainMenuConfig}
                        widgetConfig={config.widgetConfig}
                        onWidthChanged={this.onMainMenuWidthChanged}
                    />
                
                </div>
                
                <OEMainMenuControlBar
                    moduleId={this.props.moduleId}
                    style={{
                        left: controlButtonLeft.toString() + 'px',
                        transition: OEToolbox.transitionForInsets({left: this.state.leftSlideInContainer.animate})
                    }}
                    onMainMenuControlBtnPressed={this.onMainMenuControlBtnPressed}
                />

                <OEBottomWidgetToolbar
                    moduleId={this.props.moduleId}
                    insets={{left: this.state.leftSlideInContainer.offset}}
                    animate={this.state.leftSlideInContainer.animate}
                    onEdgeOffsetsChanged={this.onBottomBarWidgetToolbarEdgeOffsetsChanged}
                    config={config.widgetConfig.bottomWidgetToolbar}
                />

                <OEContextMenu moduleId={this.props.moduleId}/>

                {config.disableTutor ? null :
                    <OETutorController
                        moduleId={this.props.moduleId}
                        margin={this.state.tutorMargin}
                    />
                }

                {this.hasMainMenuBtn(OEMainMenuStdBtnType.label) ? <OELabelController {...popoverControllerProps} target='mm-label-btn'/> : null}
                {this.hasMainMenuBtn(OEMainMenuStdBtnType.cut) ? <OECutController {...popoverControllerProps} target='mm-cut-btn'/> : null}
                {this.hasMainMenuBtn(OEMainMenuStdBtnType.settings) ? <OESettingsController {...popoverControllerProps} target='mm-settings-btn'/> : null}
                {this.hasMainMenuBtn(OEMainMenuStdBtnType.substructure) ? <OESubstructureController {...popoverControllerProps} target='mm-substructure-btn'/> : null}
                {this.hasMainMenuBtn(OEMainMenuStdBtnType.search) ? <OESearchController {...popoverControllerProps} target='mm-search-btn' /> : null}
                {this.hasMainMenuBtn(OEMainMenuStdBtnType.presentation) ? <OEPresentationController {...popoverControllerProps} target='mm-presentation-btn'/> : null}
                {this.hasMainMenuBtn(OEMainMenuStdBtnType.note) ? <OENoteController {...popoverControllerProps} target='mm-note-btn'/> : null}
                {this.hasMainMenuBtn(OEMainMenuStdBtnType.arrow) ? <OEArrowController {...popoverControllerProps} target='mm-arrow-btn'/> : null}
                
                <OENoteControllerPopover {...popoverControllerProps} ref={this.onNotePopoverRef}/>
                <OEArrowPopoverController {...popoverControllerProps} ref={this.onArrowPopoverRef}/>

                {this.hasMainMenuBtn(OEMainMenuStdBtnType.screenshot) ? <OEScreenshotController ref={this.onScreenshotViewRef} {...popoverControllerProps} target='mm-screenshot-btn'/> : null}

                <OETextViewController ref={this.onTextViewRef} {...popoverControllerProps} target='mm-text-view-btn'/>

                <OEStartView moduleId={this.props.moduleId} ref={this.onStartViewRef}/>

                <OEWelcomeView moduleId={this.props.moduleId} ref={this.onWelcomeViewRef}/>

                <OEInfoViewController
                    appComponent={this.props.appComponent}
                    moduleId={this.props.moduleId}
                    config={config.infoViewConfig}
                    ref={this.onInfoViewRef}
                />

                <OEHelpView moduleId={this.props.moduleId} ref={this.onHelpViewRef}/>

                <OEManualView moduleId={this.props.moduleId} ref={this.onManualViewRef}/>

                <OERightTopBar
                    moduleId={this.props.moduleId}
                    style={{
                        right: this.state.mediaCenterEdgeOffsets.right.toString() + 'px',
                        transition: OEToolbox.transitionForInsets({ right: this.state.mediaCenterEdgeOffsets.animate })
                    }}
                />

                <OEDevTools
                    moduleId={this.props.moduleId}
                    style={{
                        right: this.state.mediaCenterEdgeOffsets.right.toString() + 'px',
                        bottom: this.state.bottomEdgeOffset.value,
                        left: Math.max(this.state.leftSlideInContainer.offset, 0),
                        transition: OEToolbox.transitionForInsets({
                            right: this.state.mediaCenterEdgeOffsets.animate, 
                            bottom: this.state.bottomEdgeOffset.animate, 
                            left: this.state.leftSlideInContainer.animate
                        })
                    }}
                />

                <OEBlurCoreLayer appComponent={this.props.appComponent} moduleId={this.props.moduleId} radius={8}/>
                <OEBlurUILayer appComponent={this.props.appComponent} moduleId={this.props.moduleId} radius={8}/>
                <OEBlurAllLayer appComponent={this.props.appComponent} moduleId={this.props.moduleId} radius={8}/>

            </div>
        );
    }

    onMediaCenterEdgeOffsetsChanged(offsets, animated)   {
        let animate = typeof(animated) === 'undefined' ? false : animated;

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.mediaCenterEdgeOffsets = clone(offsets);
            newState.mediaCenterEdgeOffsets.animate = animate;
            newState.bottomEdgeOffset = {value: Math.max(offsets.bottom, newState.bottomBarWidgetToolbarEdgeOffsets.bottom), animate: animate};
            return newState;
        });

        this.props.moduleEnv.component.uiStateManager.setState((prevState) => {
            let state = clone(prevState);
            state.mediaCenter.edgeOffsets = Object.assign({animate: animate}, offsets);
            state.bottomEdgeOffset = {value: Math.max(offsets.bottom, state.bottomBarWidgetToolbar.edgeOffsets.bottom), animate: animate};
            return state;
        });

        /*
        this.props.setModuleEnv((prevEnv) => {
            let env = clone(prevEnv);
            env.ui.mediaCenter.edgeOffsets = Object.assign({animate: animate}, offsets);
            env.ui.bottomEdgeOffset = {value: Math.max(offsets.bottom, env.ui.bottomBarWidgetToolbar.edgeOffsets.bottom), animate: animate};
            return env;
        });
        */
    }

    onShouldBeVisibleLeftWidgetToolbar(visible, animated) {
        if(visible === this.leftWidgetToolbarShown) return;
        this.leftWidgetToolbarShown = visible;
        this.updateLeftSlideInContainer(animated);
    }

    onLeftWidgetToolbarResize(sender, size) {
        this.leftWidgetSizeObserver = sender;
        this.updateLeftSlideInContainer(false);
    }

    onMainMenuWidthChanged(width)   {
        this.mainMenuWidth = width;

        this.props.moduleEnv.component.uiStateManager.updateState({mainMenu: {width : {$set: width}}});
        //this.props.updateModuleEnv({ui: {mainMenu: {width : {$set: width}}}});
        this.updateLeftSlideInContainer(false);
    }

    onMainMenuControlBtnPressed()   {
        this.closeAllPopovers();
        this.showLeftSlideIn(!this.leftSlideInShown, true);
    }

    onBottomBarWidgetToolbarEdgeOffsetsChanged(offsets, animated)   {
        let animate = typeof(animated) === 'undefined' ? false : animated;

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.bottomBarWidgetToolbarEdgeOffsets = clone(offsets);
            newState.bottomBarWidgetToolbarEdgeOffsets.animate = animate;
            newState.bottomEdgeOffset = {value: Math.max(offsets.bottom, newState.mediaCenterEdgeOffsets.bottom), animate: animate};
            return newState;
        });

        this.props.moduleEnv.component.uiStateManager.setState((prevState) => {
            let state = clone(prevState);
            state.bottomBarWidgetToolbar.edgeOffsets = Object.assign({animate: animate}, offsets);
            state.bottomEdgeOffset = {value: Math.max(offsets.bottom, state.mediaCenter.edgeOffsets.bottom), animate: animate};
            return state;
        });
        /*
        this.props.setModuleEnv((prevEnv) => {
            let env = clone(prevEnv);
            env.ui.bottomBarWidgetToolbar.edgeOffsets = Object.assign({animate: animate}, offsets);
            env.ui.bottomEdgeOffset = {value: Math.max(offsets.bottom, env.ui.mediaCenter.edgeOffsets.bottom), animate: animate};
            return env;
        });
        */
    }
}

OEUILayer.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.uiLayer()
};

OEUILayer.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        mainMenuButtons: OEMainMenu.propTypes.buttons,
        mainMenuConfig: OEMainMenu.propTypes.config,
        widgetConfig: PropTypes.object,
        hasMediaCenter: PropTypes.bool,
        disableTutor: PropTypes.bool
    }).isRequired
};

export default connectAppEnv((env) => { return {
    appComponent: env.component,
    target: env.config.target,
    version: env.config.version,
    config: env.config.module.uiLayerConfig
}})(addModuleEnv(OEUILayer));