import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {connectAppEnv} from './app-env';
import {withModuleEnv, addModuleEnv} from './oe-module-env';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEStatusType, OEStatus, OESurface} from '../react-oe/oe-surface';
import {OETarget} from '../react-oe/oe-target';
import {OEFeatureAccessTypes} from '../lib/oe-types';
import OEModuleUIStateManager from './oe-module-ui-state-manager';
import OEUILayer from './oe-ui-layer';
import OELoadingScreen from './oe-loading-screen';
import OEBlockingView from './oe-blocking-view';
import {OEModalContainer} from './modals/oe-modal';
import {OEWindowManager, oeWindowManager} from '../lib/oe-window';
import OELicensingManager from './oe-licensing-manager';
import OEThemeWaitingController from './oe-theme-waiting-controller';
import OEStatusDlgHandler from './modals/oe-status-dlg-handler';
import OEResizeObserver from '../lib/oe-resize-observer';
import OECapBar from './bars/cap/oe-cap-bar';

export class OEModule extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.props.appComponent.module = this;
        this.props.moduleEnv.component.module = this;

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {    
            status: new OEStatus(),
            lsKey: 0
        };

        this.onStatusChanged = this.onStatusChanged.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onCapBarResize = this.onCapBarResize.bind(this);

        this.onWaitingControllerRef = this.onWaitingControllerRef.bind(this);
        this.onModalContainerRef = this.onModalContainerRef.bind(this);
        this.onWindowManagerRef = this.onWindowManagerRef.bind(this);
    }

    componentDidUpdate(prevProps, prevState)    {
        if(this.state.status.type !== prevState.status.type)    {
            //console.log('status changed:' + this.state.status.type)

            // note that lsKey is set as the key property of a div enclosing the loadingScreen and corresponding VelocityTransitionGroup
            // this fixes the black screen problem when changing the target while still loading one
            // the reason for the black screen is the VelocityTransitionGroup fade and as expected the problem only arises if the status already reached 'initialized'
            // changing the key causes a complete replacing of the dom elements circumventing VelocityTransitionGroup side effects
            if(prevState.status.type === OEStatusType.initialized)  {
                this.setState((prevState, props) => {
                    let newState = clone(prevState);
                    newState.lsKey = newState.lsKey + 1;
                    return newState;
                });
            }
        }
    }

    onConnect()  {
        if(this.props.onConnect) this.props.onConnect(this, this.oe);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()  {
        if(this.props.onRelease) this.props.onRelease(this, this.oe);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()    {
        const si = this.oe.sharedInterface;

        let strings = {
            toggle: {
                on: si.getLocalizedStringEnc('switch_on'),
                off: si.getLocalizedStringEnc('switch_off')
            }
        };

        this.props.updateModuleEnv({strings: {$set: strings}});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    addModal(title, msg, buttonFunc, options)  {
        this.modalContainer.addModal(title, msg, buttonFunc, options);
    }

    onStatusChanged(status) {
        this.setState({ status: new OEStatus(status) });

        this.statusHandler = this.statusHandler || new OEStatusDlgHandler();
        this.statusHandler.onStatusChanged(status, this.modalContainer);

        this.props.onStatusChanged(status);
    }

    onCapBarResize(sender, size)    {
        this.props.moduleEnv.component.uiStateManager.updateState({capBar: {size : {$set: size}}});
        //this.props.updateModuleEnv({ui: {capBar: {size : {$set: size}}}});
    }

    onWaitingControllerRef(ref)    {
        this.props.appComponent.waitingController = ref;
    }

    onModalContainerRef(ref)    {
        this.modalContainer = ref;
    }

    onWindowManagerRef(ref)    {
        oeWindowManager.instance = ref;
    }

    render() {
        let config = this.props.config;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>

                <OEModuleUIStateManager moduleId={this.props.moduleId}/>

                {
                    !(config.featureAccess && config.featureAccess.accessType === OEFeatureAccessTypes.licensing) ? null :
                        <OELicensingManager moduleId={this.props.moduleId}/>
                }

                <div id={this.props.moduleId + '-container'} className={this.props.moduleId + '-container oe-module-container'}>

                    <OEBlockingView moduleId={this.props.moduleId}/>

                    <OEThemeWaitingController className="screenshot-filter-hide" ref={this.onWaitingControllerRef}/>

                    <div className="cap-bar-ui-layer-container">
                        <OECapBar moduleId={this.props.moduleId} opaque={false}>
                            <OEResizeObserver onResize={this.onCapBarResize} />
                        </OECapBar>

                        <OEUILayer moduleId={this.props.moduleId}/>
                    </div>

                    <div key={this.state.lsKey}>
                        <OELoadingScreen moduleId={this.props.moduleId} status={this.state.status} target={this.props.target}/>
                    </div>

                    <OESurface
                        moduleId={this.props.moduleId}
                        target={this.props.target}
                        resourceTarget={this.props.resourceTarget}
                        {...config}
                        onStatusChanged={this.onStatusChanged}
                    />

                    <OEModalContainer ref={this.onModalContainerRef}/>

                    <OEWindowManager ref={this.onWindowManagerRef}/>

                </div>
            </React.Fragment>
        );
    }
}

OEModule.defaultProps = {
    moduleId: 'oe-embed',
    target: OETarget.humanSkull,
    resourceTarget: null,
    config: {
        version: null,
        flags: null,
        labelsEnabled: false,
        labelsLatin: false,
        featureAccess: {accessType: 'none'},
        link: null,
        uiLink: null,
        linkRef: null,
        uiLinkRef: null,
        uiStateMC: null
    },
    onStatusChanged: (status) => {},
    onConnect: (sender, oe) => {},
    onRelease: (sender, oe) => {},
};

OEModule.propTypes = {
    moduleId: PropTypes.string,
    target: PropTypes.string,
    resourceTarget: PropTypes.string,
    config: PropTypes.shape({
        version: PropTypes.string,
        flags: PropTypes.arrayOf(PropTypes.string),
        labelsEnabled: PropTypes.bool,
        labelsLatin: PropTypes.bool,
        featureAccess: PropTypes.shape({
            accessType: PropTypes.oneOf([
                OEFeatureAccessTypes.none,
                OEFeatureAccessTypes.licensing,
                OEFeatureAccessTypes.full
            ]).isRequired
        }),
        link: PropTypes.string,
        uiLink: PropTypes.string,
        linkRef: PropTypes.string,
        uiLinkRef: PropTypes.string,
        uiStateMC: PropTypes.shape({
            visible: PropTypes.bool,
            category: PropTypes.number,
            item: PropTypes.number,
            resourceId: PropTypes.string,
            subItem: PropTypes.number,
            progress: PropTypes.number
        })
    }).isRequired,
    onStatusChanged: PropTypes.func,
    onConnect: PropTypes.func,
    onRelease: PropTypes.func
};

export default connectAppEnv((env) => { return {
    appComponent: env.component,
    target: env.config.target,
    resourceTarget: env.config.resourceTarget,
    config: env.config.module
}})(withModuleEnv(addModuleEnv(OEModule)));