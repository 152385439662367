import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {connectAppEnv} from '../../app-env';
import {frefFromRef, frefToRef} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEStdModalController from '../../modals/oe-std-modal-controller';
import {OENavigationController} from '../../../lib/oe-navigation-controller';
import {OEBlurModalBackdropLayer} from '../../oe-blur-layer';
import {OEToolbox} from '../../../lib/oe-toolbox';

export class OEFlowNavModal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.options = this.props.options;
        this.isOpen_ = false;

        this.navRef = null;

        this.stateTitle = {text: ''};

        this.state = {
            title: clone(this.stateTitle),
            isOpen: this.isOpen_,
            options: this.options,
            showCloseBtn: true,
            showLogo: true,
            navRef: this.navRef,
            doBlur: true
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onNavRef = this.onNavRef.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.onStackTopChanged = this.onStackTopChanged.bind(this);
        this.onStackChanged = this.onStackChanged.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
    }

    setOpen(open = true, options)   {
        let optionsChanged = false;

        if((open || typeof(this.props.isOpen) === 'boolean') && !this.props.options && this.options != options)    {
            this.options = options;
            optionsChanged = true;
            if(typeof(this.props.isOpen) === 'boolean') {
                this.setState({options: this.options});
                this.onOptionsChanged();
                return;
            }
        }

        if(typeof(this.props.isOpen) === 'boolean') {
            if(optionsChanged)  this.onOptionsChanged();
            return;
        }

        let isOpenChanged = this.isOpen_ != open;

        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_, options: this.options});

        if(isOpenChanged && this.props.onOpenStateChanged)  this.props.onOpenStateChanged(this, this.isOpen_);
        if(optionsChanged)  this.onOptionsChanged();
    }

    open(options) {
        this.setOpen(true, options);
    }

    close() {
        this.setOpen(false);
    }

    isOpen()    {
        return this.isOpen_;
    }

    toggle()    {
        this.setOpen(!this.isOpen());
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.options !== this.props.options)     {
            this.options = nextProps.options;
            this.setState({options: this.options});
            this.onOptionsChanged();
        }

        if(nextProps.showCloseBtnAllways !== this.props.showCloseBtnAllways)     {
            this.updateShowCloseBtn();
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        setTimeout(this.onTimeout.bind(this), 500);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onTimeout() {
        if(this.props.config.showAtStartUp && this.oe.isReady() && (!this.props.config.targetViewShowedAtStartFlag || !this.props.appState.targetViewShowedAtStart) && localStorage.getItem('dontShowStartViewAtStartUp') !== 'true')   {
            this.setOpen(true);
            if(this.props.config.targetViewShowedAtStartFlag)   this.props.setAppState({targetViewShowedAtStart: true});
        }
    }

    onOptionsChanged()  {
        if(this.props.onOptionsChanged) this.props.onOptionsChanged(this, this.options);
        this.updateShowCloseBtn();
    }

    updateLanguage()   {
        this.updateTitle();
    }

    unpackTitle(title)  {
        if(typeof(title) === 'object')  return title;
        if(typeof(title) === 'string')  return {titleId: title};
    }

    updateTitle(title)    {
        if(!this.oe.isReady())  return;
        let unpacked = this.unpackTitle(title);
        this.title = unpacked ? unpacked : this.title;

        let text = !this.title ? '' : (!this.title.titleId || this.title.titleId === '' ? (this.title.text ? this.title.text : '') : this.oe.sharedInterface.getLocalizedStringEnc(this.title.titleId));

        let stateTitle = {text: text, icon: this.title ? this.title.icon : undefined};
        if(!OEToolbox.shallowEqual(stateTitle, this.stateTitle))    {   // we are cautious here and making a diff before updating to prevent update loops with OENavigationController
            this.stateTitle = stateTitle;
            this.setState({title: clone(stateTitle)});
        }
    }

    updateShowCloseBtn(stackSize)    {
        if(typeof(stackSize) != 'number')    stackSize = this.navRef ? this.navRef.stackSize() : 0;
        this.setState({showCloseBtn: stackSize > 1 || this.props.showCloseBtnAllways});
    }

    updateShowLogo(stackSize)    {
        if(typeof(stackSize) != 'number')    stackSize = this.navRef ? this.navRef.stackSize() : 0;
        let top = this.navRef ? this.navRef.top() : undefined;

        let showLogo = stackSize <= 1;
        if(top && top.view.props.showLogo)  showLogo = true;
        this.setState({showLogo: showLogo});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    onNavRef(ref)  {
        if(this.navRef === ref) return;
        this.navRef = ref;
        this.setState({navRef: ref});
        if(this.props.onNavRef)   this.props.onNavRef(this.navRef);
        if(this.navRef) this.onStackChanged();
    }

    render() {
        let isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;

        let childrenArray = React.Children.toArray(this.props.children);
        
        let children = !this.state.navRef ? null : childrenArray.map(child => React.cloneElement(child, {navigationController: this.state.navRef}));

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEStdModalController
                    moduleId={this.props.moduleId}
                    className={'simple-header clear-backdrop flow-nav-modal blur-by-manual-view ' + this.props.className}
                    title={this.state.title}
                    logo={this.props.showLogo && this.state.showLogo ? this.props.config.logo : null}
                    isOpen={isOpen}
                    hasCloseBtn={this.state.showCloseBtn}
                    onToggle={this.onToggle}
                >
                    <OENavigationController ref={this.onNavRef} onStackChanged={this.onStackChanged} onStackTopChanged={this.onStackTopChanged} onTitleChanged={this.onTitleChanged}/>

                    {children}

                    <OEBlurModalBackdropLayer/>
                </OEStdModalController>
            </React.Fragment>
        );
    }

    onToggle() { 
        if(this.props.onToggle)  {
            this.props.onToggle();
        } else {
            if(this.navRef && this.navRef.stackSize() > 1) {
                this.navRef.pop();
            } else {
                this.setOpen(false);
            }
        }
    }

    onStackChanged(sender, size)    {
        this.updateShowCloseBtn(size);
        this.updateShowLogo(size);
    }

    onStackTopChanged(sender) {}

    onTitleChanged(sender, title)   {
        this.updateTitle(title);
    }
}

OEFlowNavModal.defaultProps = {
    moduleId: '',
    className: '',
    config: {
        logo: null,
        showAtStartUp: true,
        targetViewShowedAtStartFlag: false
    },
    showLogo: true,
    showCloseBtnAllways: true
};

OEFlowNavModal.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    options: PropTypes.string,
    config: PropTypes.shape({
        logo: PropTypes.string,
        showAtStartUp: PropTypes.bool,
        targetViewShowedAtStartFlag: PropTypes.bool
    }),
    onNavRef: PropTypes.func,
    onOpenStateChanged: PropTypes.func,
    onOptionsChanged: PropTypes.func,
    showLogo: PropTypes.bool,
    showCloseBtnAllways: PropTypes.bool,
    onToggle: PropTypes.func
};

export default frefFromRef(connectAppEnv((env) => { return {
    appState: env.state,
    setAppState: env.setState
}})(frefToRef(OEFlowNavModal)));