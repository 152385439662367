import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEPresentationItem from './oe-presentation-item';
import OEScrollbars from '../../oe-scrollbars';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEPresentationList extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.presentations = [];

        this.state = {
            uiEnabled: false,
            presentations: [],
            activePresID: -2
        };

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
        this.onPresentationAdded = this.onPresentationAdded.bind(this);
        this.onPresentationRemoved = this.onPresentationRemoved.bind(this);

        this.onItemClicked = this.onItemClicked.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationRemoved, this.onPresentationRemoved);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationRemoved, this.onPresentationRemoved);
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled()
        });
    }

    updatePresentations()   {
        let controller = this.oe.sharedInterface.getUIControllerPresentation();
        let presentations = controller.getPresentationDataList();

        this.presentations = presentations.map((pres) => {
            return {id: pres.id};
        });

        this.setState({ presentations: Array.from(this.presentations) });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.presentations = [];
            this.setState({ uiEnabled: false, presentations: [], activePresID: -2 });
            return;
        }

        retardUpdate(this, () => {
            this.updatePresentations();
            this.onActivePresentationChanged();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
        }
    }

    onActivePresentationChanged(message, userInfo)   {
        let activePresID = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerPresentation().getActivePresentationID() : userInfo.presID;
        this.setState({activePresID: activePresID});
    }

    onPresentationAdded(message, userInfo)   {
        let presIndex = userInfo.presentation;
        let pres = userInfo.presData;
        this.presentations.splice(presIndex, 0, {id: pres.id});
        this.setState({ presentations: Array.from(this.presentations) });
    }

    onPresentationRemoved(message, userInfo)   {
        let presIndex = userInfo.presentation;
        this.presentations.splice(presIndex, 1);
        this.setState({ presentations: Array.from(this.presentations) });
    }

    render() {
        let disabled = !(this.state.uiEnabled);

        const presentations = this.state.presentations !== null ? this.state.presentations.map((presentation, index) =>
            <OEPresentationItem
                key={presentation.id}
                className={this.props.itemClassName}
                moduleId={this.props.moduleId}
                disabled={disabled}
                selected={presentation.id === this.state.activePresID}
                id={presentation.id}
                presID={presentation.id}
                index={index}
                showIndex={this.props.showIndices}
                onClick={this.onItemClicked}
            />
        ) : null;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEScrollbars 
                    className={this.props.className + ' presentations-view'}
                    style={this.props.style}
                >
                    <ul>{presentations}</ul>
                </OEScrollbars>
            </React.Fragment>
        );
    }

    onItemClicked(id)   {
        this.oe.sharedInterface.getUIControllerPresentation().setActivePresentationID(id);
    }
}

OEPresentationList.defaultProps = {
    className: '',
    itemClassName: '',
    moduleId: '',
    style: null,
    showIndices: false
};

OEPresentationList.propTypes = {
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    moduleId: PropTypes.string,
    showIndices: PropTypes.bool
};