import {OETarget} from '../../../react-oe/oe-target';
import {OETargetMenuDefs} from '../../elements/target-menu/oe-target-menu-defs'

export const OEImplantcastTargetType = {
    anatomy: 'anatomy',
    op: 'op'
};

export const OEImplantcastTargets = {

    opTargets: [
        OETarget.humanKneeImplantcast,
        OETarget.implantcastACSMB4in1,
        OETarget.implantcastACSSCFB,
        OETarget.implantcastAGILONMI,
        OETarget.implantcastACSSCMB,
        OETarget.implantcastMutarsDistFemurMK,
        OETarget.implantcastMUTARSGenuXMK,
        OETarget.implantcastMUTARSKRIMK,
        OETarget.implantcastEcoFitHipStem133123,
        OETarget.implantcastEcoFitHipStem,
        OETarget.implantcastEcoFitShortStem,
        OETarget.implantcastEcoFitCup,
        OETarget.implantcastActiniaStem,
        OETarget.implantcastShoulderApproachAnterior,
        OETarget.implantcastTestTarget,
// @ADD_TARGET_IMPLANTCAST_OPTLIST
        OETarget.implantcastAidaShortStem,
        OETarget.implantcastAGILONTraumalongfit,
        OETarget.implantcastMutarsRS,
        OETarget.implantcastDirectAnteriorApproach,
        OETarget.implantcastEcoFitCupEPORE,
        OETarget.implantcastMUTARSPRS,
        OETarget.implantcastAGILONOmarthrosisLongFit,
    ],

    anatomyTargets: [
        OETarget.humanLeg,
        OETarget.humanHip2,
        OETarget.humanArm,
        OETarget.humanUpperArmElbow,
        OETarget.humanShoulderGirdle
    ],

    opDefault: OETarget.implantcastEcoFitCup,
    
    anatomyDefault: OETarget.humanLeg,

    isOpTarget: function(target)    {
        return OEImplantcastTargets.opTargets.includes(target);
    },

    targetType: function(target)    {
        return OEImplantcastTargets.isOpTarget(target) ? OEImplantcastTargetType.op : OEImplantcastTargetType.anatomy;
    }
};

export const OEImplantcastStartSelectionData = {

    std: function() {
        return [
        {
            label: {text: 'Hüfte', id: 'welcome_view_selection_implantcast_1_short'},
            subcategories: [
                {
                    label: {text: ''},
//@ADD_TARGET_IMPLANTCAST_TARGETMENU
                    items: [ OETargetMenuDefs.implantcastDirectAnteriorApproach ]
                },
                {
                    label: {text: 'Primärendoprothetik Hüftschäfte', id: 'welcome_view_selection_implantcast_1_1'},
                    items: [
                        OETargetMenuDefs.implantcastActiniaStem, OETargetMenuDefs.implantcastAidaShortStem, OETargetMenuDefs.implantcastEcoFitHipStem133123, OETargetMenuDefs.implantcastEcoFitHipStem, OETargetMenuDefs.implantcastEcoFitShortStem
                    ]
                }, 
                {
                    label: {text: 'Primärendoprothetik Hüftköpfe / -pfannen', id: 'welcome_view_selection_implantcast_1_2'},
                    items: [OETargetMenuDefs.implantcastEcoFitCup, OETargetMenuDefs.implantcastEcoFitCupEPORE, ]
                }, 
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_1_3'},
                    items: [OETargetMenuDefs.implantcastMutarsRS, OETargetMenuDefs.implantcastMUTARSPRS]
                },
            ]
        },
        {
            label: {text: 'Knie', id: 'welcome_view_selection_implantcast_2_short'},
            subcategories: [
                {
                    label: {text: 'Primärendoprothetik', id: 'welcome_view_selection_implantcast_2_1'},
                    items: [OETargetMenuDefs.humanKneeImplantcast, OETargetMenuDefs.implantcastACSMB4in1]
                },
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_2_2'},
                    items: [
                        OETargetMenuDefs.implantcastACSSCFB, OETargetMenuDefs.implantcastACSSCMB, OETargetMenuDefs.implantcastMutarsDistFemurMK, OETargetMenuDefs.implantcastMUTARSGenuXMK, OETargetMenuDefs.implantcastMUTARSKRIMK
                    ]
                }, 
                {
                    label: {text: 'Arthrodesen', id: 'welcome_view_selection_implantcast_2_3'},
                    items: []
                }
            ]
        },
        {
            label: {text: 'Schulter', id: 'welcome_view_selection_implantcast_3_short'},
            subcategories: [
                {
                    label: {text: 'Primärendoprothetik', id: 'welcome_view_selection_implantcast_3_1'},
                    items: [OETargetMenuDefs.implantcastAGILONMI, OETargetMenuDefs.implantcastAGILONTraumalongfit, OETargetMenuDefs.implantcastAGILONOmarthrosisLongFit]
                }, 
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_3_2'},
                    items: []
                }
            ]
        },
        {label: {text: 'Sprung-gelenk', id: 'welcome_view_selection_implantcast_4_short'}},
        {label: {text: 'Ellen-bogen', id: 'welcome_view_selection_implantcast_5_short'}},
        {label: {text: 'Daumen', id: 'welcome_view_selection_implantcast_6_short'}}
        ];
    },

    beta: function() {
        return [
        {
            label: {text: 'Hüfte', id: 'welcome_view_selection_implantcast_1_short'},
            subcategories: [
                {
                    label: {text: ''},
                    items: [ OETargetMenuDefs.implantcastDirectAnteriorApproach ]
                },
                {
                    label: {text: 'Primärendoprothetik Hüftschäfte', id: 'welcome_view_selection_implantcast_1_1'},
                    items: [
                        OETargetMenuDefs.implantcastActiniaStem, OETargetMenuDefs.implantcastAidaShortStem, OETargetMenuDefs.implantcastEcoFitHipStem133123, OETargetMenuDefs.implantcastEcoFitHipStem, OETargetMenuDefs.implantcastEcoFitShortStem
                    ]
                }, 
                {
                    label: {text: 'Primärendoprothetik Hüftköpfe / -pfannen', id: 'welcome_view_selection_implantcast_1_2'},
                    items: [OETargetMenuDefs.implantcastEcoFitCup, OETargetMenuDefs.implantcastEcoFitCupEPORE, ]
                }, 
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_1_3'},
                    items: [OETargetMenuDefs.implantcastMUTARSPRS]
                },
            ]
        },
        {
            label: {text: 'Knie', id: 'welcome_view_selection_implantcast_2_short'},
            subcategories: [
                {
                    label: {text: 'Primärendoprothetik', id: 'welcome_view_selection_implantcast_2_1'},
                    items: [OETargetMenuDefs.humanKneeImplantcast, OETargetMenuDefs.implantcastACSMB4in1]
                },
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_2_2'},
                    items: [
                        OETargetMenuDefs.implantcastACSSCFB, OETargetMenuDefs.implantcastACSSCMB, OETargetMenuDefs.implantcastMutarsDistFemurMK, OETargetMenuDefs.implantcastMUTARSGenuXMK, OETargetMenuDefs.implantcastMUTARSKRIMK
                    ]
                }, 
                {
                    label: {text: 'Arthrodesen', id: 'welcome_view_selection_implantcast_2_3'},
                    items: []
                }
            ]
        },
        {
            label: {text: 'Schulter', id: 'welcome_view_selection_implantcast_3_short'},
            subcategories: [
                {
                    label: {text: 'Primärendoprothetik', id: 'welcome_view_selection_implantcast_3_1'},
                    items: [OETargetMenuDefs.implantcastAGILONMI, OETargetMenuDefs.implantcastAGILONTraumalongfit, OETargetMenuDefs.implantcastAGILONOmarthrosisLongFit]
                }, 
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_3_2'},
                    items: []
                }
            ]
        },
        {label: {text: 'Sprung-gelenk', id: 'welcome_view_selection_implantcast_4_short'}},
        {label: {text: 'Ellen-bogen', id: 'welcome_view_selection_implantcast_5_short'}},
        {label: {text: 'Daumen', id: 'welcome_view_selection_implantcast_6_short'}}
        ];
    },

    production: function() {
        return [
        {
            label: {text: 'Hüfte', id: 'welcome_view_selection_implantcast_1_short'},
            subcategories: [
                {
                    label: {text: 'Primärendoprothetik Hüftschäfte', id: 'welcome_view_selection_implantcast_1_1'},
                    items: [
                        OETargetMenuDefs.implantcastActiniaStem, OETargetMenuDefs.implantcastEcoFitHipStem133123, OETargetMenuDefs.implantcastEcoFitHipStem, OETargetMenuDefs.implantcastEcoFitShortStem
                    ]
                }, 
                {
                    label: {text: 'Primärendoprothetik Hüftköpfe / -pfannen', id: 'welcome_view_selection_implantcast_1_2'},
                    items: [OETargetMenuDefs.implantcastEcoFitCup, OETargetMenuDefs.implantcastEcoFitCupEPORE]
                }, 
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_1_3'},
                    items: []
                }
            ]
        },
        {
            label: {text: 'Knie', id: 'welcome_view_selection_implantcast_2_short'},
            subcategories: [
                {
                    label: {text: 'Primärendoprothetik', id: 'welcome_view_selection_implantcast_2_1'},
                    items: [OETargetMenuDefs.humanKneeImplantcast, OETargetMenuDefs.implantcastACSMB4in1]
                },
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_2_2'},
                    items: [OETargetMenuDefs.implantcastACSSCFB, OETargetMenuDefs.implantcastACSSCMB, OETargetMenuDefs.implantcastMutarsDistFemurMK, OETargetMenuDefs.implantcastMUTARSGenuXMK]
                }, 
                {
                    label: {text: 'Arthrodesen', id: 'welcome_view_selection_implantcast_2_3'},
                    items: []
                }
            ]
        },
        {
            label: {text: 'Schulter', id: 'welcome_view_selection_implantcast_3_short'},
            subcategories: [
                {
                    label: {text: 'Primärendoprothetik', id: 'welcome_view_selection_implantcast_3_1'},
                    items: [OETargetMenuDefs.implantcastAGILONMI]
                }, 
                {
                    label: {text: 'Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_3_2'},
                    items: []
                }
            ]
        },
        {label: {text: 'Sprung-gelenk', id: 'welcome_view_selection_implantcast_4_short'}},
        {label: {text: 'Ellen-bogen', id: 'welcome_view_selection_implantcast_5_short'}},
        {label: {text: 'Daumen', id: 'welcome_view_selection_implantcast_6_short'}}
        ];
    },

    choose: function(version, flags, target)    {
        if(flags.includes('production')) return OEImplantcastStartSelectionData.production();
        if(flags.includes('beta')) return OEImplantcastStartSelectionData.beta();
        return OEImplantcastStartSelectionData.std();
    }
};