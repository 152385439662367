import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone'

import Embed from './embed';
import {OETarget} from './oe-target';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import {OEFeatureAccessTypes} from '../lib/oe-types';
import {OEToolbox} from '../lib/oe-toolbox';

export const OECheckEnvResultType = {
    ok: 'ok',
    failedToCreateGLContext: 'failedToCreateGLContext',
    requiredExtNotSupported: 'requiredExtNotSupported',
    browserIncompatible: 'browserIncompatible',
    browserNotSupported: 'browserNotSupported'
};

export class OECheckEnvResult   {
    constructor(result) {
        var result = result || {};
        this.fatal = result.fatal || false;
        this.type = result.type || OEStatusType.ok;
        this.msg = result.msg || '';
        this.data = result.data || null;
    }
};

export const OEStatusType = {
    undefined: 'undefined',
    aborted: 'aborted',
    envWarning: 'envWarning',
    downloading: 'downloading',
    downloadingData: 'downloading_data',
    preparing: 'preparing',
    running: 'running',
    initializing: 'initializing',
    initialized: 'initialized',
    glContextLost: 'gl_context_lost',
    error: 'error'
};

export class OEStatus   {
    constructor(status) {
        let stat = status || {};
        this.type = stat.type || OEStatusType.undefined;
        this.msg = stat.msg || '';
        this.progress = stat.progress || null;

        if(stat.type === OEStatusType.aborted)  {
            this.userInfo = new Object();
            this.userInfo.checkEnvResult = new OECheckEnvResult(stat.userInfo.checkEnvResult);
        } else {
            this.userInfo = stat.userInfo || null;
        }
    }
};

export class OESurface extends React.Component {

    constructor(props) {
        super(props);

        this.onLoad = this.onLoad.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);

        this.status = new OEStatus();
    }

    updateCoreConfig(props)  {
        props = props || this.props;

        let oe = oeInterfaceManager.getInterface(props.moduleId);
        if(!oe.isPreReady()) return;

        if(typeof(oe.Module.config) !== 'object')   oe.Module.config = {}
        oe.Module.config.version = typeof(props.version) === 'string' ? clone(props.version) : undefined;
        oe.Module.config.flags = Array.isArray(props.flags) ? clone(props.flags) : undefined;

        oe.Module.config.labelsEnabled = props.labelsEnabled === true;
        oe.Module.config.labelsLatin = props.labelsLatin === true;
        oe.Module.config.featureAccess = props.featureAccess ? clone(props.featureAccess) : undefined;

        oe.Module.config.link = typeof(props.link) === 'string' ? clone(props.link) : undefined;
        oe.Module.config.uiLink = typeof(props.uiLink) === 'string' ? clone(props.uiLink) : undefined;
        oe.Module.config.linkRef = typeof(props.linkRef) === 'string' ? clone(props.linkRef) : undefined;
        oe.Module.config.uiLinkRef = typeof(props.uiLinkRef) === 'string' ? clone(props.uiLinkRef) : undefined;

        oe.Module.config.uiStateMC = props.uiStateMC ? clone(props.uiStateMC) : undefined;
    }

    connect()   {
        if(!oeInterfaceManager.connect(this.props.moduleId)) {
            this.statusChanged(new OEStatus({type: OEStatusType.error}));
            return;
        }

        // if connect returns true we are at least preReady here
        let oe = oeInterfaceManager.getInterface(this.props.moduleId);
    
        //
        //oe.injectSentry();

        this.statusChanged(new OEStatus(oe.Module.status));
        oe.sharedNotificationCenter.register(oe.NotificationName.statusChanged, this.onStatusChanged);

       this.updateCoreConfig();

        oe.startUp();
    }

    release()   {
        let oe = oeInterfaceManager.getInterface(this.props.moduleId);
        if(oe.isPreReady())   {
            oe.sharedNotificationCenter.unregister(oe.NotificationName.statusChanged, this.onStatusChanged);
        }

        oeInterfaceManager.release(this.props.moduleId);

        this.statusChanged(new OEStatus());
    }

    changeTarget(target)  {
        let oe = oeInterfaceManager.getInterface(this.props.moduleId);
        if(!oe.isReady()) return;
        this.statusChanged(new OEStatus());
        oe.changeTarget(target);
    }

    componentDidMount() {
        document.getElementById(this.props.moduleId).onload = this.onLoad;
    }

    componentWillUnmount() {
        this.release();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.props.path != nextProps.path || this.props.moduleId != nextProps.moduleId || this.props.resourceTarget != nextProps.resourceTarget)    {
            return true;
        }

        let nextCoreConfig = {
            version: nextProps.version,
            flags: nextProps.flags,
            labelsEnabled: nextProps.labelsEnabled,
            labelsLatin: nextProps.labelsLatin,
            featureAccess: nextProps.featureAccess,
            link: nextProps.link,
            uiLink: nextProps.uiLink,
            linkRef: nextProps.linkRef,
            uiLinkRef: nextProps.uiLinkRef,
            uiStateMC: nextProps.uiStateMC
        };

        let coreConfig = {
            version: this.props.version,
            flags: this.props.flags,
            labelsEnabled: this.props.labelsEnabled,
            labelsLatin: this.props.labelsLatin,
            featureAccess: this.props.featureAccess,
            link: this.props.link,
            uiLink: this.props.uiLink,
            linkRef: this.props.linkRef,
            uiLinkRef: this.props.uiLinkRef,
            uiStateMC: this.props.uiStateMC
        };

        if(!OEToolbox.jsonEqual(coreConfig, nextCoreConfig)) {
            this.updateCoreConfig(nextProps);
        }

        if(this.props.target !== nextProps.target)   {
            this.changeTarget(nextProps.target);
        }

        return false;
    }

    componentWillUpdate(nextProps, nextState)   {
        if(this.props.moduleId != nextProps.moduleId || this.props.resourceTarget != nextProps.resourceTarget)    {
            this.release();
        }
    }

    componentDidUpdate(prevProps, prevState)   {
        if(prevProps.moduleId !== this.props.moduleId || prevProps.resourceTarget != this.props.resourceTarget)  { 
            document.getElementById(this.props.moduleId).onload = this.onLoad;
            this.connect();
        }
    }

    onLoad()    {
        this.connect();
    }

    onStatusChanged(message, userInfo) {
        this.statusChanged(new OEStatus(userInfo.status));
    }

    statusChanged(status) {
        //console.log(status.type);
        this.status = status;
        this.props.onStatusChanged(new OEStatus(status));
    }

    render() {
        let src = this.props.path;

        if(this.props.target !== OETarget.void)    {
            src += 'index.html';
            let hasParam = false;

            if(typeof(this.props.target) === 'string')  {
                src += (hasParam ? '&' : '?') + 'target=' + this.props.target; hasParam = true;
            }

            if(typeof(this.props.resourceTarget) === 'string')  {
                src += (hasParam ? '&' : '?') + 'resourceTarget=' + this.props.resourceTarget; hasParam = true;
            }
        } else {
            src = '';
        }

        return (
            <Embed name={this.props.moduleId} src={src}/>
        );
    }
}

OESurface.defaultProps = {
    moduleId: 'oe-embed',
    path: 'app/oe/',
    target: OETarget.humanSkull,
    resourceTarget: null,
    version: null,
    flags: null,
    labelsEnabled: false,
    labelsLatin: false,
    featureAccess: {accessType: 'none'},
    link: null,
    uiLink: null,
    linkRef: null,
    uiLinkRef: null,
    uiStateMC: null,
    onStatusChanged: (status) => {}
};

OESurface.propTypes = {
    moduleId: PropTypes.string,
    path: PropTypes.string,
    target: PropTypes.string,
    resourceTarget: PropTypes.string,
    version: PropTypes.string,
    flags: PropTypes.arrayOf(PropTypes.string),
    labelsEnabled: PropTypes.bool,
    labelsLatin: PropTypes.bool,
    featureAccess: PropTypes.shape({
        accessType: PropTypes.oneOf([
            OEFeatureAccessTypes.none,
            OEFeatureAccessTypes.licensing,
            OEFeatureAccessTypes.full
        ]).isRequired
    }),
    link: PropTypes.string,
    uiLink: PropTypes.string,
    linkRef: PropTypes.string,
    uiLinkRef: PropTypes.string,
    uiStateMC: PropTypes.shape({
        visible: PropTypes.bool,
        category: PropTypes.number,
        item: PropTypes.number,
        resourceId: PropTypes.string,
        subItem: PropTypes.number,
        progress: PropTypes.number
    }),
    onStatusChanged: PropTypes.func
};