import React from 'react';
import PropTypes from 'prop-types';

export default class OESlider extends React.Component {

    shouldComponentUpdate() {
        return false;
    }

    componentDidMount() {
        this.$node = $(this.refs.slider);

        this.$node.slider({
            disabled: this.props.disabled, 
            min: this.props.min, 
            max: this.props.max, 
            step: this.props.step, 
            value: this.props.value, 
            animate: this.props.animate,
            start: (event, ui) => this.onStart(event, ui),
            slide: (event, ui) => this.onSlide(event, ui),
            stop: (event, ui) => { this.onSlide(event, ui); this.onEnd(event, ui); }
        });

        let $handle = this.$node.children('span.ui-slider-handle');
        $handle.append('<span class="' + $handle.attr('class') + '"></span>');
    }

    componentWillUnmount() {
        this.$node.slider('destroy');
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.disabled !== this.props.disabled)     this.$node.slider('option', 'disabled', nextProps.disabled);
        if(nextProps.min !== this.props.min)     this.$node.slider('option', 'min', nextProps.min);
        if(nextProps.max !== this.props.max)     this.$node.slider('option', 'max', nextProps.max);
        if(nextProps.step !== this.props.step)     this.$node.slider('option', 'step', nextProps.step);
        if(nextProps.animate !== this.props.animate)     this.$node.slider('option', 'animate', nextProps.animate);
        if(nextProps.value !== this.props.value)     this.$node.slider('option', 'value', nextProps.value);
        if(nextProps.className != this.props.className)    {
            this.$node.attr('class', nextProps.className);
        }
    }

    onStart(event, ui)  {
        this.props.onStart(ui.value);
    }

    onSlide(event, ui)  {
        this.props.onSlide(ui.value);
    }

    onEnd(event, ui)  {
        this.props.onEnd(ui.value);
    }

    render() {
        return (
             <div className={'slider ' + (this.props.disabled ? 'disabled ' : '') + this.props.className} ref="slider"/>
        );
    }
}

OESlider.defaultProps = {
    className: '',
    disabled: false,
    min: 0,
    max: 100,
    step: 1,
    value: 0,
    animate: true,
    onStart: function(value)    {},
    onSlide: function(value)    {},
    onEnd: function(value)  {}
};

OESlider.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    animate: PropTypes.bool,
    onStart: PropTypes.func,
    onSlide: PropTypes.func,
    onEnd: PropTypes.func
};