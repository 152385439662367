import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {OEContextMenuContentType} from '../../lib/oe-types';
import OEContextMenuInfoBox from './oe-context-menu-info-box';

export class OEContextMenuContentFactory extends React.PureComponent {
    render() {
        let key = 0;
        let infoBox = [];

        if(this.props.config)   {
            const {config, ...rest} = this.props;

            if(this.props.config.type === OEContextMenuContentType.infoBox) {
                infoBox.push((<OEContextMenuInfoBox {...rest} showReferences={config.showReferences} key={key++} />));
            }
        }

        return !infoBox.length ? null : (
            <div className="content">
                {infoBox}
            </div>
        );	
    }
}

OEContextMenuContentFactory.defaultProps = {
    moduleId: '',
    config: PropTypes.object,
    backgroundColor: {x: 0, y: 0, z: 0.5, w: 0.8},
    textColor: {x: 1, y: 1, z: 1, w: 1},
    searchString: '',
    componentId: ''
};

OEContextMenuContentFactory.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        type: PropTypes.oneOf(OEContextMenuContentType.array),
        showReferences: PropTypes.bool
    }),
    backgroundColor: PropTypes.object,
    textColor: PropTypes.object,
    searchString: PropTypes.string,
    componentId: PropTypes.string
};

export default connectAppEnv((env) => { return {
    version: env.config.version,
    target: env.config.target
}})(OEContextMenuContentFactory);