import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEPresentationSlideList from '../controller/presentation/oe-presentation-slide-list';
import OEPresentationAnimationController from '../controller/presentation/oe-presentation-animation-controller';
import OEColor from '../color-picker/oe-color';
import {OEColorHelper} from '../oe-color-helper';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEPresentationSlideToolbar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.shouldBeVisible = false;

        this.state = {
            uiEnabled: false,
            strings:   {},
            viewBackgroundColor: OEColorHelper.viewBackgroundColor(0.6),
            viewBorderColor: OEColorHelper.stdSeparatorColor(0.6)
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onBgColorChanged = this.onBgColorChanged.bind(this);
        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }

        if(nextProps.defaultPresentation !== this.props.defaultPresentation)     {
            this.updateDefaultPresentation(nextProps);
        }

        if(nextProps.isVisible != this.props.isVisible && nextProps.isVisible) {
            this.updateDefaultPresentation(nextProps);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);

    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
    }

    updateShouldBeVisible() {
        var shouldBeVisible = false;

        if(this.oe.isReady())   {
            var si = this.oe.sharedInterface, UIControllerType = this.oe.Module.UIControllerType;
            shouldBeVisible = si.getUIControllerVisible(UIControllerType.presentation) && !si.getUIControllerVisible(UIControllerType.media_center);
        }

        if(this.shouldBeVisible != shouldBeVisible) {
            this.shouldBeVisible = shouldBeVisible;

            if(typeof(this.props.onShouldBeVisibleChanged) === 'function')  {
                this.props.onShouldBeVisibleChanged();
            }
        }
    }

    updateLanguage()   {
        this.setState({
            strings: {
                title: this.oe.sharedInterface.getLocalizedStringEnc('presentation_view'),
            }
        });
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled()
        });
    }

    updateDefaultPresentation(props) {
        if(!this.oe.isReady()) return;

        var props_ = props || this.props; 

        var presController = this.oe.sharedInterface.getUIControllerPresentation();

        if(props_.isVisible && this.shouldBeVisible && typeof(props_.defaultPresentation) === 'number' && props_.defaultPresentation >= 0 && presController.getActivePresentationID() < 0)    {
            presController.setActivePresentation(props_.defaultPresentation)
            presController.apply(true);
        }
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false});
            this.updateShouldBeVisible();
            return;
        }

        retardUpdate(this, () => {
            this.onBgColorChanged();
            this.updateLanguage();
            this.onActivePresentationChanged();
            this.updateUIState();
            this.updateShouldBeVisible();
        });
    }

    onBgColorChanged(message, userInfo)  {
        var brightness = userInfo == null ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;

        this.setState({
            viewBackgroundColor: OEColorHelper.viewBackgroundColor(brightness),
            viewBorderColor: OEColorHelper.stdSeparatorColor(brightness)
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        this.updateShouldBeVisible();

        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
            this.updateDefaultPresentation();
        }
    }

    onActivePresentationChanged()   {
        // enable animation if a actual presentation is set
        this.oe.sharedInterface.getUIControllerPresentation().stopAnimation();
    }

    render() {

        var style = { 
            backgroundColor: OEColor.toDOMStr(this.state.viewBackgroundColor), 
            border: '1px solid ' + OEColor.toDOMStr(this.state.viewBorderColor)
        };

        return (
            <div className="presentation-slide-toolbar">

                <OEPresentationSlideList
                    moduleId={this.props.moduleId}
                    style={style}
                    isPlayerPreview={true}
                />

                <OEPresentationAnimationController moduleId={this.props.moduleId} />

            </div>
        );
    }
}

OEPresentationSlideToolbar.defaultProps = {
    moduleId: '',
    defaultPresentation: null,
    isVisible: false
};

OEPresentationSlideToolbar.propTypes = {
    moduleId: PropTypes.string,
    isVisible: PropTypes.bool
};
