import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEToolbox} from '../../lib/oe-toolbox';
import KeyedCurve from '../../lib/keyed-curve';
import {OEAnimationControlAdapterStd} from '../animation-control/oe-animation-adapter';
import OEOverlayAnimationControl from './elements/oe-overlay-animation-control';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEZeissUVOverlay extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.strings = {
            age: 'Age:',
            years: 'years',
            anim_overlay_anatomy_cornea: 'Cornea',
            anim_overlay_anatomy_lens: 'Lens',
            anim_overlay_anatomy_vitreousbody: 'Vitreous body',
            anim_overlay_anatomy_retina: 'Retina',
        };

        this.state = {
            visible: false,
            animationControl: {
                visible: true,
                enabled: false,
                adapter: null
            },
            wavelengthView: {
                visible: false,
                alpha: 1.0,
                strings: {
                    length: '100 nm',
                    wavelength: 'Wavelength'
                }
            },
            ageView:    {
                visible: false,
                alpha: 1.0,
                strings: {
                    content: '30 years'
                }
            },
            anatomyView:    {
                visible: false,
                alpha: 1.0,
                strings: {
                    content: ''
                }
            },
            titleView: {
                visible: false,
                alpha: 1.0,
                strings: {
                    content: ''
                }
            }
        };
        
        this.updateLanguage = this.updateLanguage.bind(this);
        this.onAnimationModeChanged = this.onAnimationModeChanged.bind(this);
        this.onAnimationTimeChanged = this.onAnimationTimeChanged.bind(this);
        this.onActiveAnimationChanged = this.onActiveAnimationChanged.bind(this);

        this.onProgressSliderChanged = this.onProgressSliderChanged.bind(this);
        this.onAnimationControlPlay = this.onAnimationControlPlay.bind(this);
        this.onAnimationControlPause = this.onAnimationControlPause.bind(this);
        this.onAnimationControlStop = this.onAnimationControlStop.bind(this);

        this.curveAni0 = new KeyedCurve();
        this.curveAni0Alpha = new KeyedCurve();
        this.curveAni1 = new KeyedCurve();
        this.curveAni2 = new KeyedCurve();
        this.curveAni3 = new KeyedCurve();
        this.curveAni4 = new KeyedCurve();
        this.curveAni9 = new KeyedCurve();
        this.curveAni10 = new KeyedCurve();

        this.curveAni0.list = [
            {key: 50, value: 'anim_overlay_anatomy_cornea'},
            {key: 150, value: 'anim_overlay_anatomy_lens'},
            {key: 250, value: 'anim_overlay_anatomy_vitreousbody'},
            {key: 350, value: 'anim_overlay_anatomy_retina'}
        ];
	
        this.curveAni0Alpha.list = [
            {key: 50, value: 0}, {key: 65, value: 1}, {key: 100, value: 1}, {key: 115, value: 0},
            {key: 150, value: 0}, {key: 165, value: 1}, {key: 200, value: 1}, {key: 215, value: 0},
            {key: 250, value: 0}, {key: 265, value: 1}, {key: 300, value: 1}, {key: 315, value: 0},
            {key: 350, value: 0}, {key: 365, value: 1}, {key: 375, value: 1}, {key: 385, value: 0}
        ];
        
        this.curveAni1.list = [
            {key: 402, value: 300},
            {key: 501, value: 340},
            {key: 601, value: 380},
            {key: 651, value: 400},
            {key: 701, value: 420},
            {key: 751, value: 440},
            {key: 801, value: 460},
            {key: 851, value: 500}
        ];
        
        this.curveAni2.list = [
            {key: 902, value: 300},
            {key: 1001, value: 340},
            {key: 1101, value: 380},
            {key: 1151, value: 400},
            {key: 1201, value: 420},
            {key: 1251, value: 440},
            {key: 1301, value: 460},
            {key: 1351, value: 500}
        ];
        
        this.curveAni3.list = [
            {key: 1402, value: 300},
            {key: 1501, value: 340},
            {key: 1601, value: 380},
            {key: 1651, value: 400},
            {key: 1701, value: 420},
            {key: 1751, value: 440},
            {key: 1801, value: 460},
            {key: 1851, value: 500}
        ];
        
        this.curveAni4.list = [
            {key: 1902, value: 10},
            {key: 2180, value: 60}
        ];
        
        this.curveAni9.list = [
            {key: 2702, value: 100},
            {key: 2800, value: 280},
            {key: 2900, value: 315},
            {key: 2999, value: 400}
        ];
        
        this.curveAni10.list = [
            {key: 3102, value: 100},
            {key: 3200, value: 280},
            {key: 3300, value: 315},
            {key: 3390, value: 400}
        ];
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    getEdgeOffsets()    {
        return this.edgeOffsets;
    }

    updateEdgeOffsets(animationControlVisible)    {
        let edgeOffsets = {top: 0, right: 0, bottom: animationControlVisible ? 50 : 0, left: 0};
        if(OEToolbox.shallowEqual(this.edgeOffsets, edgeOffsets)) return;
        this.edgeOffsets = edgeOffsets;
        if(this.props.onEdgeOffsetsChanged) this.props.onEdgeOffsetsChanged(this, this.edgeOffsets, true);
    }

    update()   {
        if(!this.oe.isReady())  { return; }
        
        let controller = this.oe.sharedInterface.getUIControllerAnimation();
        
        let animationMode = controller.getAnimationMode();
        let activeAnimation = controller.getActiveAnimation();
        let sceneTime = activeAnimation >= 0 ? controller.getAnimationSceneTimeIndex(activeAnimation) : 0;
        
        let visible = animationMode !== this.oe.Module.AnimationMode.disabled;
        let showAnimationControl = animationMode !== this.oe.Module.AnimationMode.disabled;
        let showWavelength = animationMode !== this.oe.Module.AnimationMode.disabled && [1,2,3,9,10].includes(activeAnimation);
        let showAge = animationMode !== this.oe.Module.AnimationMode.disabled && [4].includes(activeAnimation);
        let showAnatomy = animationMode !== this.oe.Module.AnimationMode.disabled && [0].includes(activeAnimation);
        let showTitle = animationMode !== this.oe.Module.AnimationMode.disabled && [11,12].includes(activeAnimation);
        
        this.setStateUpdate({
            visible: {$set: visible},
            animationControl: {
                visible: {$set: showAnimationControl}
            },
            wavelengthView: {
                visible: {$set: showWavelength}
            },
            ageView: {
                visible: {$set: showAge}
            },
            anatomyView: {
                visible: {$set: showAnatomy}
            },
            titleView: {
                visible: {$set: showTitle}
            }
        });

        this.updateEdgeOffsets(visible && showAnimationControl);
        
        let frameTime = 0.04;
        let frame = sceneTime / frameTime;
        
        if(activeAnimation === 0) {
            let alpha = this.curveAni0Alpha.interpolatedValue(frame);
            let text = this.curveAni0.valuesForKey(frame).first;
            text = this.strings[text];

            this.setStateUpdate({
                anatomyView: {
                    alpha: {$set: alpha},
                    strings: {
                        content: {$set: text}
                    }
                }
            });
            
        } else if(activeAnimation === 1) {
            let wavelength;
            if(frame > 850) {
                wavelength = '< 500' + ' nm'
            } else {
                wavelength = this.curveAni1.interpolatedValue(frame);
                wavelength = wavelength !== null ? Math.round(wavelength).toString() + ' nm' : '';
            }

            this.setStateUpdate({wavelengthView: { strings: { length: {$set: wavelength} } }});

        } else if(activeAnimation === 2) {
            let wavelength;
            if(frame > 1350) {
                wavelength = '< 500' + ' nm'
            } else {
                wavelength = this.curveAni2.interpolatedValue(frame);
                wavelength = wavelength !== null ? Math.round(wavelength).toString() + ' nm' : '';
            }

            this.setStateUpdate({wavelengthView: { strings: { length: {$set: wavelength} } }});

        } else if(activeAnimation === 3) {
            let wavelength;
            if(frame > 1850) {
                wavelength = '< 500' + ' nm'
            } else {
                wavelength = this.curveAni3.interpolatedValue(frame);
                wavelength = wavelength !== null ? Math.round(wavelength).toString() + ' nm' : '';
            }

            this.setStateUpdate({wavelengthView: { strings: { length: {$set: wavelength} } }});

        } else if(activeAnimation === 4) {
            let age;
            if(frame > 2180) {
                age = this.strings.age + ' 60+ ' + this.strings.years;
            } else {
                age = this.curveAni4.interpolatedValue(frame);
                age = age !== null ? this.strings.age + ' ' + Math.round(age).toString() + ' ' + this.strings.years : '';
            }

            this.setStateUpdate({ageView: { strings: { content: {$set: age} } }});

        } else if(activeAnimation === 9) {
            let wavelength = this.curveAni9.interpolatedValue(frame);
            wavelength = wavelength !== null ? Math.round(wavelength).toString() + ' nm' : '';
            this.setStateUpdate({wavelengthView: { strings: { length: {$set: wavelength} } }});

        } else if(activeAnimation === 10) {
            let wavelength = this.curveAni10.interpolatedValue(frame);
            wavelength = wavelength !== null ? Math.round(wavelength).toString() + ' nm' : '';
            this.setStateUpdate({wavelengthView: { strings: { length: {$set: wavelength} } }});
        }

        let titleStrId = 'anim' + OEToolbox.numberToString(activeAnimation + 1, 2) + '_title';
        this.setStateUpdate({titleView: { strings: { content: {$set: this.oe.sharedInterface.getLocalizedStringEnc(titleStrId)} } }});
    }

    updateAnimationControlAdapter()  {
        if(!this.oe.isReady()) return;
        let controller = this.oe.sharedInterface.getUIControllerAnimation();
        let activeAnimation = controller.getActiveAnimation();

        if(activeAnimation < 0)   {
            this.setStateUpdate({animationControl: { enabled: {$set: false}, adapter: {$set: null} }});
        } else {
            let adapter = new OEAnimationControlAdapterStd();
            adapter.disabledWhenStop = false;
            adapter.setBinding(this.oe, OEAnimationControlAdapterStd.Binding.animation, activeAnimation);

            this.setStateUpdate({animationControl: { enabled: {$set: true}, adapter: {$set: adapter} }});
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.animationModeChanged, this.onAnimationModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.animationTimeChanged, this.onAnimationTimeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activeAnimationChanged, this.onActiveAnimationChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.animationModeChanged, this.onAnimationModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.animationTimeChanged, this.onAnimationTimeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activeAnimationChanged, this.onActiveAnimationChanged);
    }

    updateLanguage()   {
        if(!this.oe.isReady()) return;
        const si = this.oe.sharedInterface;

        this.strings = {
            age: si.getLocalizedStringEnc('anim_overlay_age'),
            years: si.getLocalizedStringEnc('anim_overlay_years'),
            anim_overlay_anatomy_cornea: si.getLocalizedStringEnc('anim_overlay_anatomy_cornea'),
            anim_overlay_anatomy_lens: si.getLocalizedStringEnc('anim_overlay_anatomy_lens'),
            anim_overlay_anatomy_vitreousbody: si.getLocalizedStringEnc('anim_overlay_anatomy_vitreousbody'),
            anim_overlay_anatomy_retina: si.getLocalizedStringEnc('anim_overlay_anatomy_retina'),
        };

        this.setStateUpdate({
            wavelengthView: {
                strings: {
                    wavelength: {$set: si.getLocalizedStringEnc('anim_overlay_wavelength')}
                }
            }
        });

        this.update();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({visible: false});
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateAnimationControlAdapter();
        });
    }

    onAnimationModeChanged() {
        retardUpdate(this, () => {
            this.update();
        });
    }
    
    onAnimationTimeChanged() {
        retardUpdate(this, () => {
            this.update();
        });
    }
    
    onActiveAnimationChanged() {
        retardUpdate(this, () => {
            this.update();
            this.updateAnimationControlAdapter();
        });
    }

    renderAnimationControl()  {
        return (
            <OEOverlayAnimationControl
                visible={this.state.animationControl.visible && this.state.visible}
                enabled={this.state.animationControl.enabled}
                adapter={this.state.animationControl.adapter}
                onPlayBtnPressed={this.onAnimationControlPlay}
                onPauseBtnPressed={this.onAnimationControlPause}
                onStopBtnPressed={this.onAnimationControlStop}
                onProgressSliderChanged={this.onProgressSliderChanged}
            />
        );
    }

    renderWaveLengthView()  {
        const visible = this.state.wavelengthView.visible && this.state.visible;
        return (
            <div 
                className="view display wavelength screenshot-filter-show"
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
            >
                <div className="light-bg" style={{opacity: this.state.wavelengthView.alpha}}>
                    <div className="first-line">{this.state.wavelengthView.strings.length}</div>
                    <div className="second-line">{this.state.wavelengthView.strings.wavelength}</div>
                </div>
            </div>
        );
    }

    renderAgeView()  {
        const visible = this.state.ageView.visible && this.state.visible;
        return (
            <div 
                className="view display age screenshot-filter-show"
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
            >   
                <div className="light-bg" style={{opacity: this.state.ageView.alpha}}>
                    {this.state.ageView.strings.content}
                </div>
            </div>
        );
    }

    renderAnatomyView()  {
        const visible = this.state.anatomyView.visible && this.state.visible;
        return (
            <div 
                className="view display anatomy screenshot-filter-show"
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
            >
                <div className="light-bg" style={{opacity: this.state.anatomyView.alpha}}>
                    {this.state.anatomyView.strings.content}
                </div>
            </div>
        );
    }

    renderTitleView()  {
        const visible = this.state.titleView.visible && this.state.visible;
        return (
            <div 
                className="view display title screenshot-filter-show"
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
            >
                <div className="light-bg" style={{opacity: this.state.titleView.alpha}}>
                    {this.state.titleView.strings.content}
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="overlay zeiss-uv std-label-text-color">
                    {this.renderAnimationControl()}
                    {this.renderWaveLengthView()}
                    {this.renderAgeView()}
                    {this.renderAnatomyView()}
                    {this.renderTitleView()}
                </div>
            </React.Fragment>
        );
    }

    onProgressSliderChanged(progress)   {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterProgressSliderChanged, null);
    }

    onAnimationControlPlay()    {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterPlayBtnPressed, null);
    }

    onAnimationControlPause()   {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterPauseBtnPressed, null);
    }

    onAnimationControlStop()    {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterStopBtnPressed, null);
    }
}

OEZeissUVOverlay.defaultProps = {
    moduleId: ''
};

OEZeissUVOverlay.propTypes = {
    moduleId: PropTypes.string
};