import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';

import {oeInterfaceManager} from '../../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../../react-oe/oe-interface-adapter';
import OEIcon from '../../../elements/oe-icon';
import OEButton from '../../../elements/oe-button';
import {OEIconCodes} from '../../../../lib/oe-icon-codes';
import {retardUpdate} from '../../../../lib/update-retarder';
import {OEToolbox} from '../../../../lib/oe-toolbox';
import {OEIntervalEditView} from './oe-interval-edit-view';
import {OEPresentationAttrType, OEPresentationAttrLinkType} from '../oe-presentation-model';

export class OEPresentationAttributeControllerCell extends React.PureComponent  {

    constructor(props)  {
        super(props);
    }

    render()    {
        let header = typeof(this.props.header) === 'function' ? this.props.header(this.props) : this.props.header;
        return(
            <div className={'presentation-attribute-cell ' + this.props.className}>
                {!header ? null :
                    <div className="header">
                        {header}
                    </div>
                }
                <div className="body">
                    {this.props.children}
                </div>

                {this.props.separator ? <div className="std-separator-border-color separator"/> : null}
            </div>
        );
    }
};

OEPresentationAttributeControllerCell.defaultProps = {
    className: '',
    enabled: true,
    separator: true
};

OEPresentationAttributeControllerCell.propTypes = {
    className: PropTypes.string,
    enabled: PropTypes.bool,
    separator: PropTypes.bool,
    header: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

export class OEPresentationAttributeCell extends React.PureComponent    {

    constructor(props)  {
        super(props);

        this.renderHeader = this.renderHeader.bind(this);

        this.onDumpBtnPressed = this.onDumpBtnPressed.bind(this);
    }

    renderIcon()    {
        let type = this.props.attribute ? this.props.attribute.type : 0;
        if(type < 0 || type >= OEIconCodes.presSlideAttribute.types.array.length) type = 0;
        let code = OEIconCodes.presSlideAttribute.types.array[type];
        return <OEIcon className="type-icon" code={code} />;
    }

    renderDumpButton()  {
        return (
            <OEButton
                className="transparent-btn dump-btn" 
                onPressed={this.onDumpBtnPressed}
                disabled={!this.props.enabled}
            >
                <OEIcon code={OEIconCodes.presSlideAttribute.dump}/>
            </OEButton>
        );
    }

    renderHeader()  {
        if(this.props.header)   {
            return typeof(this.props.header) === 'function' ? this.props.header(this.props) : this.props.header;
        } 

        return (
            <React.Fragment>
                {this.renderDumpButton()}
                {this.props.showIcons ? this.renderIcon() : null}
                {typeof(this.props.title) === 'function' ? this.props.title(this.props) : this.props.title}
                {typeof(this.props.rightHeaderBar) === 'function' ? this.props.rightHeaderBar(this.props) : this.props.rightHeaderBar}
            </React.Fragment>
        );
    }

    render()    {
        return (
            <OEPresentationAttributeControllerCell className={this.props.className} enabled={this.props.enabled} header={this.renderHeader()}>
                {this.props.children}
            </OEPresentationAttributeControllerCell>
        );
    }

    onDumpBtnPressed()  {
        if(this.props.onDumpBtnPressed) this.props.onDumpBtnPressed(this.props.ids);
    }
};

OEPresentationAttributeCell.defaultProps = {
    className: '',
    enabled: true,
    showIcons: true,
    separator: true
};

OEPresentationAttributeCell.propTypes = {
    className: PropTypes.string,
    enabled: PropTypes.bool,
    ids: PropTypes.shape({
        pres: PropTypes.number.isRequired,
        slide: PropTypes.number.isRequired,
        attr: PropTypes.number.isRequired
    }),
    attribute: PropTypes.object,
    showIcons: PropTypes.bool,
    separator: PropTypes.bool,
    onDumpBtnPressed: PropTypes.func,
    header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    rightHeaderBar: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

export class OEPresentationLinkAttributeCell extends React.PureComponent    {

    constructor(props)  {
        super(props);

        this.renderTitle = this.renderTitle.bind(this);
        this.renderRightHeaderBar = this.renderRightHeaderBar.bind(this);
    }

    renderIcon()    {
        let type = this.props.attribute ? this.props.attribute.type : 0;
        if(type != OEPresentationAttrType.link) return null;
        let linkType = this.props.attribute.linkType;
        if(linkType < 0 || linkType >= OEIconCodes.presSlideAttribute.linkTypes.array.length) linkType = 0;
        let code = OEIconCodes.presSlideAttribute.linkTypes.array[linkType];
        return <OEIcon className="link-type-icon" code={code} />;
    }

    renderTitle()  {
        return (
            <div className="title">
                {this.props.strings.label}{this.props.showIcons ? this.renderIcon() : null}
            </div>
        );
    }

    renderRightHeaderBar()  {
        if(typeof(this.props.naturalIntervalLength) !== 'number')   return null;
        let step = 0.01;
        return (
            <div className="right-bar">
                {this.props.strings.naturalIntervalLength + ': ' + (Math.round(this.props.naturalIntervalLength / step) * step).toString() + 's'}
            </div>
        );
    }

    render()    {
        return(
            <OEPresentationAttributeCell className={'link ' + this.className} {...this.props} title={this.renderTitle()} rightHeaderBar={this.renderRightHeaderBar()}>
                {this.props.children}
            </OEPresentationAttributeCell>
        );
    }
}

OEPresentationLinkAttributeCell.defaultProps = {
    className: '',
    moduleId: '',
    enabled: true,
    showIcons: true,
    separator: true,
    strings: {
        label: '',
        naturalIntervalLength: ''
    }
};

OEPresentationLinkAttributeCell.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    ids: PropTypes.shape({
        pres: PropTypes.number.isRequired,
        slide: PropTypes.number.isRequired,
        attr: PropTypes.number.isRequired
    }),
    attribute: PropTypes.object,
    showIcons: PropTypes.bool,
    separator: PropTypes.bool,
    onDumpBtnPressed: PropTypes.func,
    strings: PropTypes.shape({
        label: PropTypes.string,
        naturalIntervalLength: PropTypes.string,
    }),
    naturalIntervalLength: PropTypes.number
};

export class OEPresentationAnimationLinkAttributeCell extends React.PureComponent   {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.animationData = null;

        this.state = {
            animationData: null,
            strings: {
                label: '',
                naturalIntervalLength: 'Length',
                intervalStart: 'Start',
                intervalEnd: 'End',
                displayTime: 'Current Display Time of Slide'
            },
            intervalEditViewParams: {enabled: false}
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onPresentationSlideDataChanged = this.onPresentationSlideDataChanged.bind(this);

        this.onIntervalEditViewValueChange = this.onIntervalEditViewValueChange.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(nextProps.attribute, this.props.attribute))     {
            if(this.getAnimationIndex(nextProps) !== this.getAnimationIndex())  {
                this.updateAnimationData(nextProps);
            } else {
                this.updateIntervalEditParams(nextProps);
            }
        } else if(!OEToolbox.shallowEqual(nextProps.ids, this.props.ids))     {
            this.updateIntervalEditParams(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideDataChanged, this.onPresentationSlideDataChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideDataChanged, this.onPresentationSlideDataChanged);
    }

    updateLanguage()   {
        this.updateLabel();

        this.setStateUpdate({
            strings: {
                naturalIntervalLength: {$set: this.oe.sharedInterface.getLocalizedStringEnc('presentation_attribute_view_natural_interval_length')},
                intervalStart: {$set: this.oe.sharedInterface.getLocalizedStringEnc('presentation_attribute_view_interval_start')},
                intervalEnd: {$set: this.oe.sharedInterface.getLocalizedStringEnc('presentation_attribute_view_interval_end')},
                displayTime: {$set: this.oe.sharedInterface.getLocalizedStringEnc('presentation_attribute_view_slide_display_time')},
            }
        });
    }

    getAnimationIndex(props) {
        props = props || this.props;
        if(!props.attribute || props.attribute.type !== OEPresentationAttrType.link || props.attribute.linkType !== OEPresentationAttrLinkType.animation)    return undefined;
        return props.attribute.index;
    }

    updateAnimationData(props)   {
        if(!this.oe.isReady())  return;

        let animationIndex = this.getAnimationIndex(props);
        this.animationData = null;

        if(typeof(animationIndex) !== 'undefined')  {
            let animationData = this.oe.sharedInterface.getUIControllerAnimation().getAnimation(animationIndex);
            if(animationData)   this.animationData = animationData;     // getAnimation may return undefined but here we use null for unset animationData
        }

        this.setState({animationData: clone(this.animationData)});
        this.updateLabel();
        this.updateIntervalEditParams(props);
    }

    updateLabel()   {
        if(!this.animationData) {
            this.setStateUpdate({strings: {label: {$set: ''}}}); return;
        }

        let locName = this.oe.sharedInterface.getLocalizedStringEnc(this.animationData.label);
        let label = locName === '#null' ? this.animationData.label : locName;
        this.setStateUpdate({strings: {label: {$set: label}}}); 
    }

    updateIntervalEditParams(props)    {
        props = props || this.props;
        let attribute = props.attribute && props.attribute.type === OEPresentationAttrType.link ? props.attribute : null;
        let slideData = this.props.ids ? this.oe.sharedInterface.getUIControllerPresentation().getSlideDataID(props.ids.pres, props.ids.slide) : null;
        let animationData = this.animationData;

        if(!attribute || !slideData || !animationData)  {
            this.setState({
                intervalEditViewParams: {
                    enabled: false,
                    duration: 10,
                    naturalIntervalLength: 4,
                    value: {start: 1, end: 5},
                }
            });
            return;
        }

        this.setState({
            intervalEditViewParams: {
                enabled: true,
                duration: slideData.slideViewTime,
                naturalIntervalLength: animationData.duration,
                value: {start: attribute.interval.x, end: attribute.interval.y},
            }
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.animationData = null;
            this.setState({
                animationData: null,
                intervalEditViewParams: {enabled: false}
            });
            this.setStateUpdate({strings: {label: {$set: ''}}});
            return;
        }

        retardUpdate(this, () => {
            this.updateAnimationData();
            this.updateLanguage();
        });
    }


    onPresentationSlideDataChanged(message, userInfo)    {
        if(!this.props.ids || userInfo.presID !== this.props.ids.pres || userInfo.slideID !== this.props.ids.slide) return;
        this.updateIntervalEditParams();
    }

    renderIntervalView()    {
        return (
            <OEIntervalEditView
                className=" std-label-border-color"
                {...this.state.intervalEditViewParams}
                enabled={this.props.enabled && this.state.intervalEditViewParams.enabled}
                strings={{
                    unit: 's',
                    start: this.state.strings.intervalStart,
                    end: this.state.strings.intervalEnd
                }}
                onValueChange={this.onIntervalEditViewValueChange}
            />
        );
    }

    render()    {
        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPresentationLinkAttributeCell
                    {...this.props}
                    strings={this.state.strings}
                    naturalIntervalLength={this.state.intervalEditViewParams.naturalIntervalLength}
                >
                    {this.renderIntervalView()}
                </OEPresentationLinkAttributeCell>
            </React.Fragment>
        );
    }

    onIntervalEditViewValueChange(sender, value)   {
        if(!this.oe.isReady() || !this.props.ids || !this.props.attribute || this.props.attribute.type !== OEPresentationAttrType.link)  return;
        let ids = this.props.ids;
        let attribute = clone(this.props.attribute);
        attribute.interval = {x: value.start, y: value.end};
        this.oe.sharedInterface.getUIControllerPresentation().setSlideAttributeID(ids.pres, ids.slide, ids.attr, attribute);
    }
}

OEPresentationAnimationLinkAttributeCell.defaultProps = {
    className: '',
    moduleId: '',
    enabled: true,
    showIcons: true,
    separator: true
};

OEPresentationAnimationLinkAttributeCell.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    ids: PropTypes.shape({
        pres: PropTypes.number.isRequired,
        slide: PropTypes.number.isRequired,
        attr: PropTypes.number.isRequired
    }),
    attribute: PropTypes.object,
    showIcons: PropTypes.bool,
    separator: PropTypes.bool,
    onDumpBtnPressed: PropTypes.func
};

export function OEPresentationAttributeCellFactory(props)   {
    if(!props.attribute || props.attribute.type !== OEPresentationAttrType.link)    {
        return <OEPresentationAttributeCell {...props}/>;
    } else {
        if(props.attribute.linkType === OEPresentationAttrLinkType.animation)   {
            return <OEPresentationAnimationLinkAttributeCell {...props}/>;
        } else {
            return <OEPresentationLinkAttributeCell {...props}/>;
        }
    }
}

OEPresentationAttributeCellFactory.defaultProps = {
    className: '',
    moduleId: '',
    enabled: true,
    showIcons: true,
    separator: true
};

OEPresentationAttributeCellFactory.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    ids: PropTypes.shape({
        pres: PropTypes.number.isRequired,
        slide: PropTypes.number.isRequired,
        attr: PropTypes.number.isRequired
    }),
    attribute: PropTypes.object,
    showIcons: PropTypes.bool,
    separator: PropTypes.bool,
    onDumpBtnPressed: PropTypes.func
};