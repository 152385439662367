import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {connectAppEnv} from '../../app-env';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import OEStdCapBarController from '../../bars/cap/std/oe-std-cap-bar-controller';
import {OETargetMenuDefs} from '../../elements/target-menu/oe-target-menu-defs';
import {OEFeatureAccessTypes, OELicensingEdition} from '../../../lib/oe-types';
import {OEImplantcastTargetType, OEImplantcastTargets} from '../../custom/implantcast/oe-implantcast-types';
import {OEToolbox} from '../../../lib/oe-toolbox';

export class OEImplantcastCapBar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.isLicensing = config.accessType == OEFeatureAccessTypes.licensing;

        this.onTargetBarButtonPressed = this.onTargetBarButtonPressed.bind(this);

        this.targetTypes = [
            {
                id: OEImplantcastTargetType.op,
                target: OEImplantcastTargets.opDefault,
                icon: OEIconCodes.implantcast.opTarget,
                titleId: 'cap_bar_target_title_op',
                targetBarButton: {
                    icon: OEIconCodes.implantcast.startSelection,
                    onPressed: this.onTargetBarButtonPressed
                },
                targetMenuDisabled: this.isLicensing,
                targetMenuHidden: true
            },
            {
                id: OEImplantcastTargetType.anatomy,
                target: OEImplantcastTargets.anatomyDefault,
                icon: OEIconCodes.implantcast.anatomyTarget,
                titleId: 'cap_bar_target_title_anatomy',
                targetMenuEntries: OETargetMenuDefs.implantcastAnatomy,
                targetMenuDisabled: this.isLicensing
            }
        ];

        this.state = {
            disabled: this.isLicensing,
            targetTypes: clone(this.targetTypes),
            targetBarLabel: undefined
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onLicensingProductActivationChanged = this.onLicensingProductActivationChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.target !== this.props.target || !OEToolbox.shallowEqual(nextProps.targetMenuEntries, this.props.targetMenuEntries))  {
            this.updateTargetBarLabel(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    updateLanguage()    {
        this.updateTargetBarLabel();
    }

    onLicensingProductActivationChanged(message, userInfo)   {
        this.updateLicenseValidity();
    }

    targetMenuEntryLabel(entries, target, prefix)    {
        if(!Array.isArray(entries)) return;

        for(let i = 0; i < entries.length; ++i) {
            let entry = entries[i];

            if(entry.target === target) {
                let labelIsObject = typeof(entry.label) === 'object';
                let id = labelIsObject && entry.label.id ? entry.label.id : ('target_menu_' + (prefix ? (prefix + '_') : '') + entry.target);
                let labelText = labelIsObject ? entry.label.text : entry.label;
                let labelText_ = labelIsObject ? entry.label.text_ : undefined;
                return {id: id, labelText: labelText, labelText_: labelText_};
            }

            let ret = this.targetMenuEntryLabel(entry.parts, target, entry.prefix);
            if(ret) return ret;
        }
    }

    updateTargetBarLabel(props)    {
        if(!this.oe.isReady())  return;
        props = props || this.props;

        let targetMenuEntries = props.targetMenuEntries || [];
        let prefix;
        if(!Array.isArray(targetMenuEntries))    {
            prefix = targetMenuEntries.prefix;
            targetMenuEntries = targetMenuEntries.parts || [];
        }

        let label = this.targetMenuEntryLabel(targetMenuEntries, props.target, prefix);

        if(label)  {
            if(label.text_)   {
                label = label.text_;
            } else {
                let labelText = this.oe.sharedInterface.getLocalizedStringEnc(label.id);
                if(labelText === '#null')   labelText = label.text;
                label = labelText;
            }
        } else {
            label = undefined;
        }

        this.setState({targetBarLabel: label});
    }

    updateDisabledStates(disabled, opTargetMenuDisabled, anatomyTargetMenuDisabled) {
        this.targetTypes[0].targetMenuDisabled = opTargetMenuDisabled;
        this.targetTypes[0].targetBarButton.disabled = opTargetMenuDisabled;
        this.targetTypes[1].targetMenuDisabled = anatomyTargetMenuDisabled;

        this.setState({disabled: disabled, targetTypes: clone(this.targetTypes)});
    }

    updateLicenseValidity()    {
        let si = this.oe.sharedInterface;

        if(!this.isLicensing || !si.getUIControllerFeatureAccess().isAccessType(this.oe.Module.FeatureAccessType.licensing)) {
            this.updateDisabledStates(false, false, false);
            return;
        }

        let licenseValid = si.getUIControllerLicensingManager().isValid();
        let edition = si.getUIControllerLicensingManager().getEdition();

        let disabled = true, opTargetMenuDisabled = true, anatomyTargetMenuDisabled = true;
        if(licenseValid)    {
            if([OELicensingEdition.basic, OELicensingEdition.viewer, OELicensingEdition.singleUser].includes(edition))  {
                disabled = false;
                opTargetMenuDisabled = false;
            }
            if([OELicensingEdition.extended, OELicensingEdition.professional, OELicensingEdition.branchOffice, OELicensingEdition.master].includes(edition))  {
                disabled = opTargetMenuDisabled = anatomyTargetMenuDisabled = false;
            }
        }

        this.updateDisabledStates(disabled, opTargetMenuDisabled, anatomyTargetMenuDisabled);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true) {
            this.updateDisabledStates(this.isLicensing, this.isLicensing, this.isLicensing);
            return;
        }

        this.updateLanguage();
        this.updateLicenseValidity();
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEStdCapBarController
                    {...this.props}
                    className="implantcast-cap-bar"
                    disabled={this.state.disabled}
                    targetBarLabel={this.state.targetBarLabel}
                    targetTypes={this.state.targetTypes}
                    targetTypeForTarget={OEImplantcastTargets.targetType}
                />
            </React.Fragment>
        );
    }

    onTargetBarButtonPressed()  {
        let appComponent = this.props.appComponent;
        if(!appComponent || !appComponent.uiLayer || !appComponent.uiLayer.startView)   return;
        appComponent.uiLayer.startView.setOpen(true, 'start-selection');
    }
}

OEImplantcastCapBar.defaultProps = {
    moduleId: ''
};

OEImplantcastCapBar.propTypes = {
    moduleId: PropTypes.string
};

export default connectAppEnv((env) => { return {
    target: env.config.target,
    appComponent: env.component,
    targetMenuEntries: env.config.module.uiLayerConfig.targetMenuConfig.entries
}})(OEImplantcastCapBar);