import React from 'react';
import PropTypes from 'prop-types';

import OEButton from './oe-button';
import OEIcon from './oe-icon';
import {OEIconCodes} from '../../lib/oe-icon-codes';

export default class OETextField extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onInputRef = this.onInputRef.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClearButtonPressed = this.onClearButtonPressed.bind(this);
    }

    focus() {
        if(this.inputRef)   this.inputRef.focus();
    }

    onInputRef(ref) {
        this.inputRef = ref;
    }

    render()    {
        return (
            <div className={'text-field ' + (this.props.clearButton ? 'has-clear-btn ' : '') + (this.props.disabled ? 'disabled ' : '') + (this.props.className ? this.props.className : '')}>
                <div className="container">
                    <input
                        type={this.props.type}
                        disabled={this.props.disabled}
                        spellCheck={this.props.spellCheck}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        ref={this.onInputRef}
                    />
                    {!this.props.clearButton ? null :
                        <OEButton className='clear-btn' disabled={this.props.disabled} onPressed={this.onClearButtonPressed}>
                            <OEIcon code={OEIconCodes.textFieldClear}/>
                        </OEButton>
                    }
                </div>
            </div>
        );
    }

    onChange(evt)   {
        if(this.props.disabled || !this.props.onChange) return;
        this.props.onChange(this, evt.target.value);
    }

    onFocus(evt)   {
        if(this.props.onFocus)  this.props.onFocus(this);
    }

    onBlur(evt)   {
        if(this.props.onBlur)  this.props.onBlur(this);
    }

    onClearButtonPressed()  {
        if(this.props.onClearButtonPressed) this.props.onClearButtonPressed(this);
        if(this.props.onChange) this.props.onChange(this, '');
    }
}

OETextField.defaultProps = {
    className: '',
    disabled: false,
    type: 'text',
    spellCheck: false,
    placeholder: '',
    value: '',
    clearButton: true
};

OETextField.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    spellCheck: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    clearButton: PropTypes.bool,
    onChange: PropTypes.func,
    onClearButtonPressed: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};