import {OETarget} from '../../../react-oe/oe-target';

export const OEPhilipsIcarusCustomPropKey = {
    mode: 'philips_icarus_mode',
    animationId: 'philips_icarus_animation_id',
    vpFrame: 'philips_icarus_vp_frame'
};

export const OEPhilipsIcarusTargetType = {
    patient: 'patient',
    xRay: 'xRay',
    anatomy: 'anatomy'
};

export const OEPhilipsIcarusMode = {
    uninitialized: 0,
    std: 1,
    xRay: 2,
    stringArray: ['uninitialized', 'std', 'xRay'],
    modeToString(mode)  {
        return this.stringArray[Math.min(Math.max(mode, 0), this.stringArray.length - 1)];
    }
};

export class OEPhilipsIcarusModel   {

    constructor(data)   {
        this.data = data;
    }

    setData(data)   {
        this.data = data;
    }

    getAnimation(animationId)   {
        return this.data && this.data.animations ? this.data.animations[animationId] : undefined;
    }

    isIdle(animationId) {
        return this.data && this.data.idleAnimId === animationId;
    }

    isNull(animationId) {
        return typeof(animationId) !== 'string' || !animationId.length;
    }

    isIdleOrNull(animationId)   {
        return this.isIdle(animationId) || this.isNull(animationId);
    }

    getTarget(animationId)  {
        if(typeof(animationId) !== 'string')    return this.data ? this.data.target : undefined;
        const animation = this.getAnimation(animationId);
        return animation ? animation.target : undefined;
    }

    getXRayImage(animationId)  {
        const animation = this.getAnimation(animationId);
        return animation ? animation.xRayImage : undefined;
    }

    getAnimationForTarget(target, animationId)    {
        if(!this.isIdleOrNull(animationId) && this.getTarget(animationId) === target)   return animationId;
        return target && this.data && this.data.targets && this.data.targets[target] ? this.data.targets[target].animationId : undefined;
    }
}

OEPhilipsIcarusModel.shared = function()   {
    if(!OEPhilipsIcarusModel._sharedInstance)   OEPhilipsIcarusModel._sharedInstance = new OEPhilipsIcarusModel();
    return OEPhilipsIcarusModel._sharedInstance;
};

OEPhilipsIcarusModel.stdData = function()   {
    let ret = {
        idleAnimId: 'patient',
        animations: {
            patient: {
                target: OETarget.humanThighKnee,
                xRayImage: undefined
            },
            kneeJoint1: {
                target: OETarget.humanThighKnee,
                xRayImage: '01_Knie_ap.png',
                textSrc: '01_Knie_AP.html',
                xRayTextSrc: 'xRay/01R_Knie_AP.html'
            },
            kneeJoint2: {
                target: OETarget.humanThighKnee,
                xRayImage: '14_Knie_ap.png',
                textSrc: '01_Knie_AP.html',
                xRayTextSrc: 'xRay/01R_Knie_AP.html'
            },
            kneeJoint3: {
                target: OETarget.humanThighKnee,
                xRayImage: '15_Knie_LL.png',
                textSrc: '01_Knie_AP.html',
                xRayTextSrc: 'xRay/01R_Knie_AP.html'
            },
            ankleJoint: {
                target: OETarget.humanAnkleFoot,
                xRayImage: '02_ankle_foot.png',
                textSrc: '02_Sprunggelenk_AP.html',
                xRayTextSrc: 'xRay/02R_Sprunggelenk_AP.html'
            },
            neckSpine1: {
                target: OETarget.humanNeckBack,
                xRayImage: '03_neck.png',
                textSrc: '03_HWS_AP_stehend.html',
                xRayTextSrc: 'xRay/03R_HWS_AP_stehend.html'
            },
            neckSpine2: {
                target: OETarget.humanNeckBack,
                xRayImage: '04_HWS_ap.png',
                textSrc: '04_HWS_AP_sitzend.html',
                xRayTextSrc: 'xRay/04R_HWS_AP_sitzend.html'
            },
            neckSpine3: {
                target: OETarget.humanNeckBack,
                xRayImage: '05_HWS_lat.png',
                textSrc: '05_HWS_LL.html',
                xRayTextSrc: 'xRay/05R_HWS_LL.html'
            },
            chestSpine1: {
                target: OETarget.humanShoulderGirdle,
                xRayImage: '06_BWS_lat.png',
                textSrc: '06_BWS_LL_Arme_oben.html',
                xRayTextSrc: 'xRay/06R_BWS_LL_Arme_oben.html'
            },
            chestSpine2: {
                target: OETarget.humanShoulderGirdle,
                xRayImage: '07_BWS_ap.png',
                textSrc: '07_BWS_AP.html',
                xRayTextSrc: 'xRay/07R_BWS_AP.html'
            },
            chestSpine3: {
                target: OETarget.humanShoulderGirdle,
                xRayImage: '08_BWS_lat.png',
                textSrc: '08_BWS_LL_Arme_vorn.html',
                xRayTextSrc: 'xRay/08R_BWS_LL_Arme_vorn.html'
            },
            lamburSpine1: {
                target: OETarget.humanHip2,
                xRayImage: '11_LWS_liegend_AP.png',
                textSrc: '09_LWS_AP.html',
                xRayTextSrc: 'xRay/09R_LWS_AP.html'
            },
            /*
            lamburSpine2: {
                target: OETarget.humanHip2,
                xRayImage: '10_LWS_lat.png',
                textSrc: '10_LWS_LL.html',
                xRayTextSrc: 'xRay/10R_LWS_LL.html'
            },
            */
            lamburSpine3: {
                target: OETarget.humanHip2,
                xRayImage: '12_LWS_liegend_LL.png',
                textSrc: '10_LWS_LL.html',
                xRayTextSrc: 'xRay/10R_LWS_LL.html'
            },
            hipJoint1: {
                target: OETarget.humanHip2,
                xRayImage: '13_Huefte_liegend_AP.png',
                textSrc: '11_Hüfte_AP.html',
                xRayTextSrc: 'xRay/11R_Hüfte_AP.html'
            },
            hipJoint2: {
                target: OETarget.humanHip2,
                xRayImage: '12_Becken_ap.png',
                textSrc: '12_Becken_AP.html',
                xRayTextSrc: 'xRay/12R_Becken_AP.html'
            },
            shoulderJoint1: {
                target: OETarget.humanShoulderGirdle,
                xRayImage: '13_Schulter_VM.png',
                textSrc: '13_Schulter_axial_sitzend.html',
                xRayTextSrc: 'xRay/13R_Schulter_axial_sitzend.html'
            },
            shoulderJoint2: {
                target: OETarget.humanShoulderGirdle,
                xRayImage: '17_Schulter_AP.png',
                textSrc: '14_Schulter_AP.html',
                xRayTextSrc: 'xRay/14R_Schulter_AP.html'
            }
        },
        target: OETarget.philipsIcarus,
        targets: {}
    }

    ret.targets[OETarget.humanThighKnee] = {animationId: 'kneeJoint1'};
    ret.targets[OETarget.humanAnkleFoot] = {animationId: 'ankleJoint'};
    ret.targets[OETarget.humanNeckBack] = {animationId: 'neckSpine1'};
    ret.targets[OETarget.humanShoulderGirdle] = {animationId: 'chestSpine1'};
    ret.targets[OETarget.humanHip2] = {animationId: 'hipJoint1'};

    return ret;
};

export const OEPhilipsIcarusOverlayNotifications = {
    cameraResetBtnPressed: 'philips_icarus_camera_reset_btn_pressed'
};