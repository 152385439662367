import {OETarget} from '../../../react-oe/oe-target';

// format {
// target: one of the strings defined in OETarget,
// label: simple string or object { text: string (optional), id: string referencing a core localized string (optional), style: one of the strings in OETargetMenuDefs.styles (optional)}
// }
export const OETargetMenuDefs = {
    styles: {
        major: 'major'
    },
    
    void: {target: OETarget.void, label: 'Void'},

    humanEarMacro: {target: OETarget.humanEarMacro, label: {text: 'Ear Macro', id: 'target_menu_humanEarMacro'}},
    humanEarMacroAmboss: {target: OETarget.humanEarMacroAmboss, label: {text: 'Ear Macro Amboss', id: 'target_menu_humanEarMacro'}},
    humanEarMicro: {target: OETarget.humanEarMicro, label: {text: 'Ear Micro', id: 'target_menu_humanEarMicro'}},
    skinMacroIONTO: {target: OETarget.skinMacroIONTO, label: {text: 'Skin Macro', id: 'target_menu_skinMacroIONTO'}},
    skinMicroIONTO: {target: OETarget.skinMicroIONTO, label: {text: 'Skin Micro', id: 'target_menu_skinMicroIONTO'}},
    skinMacro: {target: OETarget.skinMacroIONTO, label: {text: 'Skin Macro', id: 'target_menu_skinMacro'}},
    skinMicro: {target: OETarget.skinMicroIONTO, label: {text: 'Skin Micro', id: 'target_menu_skinMicro'}},
    humanEyeMacro: {target: OETarget.humanEyeMacro, label: {text: 'Eye Macro', id: 'target_menu_humanEyeMacro'}},
    humanEyeMicro: {target: OETarget.humanEyeMicro, label: {text: 'Eye Retina', id: 'target_menu_humanEyeMicro'}},
    humanEyeCornea: {target: OETarget.humanEyeCornea, label: {text: 'Eye Cornea', id: 'target_menu_humanEyeCornea'}},
    
    snail: {target: OETarget.snail, label: 'Snail'},
    toxNetzTorso: {target: OETarget.toxNetz, label: {text: 'Torso', id: 'target_menu_toxnetz_torso'}},
    zeissOpti: {target: OETarget.zeissOpti, label: 'Zeiss Opti'},
    zeissOpti2: {target: OETarget.zeissOpti2, label: 'Zeiss Opti 2'},
    zeissUV: {target: OETarget.zeissUV, label: 'Zeiss UV'},
    sensorySystems: {target: OETarget.sensorySystems, label: {text: 'Sensory Pathways', id: 'target_menu_sensorySystems'}},
    sensorySystemsEye: {target: OETarget.sensorySystemsEye, label: {text: 'Sensory Pathways', id: 'target_menu_sensorySystemsEye'}},
    sensorySystemsEar: {target: OETarget.sensorySystemsEar, label: {text: 'Sensory Pathways', id: 'target_menu_sensorySystemsEar'}},
    
    hoofCarpal: {target: OETarget.hoofCarpal, label: {text: 'Equine Hoof', id:'target_menu_hoofCarpal'}},
    hoofWeb: {target: OETarget.hoofWeb, label: {text: 'Equine Hoof', id:'target_menu_hoofWeb'}},
    hoofVessels: {target: OETarget.hoofVessels, label: {text: 'Vessels', id:'target_menu_hoofVessels'}},
    hoofDynamics: {target: OETarget.hoofDynamics, label: {text: 'Dynamics', id:'target_menu_hoofDynamics'}},
    hoofMicro: {target: OETarget.hoofMicro, label: {text: 'Suspensory Apparatus', id:'target_menu_hoofMicro'}},

//@ADD_TARGET_ANATOMY_TARGETMENU_ENTRY @ADD_TARGET_AMBOSS_TARGETMENU_ENTRY
    humanHeadLeFx: {target: OETarget.humanHeadLeFx, label: {text: 'Human Head (LeFx)', id: 'target_menu_humanHeadLeFx'}},
    humanBrainSynapse: {target: OETarget.humanBrainSynapse, label: {text: 'Brain (synapse)', id: 'target_menu_humanBrainSynapse'}},
    humanSkull: {target: OETarget.humanSkull, label: {text: 'Human Skull', id: 'target_menu_humanSkull'}},
    humanCranialNerves: {target: OETarget.humanCranialNerves, label: {text: 'Cranial Nerves', id: 'target_menu_humanCranialNerves'}},
    humanArm: {target: OETarget.humanArm, label:  {text: 'Human Arm', id: 'target_menu_humanArm'}},
    humanArmPathways: {target: OETarget.humanArmPathways, label:  {text: 'Arm (pathways)', id: 'target_menu_humanArmPathways'}},
    humanBodyMuscles: {target: OETarget.humanBodyMuscles, label:  {text: 'Body Muscles', id: 'target_menu_humanBodyMuscles'}},
    humanUpperArmElbow: {target: OETarget.humanUpperArmElbow, label:  {text: 'Upper arm + elbow', id: 'target_menu_humanUpperArmElbow'}},
    humanForearm: {target: OETarget.humanForearm, label:  {text: 'Forearm', id: 'target_menu_humanForearm'}},
    humanShoulder: {target: OETarget.humanShoulder, label:  {text: 'Human Shoulder', id: 'target_menu_humanShoulder'}},
    humanElbow: {target: OETarget.humanElbow, label:  {text: 'Human Elbow', id: 'target_menu_humanElbow'}},
    humanHand: {target: OETarget.humanHand, label:  {text: 'Human Hand', id: 'target_menu_humanHand'}},
    humanLeg: {target: OETarget.humanLeg, label:  {text: 'Human Leg', id: 'target_menu_humanLeg'}},
    humanAnkleFoot: {target: OETarget.humanAnkleFoot, label:  {text: 'Ankle / Foot', id: 'target_menu_humanAnkleFoot'}},
    humanHip2: {target: OETarget.humanHip2, label:  {text: 'Hip joint (Hip2)', id: 'target_menu_humanHip2'}},
    humanLegPathways: {target: OETarget.humanLegPathways, label:  {text: 'Leg Pathways', id: 'target_menu_humanLegPathways'}},
    humanLowerLeg: {target: OETarget.humanLowerLeg, label:  {text: 'Lower Leg', id: 'target_menu_humanLowerLeg'}},
    humanThighKnee: {target: OETarget.humanThighKnee, label:  {text: 'Thigh / Knee', id: 'target_menu_humanThighKnee'}},
    humanHip: {target: OETarget.humanHip, label:  {text: 'Human Hip', id: 'target_menu_humanHip'}},
    humanKnee: {target: OETarget.humanKnee, label:  {text: 'Human Knee', id: 'target_menu_humanKnee'}},    
    humanFoot: {target: OETarget.humanFoot, label:  {text: 'Human Foot', id: 'target_menu_humanFoot'}},
    humanTrunk: {target: OETarget.humanTrunk, label:  {text: 'Human Trunk', id: 'target_menu_humanTrunk'}},
    humanSpine: {target: OETarget.humanSpine, label:  {text: 'Human Spine', id: 'target_menu_humanSpine'}},
    humanSpineFracture: {target: OETarget.humanSpineFracture, label:  {text: 'Human Spine (fractures)', id: 'target_menu_humanSpineFracture'}},
    humanAbdominalWall: {target: OETarget.humanAbdominalWall, label:  {text: 'Abdominal Wall', id: 'target_menu_humanAbdominalWall'}},
    humanChestWall: {target: OETarget.humanChestWall, label:  {text: 'Chest Wall', id: 'target_menu_humanChestWall'}},
    humanPelvis: {target: OETarget.humanPelvis, label:  {text: 'Human Pelvis', id: 'target_menu_humanPelvis'}},
    humanNeckBack: {target: OETarget.humanNeckBack, label:  {text: 'Neck, Back', id: 'target_menu_humanNeckBack'}},
    humanShoulderGirdle: {target: OETarget.humanShoulderGirdle, label:  {text: 'Shoulder Girdle', id: 'target_menu_humanShoulderGirdle'}},
    humanHead: {target: OETarget.humanHead, label:  {text: 'Human Head', id: 'target_menu_humanHead'}},
    humanHeadWIP: {target: OETarget.humanHeadWIP, label:  {text: 'Human Head (WIP)', id: 'target_menu_humanHeadWIP'}},
    humanEye2: {target: OETarget.humanEye2, label:  {text: 'Human Eye (2)', id: 'target_menu_humanEye2'}},
    humanEyeRetina: {target: OETarget.humanEyeRetina, label:  {text: 'Human Eye Retina (2)', id: 'target_menu_humanEyeRetina'}},
    humanLarynx: {target: OETarget.humanLarynx, label:  {text: 'Human Larynx', id: 'target_menu_humanLarynx'}},
    humanLiverMicro: {target: OETarget.humanLiverMicro, label:  {text: 'Human Liver (microscopic)', id: 'target_menu_humanLiverMicro'}},
    humanCellEpithelium: {target: OETarget.humanCellEpithelium, label: {text: 'Epithelium cell', id: 'target_menu_humanCellEpithelium'}},

    humanEyeOCT: {target: OETarget.humanEyeOCT, label: {text: 'OCT', id: 'target_menu_humanEyeOCT'}},
    humanHeart: {target: OETarget.humanHeart, label: {text: 'Human Heart', id: 'target_menu_humanHeart'}},
    animalCell: {target: OETarget.animalCell, label: 'Animal Cell'},
    plantCell: {target: OETarget.plantCell, label: 'Plant Cell'},
    philipsIcarus: {target: OETarget.philipsIcarus, label: 'Philips Icarus'},

    humanKneeImplantcast: {target: OETarget.humanKneeImplantcast, label: {text: 'ACS FB', id: 'target_menu_implantcast_knee'}},
    implantcastACSMB4in1: {target: OETarget.implantcastACSMB4in1, label: {text: 'ACS MB', id: 'target_menu_implantcast_mb_4in1'}},
    implantcastACSSCFB: {target: OETarget.implantcastACSSCFB, label: {text: 'ACS FB SC', id: 'target_menu_implantcast_scfb'}},
    implantcastAGILONMI: {target: OETarget.implantcastAGILONMI, label: {text: 'Agilon MI', id: 'target_menu_implantcast_agilonmi'}},
    implantcastAGILONOmarthrosisLongFit: {target: OETarget.implantcastAGILONOmarthrosisLongFit, label: {text: 'Agilon Omarthrosis (long fit)', id: 'target_menu_implantcast_implantcastAGILONOmarthrosisLongFit'}},
    implantcastACSSCMB: {target: OETarget.implantcastACSSCMB, label: {text: 'ACS MB SC', id: 'target_menu_implantcast_acsscmb'}},
    implantcastMutarsDistFemurMK: {target: OETarget.implantcastMutarsDistFemurMK, label: {text: 'MUTARS Distaler Femurersatz MK', id: 'target_menu_implantcast_mutars_dist_femur_mk'}},
    implantcastMUTARSGenuXMK: {target: OETarget.implantcastMUTARSGenuXMK, label: {text: 'MUTARS GenuX', id: 'target_menu_implantcast_mutarsgenuxmk'}},
    implantcastMUTARSKRIMK: {target: OETarget.implantcastMUTARSKRIMK, label: {text: 'MUTARS KRI MK', id: 'target_menu_implantcast_mutarskrimk'}},
    implantcastMUTARSPRS: {target: OETarget.implantcastMUTARSPRS, label: {text: 'MUTARS® PRS', id: 'target_menu_implantcast_implantcastMUTARSPRS'}},
    implantcastEcoFitHipStem133123: {target: OETarget.implantcastEcoFitHipStem133123, label: {text: 'EcoFit Hüftschaft 133°', id: 'target_menu_implantcast_ecofit_hipstem_133123'}},
    implantcastEcoFitHipStem: {target: OETarget.implantcastEcoFitHipStem, label: {text: 'EcoFit Hüftschaft 138°', id: 'target_menu_implantcast_ecofit_hipstem'}},
    implantcastEcoFitShortStem: {target: OETarget.implantcastEcoFitShortStem, label: {text: 'EcoFit Kurzschaft', id: 'target_menu_implantcast_ecofit_shortstem'}},
    implantcastEcoFitCup: {target: OETarget.implantcastEcoFitCup, label: {text: 'EcoFit Hüftpfanne', id: 'target_menu_implantcast_ecofit_cup'}},
    implantcastEcoFitCupEPORE: {target: OETarget.implantcastEcoFitCupEPORE, label: {text: 'EcoFit EPORE Hüftpfanne', id: 'target_menu_implantcast_implantcastEcoFitCupEPORE'}},
    implantcastActiniaStem: {target: OETarget.implantcastActiniaStem, label: {text: 'Actinia Hüftschaft', id: 'target_menu_implantcast_actiniastem'}},
    implantcastShoulderApproachAnterior: {target: OETarget.implantcastShoulderApproachAnterior, label: {text: 'Zugang Schulter vorne', id: 'target_menu_implantcast_implantcastShoulderApproachAnterior'}},
    implantcastTestTarget: {target: OETarget.implantcastTestTarget, label: {text: 'test', id: 'target_menu_implantcast_implantcastTestTarget'}},
//@ADD_TARGET_IMPLANTCAST_TARGETMENU_ENTRY
    implantcastAidaShortStem: {target: OETarget.implantcastAidaShortStem, label: {text: 'AIDA® Short Stem', id: 'target_menu_implantcastAidaShortStem'}},
    implantcastAGILONTraumalongfit: {target: OETarget.implantcastAGILONTraumalongfit, label: {text: 'AGILON® Trauma Long Fit', id: 'target_menu_implantcastAGILONTraumalongfit'}},
    implantcastMutarsRS: {target: OETarget.implantcastMutarsRS, label: {text: 'MUTARS® RS', id: 'target_menu_implantcastMutarsRS'}},
    implantcastDirectAnteriorApproach: {target: OETarget.implantcastDirectAnteriorApproach, label: {text: 'Direct Anterior Approach', id: 'target_menu_implantcastDirectAnteriorApproach'}},

};

OETargetMenuDefs.all = [
    OETargetMenuDefs.void,
    OETargetMenuDefs.humanEarMacro,
    OETargetMenuDefs.humanEarMacroAmboss,
    OETargetMenuDefs.humanEarMicro,
    OETargetMenuDefs.skinMacro,
    OETargetMenuDefs.skinMicro,
    OETargetMenuDefs.humanEyeMacro,
    OETargetMenuDefs.humanEyeMicro,
    OETargetMenuDefs.humanEyeCornea,
    OETargetMenuDefs.snail,
    OETargetMenuDefs.toxNetzTorso,
    OETargetMenuDefs.zeissOpti,
    OETargetMenuDefs.zeissOpti2,
    OETargetMenuDefs.zeissUV,
    OETargetMenuDefs.sensorySystems,
    OETargetMenuDefs.hoofWeb,
    OETargetMenuDefs.hoofVessels,
    OETargetMenuDefs.hoofDynamics,
    OETargetMenuDefs.hoofMicro,

    OETargetMenuDefs.humanSkull,
    OETargetMenuDefs.humanCranialNerves,
    OETargetMenuDefs.humanArm,
    OETargetMenuDefs.humanArmPathways,
    OETargetMenuDefs.humanBodyMuscles,
    OETargetMenuDefs.humanUpperArmElbow,
    OETargetMenuDefs.humanForearm,
    OETargetMenuDefs.humanShoulder,
    OETargetMenuDefs.humanElbow,
    OETargetMenuDefs.humanHand,
    OETargetMenuDefs.humanLeg,
    OETargetMenuDefs.humanAnkleFoot,
    OETargetMenuDefs.humanHip2,
    OETargetMenuDefs.humanLegPathways,
    OETargetMenuDefs.humanLowerLeg,
    OETargetMenuDefs.humanThighKnee,
    OETargetMenuDefs.humanHip,
    OETargetMenuDefs.humanKnee,
    OETargetMenuDefs.humanFoot,
    OETargetMenuDefs.humanTrunk,
    OETargetMenuDefs.humanSpine,
    OETargetMenuDefs.humanSpineFracture,
    OETargetMenuDefs.humanAbdominalWall,
    OETargetMenuDefs.humanChestWall,
    OETargetMenuDefs.humanPelvis,
    OETargetMenuDefs.humanNeckBack,
    OETargetMenuDefs.humanShoulderGirdle,
    OETargetMenuDefs.humanHead,
    OETargetMenuDefs.humanHeadWIP,
    OETargetMenuDefs.humanEye2,
    OETargetMenuDefs.humanEyeRetina,
    OETargetMenuDefs.humanEyeOCT,
    OETargetMenuDefs.humanLarynx,
    OETargetMenuDefs.humanLiverMicro,
    OETargetMenuDefs.humanHeart,
// @ADD_TARGET_AMBOSS_TARGETMENU_ALL @ADD_TARGET_ANATOMY_TARGETMENU_ALL
    OETargetMenuDefs.humanHeadLeFx,
    OETargetMenuDefs.humanBrainSynapse,

    OETargetMenuDefs.animalCell,
    OETargetMenuDefs.plantCell,
    OETargetMenuDefs.humanCellEpithelium,
    OETargetMenuDefs.philipsIcarus,

    OETargetMenuDefs.humanKneeImplantcast,
    OETargetMenuDefs.implantcastACSMB4in1,
    OETargetMenuDefs.implantcastACSSCFB,
    OETargetMenuDefs.implantcastAGILONMI,
    OETargetMenuDefs.implantcastAGILONOmarthrosisLongFit,
    OETargetMenuDefs.implantcastACSSCMB,
    OETargetMenuDefs.implantcastMutarsDistFemurMK,
    OETargetMenuDefs.implantcastMUTARSGenuXMK,
    OETargetMenuDefs.implantcastMUTARSKRIMK,
    OETargetMenuDefs.implantcastMUTARSPRS,
    OETargetMenuDefs.implantcastEcoFitHipStem133123,
    OETargetMenuDefs.implantcastEcoFitHipStem,
    OETargetMenuDefs.implantcastEcoFitShortStem,
    OETargetMenuDefs.implantcastEcoFitCup,
    OETargetMenuDefs.implantcastEcoFitCupEPORE,
    OETargetMenuDefs.implantcastActiniaStem,
    OETargetMenuDefs.implantcastShoulderApproachAnterior,
    OETargetMenuDefs.implantcastTestTarget,
// @ADD_TARGET_IMPLANTCAST_TARGETMENU_ALL
    OETargetMenuDefs.implantcastAidaShortStem,
    OETargetMenuDefs.implantcastAGILONTraumalongfit,
    OETargetMenuDefs.implantcastMutarsRS,
    OETargetMenuDefs.implantcastDirectAnteriorApproach,
];

OETargetMenuDefs.app = [
    OETargetMenuDefs.humanEyeMacro,
    OETargetMenuDefs.humanEyeMicro,
    OETargetMenuDefs.humanEyeCornea,
    OETargetMenuDefs.humanEarMacro, 
    OETargetMenuDefs.humanEarMicro,
    OETargetMenuDefs.humanSkull,
    OETargetMenuDefs.humanArm,
    OETargetMenuDefs.humanShoulder,
    OETargetMenuDefs.humanElbow,
    OETargetMenuDefs.humanHand,
    OETargetMenuDefs.humanLeg,
    OETargetMenuDefs.humanHip,
    OETargetMenuDefs.humanKnee,
    OETargetMenuDefs.humanFoot,
    OETargetMenuDefs.humanTrunk,
    OETargetMenuDefs.humanSpine,
    OETargetMenuDefs.humanAbdominalWall,
    OETargetMenuDefs.humanChestWall,
    OETargetMenuDefs.humanPelvis,
    OETargetMenuDefs.humanNeckBack,
    OETargetMenuDefs.humanShoulderGirdle,
    OETargetMenuDefs.skinMacroIONTO,
    OETargetMenuDefs.skinMicroIONTO,
    OETargetMenuDefs.snail,
    OETargetMenuDefs.hoofWeb,
    OETargetMenuDefs.hoofVessels,
    OETargetMenuDefs.hoofDynamics,
    OETargetMenuDefs.hoofMicro
];

OETargetMenuDefs.humanEar = [
    OETargetMenuDefs.humanEarMacro,
    OETargetMenuDefs.humanEarMicro,
    OETargetMenuDefs.sensorySystemsEar
];

OETargetMenuDefs.humanEarAmboss = [ 
    OETargetMenuDefs.humanEarMacroAmboss,
    OETargetMenuDefs.humanEarMicro
];

OETargetMenuDefs.anatomy = {
    parts: [
// @ADD_TARGET_ANATOMY_TARGETMENU
        OETargetMenuDefs.humanHeadLeFx,
        OETargetMenuDefs.humanBrainSynapse,
        {target: OETarget.void, label: {text: 'Head and neck', id: 'target_menu_humanHeadMenuItem'}, parts: [
            OETargetMenuDefs.humanSkull,
            OETargetMenuDefs.humanCranialNerves,
            OETargetMenuDefs.humanLarynx,
        ]},
        {target: OETarget.void, label: {text: 'Upper extremity', id: 'target_menu_humanArmMenuItem'}, parts: [
            OETargetMenuDefs.humanArm,
            OETargetMenuDefs.humanShoulderGirdle,
            OETargetMenuDefs.humanUpperArmElbow,
            OETargetMenuDefs.humanForearm,
            OETargetMenuDefs.humanHand,
            OETargetMenuDefs.humanArmPathways,
        ]},
        {target: OETarget.void, label: {text: 'Lower extremity', id: 'target_menu_humanLegMenuItem'}, parts: [
            OETargetMenuDefs.humanLeg,
            OETargetMenuDefs.humanHip2,
            OETargetMenuDefs.humanThighKnee,
            OETargetMenuDefs.humanLowerLeg,
            OETargetMenuDefs.humanAnkleFoot,
            OETargetMenuDefs.humanLegPathways,
        ]},
        {target: OETarget.void, label: {text: 'Trunk', id: 'target_menu_humanTrunkMenuItem'}, parts: [
            OETargetMenuDefs.humanTrunk,
            OETargetMenuDefs.humanNeckBack,
            OETargetMenuDefs.humanChestWall,
            OETargetMenuDefs.humanAbdominalWall,
            OETargetMenuDefs.humanPelvis,
        ]},
        {target: OETarget.void, label: {text: 'Biology', id: 'target_menu_ humanBiologyItem'}, parts: [
            OETargetMenuDefs.humanCellEpithelium,
        ]}
    ]
};

OETargetMenuDefs.amboss = {
    parts: [
// @ADD_TARGET_AMBOSS_TARGETMENU
        {target: OETarget.void, label: {text: 'Head and neck', id: 'target_menu_humanHeadMenuItem'}, parts: [
//            OETargetMenuDefs.humanHead,
            OETargetMenuDefs.humanSkull,
            OETargetMenuDefs.humanCranialNerves,
            OETargetMenuDefs.humanLarynx,
        ]},
        {target: OETarget.void, label: {text: 'Sensory organs', id: 'target_menu_humanSensoryOrgansMenuItem'}, parts: [
            OETargetMenuDefs.humanEyeMacro,
            OETargetMenuDefs.humanEarMacroAmboss,
            OETargetMenuDefs.skinMacro,
        ]},
        {target: OETarget.void, label: {text: 'Upper extremity', id: 'target_menu_humanArmMenuItem'}, parts: [
            OETargetMenuDefs.humanArm,
            //OETargetMenuDefs.humanArmPathways,
            //OETargetMenuDefs.humanShoulder,
            OETargetMenuDefs.humanShoulderGirdle,
            OETargetMenuDefs.humanUpperArmElbow,
            //OETargetMenuDefs.humanElbow,
            OETargetMenuDefs.humanForearm,
            OETargetMenuDefs.humanHand,
        ]},
        {target: OETarget.void, label: {text: 'Lower extremity', id: 'target_menu_humanLegMenuItem'}, parts: [
            OETargetMenuDefs.humanLeg,
            //OETargetMenuDefs.humanLegPathways,
            //OETargetMenuDefs.humanHip,
            OETargetMenuDefs.humanHip2,
            OETargetMenuDefs.humanThighKnee,
            //OETargetMenuDefs.humanKnee,
            OETargetMenuDefs.humanLowerLeg,
            //OETargetMenuDefs.humanFoot,
            OETargetMenuDefs.humanAnkleFoot,
        ]},
        {target: OETarget.void, label: {text: 'Trunk', id: 'target_menu_humanTrunkMenuItem'}, parts: [
            OETargetMenuDefs.humanTrunk,
            OETargetMenuDefs.humanNeckBack,
            OETargetMenuDefs.humanChestWall,
            OETargetMenuDefs.humanAbdominalWall,
            OETargetMenuDefs.humanPelvis,
            //OETargetMenuDefs.humanSpine,
            //{OETargetMenuDefs.humanSpineFracture,
        ]},
        {target: OETarget.void, label: {text: 'Biology', id: 'target_menu_humanBiologyItem'}, parts: [
            OETargetMenuDefs.humanCellEpithelium,
            //OETargetMenuDefs.humanSpine,
            //OETargetMenuDefs.humanSpineFracture,
        ]}
    ]
};

OETargetMenuDefs.amboss.import = {
    parts: [
// @ADD_TARGET_AMBOSS_TARGETMENU
        {target: OETarget.humanHead, label: {text: 'Head and neck', id: 'target_menu_humanHeadMenuItem'}, parts: [
            OETargetMenuDefs.humanSkull,
            OETargetMenuDefs.humanHead,
            OETargetMenuDefs.humanHeadWIP,
            OETargetMenuDefs.humanCranialNerves,
            OETargetMenuDefs.humanLarynx,
        ]},
        {target: OETarget.void, label: {text: 'Sensory organs', id: 'target_menu_humanSensoryOrgansMenuItem'}, parts: [
            OETargetMenuDefs.humanEyeMacro,
            OETargetMenuDefs.humanEye2,
            OETargetMenuDefs.humanEyeMicro,
            OETargetMenuDefs.humanEyeRetina,
            OETargetMenuDefs.humanEyeCornea,
            OETargetMenuDefs.humanEarMacroAmboss,
            OETargetMenuDefs.humanEarMicro,
            OETargetMenuDefs.skinMacro,
            OETargetMenuDefs.skinMicro,
        ]},
        {target: OETarget.void, label: {text: 'Internal organs', id: 'target_menu_humanOrgansMenuItem'}, parts: [
            OETargetMenuDefs.humanHeart,
            OETargetMenuDefs.humanLiverMicro,
            OETargetMenuDefs.humanCellEpithelium,
        ]},
        {ptarget: OETarget.humanBodyMuscles, label: {text: 'Body Muscles', id: 'target_menu_humanBodyMuscles'}, parts: [
            OETargetMenuDefs.humanBodyMuscles,
        ]},
        {target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_humanArmMenuItem'}, parts: [
            OETargetMenuDefs.humanArm,
            OETargetMenuDefs.humanArmPathways,
            OETargetMenuDefs.humanShoulder,
            OETargetMenuDefs.humanShoulderGirdle,
            OETargetMenuDefs.humanUpperArmElbow,
            OETargetMenuDefs.humanElbow,
            OETargetMenuDefs.humanForearm,
            OETargetMenuDefs.humanHand,
        ]},
        {target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_humanLegMenuItem'}, parts: [
            OETargetMenuDefs.humanLeg,
            OETargetMenuDefs.humanLegPathways,
            OETargetMenuDefs.humanHip,
            OETargetMenuDefs.humanHip2,
            OETargetMenuDefs.humanThighKnee,
            OETargetMenuDefs.humanKnee,
            OETargetMenuDefs.humanLowerLeg,
            OETargetMenuDefs.humanFoot,
            OETargetMenuDefs.humanAnkleFoot,
        ]},
        {target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_humanTrunkMenuItem'}, parts: [
            OETargetMenuDefs.humanTrunk,
            OETargetMenuDefs.humanNeckBack,
            OETargetMenuDefs.humanChestWall,
            OETargetMenuDefs.humanAbdominalWall,
            OETargetMenuDefs.humanPelvis,
            OETargetMenuDefs.humanSpine,
            OETargetMenuDefs.humanSpineFracture,
        ]}
    ]
};

OETargetMenuDefs.amboss.free = [
];

OETargetMenuDefs.kind = [
    {target: OETarget.humanEarMacro, label: 'Ohr'},
    {target: OETarget.humanEarMicro, label: 'Cortisches Organ'},
    {target: OETarget.humanEyeMacro, label: 'Auge'},
    {target: OETarget.humanEyeMicro, label: 'Retina'},
    {target: OETarget.humanEyeCornea, label: 'Cornea'},
    {target: OETarget.sensorySystems, label: 'Seh-/Hörbahn'}
];

OETargetMenuDefs.ortho = {
    parts: [
        {target: OETarget.humanHead, label: {text: 'Head', id: 'target_menu_humanHeadMenuItem'}, parts: [
            OETargetMenuDefs.humanSkull
        ]},
        {target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_humanArmMenuItem'}, parts: [
            OETargetMenuDefs.humanArm,
            OETargetMenuDefs.humanShoulder,
            OETargetMenuDefs.humanElbow,
            OETargetMenuDefs.humanHand
        ]},
        {target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_humanLegMenuItem'}, parts: [
            OETargetMenuDefs.humanLeg,
            OETargetMenuDefs.humanHip,
            OETargetMenuDefs.humanKnee,
            OETargetMenuDefs.humanFoot
        ]},
        {target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_humanTrunkMenuItem'}, parts: [
            OETargetMenuDefs.humanTrunk
        ]}
    ]
};

OETargetMenuDefs.skin = [
    OETargetMenuDefs.skinMacroIONTO,
    OETargetMenuDefs.skinMicroIONTO
];

OETargetMenuDefs.humanEye = {
    parts: [
        OETargetMenuDefs.humanEyeMacro,
        OETargetMenuDefs.humanEyeMicro,
        OETargetMenuDefs.humanEyeCornea,
        OETargetMenuDefs.sensorySystemsEye
    ]
};

OETargetMenuDefs.humanEyeExt = {
    parts: [
        OETargetMenuDefs.humanEyeMacro,
        OETargetMenuDefs.humanEye2,
        OETargetMenuDefs.humanEyeMicro,
        OETargetMenuDefs.humanEyeRetina,
        OETargetMenuDefs.humanEyeCornea,
        OETargetMenuDefs.sensorySystemsEye,
        OETargetMenuDefs.humanEyeOCT
    ]
};

OETargetMenuDefs.humanEyeNew = {
    parts: [
        OETargetMenuDefs.humanEye2,
        OETargetMenuDefs.humanEyeRetina,
        OETargetMenuDefs.humanEyeCornea,
        OETargetMenuDefs.sensorySystemsEye,
        OETargetMenuDefs.humanEyeOCT
    ]
};

OETargetMenuDefs.humanEyeOCTVersion = {
    parts: [
        OETargetMenuDefs.humanEye2,
        OETargetMenuDefs.humanEyeRetina,
        OETargetMenuDefs.humanEyeCornea,
        OETargetMenuDefs.sensorySystemsEye
    ]
};

OETargetMenuDefs.humanEyeSanten = {
    parts: [
        OETargetMenuDefs.humanEye2,
        OETargetMenuDefs.humanEyeRetina,
        OETargetMenuDefs.humanEyeCornea,
        OETargetMenuDefs.humanEyeOCT,
        OETargetMenuDefs.humanForearm
    ]
};

OETargetMenuDefs.hoof = [
    OETargetMenuDefs.hoofCarpal,
    OETargetMenuDefs.hoofWeb,
    OETargetMenuDefs.hoofVessels,
    OETargetMenuDefs.hoofDynamics,
    OETargetMenuDefs.hoofMicro
];

OETargetMenuDefs.toxNetz = [
    OETargetMenuDefs.toxNetzTorso,
    OETargetMenuDefs.humanEye2,
    OETargetMenuDefs.humanEyeRetina,
    OETargetMenuDefs.humanEyeCornea,
    OETargetMenuDefs.humanEarMacro,
    OETargetMenuDefs.skinMacroIONTO,
    OETargetMenuDefs.humanLiverMicro,
	OETargetMenuDefs.sensorySystems,
	OETargetMenuDefs.humanBrainSynapse
];

OETargetMenuDefs.cell = [
    OETargetMenuDefs.animalCell,
    OETargetMenuDefs.plantCell
];

OETargetMenuDefs.implantcast_alpha = {
    parts: [
//@ADD_TARGET_IMPLANTCAST_TARGETMENU
        OETargetMenuDefs.implantcastAidaShortStem,
        OETargetMenuDefs.implantcastAGILONTraumalongfit,
        OETargetMenuDefs.implantcastMutarsRS,
        OETargetMenuDefs.implantcastDirectAnteriorApproach,
        OETargetMenuDefs.implantcastTestTarget,
        OETargetMenuDefs.implantcastShoulderApproachAnterior,
        {label: {text: '1. Becken/Hüfte', id: 'welcome_view_selection_implantcast_1'}, parts: [
            {label: {text: '1.1. Primärendoprothetik Hüftschäfte', id: 'welcome_view_selection_implantcast_1_1'}, parts: [
                OETargetMenuDefs.implantcastActiniaStem,
                OETargetMenuDefs.implantcastEcoFitHipStem133123,
                OETargetMenuDefs.implantcastEcoFitHipStem,
                OETargetMenuDefs.implantcastEcoFitShortStem,
            ]},
            {label: {text: '1.2. Primärendoprothetik Hüftköpfe/-pfannen', id: 'welcome_view_selection_implantcast_1_2'}, parts: [
                OETargetMenuDefs.implantcastEcoFitCup,
                OETargetMenuDefs.implantcastEcoFitCupEPORE
            ]},
            {label: {text: '1.3. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_1_3'}, parts: [
                OETargetMenuDefs.implantcastMUTARSPRS
            ]}
        ]},
        {label: {text: '2. Knie', id: 'welcome_view_selection_implantcast_2'}, parts: [
            {label: {text: '2.1. Primärendoprothetik', id: 'welcome_view_selection_implantcast_2_1'}, parts: [
                OETargetMenuDefs.humanKneeImplantcast,
                OETargetMenuDefs.implantcastACSMB4in1
            ]}, 
            {label: {text: '2.2. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_2_2'}, parts: [
                OETargetMenuDefs.implantcastACSSCFB,
                OETargetMenuDefs.implantcastACSSCMB,
                OETargetMenuDefs.implantcastMutarsDistFemurMK,
                OETargetMenuDefs.implantcastMUTARSGenuXMK,
                OETargetMenuDefs.implantcastMUTARSKRIMK
            ]},
            {label: {text: '2.3. Arthrodesen', id: 'welcome_view_selection_implantcast_2_3'}, parts: [
            ]},
        ]},
        {label: {text: '3. Schulter', id: 'welcome_view_selection_implantcast_3'}, parts: [
            {label: {text: '3.1. Primärendoprothetik', id: 'welcome_view_selection_implantcast_3_1'}, parts: [
                OETargetMenuDefs.implantcastAGILONMI,
                OETargetMenuDefs.implantcastAGILONOmarthrosisLongFit, 
            ]}, 
            {label: {text: '3.2. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_3_2'}, parts: [
            ]}
        ]},
        {label: {text: '4. Sprunggelenk', id: 'welcome_view_selection_implantcast_4'}, parts: [
        ]},
        {label: {text: '5. Ellenbogen', id: 'welcome_view_selection_implantcast_5'}, parts: [
        ]},
        {label: {text: '6. Daumen', id: 'welcome_view_selection_implantcast_6'}, parts: [
        ]}
    ]
};

OETargetMenuDefs.implantcast_beta = {
    parts: [
//@ADD_TARGET_IMPLANTCAST_TARGETMENUCOMMENT
        //OETargetMenuDefs.implantcastAidaShortStem,
        //OETargetMenuDefs.implantcastAGILONTraumalongfit,
        //OETargetMenuDefs.implantcastMutarsRS,
        //OETargetMenuDefs.implantcastDirectAnteriorApproach,
        //OETargetMenuDefs.implantcastTestTarget,
        //OETargetMenuDefs.implantcastShoulderApproachAnterior,
        {label: {text: '1. Becken/Hüfte', id: 'welcome_view_selection_implantcast_1'}, parts: [
            {label: {text: '1.1. Primärendoprothetik Hüftschäfte', id: 'welcome_view_selection_implantcast_1_1'}, parts: [
                OETargetMenuDefs.implantcastActiniaStem,
                OETargetMenuDefs.implantcastEcoFitHipStem133123,
                OETargetMenuDefs.implantcastEcoFitHipStem,
                OETargetMenuDefs.implantcastEcoFitShortStem,
            ]},
            {label: {text: '1.2. Primärendoprothetik Hüftköpfe/-pfannen', id: 'welcome_view_selection_implantcast_1_2'}, parts: [
                OETargetMenuDefs.implantcastEcoFitCup,
                OETargetMenuDefs.implantcastEcoFitCupEPORE
            ]},
            {label: {text: '1.3. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_1_3'}, parts: [
                OETargetMenuDefs.implantcastMUTARSPRS
            ]}
        ]},
        {label: {text: '2. Knie', id: 'welcome_view_selection_implantcast_2'}, parts: [
            {label: {text: '2.1. Primärendoprothetik', id: 'welcome_view_selection_implantcast_2_1'}, parts: [
                OETargetMenuDefs.humanKneeImplantcast,
                OETargetMenuDefs.implantcastACSMB4in1
            ]}, 
            {label: {text: '2.2. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_2_2'}, parts: [
                OETargetMenuDefs.implantcastACSSCFB,
                OETargetMenuDefs.implantcastACSSCMB,
                OETargetMenuDefs.implantcastMutarsDistFemurMK,
                OETargetMenuDefs.implantcastMUTARSGenuXMK,
                OETargetMenuDefs.implantcastMUTARSKRIMK
            ]},
            {label: {text: '2.3. Arthrodesen', id: 'welcome_view_selection_implantcast_2_3'}, parts: [
            ]},
        ]},
        {label: {text: '3. Schulter', id: 'welcome_view_selection_implantcast_3'}, parts: [
            {label: {text: '3.1. Primärendoprothetik', id: 'welcome_view_selection_implantcast_3_1'}, parts: [
                OETargetMenuDefs.implantcastAGILONMI,
                OETargetMenuDefs.implantcastAGILONOmarthrosisLongFit,
            ]}, 
            {label: {text: '3.2. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_3_2'}, parts: [
            ]}
        ]},
        {label: {text: '4. Sprunggelenk', id: 'welcome_view_selection_implantcast_4'}, parts: [
        ]},
        {label: {text: '5. Ellenbogen', id: 'welcome_view_selection_implantcast_5'}, parts: [
        ]},
        {label: {text: '6. Daumen', id: 'welcome_view_selection_implantcast_6'}, parts: [
        ]}
    ]
};

OETargetMenuDefs.implantcast_production = {
    parts: [
//@ADD_TARGET_IMPLANTCAST_TARGETMENUCOMMENT
        //OETargetMenuDefs.implantcastAidaShortStem,
        //OETargetMenuDefs.implantcastAGILONTraumalongfit,
        //OETargetMenuDefs.implantcastMutarsRS,
        //OETargetMenuDefs.implantcastDirectAnteriorApproach,
        //OETargetMenuDefs.implantcastTestTarget,
        //OETargetMenuDefs.implantcastShoulderApproachAnterior,
        {label: {text: '1. Becken/Hüfte', id: 'welcome_view_selection_implantcast_1'}, parts: [
            {label: {text: '1.1. Primärendoprothetik Hüftschäfte', id: 'welcome_view_selection_implantcast_1_1'}, parts: [
                OETargetMenuDefs.implantcastActiniaStem,
                OETargetMenuDefs.implantcastEcoFitHipStem133123,
                OETargetMenuDefs.implantcastEcoFitHipStem,
                OETargetMenuDefs.implantcastEcoFitShortStem,
            ]},
            {label: {text: '1.2. Primärendoprothetik Hüftköpfe/-pfannen', id: 'welcome_view_selection_implantcast_1_2'}, parts: [
                OETargetMenuDefs.implantcastEcoFitCup,
                OETargetMenuDefs.implantcastEcoFitCupEPORE
            ]},
            {label: {text: '1.3. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_1_3'}, parts: [
                //OETargetMenuDefs.implantcastMUTARSPRS
            ]}
        ]},
        {label: {text: '2. Knie', id: 'welcome_view_selection_implantcast_2'}, parts: [
            {label: {text: '2.1. Primärendoprothetik', id: 'welcome_view_selection_implantcast_2_1'}, parts: [
                OETargetMenuDefs.humanKneeImplantcast, 
                OETargetMenuDefs.implantcastACSMB4in1
            ]}, 
            {label: {text: '2.2. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_2_2'}, parts: [
                OETargetMenuDefs.implantcastACSSCFB,
                OETargetMenuDefs.implantcastACSSCMB,
                OETargetMenuDefs.implantcastMutarsDistFemurMK,
                OETargetMenuDefs.implantcastMUTARSGenuXMK,
                //OETargetMenuDefs.implantcastMUTARSKRIMK
            ]},
            {label: {text: '2.3. Arthrodesen', id: 'welcome_view_selection_implantcast_2_3'}, parts: [
            ]},
        ]},
        {label: {text: '3. Schulter', id: 'welcome_view_selection_implantcast_3'}, parts: [
            {label: {text: '3.1. Primärendoprothetik', id: 'welcome_view_selection_implantcast_3_1'}, parts: [
                OETargetMenuDefs.implantcastAGILONMI,
                //OETargetMenuDefs.implantcastAGILONOmarthrosisLongFit,
            ]}, 
            {label: {text: '3.2. Revisions- und Tumorendoprothetik', id: 'welcome_view_selection_implantcast_3_2'}, parts: [
            ]}
        ]},
        {label: {text: '4. Sprunggelenk', id: 'welcome_view_selection_implantcast_4'}, parts: [
        ]},
        {label: {text: '5. Ellenbogen', id: 'welcome_view_selection_implantcast_5'}, parts: [
        ]},
        {label: {text: '6. Daumen', id: 'welcome_view_selection_implantcast_6'}, parts: [
        ]}
    ]
};

OETargetMenuDefs.implantcastAnatomy = [
    {target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_humanLegMenuItem'}},
    {target: OETarget.humanHip2, label: {text: 'Hip', id: 'target_menu_humanHip2'}},
    {target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_humanArmMenuItem'}},
    {target: OETarget.humanUpperArmElbow, label: {text: 'Upper arm / elbow', id: 'target_menu_humanUpperArmElbow'}},
    {target: OETarget.humanShoulderGirdle, label: {text: 'Shoulder girdle', id: 'target_menu_humanShoulderGirdle'}}
];
//@ADD_TARGET_END implantcast

OETargetMenuDefs.philipsIcarusVersion = {
    parts: [
        /*
        {target: OETarget.humanHead, label: {text: 'Head and neck', id: 'target_menu_humanHeadMenuItem'}, parts: [
        ]},
        */
        {target: OETarget.humanArm, label: {text: 'Upper extremity', id: 'target_menu_humanArmMenuItem'}, parts: [
            {target: OETarget.humanShoulderGirdle, label: 'Shoulder'},
        ]},
        {target: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_humanLegMenuItem'}, parts: [
            {target: OETarget.humanHip2, label: 'Hip'},
            {target: OETarget.humanThighKnee, label: 'Thigh + knee'},
            {target: OETarget.humanAnkleFoot, label: 'Ankle + foot'}
        ]},
        {target: OETarget.humanTrunk, label: {text: 'Trunk', id: 'target_menu_humanTrunkMenuItem'}, parts: [
            {target: OETarget.humanNeckBack, label: 'Neck, Back'},
        ]}
    ]
};

OETargetMenuDefs.philipsIcarusVersionReduced = {
    parts: [
        /*
        {ptarget: OETarget.humanHead, label: {text: 'Head and neck', id: 'target_menu_humanHeadMenuItem'}, parts: [
        ]},
        */
        {ptarget: OETarget.humanLeg, label: {text: 'Lower extremity', id: 'target_menu_humanLegMenuItem'}, parts: [
            /*{target: OETarget.humanHip2, label: 'Hip'},*/
            {target: OETarget.humanThighKnee, label: 'Thigh + knee'},
            {target: OETarget.humanAnkleFoot, label: 'Ankle + foot'}
        ]}
    ]
};