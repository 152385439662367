import React from 'react';
import PropTypes from 'prop-types';

import {OEAnimationMode} from './oe-animation-adapter';
import {OEAnimationControls} from '../oe-controls';

export default class OEAnimationControl extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;

        this.isSliding = false;
        this.wasPlaying = false;

        this.state = {
            enabled: true,
            mode: OEAnimationMode.disabled,
            progress: 0,
            wasPlaying: false
        };

        this.onStopBtnPressed = this.onStopBtnPressed.bind(this);
        this.onPlayPauseBtnPressed = this.onPlayPauseBtnPressed.bind(this);
        this.onProgressSliderStart = this.onProgressSliderStart.bind(this);
        this.onProgressSliderChanged = this.onProgressSliderChanged.bind(this);
        this.onProgressSliderEnd = this.onProgressSliderEnd.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.adapter !== this.props.adapter && this.mounted)     {
            this.releaseAdapter(this.props.adapter);
            this.connectAdapter(nextProps.adapter);
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.connectAdapter(this.props.adapter);
    }

    componentWillUnmount()  {
        this.mounted = false;
        this.releaseAdapter(this.props.adapter);
    }

    releaseAdapter(adapter)    {

        if(adapter)  {
            adapter.unregisterControl(this);
        }

        this.setFromAdapter(null);
    }

    connectAdapter(adapter) {

        if(adapter)  {
            adapter.registerControl(this);
        }

        this.setFromAdapter(adapter);
    }

    setFromAdapter(adapter)    {

        if(!adapter)  {
            this.wasPlaying = false;
            this.setState({ enabled: false, mode: OEAnimationMode.disabled, progress: 0, wasPlaying: this.wasPlaying });
            return
        }

        if(this.isSliding) {
            this.wasPlaying = adapter.getAnimationMode() === OEAnimationMode.play;
        } else {
            this.wasPlaying = false;
        }

        this.setState({ enabled: true, mode: adapter.getAnimationMode(), progress: adapter.getProgress(), wasPlaying: this.wasPlaying });
    }

    onAnimationModeChanged(sender, mode)    {
        this.setState({ mode: mode });
    }

    onProgressChanged(sender, progress) {
        if(!this.isSliding) {
            this.setState({ progress: progress });
        }
    }

    render() {

        var disabled = !(this.state.uiEnabled) || this.props.disabled;

        var slider = Object.assign({}, OEAnimationControl.defaultProps.slider);
        slider = Object.assign(slider, this.props.slider);

        var control = Object.assign({}, OEAnimationControl.defaultProps.control);
        control = Object.assign(control, this.props.control);

        return (
            <OEAnimationControls
                className={this.props.className}
                disabled={!(this.props.enabled && this.state.enabled)}
                slider={{visible: slider.visible, disabled: !slider.enabled}}
                control={{visible: control.visible, disabled: !control.enabled, stopBtnVisible: control.stopBtnVisible, playBtnVisible: control.playBtnVisible, doNotDisableStopBtn: control.doNotDisableStopBtn, doNotDisablePlayBtn: control.doNotDisablePlayBtn}}
                iconCodes={this.props.iconCodes}
                mode={this.state.wasPlaying ? OEAnimationMode.play : this.state.mode}
                time={this.state.progress}
                startTime={0}
                endTime={1}
                onStopBtnPressed={this.onStopBtnPressed}
                onPlayPauseBtnPressed={this.onPlayPauseBtnPressed}
                onProgressSliderStart={this.onProgressSliderStart}
                onProgressSliderChanged={this.onProgressSliderChanged}
                onProgressSliderEnd={this.onProgressSliderEnd}
            />
        );
    }

    onStopBtnPressed() {

        if(!this.isSliding && typeof(this.props.onStopBtnPressed) === 'function') {
            if(this.props.onStopBtnPressed(this, this.props.adapter) === true) return;
        }

        if(!this.props.adapter || this.isSliding)  { return; }
        this.props.adapter.stop();
    }

    onPlayPauseBtnPressed()    {

        if(!this.isSliding) {
            if(this.props.adapter.getAnimationMode() === OEAnimationMode.play)  {
                if(typeof(this.props.onPauseBtnPressed) === 'function') {
                    if(this.props.onPauseBtnPressed(this, this.props.adapter) === true) return;
                }
            } else {
                if(typeof(this.props.onPlayBtnPressed) === 'function') {
                    if(this.props.onPlayBtnPressed(this, this.props.adapter) === true) return;
                }
            }
        }

        if(!this.props.adapter || this.isSliding)  { return; }
        if(this.props.adapter.getAnimationMode() === OEAnimationMode.play)  {
            this.props.adapter.pause();
        } else {
            this.props.adapter.play();
        }
    }

    onProgressSliderStart(value)    {

        this.isSliding = true;

        if(!this.props.adapter)  { return; }

        if(this.props.adapter.getAnimationMode() === OEAnimationMode.play)  {
            this.wasPlaying = true;
            this.setState({ wasPlaying: this.wasPlaying });
            this.props.adapter.pause();
        }
    }

    onProgressSliderChanged(value)   {

        this.isSliding = true;

        // we have to control the time state of OEAnimationControls! 
        this.setState({ progress: value });     

        if(typeof(this.props.onProgressSliderChanged) === 'function') {
            this.props.onProgressSliderChanged(value);
        }

        if(!this.props.adapter)  { return; }

        if(this.props.adapter.getAnimationMode() === OEAnimationMode.play)  {
            this.wasPlaying = true;
            this.setState({ wasPlaying: this.wasPlaying });
            this.props.adapter.pause();
        }
        this.props.adapter.setProgress(Math.max(0, Math.min(value, 1.0)));
    }

    onProgressSliderEnd(value)  {

        this.isSliding = false;

        this.setState({ progress: this.props.adapter ? this.props.adapter.getProgress() : 0 });

        if(this.wasPlaying == true)    {
            this.wasPlaying = false;
            this.setState({ wasPlaying: this.wasPlaying });
            this.props.adapter.play();
        }
    }
}

OEAnimationControl.defaultProps = {
    className: '',
    enabled: true,
    slider: {visible: true, enabled: true},
    control: {visible: true, enabled: true, stopBtnVisible: true, playBtnVisible: true, doNotDisableStopBtn: false, doNotDisablePlayBtn: false}
};

OEAnimationControl.propTypes = {
    className: PropTypes.string,
    enabled: PropTypes.bool,
    slider: PropTypes.shape({visible: PropTypes.bool, enabled: PropTypes.bool}),
    control: PropTypes.shape({visible: PropTypes.bool, enabled: PropTypes.bool, stopBtnVisible: PropTypes.bool, playBtnVisible: PropTypes.bool, doNotDisableStopBtn: PropTypes.bool, doNotDisablePlayBtn: PropTypes.bool})
};