
export default class OEOpenStateElementContainer    {

    constructor()  {
        this.list = [];
    }

    register(element)    {
        if(!element || typeof(element) !== 'object')  return;
        if(this.list.find(item => item === element))  return;
        this.list.push(element);
    }

    unregister(element)    {
        if(!element || typeof(element) !== 'object')  return;
        if(!this.list.find(item => item === element))  return;
        this.list = this.list.filter(item => item !== element);
    }

    closeAll(excluded)  {
        this.list.forEach(item => {
            if(item === excluded)   return;
            if(typeof(item.setOpen) === 'function') item.setOpen(false);
        });
    }
}