import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {OEMainMenuControlBarEntryTypes} from '../../lib/oe-types';
import {OETarget} from '../../react-oe/oe-target';
import {OEVersion} from '../../react-oe/oe-version';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEIcon from '../elements/oe-icon';
import OETargetMenu from '../elements/target-menu/oe-target-menu';
import {OEDefaultConfigFactory} from '../oe-default-configs';

export class OEMainMenuControlBarEntryFactory extends React.PureComponent {
    render()    {
        if(!this.props.config.enabled)  return null;

        let key = 0;
        let elements = this.props.config.entries.map(entry => {
    
            if(entry.type === OEMainMenuControlBarEntryTypes.mainMenuControlButton) {
                return (
                    <div key={key++} className="main-menu-control-button view-port-label-text-color-border">
                        <a onClick={this.props.onMainMenuControlBtnPressed}>
                            <OEIcon code={OEIconCodes.mainMenu} />
                        </a>
                    </div>
                );
            } else if(entry.type === OEMainMenuControlBarEntryTypes.logo)   {
                return <img key={key++} className="main-menu-control-button-logo" src={entry.src} />;
            } else if(entry.type === OEMainMenuControlBarEntryTypes.targetMenu) {
                return <OETargetMenu key={key++} moduleId={this.props.moduleId} hideWhenMediaCenterOpen={true} subOrientation={OETargetMenu.subOrientation.left}/>;
            }
        });

        /*
        if(this.props.version !== OEVersion.zeissOpti && this.props.version !== OEVersion.philipsIcarus)   {
            elements.push(
                <div key={key++} className="main-menu-control-button view-port-label-text-color-border">
                    <a onClick={this.props.onMainMenuControlBtnPressed}>
                        <OEIcon code={OEIconCodes.mainMenu} />
                    </a>
                </div>
            );
        }

        if(this.props.version === OEVersion.kind)   elements.push(<img key={key++} className="main-menu-control-button-logo" src="images/logo_KIND_128.png" />);
        */

        return (
            <React.Fragment>
                {elements}
            </React.Fragment>
        );
    }
}

export class OEMainMenuControlBar extends React.PureComponent {
    render() {
        if(!this.props.config.enabled)  return null;
        return (
            <div className="main-menu-control-button-bar" style={this.props.style}>
                <OEMainMenuControlBarEntryFactory {...this.props}/>
            </div>
        );
    }
}

OEMainMenuControlBar.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.mainMenuControlBar()
};

OEMainMenuControlBar.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        entries: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.oneOf([OEMainMenuControlBarEntryTypes.mainMenuControlButton, OEMainMenuControlBarEntryTypes.logo, OEMainMenuControlBarEntryTypes.targetMenu]),
            src: PropTypes.string
        }))
    })
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: ui.mainMenuControlBar,
        version: env.config.version,
        target: env.config.target
    }
})(OEMainMenuControlBar);