import OETextViewAdapter from '../../text-view/oe-text-view-adapter';
import {OEPhilipsIcarusCustomPropKey, OEPhilipsIcarusMode, OEPhilipsIcarusModel} from './oe-philips-icarus-model';

export default class OEPhilipsIcarusTextViewAdapter extends OETextViewAdapter {

    constructor() {
        super();

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);
    }

    onConnect(oe, controller)   {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    onRelease(oe, controller)   {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    updateSrc() {
        if(!this.oe || !this.oe.isReady())  return;
        const si = this.oe.sharedInterface;

        let animationId = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.animationId).value;
        let animation = OEPhilipsIcarusModel.shared().getAnimation(animationId);

        let mode = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;

        let textSrc = animation ? (mode == OEPhilipsIcarusMode.std ? animation.textSrc : (mode == OEPhilipsIcarusMode.xRay ? animation.xRayTextSrc : undefined)) : undefined;

        if(!textSrc)  {
            this.setSrc(null);
            return;
        }

        this.setSrc('app/oe/' + this.oe.Module.filePackagePathURL + 'philipsIcarusMedia/texts/' + textSrc);
    }

    updateLanguage()   {
        this.updateSrc();
    }

    updateState(released)   {
        if(!this.oe || !this.oe.isReady() || released === true)   {
            this.setSrc(null);
            return;
        }

        this.updateSrc();
    }

    onCustomPropertyChanged(message, userInfo)   {
        if(userInfo.key === OEPhilipsIcarusCustomPropKey.mode || userInfo.key === OEPhilipsIcarusCustomPropKey.animationId)   {
            this.updateSrc();
        }
    }
};