import React from 'react';  
import PropTypes from 'prop-types';

import OETextField from '../../elements/oe-text-field';
import OENumberInput from '../../elements/oe-number-input';
import {OEFunctionFragmentParameterTypes} from './oe-function-model';

class OEFunctionFragmentParameterCell extends React.PureComponent {

    constructor(props)  {
        super(props);
    }

    render()    {
        return  (
            <div className="fragment-parameter-cell">
                <div className="content">
                    <div className="bar">
                        <span className="label">{this.props.label}</span>
                        <span className="type">{this.props.type}</span>
                    </div>
                    <div className={'std-label-border-color edit-element ' + this.props.type}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
};

OEFunctionFragmentParameterCell.defaultProps = {
    name: 'unnamed',
    type: 'undefined',
    label: 'unnamed'
};

OEFunctionFragmentParameterCell.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
};

class OEFunctionFragmentStringParameterCell extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    render()    {
        return (
            <OEFunctionFragmentParameterCell {...this.props}>
                <OETextField
                    value={this.props.value}
                    clearButton={false}
                    onChange={this.onChange}
                /> 
            </OEFunctionFragmentParameterCell>
        );
    }

    onChange(sender, value)    {
        let value_ = value === '' ? undefined : value;
        if(value_ === this.props.value) return;
        if(this.props.onChange)   this.props.onChange(this, this.props.name, value_);
    }
};

OEFunctionFragmentStringParameterCell.defaultProps = Object.assign({}, OEFunctionFragmentParameterCell.defaultProps);

OEFunctionFragmentStringParameterCell.propTypes = Object.assign({
    value: PropTypes.string
}, OEFunctionFragmentParameterCell.propTypes);

class OEFunctionFragmentNumberParameterCell extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    render()    {
        return (
            <OEFunctionFragmentParameterCell {...this.props}>
                <OENumberInput
                    allowUndefined={true}
                    value={this.props.value}
                    step={this.props.type === OEFunctionFragmentParameterTypes.integer ? 1 : undefined}
                    onChange={this.onChange}
                />
            </OEFunctionFragmentParameterCell>
        );
    }

    onChange(sender, value)    {
        if(value === this.props.value) return;
        if(this.props.onChange)   this.props.onChange(this, this.props.name, value);
    }
};

OEFunctionFragmentNumberParameterCell.defaultProps = Object.assign({}, OEFunctionFragmentParameterCell.defaultProps);

OEFunctionFragmentNumberParameterCell.propTypes = Object.assign({
    value: PropTypes.number
}, OEFunctionFragmentParameterCell.propTypes);

export default function OEFunctionFragmentParameterCellFactory(props)   {
    const Types = OEFunctionFragmentParameterTypes;

    if(props.type === Types.string)  {
        return <OEFunctionFragmentStringParameterCell {...props}/>
    } else if(props.type === Types.integer || props.type === Types.float)  {
        return <OEFunctionFragmentNumberParameterCell {...props}/>
    } else {
        return <OEFunctionFragmentParameterCell {...props}/>
    }
}

OEFunctionFragmentParameterCellFactory.defaultProps = Object.assign({}, OEFunctionFragmentParameterCell.defaultProps);

OEFunctionFragmentParameterCellFactory.propTypes = Object.assign({}, OEFunctionFragmentParameterCell.propTypes);