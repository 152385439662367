import React from 'react';
import PropTypes from 'prop-types';

import {OEToolbox} from '../lib/oe-toolbox';
import {oeEventRouter} from './oe-event-router';

export default class Embed extends React.PureComponent {

    constructor(props) {
        super(props);
        this.keyCounter = 0;

        this.setRef = this.setRef.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.src !== this.props.src)     {

            // this is a hack to prevent reloading into the same dom iframe
            // if the key of a react component is changed the corresponding dom object is removed and a new one is added to the dom
            // we do this to prevent that the browsing history includes changes of the iframe src, i.e., the loaded target 
            this.keyCounter += 1;
        }
    }

    setRef(ref) {
        if(this.ref === ref) return;

        if(this.ref)    {
            oeEventRouter.removeRoute({window: this.ref.contentWindow, element: this.ref.contentWindow});
        }

        this.ref = ref;
        OEToolbox.bubbleIframeMouseEvents(ref);

        if(this.ref)    {
            oeEventRouter.addRoute({window: this.ref.contentWindow, element: this.ref.contentWindow});
        }
    }

    render() {
        return (
            <div className={this.props.name}>
                <iframe
                    className="oe-embed"
                    key={this.keyCounter}
                    src={this.props.src}
                    id={this.props.name}
                    ref={this.setRef}
                />
            </div>
        );
    }
}

Embed.defaultProps = {
    name: '',
    src: ''
};

Embed.propTypes = {
    name: PropTypes.string,
    src: PropTypes.string
};