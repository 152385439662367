import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import {OEAnimationControlAdapterStd} from '../../animation-control/oe-animation-adapter';
import OEAnimationControl from '../../animation-control/oe-animation-control';

export default class OEPresentationAnimationController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.adapter = new OEAnimationControlAdapterStd();
        this.adapter.disabledWhenStop = false;
        
        this.state = {
            uiEnabled: false,
            adapter: this.adapter
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false});
            this.adapter.clearBinding();
            return;
        }

        this.adapter.setBinding(this.oe, OEAnimationControlAdapterStd.Binding.presentation, this.oe.sharedInterface.getUIControllerPresentation().getActivePresentationID())
        
        this.updateUIState();
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
        }
    }

    onActivePresentationChanged(message, userInfo)   {
        this.adapter.setBinding(this.oe, OEAnimationControlAdapterStd.Binding.presentation, userInfo.presID)
    }

    render() {
        return (
            <OEAnimationControl
                enabled={this.state.uiEnabled && !this.props.disabled}
                adapter={this.state.adapter}
            />
        );
    }
}

OEPresentationAnimationController.defaultProps = {
    moduleId: '',
    disabled: false
};

OEPresentationAnimationController.propTypes = {
    moduleId: PropTypes.string,
    disabled: PropTypes.bool
};