import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../../lib/oe-higher-order-components';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEPopover from '../oe-popover';
import OEColor from './oe-color';
import {OEColorPalettePopover} from './oe-color-palette';
import {OEColorPickerViewType, OEColorPickerSVView, OEColorPickerHueView, OEColorPickerAlphaView, OEColorPickerPaletteView, OEColorPickerTextField, OEColorPickerRGBInput} from './oe-color-picker-views';
import OEAnimationLoop from '../../lib/oe-animation-loop';
import OEOpenStateElementContainer from '../../lib/oe-open-state-element-container';
import {oeUniqueIdGen} from '../../lib/oe-unique-id-gen';

export const OEColorPickerNumericInputType = {
    none: 'none',
    textField: 'textField',
    rgbInput: 'rgbInput',
    default: 'rgbInput'
};

export class OEColorPickerController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.uid + oeUniqueIdGen.get();
        this.colorPickerPopoverTargetId = 'color-picker-popover-target-id-' + this.uid;

        this.colorHSV = OEColor.sanitizeAddAlpha(this.props.colorHSV ? this.props.colorHSV : OEColor.toHSV(this.props.color), true);

        this.mouseState = 0;

        this.state = {
            colorHSV: OEColor.clone(this.colorHSV),
            defaultColorHSV: OEColor.sanitizeAddAlpha(this.props.defaultColorHSV ? this.props.defaultColorHSV : (this.props.defaultColor ? OEColor.toHSV(this.props.defaultColor) : null), true),
            isPaletteOpen: false,
            popoverPlacement: this.popoverPlacement()
        };

        this.onPaletteViewRef = this.onPaletteViewRef.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onMouseState = this.onMouseState.bind(this);
        this.onPaletteSelect = this.onPaletteSelect.bind(this);
        this.onPaletteButtonPressed = this.onPaletteButtonPressed.bind(this);
        this.onPaletteToggle = this.onPaletteToggle.bind(this);
        this.onPaletteColor = this.onPaletteColor.bind(this);
    }

    setColorHSV(colorHSV)   {
        this.colorHSV = OEColor.sanitizeAddAlpha(colorHSV, true);
        this.setState({colorHSV: OEColor.clone(this.colorHSV)});
    }

    popoverPlacement(props)   {
        props = props || this.props;
        let leftRight = props.placement.indexOf('left') >= 0 ? 'left' : 'right';
        return leftRight + '-start';
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.colorHSV)  {
            let nextColorHSV = OEColor.sanitizeAddAlpha(nextProps.colorHSV, true);
            if(!OEColor.compare(nextColorHSV, this.colorHSV))   this.setColorHSV(nextColorHSV);
        } else {
            let nextColor = OEColor.sanitizeAddAlpha(nextProps.color);
            let colorHSV = OEColor.toHSVPreservative(nextColor, this.colorHSV);
            if(!OEColor.compareEps(this.colorHSV, colorHSV))   this.setColorHSV(colorHSV);
        }

        let nextDefaultColorHSV = OEColor.sanitizeAddAlpha(nextProps.defaultColorHSV, true);
        let nextDefaultColor = OEColor.sanitizeAddAlpha(nextProps.defaultColor);

        if(!OEColor.compare(nextDefaultColorHSV, this.props.defaultColorHSV) || !OEColor.compare(nextDefaultColor, this.props.defaultColor))  {
            this.setState({defaultColorHSV: nextDefaultColorHSV ? nextDefaultColorHSV : (nextDefaultColor ? OEColor.toHSV(nextDefaultColor) : null)});
        }

        if((nextProps.disabled != this.props.disabled && nextProps.disabled) || (nextProps.hasPaletteButton != this.props.hasPaletteButton && !nextProps.hasPaletteButton))    {
            this.setState({isPaletteOpen: false});
        }

        if(nextProps.placement != this.props.placement) {
            this.setState({popoverPlacement: this.popoverPlacement(nextProps)});
        }
    }

    onPaletteViewRef(ref)   {
        this.paletteViewRef = ref;
    }

    renderNumericInput()    {
        if(this.props.numericInput === OEColorPickerNumericInputType.none)  return null;

        return this.props.numericInput === OEColorPickerNumericInputType.textField ?
            <OEColorPickerTextField
                disabled={this.props.disabled}
                colorHSV={this.state.colorHSV}
                hasAlphaControl={this.props.hasAlphaControl}
                onChange={this.onChange}
                onMouseState={this.onMouseState}
            /> :
            <OEColorPickerRGBInput
                disabled={this.props.disabled}
                colorHSV={this.state.colorHSV}
                hasAlphaControl={this.props.hasAlphaControl}
                onChange={this.onChange}
                onMouseState={this.onMouseState}
            />;
    }

    renderBottomBar()   {
        if(this.props.numericInput === OEColorPickerNumericInputType.none && !this.props.hasPaletteButton) return null;
        return (
            <div className="bottom-bar">
                {this.renderNumericInput()}
                <div className="right-buttons">
                    {!this.props.hasPaletteButton ? null :
                        <OEButton
                            className="btn transparent-btn palette-button"
                            disabled={this.props.disabled}
                            activated={this.state.isPaletteOpen}
                            onPressed={this.onPaletteButtonPressed}
                        >
                            <OEIcon code={OEIconCodes.colorPicker.palette}/>
                        </OEButton>
                    }
                </div>
            </div>
        );
    }

    render()    {
        return (
            <React.Fragment>
                <div className={'color-picker ' + this.props.className}>
                    <div className={this.colorPickerPopoverTargetId}/>
                    <div className="pickers">
                        <OEColorPickerSVView
                            disabled={this.props.disabled}
                            colorHSV={this.state.colorHSV}
                            onChange={this.onChange}
                            onMouseState={this.onMouseState}
                        />
                        <OEColorPickerHueView
                            disabled={this.props.disabled}
                            colorHSV={this.state.colorHSV}
                            onChange={this.onChange}
                            onMouseState={this.onMouseState}
                        />
                        {!this.props.hasAlphaControl ? null :
                            <OEColorPickerAlphaView
                                disabled={this.props.disabled}
                                colorHSV={this.state.colorHSV}
                                alphaDisplayRange={this.props.alphaDisplayRange}
                                onChange={this.onChange}
                                onMouseState={this.onMouseState}
                            />
                        }
                        {!this.props.hasPaletteControl ? null :
                            <OEColorPickerPaletteView
                                moduleId={this.props.moduleId}
                                className={!this.props.hasPaletteButton ? 'narrow' : ''}
                                disabled={this.props.disabled}
                                colorHSV={this.state.colorHSV}
                                defaultColorHSV={this.state.defaultColorHSV}
                                alphaDisplayRange={this.props.hasAlphaControl ? this.props.alphaDisplayRange : {min: 1, max: 1}}
                                onSelect={this.onPaletteSelect}
                                ref={this.onPaletteViewRef}
                            />
                        }
                    </div>
                    {this.renderBottomBar()}
                    {!this.props.hasPaletteButton ? null :
                        <OEIcon className={'palette-toggle ' + (this.state.isPaletteOpen ? 'open' : 'closed')} code={OEIconCodes.colorPicker.paletteToggle}/>
                    }
                </div>
                {!this.props.hasPaletteButton ? null :
                    <OEColorPalettePopover
                        moduleId={this.props.moduleId}
                        className={this.props.popoverClassName + (this.props.backdrop ? ' with-backdrop' : '')}
                        boundariesElement={this.props.boundariesElement}
                        target={this.props.popoverTargetId ? this.props.popoverTargetId : this.colorPickerPopoverTargetId}
                        placement={this.state.popoverPlacement}
                        hideArrow={true}
                        isOpen={this.state.isPaletteOpen}
                        onToggle={this.onPaletteToggle}
                        onColor={this.onPaletteColor}
                    />
                }
            </React.Fragment>
        );
    }

    onMouseState(sender, state) {
        let oldMouseState = this.mouseState;
        this.mouseState = Math.max(0, this.mouseState + (state ? 1 : -1));

        if(oldMouseState === this.mouseState)   return;

        if(this.mouseState === 1 && [OEColorPickerViewType.sv, OEColorPickerViewType.hue, OEColorPickerViewType.alpha, OEColorPickerViewType.textField, OEColorPickerViewType.rgbInput].includes(sender.viewType))   {
            this.trackNextChange = true;
        }
        
        if(!this.mouseState)    this.trackNextChange = false;
    }

    onChange(sender, colorHSV, isDefault = false) {
        if(this.props.disabled) return;
        colorHSV = OEColor.sanitizeAddAlpha(colorHSV, true);
        if(OEColor.compare(colorHSV, this.colorHSV))    return;

        if(!this.mouseState || this.trackNextChange)    {
            if(this.paletteViewRef) this.paletteViewRef.addColor();
            this.trackNextChange = false;
        }

        let color = OEColor.fromHSV(colorHSV);
        let oldColorHSV = this.colorHSV;

        //console.log('onChange - HSV - ' + OEColor.debugStrHSV(colorHSV_) + ' - ' + OEColor.debugStr(color));

        this.setColorHSV(colorHSV);

        if(!this.props.separatedDelegation) {
            if(this.props.onChange) this.props.onChange(color, colorHSV, isDefault);
        } else {
            if(this.props.onChange && !OEColor.compare(colorHSV, oldColorHSV, true))    this.props.onChange(color, colorHSV, isDefault);
            if(this.props.onAlphaChange && colorHSV.w !== oldColorHSV.w)    this.props.onAlphaChange(colorHSV.w, isDefault);
        }
    }

    onPaletteSelect(sender, colorHSV, isDefault)    {
        if(this.props.disabled) return;
        this.onChange(sender, colorHSV, isDefault);
    }

    onPaletteButtonPressed()    {
        this.onPaletteToggle();
    }

    onPaletteToggle()   {
        this.setState(state => {
            let newState = Object.assign({}, state);
            newState.isPaletteOpen = !newState.isPaletteOpen;
            return newState;
        });
    }

    onPaletteColor(color)   {
        if(this.props.disabled) return;
        this.onChange(null, OEColor.toHSV(color));
    }
}

OEColorPickerController.defaultProps = {
    moduleId: '',
    className: '',
    popoverClassName: '',
    disabled: false,
    placement: 'right',
    color: {x: 1, y: 1, z: 1},
    hasAlphaControl: true,
    alphaDisplayRange: {
        min: 0,
        max: 1,
        //palette: {min: 1, max: 1}
    },
    hasPaletteControl: true,
    numericInput: OEColorPickerNumericInputType.default,
    hasPaletteButton: false,
    separatedDelegation: false,
    backdrop: false
};

OEColorPickerController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    popoverClassName: PropTypes.string,
    popoverTargetId: PropTypes.string,
    disabled: PropTypes.bool,
    placement: PropTypes.string,
    color: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    colorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    defaultColor: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    defaultColorHSV: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        w: PropTypes.number
    }),
    hasAlphaControl: PropTypes.bool,
    alphaDisplayRange: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        alpha: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        palette: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        button: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
    }),
    hasPaletteControl: PropTypes.bool,
    numericInput: PropTypes.oneOf([OEColorPickerNumericInputType.none, OEColorPickerNumericInputType.textField, OEColorPickerNumericInputType.rgbInput]),
    hasPaletteButton: PropTypes.bool,
    separatedDelegation: PropTypes.bool,
    onChange: PropTypes.func,
    onAlphaChange: PropTypes.func,
    backdrop: PropTypes.bool
};

export const OEColorPickerPopover = OEPopover.coat(OEColorPickerController, {}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    titleId: 'color_picker_view',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OEColorPickerPopover);

const colorPickerButtonContainer = new OEOpenStateElementContainer();

export class OEColorPickerButton extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.isOpen = false;

        this.colorHSV = OEColor.sanitizeAddAlpha(this.props.colorHSV ? this.props.colorHSV : OEColor.toHSV(this.props.color), true);

        this.elementRef = null;

        this.state = {
            isOpen: this.isOpen,
            colorHSV: OEColor.clone(this.colorHSV),
            elementRef: null
        };

        this.onElementRef = this.onElementRef.bind(this);

        this.onPressed = this.onPressed.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAlphaChange = this.onAlphaChange.bind(this);
        this.outOffScreenCheck = this.outOffScreenCheck.bind(this);
    }

    setOpen(isOpen)   {
        if(this.isOpen == isOpen || (this.props.disabled && isOpen))   return;
        this.isOpen = isOpen;
        this.setState({isOpen: this.isOpen});
        if(this.props.closeOther && this.isOpen)    {
            colorPickerButtonContainer.closeAll(this);
        }
        if(this.isOpen && this.props.onShow)    this.props.onShow(OEColor.fromHSV(this.colorHSV), this.colorHSV);
        if(!this.isOpen && this.props.onHide)    this.props.onHide(OEColor.fromHSV(this.colorHSV), this.colorHSV);
    }

    componentWillUnmount()    {
        colorPickerButtonContainer.unregister(this);
        this.setOpen(false);
    }

    componentDidMount()    {
        colorPickerButtonContainer.register(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.disabled)     this.setOpen(false);

        if(nextProps.colorHSV)  {
            let nextColorHSV = OEColor.sanitizeAddAlpha(nextProps.colorHSV, true);
            if(!OEColor.compare(nextColorHSV, this.colorHSV))   {
                this.colorHSV = nextColorHSV;
                this.setState({colorHSV: OEColor.clone(this.colorHSV)});
            }
        } else {
            let nextColor = OEColor.sanitizeAddAlpha(nextProps.color);
            let colorHSV = OEColor.toHSVPreservative(nextColor, this.colorHSV);
            if(!OEColor.compare(this.colorHSV, colorHSV))   {
                this.colorHSV = colorHSV;
                this.setState({colorHSV: OEColor.clone(this.colorHSV)});
            }
        }
    }

    onElementRef(ref)    {
        if(this.elementRef === ref) return;
        this.elementRef = ref;
        this.setState({elementRef: ref});
    }

    render()    {
        const {className, style, popoverClassName, color, colorHSV, onChange, onAlphaChange, onShow, onHide, ...rest} = this.props;

        let btnStyle = {backgroundColor: OEColor.hsvToDOMStr(OEColor.mapAlphaWithRange(this.state.colorHSV, this.props.alphaDisplayRange, 'button'))};

        if(style)   btnStyle = Object.assign(btnStyle, style);

        let picker = this.state.isOpen && !this.props.target && !this.state.elementRef ? null :
            <OEColorPickerPopover
                className={popoverClassName}
                target={this.state.elementRef}
                {...rest}
                isOpen={this.state.isOpen}
                onToggle={this.onToggle}
                colorHSV={this.state.colorHSV}
                onChange={this.onChange}
                onAlphaChange={this.onAlphaChange}
            />;

        return (
            <React.Fragment>
                <OEButton
                    className={'color-picker-btn ' + this.props.className}
                    disabled={this.props.disabled}
                    onPressed={this.onPressed}
                    elementRef={this.onElementRef}
                >
                    <div className="content">
                        <div className="background checker-background"/>
                        <div className="foreground" style={btnStyle}/>
                        {this.props.children}
                    </div>
                </OEButton>
                {picker}
                {this.state.isOpen && this.props.closeWhenOutOfScreen ? <OEAnimationLoop onFramemove={this.outOffScreenCheck} /> : null}
            </React.Fragment>
        );
    }

    onPressed() {
        if(this.props.disabled)     return;
        this.setOpen(!this.isOpen);
    }

    onToggle()  {
        this.setOpen(false);
    }

    onChange(color, colorHSV, isDefault = false)   {
        this.colorHSV = OEColor.clone(colorHSV);
        this.setState({colorHSV: OEColor.clone(this.colorHSV)});
        if(this.props.onChange) this.props.onChange(color, colorHSV, isDefault);
    }

    onAlphaChange(alpha, isDefault = false)    {
        this.colorHSV.w = alpha;
        this.setState({colorHSV: OEColor.clone(this.colorHSV)});
        if(this.props.onAlphaChange) this.props.onAlphaChange(alpha, isDefault);
    }

    outOffScreenCheck()  {
        if(!this.isOpen || !this.props.closeWhenOutOfScreen || !this.elementRef)    return;

        let windowSize = {w: window.innerWidth || document.documentElement.clientWidth, h: window.innerHeight || document.documentElement.clientHeight};

        let rect = this.elementRef.getBoundingClientRect();
        //let visible = rect.left < windowSize.w && rect.top < windowSize.h && rect.right >= 0 && rect.bottom >= 0;
        let completeVisible = rect.right < windowSize.w && rect.bottom < windowSize.h && rect.left >= 0 && rect.top >= 0;

        if(!completeVisible)    this.setOpen(false);
    }
}

OEColorPickerButton.defaultProps = Object.assign({}, OEColorPickerPopover.defaultProps, {
    closeOther: true,
    closeWhenOutOfScreen: false,
});

OEColorPickerButton.propTypes = Object.assign({}, OEColorPickerPopover.propTypes, {
    closeOther: PropTypes.bool,
    closeWhenOutOfScreen: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func
});