import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OEIconButton} from '../../elements/oe-button';
import OEResizeObserver from '../../../lib/oe-resize-observer';
import OEScrollbars from '../../oe-scrollbars';
import {OEIconCodes} from '../../../lib/oe-icon-codes';

export default class OEOverlayTextBox extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            style: null
        };

        this.onWindowResized = this.onWindowResized.bind(this);

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onIFrameRef = this.onIFrameRef.bind(this);
        
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
        this.onToLeftRightBtnPressed = this.onToLeftRightBtnPressed.bind(this);
    }

    componentWillUnmount()  {
        this.setCompId(null);
    }

    onWindowResized()   {
        this.updateStyle();
    }

    onConnect()  {}

    onRelease()  {}

    setCompId(id)   {
        if(this.compId == id || !this.oe.isReady())   return;
        let highlight = this.oe.sharedInterface.getUIControllerHighlightingTooltip();

        if(this.compId) highlight.removeCustomHighlightedComponent(this.compId);
        this.compId = id;
        if(this.compId) highlight.addCustomHighlightedComponent(this.compId);
    }

    updateStyle()   {
        if(!this.$iFrameDocumentBody)    return;
        let style = {height: this.$iFrameDocumentBody.innerHeight()};
        this.setState({style: style});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setCompId(null);
            this.setState({src: '', style: null});
            return;
        }

        this.setCompId(null);
    }

    onMouseEnter(e) {
        this.setCompId(e.target.dataset.id);
    }

    onMouseLeave(e) {
        if(this.compId == e.target.dataset.id)  this.setCompId(null);
    }

    onLoad()    {
        if(!this.iFrameRef || !this.iFrameRef.contentWindow)    return;
        this.iFrameDocument = this.iFrameRef.contentWindow.document;

        let host = window.location.host;
        let path = window.location.pathname;
        let url = 'http://' + host + path;

        let cssFiles = [
            'fonts/effigos-symbol.css',
            'fonts/roboto/roboto.css'
        ];

        cssFiles.forEach(path => {
            let cssLink = document.createElement('link');
            cssLink.rel = 'stylesheet';
            cssLink.type = 'text/css';
            cssLink.href = url + path;
            document.head.appendChild(cssLink);
        });

        $(this.iFrameDocument.documentElement).find('span.comp-ref').hover(this.onMouseEnter, this.onMouseLeave);
        this.$iFrameDocumentBody = $(this.iFrameDocument.documentElement).find('body');

        this.updateStyle();
    }

    onResize() {
        this.updateStyle();
    }

    onIFrameRef(ref)   {
        if(this.iFrameRef === ref) return;
        this.iFrameRef = ref;
        if(!ref && this.iFrameDocument)    {
            $(this.iFrameDocument.documentElement).find('span.comp-ref').off('mouseenter mouseleave');
            this.iFrameDocument = null;
            this.$iFrameDocumentBody = null;
        }
    }

    render() {
        const LayoutType = OEOverlayTextBox.ExtLayoutType;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>

                <div className={'overlay-text-box ' + this.props.extLayoutType + (this.props.isOpen ? ' isOpen ' : ' ') + (this.props.isHidden ? ' isHidden ' : ' ') + this.props.className}>
                    <OEResizeObserver onResize={this.onResize}/>

                    <OEScrollbars>
                        <iframe
                            style={this.state.style}
                            src={this.props.src}
                            ref={this.onIFrameRef}
                            onLoad={this.onLoad}
                        />
                    </OEScrollbars>

                    <OEIconButton
                        className={'btn ' + this.props.extLayoutType + ' top-btn close-btn'}
                        icon={OEIconCodes.overlayTextBox.textBoxClose}
                        onPressed={this.onCloseBtnPressed}
                    />

                    <OEIconButton
                        className={'btn ' + (this.props.extLayoutType === LayoutType.right ? LayoutType.left : LayoutType.right) + ' top-btn'}
                        icon={this.props.extLayoutType === LayoutType.right ? OEIconCodes.overlayTextBox.textBoxToLeft : OEIconCodes.overlayTextBox.textBoxToRight}
                        onPressed={this.onToLeftRightBtnPressed}
                    />
                </div>

            </React.Fragment>
        );
    }

    onCloseBtnPressed()   {
        if(this.props.onToggle) this.props.onToggle();
    }

    onToLeftRightBtnPressed()   {
        const LayoutType = OEOverlayTextBox.ExtLayoutType;
        if(this.props.onToLeftRightBtnPressed) this.props.onToLeftRightBtnPressed(this.props.extLayoutType === LayoutType.right ? LayoutType.left : LayoutType.right);
    }
}

OEOverlayTextBox.ExtLayoutType = {
    right: 'right',
    left: 'left'
};

OEOverlayTextBox.defaultProps = {
    className: '',
    moduleId: '',
    isOpen: true,
    isHidden: false,
    extLayoutType: OEOverlayTextBox.ExtLayoutType.right
};

OEOverlayTextBox.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    isOpen: PropTypes.bool,
    isHidden: PropTypes.bool,
    extLayoutType: PropTypes.oneOf([OEOverlayTextBox.ExtLayoutType.right, OEOverlayTextBox.ExtLayoutType.left]),
    src: PropTypes.string,
    onToggle: PropTypes.func,
    onToLeftRightBtnPressed: PropTypes.func
};