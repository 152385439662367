import React from 'react';
import PropTypes from 'prop-types';

export class AnimationTimer {
    
    constructor(onAnimationBegan, onFrame, shouldEndAnimation, onAnimationEnded) {
        this.play_ = 0;
        this.playing = false;

        this.onFrame = this.onFrame.bind(this);

        this.props = {
            onAnimationBegan: onAnimationBegan,
            onFrame: onFrame,
            shouldEndAnimation: shouldEndAnimation,
            onAnimationEnded: onAnimationEnded,
        }
    }

    play()  {
        this.play_++;
        this.update();
    }

    stop()  {
        if(this.play_ <= 0) return;
        this.play_--;
        this.update();
    }

    isPlaying() {
        return this.shouldPlay();
    }

    shouldPlay()    {
        return this.play_ > 0;
    }

    update()   {
        if(this.shouldPlay())   {
            this.startPlay();
        } else {
            this.endPlay();
        }
    }

    startPlay() {
        if(this.playing) return;
        this.endAnimation = false;
        this.startTime = 0.001 * Date.now();
        this.currentTime = 0;
        if(this.props.onAnimationBegan) this.props.onAnimationBegan(this, this.currentTime);
        requestAnimationFrame(this.onFrame);
    }

    endPlay()   {
        this.endAnimation = true;
    }

    onAnimationEnded()  {
        this.playing = false;
        if(this.props.onAnimationEnded) this.props.onAnimationEnded(this, this.currentTime);
    }

    onFrame()   {
        this.currentTime = 0.001 * Date.now() - this.startTime;

        if((this.props.shouldEndAnimation && this.props.shouldEndAnimation(this, this.currentTime)) || (this.props.onFrame && this.props.onFrame(this, this.currentTime)))  {
            this.play_ = 0;
            this.update();
        }

        if(this.endAnimation)  {
            this.onAnimationEnded();
            return;
        }

        requestAnimationFrame(this.onFrame);
    }
}

export class AnimationTimerComponent extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.mounted = false;
        this.play_ = 0;

        this.playing = false;

        this.onFrame = this.onFrame.bind(this);
    }

    play()  {
        this.play_++;
        this.update();
    }

    stop()  {
        if(this.play_ <= 0) return;
        this.play_--;
        this.update();
    }

    isPlaying() {
        return typeof(this.props.play) === 'boolean' ? this.props.play : (this.play_ > 0);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.play !== this.props.play)     {
            this.update();
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.update();
    }

    componentWillUnmount()    {
        this.mounted = false;
        this.update();
    }

    shouldPlay()    {
        return this.mounted && (typeof(this.props.play) === 'boolean' ? this.props.play : (this.play_ > 0));
    }

    update()   {
        if(this.shouldPlay())   {
            this.startPlay();
        } else {
            this.endPlay();
        }
    }

    startPlay() {
        if(this.playing) return;
        this.endAnimation = false;
        this.startTime = 0.001 * Date.now();
        this.currentTime = 0;
        if(this.props.onAnimationBegan) this.props.onAnimationBegan(this, this.currentTime);
        requestAnimationFrame(this.onFrame);
    }

    endPlay()   {
        this.endAnimation = true;
    }

    onAnimationEnded()  {
        this.playing = false;
        if(this.props.onAnimationEnded) this.props.onAnimationEnded(this, this.currentTime);
    }

    onFrame()   {
        this.currentTime = 0.001 * Date.now() - this.startTime;

        if((this.props.shouldEndAnimation && this.props.shouldEndAnimation(this, this.currentTime)) || (this.props.onFrame && this.props.onFrame(this, this.currentTime)))  {
            this.play_ = 0;
            this.update();
        }

        if(this.endAnimation)  {
            this.onAnimationEnded();
            return;
        }

        requestAnimationFrame(this.onFrame);
    }

    render() {
        return null;
    }
}

AnimationTimerComponent.defaultProps = {
};

AnimationTimerComponent.propTypes = {
    play: PropTypes.bool,
    onAnimationBegan:  PropTypes.func,
    onFrame: PropTypes.func,
    shouldEndAnimation: PropTypes.func,
    onAnimationEnded: PropTypes.func
};