import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

export default class OEDropdown extends React.PureComponent {

    constructor(props) {
        super(props);

        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_
        };

        this.toggle = this.toggle.bind(this);
    }

    setOpen(open)   {
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
    }

    isOpen()    {
        return this.isOpen_;
    }

    toggle() {
        this.setOpen(!this.isOpen());
    }

    onClick(key, userData)   {
        this.props.onClick(key, userData);
    }

    render() {

        const items = this.props.entries.map((entry) =>
            <DropdownItem
                key={entry.key}
                active={entry.key === this.props.chosenKey}
                disabled={this.props.disabled}
                onClick={() => this.onClick(entry.key, this.props.userData)}
            >
                {entry.content}
            </DropdownItem>
        );

        return (
            <Dropdown className={this.props.className} disabled={this.props.disabled} isOpen={this.state.isOpen && !this.props.disabled} toggle={this.toggle}>
                <DropdownToggle className={'d-flex w-100 justify-content-between ' + 'dropdown-toggle ' + this.props.toggleClassName} caret disabled={this.props.disabled}>
                    {this.props.title}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                    {items}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

OEDropdown.defaultProps = {
    className: '',
    toggleClassName: '',
    disabled: false,
    chosenKey: '',
    entries: [],
    onClick: (key) => {}
};

OEDropdown.propTypes = {
    className: PropTypes.string,
    toggleClassName: PropTypes.string,
    title: PropTypes.node,
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    onClick: PropTypes.func
};