import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import * as screenfull from 'screenfull';

import {connectAppEnv} from '../../../app-env';
import {frefFromRef, frefToRef} from '../../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../../react-oe/oe-interface-adapter';
import {retardUpdate} from '../../../../lib/update-retarder';
import {OETarget} from '../../../../react-oe/oe-target';
import {OEIconCodes} from '../../../../lib/oe-icon-codes';
import OEStdCapBar from './oe-std-cap-bar';
import {OEToolbox} from '../../../../lib/oe-toolbox';
import {OETargetMenu} from '../../../elements/target-menu/oe-target-menu';

export class OEStdCapBarController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.targetTypes = this.getTargetTypes();
        this.activeTargetType = this.getActiveTargetType();

        this.state = {
            targetTypes: clone(this.targetTypes),
            activeTargetType: this.activeTargetType
        };

        this.updateLastTargetForType();

        this.updateLanguage = this.updateLanguage.bind(this);
        
        this.onTargetBtnPressed = this.onTargetBtnPressed.bind(this);
    }

    componentWillUpdate(nextProps)   {
        let targetTypesChanged = !OEToolbox.shallowEqual(nextProps.targetTypes, this.props.targetTypes);

        if(targetTypesChanged)  this.updateTargetTypes(nextProps);

        if(nextProps.target !== this.props.target || nextProps.targetTypeForTarget !== this.props.targetTypeForTarget || targetTypesChanged)  {
            let needsLastTargetForTypeReset = nextProps.targetTypeForTarget !== this.props.targetTypeForTarget;
            if(!needsLastTargetForTypeReset)    {
                let next = nextProps.targetTypes ? nextProps.targetTypes.map(targetType => { return {id: targetType.id, target: targetType.target}; }) : null;
                let current = this.props.targetTypes ? this.props.targetTypes.map(targetType => { return {id: targetType.id, target: targetType.target}; }) : null;
                needsLastTargetForTypeReset = !OEToolbox.shallowEqual(next, current);
            }
            
            if(needsLastTargetForTypeReset)  this.lastTargetForType = {};

            this.updateActiveTargetType(nextProps);
            this.updateLastTargetForType(nextProps);
        }

        if(!targetTypesChanged && nextProps.isTargetTypeDisabled !== this.props.isTargetTypeDisabled)   this.updateTargetTypesEnabledState(nextProps);
    }

    getActiveTargetType(props)    {
        props = props || this.props;
        if(props.targetTypeForTarget)   return props.targetTypeForTarget(props.target, this);

        if(!this.targetTypes.length)    return '';
        for(let i = 0; i < this.targetTypes.length; ++i)    if(this.targetTypes[i].target === props.target) return this.targetTypes[i].id;
        return this.targetTypes[0].id;
    }

    isTargetTypeDisabled(targetType, props)    {
        props = props || this.props;
        if(props.isTargetTypeDisabled)   return props.isTargetTypeDisabled(targetType, this);
        return false;
    }

    getTargetTypes(props)   {
        props = props || this.props;
        return !props.targetTypes ? [] : props.targetTypes.map(targetType => { return {
            id: targetType.id,
            disabled: this.isTargetTypeDisabled(targetType.id, props),
            proto: clone(targetType),
            strings: this.getStringsForTargetType(targetType)
        }});
    }

    getStringsForTargetType(targetType)    {
        let ret = {
            label: targetType.label,
            title: targetType.title,
            tooltip: targetType.tooltip,
            disabledTooltip: targetType.disabledTooltip,
        };
        if(!this.oe.isReady())  return ret;
        const si = this.oe.sharedInterface;

        if(targetType.labelId)  ret.label = si.getLocalizedStringEnc(targetType.labelId);
        if(targetType.titleId)  ret.title = si.getLocalizedStringEnc(targetType.titleId);
        if(targetType.tooltipId)  ret.tooltip = si.getLocalizedStringEnc(targetType.tooltipId);
        if(targetType.disabledTooltipId)  ret.disabledTooltip = si.getLocalizedStringEnc(targetType.disabledTooltipId);
        if(!ret.tooltip)    ret.tooltip = ret.title;
        return ret;
    }

    getLastTargetForType(targetType)    {
        targetType = targetType || this.activeTargetType;
        return this.lastTargetForType[targetType];
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateTargetTypes(props)   {
        this.targetTypes = this.getTargetTypes(props);
        this.setState({targetTypes: clone(this.targetTypes)});
    }

    updateActiveTargetType(props)   {
        let activeTargetType = this.getActiveTargetType(props);
        if(activeTargetType === this.activeTargetType)  return;
        this.activeTargetType = activeTargetType;
        this.setState({activeTargetType: activeTargetType});
        this.onActiveTargetTypeChanged(props);
    }

    updateTargetTypesEnabledState(props) {
        this.targetTypes.forEach(targetType => {
            targetType.disabled = this.isTargetTypeDisabled(targetType.id, props);
        });
        this.setState({targetTypes: clone(this.targetTypes)});
    }

    updateTargetTypeStrings()   {
        this.targetTypes.forEach(targetType => {
            targetType.strings = this.getStringsForTargetType(targetType.proto);
        });
        this.setState({targetTypes: clone(this.targetTypes)});
    }

    updateLastTargetForType(props)   {
        props = props || this.props;
        if(!this.lastTargetForType) this.lastTargetForType = {};
        if(typeof(this.activeTargetType) !== 'string') return;
        this.lastTargetForType[this.activeTargetType] = props.target;
    }

    updateLanguage()   {
        this.updateTargetTypeStrings();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    onActiveTargetTypeChanged(props) {
        this.updateTargetTypesEnabledState(props);
        this.updateLastTargetForType(props);
    }

    render()    {
        let targetBarTitle = null;
        let targetBarButton;
        let targetMenuEntries;
        let targetMenuDisabled;
        let targetMenuHidden;

        let targetButtonBar = this.state.targetTypes.map((targetType) => {
            if(targetType.id === this.state.activeTargetType)   {
                targetBarTitle = targetType.strings.title;
                targetBarButton = targetType.proto.targetBarButton;
                targetMenuEntries = targetType.proto.targetMenuEntries;
                targetMenuDisabled = targetType.proto.targetMenuDisabled;
                targetMenuHidden = targetType.proto.targetMenuHidden;
            }
            
            return {
                icon: targetType.proto.icon,
                label: targetType.strings.label,
                activated: targetType.id === this.state.activeTargetType,
                disabled: targetType.disabled,
                onPressed: this.onTargetBtnPressed,
                userData: targetType.proto,
                tooltip: targetType.strings.tooltip,
                disabledTooltip: targetType.strings.disabledTooltip
            };
        });

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEStdCapBar
                    className={this.props.className}
                    moduleId={this.props.moduleId}
                    disabled={this.props.disabled}
                    showFullscreenBtn={this.props.showFullscreenBtn}
                    isFullscreen={this.props.isFullscreen}
                    appComponent={this.props.appComponent}
                    targetBarTitle={targetBarTitle}
                    targetBarLabel={this.props.targetBarLabel}
                    targetBarButton={targetBarButton}
                    targetButtonBar={targetButtonBar}
                    targetMenuEntries={targetMenuEntries}
                    targetMenuDisabled={targetMenuDisabled}
                    targetMenuHidden={targetMenuHidden}
                />
            </React.Fragment>
        );
    }

    onTargetBtnPressed(event, sender)    {
        if(this.props.onTargetBtnPressed)   {
            this.props.onTargetBtnPressed(sender.props.userData, this.props.target, this);
            return;
        }
        if(!this.props.appComponent)    return;
        let lastTargetForType = this.getLastTargetForType(sender.props.userData.id);
        let target = this.props.targetTypeTargetForTarget ? this.props.targetTypeTargetForTarget(sender.props.userData, this.props.target, this) : (lastTargetForType ? lastTargetForType : sender.props.userData.target);
        if(typeof(target) !== 'string') return;
        this.props.appComponent.app.changeTarget(target);
    }
}

OEStdCapBarController.defaultProps = {
    className: '',
    moduleId: '',
    target: '',
    showFullscreenBtn: true,
    isFullscreen: false,
    targetTypes: [
        {id: OETarget.humanEyeMacro, target: OETarget.humanEyeMacro, icon: OEIconCodes.zeissOpti.anatomyTarget, titleId: 'target_menu_anatomy'},
        {id: OETarget.zeissOpti2, target: OETarget.zeissOpti2, icon: OEIconCodes.zeissOpti.optiTarget, titleId: 'target_menu_zeissOpti2'},
        {id: OETarget.zeissUV, target: OETarget.zeissUV, icon: OEIconCodes.zeissOpti.UVTarget, titleId: 'target_menu_zeissUV'}
    ]
};

OEStdCapBarController.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    disabled: PropTypes.bool,
    target: PropTypes.string,
    showFullscreenBtn: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    appComponent: PropTypes.object,
    targetBarLabel: PropTypes.string,
    targetTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        target: PropTypes.string,
        icon: PropTypes.string,
        label: PropTypes.string,
        labelId: PropTypes.string,
        title: PropTypes.string,
        titleId: PropTypes.string,
        tooltip: PropTypes.string,
        tooltipId: PropTypes.string,
        disabledTooltip: PropTypes.string,
        disabledTooltipId: PropTypes.string,
        targetBarButton: PropTypes.shape({
            className: PropTypes.string,
            disabled: PropTypes.bool,
            activated: PropTypes.bool,
            onPressed: PropTypes.func,
            icon: PropTypes.string
        }),
        targetMenuEntries: OETargetMenu.propTypes.entries,
        targetMenuDisabled: PropTypes.bool,
        targetMenuHidden: PropTypes.bool
    })),
    targetTypeForTarget: PropTypes.func,
    isTargetTypeDisabled: PropTypes.func,
    onTargetBtnPressed: PropTypes.func,
    targetTypeTargetForTarget: PropTypes.func
};

export default frefFromRef(connectAppEnv((env) => { return {
    target: env.config.target,
    showFullscreenBtn: screenfull.enabled,
    isFullscreen: env.state.isFullscreen,
    appComponent: env.component
}})(frefToRef(OEStdCapBarController)));