import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OEGroupControl, OEControl, OENumberControl, OEIconButtonControl, OECheckboxControl} from '../../oe-controls';
import OEPopover from '../../oe-popover';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEToolbox} from '../../../lib/oe-toolbox';
import OENotesAreInDocumentButton from '../../elements/oe-notes-are-in-document-button';

export class OEPresentationSlideController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            data: null,
            strings: {
                displayTime: 'Display Time',
                transitionTime: 'Transition Time',
                showInPreview: 'Show in Preview',
                resetComponentColors: 'Reset Component Colors',
                notesAreInDocument: 'Apply 4:3 to all Notes'
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onSlideDataChanged = this.onSlideDataChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onDisplayTimeChanged = this.onDisplayTimeChanged.bind(this);
        this.onTransitionTimeChanged = this.onTransitionTimeChanged.bind(this);
        this.onShowInPlayerPreviewChanged = this.onShowInPlayerPreviewChanged.bind(this);
        this.onComponentColorsResetBtnPressed = this.onComponentColorsResetBtnPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.jsonEqual(nextProps.ids, this.props.ids))     {
            this.updateSlideData(undefined, nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideDataChanged, this.onSlideDataChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideDataChanged, this.onSlideDataChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        let si = this.oe.sharedInterface;
        this.setState({strings: {
            displayTime: si.getLocalizedStringEnc('pres_slide_display_time'),
            transitionTime: si.getLocalizedStringEnc('pres_slide_transition_time'),
            showInPreview: si.getLocalizedStringEnc('pres_slide_show_in_preview'),
            resetComponentColors: si.getLocalizedStringEnc('pres_slide_component_colors_reset'),
            notesAreInDocument: si.getLocalizedStringEnc('pres_slide_notes_are_in_document')
        }});
    }

    updateSlideData(data, props)   {
        data = data || null;
        props = props || this.props;
        if(this.oe.isReady() && !data && props.ids)  {
            let pres = this.oe.sharedInterface.getUIControllerPresentation();
            data = pres.getSlideDataID(props.ids.pres, props.ids.slide)
        }
        this.setState({data: data});
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({uiEnabled: false, data: null});
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateSlideData();
            this.updateUIState();
        });
    }

    onSlideDataChanged(message, userInfo)    {
        if(!this.props.ids || this.props.ids.pres != userInfo.presID || this.props.ids.slide != userInfo.slideID) return;
        this.updateSlideData(userInfo.data);
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
        }
    }

    render()    {
        const disabled = !this.state.uiEnabled || !this.state.data;
        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="presentation-slide-controller">
                    <OEGroupControl>
                        <OENumberControl
                            className="std-label-border-color"
                            title = {this.state.strings.displayTime}
                            disabled={disabled} 
                            min={0.1}
                            max={86400}
                            step={0.01}
                            value={this.state.data ? this.state.data.slideViewTime : 0.5}
                            unit="s"
                            onChange={this.onDisplayTimeChanged}
                        />
                        <OENumberControl
                            className="std-label-border-color"
                            title = {this.state.strings.transitionTime}
                            disabled={disabled} 
                            min={0}
                            max={60}
                            step={0.01}
                            value={this.state.data ? this.state.data.slideTransitionTime : 0}
                            unit="s"
                            onChange={this.onTransitionTimeChanged}
                        />
                        <OECheckboxControl
                            title={this.state.strings.showInPreview}
                            checked={this.state.data ? this.state.data.showInPlayerPreview : false}
                            disabled={disabled}
                            onPressed={this.onShowInPlayerPreviewChanged}
                        />
                        {!this.props.devFeatures ? null : 
                            <OEIconButtonControl
                                title={this.state.strings.resetComponentColors}
                                disabled={disabled}
                                icon={OEIconCodes.pres.resetCompColors}
                                onPressed={this.onComponentColorsResetBtnPressed}
                            />
                        }
                        {!this.props.devFeatures ? null :
                            <OEControl className="text-right" title={this.state.strings.notesAreInDocument}>
                                <OENotesAreInDocumentButton moduleId={this.props.moduleId}/>
                            </OEControl>
                        }
                    </OEGroupControl>
                </div>
            </React.Fragment>
        );
    }

    onDisplayTimeChanged(sender, value) {
        if(!this.oe.isReady() || !this.props.ids)  return;
        this.oe.sharedInterface.getUIControllerPresentation().setSlideViewTimeID(this.props.ids.pres, this.props.ids.slide, value);
    }

    onTransitionTimeChanged(sender, value) {
        if(!this.oe.isReady() || !this.props.ids)  return;
        this.oe.sharedInterface.getUIControllerPresentation().setSlideTransitionTimeID(this.props.ids.pres, this.props.ids.slide, value);
    }

    onShowInPlayerPreviewChanged()   {
        if(!this.oe.isReady() || !this.props.ids || !this.state.data)  return;
        this.oe.sharedInterface.getUIControllerPresentation().setShowInPlayerPreviewID(this.props.ids.pres, this.props.ids.slide, !this.state.data.showInPlayerPreview);
    }

    onComponentColorsResetBtnPressed()  {
        if(!this.oe.isReady() || !this.props.ids)  return;
        let si = this.oe.sharedInterface;
        let pres = si.getUIControllerPresentation();

        let title = si.getLocalizedStringEnc('alert_pres_reset_component_colors_title'), msg = si.getLocalizedStringEnc('alert_pres_reset_component_colors_msg');

        let buttons = (id, container) => [{
                title: si.getLocalizedStringEnc('alert_pres_cancel_btn_message'),
                onClick: () => { container.closeModal(id) },
                className: 'btn-primary'
            },
            {
                title: si.getLocalizedStringEnc('alert_pres_reset_component_colors_only_hidden'),
                onClick: () => {
                    container.closeModal(id); 
                    pres.resetSlideComponentColorsID(this.props.ids.pres, this.props.ids.slide, true);
                    if(pres.getActivePresentationID() == this.props.ids.pres && pres.getActiveSlideID() == this.props.ids.slide)    pres.apply(true);
                },
                className: 'btn-primary'
            },
            {
                title: si.getLocalizedStringEnc('alert_pres_reset_component_colors_ok'),
                onClick: () => {
                    container.closeModal(id); 
                    pres.resetSlideComponentColorsID(this.props.ids.pres, this.props.ids.slide, false);
                    if(pres.getActivePresentationID() == this.props.ids.pres && pres.getActiveSlideID() == this.props.ids.slide)    pres.apply(true);
                },
                className: 'btn-primary'
            }
        ];

        this.props.appComponent.module.addModal(title, msg, buttons);
    }
}

OEPresentationSlideController.defaultProps = {
    moduleId: '',
    devFeatures: false
};

OEPresentationSlideController.propTypes = {
    moduleId: PropTypes.string,
    ids: PropTypes.shape({
        pres: PropTypes.number,
        slide: PropTypes.number,
    }),
    devFeatures: PropTypes.bool
};

export const OEPresentationSlidePopover = OEPopover.coat(OEPresentationSlideController, {}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    headerSeparator: true,
    titleId: 'presentation_slide_view',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OEPresentationSlidePopover);