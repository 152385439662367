import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import {OEToolbox} from '../../lib/oe-toolbox';
import KeyedCurve from '../../lib/keyed-curve';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEAnimalCellOverlay extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.numPhases = [7, 8];

        this.state = {
            activeAnim: -1,
            anims: [
                {
                    phasesAlpha: [1.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    phase0: {
                        strings: { label: 'Interphase' }
                    },
                    phase1:    {
                        strings: { label: 'Prophase' }
                    },
                    phase2:    {
                        strings: { label: 'Prometaphase' }
                    },
                    phase3:    {
                        strings: { label: 'Metaphase' }
                    },
                    phase4:    {
                        strings: { label: 'Anaphase' }
                    },
                    phase5:    {
                        strings: { label: 'Telophase' }
                    },
                    phase6:    {
                        strings: { label: 'Cytokinesis' }
                    }
                },
                {
                    phasesAlpha: [1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    phase0: {
                        strings: { label: 'Meiose I - Prophase I' }
                    },
                    phase1:    {
                        strings: { label: 'Meiose I - Metaphase I' }
                    },
                    phase2:    {
                        strings: { label: 'Meiose I - Anaphase I' }
                    },
                    phase3:    {
                        strings: { label: 'Meiose I - Telophase I' }
                    },
                    phase4:    {
                        strings: { label: 'Meiose II - Prophase II' }
                    },
                    phase5:    {
                        strings: { label: 'Meiose II - Metaphase II' }
                    },
                    phase6:    {
                        strings: { label: 'Meiose II - Anaphase II' }
                    },
                    phase7:    {
                        strings: { label: 'Meiose II - Telophase II' }
                    }
                }
            ]
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);
        
        this.updateLanguage = this.updateLanguage.bind(this);
        this.onAnimationModeChanged = this.onAnimationModeChanged.bind(this);
        this.onAnimationTimeChanged = this.onAnimationTimeChanged.bind(this);
        this.onActiveAnimationChanged = this.onActiveAnimationChanged.bind(this);

        this.curveAni = [new KeyedCurve(), new KeyedCurve()];

        this.curveAni[0].list = [
            {key: 290, value: 0},
            {key: 310, value: 1},
            {key: 430, value: 1},
            {key: 450, value: 2},
            {key: 650, value: 2},
            {key: 670, value: 3},
            {key: 910, value: 3},
            {key: 930, value: 4},
            {key: 1015, value: 4},
            {key: 1035, value: 5},
            {key: 1190, value: 5},
            {key: 1210, value: 6}
        ];

        this.curveAni[1].list = [
            {key: 2040, value: 0},
            {key: 2060, value: 1},
            {key: 2290, value: 1},
            {key: 2310, value: 2},
            {key: 2490, value: 2},
            {key: 2510, value: 3},
            {key: 2790, value: 3},
            {key: 2810, value: 4},
            {key: 2990, value: 4},
            {key: 3010, value: 5},
            {key: 3190, value: 5},
            {key: 3210, value: 6},
            {key: 3290, value: 6},
            {key: 3310, value: 7}
        ];
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    update()   {
        if(!this.oe.isReady())  {   return; }

        let controller = this.oe.sharedInterface.getUIControllerAnimation();
        let animationMode = controller.getAnimationMode();
        let activeAnimation = controller.getActiveAnimation();
        let sceneTime = activeAnimation >= 0 ? controller.getAnimationSceneTimeIndex(activeAnimation) : 0;
        
        let visible = animationMode !== this.oe.Module.AnimationMode.disabled && [1, 2].includes(activeAnimation);

        if(!visible) {
            this.setState({activeAnim: -1});
            return;
        }

        let frameTime = 0.04;
        let frame = sceneTime / frameTime;

        let anim = activeAnimation - 1;

        let phases = [];
        for(let i = 0; i < this.numPhases[anim]; ++i) phases.push(0);
        let phase = this.curveAni[anim].interpolatedValue(frame);

        if(phase >= phases.length - 1) {
            phases[phases.length - 1] = 1;
        } else {
            let phaseA = Math.max(0, Math.floor(phase));
            let phaseB = Math.min(phases.length - 1, phaseA + 1);
            let frac = phase - phaseA;
            phases[phaseA] = 1 - frac;
            phases[phaseB] = frac;
        }

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.anims[anim].phasesAlpha = phases;
            newState.activeAnim = anim;
            return newState;
        });
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.animationModeChanged, this.onAnimationModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.animationTimeChanged, this.onAnimationTimeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activeAnimationChanged, this.onActiveAnimationChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.animationModeChanged, this.onAnimationModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.animationTimeChanged, this.onAnimationTimeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activeAnimationChanged, this.onActiveAnimationChanged);
    }

    updateLanguage()   {
        let si = this.oe.sharedInterface;

        this.setState((prevState, props) => {
            let newState = clone(prevState);

            newState.anims[0].phase0.strings = { label: si.getLocalizedStringEnc('cell_division_interphase') };
            newState.anims[0].phase1.strings = { label: si.getLocalizedStringEnc('cell_division_prophase') };
            newState.anims[0].phase2.strings = { label: si.getLocalizedStringEnc('cell_division_prometaphase') };
            newState.anims[0].phase3.strings = { label: si.getLocalizedStringEnc('cell_division_metaphase') };
            newState.anims[0].phase4.strings = { label: si.getLocalizedStringEnc('cell_division_anaphase') };
            newState.anims[0].phase5.strings = { label: si.getLocalizedStringEnc('cell_division_telophase') };
            newState.anims[0].phase5.strings = { label: si.getLocalizedStringEnc('cell_division_cytokinesis') };

            newState.anims[1].phase0.strings = { label: si.getLocalizedStringEnc('cell_meiose_1_prophase') };
            newState.anims[1].phase1.strings = { label: si.getLocalizedStringEnc('cell_meiose_1_metaphase') };
            newState.anims[1].phase2.strings = { label: si.getLocalizedStringEnc('cell_meiose_1_anaphase') };
            newState.anims[1].phase3.strings = { label: si.getLocalizedStringEnc('cell_meiose_1_telophase') };
            newState.anims[1].phase4.strings = { label: si.getLocalizedStringEnc('cell_meiose_2_prophase') };
            newState.anims[1].phase5.strings = { label: si.getLocalizedStringEnc('cell_meiose_2_metaphase') };
            newState.anims[1].phase6.strings = { label: si.getLocalizedStringEnc('cell_meiose_2_anaphase') };
            newState.anims[1].phase7.strings = { label: si.getLocalizedStringEnc('cell_meiose_2_telophase') };

            return newState;
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({activeAnim: -1});
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.update();
        });
    }

    onAnimationModeChanged() {
        this.update();
    }
    
    onAnimationTimeChanged() {
        this.update();
    }
    
    onActiveAnimationChanged() {
        this.update();
    }

    renderTitleView(alpha, text, key)  {
        return alpha === 0 ? null : (
            <div
                key={key}
                className='view title'
                style={{ opacity: alpha }}
            >
                {text}
            </div>
        );
    }

    render() {
        let anim = this.state.activeAnim >= 0 ? this.state.anims[this.state.activeAnim] : null;
        let titleViews = [];
        
        if(anim)    {
            for(let i = 0; i < this.numPhases[this.state.activeAnim]; ++i) {
                let view = this.renderTitleView(anim.phasesAlpha[i], anim['phase' + i.toString()].strings.label, i);
                if(view) titleViews.push(view);
            }
        }

        return (
            <div 
                className='overlay animal-cell screenshot-filter-show'
                style={{
                    visibility: anim ? 'visible' : 'hidden', 
                    opacity: anim ? '1.0' : '0.0'
                }}
            >
                {titleViews}
            </div>
        );
    }
}

OEAnimalCellOverlay.defaultProps = {
    moduleId: ''
};

OEAnimalCellOverlay.propTypes = {
    moduleId: PropTypes.string
};