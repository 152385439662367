export class SafeList {

    constructor() {
        this.array = [];
        this.iterationList = [];
    }

    add(element)    {
        this.array.push(element);
    }

    addUnique(element)    {
        if(this.array.includes(element)) return;
        this.array.push(element);
    }

    remove(element)    {
        var index = this.array.findIndex(e => e === element);
        if(index < 0) return;
        this.array.splice(index, 1);

        for(var i = 0; i < this.iterationList.length; ++i)  {
            var indexObj = this.iterationList[i];
            if(index <= indexObj.index) indexObj.index--;
        }
    }

    forEach(callback)   {
        var indexObj = {index: 0};
        this.iterationList.push(indexObj);

        while(indexObj.index < this.array.length) {
            callback(this.array[indexObj.index], indexObj.index);
            indexObj.index = indexObj.index + 1;
        }

        var index = this.iterationList.findIndex(e => e === indexObj);
        if(index < 0) return;
        this.iterationList.splice(index, 1);
    }
};