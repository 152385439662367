import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {retardUpdate} from '../../lib/update-retarder';
import {OEToolbox} from '../../lib/oe-toolbox';

export class OELogoView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            masked: false
        };

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(nextProps.config, this.props.config))    {
            this.updateMaskedState();
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateMaskedState()   {
        if(typeof(this.props.config) !== 'object' || !this.oe.isReady())  return false;
        let masked = false;

        if(this.props.config.mask)  {
            if(this.props.config.mask.uiController)  {
                if(typeof(this.props.config.mask.uiController) === 'number')    {
                    masked = this.oe.sharedInterface.getUIControllerVisible({value: this.props.config.mask.uiController});
                } else {
                    for(let i = 0; i < this.props.config.mask.length; ++i) {
                        masked = this.oe.sharedInterface.getUIControllerVisible({value: this.props.config.mask[i]});
                        if(masked)  break;
                    }
                }
            }
        }

        this.setState({masked: masked});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ masked: false });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateMaskedState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(typeof(this.props.config) !== 'object' || !this.props.config.mask || !this.props.config.mask.uiController)  return;

        if(typeof(this.props.config.mask.uiController) === 'number')    {
            if(userInfo.type.value != this.props.config.mask.uiController)  return;
        } else {
            if(!this.props.config.mask.includes(userInfo.type.value))  return;
        }

        this.updateMaskedState();
    }

    render()    {
        if(!this.props.config)  return null;
        let src = typeof(this.props.config) === 'string' ? this.props.config : (typeof(this.props.config.enabled) === 'undefined' || this.props.config.enabled ? this.props.config.src : undefined);
        if(!src)    return null;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <img className={'logo-view ' + (this.state.masked ? 'masked ' : '') + this.props.className} src={src}/>
            </React.Fragment>
        );
    }
}

OELogoView.defaultProps = {
    moduleId: '',
    className: ''
};

OELogoView.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    config: PropTypes.oneOfType([
        PropTypes.shape({
            enabled: PropTypes.bool,
            src: PropTypes.string,
            mask: PropTypes.shape({
                uiController: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number])
            })
        }),
        PropTypes.string
    ])
};