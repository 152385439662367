import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEPresentationSlideToolbar from './oe-presentation-slide-toolbar';
import OEColor from '../color-picker/oe-color';
import {OEColorHelper} from '../oe-color-helper';
import {OEToolbox} from '../../lib/oe-toolbox';
import {retardUpdate} from '../../lib/update-retarder';

export class BottomWidgetToolbarConfig  {

    constructor() {
        this.presentationSlideToolbarEnabled = false;
        this.presentationSlideToolbarPresentation = 0;
    }
}

export default class OEBottomWidgetToolbar extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.visible = false;

        this.state = {
            visible: false,
            backgroundColor: OEColorHelper.stdToolbarBackgroundColor(0.6),
            textColor: OEColorHelper.stdLabelTextColor(0.6),
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onBgColorChanged = this.onBgColorChanged.bind(this);

        this.onWindowResized = this.onWindowResized.bind(this);

        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.onShouldBeVisibleChanged = this.onShouldBeVisibleChanged.bind(this);

        this.setBottomBarRef = this.setBottomBarRef.bind(this);
        this.setWidgetRef = this.setWidgetRef.bind(this);
    }

    onWindowResized()   {
        this.updateEdgeOffsets();
    }

    updateEdgeOffsets(animated) {
        var edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        if(this.mounted && this.visible && this.$bottomBar) edgeOffsets.bottom = this.$bottomBar.outerHeight();

        if(!OEToolbox.jsonEqual(edgeOffsets, this.edgeOffsets)) {
            this.edgeOffsets = edgeOffsets;
            this.props.onEdgeOffsetsChanged(edgeOffsets, typeof(animated) === 'undefined' ? false : animated);
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }

        if(OEToolbox.jsonEqual(nextProps.config, this.props.config) === false)     {
            this.updateVisibility(nextProps);
        }
    }

    componentDidMount()    {
        this.mounted = true;

        this.updateEdgeOffsets();
        window.addEventListener('resize', this.onWindowResized);

        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;

        this.updateEdgeOffsets();
        window.removeEventListener('resize', this.onWindowResized);
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   this.onRelease();   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    shouldBeVisible(props) {

        var props_ = props || this.props;

        if(props_.config == null || !this.widgetRef)    {
            return false;
        }

        var widgetRefResponse = typeof(this.widgetRef.shouldBeVisible) === 'boolean' ? this.widgetRef.shouldBeVisible : true;

        return props_.config.presentationSlideToolbarEnabled && widgetRefResponse;
    }

    updateVisibility(props)  {

        var shouldBeVisible = this.shouldBeVisible(props);

        if(this.visible != shouldBeVisible) {
            this.visible = shouldBeVisible;
            this.setState({visible: this.visible});
            this.updateEdgeOffsets();
        }
    }

    setBottomBarRef(ref)    {
        this.$bottomBar = $(ref);
    }

    setWidgetRef(ref)   {
        if(this.widgetRef === ref)  {
            return;
        }

        this.widgetRef = ref;

        this.updateVisibility();
    }

    onShouldBeVisibleChanged()  {
        this.updateVisibility();
    }

    onBgColorChanged(message, userInfo)  {

        var brightness = userInfo == null ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;

        this.setState({
            backgroundColor: OEColorHelper.stdToolbarBackgroundColor(brightness),
            textColor: OEColorHelper.stdLabelTextColor(brightness)
        });
    }

    updateState_(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.updateVisibility();
            return;
        }
        
        this.updateVisibility();
        this.onBgColorChanged();
    }

    updateState(released)   {
        retardUpdate(this, () => {
            this.updateState_(released)
        });
    }

    render() {
        var config = this.props.config || new BottomWidgetToolbarConfig();

        return (
            <div 
                className="bottom-bar bottom-widget-toolbar"
                style={{
                    backgroundColor: OEColor.toDOMStr(this.state.backgroundColor),
                    color: OEColor.toDOMStr(this.state.textColor),
                    left: this.props.insets.left.toString() + 'px',
                    transform: 'translateY(' + (this.state.visible ? '0px' : '100%') + ')',
                    transition: 'transform 0.333s ease' + OEToolbox.sepFront(this.props.animate ? 'left 0.333s ease' : '')
                }}
                ref={this.setBottomBarRef}
            >

                <OEPresentationSlideToolbar
                    moduleId={this.props.moduleId}
                    defaultPresentation={config.presentationSlideToolbarPresentation}
                    ref={this.setWidgetRef}
                    onShouldBeVisibleChanged={this.onShouldBeVisibleChanged}
                    isVisible={this.state.visible}
                />
            </div>
        );
    }
};

OEBottomWidgetToolbar.defaultProps = {
    moduleId: '',
    insets: {left: 0},
    animate: false,
    config: null,
    onEdgeOffsetsChanged: () => {}
};

OEBottomWidgetToolbar.propTypes = {
    moduleId: PropTypes.string,
    insets: PropTypes.object,
    animate: PropTypes.bool,
    onEdgeOffsetsChanged: PropTypes.func
};