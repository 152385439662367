import React from 'react';
import PropTypes from 'prop-types';

class OENumberInput_ extends React.PureComponent {

    constructor(props) {
        super(props);

        this.value = this.props.value;
        this.strValue = this.getStrFromValue(this.value);

        this.state = {
            strValue: this.strValue
        };

        this.onChange = this.onChange.bind(this);

        this.onBlur = this.onBlur.bind(this);
    }

    getStrFromValue(value)   {
        return value !== undefined ? value.toString() : "";
    }

    getValueFromString(str) {
        return str == "" ? undefined : Number(str.replaceAll(',', '.'));
    }

    setValue(value) {
        if(value === this.value)    return;
        this.value = value;
        let valueFromStr = this.getValueFromString(this.strValue);
        if(this.value != valueFromStr)  {
            this.strValue = this.getStrFromValue(this.value);
            this.setState({strValue: this.strValue});
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setValue(nextProps.value);
    }

    render() {
        return (
            <input
                type="text"
                inputMode="numeric"
                disabled={this.props.disabled}
                value={this.state.strValue}
                onChange={this.onChange}
                onFocus={this.props.onFocus}
                onBlur={this.onBlur}
                ref={this.props.elementRef}
            />
        );
    }

    onChange(evt)   {
        let strNewValue = evt.target.value;
        let newValue;

        if(strNewValue === '' || strNewValue === '-') {
            newValue = undefined;
            this.strValue = strNewValue;
            this.setState({strValue: this.strValue});
        } else {
            if(this.props.step >= 1)   strNewValue = strNewValue.replaceAll(',', '').replaceAll('.', '');

            newValue = Number(strNewValue.replaceAll(',', '.'));
            if(Number.isNaN(newValue))  return;
            this.strValue = strNewValue;
            this.setState({strValue: this.strValue});
        }

        if(newValue != this.value)  {
            this.value = newValue;
            if(this.props.onChange) this.props.onChange(this.value);
        }
    }

    onBlur(evt)    {
        if(this.strValue === '-')   {
            this.strValue = '';
            this.setState({strValue: this.strValue});
            evt.target.value = '';
        }
        if(this.props.onBlur)   this.props.onBlur(evt);
    }
};

OENumberInput_.defaultProps = {
    className: '',
    disabled: false
};

OENumberInput_.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    step: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    elementRef: PropTypes.func
};

export default class OENumberInput extends React.PureComponent {

    constructor(props) {
        super(props);

        this.value = this.constrain(this.props.value, true);
        this.lastNumberValue = this.value !== undefined ? this.value : undefined;
        this.state = {
            value: this.value
        };

        this.onInputElementRef = this.onInputElementRef.bind(this);
        this.onInputComponentRef = this.onInputComponentRef.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    focus() {
        if(this.inputElementRef)   this.inputElementRef.focus();
    }

    constrain(value, forceAllowUndefined, props)    {
        props = props || this.props;
        if(forceAllowUndefined) value = typeof(value) === 'number' ? value : (props.allowUndefined ? undefined : 0);

        if(typeof(value) !== 'number')  return value;

        if(props.max !== undefined) value = Math.min(props.max, value);
        if(props.min !== undefined) value = Math.max(props.min, value);

        if(props.step !== undefined && props.step > 0) {
            let n = Math.ceil(Math.log10(1 / props.step));
            let value_ = parseFloat(value.toFixed(n));
            value = Number.isNaN(value_) ? value : value_;
        }
        return value;
    }

    parse(value)    {
        return typeof(value) === 'number' ? value : (typeof(value) === 'string' && value !== '' ? Number.parseFloat(value) : undefined);
    }

    setValue(value, forceAllowUndefined, props) {
        if(value === this.value)    return;
        let newValue = this.constrain(value, typeof(forceAllowUndefined) === 'boolean' ? forceAllowUndefined : true, props);
        if(newValue != this.value) {
            this.value = newValue;
            if(this.value !== undefined) this.lastNumberValue = this.value;
            this.setState({value: this.value});
        }
    }

    componentWillReceiveProps(nextProps) {
        let needsValueUpdate = this.props.min != nextProps.min || this.props.max != nextProps.max || this.props.step != nextProps.step || this.props.allowUndefined != nextProps.allowUndefined;

        if(nextProps.value != this.value) {
            let oldValue = this.value;
            this.setValue(nextProps.value, true, nextProps);
            if(oldValue != this.value)  needsValueUpdate = false;
        }

        if(needsValueUpdate)    {
            let oldValue = this.value;
            this.setValue(this.value, this.props.allowUndefined != nextProps.allowUndefined, nextProps);
            if(oldValue != this.value && (nextProps.allowUndefined || this.value !== undefined) && nextProps.onChange) nextProps.onChange(this, this.value);
        }
    }

    onInputElementRef(ref) {
        this.inputElementRef = ref;
    }

    onInputComponentRef(ref) {
        this.inputComponentRef = ref;
    }

    render() {
        return (
            <div className={'number-input ' + (this.props.disabled ? 'disabled ' : '') + this.props.className}>
                {!this.props.label ? null : <span className="label">{this.props.label}</span>}
                {!this.props.allowUndefined ?
                    <input
                        type="number"
                        disabled={this.props.disabled}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step}
                        value={this.state.value === undefined ? '' : this.state.value}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        ref={this.onInputElementRef}
                    /> :
                    <OENumberInput_
                        disabled={this.props.disabled}
                        step={this.props.step}
                        value={this.state.value}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        elementRef={this.onInputElementRef}
                    />
                }
                {!this.props.unit ? null : <span className="unit-label">{this.props.unit}</span>}
            </div>
        );
    }

    onChange(evtValue)   {
        let newValue = typeof(evtValue) !== 'object' ? (typeof(evtValue) === 'number' ? evtValue : undefined) : this.parse(evtValue.target.value);
        if(newValue !== undefined && Number.isNaN(newValue))   return;
        newValue = this.constrain(newValue);
        if(this.inputComponentRef)  this.inputComponentRef.setValue(newValue);
        if(newValue === this.value)  return;

        this.value = newValue;
        if(this.value !== undefined) this.lastNumberValue = this.value;
        this.setState({value: this.value});

        if((this.props.allowUndefined || newValue !== undefined) && this.props.onChange)   this.props.onChange(this, newValue);
    }

    onFocus(evt)   {
        if(this.props.onFocus)  this.props.onFocus(this);
    }

    onBlur(evt)   {
        if(evt.target.value === '' && !this.props.allowUndefined && this.lastNumberValue !== undefined) {
            this.onChange(this.lastNumberValue);
        }

        if(this.props.onBlur)  this.props.onBlur(this);
    }
}

OENumberInput.defaultProps = {
    className: '',
    disabled: false,
    allowUndefined: false
};

OENumberInput.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    allowUndefined: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string,
    unit: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};