import React from 'react';
import PropTypes from 'prop-types';
import * as screenfull from 'screenfull';

import {connectAppEnv} from '../app-env';
import {OERightTopBarEntryTypes} from '../../lib/oe-types';
import {OETarget} from '../../react-oe/oe-target';
import {OEVersion} from '../../react-oe/oe-version';
import {AppMode} from '../app-types';
import OEIcon from '../elements/oe-icon';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OETargetMenu from '../elements/target-menu/oe-target-menu';
import {OEDefaultConfigFactory} from '../oe-default-configs';

export class OERightTopBarEntryFactory extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
    }

    render()    {
        if(!this.props.config.enabled)  return null;

        let key = 0;
        let elements = this.props.config.entries.map(entry => {
            if(entry.type === OERightTopBarEntryTypes.targetMenu) {
                return <OETargetMenu key={key++} moduleId={this.props.moduleId} hideWhenMediaCenterOpen={true} subOrientation={OETargetMenu.subOrientation.left}/>;
            }
        });

        if(this.props.showFullscreenBtn)    {
            elements.push( 
                <div key={key++} className="main-menu-control-button right-top-bar-button view-port-label-text-color-border">
                    <a onClick={this.onFullscreenBtnPressed}>
                        <OEIcon code={this.props.isFullscreen ? OEIconCodes.compress : OEIconCodes.expand} />
                    </a>
                </div>
            );
        }

        if(this.props.showCloseBtn)    {
            elements.push( 
                <div key={key++} className="main-menu-control-button right-top-bar-button view-port-label-text-color-border">
                    <a onClick={this.onCloseBtnPressed}>
                        <OEIcon code={OEIconCodes.close} />
                    </a>
                </div>
            );
        }

        return (
            <React.Fragment>
                {elements}
            </React.Fragment>
        );
    }

    onFullscreenBtnPressed()    {
        if(typeof(this.props.onFullscreenBtnPressed) === 'function')    {
            this.props.onFullscreenBtnPressed();
        } else if(this.props.app) {
            this.props.app.setFullscreen(!this.props.isFullscreen);
        }
    }

    onCloseBtnPressed() {
        if(typeof(this.props.onCloseBtnPressed) === 'function')    {
            this.props.onCloseBtnPressed();
        } else if(this.props.app) {
            this.props.app.changeMode(AppMode.start);
        }
    }
}

export const OERightTopBarEntryFactoryConnected = connectAppEnv((env) => { return {
    showFullscreenBtn: screenfull.enabled,
    isFullscreen: env.state.isFullscreen,
    showCloseBtn: env.config.isLicensingStart,
    app: env.component.app
}})(OERightTopBarEntryFactory);

export class OERightTopBar extends React.PureComponent {
    render() {
        if(!this.props.config.enabled)  return null;
        return (
            <div className="right-top-bar" style={this.props.style}>
                <OERightTopBarEntryFactoryConnected {...this.props}/>
            </div>
        );
    }
}

OERightTopBar.defaultProps = {
    moduleId: '',
    config: OEDefaultConfigFactory.rightTopBar()
};

OERightTopBar.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        entries: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.oneOf([OERightTopBarEntryTypes.targetMenu]),
            src: PropTypes.string
        }))
    })
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: ui.rightTopBar,
        version: env.config.version,
        target: env.config.target
    }
})(OERightTopBar);