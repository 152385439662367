import React from 'react';
import PropTypes from 'prop-types';

import {OEToolbox} from './oe-toolbox';

export default class OEResizeObserver extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
        this.onContentWindowResize = this.onContentWindowResize.bind(this);
        this.onRef = this.onRef.bind(this);
    }

    componentDidMount()    {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onResize);
        let size = {w: 0, h: 0};
        if(OEToolbox.shallowEqual(this.size, size))   return;
        this.size = size;
        if(this.props.onResize) this.props.onResize(this, this.size);
    }

    getElementRef()    {
        this.ref;
    }

    getSize()   {
        return this.size;
    }

    getWidth()   {
        return this.size.w;
    }

    getHeight()   {
        return this.size.h;
    }

    getClientPos()  {
        if(!this.ref) return;
        const rect = this.ref.getBoundingClientRect();
        return {x: rect.left, y: rect.top};
    }

    updateSize()    {
        if(!this.ref || !this.ref.contentWindow)   return;

        const rect = this.ref.getBoundingClientRect();
        let size = {w: rect.right - rect.left, h: rect.bottom - rect.top};

        if(size.w === 0 && size.h === 0)     size = {w: this.ref.contentWindow.innerWidth, h: this.ref.contentWindow.innerHeight};

        if(OEToolbox.shallowEqual(this.size, size))   return;
        this.size = size;
        if(this.props.onResize) this.props.onResize(this, this.size);
    }

    onResize() {
        this.updateSize();
    }

    onContentWindowResize() {
        this.updateSize();
    }

    onRef(ref) {
        if(this.ref === ref) return;

        if(this.ref && this.ref.contentWindow)    {
            this.ref.contentWindow.removeEventListener('resize', this.onContentWindowResize);
        }

        this.ref = ref;

        if(this.ref && this.ref.contentWindow)    {
            this.ref.contentWindow.addEventListener('resize', this.onContentWindowResize);
            this.updateSize();
        }
    }

    render() {
        // use opacity: 0 instead of visibility: hidden because some browsers, e.g., firefox, don't update the window size otherwise
        const style = { width: '100%', height: '100%', position: 'absolute', opacity: 0, border: 'none', padding: '0', margin: '0', pointerEvents: 'none' };
        return (
            <iframe
                style={style}
                ref={this.onRef}
                src="about:blank"
            />
        );
    }
}

OEResizeObserver.propTypes = {
    onResize: PropTypes.func
};