import clone from 'clone';

import {AppMode} from './app-types';
import {OEFeatureAccessTypes, OEViewType, UIControllerType, OEMainMenuStdBtnType, OEContextMenuContentType, OEStartViewTypes, OEHelpViewEntryType, OECutControllerStyle, UILevel, OESettingsControllerDefs, OEMainMenuControlBarEntryTypes} from '../lib/oe-types';
import {OETargetMenuDefs} from './elements/target-menu/oe-target-menu-defs';
import {OEMediaCenterItemTypes, OEMediaCenterMode} from './controller/media-center/oe-media-center-model';
import {OEMainMenuBtnAdapterView, OEMainMenuBtnAdapterPostProcess, OEMainMenuBtnAdapterAnimation} from './oe-main-menu-btn-adapter';
import OEPhilipsIcarusMainMenuBtnAdapterReset from './custom/philipsIcarus/oe-philips-icarus-main-menu-btn-adapter-reset';
import OEPhilipsIcarusTextViewAdapter from './custom/philipsIcarus/oe-philips-icarus-text-view-adapter';
import {OEDefaultConfigFactory} from './oe-default-configs';
import {OETarget, OEResourceTarget} from '../react-oe/oe-target';
import {OEVersion} from '../react-oe/oe-version';
import {OEToolbox} from '../lib/oe-toolbox';
import {OELicensingAppConfigFactory} from './licensing-start/oe-licensing-app';
import {OEIconCodes} from '../lib/oe-icon-codes';
import {OEImplantcastTargets} from './custom/implantcast/oe-implantcast-types';

export default class AppConfig {

    constructor(params) {
        let {target, initial, ...otherParams} = params || {};

        this.fontFamily = 'Roboto,sans-serif';
        this.version = config.version;
        this.target = OETarget.humanSkull;
        this.isLicensingStart = false;
        this.startAppMode = AppMode.play;
        this.logo = null;

        const flags = this.getFlags();

        this.module = {
            version: config.version,
            flags: flags.length ? flags : null,
            uiLayerConfig: OEDefaultConfigFactory.uiLayer(),
            labelsEnabled: OEToolbox.getUrlParameterBool('labelsEnabled', false),
            labelsLatin: OEToolbox.getUrlParameterBool('labelsLatin', false),
            featureAccess: {accessType: OEFeatureAccessTypes.isOne(config.accessType) ? config.accessType : OEFeatureAccessTypes.none},
            link: initial ? OEToolbox.getUrlParameter('link') : null,
            uiLink: initial ? OEToolbox.getUrlParameter('uiLink') : null,
            linkRef: initial ? OEToolbox.getUrlParameter('linkRef') : null,
            uiLinkRef: initial ? OEToolbox.getUrlParameter('uiLinkRef') : null,
            uiStateMC: !initial ? null : {
                visible: OEToolbox.getUrlParameterBool('mcVisible'),
                category: OEToolbox.getUrlParameterInt('category'),
                item: OEToolbox.getUrlParameterInt('item'),
                resourceId: OEToolbox.getUrlParameter('resourceId'),
                subItem: OEToolbox.getUrlParameterInt('subItem'),
                progress: OEToolbox.getUrlParameterFloat('progress')
            }
        };

        let hasLink = typeof(this.module.link) === 'string' || typeof(this.module.uiLink) === 'string' || typeof(this.module.linkRef) === 'string' || typeof(this.module.uiLinkRef) === 'string';

        this.licensingAppConfig = OELicensingAppConfigFactory.defaultConfig();

        //
        let isAmboss = config.version === OEVersion.amboss;
        let isAnatomy = config.version === OEVersion.anatomy;
        let isIonto = config.version === OEVersion.ionto;
        let isEffigos = config.version === OEVersion.effigos;
        let isVisionResearch = config.version === OEVersion.visionResearch;
        let isLicensingStart = config.version === OEVersion.licensingStart || isEffigos;
        let isToxNetz = config.version === OEVersion.toxNetz;
        let isZeissOpti = config.version === OEVersion.zeissOpti;
        let isEyeOCT = config.version === OEVersion.eyeOCT;
        let isKind = config.version === OEVersion.kind;
        let isOrtho = config.version === OEVersion.ortho;
        let isCambridgeCell = config.version === OEVersion.cambridgeCell;
        let isPhilipsIcarus = config.version === OEVersion.philipsIcarus;
        let isBayer = config.version === OEVersion.bayer;
        let isImplantcast = config.version === OEVersion.implantcast;
        let isSanten = config.version === OEVersion.santen;
        let isLicensing = config.accessType == OEFeatureAccessTypes.licensing;
        
        let isEnglish = flags.includes('english');
        let isImport = flags.includes('import');

        let noStartView = OEToolbox.getUrlParameterBool('noStartView', false);
        let showMainMenu = OEToolbox.getUrlParameterBool('showMainMenu');
        let isShowMainMenuSet = typeof(showMainMenu) === 'boolean';

        //
        this.isLicensingStart = isLicensingStart;

        //
        let uiLayerConfig = this.module.uiLayerConfig;
        let targetMenuConfig = this.module.uiLayerConfig.targetMenuConfig;
        const MMBtnT = OEMainMenuStdBtnType;

        uiLayerConfig.startViewConfig.showAtStartUp = !noStartView;
        if(isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = showMainMenu;

        uiLayerConfig.widgetConfig.componentController.searchBar = isImport;

        // some general configs for versions
        if(isIonto) {

            this.target = OETarget.skinMacroIONTO;
            this.resourceTarget = OEResourceTarget.skinIONTO;

            //uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => btn.type !== MMBtnT.presentation);

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.ionto;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            if(isLicensing) {
                let index = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                uiLayerConfig.mainMenuButtons.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            }
            
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            uiLayerConfig.widgetConfig.cutController.showHelp = false;

            uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManual();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF();

        } else if(isAmboss) {

            this.target = OETarget.humanTrunk;
            this.resourceTarget = isImport ? OEResourceTarget.ambossImport : OEResourceTarget.amboss;

            const isFree = OEToolbox.getUrlParameter('status') === 'free';
            const excludedButtons = isImport ? [MMBtnT.explode] : [MMBtnT.presentation, MMBtnT.note, MMBtnT.arrow, MMBtnT.color, MMBtnT.explode];
           
            this.target = isFree ? OETarget.humanKnee : OETarget.humanTrunk;
            targetMenuConfig.entries = isFree ? OETargetMenuDefs.amboss.free : (isImport ? OETargetMenuDefs.amboss.import : OETargetMenuDefs.amboss);

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.amboss;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => !excludedButtons.includes(btn.type));
            //uiLayerConfig.mainMenuButtons.top.find((btn) => btn.type === MMBtnT.info).icon = OEIconCodes.infoQuestionMark;
            uiLayerConfig.mainMenuButtons.bottom = uiLayerConfig.mainMenuButtons.bottom.filter((btn) => !excludedButtons.includes(btn.type));

            let infoBtn = uiLayerConfig.mainMenuButtons.top.find((btn) => btn.type === MMBtnT.info);
            infoBtn.adapter = new OEMainMenuBtnAdapterView(OEViewType.start);

            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            uiLayerConfig.contextMenu.content = {type: OEContextMenuContentType.infoBox, showReferences: false};

            uiLayerConfig.widgetConfig.labelController.enabled = isImport;
            uiLayerConfig.widgetConfig.cutController.style = isImport ? OECutControllerStyle.std : OECutControllerStyle.minimal;
            uiLayerConfig.widgetConfig.componentController.colorBtnEnabled = isImport;
            uiLayerConfig.widgetConfig.componentController.catSwitchBtnEnabled = isImport;
            uiLayerConfig.widgetConfig.componentController.catActivationBtnEnabled = isImport;

            uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);

            this.fontFamily = 'Lato,sans-serif';
            
         } else if(isAnatomy) {

            this.target = OETarget.humanSkull;
            this.resourceTarget = OEResourceTarget.anatomy;

            targetMenuConfig.entries = OETargetMenuDefs.anatomy;

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.anatomy;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            if(isLicensing) {
                let index = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                uiLayerConfig.mainMenuButtons.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            }

            uiLayerConfig.contextMenu.content = {type: OEContextMenuContentType.infoBox, showReferences: false};

            uiLayerConfig.widgetConfig.cutController.showHelp = false;

            uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManual();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF(true);

         } else if(isEffigos) {

            targetMenuConfig.entries = OETargetMenuDefs.app;
            
        } else if(isToxNetz) {

            this.target = OETarget.toxNetz;
            targetMenuConfig.entries = OETargetMenuDefs.toxNetz;

        } else if(isVisionResearch) {
        
            this.target = OETarget.sensorySystems;
            targetMenuConfig.enabled = false;
        
        } else if(isZeissOpti)  {

            this.target = OETarget.zeissOpti2;
            this.resourceTarget = OEResourceTarget.zeiss;
            uiLayerConfig.startViewConfig.type = OEStartViewTypes.zeissOpti;
            uiLayerConfig.startViewConfig.targetViewShowedAtStartFlag = true;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            uiLayerConfig.rightTopBar.enabled = false;
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;
            uiLayerConfig.mainMenuControlBar.enabled = false;

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManualVideoTutorial();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF();

        } else if(isEyeOCT) {
            
            this.target = OETarget.humanEyeOCT;
            this.resourceTarget = OEResourceTarget.humanEyeRes;

            targetMenuConfig.entries = OETargetMenuDefs.humanEyeOCTVersion;

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.eyeOCT;
            uiLayerConfig.startViewConfig.targetViewShowedAtStartFlag = true;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            if(isLicensing) {
                let index = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                uiLayerConfig.mainMenuButtons.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            }

            uiLayerConfig.rightTopBar.enabled = false;
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;
            uiLayerConfig.mainMenuControlBar.enabled = false;

            uiLayerConfig.widgetConfig.cutController.showHelp = false;
            
            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManualVideoTutorial();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF();
            
        }else if(isKind) {

            this.target = OETarget.humanEarMacro;
            targetMenuConfig.entries = OETargetMenuDefs.kind;

            uiLayerConfig.mainMenuControlBar.entries.push({type: OEMainMenuControlBarEntryTypes.logo, src: 'images/logo_KIND_128.png'});

        } else if(isOrtho) {
            
            this.target = OETarget.humanSkull;
            targetMenuConfig.entries = OETargetMenuDefs.ortho;
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

        } else if(isCambridgeCell)  {

            this.target = OETarget.animalCell; 
            this.resourceTarget = OEResourceTarget.cell;

            uiLayerConfig.startViewConfig.type = OEStartViewTypes.cambridgeCell;

            let btnsToRemove = [MMBtnT.cut];
            uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => btnsToRemove.indexOf(btn.type) < 0);

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top[infoBtnIndex].adapter = new OEMainMenuBtnAdapterView(OEViewType.start);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex + 1, 0, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});
        
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;

            uiLayerConfig.contextMenu.content = {type: OEContextMenuContentType.infoBox, showReferences: false};

            uiLayerConfig.widgetConfig.labelController.enabled = false;

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManualVideoTutorial();
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewCambridgeCell();

        } else if(isPhilipsIcarus)  {

            this.target = OETarget.philipsIcarus;
            this.resourceTarget = OEResourceTarget.philipsIcarusAnatomy;
            uiLayerConfig.rightTopBar.enabled = false;
            
            if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;
            uiLayerConfig.mainMenuControlBar.enabled = false;

        } else if(isBayer) {

            this.target = OETarget.humanEyeMacro;
            this.resourceTarget = OEResourceTarget.humanEyeRes;

            targetMenuConfig.entries = OETargetMenuDefs.humanEyeNew;

        } else if(isImplantcast)    {

            this.target = OETarget.implantcastEcoFitCup;
            this.resourceTarget = OEResourceTarget.implantcast;
            
            uiLayerConfig.startViewConfig.type = OEStartViewTypes.implantcast;
            uiLayerConfig.startViewConfig.targetViewShowedAtStartFlag = true;
            uiLayerConfig.startViewConfig.showAtStartUp = !noStartView && !hasLink;

            uiLayerConfig.rightTopBar.enabled = false;

            const excludedButtons = [MMBtnT.explode];
            uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => !excludedButtons.includes(btn.type));

            let homeBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.home);
            uiLayerConfig.mainMenuButtons.top.splice(homeBtnIndex + 1, 0, {type: 'demoAnimLoop', enabled: true, icon: OEIconCodes.implantcast.demoAnimLoop, tooltip: 'animLoop_label', adapter: new OEMainMenuBtnAdapterAnimation('animLoop')})

            let infoBtnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.info);
            uiLayerConfig.mainMenuButtons.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            if(isLicensing) {
                let index = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                uiLayerConfig.mainMenuButtons.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            }

            uiLayerConfig.mainMenuControlBar.enabled = false;

            uiLayerConfig.widgetConfig.cutController.showHelp = false;

            uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);
            uiLayerConfig.mediaCenterConfig.hideSliderForItems = [];
            uiLayerConfig.mediaCenterConfig.doNotPlayPresentationWithoutAudio = false;

            uiLayerConfig.helpViewConfig = OEDefaultConfigFactory.helpViewManual();
            uiLayerConfig.helpViewConfig.entries = uiLayerConfig.helpViewConfig.entries.filter(e => e.type !== OEHelpViewEntryType.tutorReset);
            uiLayerConfig.manualViewConfig = OEDefaultConfigFactory.manualViewPDF(true);

            this.logo = {src: 'images/implantcast_logo.png', mask: {uiController: UIControllerType.media_center}};
        } else if(isSanten) {

            this.target = OETarget.humanEye2;
            targetMenuConfig.entries = OETargetMenuDefs.humanEyeSanten;
        }

        // set final target
        let info = {targetChoiceTag: 0};
        this.target = this.selectTarget(target, info) || this.target;

        if(config.target == OETarget.humanEye) {
            this.resourceTarget = OEResourceTarget.humanEyeRes;
        }

        if(!this.resourceTarget)    this.resourceTarget = OEResourceTarget.isPureResourceTarget(config.target) ? config.target : this.target;

        if(this.resourceTarget == OEResourceTarget.sensorySystemsEar || this.resourceTarget == OEResourceTarget.sensorySystemsEye)  {
            this.resourceTarget = OEResourceTarget.sensorySystems;
        }

        this.target = OEResourceTarget.defaultTargetForResourceTarget(this.target);

        // set app mode at start up
        this.startAppMode = isLicensingStart && info.targetChoiceTag == 0 ? AppMode.start : AppMode.play;

        // decide if we want to show all available targets in target menu or only micro/macro switch when appropriate
        let hasConfigTarget = typeof(config.target) === 'string' && config.target !== '';
        let hasFullTargetMenu = !(hasConfigTarget || isIonto || isLicensingStart || isZeissOpti || isCambridgeCell);

        if(!hasFullTargetMenu)  {

            // choose target menu config with respect to current target
            targetMenuConfig.enabled = false;
            switch (this.target) {
                case OETarget.humanEarMacro:
                case OETarget.humanEarMacroAmboss:
                case OETarget.humanEarMicro:
                case OETarget.sensorySystemsEar:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.humanEar;
                    break;
                case OETarget.humanEyeMacro:
                case OETarget.humanEyeMicro:
                case OETarget.humanEyeCornea:
                case OETarget.sensorySystemsEye:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.humanEye;
                    break;
                case OETarget.skinMacroIONTO:
                case OETarget.skinMicroIONTO:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.skin;
                    break;
                case OETarget.hoofWeb:
                case OETarget.hoofVessels:
                case OETarget.hoofDynamics:
                case OETarget.hoofMicro:
                case OETarget.hoofCarpal:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.hoof;
                    break;
                case OETarget.animalCell:
                case OETarget.plantCell:
                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.cell;
                    break;
            }

            if(config.target == OETarget.humanEye || config.target == OEResourceTarget.humanEyeRes) {
                targetMenuConfig.enabled = true;
                targetMenuConfig.entries = OETargetMenuDefs.humanEyeNew;
            }
        }

        //
        if(this.target == OETarget.humanEye)    {
            this.target = OETarget.humanEye2;
        }

        // choose logo for start and info view
        let logo = this.logoFor(this.target, config.version);
        uiLayerConfig.startViewConfig.logo = logo;
        uiLayerConfig.infoViewConfig.logo = logo;

        // further ui configs with respect to choosen target
        //
        let isTargetZeissOpti = this.target === OETarget.zeissOpti || this.target === OETarget.zeissOpti2;
        let isTargetZeiss = isTargetZeissOpti || this.target === OETarget.zeissUV;
        let isZeiss = isTargetZeiss || isZeissOpti;
        if(isZeiss)   {

            let mmBtns = OEDefaultConfigFactory.mainMenuButtons();
            let infoBtnIndex = mmBtns.top.findIndex((btn) => btn.type === MMBtnT.info);
            mmBtns.top.splice(infoBtnIndex, 1, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

            let topBtnsRestricted = OEDefaultConfigFactory.mainMenuButtonArray([MMBtnT.home, MMBtnT.label, MMBtnT.color, MMBtnT.mediaCenter, MMBtnT.screenshot, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)}, MMBtnT.settings]);
            
            if(isLicensing) {
                let index = mmBtns.top.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                mmBtns.top.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});

                index = topBtnsRestricted.findIndex((btn) => btn.type === MMBtnT.settings) + 1;
                topBtnsRestricted.splice(index, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});
            }

            if(isZeissOpti) {
                if(!isShowMainMenuSet)   uiLayerConfig.mainMenuConfig.initiallyVisible = true;
                uiLayerConfig.widgetConfig.cutController.showHelp = false;

                mmBtns.top.find(btn => btn.type === MMBtnT.color).tooltip = 'main_menu_tooltip_stylized';
                topBtnsRestricted.find(btn => btn.type === MMBtnT.color).tooltip = 'main_menu_tooltip_stylized';

                //mmBtns.top.splice(colorBtnIndex, 1, {type: 'postProcess', enabled: true, icon: OEIconCodes.zeissOpti.pencil, tooltip: 'main_menu_tooltip_stylized', adapter: new OEMainMenuBtnAdapterPostProcess()})
                //topBtnsRestricted.splice(colorBtnIndex, 1, {type: 'postProcess', enabled: true, icon: OEIconCodes.zeissOpti.pencil, tooltip: 'main_menu_tooltip_stylized', adapter: new OEMainMenuBtnAdapterPostProcess()})

                if(isTargetZeiss)    {

                    uiLayerConfig.mainMenuButtons.top = topBtnsRestricted.filter(btn => btn.type !== MMBtnT.mediaCenter && !(isTargetZeissOpti && btn.type === MMBtnT.color));
                    uiLayerConfig.mainMenuButtons.bottom = [];
                    uiLayerConfig.widgetConfig.labelController.enabled = false;
                    uiLayerConfig.widgetConfig.componentController.colorPurityControl = false;
                    
                    let bgGroup = uiLayerConfig.widgetConfig.settingsController.controls.find(group => group.type === OESettingsControllerDefs.controlGroupTypes.background);
                    bgGroup.controls = bgGroup.controls.filter(control => control.type !== OESettingsControllerDefs.controlTypes.vpGizmo);

                    this.logo = 'images/zeiss_logo.png';

                } else {
                    // add ui level entry to settings controller for non zeiss targets
                    uiLayerConfig.widgetConfig.settingsController.controls.push(clone(OESettingsControllerDefs.uiControlAdvanced));

                    targetMenuConfig.enabled = true;
                    targetMenuConfig.entries = OETargetMenuDefs.humanEye;
                    targetMenuConfig[UILevel.advanced] = clone(targetMenuConfig);
                    //if(this.target === OETarget.humanEyeMacro)   targetMenuConfig.enabled = false;

                    // main menu config for ui level - advanced
                    uiLayerConfig.mainMenuButtons[UILevel.advanced] = mmBtns;

                    // main menu config for ui level - std 
                    uiLayerConfig.mainMenuButtons.top = topBtnsRestricted;
                    uiLayerConfig.mainMenuButtons.bottom = [];

                    // widget config for ui level
                    uiLayerConfig.widgetConfig.labelController.enabled = false;
                    uiLayerConfig.widgetConfig.labelController[UILevel.advanced] = OEDefaultConfigFactory.labelController();

                    uiLayerConfig.widgetConfig.componentController.enabled = false;
                    uiLayerConfig.widgetConfig.componentController.colorPurityControl = false;
                    uiLayerConfig.widgetConfig.componentController[UILevel.advanced] = OEDefaultConfigFactory.componentController({searchBar: isImport, colorPurityControl: false});
                }

                //uiLayerConfig.widgetConfig.settingsController.controls.push(clone(OESettingsControllerDefs.defaultTutorControl));

                let graphicsGroup = uiLayerConfig.widgetConfig.settingsController.controls.find(group => group.type === OESettingsControllerDefs.controlGroupTypes.graphics);
                graphicsGroup.controls = graphicsGroup.controls.filter(control => control.type !== OESettingsControllerDefs.controlTypes.rendererPreset);

            } else {

                if(isTargetZeissOpti)   {

                    uiLayerConfig.mainMenuButtons.top = topBtnsRestricted;
                    uiLayerConfig.mainMenuButtons.bottom = [];

                } else { // target is zeiss uv here
                    
                    // main menu config for legacy uv version 
                    uiLayerConfig.mainMenuButtons.top = OEDefaultConfigFactory.mainMenuButtonArray([MMBtnT.home, MMBtnT.tree, MMBtnT.label, MMBtnT.mediaCenter, MMBtnT.cut, MMBtnT.presentation, MMBtnT.screenshot, MMBtnT.info, MMBtnT.settings]);
                }
            }

            //
            uiLayerConfig.widgetConfig.cutController.enabled = !(isZeissOpti && isTargetZeiss);

            //
            uiLayerConfig.infoViewConfig.logo = null;

            if(isZeissOpti || isTargetZeissOpti)  {
                uiLayerConfig.infoViewConfig.isMaximized = true;
                uiLayerConfig.infoViewConfig.header = false;
                uiLayerConfig.infoViewConfig.tutorials = false;
            } else {
                uiLayerConfig.infoViewConfig.mediaContent = ['info_view_media_video_1_youtube', 'info_view_media_video_2_youtube'];
            }

            // media center config changes
            if((isZeissOpti && isTargetZeiss) || (!isZeissOpti && isTargetZeissOpti))   {
                uiLayerConfig.mediaCenterConfig.mode = OEMediaCenterMode.bottomBar;
                uiLayerConfig.mediaCenterConfig.hideBottomBarControlsForItems = [OEMediaCenterItemTypes.none, OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];
                uiLayerConfig.mediaCenterConfig.hideSliderForItems = [OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];
            }
        }

        //
        if(isEyeOCT)    {
            let isAnantomyTarget = !isTargetZeissOpti && this.target != OETarget.humanEyeOCT;
            targetMenuConfig.enabled = isAnantomyTarget;

            if(isTargetZeissOpti)   {
                uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter(btn => btn.type !== MMBtnT.mediaCenter && btn.type !== MMBtnT.color);
                this.logo = 'images/zeiss_logo.png';
            } if(this.target === OETarget.humanEyeOCT)    {
                uiLayerConfig.mediaCenterConfig = OEDefaultConfigFactory.mediaCenter(true);
                uiLayerConfig.mediaCenterConfig.doNotPlayPresentationWithoutAudio = false;
                
                this.logo = {src: 'images/logo_eye_oct.png', mask: {uiController: UIControllerType.media_center}};
            }
        }

        //
        if(isPhilipsIcarus)  {
            targetMenuConfig.enabled = this.target != OETarget.philipsIcarus;
            targetMenuConfig.entries = flags.includes('reduced') ? OETargetMenuDefs.philipsIcarusVersionReduced : OETargetMenuDefs.philipsIcarusVersion;

            uiLayerConfig.widgetConfig.labelController.enabled = false;

            uiLayerConfig.widgetConfig.showHelp = false;
            uiLayerConfig.widgetConfig.cutController.style = OECutControllerStyle.minimal;

            uiLayerConfig.startViewConfig.showAtStartUp = false;

            if(this.target == OETarget.philipsIcarus)   {

                uiLayerConfig.mediaCenterConfig.mode = OEMediaCenterMode.bottomBar;
                uiLayerConfig.mediaCenterConfig.hideBottomBarControlsForItems = [OEMediaCenterItemTypes.none, OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];
                uiLayerConfig.mediaCenterConfig.hideSliderForItems = [OEMediaCenterItemTypes.animation, OEMediaCenterItemTypes.images];

                uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter((btn) => ![MMBtnT.presentation, MMBtnT.mediaCenter, MMBtnT.arrow, MMBtnT.note, MMBtnT.substructure, MMBtnT.explode, MMBtnT.info].includes(btn.type));

                uiLayerConfig.mainMenuButtons.top.splice(0, 1, {type: 'home-icarus', enabled: true, icon: OEIconCodes.home, tooltip: 'main_menu_tooltip_home', adapter: new OEPhilipsIcarusMainMenuBtnAdapterReset()});

                uiLayerConfig.mainMenuButtons.top.splice(1, 0, {type: 'text-view', enabled: true, icon: OEIconCodes.info, adapter: new OEMainMenuBtnAdapterView(OEViewType.text)});

                uiLayerConfig.widgetConfig.textViewController = OEDefaultConfigFactory.textViewController(new OEPhilipsIcarusTextViewAdapter());

                if(isLicensing) {
                    let btnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings);
                    topBtnsRestricted.splice(btnIndex, 0, {type: MMBtnT.info, enabled: true, icon: OEIconCodes.key, tooltip: 'main_menu_tooltip_license'});
                }

                //let btnIndex = uiLayerConfig.mainMenuButtons.top.findIndex((btn) => btn.type === MMBtnT.settings);
                //uiLayerConfig.mainMenuButtons.top.splice(btnIndex - 1, 0, {type: MMBtnT.help, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.help)});

                //this.logo = 'images/philips_logo.png'

                let bgGroup = uiLayerConfig.widgetConfig.settingsController.controls.find(group => group.type === OESettingsControllerDefs.controlGroupTypes.background);
                bgGroup.controls = bgGroup.controls.filter(control => control.type !== OESettingsControllerDefs.controlTypes.vpGizmo);

            } else {

                uiLayerConfig.mainMenuButtons.top = uiLayerConfig.mainMenuButtons.top.filter(btn => btn.type !== MMBtnT.info || isLicensing);

                if(isLicensing) {
                    let infoBtn = uiLayerConfig.mainMenuButtons[UILevel.advanced].top.find(btn => btn.type === MMBtnT.info);
                    infoBtn.icon = OEIconCodes.key;
                    infoBtn.tooltip = 'main_menu_tooltip_license';
                }
            }
        }

        //
        if(isImplantcast)    {
            let isAnatomyTarget = !OEImplantcastTargets.isOpTarget(this.target);
            targetMenuConfig.enabled = !isAnatomyTarget;

            //change implantcast target menu depending on build flags
            targetMenuConfig.entries = OETargetMenuDefs.implantcast_alpha;
            if(flags.includes('beta')) targetMenuConfig.entries = OETargetMenuDefs.implantcast_beta;
            if(flags.includes('production')) targetMenuConfig.entries = OETargetMenuDefs.implantcast_production;

            uiLayerConfig.startViewConfig.showAtStartUp = !isAnatomyTarget && !noStartView && !hasLink;
        }

        // customer logo
        let customerParam = OEToolbox.getUrlParameter('customer');
        if (typeof(customerParam) === 'string') {
            switch (customerParam) {
                case 'med-el': this.logo = 'images/logo_medel.png'; break;
                default: this.logo = 'images/logo_' + customerParam + '.png';
            }
        }
    }

    selectTarget(target, info) {
        let isAmboss = config.version === OEVersion.amboss;

        info.targetChoiceTag = 0;

        // target is already choosen so just return
        if(typeof(target) === 'string') return target;
        
        // if a target is provided as url parameter with name 'target', then translate this target to a oe target and return
        let targetUrlParam = OEToolbox.getUrlParameter('target');
        if(typeof(targetUrlParam) === 'string') {
            let ret;

            switch (targetUrlParam) {
                case 'humanEarMacro': case 'earMacro': ret = isAmboss ? OETarget.humanEarMacroAmboss : OETarget.humanEarMacro; break;
                case 'humanEarMicro': case 'earMicro': ret = OETarget.humanEarMicro; break;
                case 'humanEarMacroAmboss': case 'earMacroAmboss': ret = OETarget.humanEarMacroAmboss; break;
                case 'humanEarMicroAmboss': case 'earMicroAmboss': ret = OETarget.humanEarMicro; break;
                case 'sensorySystemsEar': ret = OETarget.sensorySystemsEar; break;
                case 'skinMacro': ret = OETarget.skinMacroIONTO; break;
                case 'skinMicro': ret = OETarget.skinMicroIONTO; break;
                case 'humanEyeMacro': case 'eyeMacro':ret = OETarget.humanEyeMacro; break;
                case 'humanEyeMicro': case 'eyeMicro':ret = OETarget.humanEyeMicro; break;
                case 'humanEyeCornea': case 'eyeCornea':ret = OETarget.humanEyeCornea; break;
                case 'sensorySystemsEye': ret = OETarget.sensorySystemsEye; break;
                case 'humanEye': case 'eye': ret = OEResourceTarget.humanEye; break;
                case 'humanEyeRes': case 'eyeRes': ret = OEResourceTarget.humanEyeRes; break;
                case 'skin': ret = OETarget.skinMacroIONTO; break;
                case 'humanEar': case 'ear': ret = isAmboss ? OETarget.humanEarMacroAmboss : OETarget.humanEarMacro; break;
                case 'snail': ret = OETarget.snail; break;
                case 'humanSkull': ret = OETarget.humanSkull; break;
                case 'humanCranialNerves': ret = OETarget.humanCranialNerves; break;
                case 'toxNetz': ret = OETarget.toxNetz; break;
                case 'zeissOpti': ret = OETarget.zeissOpti; break;
                case 'zeissOpti2': ret = OETarget.zeissOpti2; break;
                case 'zeissUV': ret = OETarget.zeissUV; break;
                case 'zeiss': ret = OEResourceTarget.zeiss; break;
                case 'sensorySystems': ret = OETarget.sensorySystems; break;
                case 'hoofWeb': ret = OETarget.hoofWeb; break;
                case 'hoofVessels': ret = OETarget.hoofVessels; break;
                case 'hoofDynamics': ret = OETarget.hoofDynamics; break;
                case 'hoofMicro': ret = OETarget.hoofMicro; break;
                case 'hoofCarpal': ret = OETarget.hoofCarpal; break;
                case 'hoof': ret = OEResourceTarget.hoof; break;
                case 'humanArm': ret = OETarget.humanArm; break;
                case 'humanArmPathways': ret = OETarget.humanArmPathways; break;
                case 'humanBodyMuscles': ret = OETarget.humanBodyMuscles; break;
                case 'humanUpperArmElbow': ret = OETarget.humanUpperArmElbow; break;
                case 'humanForearm': ret = OETarget.humanForearm; break;
                case 'humanShoulder': ret = OETarget.humanShoulder; break;
                case 'humanElbow': ret = OETarget.humanElbow; break;
                case 'humanHand': ret = OETarget.humanHand; break;
                case 'humanLeg': ret = OETarget.humanLeg; break;
                case 'humanAnkleFoot': ret = OETarget.humanAnkleFoot; break;
                case 'humanHip2': ret = OETarget.humanHip2; break;
                case 'humanLegPathways': ret = OETarget.humanLegPathways; break;
                case 'humanLowerLeg': ret = OETarget.humanLowerLeg; break;
                case 'humanThighKnee': ret = OETarget.humanThighKnee; break;
                case 'humanHip': ret = OETarget.humanHip; break;
                case 'humanKnee': 
                case 'knee': ret = OETarget.humanKnee; break;
                case 'humanKneeImplantcast': ret = OETarget.humanKneeImplantcast; break;
                case 'implantcastACSMB4in1': ret = OETarget.implantcastACSMB4in1; break;
                case 'implantcastACSSCFB': ret = OETarget.implantcastACSSCFB; break;
                case 'implantcastAGILONMI': ret = OETarget.implantcastAGILONMI; break;
                case 'implantcastACSSCMB': ret = OETarget.implantcastACSSCMB; break;
                case 'implantcastMutarsDistFemurMK': ret = OETarget.implantcastMutarsDistFemurMK; break;
                case 'implantcastMUTARSGenuXMK': ret = OETarget.implantcastMUTARSGenuXMK; break;
                case 'implantcastMUTARSKRIMK': ret = OETarget.implantcastMUTARSKRIMK; break;
                case 'implantcastEcoFitHipStem133123': ret = OETarget.implantcastEcoFitHipStem133123; break;
                case 'implantcastEcoFitHipStem': ret = OETarget.implantcastEcoFitHipStem; break;
                case 'implantcastEcoFitShortStem': ret = OETarget.implantcastEcoFitShortStem; break;
                case 'implantcastEcoFitCup': ret = OETarget.implantcastEcoFitCup; break;
                case 'implantcastActiniaStem': ret = OETarget.implantcastActiniaStem; break;
                case 'implantcastShoulderApproachAnterior': ret = OETarget.implantcastShoulderApproachAnterior; break;
                case 'implantcastTestTarget': ret = OETarget.implantcastTestTarget; break;
                case 'humanFoot': 
                case 'foot': ret = OETarget.humanFoot; break;
                case 'humanTrunk': ret = OETarget.humanTrunk; break;
                case 'humanSpine': ret = OETarget.humanSpine; break;
                case 'humanSpineFracture': ret = OETarget.humanSpineFracture; break;
                case 'humanAbdominalWall': ret = OETarget.humanAbdominalWall; break;
                case 'humanChestWall': ret = OETarget.humanChestWall; break;
                case 'humanPelvis': ret = OETarget.humanPelvis; break;
                case 'humanNeckBack': ret = OETarget.humanNeckBack; break;
                case 'humanShoulderGirdle': ret = OETarget.humanShoulderGirdle; break;
                case 'humanHead': ret = OETarget.humanHead; break;
                case 'humanHeadWIP': ret = OETarget.humanHeadWIP; break;
                case 'humanEye2': ret = OETarget.humanEye2; break;
                case 'humanEyeRetina': ret = OETarget.humanEyeRetina; break;
                case 'humanEyeOCT': ret = OETarget.humanEyeOCT; break;
                case 'humanLarynx': ret = OETarget.humanLarynx; break;
                case 'humanLiverMicro': ret = OETarget.humanLiverMicro; break;
                case 'humanHeart' : ret = OETarget.humanHeart; break;
                case 'animalCell': ret = OETarget.animalCell; break;
                case 'plantCell': ret = OETarget.plantCell; break;
                case 'humanCellEpithelium': ret = OETarget.humanCellEpithelium; break;
                case 'amboss': ret = OEResourceTarget.amboss; break;
                case 'ambossImport': ret = OEResourceTarget.ambossImport; break;
                case 'anatomy': ret = OEResourceTarget.anatomy; break;
                case 'cell': ret = OEResourceTarget.cell; break;
                case 'philipsIcarus': ret = OETarget.philipsIcarus; break;
                case 'philipsIcarusAnatomy': ret = OEResourceTarget.philipsIcarusAnatomy; break;
                case 'skinIONTO': ret = OEResourceTarget.skinIONTO; break;
// @ADD_TARGET_ALL_TARGETURL
                case 'humanHeadLeFx': ret = OETarget.humanHeadLeFx; break;
                case 'implantcastAidaShortStem': ret = OETarget.implantcastAidaShortStem; break;
                case 'humanBrainSynapse': ret = OETarget.humanBrainSynapse; break;
                case 'implantcastAGILONTraumalongfit': ret = OETarget.implantcastAGILONTraumalongfit; break;
                case 'implantcastMutarsRS': ret = OETarget.implantcastMutarsRS; break;
                case 'implantcastDirectAnteriorApproach': ret = OETarget.implantcastDirectAnteriorApproach; break;
                case 'implantcastEcoFitCupEPORE': ret = OETarget.implantcastEcoFitCupEPORE; break;
                case 'implantcastMUTARSPRS': ret = OETarget.implantcastMUTARSPRS; break;
                case 'implantcastAGILONOmarthrosisLongFit': ret = OETarget.implantcastAGILONOmarthrosisLongFit; break;
                default: break;
            }

            if(ret) {
                info.targetChoiceTag = 2;
                return ret;
            }
        }

        // if config.target is set, i.e., the target is fixed by the build configuration, translate this target to a oe target and return
        if(typeof(config.target) === 'string' && config.target !== '')   {

            let confTarget = config.target;

            switch (config.target) {
                case 'humanEar':
                case 'humanEarMacro': 
                    confTarget = isAmboss ? OETarget.humanEarMacroAmboss : OETarget.humanEarMacro; break;
                case 'humanEarMicroAmboss': confTarget = OETarget.humanEarMicro; break;
                case 'skinMacro':
                case 'skin':
                    confTarget = OETarget.skinMacroIONTO;
                    break;
                case 'skinMicro': confTarget = OETarget.skinMicroIONTO; break;
            }

            info.targetChoiceTag = 1;

            return confTarget;
        }

        // target keeps undefined
        return target;
    }

    logoFor(target, version)  {
        if(version == OEVersion.anatomy)  return 'images/logo/logo_anatomy.png';
        if(version == OEVersion.ionto)  return 'images/logo/logo_skin_2.png';
        if(version == OEVersion.philipsIcarus)  return 'images/logo/logo_foot.png';
        if(version == OEVersion.zeissOpti)  return 'images/logo/logo_zeiss_opti.png';
        if(version == OEVersion.eyeOCT) return 'images/logo/logo_eye_oct.png';
        if(version == OEVersion.implantcast)  return 'images/logo/logo_implantcast.png';

        switch(target)   {
            case OETarget.humanSkull: return 'images/logo/logo_skull.png';

            case OETarget.humanEarMacro:
            case OETarget.humanEarMacroAmboss:
            case OETarget.humanEarMicro:
                return 'images/logo/logo_ear.png';

            case OETarget.skinMacroIONTO:
            case OETarget.skinMicroIONTO:
                return 'images/logo/logo_skin.png';

            case OETarget.humanEyeMacro:
            case OETarget.humanEyeMicro:
                return 'images/logo/logo_eye.png';

            case OETarget.snail: return 'images/logo/logo_snail.png';

            case OETarget.humanKnee:
                return 'images/logo/logo_knee.png';

            case OETarget.zeissOpti:
            case OETarget.zeissOpti2:
            case OETarget.zeissUV:
                return 'images/logo/logo_zeiss_opti.png';

            case OETarget.humanFoot:
                return 'images/logo/logo_foot.png';

            case OETarget.hoofDynamics:
            case OETarget.hoofVessels:
            case OETarget.hoofWeb:
            case OETarget.hoofMicro:
                return 'images/logo/logo_hoof.png';

            case OETarget.animalCell:
            case OETarget.plantCell:
                return 'images/logo/logo_animal_cell.png';
        }
        
        return 'images/logo/logo.png';
    }

    getFlags()  {
        let flags = Array.isArray(config.flags) ? clone(config.flags) : new Array();
        let flagsUrl = OEToolbox.getUrlParameter('flags');
        if(flagsUrl)    {
            flagsUrl = flagsUrl.split('_');
            flags = flags.concat(flagsUrl);
        }
        return flags.filter(flag => flag != '');
    }
}