import clone from 'clone';
import domToImage from 'dom-to-image';

import {oeInterfaceManager} from '../react-oe/oe-interface';
import {OEToolbox} from '../lib/oe-toolbox';

const OEScreenshotTool = new class {

    constructor() {
        this.defaultOptions = {uiLayer: true, transparency: false, size: undefined, bgColor: undefined, imageFormat: undefined, quality: undefined, filename: undefined};
    }

    safe(dataUrl, filename)   {
        let link = document.createElement('a');
        link.download = filename;
        link.href = dataUrl;
        link.click();
    }

    newCanvas(size, bgColor) {
        let canvas = document.createElement('canvas');
        canvas.width = size.w; canvas.height = size.h;

        if(bgColor) {
            let ctx = canvas.getContext('2d');
            ctx.fillStyle = bgColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        return canvas;
    }

    filterFn(oe, options)   {
        if(options.uiLayer) {
            return node => { return node._screenshotToolFilter_ui !== 1; };
        } else {
            return node => { return node._screenshotToolFilter_no_ui === 1; };
        }
    }

    modifierFn(oe, options)   {
        return node => {
            if(node.tagName !== 'BODY') return;
            node.style.background = 'rgba(56, 56, 56, 0)';
        };
    }

    updateFilterTags(oe, options)   {
        if(options.uiLayer) {
            let hiddenElements = document.getElementsByClassName('screenshot-filter-hide');
            for(let i = 0; i < hiddenElements.length; i++)  hiddenElements[i]._screenshotToolFilter_ui = 1;

            oe.element._screenshotToolFilter_ui = 1;

        } else {
            let showElements = document.getElementsByClassName('screenshot-filter-show');
            for(let i = 0; i < showElements.length; i++)  OEToolbox.iterateRelatedNodes(showElements[i], (element) => { element._screenshotToolFilter_no_ui = 1})

            let hiddenElements = document.getElementsByClassName('screenshot-filter-hide');
            for(let i = 0; i < hiddenElements.length; i++)  hiddenElements[i]._screenshotToolFilter_no_ui = 0;

            oe.element._screenshotToolFilter_no_ui = 0;
        }
    }

    takeCoreImage(oe, options)   {
        return (
            new Promise(function (resolve, reject) {
                if(!oe || !oe.isReady())    { resolve(null); return; }

                let flags = 0;
                if(options.transparency)    flags |= oe.Module.ScreenshotFlag.transparency.value;
                if(!options.uiLayer)    flags |= oe.Module.ScreenshotFlag.noVPGizmo.value | oe.Module.ScreenshotFlag.noNoteGUI.value;

                oe.sharedInterface.screenshotFn(resolve, null, flags);

        })).then(uri => {
            return uri ? domToImage.impl.util.makeImage(uri) : null;
        }).then(domToImage.impl.util.delay(100));

        /*
        return (
            new Promise(function (resolve, reject) {
                if(!oe || !oe.isReady())    { resolve(null); return; }

                let flags = 0;
                if(options.transparency)    flags |= oe.Module.ScreenshotFlag.transparency.value;
                if(!options.uiLayer)    flags |= oe.Module.ScreenshotFlag.noVPGizmo.value | oe.Module.ScreenshotFlag.noNoteGUI.value;

                return oe.sharedInterface.screenshot(null, flags);

        })).then(uri => {
            return uri ? domToImage.impl.util.makeImage(uri) : null;
        }).then(domToImage.impl.util.delay(100));
        */
    }

    takeUIImage(oe, options)    {
        this.updateFilterTags(oe, options);
        let domNode = document.body;
        let filter = this.filterFn(oe, options);
        let modifier = this.modifierFn(oe, options);

        /*
        domToImage.toSvg(domNode, {filter: filter, modifier: modifier})
        .then((data) => {
            this.safe(data, "Test.svg");
        });
        */
       
        return domToImage.toSvg(domNode, {filter: filter, modifier: modifier})
            .then(domToImage.impl.util.makeImage)
            .then(domToImage.impl.util.delay(100));
    }

    devicePixelRatio()    {
        return window.devicePixelRatio || 1;
    }

    takeCanvas(moduleId, options)   {
        options = clone(options || this.defaultOptions);
        let oe = oeInterfaceManager.getInterface(moduleId);
        let domNode = document.body;
        let domRect = domNode.getBoundingClientRect();
        let pixelRatio = this.devicePixelRatio();
        options.size = options.size || {w: pixelRatio * domRect.width, h: pixelRatio * domRect.height};
        let canvas = this.newCanvas(options.size, options.bgColor);

        let coreFrame;
        if(oe && oe.element)    {
            let rect = oe.element.getBoundingClientRect();
            coreFrame = {x: pixelRatio * rect.top, y: pixelRatio * rect.left, w: pixelRatio * rect.width, h: pixelRatio * rect.height};
        }

        return this.takeCoreImage(oe, options)
            .then(coreImage => {
                return this.takeUIImage(oe, options)
                    .then(image => {
                        let ctx = canvas.getContext('2d');
                        if(coreImage && coreFrame)   ctx.drawImage(coreImage, coreFrame.x, coreFrame.y, canvas.width - coreFrame.x, canvas.height - coreFrame.y);
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        return canvas;
                    });
            });
    }

    takeDataURL(moduleId, options)   {
        options = options || this.defaultOptions;
        return this.takeCanvas(moduleId, options)
            .then(canvas => {
                let jpg = options.imageFormat === 'jpeg';
                let data = canvas.toDataURL(jpg ? 'image/jpeg' : 'image/png', jpg ? options.quality || 1.0 : undefined);
                if(options.filename)    this.safe(data, options.filename);
                return data;
            });
    }
};

export default OEScreenshotTool;