import React from 'react';
import PropTypes from 'prop-types';

export default class OETextEdit extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);

        this.onTextareaRef = this.onTextareaRef.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    focus() {
        if(this.textareaRef)   this.textareaRef.focus();
    }

    onKeyDown(e)    {
        if(e.key !== 'Tab' || this.props.disabled) return;
        e.preventDefault();
        let textarea = e.target;

        let start = textarea.selectionStart;
        let end = textarea.selectionEnd;
        textarea.value = textarea.value.substring(0, start) + '\t' + textarea.value.substring(end);

        textarea.selectionStart = textarea.selectionEnd = start + 1;

        this.onChange(e);
    }

    onTextareaRef(ref) {
        if(this.textareaRef === ref)    return;
        if(this.textareaRef)    this.textareaRef.removeEventListener('keydown', this.onKeyDown);
        this.textareaRef = ref;
        if(this.textareaRef)    this.textareaRef.addEventListener('keydown', this.onKeyDown);
    }

    render()    {
        let style = {};
        if(this.props.fontSize) style.fontSize = this.props.fontSize;

        return (
            <div className={'text-edit ' + (this.props.disabled ? 'disabled ' : '') + (this.props.className ? this.props.className : '')}>
                <form>
                    <textarea
                        style={style}
                        disabled={this.props.disabled}
                        spellCheck={this.props.spellCheck}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        ref={this.onTextareaRef}
                    />	
                </form>
            </div>
        );
    }

    onChange(evt)   {
        if(this.props.disabled || !this.props.onChange) return;
        this.props.onChange(this, evt.target.value);
    }

    onFocus(evt)   {
        if(this.props.onFocus)  this.props.onFocus(this);
    }

    onBlur(evt)   {
        if(this.props.onBlur)  this.props.onBlur(this);
    }
}

OETextEdit.defaultProps = {
    className: '',
    disabled: false,
    spellCheck: false,
    placeholder: '',
    value: ''
};

OETextEdit.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    spellCheck: PropTypes.bool,
    placeholder: PropTypes.string,
    fontSize: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClearButtonPressed: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};