import React from 'react';
import PropTypes from 'prop-types';

//import Hammer from 'react-hammerjs';

import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import OESlider from '../elements/oe-slider';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEThemeIndicatorView from '../oe-theme-indicator-view';

import {OEMediaViewerContentType, OEMediaViewerPlaybackState, OEMediaViewerReadyState, OEMediaViewerContentSizeMode} from './oe-media-viewer-model';
import {OEIconCodes} from '../../lib/oe-icon-codes';

export class OEMediaViewerOverlayController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;

        this.state = {
            show: true,
            buttons: {
                prev: {enabled: false},
                next: {enabled: false}
            }
        };

        this.resetShowCycle = this.resetShowCycle.bind(this);

        this.onControlsRef = this.onControlsRef.bind(this);
        this.onOverlayViewRef = this.onOverlayViewRef.bind(this);

        this.onSwipeLeft = this.onSwipeLeft.bind(this);
        this.onSwipeRight = this.onSwipeRight.bind(this);

        this.onNextBtnPressed = this.onNextBtnPressed.bind(this);
        this.onPrevBtnPressed = this.onPrevBtnPressed.bind(this);

        this.onPlayBtnPressed = this.onPlayBtnPressed.bind(this);
        this.onStopBtnPressed = this.onStopBtnPressed.bind(this);

        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);

        this.onProgressSliderStart = this.onProgressSliderStart.bind(this);
        this.onProgressSliderChanged = this.onProgressSliderChanged.bind(this);
        this.onProgressSliderEnd = this.onProgressSliderEnd.bind(this);
    }

    resheduleTimer()  {
        if(this.timer)  {
            clearTimeout(this.timer); this.timer = null;
        }

        this.timer = setTimeout(() => { this.setState({show: false}) }, 2000);
    }

    resetShowCycle(pos)    {
        if(this.mounted === false) return;
        if(pos && !this.isPosInOverlayView(pos))    return;
        this.setState({show: true});
        this.resheduleTimer();
    }

    componentWillMount() {
        this.mounted = true;
        this.resetShowCycle();
        this.updateButtonState();
    }

    componentWillUnmount()  {
        if(this.timer)  {
            clearTimeout(this.timer); this.timer = null;
        }
        this.mounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.activeContentIndex !== this.props.activeContentIndex || nextProps.dataSource !== this.props.dataSource)  {
            this.updateButtonState(nextProps);
        }
    }

    componentDidUpdate(prevProps)    {
        if(prevProps.fullscreen !== this.props.fullscreen || prevProps.readyState !== this.props.readyState || prevProps.playbackState !== this.props.playbackState)  {
            this.resetShowCycle();
        }
    }

    updateButtonState(props)    {
        props = props || this.props;
        this.setState({buttons: {
            prev: {enabled: props.shouldChangePrev()},
            next: {enabled: props.shouldChangeNext()}
        }});
    }

    onControlsRef(ref)  {
        this.controlsRef = ref;
    }

    onOverlayViewRef(ref)  {
        this.overlayViewRef = ref;
    }

    isPosInControlsView(pos)    {
        if(!this.controlsRef) return false;
        if(this.controlsRef.style.opacity == 0 || this.controlsRef.style.visibility == 'hidden')    return false;
        const rect = this.controlsRef.getBoundingClientRect();
        //console.log('rect - left: ' + rect.left.toString() + ' right:' + rect.right.toString() + ' top:' + rect.top.toString() + ' bottom:' + rect.bottom.toString());
        return pos.x >= rect.left && pos.x <= rect.right && pos.y >= rect.top && pos.y <= rect.bottom;
    }

    isPosInOverlayView(pos)    {
        if(!this.overlayViewRef) return false;
        const rect = this.overlayViewRef.getBoundingClientRect();
        //console.log('rect - left: ' + rect.left.toString() + ' right:' + rect.right.toString() + ' top:' + rect.top.toString() + ' bottom:' + rect.bottom.toString());
        return pos.x >= rect.left && pos.x <= rect.right && pos.y >= rect.top && pos.y <= rect.bottom;
    }

    renderControls()   {
        if(!this.props.showControlsWhenWindow && !this.props.fullscreen)    return null;

        let time = this.props.time;
        let timeMin = Math.floor(time/60);
        let timeSec = Math.round(time - timeMin * 60);
        time = OEToolbox.numberToString(timeMin, 2) + ':' + OEToolbox.numberToString(timeSec, 2);

        let duration = this.props.duration;
        let durationMin = Math.floor(duration/60);
        let durationSec = Math.round(duration - durationMin * 60);
        duration = OEToolbox.numberToString(durationMin, 2) + ':' + OEToolbox.numberToString(durationSec, 2);

        let showWaitingSpinner = this.props.mediaType === OEMediaViewerContentType.video && this.props.readyState !== OEMediaViewerReadyState.ready;

        let show = this.props.mediaType === OEMediaViewerContentType.video && (this.state.show || showWaitingSpinner || (this.props.showControlsPermanentlyWhenPaused && this.props.playbackState === OEMediaViewerPlaybackState.pause));

        let style = {opacity: show ? '1' : '0', pointerEvents: show ? 'initial' : 'none'};
        let disabled = !show;

        if(this.props.contentAlignedWhenWindowed && !this.props.fullscreen)  {
            if(this.props.contentRect)  {
                if(this.props.contentRect.mode !== OEMediaViewerContentSizeMode.fill)   {
                    style.right = this.props.contentRect.right;
                    style.bottom = this.props.contentRect.bottom;
                    style.left = this.props.contentRect.left;
                    style.width = 'auto';
                }
                
            } else {
                style.visibility = 'hidden';
                style.pointerEvents = 'none';
            }
        }

        return (
            <div 
                className="controls media-viewer-overlay-element" 
                style={style}
                ref={this.onControlsRef}
            >

                <OEButton
                    className="btn btn-play"
                    disabled={disabled}
                    onPressed={this.onPlayBtnPressed}
                >
                    <OEIcon code={this.props.playbackState === OEMediaViewerPlaybackState.play ? OEIconCodes.pause : OEIconCodes.play}/>
                </OEButton>

                <OEButton
                    className="btn btn-stop"
                    disabled={disabled}
                    onPressed={this.onStopBtnPressed}
                >
                    <OEIcon code={OEIconCodes.stop}/>
                </OEButton>

                <span className="display-time">
                    {time}
                </span>

                <OESlider
                    className="progress-slider"
                    disabled={disabled}
                    min={0}
                    max={this.props.duration}
                    step={Math.max(1e-6, Math.abs(this.props.duration)/1000.0)}
                    value={this.props.time}
                    onStart={this.onProgressSliderStart}
                    onSlide={this.onProgressSliderChanged}
                    onEnd={this.onProgressSliderEnd}
                />

                <span className="display-duration">
                    {duration}
                </span>

            </div>
        );
    }

    render() {
        let showWaitingSpinner = this.props.mediaType === OEMediaViewerContentType.video && this.props.readyState !== OEMediaViewerReadyState.ready;
        let showBigPlayButton = this.props.mediaType === OEMediaViewerContentType.video && this.props.playbackState !== OEMediaViewerPlaybackState.play && this.props.readyState === OEMediaViewerReadyState.ready;

        let show = this.state.show || showWaitingSpinner || (this.props.showControlsPermanentlyWhenPaused && this.props.mediaType === OEMediaViewerContentType.video && this.props.playbackState === OEMediaViewerPlaybackState.pause);
        let style = {opacity: show ? '1' : '0'};
        let disabled = !show;

        let controls = this.renderControls();

        let fullscreenCloseBtn = this.props.showCloseBtn ? (
            <OEButton
                className="media-viewer-overlay-element btn btn-close"
                style={style}
                disabled={disabled}
                onPressed={this.onCloseBtnPressed}
            >
                <OEIcon code={OEIconCodes.close}/>
            </OEButton>
        ) : (
            <OEButton
                className="media-viewer-overlay-element btn btn-fullscreen"
                style={style}
                disabled={disabled}
                onPressed={this.onFullscreenBtnPressed}
            >
                <OEIcon code={this.props.fullscreen ? OEIconCodes.compress : OEIconCodes.expand}/>
            </OEButton>
        );

        let waitingSpinner = <div className='waiting-spinner' style={{opacity: showWaitingSpinner ? '1' : '0'}}><OEThemeIndicatorView /></div>;

        let overlayViewStyle = null;
        if(this.props.contentAlignedWhenWindowed && !this.props.fullscreen)  {

            if(this.props.contentRect)  {

                if(this.props.contentRect.mode === OEMediaViewerContentSizeMode.fill)   {
                    overlayViewStyle = {width:'100%', height:'100%'};
                } else {
                    overlayViewStyle = {top: this.props.contentRect.top, right: this.props.contentRect.right, bottom: this.props.contentRect.bottom, left: this.props.contentRect.left, width:'auto', height:'auto'};
                }
                
            } else {
                overlayViewStyle = {visibility: 'hidden'};
            }
        }

        return (
            <div 
                className={'media-viewer-overlay ' + this.props.className}
                ref={this.props.elementRef}
            >

                {/*
                <Hammer
                    onSwipeLeft={this.onSwipeLeft}
                    onSwipeRight={this.onSwipeRight}
                    onTap={this.resetShowCycle}
                >
                */}
                    <div
                        className={'overlay-view'}
                        style={overlayViewStyle}
                        ref={this.onOverlayViewRef}
                        onMouseMove={this.resetShowCycle}
                    >

                        {waitingSpinner}

                        {/*
                        <div className="mouse-event-target-ref"
                            ref={this.props.mouseEventTargetRef}
                        />
                        */}

                        {fullscreenCloseBtn}

                        {this.props.showNextPrevButtons ? <OEButton
                            className="media-viewer-overlay-element btn btn-next"
                            style={style}
                            disabled={disabled || !this.state.buttons.next.enabled}
                            onPressed={this.onNextBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.chevronRight}/>
                        </OEButton> : null}

                        {this.props.showNextPrevButtons ? <OEButton
                            className="media-viewer-overlay-element btn btn-prev"
                            style={style}
                            disabled={disabled || !this.state.buttons.prev.enabled}
                            onPressed={this.onPrevBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.chevronLeft}/>
                        </OEButton> : null}

                        <OEButton
                            className="media-viewer-overlay-element btn btn-big-play"
                            style={{opacity: showBigPlayButton ? '1' : '0'}}
                            disabled={!showBigPlayButton}
                            onPressed={this.onPlayBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.play}/>
                        </OEButton>
                    </div>

                {/*
                </Hammer>
                */}

                {controls}

            </div>
        );
    }

    onSwipeLeft(event)  {
        if(this.isPosInOverlayView(event.center) && !this.isPosInControlsView(event.center) && this.props.shouldChangeNext())  {
            this.props.indexChange(1);
        }
    }

    onSwipeRight(event)  {
        if(this.isPosInOverlayView(event.center) && !this.isPosInControlsView(event.center) && this.props.shouldChangePrev())  {
            this.props.indexChange(-1);
        }
    }

    onNextBtnPressed()  {
        this.resetShowCycle();
        if(this.props.shouldChangeNext())  this.props.indexChange(1);
    }

    onPrevBtnPressed() {
        this.resetShowCycle();
        if(this.props.shouldChangePrev())  this.props.indexChange(-1);
    }

    onPlayBtnPressed()  {
        this.resetShowCycle();
        this.props.playbackStateChange(this.props.playbackState === OEMediaViewerPlaybackState.play ? OEMediaViewerPlaybackState.pause : OEMediaViewerPlaybackState.play);
    }

    onStopBtnPressed()  {
        this.resetShowCycle();
        this.props.playbackStateChangeStop();
    }

    onFullscreenBtnPressed()    {
        this.resetShowCycle();
        this.props.onFullscreenBtnPressed(this.props.fullscreen);
    }

    onCloseBtnPressed() {
        this.resetShowCycle();
        this.props.onCloseBtnPressed();
    }

    onProgressSliderStart(value) {
        this.resetShowCycle();
        let progress = Math.max(0, Math.min(1, this.props.duration == 0 ? 0 : value / this.props.duration));
        this.props.progressChange(progress);
    }

    onProgressSliderChanged(value)   {
        this.resetShowCycle();
        let progress = Math.max(0, Math.min(1, this.props.duration == 0 ? 0 : value / this.props.duration));
        this.props.progressChange(progress);
    }

    onProgressSliderEnd(value)   {
        this.resetShowCycle();
        let progress = Math.max(0, Math.min(1, this.props.duration == 0 ? 0 : value / this.props.duration));
        this.props.progressChange(progress);
    }
}

OEMediaViewerOverlayController.defaultProps = {
    className: '',
    activeContentIndex: 0,
    dataSource: null,
    contentRect: null,
    showCloseBtn: false,
    showControlsWhenWindow: false,
    showControlsPermanentlyWhenPaused: true,
    contentAlignedWhenWindowed: true,
    showNextPrevButtons: true,
    playbackStateChange: (state) => {},
    playbackStateChangeStop: () => {},
    progressChange: (progress) => {},
    indexChange: (change) => {},
    shouldChangePrev: () => { return true; },
    shouldChangeNext: () => { return true; },
    onFullscreenBtnPressed: (fullscreen) => {},
    onCloseBtnPressed: () => {},
    mediaType: OEMediaViewerContentType.dummy,
    fullscreen: false,
    readyState: OEMediaViewerReadyState.ready,
    playbackState: OEMediaViewerPlaybackState.pause,
    duration: 0,
    time: 0
};

OEMediaViewerOverlayController.propTypes = {
    className: PropTypes.string,
    activeContentIndex: PropTypes.number,
    dataSource: PropTypes.object,
    contentRect: PropTypes.object,
    showCloseBtn: PropTypes.bool,
    showControlsWhenWindow: PropTypes.bool,
    showControlsPermanentlyWhenPaused: PropTypes.bool,
    contentAlignedWhenWindowed: PropTypes.bool,
    showNextPrevButtons: PropTypes.bool,
    playbackStateChange: PropTypes.func,
    playbackStateChangeStop: PropTypes.func,
    progressChange: PropTypes.func,
    indexChange: PropTypes.func,
    shouldChangePrev: PropTypes.func,
    shouldChangeNext: PropTypes.func,
    onFullscreenBtnPressed: PropTypes.func,
    onCloseBtnPressed: PropTypes.func,
    mediaType: PropTypes.oneOf([OEMediaViewerContentType.dummy, OEMediaViewerContentType.image, OEMediaViewerContentType.video, OEMediaViewerContentType.web, OEMediaViewerContentType.pdf]),
    fullscreen: PropTypes.bool,
    readyState: PropTypes.oneOf([OEMediaViewerReadyState.error, OEMediaViewerReadyState.noMeta, OEMediaViewerReadyState.waiting, OEMediaViewerReadyState.ready]),
    playbackState: PropTypes.oneOf([OEMediaViewerPlaybackState.pause, OEMediaViewerPlaybackState.play]),
    duration: PropTypes.number,
    time: PropTypes.number,
    elementRef: PropTypes.func,
    mouseEventTargetRef: PropTypes.func
};