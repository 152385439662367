import React from 'react';
import PropTypes from 'prop-types';

import {copyComponentKeys} from '../lib/oe-higher-order-components';
import OEEnvContextHelper from '../lib/oe-env-context-helper';

// data model for module environment state
export class ModuleComponent {
    constructor() {
        this.module = null;
        this.uiStateManager = null;
    }
}

export default class ModuleEnv {
    constructor(strings) {
        this.component = new ModuleComponent();

        this.strings = strings || {
            toggle: {
                on: 'On',
                off: 'Off'
            }
        };

        this.ui = {
            capBar: {
                size: {w: 0, h: 0}
            },
            mainMenu: {
                width: 56
            },
            leftSlideInContainer: {
                width: 250 + 56, // 250 + this.ui.mainMenu.width
                offset: 56, // this.ui.mainMenu.width
                animate: false
            },
            mediaCenter:    {
                edgeOffsets: {top: 0, right: 0, bottom: 0, left: 0, animate: false}
            },
            bottomBarWidgetToolbar:    {
                edgeOffsets: {top: 0, right: 0, bottom: 0, left: 0, animate: false}
            },
            overlay:    {
                edgeOffsets: {top: 0, right: 0, bottom: 0, left: 0, animate: false}
            },
            bottomEdgeOffset: {
                value: 0, animate: false
            },
            contextMenu: {
                areaRestriction: {top: 0, right: 0, bottom: 0, left: 0}
            }
        };
    }
}

// define a context holding ModuleEnv
export const ModuleEnvContext = OEEnvContextHelper.createContext(ModuleEnv, 'ModuleEnv');

export const ModuleEnvContextProvider = OEEnvContextHelper.contextProviderComponent(ModuleEnvContext, ModuleEnv);

// HOC for embedding given OEModule into an ModuleEnvContext
export const withModuleEnv = OEEnvContextHelper.withContext(ModuleEnvContextProvider);

// custom hooks for ModuleEnvContext subscription
export const useModuleEnv = OEEnvContextHelper.useEnv(ModuleEnvContext);

// HOCs for adding environment as env prop
export function addModuleEnv(WrappedComponent) {
    return copyComponentKeys(function(props)  {
        const [moduleEnv, setModuleEnv, updateModuleEnv] = useModuleEnv();
        return <WrappedComponent moduleEnv={moduleEnv} setModuleEnv={setModuleEnv} updateModuleEnv={updateModuleEnv} {...props} />;
    }, WrappedComponent);
}

// creates HOCs for global state reduction an application with constructState: (ModuleEnv, set, update) => props for WrappedComponent
// export const connectModuleEnv = OEEnvContextHelper.connectEnv(ModuleEnvContext);
// there is some unknown problem with the usage of OEEnvContextHelper.connectEnv, but the following inline definition works
export function connectModuleEnv(constructState) {
    return (WrappedComponent) => {
        return copyComponentKeys(addModuleEnv(class extends React.PureComponent {

            constructor(props) {
                super(props);
                this.state = constructState(this.props.moduleEnv, this.props.setModuleEnv, this.props.updateModuleEnv);
            }

            componentWillReceiveProps(nextProps) {
                if(this.props.moduleEnv !== nextProps.moduleEnv || this.props.setModuleEnv !== nextProps.setModuleEnv || this.props.updateModuleEnv !== nextProps.updateModuleEnv) {
                    this.setState(constructState(nextProps.moduleEnv, nextProps.setModuleEnv, nextProps.updateModuleEnv))
                }
            }

            render() {
                const {moduleEnv, setModuleEnv, updateModuleEnv, ...rest} = this.props;

                if(WrappedComponent.defaultProps)   {
                    Object.keys(this.state).forEach(key => {
                        if(rest[key] === WrappedComponent.defaultProps[key]) delete rest[key];
                    });
                } else {
                    Object.keys(this.state).forEach(key => {
                        delete rest[key];
                    });
                }

                return <WrappedComponent {...this.state} {...rest}/>;
            }

        }), WrappedComponent);
    };
}

// component for testing purpose
export class OEModuleEnvComponentUnconnected extends React.PureComponent {
    render() {
        return (
            <div className={'module-env-test ' + this.props.className}>
                <span>{this.props.strings.on}</span><span>{this.props.strings.off}</span>
            </div>
        );
    }
}

OEModuleEnvComponentUnconnected.defaultProps = {
    className: '',
    strings: {
        on: 'On',
        off: 'Off'
    }
};

OEModuleEnvComponentUnconnected.propTypes = {
    className: PropTypes.string,
    strings: PropTypes.shape({
        on: PropTypes.string,
        off: PropTypes.string
    })
};

export const OEModuleEnvComponent = connectModuleEnv((env) => {
    return {
        strings: env.strings.toggle
    };
})(OEModuleEnvComponentUnconnected);