import React from 'react';
import PropTypes from 'prop-types';

import {OECustomCheckbox} from '../elements/oe-checkbox';
import OEIcon from '../elements/oe-icon';
import OEScrollbars from '../oe-scrollbars';

export class OEFNSpacerElement extends React.PureComponent {
    render() {
        let height = '34px';
        if(typeof(this.props.height) === 'number')  {
            height = this.props.height.toString() + 'px';
        } else if(typeof(this.props.height) === 'string')  {
            height = OEFNSpacerElement.heights[this.props.height] ? OEFNSpacerElement.heights[this.props.height] : this.props.height;
        } 
        return <div className={'flow-nav-spacer-element ' + this.props.className} style={{height: height}} />;
    }
}

OEFNSpacerElement.defaultProps = {
    className: '',
    height: '34px'
};

OEFNSpacerElement.propTypes = {
    className: PropTypes.string,
    height:  PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

OEFNSpacerElement.heights = {
    std: '34px',
    top: '74px',
    bottom: '106px'
}

export class OEFNMenuElement extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-menu-element ' + (this.props.enabled ? 'enabled ' : 'disabled ') + this.props.className}>
                <div className="left" onClick={this.onClick}>
                    {this.props.iconCode === '' ? null : <OEIcon code={this.props.iconCode}/>}
                    {this.props.leftContent}
                </div>

                <div className={'spacer ' + (this.props.iconCode === '' ? 'opaque' : '')} onClick={this.onClick}/>

                <div className="main" onClick={this.onClick}>
                    {this.props.html ? <span dangerouslySetInnerHTML={{__html: this.props.html}}/> : <span>{this.props.title}</span>}
                    {this.props.children}
                </div>
            </div>
        );
    }

    onClick()   {
        if(!this.props.enabled) return;
        if(this.props.onClick)  this.props.onClick(this.props.id, this);
    }
}

OEFNMenuElement.defaultProps = {
    id: '',
    className: '',
    iconCode: '',
    title: '',
    enabled: true
};

OEFNMenuElement.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    html: PropTypes.string,
    enabled: PropTypes.bool,
    onClick: PropTypes.func
};

export class OEFNCheckboxElement extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        const subtitle = this.props.subtitle;
        return (
            <div className={'flow-nav-checkbox-element ' + this.props.className}>
                <OECustomCheckbox checked={this.props.checked} onPressed={this.onClick}/>
                <div className="title">
                    <span className="primary-title">{this.props.title}</span>
                    {!subtitle || !subtitle.length ? null : <span className="sub-title">{subtitle}</span>}
                </div>
            </div>
        );
    }

    onClick()   {
        if(this.props.onClick)  this.props.onClick(this.props.id);
    }
}

OEFNCheckboxElement.defaultProps = {
    id: '',
    className: '',
    title: '',
    checked: false
};

OEFNCheckboxElement.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    checked:  PropTypes.bool,
    onClick: PropTypes.func
};

export class OEFNHyperlinkElement extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-hyperlink-element ' + this.props.className}>
                <span>{this.props.label}</span>
                <a href={this.props.href} onClick={this.props.onClick ? this.onClick : null}>
                    {this.props.title ? this.props.title : this.props.href}
                </a>
            </div>
        );
    }

    onClick()   {
        if(this.props.onClick)  this.props.onClick(this.props.id);
    }
}

OEFNHyperlinkElement.defaultProps = {
    id: '',
    className: '',
    label: ''
};

OEFNHyperlinkElement.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNChapter extends React.PureComponent {
    render() {
        return (
            <div className={'flow-nav-chapter ' + this.props.className}>
                <span className="title">{this.props.title}</span>
                <div className="body">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

OEFNChapter.defaultProps = {
    className: '',
    title: ''
};

OEFNChapter.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string
};

export class OEFNParagraph extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        var noChild = !this.props.children || !this.props.children.length;
        var noIcon = this.props.iconCode === '';

        return (
            <div className={'flow-nav-paragraph ' + (noChild ? 'empty ' : '') + (noIcon ? 'no-icon ' : '') + this.props.className}>
                <div className="title-bar">
                    <div className="left" onClick={this.onClick}>
                        {noIcon ? null : <OEIcon code={this.props.iconCode}/>}
                    </div>

                    <div className="main" onClick={this.onClick}>
                        {this.props.title}
                    </div>

                    <div className="right" onClick={this.onClick}>
                    </div>
                </div>
                <div className="body">
                    {this.props.children}
                </div>
            </div>
        );
    }

    onClick()   {
        if(this.props.onClick)  this.props.onClick(this.props.id);
    }
}

OEFNParagraph.defaultProps = {
    id: '',
    className: '',
    iconCode: '',
    title: ''
};

OEFNParagraph.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNStdParagraphItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        var noIcon = this.props.iconCodeLeft === '' && this.props.iconCodeRight === '';

        return (
            <div className={'flow-nav-std-item ' + (noIcon ? 'no-icon ' : '') + this.props.className}>
                <div className="left" onClick={this.onClick}>
                    {this.props.iconCodeLeft === '' ? null : <OEIcon code={this.props.iconCodeLeft}/>}
                    {this.props.iconElementLeft}
                </div>

                <div className="main" onClick={this.onClick}>
                    <span>{this.props.title}</span>
                </div>

                <div className="right" onClick={this.onClick}>
                    {this.props.iconCodeRight === '' ? null : <OEIcon code={this.props.iconCodeRight}/>}
                    {this.props.iconElementRight}
                </div>
            </div>
        );
    }

    onClick()   {
        if(this.props.onClick)  this.props.onClick(this.props.id);
    }
}

OEFNStdParagraphItem.defaultProps = {
    id: '',
    className: '',
    iconCodeLeft: '',
    title: '',
    iconCodeRight: ''
};

OEFNStdParagraphItem.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCodeLeft: PropTypes.string,
    title: PropTypes.string,
    iconCodeRight: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNStdParagraphSubItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-std-sub-item ' + this.props.className}>
                <div className="left" onClick={this.onClick}>
                    {this.props.iconCode === '' ? null : <OEIcon code={this.props.iconCode}/>}
                    {this.props.iconElement}
                </div>

                <div className="main" onClick={this.onClick}>
                    {this.props.html ? <span dangerouslySetInnerHTML={{__html: this.props.html}}/> : <span>{this.props.title}</span>}
                </div>
            </div>
        );
    }

    onClick()   {
        if(this.props.onClick)  this.props.onClick(this.props.id);
    }
}

OEFNStdParagraphSubItem.defaultProps = {
    id: '',
    className: '',
    iconCode: '',
    title: ''
};

OEFNStdParagraphSubItem.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    iconCode: PropTypes.string,
    title: PropTypes.string,
    html: PropTypes.string,
    onClick: PropTypes.func
};

export class OEFNBlock extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        return (
            <div className={'flow-nav-block ' + this.props.className + (this.props.disabled ? ' disabled' : '')} style={this.props.style} onClick={this.onClick}>
                {this.props.html ? <span dangerouslySetInnerHTML={{__html: this.props.content}}/> : <span>{this.props.content}</span>}
                {this.props.children}
            </div>
        );
    }

    onClick()   {
        if(this.props.disabled) return;
        if(this.props.onClick)  this.props.onClick(this.props.id, this);
    }
}

OEFNBlock.defaultProps = {
    id: '',
    className: ''
};

OEFNBlock.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    html: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export class OEFNSeparator extends React.PureComponent {
    render() {
        return (
            <div className={'flow-nav-separator ' + this.props.className} />
        );
    }
}

OEFNSeparator.defaultProps = {
    className: ''
};

OEFNSeparator.propTypes = {
    className: PropTypes.string
};

export class OEFNView extends React.PureComponent {
    render() {
        return (
            <div className={'flow-nav-view ' + this.props.className}>
                <OEScrollbars>
                    <div className="flow-nav-view-content flow-nav-view-content-colors">
                        {this.props.children}
                    </div>
                </OEScrollbars>
            </div>
        );
    }
}

OEFNView.defaultProps = {
    className: ''
};

OEFNView.propTypes = {
    className: PropTypes.string
};

export class OESelectionBarCell extends React.PureComponent {

    constructor(props)  {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render()    {
        return (
            <div className={'flow-nav-selection-bar-cell' + (this.props.selected ? ' selected' : '') + (this.props.last ? ' last' : '')} onClick={this.onClick}>
                <div className="cell-border"/>
                <div className="container">
                    <div className="label">
                        {this.props.label}
                    </div>
                </div>
            </div>
        );
    }

    onClick(e) {
        if(this.props.onClick)  this.props.onClick(this);
    }
};

OESelectionBarCell.defaultProps = {};

OESelectionBarCell.propTypes = {
    label: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func
};

export class OEFNSelectionBar extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.onCellClick = this.onCellClick.bind(this);
    }
    
    renderCells()   {
        return !this.props.items ? null : this.props.items.map((item, index) => {
            let id = typeof(item.id) === 'string' ? item.id : index;
            return <OESelectionBarCell key={index} id={id} label={item.label} selected={id === this.props.selected} last={index === this.props.items.length} onClick={this.onCellClick}/>
        });
    }

    render() {
        return (
            <div className={'flow-nav-selection-bar ' + this.props.className + (this.props.disabled ? ' disabled' : '')}>
                {this.renderCells()}
            </div>
        );
    }

    onCellClick(sender) {
        if(this.props.disabled) return;
        if(this.props.onItemClick)  this.props.onItemClick(this, sender.props.id);
    }
}

OEFNSelectionBar.defaultProps = {
    className: ''
};

OEFNSelectionBar.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
    })),
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    onItemClick: PropTypes.func
};

const OEFNElement = {
    Spacer: OEFNSpacerElement,
    Menu: OEFNMenuElement,
    Checkbox: OEFNCheckboxElement,
    Hyperlink : OEFNHyperlinkElement,
    Chapter: OEFNChapter,
    Paragraph: OEFNParagraph,
    StdParagraphItem: OEFNStdParagraphItem,
    StdParagraphSubItem: OEFNStdParagraphSubItem,
    Block: OEFNBlock,
    Separator: OEFNSeparator,
    View: OEFNView,
    SelectionBar: OEFNSelectionBar
};

export default OEFNElement;