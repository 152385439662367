import React from 'react';

import {oeInterfaceManager} from '../react-oe/oe-interface';
import {UILevel} from './oe-types';
import {OEToolbox} from './oe-toolbox';

// to copy static class fields
export function copyComponentKeys(dst, src)    {
    Object.keys(src).forEach(key => {
        dst[key] = src[key];
    });
    return dst;
}

// translates the given object (obj) to the specified ui level
export function translateToUILevel(obj, uiLevel)    {
    if(!uiLevel || uiLevel === UILevel.std)  return obj;

    if(obj[uiLevel]) return {...obj, ...obj[uiLevel]};
        
    var res = {};
    Object.keys(obj).forEach(key => {
        const v = obj[key];
        res[key] = typeof(v) === 'object' && v !== null && v.hasOwnProperty(uiLevel) ? v[uiLevel] : v;
    });
    return res;
}

// translates the props of the component with respect to the provides uiLevel
export function withUILevel(WrappedComponent) {
    return copyComponentKeys(class extends React.PureComponent {

        render() {
            const uiLevel = this.props.uiLevel || UILevel.std;
            const props = translateToUILevel(this.props, uiLevel);
            return <WrappedComponent {...props}/>;
        }

    }, WrappedComponent);
}

// provides ui level as prop from core module
export function addUILevelProp(WrappedComponent) {
    return copyComponentKeys(class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.mounted = false;
            this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

            this.state = {
                uiLevel: UILevel.std
            };

            this.onConnect = this.onConnect.bind(this);
            this.onRelease = this.onRelease.bind(this);

            this.updateUILevel = this.updateUILevel.bind(this);
        }

        componentWillReceiveProps(nextProps) {
            if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
                this.release(); 
                this.connect(nextProps.moduleId);
            }
        }
    
        componentDidMount()    {
            this.mounted = true;
            this.connect();
        }
    
        componentWillUnmount()    {
            this.release();
            this.mounted = false;
        }
    
        connect(moduleId) {
            this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
            this.oe.register(this.onConnect, this.onRelease);
            if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
        }
    
        release()   {
            this.oe.unregister(this.onConnect, this.onRelease);
            if(this.oe.isReady())   {
                this.onRelease();
            } else {
                this.updateState();
            }   
        }
    
        onConnect()  {
            this.updateState();
            this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiLevelChanged, this.updateUILevel);
        }
    
        onRelease()    {
            this.updateState(true);
            this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiLevelChanged, this.updateUILevel);
        }

        updateUILevel() {
            this.setState({
                uiLevel: UILevel.levelFromModuleValue(this.oe.sharedInterface.getUIControllerSettings().getUILevel().value)
            });
        }
    
        updateState(released)   {
            if(!this.oe.isReady() || released === true) {
                //this.setState({ uiLevel: UILevel.std });  // do not switch back at disconnect, instead wait for new state when connecting
                return;
            }
    
            this.updateUILevel();
        }

        render() {
            return <WrappedComponent uiLevel={this.state.uiLevel} {...this.props} />;
        }

    }, WrappedComponent);
}

export function withAddedUILevel(WrappedComponent) {
    return addUILevelProp(withUILevel(WrappedComponent));
}

// wraps a component with an isOpen prop with an isOpen state and managing logic
export function withIsOpenState(WrappedComponent, onOpenStateChanged, onRefChanged) {
    let ret = copyComponentKeys(class extends React.PureComponent {

        constructor(props) {
            super(props);
            
            this.isOpen_ = false;
            this.state = {
                isOpen: this.isOpen_
            };

            this.setOpen = this.setOpen.bind(this);
            this.open = this.open.bind(this);
            this.close = this.close.bind(this);
            this.isOpen = this.isOpen.bind(this);

            this.openState = {setOpen: this.setOpen, open: this.open, close: this.close, isOpen: this.isOpen};

            this.onRef = this.onRef.bind(this);

            this.onToggle = this.onToggle.bind(this);
        }
    
        setOpen(open = true)    {
            if(typeof(this.props.isOpen) === 'boolean' || this.isOpen_ === open) return;
            this.isOpen_ =  open;
            this.setState({isOpen: this.isOpen_});
            this.onOpenStateChanged();
        }
    
        open()  {
            this.setOpen(true);
        }

        close() {
            this.setOpen(false);
        }

        isOpen()    {
            return this.isOpen_;
        }

        toggle()    {
            this.setOpen(!this.isOpen());
        }

        onOpenStateChanged()    {
            if(typeof(onOpenStateChanged) === 'function')  onOpenStateChanged(this, this.isOpen_);
            if(typeof(this.props.onOpenStateChanged) === 'function')  this.props.onOpenStateChanged(this, this.isOpen_);
        }

        isPresentable() {
            return this.ref ? (typeof(this.ref.isPresentable) === 'function' ? this.ref.isPresentable() : true) : false;
        }

        onRef(ref) {
            if(this.ref === ref)    return;
            this.ref = ref;
            if(typeof(onRefChanged) === 'function')  onRefChanged(this, ref);
        }
    
        render()    {
            const isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;
    
            return (
                <WrappedComponent ref={this.onRef} {...this.props} isOpen={isOpen} onToggle={this.onToggle} openState={this.openState}/>
            );
        }
    
        onToggle()    {
            if(typeof(this.props.onToggle) === 'function')   {
                this.props.onToggle();
            }
    
            this.setOpen(!this.state.isOpen);
        }

    }, WrappedComponent);

    ret.defaultProps = OEToolbox.filterObject(ret.defaultProps, null, ['isOpen']);

    return ret;
}

// passthrough of isOpen API to some wrapped component via fref prop 
export function delegateOpenState(WrappedComponent) {
    return copyComponentKeys(class extends React.PureComponent {

        constructor(props) {
            super(props);
            this.onRef = this.onRef.bind(this);
        }
    
        setOpen(open = true, options)   {
            if(this.ref && typeof(this.ref.setOpen) === 'function')  {
                this.ref.setOpen(open, options);
            } else {
                this.open_ = {open: open, options: options};
            }
        }
    
        open(options) {
            this.setOpen(true, options);
        }

        close() {
            this.setOpen(false);
        }

        isOpen()    {
            if(!this.ref) return false;
            if(typeof(this.ref.isOpen) === 'function')  {
                return this.ref.isOpen();
            } else if(OEToolbox.isClassComponent(WrappedComponent)) {
                return this.ref.state.isOpen;
            } else {
                return false;
            }
        }

        toggle()    {
            if(this.ref.toggle) {
                this.ref.toggle();
            } else {
                this.setOpen(!this.isOpen());
            }
        }
    
        onRef(ref) {
            if(this.props.fref) this.props.fref(ref);
            if(this.ref === ref) return;
            this.ref = ref;
            if(!ref || !this.open_) return;
            ref.setOpen(this.open_.open, this.open_.options);
            this.open_ = null;
        }
    
        render() {
            return <WrappedComponent {...this.props} fref={this.onRef}/>;
        }

    }, WrappedComponent);
}

// apply ref prop to fref to start ref forwarding via fref prop
export function frefFromRef(WrappedComponent) {
    return copyComponentKeys(React.forwardRef((props, ref) => (
        <WrappedComponent {...props} fref={ref}/>
    )), WrappedComponent);
}

// apply fref prop to ref to close ref forwarding via fref prop
export function frefToRef(WrappedComponent) {
    return copyComponentKeys(class extends React.PureComponent {

        render() {
            const {fref, ...rest} = this.props;
            return <WrappedComponent {...rest} ref={fref}/>;
        }

    }, WrappedComponent);
}

