import React from 'react';
import PropTypes from 'prop-types';

import Plyr from 'react-plyr';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OEManualViewLinks} from '../../../lib/oe-types';
import OEIcon from '../../elements/oe-icon';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEHelpViewEntryType} from '../../../lib/oe-types';
import {OENavigationViewComponent} from '../../../lib/oe-navigation-controller';
import OEResizeObserver from '../../../lib/oe-resize-observer';
import OEFNElement from '../../elements/oe-flow-nav-elements';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEFNHelp extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            tutorUIEnabled: false,
            tutorIsClear: true,
            strings: {
                advice: '',
                manual: '',
                videos: '',
                help: '',
                tutor: '',
                credits: '',
                feedbackLabel: '',
                feedbackEmail: ''
            }
        };

        this.stringMap = new function(){
            this[OEHelpViewEntryType.advice] = 'advice';
            this[OEHelpViewEntryType.manual] = 'manual';
            this[OEHelpViewEntryType.quickStart] = 'help';
            this[OEHelpViewEntryType.videoTutorials] = 'videos';
            this[OEHelpViewEntryType.tutorReset] = 'tutor';
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateTutorState = this.updateTutorState.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onItemClick = this.onItemClick.bind(this);
    }

    componentWillReceiveProps(nextProps)   {
        if(nextProps.locStringPrefix !== this.props.locStringPrefix)  {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.tutorIsClearStateChanged, this.updateTutorState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.tutorIsClearStateChanged, this.updateTutorState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage(props)   {
        props = props || this.props;
        let si = this.oe.sharedInterface;
        this.setState({strings: {
            advice: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_general_advice'),
            manual: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_manual'),
            videos: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_videos'),
            help: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_help'),
            tutor: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_reset_tutor'),
            credits: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_credits'),
            feedbackLabel: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_feedback_label'),
            feedbackEmail: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_feedback_email')
        }});
    }

    updateTutorState()  {
        this.setState({
            tutorIsClear: this.oe.sharedInterface.getUIControllerTutor().isClear()
        });
    }

    updateUIState()   {
        this.setState({
            tutorUIEnabled: this.oe.sharedInterface.getUIControllerTutor().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateTutorState();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type !== this.oe.Module.UIControllerType.tutor) return;
        this.updateUIState();
    }

    renderEntries() {
        let entries = [];
        for(let i = 0; i < this.props.entries.length; ++i)  {
            const entry = this.props.entries[i];
            if(entry.type === OEHelpViewEntryType.credits || entry.type === OEHelpViewEntryType.feedback) continue;
            if(i != 0)  entries.push(<OEFNElement.Spacer key={i.toString() + '_spacer'}/>);
            let enabled = entry.type === OEHelpViewEntryType.tutorReset ? this.state.tutorUIEnabled && !this.state.tutorIsClear : true;
            entries.push(
                <OEFNElement.Menu
                    key={i.toString() + '_element'}
                    iconCode={OEIconCodes.helpView[entry.type]}
                    title={this.state.strings[this.stringMap[entry.type]]}
                    enabled={enabled}
                    id={entry.type}
                    link={entry.link}
                    onClick={this.onItemClick}
                />
            );
        }
        return entries;
    }

    renderCreditsEntry() {
        const entry = this.props.entries.find(entry => entry.type === OEHelpViewEntryType.credits);
        if(!entry) return null;

        return (
            <React.Fragment>
                <OEFNElement.StdParagraphItem
                    className="credits"
                    title={this.state.strings.credits}
                    iconElementRight={
                        <img src="images/logo_effigos_white.png"/>
                    }
                />
                <OEFNElement.Spacer height="10px"/>
            </React.Fragment>
        );
    }

    renderFeedbackEntry() {
        const entry = this.props.entries.find(entry => entry.type === OEHelpViewEntryType.feedback);
        if(!entry) return null;

        return (
            <React.Fragment>
                <OEFNElement.Hyperlink
                    label={this.state.strings.feedbackLabel}
                    title={this.state.strings.feedbackEmail}
                    href={'mailto:' + this.state.strings.feedbackEmail}
                />
                <OEFNElement.Spacer height="28px"/>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFNElement.View className="help">
                    <OEFNElement.Spacer height={OEFNElement.Spacer.heights.top}/>
                    {this.renderEntries()}
                    <OEFNElement.Spacer height={OEFNElement.Spacer.heights.bottom}/>
                    {this.renderCreditsEntry()}
                    {this.renderFeedbackEntry()}
                </OEFNElement.View>

                <OENavigationViewComponent
                    navigationController={this.props.navigationController}
                    compId={'help_' + OEHelpViewEntryType.advice}
                    deferred={true}
                >
                    <OEFNHelpAdvice
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        navigationController={this.props.navigationController}
                        locStringPrefix={this.props.locStringPrefix}
                    />
                </OENavigationViewComponent>

                <OENavigationViewComponent
                    navigationController={this.props.navigationController}
                    title={this.props.locStringPrefix + 'help_view_videos'}
                    compId={'help_' + OEHelpViewEntryType.videoTutorials}
                    deferred={true}
                >
                    <OEFNHelpVideos
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        navigationController={this.props.navigationController}
                        locStringPrefix={this.props.locStringPrefix}
                    />
                </OENavigationViewComponent>

                <OENavigationViewComponent
                    navigationController={this.props.navigationController}
                    compId={'help_' + OEHelpViewEntryType.quickStart}
                    deferred={true}
                >
                    <OEFNHelpHandling
                        appComponent={this.props.appComponent}
                        moduleId={this.props.moduleId}
                        navigationController={this.props.navigationController}
                        locStringPrefix={this.props.locStringPrefix}
                    />
                </OENavigationViewComponent>

            </React.Fragment>
        );
    }

    onItemClick(id, sender) {
        if(id === OEHelpViewEntryType.advice)   {
            
            this.props.navigationController.push('help_' + OEHelpViewEntryType.advice);

        } else if(id === OEHelpViewEntryType.manual)   {

            if(!this.props.appComponent) return;
            this.props.appComponent.uiLayer.manualView.setOpen(true, {link: sender.props.link ? sender.props.link : OEManualViewLinks.app});

        } else if(id === OEHelpViewEntryType.videoTutorials)   {

            this.props.navigationController.push('help_' + OEHelpViewEntryType.videoTutorials);

        } else if(id === OEHelpViewEntryType.quickStart)   {

            this.props.navigationController.push('help_' + OEHelpViewEntryType.quickStart);

        } else if(id === OEHelpViewEntryType.tutorReset)   {
            if(!this.oe.isReady()) return;
            this.oe.sharedInterface.getUIControllerTutor().reset();
        }
    }
}

OEFNHelp.defaultProps = {
    moduleId: '',
    locStringPrefix: '',
    entries:  [
        {type: OEHelpViewEntryType.advice},
        //{type: OEHelpViewEntryType.manual, link: OEManualViewLinks.app},
        {type: OEHelpViewEntryType.quickStart},
        //{type: OEHelpViewEntryType.videoTutorials},
        {type: OEHelpViewEntryType.tutorReset}
    ]
};

OEFNHelp.propTypes = {
    moduleId: PropTypes.string,
    locStringPrefix: PropTypes.string,
    entries: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf([
            OEHelpViewEntryType.advice,
            OEHelpViewEntryType.manual,
            OEHelpViewEntryType.quickStart,
            OEHelpViewEntryType.videoTutorials,
            OEHelpViewEntryType.tutorReset,
            OEHelpViewEntryType.credits,
            OEHelpViewEntryType.feedback
        ]).isRequired
    })).isRequired
};

export class OEFNHelpAdvice extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                requirements_title: '',
                requirements_msg_1: '',
                requirements_msg_2: '',
                requirements_msg_3: '',
                requirements_msg_4: '',
                tutor_title: '',
                tutor_msg_1: '',
                tutor_msg_2: '',
                tutor_msg_3: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
    }

    componentWillReceiveProps(nextProps)   {
        if(nextProps.locStringPrefix !== this.props.locStringPrefix)  {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage(props)   {
        props = props || this.props;
        let si = this.oe.sharedInterface;
        this.setState({strings: {
            requirements_title: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_requirements_title'),
            requirements_msg_1: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_requirements_msg_1'),
            requirements_msg_2: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_requirements_msg_2'),
            requirements_msg_3: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_requirements_msg_3'),
            requirements_msg_4: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_requirements_msg_4'),
            tutor_title: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_tutor_title'),
            tutor_msg_1: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_tutor_msg_1'),
            tutor_msg_2: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_tutor_msg_2'),
            tutor_msg_3: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_ga_tutor_msg_3')
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFNElement.View>
                    <OEFNElement.Chapter
                        title={this.state.strings.requirements_title}
                    >
                        <OEFNElement.StdParagraphSubItem iconCode={OEIconCodes.helpView.dot} title={this.state.strings.requirements_msg_1} />
                        <OEFNElement.StdParagraphSubItem iconCode={OEIconCodes.helpView.dot} title={this.state.strings.requirements_msg_2} />
                        <OEFNElement.StdParagraphSubItem iconCode={OEIconCodes.helpView.dot} html={this.state.strings.requirements_msg_3} />
                        <OEFNElement.StdParagraphSubItem iconCode={OEIconCodes.helpView.dot} html={this.state.strings.requirements_msg_4} />
                    </OEFNElement.Chapter>

                    <OEFNElement.Chapter
                        title={this.state.strings.tutor_title}
                    >
                        <OEFNElement.StdParagraphSubItem iconCode={OEIconCodes.helpView.dot} title={this.state.strings.tutor_msg_1} />
                        <OEFNElement.StdParagraphSubItem iconCode={OEIconCodes.helpView.dot} title={this.state.strings.tutor_msg_2} />
                        <OEFNElement.StdParagraphSubItem iconCode={OEIconCodes.helpView.dot} html={this.state.strings.tutor_msg_3} />
                    </OEFNElement.Chapter>

                    <OEFNElement.Spacer height="16px"/>
                </OEFNElement.View>
            </React.Fragment>
        );
    }
}

OEFNHelpAdvice.defaultProps = {
    moduleId: '',
    locStringPrefix: ''
};

OEFNHelpAdvice.propTypes = {
    moduleId: PropTypes.string,
    locStringPrefix: PropTypes.string
};

export class OEFNHelpHandling extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                general: '',
                features: '',
                navigation: {
                    title: '',
                    rotate: '',
                    move: '',
                    zoom: '',
                    reset: ''
                },
                view: {
                    title: '',
                    context: '',
                    hide: '',
                    separate: '',
                    transparent: '',
                    distinguish: ''
                },
                reset: '',
                tree: {
                    title: '',
                    expand: '',
                    name: '',
                    color: '',
                    visibility: ''
                },
                label: '',
                cut: {
                    title: '',
                    quickCut: '',
                    all: '',
                    touched: '',
                    touch: '',
                    bySwipe: '',
                    swipe: '',
                    doubleClick: '',
                    shift: '',
                    reset: ''
                },
                colorize: '',
                explosion: '',
                mediaCenter: '',
                info: '',
                settings:    {
                    title: '',
                    language: '',
                    backgroundColor: '',
                    mouseSensitivity: '',
                    quality: ''
                },
                progress: '',
                back: '',
                forth: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
    }

    componentWillReceiveProps(nextProps)   {
        if(nextProps.locStringPrefix !== this.props.locStringPrefix)  {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage(props)   {
        props = props || this.props;
        let si = this.oe.sharedInterface;
        this.setState({strings: {
            general: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_general'),
            features: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_features'),
            navigation: {
                title: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_navigation_title'),
                rotate: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_navigation_rotate'),
                move: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_navigation_move'),
                zoom: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_navigation_zoom'),
                reset: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_navigation_reset')
            },
            view: {
                title: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_view_title'),
                context: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_view_context'),
                hide: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_view_hide'),
                separate: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_view_separate'),
                transparent: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_view_transparent'),
                distinguish: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_view_distinguish')
            },
            reset: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_reset'),
            tree: {
                title: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_tree_title'),
                expand: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_tree_expand'),
                name: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_tree_name'),
                color: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_tree_color'),
                visibility: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_tree_visibility')
            },
            label: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_label'),
            cut: {
                title: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_title'),
                quickCut: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_quick_cut'),
                all: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_all'),
                touched: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_touched'),
                touch: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_touch'),
                bySwipe: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_by_swipe'),
                swipe: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_swipe'),
                doubleClick: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_double_click'),
                shift: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_shift'),
                reset: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_cut_reset')
            },
            colorize: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_colorize'),
            explosion: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_explosion'),
            mediaCenter: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_media_center'),
            info: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_info'),
            settings:    {
                title: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_settings_title'),
                language: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_settings_language'),
                backgroundColor: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_settings_background_color'),
                mouseSensitivity: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_settings_mouse_sensitivity'),
                quality: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_settings_quality')
            },
            progress: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_progress'),
            back: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_back'),
            forth: si.getLocalizedStringEnc(props.locStringPrefix + 'help_view_handling_forth')
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    render() {
        const helpViewIcons = OEIconCodes.helpView.helpHandling;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFNElement.View>
                    <OEFNElement.Chapter
                        title={this.state.strings.general}
                    >
                        <OEFNElement.Paragraph
                            title={this.state.strings.navigation.title}
                        >
                            <OEFNElement.StdParagraphItem
                                iconCodeLeft={helpViewIcons.navigation.rotateTouch}
                                title={this.state.strings.navigation.rotate}
                                iconCodeRight={helpViewIcons.navigation.rotateMouse}
                            />
                            <OEFNElement.StdParagraphItem
                                iconCodeLeft={helpViewIcons.navigation.moveTouch}
                                title={this.state.strings.navigation.move}
                                iconCodeRight={helpViewIcons.navigation.moveMouse}
                            />
                            <OEFNElement.StdParagraphItem
                                iconCodeLeft={helpViewIcons.navigation.zoomTouch}
                                title={this.state.strings.navigation.zoom}
                                iconCodeRight={helpViewIcons.navigation.zoomMouse}
                            />
                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.view.title}
                        >
                            <OEFNElement.StdParagraphItem
                                className="sub-paragraph"
                                iconCodeLeft={helpViewIcons.view.contextTouch}
                                title={this.state.strings.view.context}
                                iconCodeRight={helpViewIcons.view.contextMouse}
                            />

                            <OEFNElement.StdParagraphSubItem
                                iconCode={OEIconCodes.contextMenuHide}
                                title={this.state.strings.view.hide}
                            />

                            <OEFNElement.StdParagraphSubItem
                                iconCode={OEIconCodes.contextMenuHideOther}
                                title={this.state.strings.view.separate}
                            />

                            <OEFNElement.StdParagraphSubItem
                                iconCode={OEIconCodes.contextMenuFade}
                                title={this.state.strings.view.transparent}
                            />

                            <OEFNElement.StdParagraphSubItem
                                iconCode={OEIconCodes.contextMenuFadeOther}
                                title={this.state.strings.view.distinguish}
                            />

                        </OEFNElement.Paragraph>
                    </OEFNElement.Chapter>
                    <OEFNElement.Chapter
                        title={this.state.strings.features}
                    >
                        <OEFNElement.Paragraph
                            title={this.state.strings.reset}
                            iconCode={OEIconCodes.home}
                        >
                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.tree.title}
                            iconCode={OEIconCodes.tree}
                        >
                            <OEFNElement.StdParagraphSubItem
                                title={this.state.strings.tree.expand}
                                iconElement={
                                    <div className="icon-element tree-expand">
                                        <OEIcon code={OEIconCodes.caretRight}/>
                                        {/*
                                        <OEIcon code={OEIconCodes.ctNode}/>
                                        */}
                                    </div>
                                }
                            />
                            <OEFNElement.StdParagraphSubItem
                                title={this.state.strings.tree.name}
                                iconElement={
                                    <div className="icon-element tree-name">
                                        vena
                                    </div>
                                }
                            />
                            {/*
                            <OEFNElement.StdParagraphSubItem
                                title={this.state.strings.tree.color}
                                iconElement={
                                    <div className="icon-element tree-color">
                                        <div></div>
                                    </div>
                                }
                            />
                            */}
                            <OEFNElement.StdParagraphSubItem
                                title={this.state.strings.tree.visibility}
                                iconElement={
                                    <div className="icon-element tree-rm">
                                        <button className="ct_btn ct_rm_btn" disabled={true}>
                                            <OEIcon className="button-visible" code={OEIconCodes.eye}/>
                                        </button>
                                    </div>
                                }
                            />

                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.label}
                            iconCode={OEIconCodes.label}
                        >
                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.cut.title}
                            iconCode={OEIconCodes.cut}
                        >
                            {/*
                            <OEFNElement.StdParagraphSubItem
                                className="sub-paragraph"
                                iconCode={OEIconCodes.cutToolTouch}
                                title={this.state.strings.cut.quickCut}
                            />

                            <OEFNElement.StdParagraphSubItem
                                iconCode={OEIconCodes.cutToolAll}
                                title={this.state.strings.cut.all}
                            />

                            <OEFNElement.StdParagraphSubItem
                                iconCode={OEIconCodes.cutToolTouched}
                                title={this.state.strings.cut.touched}
                            />

                            <OEFNElement.Spacer />
                            */}

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                iconCodeLeft={helpViewIcons.cut.touchTouch}
                                title={this.state.strings.cut.touch}
                                iconCodeRight={helpViewIcons.cut.touchMouse}
                            />

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                iconCodeLeft={helpViewIcons.cut.shiftTouch}
                                title={this.state.strings.cut.shift}
                                iconCodeRight={helpViewIcons.cut.shiftMouse}
                            />

                            <OEFNElement.StdParagraphSubItem
                                iconCode={OEIconCodes.reset}
                                title={this.state.strings.cut.reset}
                            />

                            {/*
                            <OEFNElement.Separator/>

                            <OEFNElement.StdParagraphSubItem
                                className="sub-paragraph"
                                iconCode={OEIconCodes.cutToolSwipe}
                                title={this.state.strings.cut.bySwipe}
                            />

                            <OEFNElement.Spacer />

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                iconCodeLeft={helpViewIcons.cut.swipeTouch}
                                title={this.state.strings.cut.swipe}
                                iconCodeRight={helpViewIcons.cut.swipeMouse}
                            />

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                iconCodeLeft={helpViewIcons.cut.doubleClickTouch}
                                title={this.state.strings.cut.doubleClick}
                                iconCodeRight={helpViewIcons.cut.doubleClickMouse}
                            />

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                iconCodeLeft={helpViewIcons.cut.shiftTouch}
                                title={this.state.strings.cut.shift}
                                iconCodeRight={helpViewIcons.cut.shiftMouse}
                            />
                            */}

                        </OEFNElement.Paragraph>

                        {/*
                        <OEFNElement.Paragraph
                            title={this.state.strings.colorize}
                            iconCode={OEIconCodes.color}
                        >
                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.explosion}
                            iconCode={OEIconCodes.explode}
                        >
                        </OEFNElement.Paragraph>
                        */}

                        <OEFNElement.Paragraph
                            title={this.state.strings.mediaCenter}
                            iconCode={OEIconCodes.mediaCenter}
                        >
                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.info}
                            iconCode={OEIconCodes.info/*infoQuestionMark*/}
                        >
                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.settings.title}
                            iconCode={OEIconCodes.settings}
                        >
                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                title={this.state.strings.settings.language}
                            />

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                title={this.state.strings.settings.backgroundColor}
                            />

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                title={this.state.strings.settings.mouseSensitivity}
                            />

                            <OEFNElement.StdParagraphItem
                                className="left-align"
                                title={this.state.strings.settings.quality}
                            />

                        </OEFNElement.Paragraph>

                    </OEFNElement.Chapter>

                    {/*
                    <OEFNElement.Chapter
                        title={this.state.strings.progress}
                    >
                        <OEFNElement.Paragraph
                            title={this.state.strings.back}
                            iconCode={OEIconCodes.stateBack}
                        >
                        </OEFNElement.Paragraph>

                        <OEFNElement.Paragraph
                            title={this.state.strings.forth}
                            iconCode={OEIconCodes.stateForth}
                        >
                        </OEFNElement.Paragraph>

                    </OEFNElement.Chapter>
                    */}

                    <OEFNElement.Spacer height="16px"/>
                </OEFNElement.View>
            </React.Fragment>
        );
    }
}

OEFNHelpHandling.defaultProps = {
    moduleId: '',
    locStringPrefix: ''
};

OEFNHelpHandling.propTypes = {
    moduleId: PropTypes.string,
    locStringPrefix: PropTypes.string
};

export class OEFNHelpVideos extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
            },
            videos: [],
            numColumn: 3
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onResize = this.onResize.bind(this);
    }

    componentWillReceiveProps(nextProps)   {
        if(nextProps.locStringPrefix !== this.props.locStringPrefix)  {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage(props)   {
        props = props || this.props;
        let si = this.oe.sharedInterface;
        var videos = [];
        for(var i = 1; i < 10; ++i) {
            videos.push(si.getLocalizedStringEnc(props.locStringPrefix + 'tutorial_video_' + i.toString() + '_youtube'));
        }

        this.setState({ videos: videos });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    onResize(sender, size)  {
        var numColumn = size.w > 600 ? 3 : (size.w > 400 ? 2 : 1);
        this.setState({numColumn: numColumn});
    }

    render() {

        var videos = this.state.videos.map((src, index) => 
            <Plyr
                key={index}
                className="react-plyr"
                type="youtube"
                videoId={src} 
                controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}
            />);

        var num = (Math.floor(videos.length / this.state.numColumn) + (videos.length % this.state.numColumn > 0 ? 1 : 0)) * this.state.numColumn;
        var videoElements = [];

        /*
        var numRows = num / this.state.numColumn;
        for(var row = 0; row < numRows; ++row)  {
            
            var videoRow = [];

            for(var column = 0; column < this.state.numColumn; ++column)    {
                var i = row * this.state.numColumn + column;
                videoRow.push(
                    <div key={i} className="video-item">
                        {i < videos.length ? videos[i] : null}
                    </div>
                );
            }

            videoElements.push(
                <div key={row} className="video-container">
                    {videoRow}
                </div>
            );
        }
        */

        var style = {width: (90 / this.state.numColumn).toString() + '%'};

        for(var i = 0; i < num; ++i)    {
            videoElements.push(
                <div key={i} className="video-item" style={style}>
                    {i < videos.length ? videos[i] : null}
                 </div>
            );
        }

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFNElement.View className="video">
                    <OEResizeObserver onResize={this.onResize}/>
                    {/*videoElements*/}
                    {
                    <div className="video-container">
                        {videoElements}
                    </div>}
                </OEFNElement.View>
            </React.Fragment>
        );
    }
}

OEFNHelpVideos.defaultProps = {
    moduleId: '',
    locStringPrefix: ''
};

OEFNHelpVideos.propTypes = {
    moduleId: PropTypes.string,
    locStringPrefix: PropTypes.string
};