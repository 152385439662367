export const OEMediaViewerContentType = {
    dummy: 'dummy',
    image: 'image',
    video: 'video',
    web: 'web',
    pdf: 'pdf'
};

export const OEMediaViewerPlaybackState = {
    pause: 'pause',
    play: 'play'
};

export const OEMediaViewerReadyState = {
    error: -1,
    noMeta: 0,
    waiting: 1,
    ready: 2
};

export const OEMediaViewerContentSizeMode = {
    std: 'std',
    fill: 'fill'
};

export const OEMediaViewerEvent = {
    mediaItemChanged: 'mediaItemChanged',
    readyStateChanged: 'readyStateChanged',
    playbackStateChanged: 'playbackStateChanged',
    timeChanged: 'timeChanged',
    durationChanged: 'durationChanged',
    progressChanged: 'progressChanged',
    fullscreenStateChanged: 'fullscreenStateChanged'
};

export class OEMediaViewerContent {

    constructor(type, path, autoPlayback, coverImagePath, backgroundColor, size, loop) {
        this.parameter = null;
        this.isPreloadEnabled = true;
        this.isCachingEnabled = true;

        this.type = type ? type : OEMediaViewerContentType.dummy;
        this.path = path;
        this.autoPlayback = typeof(autoPlayback) === 'boolean' ? autoPlayback : false;
        this.coverImagePath = coverImagePath;
        this.backgroundColor = backgroundColor || this.type !== OEMediaViewerContentType.pdf ? {x: 0, y: 0, z: 0, w: 1} : {x: 0, y: 0, z: 0, w: 0};
        this.size = size;
        this.loop = typeof(loop) === 'boolean' ? loop : false;
    }
};

export class OEMediaViewerDataSource {

    mediaViewerContentCount() {
        return 0;
    }

    mediaViewerContentForIndex(index)   {
        return null;
    }
};

export class OEMediaViewerDataSourceIntrinsic extends OEMediaViewerDataSource  {

    constructor(content) {
        super();
        this.content = content || new OEMediaViewerContent();
    }

    mediaViewerContentCount() {
        return 1;
    }

    mediaViewerContentForIndex(index)   {
        return this.content;
    }
};

export class OEMediaViewerDataSourceIntrinsicArray extends OEMediaViewerDataSource  {

    constructor(content) {
        super();
        this.content = content;
    }

    mediaViewerContentCount() {
        return this.content.length;
    }

    mediaViewerContentForIndex(index)   {
        return index < 0 || index >= this.content.length ? null : this.content[index];
    }
};