import React from 'react';
import PropTypes from 'prop-types';

import OECapBarFactory from './oe-cap-bar-factory';

export default class OECapBar extends React.PureComponent {
    render() {
        const {children, ...rest} = this.props;
        return (
            <div className={'cap-bar ' + (this.props.opaque ? 'opaque' : 'transparent')}>
                <OECapBarFactory {...rest}/>
                {children}
            </div>
        );
    }
}

OECapBar.defaultProps = {
    moduleId: '',
    opaque: false
};

OECapBar.propTypes = {
    moduleId: PropTypes.string,
    opaque: PropTypes.bool
};