import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {withUILevel} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {UIControllerType, OEManualViewLinks} from '../../lib/oe-types';
import OEPopover from '../oe-popover';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import {OEGroupControl, OEControl} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OECutControllerStyle} from '../../lib/oe-types';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {retardUpdate} from '../../lib/update-retarder';

export class OECutControllerStd extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            mode: 0,
            componentMode: 0,
            strings:   {
                title: '',
                modeOff: '',
                modeTouch: '',
                modeSwipe: '',
                options: '',
                componentModeAll: '',
                componentModeTapped: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateControlsState = this.updateControlsState.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onIsOpenStateChanged = this.onIsOpenStateChanged.bind(this);
        this.onPopoverRef = this.onPopoverRef.bind(this);
        this.renderAdditionalButtons = this.renderAdditionalButtons.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onResetBtnPressed = this.onResetBtnPressed.bind(this);
        this.onModeTouchBtnPressed = this.onModeTouchBtnPressed.bind(this);
        this.onModeSwipeBtnPressed = this.onModeSwipeBtnPressed.bind(this);
        this.onComponentModeAllBtnPressed = this.onComponentModeAllBtnPressed.bind(this);
        this.onComponentModeTappedBtnPressed = this.onComponentModeTappedBtnPressed.bind(this);
    }

    componentDidUpdate(prevProps)   {
        if(prevProps.config.showHelp !== this.props.config.showHelp) this.showHelpModalWhenOpen();
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.cutModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.cutComponentsModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.cutModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.cutComponentsModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        var si = this.oe.sharedInterface;
        this.setState({strings: {
            title: si.getLocalizedStringEnc('cut_view'),
            modeOff: si.getLocalizedStringEnc('cut_view_off'),
            modeTouch: si.getLocalizedStringEnc('cut_view_touch'),
            modeSwipe: si.getLocalizedStringEnc('cut_view_swipe'),
            options: si.getLocalizedStringEnc('cut_view_options'),
            componentModeAll: si.getLocalizedStringEnc('cut_view_all'),
            componentModeTapped: si.getLocalizedStringEnc('cut_view_tapped')
        }});
    }

    updateControlsState()   {
        var cut = this.oe.sharedInterface.getUIControllerCut();
        var mode = cut.getMode();
        var componentsMode = cut.getComponentsMode();

        var modeVal = mode.value;
        var componentsModeVal = componentsMode.value;
        
        componentsModeVal = componentsModeVal === 2 ? 1 : componentsModeVal;

        this.setState({
            mode: modeVal,
            componentMode: componentsModeVal
        });
    }

    updateUIState()   {
        var cut = this.oe.sharedInterface.getUIControllerCut();

        this.setState({ uiEnabled: cut.getUIEnabled() });

        if(cut.getUIVisible()) {
            if(cut.getMode() === this.oe.Module.CutMode.disabled)   {
                cut.setMode(this.oe.Module.CutMode.touch);
            }
        }
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateControlsState();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.cut) {
            this.updateUIState();
        }
    }

    onIsOpenStateChanged(sender, isOpen)  {
        if(isOpen) this.showHelpModalWhenOpen();
    }

    onPopoverRef(ref)   {
        if(this.popover === ref) return;
        this.popover = ref;
        if(this.popover) this.showHelpModalWhenOpen();
    }

    showHelpModalWhenOpen() {
        if(this.props.config.showHelp && this.popover && this.popover.isOpen() && localStorage.getItem('cutWidgetOpened') !== 'true')   {
            if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.cut});
            localStorage.setItem('cutWidgetOpened', 'true');
        }
    }

    renderAdditionalButtons(props)  {
        return (
            <OEButton disabled={!props.uiEnabled} className={props.buttonClassName + ' reset-btn'} onPressed={this.onResetBtnPressed}>
                <OEIcon code={OEIconCodes.reset} />
            </OEButton>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control"
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.cut}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    onIsOpenStateChanged={this.onIsOpenStateChanged}
                    title={this.state.strings.title}
                    additionalButtons={this.renderAdditionalButtons}
                    uiEnabled={this.state.uiEnabled}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                    ref={this.onPopoverRef}
                >

                    <div className="cut-controller">

                        <OEGroupControl><OEControl>

                            <OEButton 
                                disabled={!this.state.uiEnabled} 
                                activated={this.state.mode === 1} 
                                className="iconTextBtn cc-button transparent-btn"
                                onPressed={this.onModeTouchBtnPressed}
                            ><span><OEIcon code={OEIconCodes.cutToolTouch}/></span><span>{this.state.strings.modeTouch}</span></OEButton>

                            <OEButton 
                                disabled={!this.state.uiEnabled} 
                                activated={this.state.mode === 2} 
                                className="iconTextBtn cc-button transparent-btn"
                                onPressed={this.onModeSwipeBtnPressed}
                            ><span><OEIcon code={OEIconCodes.cutToolSwipe}/></span><span>{this.state.strings.modeSwipe}</span></OEButton>

                        </OEControl></OEGroupControl>

                        <OEGroupControl 
                            className="option-group_"
                            headerClassName="std-transparent-btn_"
                            title={this.state.strings.options}
                            type={OEGroupControl.type.fullCollapsable}
                            collapsed={this.state.mode !== 1}
                            topSeparator={true}
                        ><OEControl>

                            <OEButton 
                                disabled={!(this.state.uiEnabled && this.state.mode === 1)} 
                                activated={this.state.componentMode === 0} 
                                className="iconTextBtn cc-button transparent-btn option left"
                                onPressed={this.onComponentModeAllBtnPressed}
                            ><span><OEIcon code={OEIconCodes.cutToolAll}/></span><span>{this.state.strings.componentModeAll}</span></OEButton>

                            <OEButton 
                                disabled={!(this.state.uiEnabled && this.state.mode === 1)} 
                                activated={this.state.componentMode === 1} 
                                className="iconTextBtn cc-button transparent-btn option right"
                                onPressed={this.onComponentModeTappedBtnPressed}
                            ><span><OEIcon code={OEIconCodes.cutToolTouched}/></span><span>{this.state.strings.componentModeTapped}</span></OEButton>

                        </OEControl></OEGroupControl>

                    </div>

                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.cut});
    }

    onResetBtnPressed() {
        this.oe.sharedInterface.getUIControllerCut().resetCut();
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceCutResetBtnPressed, null);
    }

    onModeTouchBtnPressed() {
        //this.oe.sharedInterface.getUIControllerCut().setMode(this.state.mode === 1 ? this.oe.Module.CutMode.disabled : this.oe.Module.CutMode.touch);
        this.oe.sharedInterface.getUIControllerCut().setMode(this.oe.Module.CutMode.touch);
    }

    onModeSwipeBtnPressed() {
        //this.oe.sharedInterface.getUIControllerCut().setMode(this.state.mode === 2 ? this.oe.Module.CutMode.disabled : this.oe.Module.CutMode.swipe);
        this.oe.sharedInterface.getUIControllerCut().setMode(this.oe.Module.CutMode.swipe);
    }

    onComponentModeAllBtnPressed()  {
        if(this.state.mode !== 1) return;
        this.oe.sharedInterface.getUIControllerCut().setComponentsMode(this.oe.Module.CutComponentsMode.all);
    }

    onComponentModeTappedBtnPressed()  {
        if(this.state.mode !== 1) return;
        this.oe.sharedInterface.getUIControllerCut().setComponentsMode(this.oe.Module.CutComponentsMode.tapped);
    }
}

OECutControllerStd.defaultProps = {
    moduleId: '',
    target: ''
};

OECutControllerStd.propTypes = {
    moduleId: PropTypes.string
};

export class OECutControllerMinimal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            mode: 0,
            componentMode: 0,
            strings:   {}
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateControlsState = this.updateControlsState.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onResetBtnPressed = this.onResetBtnPressed.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.cutModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.cutComponentsModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.cutModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.cutComponentsModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        var si = this.oe.sharedInterface;
        this.setState({strings: {}});
    }

    updateControlsState()   {
        var cut = this.oe.sharedInterface.getUIControllerCut();
        var mode = cut.getMode();
        var componentsMode = cut.getComponentsMode();

        var modeVal = mode.value;
        var componentsModeVal = componentsMode.value;
        
        componentsModeVal = componentsModeVal === 2 ? 1 : componentsModeVal;

        this.setState({
            mode: modeVal,
            componentMode: componentsModeVal
        });
    }

    updateUIState()   {
        var cut = this.oe.sharedInterface.getUIControllerCut();

        this.setState({ uiEnabled: cut.getUIEnabled() });

        if(cut.getUIVisible()) {
            if(cut.getMode() === this.oe.Module.CutMode.disabled)   {
                cut.setMode(this.oe.Module.CutMode.touch);
            }
        }
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateControlsState();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.cut) {
            this.updateUIState();
        }
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control"
                    placement="right-start"
                    hideArrow={true}
                    noHeader={true}
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.cut}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                >

                    <div className="cut-controller-minimal">
                        <OEButton disabled={!this.state.uiEnabled} className="transparent-btn main-menu-button reset-btn" onPressed={this.onResetBtnPressed}>
                            <OEIcon code={OEIconCodes.reset} />
                        </OEButton>
                    </div>

                </OEPopover>
            </React.Fragment>
        );
    }

    onResetBtnPressed() {
        this.oe.sharedInterface.getUIControllerCut().resetCut();

        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceCutResetBtnPressed, null);
    }
}

OECutControllerMinimal.defaultProps = {
    moduleId: '',
    target: ''
};

OECutControllerMinimal.propTypes = {
    moduleId: PropTypes.string
};

export class OECutController extends React.PureComponent {
    render() {
        var config = this.props.config;
        if(!config.enabled) return null;
        return (config.style !== OECutControllerStyle.minimal ? <OECutControllerStd {...this.props} /> : <OECutControllerMinimal {...this.props} />);
    }
}

OECutController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.cutController()
};

OECutController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelp: PropTypes.bool,
        showHelpBtn: PropTypes.bool,
        style: PropTypes.oneOf([OECutControllerStyle.std, OECutControllerStyle.minimal])
    }).isRequired
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.cutController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.cut)
    }
})(withUILevel(OECutController));