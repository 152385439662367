import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {frefFromRef} from '../../../lib/oe-higher-order-components';
import {OEFNWelcomeEntryTypes} from '../../../lib/oe-types';
import {OENavigationViewComponent} from '../../../lib/oe-navigation-controller';
import OEFlowNavModal from './oe-flow-nav-modal';
import OEFNWelcome from './oe-flow-nav-welcome';
import OEFNHelp from './oe-flow-nav-help';

export class OEFNStartView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.options = this.props.options;

        this.ref = null;

        this.state = {
            options: this.options,
            showCloseBtnAllways: this.options === 'help' || this.props.showCloseBtnAllways,
        };

        this.onRef = this.onRef.bind(this);

        this.onOptionsChanged = this.onOptionsChanged.bind(this);
    }

    updateShowNavButtonAllways(props) {
        props = props || this.props;
        this.setState({showCloseBtnAllways: this.options === 'help' || this.props.showCloseBtnAllways});
    }

    onRef(ref)  {
        if(this.ref !== ref) {
            this.ref = ref;
            this.setState({parent: ref});

            if(ref && this.options !== ref.options)    {
                this.options = ref.options;
                this.setState({options: this.options});
            }
        }
        if(this.props.fref) this.props.fref(ref);
    }

    renderViewComponent()   {
        let hasUndeferredChilds = false;
        React.Children.forEach(this.props.children, (child) => {
            if(!child.props.deferred)   hasUndeferredChilds = true;
        });
        if(hasUndeferredChilds) return null;

        if(this.state.options === 'help')   {
            return (
                <OENavigationViewComponent>
                    <OEFNHelp appComponent={this.props.appComponent} moduleId={this.props.moduleId}/>
                </OENavigationViewComponent>
            );
        }

        return (
            <OENavigationViewComponent title="welcome_view_title">
                <OEFNWelcome
                    appComponent={this.props.appComponent}
                    moduleId={this.props.moduleId}
                    locStringPrefix={this.props.locStringPrefix}
                    entries={this.props.entries}
                    parent={this.state.parent}
                />
            </OENavigationViewComponent>
        );
    }

    render() {
        const {fref, onOptionsChanged, showCloseBtnAllways, locStringPrefix, entries, className, ...rest} = this.props;

        return (
            <OEFlowNavModal
                {...rest}
                className={'start-view ' + className}
                ref={this.onRef}
                onOptionsChanged={this.onOptionsChanged}
                showCloseBtnAllways={this.state.showCloseBtnAllways}
            >
                {this.renderViewComponent()}
                {this.props.children}
            </OEFlowNavModal>
        );
    }

    onOptionsChanged(sender, options)  {
        if(this.options !== options)    {
            this.options = options;
            this.setState({options: this.options});
        }
        if(this.props.onOptionsChanged) this.props.onOptionsChanged(sender, options);
    }
}

OEFNStartView.defaultProps = Object.assign({}, OEFlowNavModal.defaultProps, {
    locStringPrefix: '',
    entries:  [
        {type: OEFNWelcomeEntryTypes.spacer, height: 52},
        {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text', mask: {licenseValid: true}},
        {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text_no_license', mask: {licenseValid: false}},
        {type: OEFNWelcomeEntryTypes.spacer, height: 32},
        {type: OEFNWelcomeEntryTypes.licensing, mask: {licenseValid: false}},
        {type: OEFNWelcomeEntryTypes.home, stringId: 'welcome_view_home_v2'},
        {type: OEFNWelcomeEntryTypes.mediaCenter, stringId: 'welcome_view_pres_v2'},
        {type: OEFNWelcomeEntryTypes.quickStart},
        {type: OEFNWelcomeEntryTypes.manual},
        {type: OEFNWelcomeEntryTypes.spacer, height: 20},
        {type: OEFNWelcomeEntryTypes.dontShowCheckbox, noSubtitle: true, stringId: 'welcome_view_dont_show_again_title_v2'},
        {type: OEFNWelcomeEntryTypes.spacer, height: 8}
    ]
});

OEFNStartView.propTypes = Object.assign({}, OEFlowNavModal.propTypes, {
    locStringPrefix: PropTypes.string,
    entries: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf([
            OEFNWelcomeEntryTypes.text,
            OEFNWelcomeEntryTypes.menu,
            OEFNWelcomeEntryTypes.home,
            OEFNWelcomeEntryTypes.licensing,
            OEFNWelcomeEntryTypes.mediaCenter,
            OEFNWelcomeEntryTypes.help,
            OEFNWelcomeEntryTypes.quickStart,
            OEFNWelcomeEntryTypes.manual,
            OEFNWelcomeEntryTypes.spacer,
            OEFNWelcomeEntryTypes.dontShowCheckbox
        ]).isRequired,
        id: PropTypes.string,
        stringId: PropTypes.string,
        string: PropTypes.string,
        html: PropTypes.bool,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        logo: PropTypes.string,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        noSubtitle: PropTypes.bool,
        fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        mask: PropTypes.shape({
            licenseValid: PropTypes.bool
        })
    })),
});

export default frefFromRef(connectAppEnv((env) => { return {
    appComponent: env.component
}})(OEFNStartView));