import React from 'react';
import PropTypes from 'prop-types';
import {TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEScrollbars from '../oe-scrollbars';
import OEColor from '../color-picker/oe-color';
import {retardUpdate} from '../../lib/update-retarder';

const OEContextMenuInfoBoxTabs = {
    basic: 'basic',
    advanced: 'advanced',
    current: 'current',
    references: 'references',
    array: ['basic', 'advanced', 'current', 'references'],
    arrayC: ['Basic', 'Advanced', 'Current', 'References']
};

export default class OEContextMenuInfoBox extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            hasContent: false,
            activeTab: OEContextMenuInfoBoxTabs.basic,
            strings: {
                basic: {
                    title: 'Basic',
                    content: ''
                },
                advanced: {
                    title: 'Advanced',
                    content: ''
                },
                current: {
                    title: 'Current research',
                    content: ''
                },
                references: {
                    title: 'References',
                    content: ''
                }
            }
        }

        this.onLanguageChanged = this.onLanguageChanged.bind(this);

        this.onNavLink = this.onNavLink.bind(this);

        for(let i = 0; i < 4; ++i)	{
            const iloc = i; // use new local variable here instead of i, otherwise i gets captured as a reference in the function and will have the value 3 at execution time 
            this['onNavLink' + OEContextMenuInfoBoxTabs.arrayC[i]] = () => { this.onNavLink(OEContextMenuInfoBoxTabs.array[iloc]); };
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.componentId !== this.props.componentId)     {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
    }
    
    onLanguageChanged()	{
        this.updateLanguage();
    }

    updateLanguage(props)	{
        const si = this.oe.sharedInterface;
        const compId = (props || this.props).componentId || '';
        let basic = si.getLocalizedStringRawEnc('infobox_' + compId + '_l1');
        let advanced = si.getLocalizedStringRawEnc('infobox_' + compId + '_l2');
        let current = si.getLocalizedStringRawEnc('infobox_' + compId + '_l3');
        let references = si.getLocalizedStringRawEnc('infobox_' + compId + '_ref');

        this.setState({
            hasContent: basic || advanced || current,
            strings: {
                basic: {
                    title: si.getLocalizedStringEnc('infobox_componentinfo_header_l1'),
                    content: basic ? basic : si.getLocalizedStringEnc('infobox_default_l1')
                },
                advanced: {
                    title: si.getLocalizedStringEnc('infobox_componentinfo_header_l2'),
                    content: advanced ? advanced : si.getLocalizedStringEnc('infobox_default_l2')
                },
                current: {
                    title: si.getLocalizedStringEnc('infobox_componentinfo_header_l3'),
                    content: current ? current : si.getLocalizedStringEnc('infobox_default_l3')
                },
                references: {
                    title: si.getLocalizedStringEnc('infobox_componentinfo_header_ref'),
                    content: references ? references : ''
                }
            }           
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    renderContent() {
        if(!this.state.hasContent) return null;
        let itemCount = this.props.showReferences ? 4 : 3;


        let contentStyle = {
            backgroundColor: OEColor.toDOMStr(this.props.textColor),
            color: OEColor.toDOMStr(this.props.backgroundColor)
        };

        let navItemStyle = {
            width: (100 / itemCount).toString() + "%"
        }

        let navLinkStyleActive = {
            backgroundColor: contentStyle.backgroundColor,
            color: contentStyle.color,
            borderColor: 'rgba(0, 0, 0, 0)'
        };

        let navItems = [], content = [];

        for(let i = 0; i < itemCount; ++i)	{
            const tab = OEContextMenuInfoBoxTabs.array[i];
            navItems.push(
                <NavItem key={i} style={navItemStyle}>
                    <NavLink
                        className={this.state.activeTab === tab ? 'active ' : 'transparent-btn std-border'}
                        style={this.state.activeTab === tab ? navLinkStyleActive : null}
                        onClick={this['onNavLink' + OEContextMenuInfoBoxTabs.arrayC[i]]}
                    >
                        {this.state.strings[tab].title}
                    </NavLink>
                </NavItem>
            );

            content.push(
                <TabPane tabId={tab} key={i}>
                    <Row>
                        <Col>
                            <div dangerouslySetInnerHTML={{__html: this.state.strings[tab].content}}/>
                            {/*this.state.strings[tab].content*/}
                        </Col>
                    </Row>
                </TabPane>
            );
        }

        return (
            <div className="info-box">
                <div style={{color: OEColor.toDOMStr(this.props.textColor)}}>
                    <Nav tabs>{navItems}</Nav>
                </div>
                    
                <div 
                    className="content-container"
                    style={contentStyle}
                >	
                    <OEScrollbars>		
                        <TabContent activeTab={this.state.activeTab} className="text">
                            {content}
                        </TabContent>
                    </OEScrollbars>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                {this.renderContent()}
            </React.Fragment>
        );
    }

    onNavLink(tab) {
        this.setState({activeTab: tab});
    }
}

OEContextMenuInfoBox.defaultProps = {
    moduleId: '',
    showReferences: false,
    backgroundColor: {x: 0, y: 0, z: 0.5, w: 0.8},
    textColor: {x: 1, y: 1, z: 1, w: 1}
};

OEContextMenuInfoBox.propTypes = {
    moduleId: PropTypes.string,
    showReferences: PropTypes.bool,
    backgroundColor: PropTypes.object,
    textColor: PropTypes.object
};