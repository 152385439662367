import React from 'react';
import PropTypes from 'prop-types';

const OEIconVAlignMap = new Map([
    ['\uF2A9', 'middle'],        // animation icon
    ['\u2004', 'middle'],        // other animation icon
    ['\u2002', 'middle']
]);

export default class OEIcon extends React.PureComponent {

    constructor(props)  {
        super(props);
        this.state = {
            className: this.getClassName()
        };
    }

    getClassName(props) {
        props = props || this.props;
        let ret = 'icon effigos-symbol ';
        let align = OEIconVAlignMap.get(props.code);
        if(align)   ret = ret + 'valign-' + align + ' ';
        ret += props.className;
        return ret;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.className != this.props.className || nextProps.code != this.props.code)     {
            this.setState({className: this.getClassName(nextProps)})
        }
    }

    render()    {
        return (
            <div className={this.state.className} style={this.props.style}>
                <div>
                    {this.props.code}{this.props.children}
                </div>
            </div>
        );
    }
}

OEIcon.defaultProps = {
    className: '',
    code: ''
};

OEIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    code: PropTypes.string
};