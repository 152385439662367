import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEToolbox} from '../../../lib/oe-toolbox';

export class OEPresentationItemRaw extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            index: typeof(this.props.index) !== 'number' ? null : OEToolbox.numberToString(this.props.index + 1, 3)
        };

        this.onRef = this.onRef.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.id !== this.props.id)  {
            this.props.elementRef(null, this.props.id);
            this.props.elementRef(this.ref, nextProps.id);
        }

        if(nextProps.index !== this.props.index)    {
            this.setState({index: typeof(nextProps.index) !== 'number' ? null : OEToolbox.numberToString(nextProps.index + 1, 3)});
        }
    }

    onRef(ref) {
        if(this.ref === ref) return;
        this.ref = ref;
        this.props.elementRef(ref, this.props.id);
    }

    renderContent() {
        if(!this.props.renderContent) {
            return <div style={{backgroundImage: 'url(' + this.props.img + ')'}}/>;
        }
        const {elementRef, ...rest} = this.props;
        return this.props.renderContent(rest);
    }

    render() {
        return (
            <li
                id={this.props.id}
                className={'presentation-item ' + this.props.className + (this.props.selected ? ' selected' : '')}
                style={this.props.style}
                ref={this.onRef}
                onClick={this.onClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {this.renderContent()}
                {this.props.showIndex && this.state.index ? <span className="index-label">{this.state.index}</span> : null}
            </li>
        );
    }

    onClick()   {
        if(!this.props.disabled)    this.props.onClick(this.props.id);
    }

    onMouseEnter()  {
        this.props.onMouseEnter(this.props.id);
    }

    onMouseLeave()  {
        this.props.onMouseLeave(this.props.id);
    }
}

OEPresentationItemRaw.defaultProps = {
    className: '',
    style: null,
    disabled: false,
    selected: false,
    img: '',
    showIndex: false,
    elementRef: function(ref, id)   {},
    onClick: function(id) {},
    onMouseEnter: function(id) {},
    onMouseLeave: function(id) {}
};

OEPresentationItemRaw.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    img: PropTypes.string,
    index: PropTypes.number,
    showIndex: PropTypes.bool,
    elementRef: PropTypes.func,
    renderContent: PropTypes.func,  //function(props) props without elementRef
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};

export default class OEPresentationItem extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.presID = this.props.presID;
        this.slideID = this.props.slideID;
        this.thumbID = {pres: -1, slide: -1};

        this.state = {
            img: null
        };

        this.onSlideSet = this.onSlideSet.bind(this);
        this.onPresentationSlideAdded = this.onPresentationSlideAdded.bind(this);
        this.onPresentationSlideRemoved = this.onPresentationSlideRemoved.bind(this);
        this.onPresentationSlideMoved = this.onPresentationSlideMoved.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.presID !== this.props.presID || nextProps.slideID !== this.props.slideID)     {
            this.presID = nextProps.presID;
            this.slideID = nextProps.slideID;
            this.updateImage(false, nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideSet, this.onSlideSet);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideRemoved, this.onPresentationSlideRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideMoved, this.onPresentationSlideMoved);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideSet, this.onSlideSet);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideRemoved, this.onPresentationSlideRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideMoved, this.onPresentationSlideMoved);
    }

    updateImage(force, props)   {
        props = props || this.props;

        if(!this.oe.isReady() || props.presID < 0)  {
            this.thumbID = {pres: -1, slide: -1};
            this.setState({img: null});
            return;
        }

        let presentation = this.oe.sharedInterface.getUIControllerPresentation();
        let slideID = props.slideID;
        if(slideID < 0) {
            let iPres = presentation.getPresentationIndexForID(props.presID);
            slideID = presentation.getSlideID(iPres, 0);
            if(slideID < 0) {
                this.thumbID = {pres: -1, slide: -1};
                this.setState({img: null});
                return;
            }
        }

        if(this.thumbID.pres === props.presID && this.thumbID.slide === slideID && !force)   return;

        this.thumbID = {pres: props.presID, slide: slideID};

        this.oe.sharedInterface.getUIControllerPresentation().getThumbnailID(this.thumbID.pres, slideID, (thumb, presID, slideID) => {
            if(this.thumbID.pres !== presID || this.thumbID.slide !== slideID)   return;
            this.setState({img: thumb});
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.thumbID = {pres: -1, slide: -1};
            this.setState({img: null});
            return;
        }
        
        retardUpdate(this, () => {
            this.updateImage();
        });
    }

    onSlideSet(message, userInfo)    {
        if(this.presID < 0 || this.presID !== userInfo.presID || (this.thumbID.pres >= 0 && this.thumbID.pres !== userInfo.presID) || (this.thumbID.slide >= 0 && this.thumbID.slide !== userInfo.slideID)) return;
        this.updateImage(true);
    }

    onPresentationSlideAdded(message, userInfo)    {
        if(this.presID < 0 || this.presID !== userInfo.presID || (this.thumbID.pres >= 0 && this.thumbID.pres !== userInfo.presID) || this.slideID >= 0 || userInfo.slide > 0) return;
        this.updateImage(true);
    }

    onPresentationSlideRemoved(message, userInfo)    {
        this.onPresentationSlideAdded(message, userInfo);
    }
    
    onPresentationSlideMoved(message, userInfo)    {
        if(this.presID < 0 || this.slideID >= 0 || (userInfo.slideSrc !== 0 && userInfo.slideDst !== 0))    return;

        if((userInfo.slideSrc === 0 && this.thumbID.pres === userInfo.presSrcID) || (userInfo.slideDst === 0 && this.thumbID.pres === userInfo.presDstID))    {
            this.updateImage(true);
        }
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPresentationItemRaw {...this.props} img={this.state.img || this.props.img}/>
            </React.Fragment>
        );
    }
}

OEPresentationItem.defaultProps = {
    className: '',
    style: null,
    moduleId: '',
    disabled: false,
    selected: false,
    presID: -1,
    slideID: -1,
    showIndex: false,
    elementRef: function(ref, id)   {},
    onClick: function(id) {},
    onMouseEnter: function(id) {},
    onMouseLeave: function(id) {}
};

OEPresentationItem.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    img: PropTypes.string,
    presID: PropTypes.number,
    slideID: PropTypes.number,
    index: PropTypes.number,
    showIndex: PropTypes.bool,
    elementRef: PropTypes.func,
    renderContent: PropTypes.func,  //function(props) props without elementRef
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};