import React from 'react';
import PropTypes from 'prop-types';
import {VelocityTransitionGroup} from 'velocity-react';

import {connectModuleEnv} from '../oe-module-env';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import {OECustomCheckbox} from '../elements/oe-checkbox';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {retardUpdate} from '../../lib/update-retarder';
import {OEToolbox} from '../../lib/oe-toolbox';

export class OETutorController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.data = null;
        this.currentChapter = -1;
        this.currentParagraph = -1;
        this.currentChapterData = null;

        this.state = {
            uiEnabled: false,
            uiVisible: false,
            enabled: false,
            currentChapter: this.currentChapter,
            currentParagraph: this.currentParagraph,
            numParagraphs: 0,
            chapter: {enabled: true, completed: false, paragraphs: []},
            content: {
                icon: '',
                title: '',
                message: ''
            },
            strings: {
                disabledChapter: "Don't show again"
            }
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onTutorStateChanged = this.onTutorStateChanged.bind(this);
        this.onCurrentChapterChanged = this.onCurrentChapterChanged.bind(this);
        this.onCurrentParagraphChanged = this.onCurrentParagraphChanged.bind(this);
        this.onChapterStateChanged = this.onChapterStateChanged.bind(this);
        this.onParagraphStateChanged = this.onParagraphStateChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onCloseBtnPressed = this.onCloseBtnPressed.bind(this);
        this.onBackwardBtnPressed = this.onBackwardBtnPressed.bind(this);
        this.onForwardBtnPressed = this.onForwardBtnPressed.bind(this);
        this.onDisableChapterClicked = this.onDisableChapterClicked.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.tutorStateChanged, this.onTutorStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.tutorCurrentChapterChanged, this.onCurrentChapterChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.tutorCurrentParagraphChanged, this.onCurrentParagraphChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.tutorChapterStateChanged, this.onChapterStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.tutorParagraphStateChanged, this.onParagraphStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.tutorStateChanged, this.onTutorStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.tutorCurrentChapterChanged, this.onCurrentChapterChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.tutorCurrentParagraphChanged, this.onCurrentParagraphChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.tutorChapterStateChanged, this.onChapterStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.tutorParagraphStateChanged, this.onParagraphStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        if(!this.chapterContent || !this.paragraphContent) return;
        if(this.currentChapter < 0) return; // do not update content
        this.setState({
            content: {
                icon: this.chapterContent.icon,
                title: this.chapterContent.title.get(),
                message: this.paragraphContent.message.get()
            },
            strings: {
                disabledChapter: this.oe.sharedInterface.getLocalizedStringEnc('tutor_disabled_chapter')
            }
        });
    }

    updateTutorState(enabled)   {
        let enabled_ = typeof(enabled) === 'boolean' ? enabled : this.oe.sharedInterface.getUIControllerTutor().getEnabled();
        if(this.data) this.data.enabled = enabled_;
        this.setState({enabled: this.data.enabled});
    }

    updateChapterState()    {
        let paragraphs = [];
        if(this.currentChapterData) {
            for(let i = 0; i < this.currentChapterData.paragraphs.length; ++i)   {
                paragraphs.push({completed: this.currentChapterData.paragraphs[i].completed});
            }
        }     

        this.setState({
            currentChapter: this.currentChapter,
            currentParagraph: this.currentParagraph,
            numParagraphs: this.currentChapterData ? this.currentChapterData.paragraphs.length : 0,
            chapter: this.currentChapterData ? { enabled: this.currentChapterData.enabled, completed: this.currentChapterData.completed, paragraphs: paragraphs} : 
                { enabled: true, completed: false, paragraphs: paragraphs},
        });
    }

    updateCurrentChapter(chapter)   {
        let currentChapter = typeof(chapter) === 'number' ? chapter : this.oe.sharedInterface.getUIControllerTutor().getCurrentChapter();
        this.currentChapter = currentChapter;
        this.currentChapterData = currentChapter >= 0 ? this.data.chapters[this.currentChapter] : null;
        this.currentParagraph = this.currentChapterData ? this.currentChapterData.currentParagraph : -1;

        if(this.currentChapter >= 0)    {
            this.updateChapterState();
        }

        this.updateContent();
    }

    updateCurrentParagraph(chapter, paragraph)   {
        let chapterData = this.data.chapters[chapter];
        chapterData.currentParagraph = paragraph;

        if(chapter !== this.currentChapter) return;
        this.updateCurrentChapter(this.currentChapter);
    }

    updateContent() {
        this.chapterContent = this.oe.sharedInterface.getUIControllerTutor().getChapterContent(this.currentChapter);
        this.paragraphContent = this.oe.sharedInterface.getUIControllerTutor().getParagraphContent(this.currentChapter, this.currentParagraph);
        this.updateLanguage();
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerTutor().getUIEnabled(),
            uiVisible: this.oe.sharedInterface.getUIControllerTutor().getUIVisible()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.data = null;
            this.setState({ uiVisible: false, uiEnabled: false, enabled: false });
            return;
        }

        this.data = this.oe.sharedInterface.getUIControllerTutor().getTutorData();
        
        retardUpdate(this, () => {
            this.updateTutorState(this.data.enabled);
            this.updateCurrentChapter();
            this.updateUIState();
        });
    }

    onTutorStateChanged(message, userInfo)    {
        this.updateTutorState(userInfo.enabled);
    }

    onCurrentChapterChanged(message, userInfo)    {
        this.updateCurrentChapter(userInfo.chapter);
    }

    onCurrentParagraphChanged(message, userInfo)    {
        this.updateCurrentParagraph(userInfo.chapter, userInfo.paragraph);
    }

    onChapterStateChanged(message, userInfo)    {
        let chapterData = this.data.chapters[userInfo.chapter];
        chapterData.enabled = userInfo.enabled;
        chapterData.completed = userInfo.completed;

        if(userInfo.chapter === this.currentChapter)    {
            this.updateChapterState();
        }
    }

    onParagraphStateChanged(message, userInfo)    {
        let chapterData = this.data.chapters[userInfo.chapter];
        let paragraphData = chapterData.paragraphs[userInfo.paragraph];
        paragraphData.completed = userInfo.completed;

        if(userInfo.chapter == this.currentChapter)  {
            this.updateChapterState();
        }
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.tutor) {
            this.updateUIState();
        }
    }

    render() {
        let visible = this.state.uiVisible;
        let enabled = this.state.uiEnabled;
        let ui = this.props.ui;

        let navigationLabel = new Array();
        for(let i = 1; i <= this.state.chapter.paragraphs.length; ++i)    {
            navigationLabel.push(<span key={2*i} className={'number ' + (this.state.currentParagraph === i - 1 ? 'current ' : '') + (this.state.chapter.paragraphs[i - 1].completed ? 'completed' : '')}>{i.toString()}</span>);
            if(i < this.state.numParagraphs) navigationLabel.push(<span key={2*i + 1} className="separator">|</span>);
        }

        let style = {
            bottom: this.props.margin + ui.bottomEdgeOffset.value + ui.overlay.edgeOffsets.bottom,
            left: this.props.margin + Math.max(ui.leftSlideInContainer.offset, 0),
            transition: OEToolbox.transitionForInsets({left: ui.leftSlideInContainer.animate, bottom: ui.bottomEdgeOffset.animate || ui.overlay.edgeOffsets.animate}),
            maxWidth: 'calc(100% - ' + (2*this.props.margin + ui.mainMenu.width).toString() + 'px)',
        };

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <VelocityTransitionGroup enter={{animation: 'fadeIn', duration: 333, easing: 'ease-in-out'}} leave={{animation: 'fadeOut', duration: 333, easing: 'ease-in-out'}}>
                    {!visible ? null :
                        <div className="tutor" style={Object.assign(style, this.props.style)}>
                            <div className="head">
                                <div className="label">
                                    {this.state.content.icon !== '' ? <OEIcon code={this.state.content.icon}/> : null}
                                    {this.state.content.title}
                                </div>
                                <OEButton
                                    className="transparent-btn close"
                                    onPressed={this.onCloseBtnPressed}
                                >
                                    <OEIcon code={OEIconCodes.tutorClose}/>
                                </OEButton>
                            </div>
                            <div className="body">
                                <div className="shadow-caster"/>
                                <div className="container">
                                    <div className="message">
                                        <span
                                            className="container"
                                            dangerouslySetInnerHTML={{__html: this.state.content.message}}
                                        />
                                    </div>
                                    <div className="navigation">
                                        <OEButton
                                            className="transparent-btn"
                                            disabled={!(enabled && this.state.currentParagraph > 0)}
                                            onPressed={this.onBackwardBtnPressed}
                                        >
                                            <OEIcon code={OEIconCodes.tutorBackward}/>
                                        </OEButton>
                                        <div className="label">
                                            {navigationLabel}
                                        </div>
                                        <OEButton
                                            className="transparent-btn"
                                            disabled={!(enabled && this.state.currentParagraph < this.state.numParagraphs - 1)}
                                            onPressed={this.onForwardBtnPressed}
                                        >
                                            <OEIcon code={OEIconCodes.tutorForward}/>
                                        </OEButton>
                                    </div>
                                    <div className="disable-chapter-control">
                                        <div>
                                            <OECustomCheckbox disabled={!enabled} checked={!this.state.chapter.enabled} onPressed={this.onDisableChapterClicked}/>
                                            <span>{this.state.strings.disabledChapter}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </VelocityTransitionGroup>
            </React.Fragment>
        );
    }

    onCloseBtnPressed() {
        this.oe.sharedInterface.getUIControllerTutor().popCurrentChapter();
    }

    onBackwardBtnPressed()  {
        if(this.currentParagraph <= 0)    return;
        this.oe.sharedInterface.getUIControllerTutor().setCurrentParagraph(this.currentParagraph - 1);
    }

    onForwardBtnPressed()  {
        if(this.currentParagraph >= this.state.numParagraphs - 1)    return;
        this.oe.sharedInterface.getUIControllerTutor().setCurrentParagraphCompleted();
        //this.oe.sharedInterface.getUIControllerTutor().setCurrentParagraph(this.currentParagraph + 1);
    }

    onDisableChapterClicked()   {
        if(!this.currentChapterData) return;
        this.oe.sharedInterface.getUIControllerTutor().setChapterEnabled(this.currentChapter, !this.currentChapterData.enabled);
    }
}

OETutorController.defaultProps = {
    moduleId: '',
    margin: 46
};

OETutorController.propTypes = {
    moduleId: PropTypes.string,
    margin: PropTypes.number.isRequired
};

export default connectModuleEnv((env) => {
    return {
        ui: env.ui
    };
})(OETutorController);