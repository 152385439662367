import React from 'react';
import PropTypes from 'prop-types';

import {oeStyleSheetTool} from '../../lib/oe-style-sheet-tool';
import {OEMediaViewerContentType, OEMediaViewerPlaybackState, OEMediaViewerReadyState, OEMediaViewerEvent, OEMediaViewerContent, OEMediaViewerDataSourceIntrinsicArray} from './oe-media-viewer-model';
import {OEMediaViewerWindowController} from './oe-media-viewer-window-controller';
import NotificationCenter from '../../lib/notification-center';

export default class OEMediaViewerController extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.events = new NotificationCenter();

        let _mediaViewerWindowController = {
            instance: null,
            dataSource: null,
            clearCacheApplied: null,
            activeContentIndex: {index: 0, applied: null},
            reloadApplied: false,
            progressApplied: false,
            readyState: OEMediaViewerReadyState.noMeta,
            playbackState: OEMediaViewerPlaybackState.pause,
            duration: 0,
            time: 0,
            progress: 0
        };

        let _fullscreen = false;

        this.state = {
            fullscreen: _fullscreen
        };

        this.isFullscreen = function()  {
            return _fullscreen;
        };

        this.setFullscreen = function(fullscreen)    {
            if(_fullscreen !== fullscreen)  {
                _fullscreen = fullscreen;
                this.setState({fullscreen: _fullscreen});
                this.props.onFullscreenStateChanged(_fullscreen);
                this.events.post(OEMediaViewerEvent.fullscreenStateChanged, {fullscreen: _fullscreen});
            }

            oeStyleSheetTool.setFullscreenRule(fullscreen);
        };

        this.setMediaViewWindowRef = function(ref)  {
            if(ref === _mediaViewerWindowController.instance)   return;

            _mediaViewerWindowController.instance = ref;

            if(ref) {

                if(typeof(_mediaViewerWindowController.clearCacheApplied) === 'number') {
                    this.ref.clearCache(_mediaViewerWindowController.clearCacheApplied);
                    _mediaViewerWindowController.clearCacheApplied = null;
                }

                if(ref.getDataSource() !== _mediaViewerWindowController.dataSource) {
                    ref.setDataSource(_mediaViewerWindowController.dataSource, true);
                } else if(_mediaViewerWindowController.reloadApplied)  {
                    ref.reload();
                }

                _mediaViewerWindowController.reloadApplied = false;

                if(_mediaViewerWindowController.activeContentIndex.applied) {
                    let aI = _mediaViewerWindowController.activeContentIndex;
                    this.set(aI.index, aI.applied.animated, aI.applied.progress);
                    aI.applied = null;
                }

                ref.setPlaybackState(_mediaViewerWindowController.playbackState);
                ref.seek(_mediaViewerWindowController.time);

                if(_mediaViewerWindowController.progressApplied)    {
                    ref.setProgress(this.progress);
                    _mediaViewerWindowController.progressApplied = false;
                }

                this.onReadyStateChanged(ref.getReadyState());
                this.onPlaybackStateChanged(ref.getPlaybackState());
                this.onTimeChanged(ref.getCurrentTime());
                this.onDurationChanged(ref.getDuration());
                this.onProgressChanged(ref.getProgress());
            }

        }.bind(this);

        this.getDataSource = function()  {
            return _mediaViewerWindowController.dataSource;
        };

        this.setDataSource = function(dataSource, reload)    {
            _mediaViewerWindowController.dataSource = dataSource;
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.setDataSource(dataSource, reload);
            }
        };

        this.getActiveContentIndex = function() {
            return _mediaViewerWindowController.activeContentIndex.index;
        };

        this.getActualContent = function()  {
            if(!_mediaViewerWindowController.dataSource) return null;
            return _mediaViewerWindowController.dataSource.mediaViewerContentForIndex(this.getActiveContentIndex());
        };
    
        this.play = function()  {
            this.setPlaybackState(OEMediaViewerPlaybackState.play);
        };

        this.pause = function()  {
            this.setPlaybackState(OEMediaViewerPlaybackState.pause);
        };

        this.stop = function()  {
            this.pause();
            this.seek(0);
        };

        this.getPlaybackState = function()  {
            return _mediaViewerWindowController.playbackState;
        }

        this.setPlaybackState = function(state)  {
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.setPlaybackState(state);
            } else {
                this.onPlaybackStateChanged(state);
            }
        }

        this.getCurrentTime = function()    {
            return _mediaViewerWindowController.time;
        }

        this.getDuration = function()    {
            return _mediaViewerWindowController.duration;
        }

        this.getProgress = function()  {
            return _mediaViewerWindowController.progress;
        };

        this.setProgress = function(progress)  {
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.setProgress(progress);
            } else {
                _mediaViewerWindowController.progressApplied = true;
                this.onProgressChanged(progress);
            }
        };

        this.seek = function(seconds)   {
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.seek(seconds);
            } else {
                this.onTimeChanged(seconds);
            }

            _mediaViewerWindowController.progressApplied = false;
        };

        this.clearCache = function(distance)   {
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.clearCache(distance);
            } else {
                _mediaViewerWindowController.clearCacheApplied = typeof(distance) === 'number' ? distance : 1;
            }
        }

        this.set = function(index, animated, progress)    {
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.set(index, animated, false, progress);
            } else {
                _mediaViewerWindowController.activeContentIndex.index = index;
                _mediaViewerWindowController.activeContentIndex.applied = {animated: animated, progress: progress};
                this.onMediaItemChanged(index);
            }
        };

        this.next = function(animated)  {
            let dataSource = _mediaViewerWindowController.dataSource;
            if(!dataSource) return false;
            let _activeContentIndex = _mediaViewerWindowController.activeContentIndex;
            let count = dataSource.mediaViewerContentCount();
            if(!(_activeContentIndex + 1 < count || count < 0)) return false;
            animated = typeof(animated) === 'boolean' ? animated : true;
            this.set(_activeContentIndex + 1, animated);
        };

        this.previous = function(animated)  {
            let dataSource = _mediaViewerWindowController.dataSource;
            if(!dataSource) return false;
            let _activeContentIndex = _mediaViewerWindowController.activeContentIndex;
            let count = dataSource.mediaViewerContentCount();
            if(!(_activeContentIndex - 1 >= 0 || count < 0)) return false;
            animated = typeof(animated) === 'boolean' ? animated : true;
            this.set(_activeContentIndex - 1, animated);
        };

        this.reload = function() {
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.reload();
            } else {
                _mediaViewerWindowController.reloadApplied = true;
            }
        };

        this.update = function(index, animated)  {
            if(_mediaViewerWindowController.instance)   {
                _mediaViewerWindowController.instance.update(index, animated);
            }
        };

        // window controller delegation
        this.onMediaItemChanged = function(index)   {
            if(_mediaViewerWindowController.activeContentIndex.index !== index) {
                _mediaViewerWindowController.activeContentIndex.index = index;
                this.props.onMediaItemChanged(index);
                this.events.post(OEMediaViewerEvent.mediaItemChanged, {index: index});
            }
        }.bind(this);

        this.onReadyStateChanged = function(state)  {
            if(_mediaViewerWindowController.readyState !== state) {
                _mediaViewerWindowController.readyState = state;
                this.props.onReadyStateChanged(state);
                this.events.post(OEMediaViewerEvent.readyStateChanged, {state: state});
            }
        }.bind(this);

        this.onPlaybackStateChanged = function(state)   {
            if(_mediaViewerWindowController.playbackState !== state)    {
                _mediaViewerWindowController.playbackState = state;
                this.props.onPlaybackStateChanged(state);
                this.events.post(OEMediaViewerEvent.playbackStateChanged, {state: state});
            }
        }.bind(this);

        this.onTimeChanged = function(time) {
            if(_mediaViewerWindowController.time !== time)  {
                _mediaViewerWindowController.time = time;
                this.props.onTimeChanged(time);
                this.events.post(OEMediaViewerEvent.timeChanged, {time: time});
            }
        }.bind(this);

        this.onDurationChanged = function(duration){
            if(_mediaViewerWindowController.duration !==duration)  {
                _mediaViewerWindowController.duration = duration;
                this.props.onDurationChanged(duration);
                this.events.post(OEMediaViewerEvent.durationChanged, {duration: duration});
            }
        }.bind(this);

        this.onProgressChanged = function(progress){
            if(_mediaViewerWindowController.progress !==progress)  {
                _mediaViewerWindowController.progress = progress;
                this.props.onProgressChanged(progress);
                this.events.post(OEMediaViewerEvent.progressChanged, {progress: progress});
            }
        }.bind(this);

        this.shouldChangePrev = function(index)  {
            return this.props.shouldChangePrev(index, this);
        }.bind(this);

        this.shouldChangeNext = function(index)  {
            return this.props.shouldChangeNext(index, this);
        }.bind(this);

        this.onGoToItem = function(name)  {
            this.props.onGoToItem(name);
        }.bind(this);

        this.onShowMedia = function(path)  {
            this.props.onShowMedia(path);
        }.bind(this);

        this.onFullscreenBtnPressed = function(fullscreen)    {
            this.setFullscreen(this.props.onFullscreenBtnPressed(fullscreen));
        }.bind(this);

        this.onCloseBtnPressed = function() {
            this.props.onCloseBtnPressed();
        }.bind(this);

        if(this.props.dataSource)   {
            this.setDataSource(this.props.dataSource);
        }
    }

    componentWillUnmount()  {
        this.setFullscreen(false);
    }

    componentDidUpdate(prevProps, prevState)    {
        if(prevProps.dataSource !== this.props.dataSource)  {
            this.setDataSource(this.props.dataSource, true);
        }
    }

    render() {

        return (
            <div className={'media-viewer ' + (this.state.fullscreen ? 'fullscreen-element' : '') + ' ' + this.props.className }>
                <OEMediaViewerWindowController
                    className={this.state.fullscreen ? 'fullscreen-element' : ''}
                    ref={this.setMediaViewWindowRef}
                    preloadWidth={this.props.preloadWidth}
                    isCaching={this.props.isCaching}
                    showCloseBtn={this.props.showCloseBtn}
                    showControlsWhenWindow={this.props.showControlsWhenWindow}
                    showControlsPermanentlyWhenPaused={this.props.showControlsPermanentlyWhenPaused}
                    controlsContentAlignedWhenWindowed={this.props.controlsContentAlignedWhenWindowed}
                    showNextPrevButtons={this.props.showNextPrevButtons}
                    fullscreen={this.state.fullscreen}
                    onMediaItemChanged={this.onMediaItemChanged}
                    onReadyStateChanged={this.onReadyStateChanged}
                    onPlaybackStateChanged={this.onPlaybackStateChanged}
                    onTimeChanged={this.onTimeChanged}
                    onDurationChanged={this.onDurationChanged}
                    onProgressChanged={this.onProgressChanged}
                    shouldChangePrev={this.shouldChangePrev}
                    shouldChangeNext={this.shouldChangeNext}
                    onGoToItem={this.onGoToItem}
                    onShowMedia={this.onShowMedia}
                    onFullscreenBtnPressed={this.onFullscreenBtnPressed}
                    onCloseBtnPressed={this.onCloseBtnPressed}
                    backgroundColor={this.state.fullscreen ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0)'}
                />
            </div>
        );
    }
}

OEMediaViewerController.defaultProps = {
    className: '',
    preloadWidth: 2,
    isCaching: true,
    dataSource: null,
    showCloseBtn: false,
    showControlsWhenWindow: false,
    showControlsPermanentlyWhenPaused: true,
    controlsContentAlignedWhenWindowed: true,
    showNextPrevButtons: true,
    onMediaItemChanged: (index) => {},
    onReadyStateChanged: (state) => {},
    onPlaybackStateChanged: (state) => {},
    onTimeChanged: (time) => {},
    onDurationChanged: (duration) => {},
    onProgressChanged: (progress) => {},
    onFullscreenStateChanged: (fullscreen) => {},
    shouldChangePrev: (index, sender) => { return true; },
    shouldChangeNext: (index, sender) => { return true; },
    onGoToItem: (name) => {},
    onShowMedia: (path) => {},
    onFullscreenBtnPressed: (fullscreen) => { return !fullscreen; },
    onCloseBtnPressed: () => {},
};

OEMediaViewerController.propTypes = {
    className: PropTypes.string,
    preloadWidth: PropTypes.number,
    isCaching: PropTypes.bool,
    showCloseBtn: PropTypes.bool,
    showControlsWhenWindow: PropTypes.bool,
    showControlsPermanentlyWhenPaused: PropTypes.bool,
    controlsContentAlignedWhenWindowed: PropTypes.bool,
    showNextPrevButtons: PropTypes.bool,
    onMediaItemChanged: PropTypes.func,
    onReadyStateChanged: PropTypes.func,
    onPlaybackStateChanged: PropTypes.func,
    onTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onProgressChanged: PropTypes.func,
    onFullscreenStateChanged: PropTypes.func,
    shouldChangePrev: PropTypes.func,
    shouldChangeNext: PropTypes.func,
    onGoToItem: PropTypes.func,
    onShowMedia: PropTypes.func,
    onFullscreenBtnPressed: PropTypes.func,
    onCloseBtnPressed: PropTypes.func
};

OEMediaViewerController.createTestDataSource = function()   {

    let contentPDF = new OEMediaViewerContent(
        OEMediaViewerContentType.pdf,
        'manual/en.pdf'
    );

    let contentPDF2 = new OEMediaViewerContent(
        OEMediaViewerContentType.pdf,
        'testContent/ACS_FB_4in1_OPT.pdf'
    );

    let content1 = new OEMediaViewerContent(
        OEMediaViewerContentType.image,
        'https://hdqwalls.com/wallpapers/beautiful-sunset-sea-sky-scenery-landscape-4k-5t.jpg'
    );

    let content2 = new OEMediaViewerContent(
        OEMediaViewerContentType.image,
        'https://hdqwalls.com/download/sulphur-mountains-wide-3840x2160.jpg'
    );

    let content3 = new OEMediaViewerContent(
        OEMediaViewerContentType.image,
        'https://hdqwalls.com/download/sunbeam-landscape-nature-3840x2160.jpg'
    );

    let content4 = new OEMediaViewerContent(
        OEMediaViewerContentType.video,
        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        true
    );

    let content5 = new OEMediaViewerContent(
        OEMediaViewerContentType.video,
        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4',
        true
    );

    let content6 = new OEMediaViewerContent(
        OEMediaViewerContentType.image,
        'https://hdqwalls.com/download/green-lizard-reptile-macro-4k-jv-2560x1440.jpg'
    );

    let content7 = new OEMediaViewerContent(
        OEMediaViewerContentType.video,
        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        false
    );

    let content8 = new OEMediaViewerContent(
        OEMediaViewerContentType.image,
        'https://hdqwalls.com/download/winter-snow-landscape-ln-3840x2160.jpg'
    );

    let dataSource = new OEMediaViewerDataSourceIntrinsicArray([contentPDF2, content1, content2, contentPDF, content3, content4, content5, content6, content7, content8]);

    dataSource.content.forEach((item, index) => {
        if(item.type === OEMediaViewerContentType.html || item.type == OEMediaViewerContentType.pdf)    {
            item.blockPrev = true;
            item.blockNext = true;
        }
        if(index > 0 && item.blockPrev)  dataSource.content[index - 1].blockNext = true;
        if(index + 1 < dataSource.content.length && item.blockNext)  dataSource.content[index + 1].blockPrev = true;
    });

    return dataSource;
};

OEMediaViewerController.getTestDataSource = function()   {
    if(!OEMediaViewerController.testDataSource) {
        OEMediaViewerController.testDataSource = OEMediaViewerController.createTestDataSource();
    }
    return OEMediaViewerController.testDataSource;
};