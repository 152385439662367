import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {withUILevel} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {UIControllerType, OEManualViewLinks} from '../../../lib/oe-types';
import OEPopover from '../../oe-popover';
import {OEGroupControl, OEToggleControl, OESliderControl} from '../../oe-controls';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import {retardUpdate} from '../../../lib/update-retarder';

export class OEArrowController extends React.PureComponent {

    constructor(props){
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            enabled: false,
            sizeArrow: 5,
            enableEditArrow: false,
            enableAddRemoveArrow: false,
            strings: {
                title: '',
                enableArrow: '',
                sizeArrowLabel: '',
                editLabel: '',
                addRemoveArrowLabel: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateControlsState = this.updateControlsState.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onArrowSwitchChanged = this.onArrowSwitchChanged.bind(this);
        this.onSizeArrowSliderChanged = this.onSizeArrowSliderChanged.bind(this);
        this.onArrowEditSwitchChanged = this.onArrowEditSwitchChanged.bind(this);
        this.onArrowAddRemoveSwitchChanged = this.onArrowAddRemoveSwitchChanged.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowsStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowEditModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.arrowSizeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowsStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowEditModeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.arrowSizeChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        this.setState({strings: {
            title: this.oe.sharedInterface.getLocalizedStringEnc('arrow_view'),
            enableArrow: this.oe.sharedInterface.getLocalizedStringEnc('arrow_view_enable'),
            sizeArrowLabel: this.oe.sharedInterface.getLocalizedStringEnc('arrow_view_size'),
            editLabel: this.oe.sharedInterface.getLocalizedStringEnc('arrow_view_edit'),
            addRemoveArrowLabel: this.oe.sharedInterface.getLocalizedStringEnc('arrow_view_add_remove')
        }});
    }

    updateControlsState()   {
        this.setState({
            enabled: this.oe.sharedInterface.getUIControllerArrow().getEnabled(),
            sizeArrow: this.oe.sharedInterface.getUIControllerArrow().getArrowSize(),
            enableEditArrow: this.oe.sharedInterface.getUIControllerArrow().isEditingEnabled(),
            enableAddRemoveArrow: this.oe.sharedInterface.getUIControllerArrow().isEditingAddRemoveEnabled()
        });
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerArrow().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateControlsState();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.arrow) {
            this.updateUIState();
        }
    }

    render(){
        if(!this.props.config.enabled) return null;
        let disabledEditing = !(this.state.uiEnabled && this.state.enabled);
        let disabledAdd = !this.state.enableEditArrow || disabledEditing;

        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover
                    className="popover-control"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.arrow}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <div className="arrow-controller">
                        <OEGroupControl>
                            <OEToggleControl
                                title={this.state.strings.enableArrow}
                                active={this.state.enabled}
                                disabled={!this.state.uiEnabled}
                                onClick={this.onArrowSwitchChanged}
                            />
                            <OESliderControl
                                title = {this.state.strings.sizeArrowLabel}
                                disabled={disabledEditing} 
                                min={0.3}
                                max={2.5}
                                step={0.05}
                                value={this.state.sizeArrow}
                                onSlide={this.onSizeArrowSliderChanged}
                            />
                            <OEToggleControl
                                title={this.state.strings.editLabel}
                                active={this.state.enableEditArrow}
                                disabled={disabledEditing}
                                onClick={this.onArrowEditSwitchChanged}
                            />
                            <OEToggleControl
                                title={this.state.strings.addRemoveArrowLabel}
                                active={this.state.enableAddRemoveArrow}
                                disabled={disabledAdd}
                                onClick={this.onArrowAddRemoveSwitchChanged}
                            />
                        </OEGroupControl>            
                    </div>
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.arrow});
    }

    onArrowSwitchChanged() {
        this.oe.sharedInterface.getUIControllerArrow().setEnabled(!this.state.enabled, true, 0.333);
    }

    onSizeArrowSliderChanged(value) {
        this.oe.sharedInterface.getUIControllerArrow().setArrowSize(value);
    }

    onArrowEditSwitchChanged() {
        this.oe.sharedInterface.getUIControllerArrow().enableEditing(!this.state.enableEditArrow);
    }

    onArrowAddRemoveSwitchChanged() {
        if(this.oe.sharedInterface.getUIControllerArrow().isEditingEnabled()) {
            this.oe.sharedInterface.getUIControllerArrow().enableEditingAddRemove(!this.state.enableAddRemoveArrow);
        }
    }
}

OEArrowController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.arrowController()
};

OEArrowController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired
};

export default connectAppEnv((env) => { 
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.arrowController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.arrow)
    }
})(withUILevel(OEArrowController));