
import OEMainMenuBtnAdapter from '../../oe-main-menu-btn-adapter';
import {OEPhilipsIcarusCustomPropKey, OEPhilipsIcarusMode, OEPhilipsIcarusOverlayNotifications} from './oe-philips-icarus-model';
import {OEToolbox} from '../../../lib/oe-toolbox';

export default class OEPhilipsIcarusMainMenuBtnAdapterReset extends OEMainMenuBtnAdapter {

    constructor() {
        super();

        this.state = {
            enabled: false
        };

        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);
    }

    setState(state) {
        let newState = Object.assign({}, this.state, state);
        if(OEToolbox.jsonEqual(newState, this.state)) return;
        this.state = newState;
        if(this.menu) this.menu.updateForAdapter(this);
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    shouldBeEnabled() {
        if(!this.oe || !this.oe.isReady())  return false;

        /*
        const si = this.oe.sharedInterface;
        let mode = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
        if(mode !== OEPhilipsIcarusMode.xRay)   return true;
        */

        return true;
    }

    updateState(released)   {
        if(!this.oe || !this.oe.isReady() || released === true)   {
            this.setState({enabled: false});
            return;
        }

        this.setState({
            enabled: this.shouldBeEnabled()
        });
    }

    onCustomPropertyChanged(message, userInfo)   {
        if(userInfo.key === OEPhilipsIcarusCustomPropKey.mode)   this.updateState();
    }

    onPressed(activated, menu)   {
        if(!this.state.enabled) return this.state.active;
        if(!this.oe || !this.oe.isReady()) return this.state.active;
        const si = this.oe.sharedInterface;

        let mode = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;

        if(mode === OEPhilipsIcarusMode.xRay)    {
            si.postNotification(OEPhilipsIcarusOverlayNotifications.cameraResetBtnPressed, null);
        } else {
            menu.props.appComponent.uiLayer.closeAllPopovers();
            si.reset(true, 0.5);
        }
    }

    isActive(menu)   {
        return false;
    }

    isEnabled(menu) {
        return this.state.enabled;
    }
};