import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEColor from '../../color-picker/oe-color';
import OEIcon from '../../elements/oe-icon';
import {OEMediaCenterItemTypes, OEMediaCenterItemLayoutType, OEMediaCenterIconType} from './oe-media-center-model';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEMediaCenterItemStd extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.thumbID = {pres: -1, slide: -1};

        this.state = {
            img: null
        };

        this.onPresentationSlideSet = this.onPresentationSlideSet.bind(this);
        this.onPresentationSlideAdded = this.onPresentationSlideAdded.bind(this);
        this.onPresentationSlideRemoved = this.onPresentationSlideRemoved.bind(this);
        this.onPresentationSlideMoved = this.onPresentationSlideMoved.bind(this);

        this.onClick = this.onClick.bind(this);
        this.onRef = this.onRef.bind(this);

        this.typeClasses = ['item-type-dummy', 'item-type-animation', 'item-type-accessory', 'item-type-image', 'item-type-video', 'item-type-timeline', 'item-type-html', 'item-type-pdf', 'item-type-presentation', 'item-type-separator'];
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.item.id !== this.props.item.id)     {
            this.props.elementRef(null, this.props.item.id);
            this.props.elementRef(this.ref, nextProps.item.id);
        }

        if(nextProps.item.id !== this.props.item.id || nextProps.item.type !== this.props.type || nextProps.item.item.index !== this.props.item.item.index)     {
            this.updateImage(false, nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideSet, this.onPresentationSlideSet);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideRemoved, this.onPresentationSlideRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideMoved, this.onPresentationSlideMoved);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideSet, this.onPresentationSlideSet);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideRemoved, this.onPresentationSlideRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideMoved, this.onPresentationSlideMoved);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.thumbID = {pres: -1, slide: -1};
            this.setState({img: null});
            return;
        }

        retardUpdate(this, () => {
            this.updateImage(true);
        });
    }

    updateImage(force, props)   {
        props = props || this.props;

        if(!this.oe.isReady() || props.item.type !== this.oe.Module.MediaCenterItemType.presentation)  {
            this.thumbID = {pres: -1, slide: -1};
            this.setState({img: null});
            return;
        }

        let pres = this.oe.sharedInterface.getUIControllerPresentation();
        
        let presID = props.item.item.index;
        let iPres = pres.getPresentationIndexForID(presID);
        let slideID = pres.getSlideID(iPres, 0);
        if(slideID < 0) {
            this.thumbID = {pres: -1, slide: -1};
            this.setState({img: null});
            return;
        }

        if(this.thumbID.pres === presID && this.thumbID.slide === slideID && !force)   return;

        this.thumbID = {pres: presID, slide: slideID};

        pres.getThumbnailID(this.thumbID.pres, slideID, (thumb, presID, slideID) => {
            if(this.thumbID.pres !== presID || this.thumbID.slide !== slideID)   return;
            this.setState({img: thumb});
        });
    }

    onPresentationSlideSet(message, userInfo)    {
        if(this.thumbID.pres !== userInfo.presID || this.thumbID.slide !== userInfo.slideID) return;
        this.updateImage(true);
    }

    onPresentationSlideAdded(message, userInfo)    {
        if(this.thumbID.pres !== userInfo.presID || userInfo.slide > 0) return;
        this.updateImage(true);
    }

    onPresentationSlideRemoved(message, userInfo)    {
        this.onPresentationSlideAdded(message, userInfo);
    }
    
    onPresentationSlideMoved(message, userInfo)    {
        if(this.thumbID.pres < 0 || (userInfo.slideSrc !== 0 && userInfo.slideDst !== 0))    return;

        if((userInfo.slideSrc === 0 && this.thumbID.pres === userInfo.presSrcID) || (userInfo.slideDst === 0 && this.thumbID.pres === userInfo.presDstID))    {
            this.updateImage(true);
        }
    }

    onRef(ref) {
        if(this.ref === ref) return;
        this.ref = ref;
        this.props.elementRef(ref, this.props.item.id);
    }

    onClick()   {
        this.props.onClick(this.props.index, this.props.item.id);
    }

    renderItem()    {
        let type = this.props.item.type;
        let typeClassName = this.typeClasses[typeof(type) !== 'undefined' && type.value >= 0 && type.value < this.typeClasses.length ? type.value : 0];

        if(type.value === OEMediaCenterItemTypes.separator)    return (
            <div
                className={'media-center-item-std ' + typeClassName + ' ' + this.props.className}
                style={this.props.style}
                ref={this.onRef}
            >
                <div className="content">
                    <div className="container">
                        <div className="label">
                            {this.props.item.label}
                        </div>
                    </div>
                </div>
            </div>
        );

        let style = this.props.item.img ? {backgroundImage: 'url(' + encodeURI(this.props.item.img) + ')'} : (this.state.img ? {backgroundImage: 'url(' + encodeURI(this.state.img) + ')'} : null);

        return (
            <div
                className={'media-center-item-std ' + typeClassName + ' ' + this.props.className + (this.props.selected ? ' selected' : '') + (!this.props.enabled ? ' disabled' : '')}
                style={this.props.style}
                onClick={this.onClick}
                ref={this.onRef}
            >
                <div
                    className={'content' + (this.props.item.layoutType === OEMediaCenterItemLayoutType.nameTop ? ' name-top' : '')}
                    style={style}
                >
                
                    <div className="container">

                        <div className="head">
                            <div className="content">

                                {!this.props.item.showNumber ? null : <span className="number">{(this.props.index + 1).toString()}</span>}

                                <OEIcon
                                    className={this.props.item.icon.type === OEMediaCenterIconType.text ? 'default-font text' : ''}
                                    style={{color: OEColor.toDOMStr(this.props.item.icon.color)}}
                                    code={this.props.item.icon.code}
                                />

                            </div>
                        </div>

                        {!this.props.item.showLabel ? null :
                            <div className="label">
                                {this.props.item.label}
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                {this.renderItem()}
            </React.Fragment>
        );
    }
}

OEMediaCenterItemStd.defaultProps = {
    className: '',
    moduleId: '',
    enabled: true,
    selected: false,
    elementRef: function(ref, id) {},
    onClick: function(index, id) {}
};

OEMediaCenterItemStd.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    selected: PropTypes.bool,
    elementRef: PropTypes.func,
    onClick: PropTypes.func
};