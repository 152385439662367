import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';
import {DraggableCore} from 'react-draggable';

import OENumberInput from '../../../elements/oe-number-input';
import {OEToolbox} from '../../../../lib/oe-toolbox';
import OEResizeObserver from '../../../../lib/oe-resize-observer';

export class OEIntervalEditDragView extends React.PureComponent  {

    constructor(props)  {
        super(props);

        this.position = this.props.position;

        this.onDrag = this.onDrag.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.shallowEqual(nextProps.position, this.props.position))     {
            this.position = nextProps.position;
        }
    }

    render()    {
        let style = {transform: 'translate(' + this.props.position.x.toString() + 'px , ' + this.props.position.y.toString() + 'px)'};
        if(typeof(this.props.zIndex) === 'number')  style.zIndex = this.props.zIndex;

        return(
            <DraggableCore
                allowAnyClick="false"
                disabled={!this.props.enabled}
                onDrag={this.onDrag}
                onMouseDown={this.onMouseDown}
            >
                <div 
                    className={'interval-edit-drag-view ' + (!this.props.enabled ? 'disabled ' : '') + this.props.className}
                    style={style}
                >
                    {this.props.children ? this.props.children : <div className="btn-view"/>}
                </div>
            </DraggableCore>
        );
    }

    onDrag(e, data) {
        this.position.x += data.deltaX;
        if(this.props.onDrag)   this.props.onDrag(this, {x: this.position.x, y: this.position.y});
    }

    onMouseDown() {
        if(this.props.onMouseDown)   this.props.onMouseDown(this, {x: this.position.x, y: this.position.y});
    }
}

OEIntervalEditDragView.defaultProps = {
    className: '',
    enabled: true,
    position: {x: 0, y: 0}
};

OEIntervalEditDragView.propTypes = {
    className: PropTypes.string,
    enabled: PropTypes.bool,
    position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }),
    zIndex: PropTypes.number,
    onMouseDown: PropTypes.func,
    onDrag: PropTypes.func
};

export class OEIntervalEditView extends React.PureComponent  {

    constructor(props)  {
        super(props);

        this.dragViewSize = 24;
        this.dragViewEdgeInset = 4;

        this.contentViewSize = null;

        this.updateLimits();
        this.interval = this.limitInterval(this.props.value);

        this.state = {
            contentViewSize: null,
            interval: clone(this.interval),
            lastDragged: 'start'
        };

        this.onContentViewResize = this.onContentViewResize.bind(this);

        this.onStartMouseDown = this.onStartMouseDown.bind(this);
        this.onEndMouseDown = this.onEndMouseDown.bind(this);
        this.onStartDrag = this.onStartDrag.bind(this);
        this.onEndDrag = this.onEndDrag.bind(this);

        this.onIntervalStartInputChanged = this.onIntervalStartInputChanged.bind(this);
        this.onIntervalEndInputChanged = this.onIntervalEndInputChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let valueChanged = !OEToolbox.shallowEqual(nextProps.value, this.props.value);
        let limitsChanged = !OEToolbox.shallowEqual(nextProps.limits, this.props.limits);

        if(valueChanged && limitsChanged)     {
            this.updateLimits(nextProps);
            this.updateInterval(nextProps);
        } else {
            if(valueChanged)    {
                this.updateInterval(nextProps);
            } else if(this.updateLimits(nextProps))    {
                this.applyLimits();
            }
        }
    }

    updateLimits(props)  {
        props = props || this.props;
        let limits = this.limits;
        if(!limits)    limits = {min: -86000, max: 86000};

        if((typeof(props.limits.min) === 'number' || typeof(props.limits.max) === 'number') && props.limits.min <= props.limits.max)  {
            limits = props.limits;
        }

        let changed = !OEToolbox.shallowEqual(limits, this.limits);
        if(changed) this.limits = clone(limits);
        return changed;
    }

    limitInterval(interval)   {
        let result = clone(interval);

        if(typeof(this.limits.min) === 'number')    {
            result.start = Math.max(result.start, this.limits.min);
            result.end = Math.max(result.end, this.limits.min);
        }

        if(typeof(this.limits.max) === 'number')    {
            result.start = Math.min(result.start, this.limits.max);
            result.end = Math.min(result.end, this.limits.max);
        }

        return result;
    }

    applyLimits()   {
        let interval = this.limitInterval(this.interval);
        let changed = !OEToolbox.shallowEqual(interval, this.interval);
        if(changed) {
            this.interval = interval;
            this.setState({interval: clone(this.interval)});
        }
        return changed;
    }

    valueForPos(pos)    {
        if(!this.state.contentViewSize || !this.state.contentViewSize.w) return 0;

        let start = Math.min(0, this.state.interval.start);
        let completeDuration = Math.max(this.props.duration, this.state.interval.end) - start;
        return start + pos / this.state.contentViewSize.w * completeDuration;
    }

    posForValue(val)    {
        if(!this.state.contentViewSize || !this.state.contentViewSize.w) return 0;

        let start = Math.min(0, this.state.interval.start);
        let completeDuration = Math.max(this.props.duration, this.state.interval.end) - start;
        return (val - start) / completeDuration * this.state.contentViewSize.w;
    }

    limitVal(val)   {
        let ret = val;
        if(typeof(this.limits.min) === 'number')    {
            ret = Math.max(ret, this.limits.min);
        }

        if(typeof(this.limits.max) === 'number')    {
            ret = Math.min(ret, this.limits.max);
        }

        return ret;
    }

    limitPos(pos)   {
        return this.posForValue(this.limitVal(this.valueForPos(pos)));
    }

    updateInterval(props)   {
        props = props || this.props;
        let interval = this.limitInterval(props.value);
        let changed = !OEToolbox.shallowEqual(interval, this.interval);
        if(changed) {
            this.interval = interval;
            this.setState({interval: clone(this.interval)});
        }
        return changed;
    }

    onContentViewResize(sender, size)   {
        if(OEToolbox.jsonEqual(this.contentViewSize, size)) return;
        this.contentViewSize = size;
        this.setState({contentViewSize: clone(this.contentViewSize)});
    }

    renderContent() {
        if(!this.state.contentViewSize) return null;

        let size = this.state.contentViewSize;
        let dragViewSizeHalf = 0.5 * this.dragViewSize;

        let start = Math.min(0, this.state.interval.start);
        let completeDuration = Math.max(this.props.duration, this.state.interval.end) - start;

        let durationLayerX1 = (0 - start) / completeDuration * size.w;
        let durationLayerX2 = (this.props.duration - start) / completeDuration * size.w;

        let intervalLayerX1 = (this.state.interval.start - start) / completeDuration * size.w;
        let intervalLayerX2 = (this.state.interval.end - start) / completeDuration * size.w;

        let durationViewStyle = {left: durationLayerX1, top: size.h - this.props.durationHeight, width: durationLayerX2 - durationLayerX1, height: this.props.durationHeight};
        let intervalViewStyle = {left: intervalLayerX1, top: size.h - this.props.intervalHeight - this.props.durationHeight, width: intervalLayerX2 - intervalLayerX1, height: this.props.intervalHeight};

        let naturalIntervalWidth = this.props.naturalIntervalLength / completeDuration * size.w;
        let naturalIntervalViewStyle = {left: intervalLayerX1, top: size.h - this.props.naturalIntervalHeight - this.props.durationHeight, width: naturalIntervalWidth, height: this.props.naturalIntervalHeight};
        let showNaturalInterval = typeof(this.props.naturalIntervalLength) === 'number' && this.props.naturalIntervalLength > 0;

        let lineViewY = size.h - this.props.intervalHeight - this.props.durationHeight - 2;
        let startLineViewStyle = {left: intervalLayerX1 - 0.5 * this.props.lineWidth, top: lineViewY, width: this.props.lineWidth, height: size.h - lineViewY - 0.5};
        let endLineViewStyle = {left: intervalLayerX2 - 0.5 * this.props.lineWidth, top: lineViewY, width: this.props.lineWidth, height: size.h - lineViewY - 0.5};

        let dragViewY = size.h - this.props.intervalHeight - this.props.durationHeight - 2 - (this.dragViewSize - this.dragViewEdgeInset);
        let startDragViewPosition = {x: intervalLayerX1 -  dragViewSizeHalf, y: dragViewY};
        let endDragViewPosition = {x: intervalLayerX2 - dragViewSizeHalf, y: dragViewY};

        return (
            <React.Fragment>
                <div className="duration-view" style={durationViewStyle}/>
                <div className="interval-view" style={intervalViewStyle}/>
                {showNaturalInterval ? <div className="natural-interval-view" style={naturalIntervalViewStyle}/> : null}
                <div className="line-view" style={startLineViewStyle}/>
                <div className="line-view" style={endLineViewStyle}/>

                <OEIntervalEditDragView
                    className="start"
                    enabled={this.props.enabled}
                    position={startDragViewPosition}
                    zIndex={this.state.lastDragged === 'start' ? 1 : 0}
                    onMouseDown={this.onStartMouseDown}
                    onDrag={this.onStartDrag}
                />

                <OEIntervalEditDragView
                    className="end"
                    enabled={this.props.enabled}
                    position={endDragViewPosition}
                    zIndex={this.state.lastDragged === 'end' ? 1 : 0}
                    onMouseDown={this.onEndMouseDown}
                    onDrag={this.onEndDrag}
                />
            </React.Fragment>
        );
    }

    renderUpperBar()    {
        let step = this.props.step > 0 ? this.props.step : 0.01;

        return (
            <div className="upper-bar">
                <div className="left">
                    <OENumberInput
                        className="start-text-field"
                        disabled={!this.props.enabled} 
                        min={this.props.limits.min}
                        max={this.props.limits.max}
                        step={step}
                        value={this.state.interval.start}
                        label={this.props.strings.start}
                        unit={this.props.strings.unit}
                        onChange={this.onIntervalStartInputChanged}
                    />
                </div>

                <div className="center">
                    <div className="duration-label">
                        {this.props.strings.duration ? <span className="label">{this.props.strings.duration}</span> : null}
                        <span className="value">{(Math.round(this.props.duration / step) * step).toString()}</span>
                    </div>
                    <div className="natural-interval-label">
                        {this.props.strings.duration ? <span className="label">{this.props.strings.naturalIntervalLength}</span> : null}
                        <span className="value">{(Math.round(this.props.naturalIntervalLength / step) * step).toString()}</span>
                    </div>
                </div>

                <div className="right">
                    <OENumberInput
                        className="end-text-field"
                        disabled={!this.props.enabled} 
                        min={this.props.limits.min}
                        max={this.props.limits.max}
                        step={step}
                        value={this.state.interval.end}
                        label={this.props.strings.end}
                        unit={this.props.strings.unit}
                        onChange={this.onIntervalEndInputChanged}
                    />
                </div>
            </div>
        );
    }

    renderContainer() {
        return (
            <React.Fragment>
                <div className='content-view'>
                    <OEResizeObserver onResize={this.onContentViewResize} />
                    {this.renderContent()}
                </div>
            </React.Fragment>
        );
    }

    render()    {
        return(
            <div className={'interval-edit-view ' + this.props.className}>
                {this.renderUpperBar()}
                <div className='container-view'>
                    {this.renderContainer()}
                </div>
            </div>
        );
    }

    onStartMouseDown()   {
        this.setState({lastDragged: 'start'});
    }

    onEndMouseDown()   {
        this.setState({lastDragged: 'end'});
    }

    onStartDrag(e, data)   {
        if(!this.state.contentViewSize || !this.state.contentViewSize.w)    return;

        let size = this.state.contentViewSize;
        let start = Math.min(0, this.state.interval.start);
        let completeDuration = Math.max(this.props.duration, this.state.interval.end) - start;
        let endDragViewCenterX = (this.state.interval.end - start) / completeDuration * size.w;

        let val = this.valueForPos(Math.min(data.x + 0.5 * this.dragViewSize, endDragViewCenterX - 2));

        let newInterval = this.limitInterval({start: val, end: this.interval.end});
        if(OEToolbox.shallowEqual(newInterval, this.interval))  return;
        this.interval = newInterval;
        this.setState({interval: clone(this.interval), lastDragged: 'start'});

        if(this.props.onValueChange)  this.props.onValueChange(this, this.interval);
    }

    onEndDrag(e, data) {
        if(!this.state.contentViewSize || !this.state.contentViewSize.w)    return;

        let size = this.state.contentViewSize;
        let start = Math.min(0, this.state.interval.start);
        let completeDuration = Math.max(this.props.duration, this.state.interval.end) - start;
        let startDragViewCenterX = (this.state.interval.start - start) / completeDuration * size.w;

        let val = this.valueForPos(Math.max(data.x + 0.5 * this.dragViewSize, startDragViewCenterX + 2));

        let newInterval = this.limitInterval({start: this.interval.start, end: val});
        if(OEToolbox.shallowEqual(newInterval, this.interval))  return;
        this.interval = newInterval;
        this.setState({interval: clone(this.interval), lastDragged: 'end'});

        if(this.props.onValueChange)  this.props.onValueChange(this, this.interval);
    }

    onIntervalStartInputChanged(sender, val)   {
        this.setState({lastDragged: 'start'});

        let newInterval = this.limitInterval({start: Math.min(val, this.interval.end), end: this.interval.end});
        if(OEToolbox.shallowEqual(newInterval, this.interval))  return;
        
        this.interval = newInterval;
        this.setState({interval: clone(this.interval)});

        if(this.props.onValueChange)  this.props.onValueChange(this, this.interval);
    }

    onIntervalEndInputChanged(sender, val) {
        this.setState({lastDragged: 'end'});

        let newInterval = this.limitInterval({start: this.interval.start, end: Math.max(val, this.interval.start)});
        if(OEToolbox.shallowEqual(newInterval, this.interval))  return;

        this.interval = newInterval;
        this.setState({interval: clone(this.interval)});

        if(this.props.onValueChange)  this.props.onValueChange(this, this.interval);
    }
};

OEIntervalEditView.defaultProps = {
    className: '',
    enabled: true,
    duration: 10,
    naturalIntervalLength: 4,
    value: {start: 1, end: 5},
    limits: {min: -86000, max: 86000},
    step: 0.01,
    durationHeight: 2,
    intervalHeight: 12,
    naturalIntervalHeight: 4,
    lineWidth: 1,
    strings: {}
};

OEIntervalEditView.propTypes = {
    className: PropTypes.string,
    enabled: PropTypes.bool,
    duration: PropTypes.number,
    naturalIntervalLength: PropTypes.number,
    value: PropTypes.shape({
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired
    }),
    limits: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
    }),
    step: PropTypes.number,
    durationHeight: PropTypes.number,
    intervalHeight: PropTypes.number,
    naturalIntervalHeight: PropTypes.number,
    lineWidth: PropTypes.number,
    strings: PropTypes.shape({
        unit: PropTypes.string,
        start: PropTypes.string,
        end: PropTypes.string,
        duration: PropTypes.string,
        naturalIntervalLength: PropTypes.string
    }),
    onValueChange: PropTypes.func 
};
