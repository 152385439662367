import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {withUILevel} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {UIControllerType, OEManualViewLinks} from '../../lib/oe-types';
import OEPopover from '../oe-popover';
import {OEGroupControl, OEToggleControl, OESliderControl} from '../oe-controls';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {retardUpdate} from '../../lib/update-retarder';

export class OELabelControllerStd extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            enabled: false,
            numLabels: 12,
            fontSize: 16,
            useLatin: false,
            onlyCutSurfaces: false,
            strings:   {
                title: '',
                enableLabel: '',
                numLabelsLabel: '',
                fontSizeLabel: '',
                useLatinLabel: '',
                onlyCutSurfacesLabel: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateControlsState = this.updateControlsState.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onLabelSwitchChanged = this.onLabelSwitchChanged.bind(this);
        this.onNumLabelsSliderChanged = this.onNumLabelsSliderChanged.bind(this);
        this.onFontSizeSliderChanged = this.onFontSizeSliderChanged.bind(this);
        this.onLatinSwitchChanged = this.onLatinSwitchChanged.bind(this);
        this.onOnlyCutSurfacesSwitchChanged = this.onOnlyCutSurfacesSwitchChanged.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.labelsStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.labelsConfigChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.labelsStateChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.labelsConfigChanged, this.updateControlsState);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        this.setState({strings: {
            title: this.oe.sharedInterface.getLocalizedStringEnc('label_view'),
            enableLabel: this.oe.sharedInterface.getLocalizedStringEnc('label_view_enable'),
            numLabelsLabel: this.oe.sharedInterface.getLocalizedStringEnc('label_view_max_number'),
            fontSizeLabel: this.oe.sharedInterface.getLocalizedStringEnc('label_view_font_size'),
            useLatinLabel: this.oe.sharedInterface.getLocalizedStringEnc('label_view_latin'),
            onlyCutSurfacesLabel: this.oe.sharedInterface.getLocalizedStringEnc('label_view_only_cut_surfaces')
        }});
    }

    updateControlsState()   {
        this.setState({
            enabled: this.oe.sharedInterface.getUIControllerLabel().getEnabled(),
            numLabels: this.oe.sharedInterface.getUIControllerLabel().getMaxNumLabels(),
            fontSize: this.oe.sharedInterface.getUIControllerLabel().getFontSize(),
            useLatin: this.oe.sharedInterface.getUIControllerLabel().getUseLatin(),
            onlyCutSurfaces: this.oe.sharedInterface.getUIControllerLabel().getLabelOnlyCutSurfaces(),
        });
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerLabel().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateControlsState();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.label) {
            this.updateUIState();
        }
    }

    render() {
        if(!this.props.config.enabled) return null;
        let disabled = !(this.state.uiEnabled && this.state.enabled);
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control" 
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.label}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <div className="label-controller">
                        <OEGroupControl>
                            <OEToggleControl
                                title = {this.state.strings.enableLabel}
                                active={this.state.enabled}
                                disabled={!this.state.uiEnabled}
                                onClick={this.onLabelSwitchChanged}
                            />
                            <OESliderControl
                                title = {this.state.strings.numLabelsLabel}
                                disabled={disabled}
                                min={3}
                                max={25}
                                step={1}
                                value={this.state.numLabels}
                                onSlide={this.onNumLabelsSliderChanged}
                            />
                            <OESliderControl
                                title = {this.state.strings.fontSizeLabel}
                                disabled={disabled}
                                min={12}
                                max={24}
                                step={0.1}
                                value={this.state.fontSize}
                                onSlide={this.onFontSizeSliderChanged}
                            />
                            <OEToggleControl
                                title = {this.state.strings.useLatinLabel}
                                active={this.state.useLatin}
                                disabled={disabled}
                                onClick={this.onLatinSwitchChanged}
                            />
                            <OEToggleControl 
                                title = {this.state.strings.onlyCutSurfacesLabel}
                                active={this.state.onlyCutSurfaces}
                                disabled={disabled}
                                onClick={this.onOnlyCutSurfacesSwitchChanged}
                            />
                        </OEGroupControl>
                    </div>
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.label});
    }

    onLabelSwitchChanged() {
        this.oe.sharedInterface.getUIControllerLabel().setEnabled(!this.state.enabled, true, 0.333);
    }

    onNumLabelsSliderChanged(value) {
        this.oe.sharedInterface.getUIControllerLabel().setMaxNumLabels(value);
    }

    onFontSizeSliderChanged(value) {
        this.oe.sharedInterface.getUIControllerLabel().setFontSize(value);
    }

    onLatinSwitchChanged() {
        this.oe.sharedInterface.getUIControllerLabel().setUseLatin(!this.state.useLatin);
    }

    onOnlyCutSurfacesSwitchChanged() {
        this.oe.sharedInterface.getUIControllerLabel().setLabelOnlyCutSurfaces(!this.state.onlyCutSurfaces);
    }
}

OELabelControllerStd.defaultProps = {
    moduleId: '',
    target: ''
};

OELabelControllerStd.propTypes = {
    moduleId: PropTypes.string
};

export class OELabelController extends React.PureComponent {
    render() {
        let config = this.props.config;
        if(!config.enabled) return null;
        return <OELabelControllerStd {...this.props} />;
    }
}

OELabelController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.labelController()
};

OELabelController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.labelController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.label)
    }
})(withUILevel(OELabelController));