import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import {OEModal} from './oe-modal';
import OEColor from '../color-picker/oe-color';
import {OEColorHelper} from '../oe-color-helper';
import OEIcon from '../elements/oe-icon';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEStdModalController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_,
            strings: {
                title: 'EFFIGOS EXPLORER',
            },
            backgroundColor: OEColorHelper.stdBackgroundColor(0.6),
            textColor: OEColorHelper.stdLabelTextColor(0.6),
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onBgColorChanged = this.onBgColorChanged.bind(this);

        this.renderHeader = this.renderHeader.bind(this);

        this.onToggle = this.onToggle.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    setOpen(open = true)   {
        if(typeof(this.props.isOpen) === 'boolean' || this.isOpen_ === open) return;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
        if(typeof(this.props.onOpenStateChanged) === 'function')  this.props.onOpenStateChanged(this, this.isOpen_);
    }

    isOpen()    {
        return this.isOpen_;
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    updateLanguage()   {
        let headerStringId = this.props.headerStringId
        let preHeader = this.oe.sharedInterface.getLocalizedStringEnc(headerStringId + '_pre_header')
        let header = this.oe.sharedInterface.getLocalizedStringEnc(headerStringId + '_header')

        this.setState({ strings: {
            title: {header: header, preHeader: preHeader}
        }});
    }

    onBgColorChanged(message, userInfo)  {
        let brightness = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;
        this.setState({
            backgroundColor: OEColorHelper.stdBackgroundColor(brightness),
            textColor: OEColorHelper.stdLabelTextColor(brightness)
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.onBgColorChanged();
        });
    }

    renderHeader(props)  {
        if(this.props.renderHeader) return this.props.renderHeader(props);
        
        let title = props.title;
        let header = null;

        if(typeof(title) === 'string')  {

            if(title != '') header = <h5 className="modal-title">{title}</h5>;

        } else if(typeof(title) === 'object')   {
            if(title.text || title.icon)    {

                header = (<h5 className="modal-title">{title.icon ? <OEIcon code={title.icon}/> : null}{title.text}</h5>);

            } else if(typeof(title.header) === 'string')    {

                if(typeof(title.preHeader) === 'string' && title.preHeader !== '#null') {
                    header = (<h5 className="modal-title">{title.preHeader}<br/>{title.header}</h5>);
                } else {
                    header = (<h5 className="modal-title">{title.header}</h5>);
                }
            }
        }

        return (
            <div className={'header ' + (!props.logo ? 'no-logo ' : '') + props.headerClassName}>
                {props.logo ? <img className="logo" src = {props.logo}/> : null}
                {header}
                {this.props.additionalButtons ? this.props.additionalButtons(props) : null}
                {props.hasCloseBtn ? <button type="button" className="close-btn" onClick={props.onToggle}> <OEIcon code={OEIconCodes.close} /> </button> : null}
            </div>
        );
    }

    render() {
        const {children, ...rest} = this.props;
        let isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;
        let title = this.props.title || this.props.title === '' ? this.props.title : this.state.strings.title;

        let modalContentStyle = {
            backgroundColor: OEColor.toDOMStr(this.state.backgroundColor),
            color: OEColor.toDOMStr(this.state.textColor)
        };

        return (
            <OEModal
                {...rest}
                isOpen={isOpen}
                className={'std-modal-controller ' + this.props.className}
                style={modalContentStyle}
                title={title}
                onToggle={this.onToggle}
                renderHeader={this.renderHeader}
            >
               {this.props.children}
            </OEModal>
        );
    }

    onToggle(id, params) {
        if(typeof(this.props.onToggle) === 'function')  {
            this.props.onToggle(id, params);
        } else {
            this.setOpen(false);
        }
    }
}

OEStdModalController.defaultProps = {
    moduleId: '',
    rootClassName: '',
    className: '',
    zIndex: 1050,
    noHeader: false,
    headerClassName: '',
    headerStringId: 'start_view',
    hasCloseBtn: true,
    backdrop: true,
    toggleOnBackdrop: false,
    onEnter: () => {},
    onExit: () => {},
    onOpened: () => {},
    onClosed: () => {}
};

OEStdModalController.propTypes = {
    moduleId: PropTypes.string,
    rootClassName: PropTypes.string,
    className: PropTypes.string,
    logo: PropTypes.string,
    zIndex: PropTypes.number,
    noHeader: PropTypes.bool,
    headerClassName: PropTypes.string,
    headerStringId: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.string
    })]),
    hasCloseBtn: PropTypes.bool,
    renderHeader: PropTypes.func,
    additionalButtons: PropTypes.func,
    renderFooter: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        title: PropTypes.string,
        onClick: PropTypes.func
    })),
    backdrop: PropTypes.bool,
    toggleOnBackdrop: PropTypes.bool,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func
};