import React from 'react';
import PropTypes from 'prop-types';

import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEIcon from './oe-icon';

export class OECustomDropdownMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSubKey: -1
        }
        
        this.renderEntry = this.renderEntry.bind(this);
    }

    componentWillUpdate(nextProps, nextState)   {
        if(nextProps.disabled !== this.props.disabled && nextProps.disabled)  {
            this.unselectSubMenu();
        }
    }

    selectSubMenu(key) {
        this.setState({activeSubKey: this.props.entries.findIndex(entry => entry.key === key) < 0 ? -1 : key});
    }

    unselectSubMenu() {
        this.setState({activeSubKey: -1});
    }

    renderEntry(entry, index)    {
        const isActive = entry.key === this.props.chosenKey;
        
        let className = this.props.disabled ? (this.props.disabledItemClassName || this.props.itemClassName) : (isActive ? (this.props.activeItemClassName || this.props.itemClassName) : this.props.itemClassName);
        className = (index == 0 ? 'first ' : '') + (this.props.disabled ? 'disabled' : (isActive ? 'active' : '')) + (className ? ' ' + className : '');

        const style = this.props.disabled ? (this.props.disabledItemStyle || this.props.itemStyle) : (isActive ? (this.props.activeItemStyle || this.props.itemStyle) : this.props.itemStyle);

        return (
            <div
                onMouseEnter={() => this.onEntryMouseHover(entry.key)}
                onMouseLeave={() => this.onEntryMouseLeave(entry.key)}
                key={entry.key}
            >
                {entry.key !== this.state.activeSubKey ? null : 
                    <OECustomDropdownMenu
                        disabled={this.props.disabled}
                        chosenKey={this.props.chosenKey}
                        entries={entry.children}
                        onClick={this.props.onClick}
                        itemClassName={this.props.itemClassName}
                        disabledItemClassName={this.props.disabledItemClassName}
                        activeItemClassName={this.props.activeItemClassName}
                        itemStyle={this.props.itemStyle}
                        disabledItemStyle={this.props.disabledItemStyle}
                        activeItemStyle={this.props.activeItemStyle}
                    />
                }

                <a
                    className={'item ' + className}
                    style={style}
                    onClick={this.props.disabled || entry.children.length !== 0 ? null : () => this.props.onClick(entry.key)}
                >
                    <div className='item-content-sub-menu'>
                        {entry.content}
                    </div>
                </a>

            </div>
        );
    }

    render() {
        const items = this.props.entries.map(this.renderEntry);
        const style = this.props.disabled ? (this.props.disabledStyle || this.props.style) : this.props.style;
                    
        return (
            <div
                className={'menu ' + (this.props.disabled ? 'disabled' : '') + ' ' + this.props.className}
                style={style}
            >
                {items}
            </div>
        );
    }

    onEntryMouseHover(key)  {
        if(this.props.disabled) return;
        this.selectSubMenu(key);
    }

    onEntryMouseLeave(key)  {
        //this.unselectSubMenu();
    }
}

OECustomDropdownMenu.defaultProps = {
    className: '',
    style: null,
    disabled: false,
    chosenKey: null,
    entries: [],
    onClick: (key) => {}
};

OECustomDropdownMenu.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    onClick: PropTypes.func,
    itemClassName: PropTypes.string,
    disabledItemClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    itemStyle: PropTypes.string,
    disabledItemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object
};

export default class OECustomDropdown extends React.PureComponent {

    constructor(props) {
        super(props);
    
        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_
        };

        this.onClick = this.onClick.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    componentWillUpdate(nextProps, nextState)   {
        if(nextProps.disabled !== this.props.disabled && nextProps.disabled)  {
            this.setOpen(false);
        }
    }

    setOpen(open)   {
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
    }

    isOpen()    {
        return this.isOpen_;
    }

    toggle() {
        this.setOpen(!this.isOpen());
    }

    findActiveEntry(entries, key) {
        for(let i = 0; i < entries.length; i++) {
            if(entries[i].key === key) return entries[i];
            const entry = this.findActiveEntry(entries[i].children, key);
            if(entry) return entry;
        }
    }
    
    renderToggle(isOpen)    {
        const activeEntry = this.findActiveEntry(this.props.entries, this.props.chosenKey);

        let className = this.props.disabled ? ((this.props.disabledToggleClassName || this.props.toggleClassName) || (this.props.disabledItemClassName || this.props.itemClassName)) : (this.props.toggleClassName || this.props.itemClassName);
        className = (this.props.disabled ? 'disabled' : '') + (className ? ' ' + className : '');

        const style = this.props.disabled ? ((this.props.disabledToggleStyle || this.props.toggleStyle) || (this.props.disabledItemStyle || this.props.itemStyle)) : (this.props.toggleStyle || this.props.itemStyle);

        return (
            <a
                className={'item toggle ' + className}
                style={style}
                onClick={this.onToggle}
            >
                <div className={'rot-toggle ' + (isOpen ? 'rot-rotate-toggle' : '')}>
                    <OEIcon code={OEIconCodes.caretRight}/>
                </div>
                <div className='item-content'>
                    {activeEntry ? activeEntry.content : null}
                </div>
            </a>
        );
    }

    render() {
        const isOpen = !this.props.disabled && (typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen);
        const style = this.props.disabled ? (this.props.disabledStyle || this.props.style) : this.props.style;
        const className = 'oe-dropdown ' + this.props.subOrientation + ' ' + (this.props.disabled ? 'disabled ' : '') + (isOpen ? 'open ' : '') + this.props.className;
                    
        return (
            <div 
                className={className}
                style={style}
            >
                {this.renderToggle(isOpen)}

                <OECustomDropdownMenu
                    chosenKey={this.props.chosenKey}
                    entries={this.props.entries}
                    onClick={this.onClick}
                    itemClassName={this.props.itemClassName}
                    disabledItemClassName={this.props.disabledItemClassName}
                    activeItemClassName={this.props.activeItemClassName}
                    itemStyle={this.props.itemStyle}
                    disabledItemStyle={this.props.disabledItemStyle}
                    activeItemStyle={this.props.activeItemStyle}
                />
            </div>
        );
    }

    onToggle()  {
        if(this.props.onToggle)  this.props.onToggle(this.state.isOpen);
        this.toggle();
    }

    onClick(key)   {
        if(this.props.onClick) this.props.onClick(key);
        if(typeof(this.props.isOpen) !== 'boolean') {
            this.setOpen(false);
        }
    }
}

OECustomDropdown.subOrientation = {
    right: 'sub-orientation-right',
    left: 'sub-orientation-left'
};

OECustomDropdown.defaultProps = {
    className: '',
    style: null,
    disabled: false,
    chosenKey: null,
    entries: [],
    subOrientation: OECustomDropdown.subOrientation.right
};

OECustomDropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    subOrientation: PropTypes.oneOf([OECustomDropdown.subOrientation.right, OECustomDropdown.subOrientation.left]),
    onToggle: PropTypes.func,
    onClick: PropTypes.func,
    toggleClassName: PropTypes.string,
    disabledToggleClassName: PropTypes.string,
    toggleStyle: PropTypes.object,
    disabledToggleStyle: PropTypes.object,
    itemClassName: PropTypes.string,
    disabledItemClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    itemStyle: PropTypes.string,
    disabledItemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object,
};