import React from 'react';
import PropTypes from 'prop-types';

import OEAnimationControl from '../../animation-control/oe-animation-control';

export default class OEOverlayAnimationControl extends React.PureComponent {

    render()  {
        return (
            <div 
                className={'view animation-control ' + this.props.className}
                style={{visibility: this.props.visible ? 'visible' : 'hidden', opacity: this.props.visible ? '1.0' : '0.0'}}
            >

                {this.props.header}

                <div 
                    className="body light-bg" 
                    style={{ pointerEvents: this.props.visible ? 'initial' : 'none' }}
                >

                    <OEAnimationControl
                        enabled={this.props.enabled}
                        adapter={this.props.adapter}
                        onPlayBtnPressed={this.props.onPlayBtnPressed}
                        onPauseBtnPressed={this.props.onPauseBtnPressed}
                        onStopBtnPressed={this.props.onStopBtnPressed}
                        onProgressSliderChanged={this.props.onProgressSliderChanged}
                    />
                </div>

                {this.props.footer}

            </div>
        );
    }
}

OEOverlayAnimationControl.defaultProps = {
    className: '',
    visible: true,
    enabled: true,

};

OEOverlayAnimationControl.propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool,
    enabled: PropTypes.bool
};