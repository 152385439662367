import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {frefFromRef} from '../../lib/oe-higher-order-components';
import {OEViewType, OEStartViewTypes, OEFNWelcomeEntryTypes} from '../../lib/oe-types';
import OENotification from '../../lib/oe-notification';
import OEStartViewStd from './std/oe-start-view-std';
import OEFNStartView from './flow-nav/oe-flow-nav-start-view';
import OEFNTestView from './flow-nav/oe-flow-nav-test-view';
import OEFNWelcome from './flow-nav/oe-flow-nav-welcome';
import OEImplantcastStartView from '../custom/implantcast/oe-implantcast-start-view';
import OEStartViewCambridgeCell from '../custom/cambridge/oe-start-view-cambridge-cell';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OETarget} from '../../react-oe/oe-target';

export function OEStartViewFactory(props) {
    const {fref, ...rest} = props;
    const config = rest.config || OEDefaultConfigFactory.startView();

    const openFn = (sender, isOpen) => {
        if(!props.appComponent || !props.appComponent.uiLayer) return;
        props.appComponent.uiLayer.notificationCenter.post(OENotification.viewOpenStateChanged, {
            type: OEViewType.start,
            isOpen: isOpen,
            sender: sender
        });
    };

    if(config.type === OEStartViewTypes.amboss) {

        return <OEFNStartView {...rest} className="old" showLogo={false} showCloseBtnAllways={false} entries={OEFNWelcome.defaultProps.entries} config={config} ref={fref} onOpenStateChanged={openFn}/>;
    
    } else if(config.type === OEStartViewTypes.zeissOpti)   {

        let onClick = (id) => {
            if(!props.appComponent || !props.appComponent.uiLayer || !props.appComponent.app)   return;
            const target = id === 'anatomy' ? OETarget.humanEyeMacro : (id === 'opti' ? OETarget.zeissOpti2 : OETarget.zeissUV);
            if(props.appComponent.app.changeTarget(target))    {
                props.appComponent.uiLayer.startView.close();
            }
        };

        let entries = [
            {type: OEFNWelcomeEntryTypes.spacer, height: 56},
            {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text', html: true, minHeight: 81, mask: {licenseValid: true}},
            {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text_no_license', html: true, minHeight: 81, mask: {licenseValid: false}},
            {type: OEFNWelcomeEntryTypes.spacer, height: 32},
            {type: OEFNWelcomeEntryTypes.licensing, mask: {licenseValid: false}},
            {type: OEFNWelcomeEntryTypes.menu, id: 'anatomy', stringId: 'target_menu_anatomy', icon: OEIconCodes.zeissOpti.anatomyTarget, onClick: onClick},
            {type: OEFNWelcomeEntryTypes.menu, id: 'opti', stringId: 'target_menu_zeissOpti2', icon: OEIconCodes.zeissOpti.optiTarget, onClick: onClick, logo: 'images/zeiss_logo.png'},
            {type: OEFNWelcomeEntryTypes.menu, id: 'uv', stringId: 'target_menu_zeissUV', icon: OEIconCodes.zeissOpti.UVTarget, onClick: onClick, logo: 'images/zeiss_logo.png'},
            {type: OEFNWelcomeEntryTypes.manual},
            {type: OEFNWelcomeEntryTypes.spacer, height: 20},
            {type: OEFNWelcomeEntryTypes.dontShowCheckbox, noSubtitle: true, stringId: 'welcome_view_dont_show_again_title_v2'},
            {type: OEFNWelcomeEntryTypes.spacer, height: 8}
        ];

        return <OEFNStartView {...rest} config={config} entries={entries} ref={fref} onOpenStateChanged={openFn}/>;

    } else if(config.type === OEStartViewTypes.eyeOCT)   {

        let onClick = (id) => {
            if(!props.appComponent || !props.appComponent.uiLayer || !props.appComponent.app)   return;
            const target = id === 'anatomy' ? OETarget.humanEye2 : (id === 'opti' ? OETarget.zeissOpti2 : OETarget.humanEyeOCT);
            if(props.appComponent.app.changeTarget(target))    {
                props.appComponent.uiLayer.startView.close();
            }
        };

        let entries = [
            {type: OEFNWelcomeEntryTypes.spacer, height: 56},
            {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text', html: true, minHeight: 81, mask: {licenseValid: true}},
            {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text_no_license', html: true, minHeight: 81, mask: {licenseValid: false}},
            {type: OEFNWelcomeEntryTypes.spacer, height: 32},
            {type: OEFNWelcomeEntryTypes.licensing, mask: {licenseValid: false}},
            {type: OEFNWelcomeEntryTypes.menu, id: 'anatomy', stringId: 'target_menu_anatomy', icon: OEIconCodes.zeissOpti.anatomyTarget, onClick: onClick},
            {type: OEFNWelcomeEntryTypes.menu, id: 'opti', stringId: 'target_menu_zeissOpti2', icon: OEIconCodes.zeissOpti.optiTarget, onClick: onClick, logo: 'images/zeiss_logo.png'},
            {type: OEFNWelcomeEntryTypes.menu, id: 'otc', stringId: 'target_menu_humanEyeOCT', icon: OEIconCodes.eyeOCT.octTarget, onClick: onClick},
            {type: OEFNWelcomeEntryTypes.manual},
            {type: OEFNWelcomeEntryTypes.spacer, height: 20},
            {type: OEFNWelcomeEntryTypes.dontShowCheckbox, noSubtitle: true, stringId: 'welcome_view_dont_show_again_title_v2'},
            {type: OEFNWelcomeEntryTypes.spacer, height: 8}
        ];

        return <OEFNStartView {...rest} config={config} entries={entries} ref={fref} onOpenStateChanged={openFn}/>;

    } else if(config.type === OEStartViewTypes.std) {

        return <OEStartViewStd {...rest} config={config} ref={fref} onOpenStateChanged={openFn}/>;

    } else if(config.type === OEStartViewTypes.cambridgeCell)   {

        return <OEStartViewCambridgeCell {...rest} config={config} ref={fref} onOpenStateChanged={openFn}/>;

    } else if(config.type === OEStartViewTypes.anatomy || config.type === OEStartViewTypes.ionto)   {

        let entries = config.type !== OEStartViewTypes.ionto ? undefined :
            [
                {type: OEFNWelcomeEntryTypes.spacer, height: 46},
                {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text', mask: {licenseValid: true}},
                {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text_no_license', mask: {licenseValid: false}},
                {type: OEFNWelcomeEntryTypes.spacer, height: 18},
                {type: OEFNWelcomeEntryTypes.licensing, mask: {licenseValid: false}},
                {type: OEFNWelcomeEntryTypes.mediaCenter, stringId: 'welcome_view_pres_v2'},
                {type: OEFNWelcomeEntryTypes.home, stringId: 'welcome_view_home_v2'},
                {type: OEFNWelcomeEntryTypes.quickStart},
                {type: OEFNWelcomeEntryTypes.manual},
                {type: OEFNWelcomeEntryTypes.spacer, height: 20},
                {type: OEFNWelcomeEntryTypes.dontShowCheckbox, noSubtitle: true, stringId: 'welcome_view_dont_show_again_title_v2'},
                {type: OEFNWelcomeEntryTypes.spacer, height: 8}
            ];
    
        return <OEFNStartView {...rest} config={config} entries={entries} ref={fref} onOpenStateChanged={openFn}/>;
        
    } else if(config.type === OEStartViewTypes.implantcast)   {

        return <OEImplantcastStartView {...rest} config={config} ref={fref} onOpenStateChanged={openFn}/>

    } else if(config.type === OEStartViewTypes.test)   {

        return <OEFNTestView {...rest} config={config} ref={fref} onOpenStateChanged={openFn}/>;
    }
}

OEStartViewFactory.defaultProps = {
    moduleId: ''
};

OEStartViewFactory.propTypes = {
    moduleId: PropTypes.string
};

export default frefFromRef(connectAppEnv((env) => { return {
    version: env.config.version,
    target: env.config.target,
    flags: env.config.module.flags,
    appComponent: env.component,
    config: env.config.module.uiLayerConfig.startViewConfig
}})(OEStartViewFactory));