import React from 'react';
import PropTypes from 'prop-types';

export class OECarouselItem extends React.PureComponent {
    render() {
        return (
            <div className={'oe-carousel-item ' + this.props.className}>
                {this.props.children}
            </div>
        );
    }
}

OECarouselItem.defaultProps = {
    className: ''
};

OECarouselItem.propTypes = {
    className: PropTypes.string
};

export class OECarousel extends React.PureComponent {
    render() {
        const activeIndex = this.props.activeIndex;
        const children = React.Children.toArray(this.props.children);
    
        var xTranslation = -activeIndex * 100;

        const slides = children.map((item, index) => {

            var heightStyle = !this.props.adaptToAspect || activeIndex === index ? 'auto' : '0px';

            return (
                <div key={index} className="oe-carousel-item" style={{height: heightStyle, transform: 'translateX(' + xTranslation.toString() + '%)', transition: 'all 300ms ease-in-out'}}>
                    {item}
                </div>
            );
        });

        return (
            <div className={'oe-carousel ' + this.props.className}>
                <div className="oe-carousel-inner" >
                    {slides}
                </div>
            </div>
        );
    }
}

OECarousel.defaultProps = {
    className: '',
    activeIndex: 0,
    adaptToAspect: true
};

OECarousel.propTypes = {
    className: PropTypes.string,
    activeIndex: PropTypes.number,
    adaptToAspect: PropTypes.bool
};