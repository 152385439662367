export default class AppComponent {

    constructor(component) {
        this.app = null;
        this.module = null;
        this.uiLayer = null;
        this.componentController = null;
        this.waitingController = null;
        this.licensingManager = null;

        if(component) {
            Object.assign(this, component);
        }
    }

    showWaitingController() {
        if(!this.waitingController)    return;
        this.waitingController.show();
    }

    hideWaitingController() {
        if(!this.waitingController)    return;
        this.waitingController.hide();
    }
}