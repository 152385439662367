import React from 'react';
import PropTypes from 'prop-types';

import {Navbar, NavbarBrand, Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap';

import {slInterface} from '../../react-oe/sl-interface';

export default class OELicensingFooter extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            strings:   {
                imprint: 'Imprint',
                privacy: 'Privacy',
                legalInfo: 'Legal information'
            }
        };

        this.onConnect = this.onConnect.bind(this);
        
        this.updateLanguage = this.updateLanguage.bind(this);
    }

    componentDidMount()    {
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
    }
  
    connect() {
        slInterface.register(this.onConnect);
        if(slInterface.isReady() && slInterface.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        slInterface.unregister(this.onConnect);
        if(slInterface.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        slInterface.sharedNotificationCenter.register(slInterface.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.updateState(true);
        slInterface.sharedNotificationCenter.unregister(slInterface.NotificationName.languageChanged, this.updateLanguage);
    }

    updateState(released)   {
        if(!slInterface.isReady() || released === true) {
            return;    
        }
    
        this.updateLanguage();
    }

    updateLanguage()    {
        this.setState({
            strings: {
                imprint: slInterface.sharedInterface.getLocalizedStringEnc('licensing_start_web_footer_imprint'),
                privacy: slInterface.sharedInterface.getLocalizedStringEnc('licensing_start_web_footer_privacy'),
                legalInfo: slInterface.sharedInterface.getLocalizedStringEnc('licensing_start_web_footer_legal_info')
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar color="white" light expand="md">
                <Container>
                    <Row>
                        <Col xs="12" sm="6">                   
                            <NavbarBrand href="https://www.effigos.com/" target="_blank">
                                <img src="images/licensing-app/logo_effigos.png" />
                            </NavbarBrand> 
                            <p>&copy; Effigos AG 2018</p> 
                        </Col>
                        <Col xs="12" sm="6">
                            <Nav vertical>
                            <NavItem>
                                <NavLink href="https://effigos.com/en/imprint/" target="_blank">{this.state.strings.imprint}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://effigos.com/en/privacy/" target="_blank">{this.state.strings.privacy}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://effigos.com/en/legal/" target="_blank">{this.state.strings.legalInfo}</NavLink>
                            </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
                </Navbar>
            </div>
        );
    }
}