export const OEFunctionFragmentType = {
    noOp: 0,
    makeReachable: 1,
    contextMenuSelect: 2,
    contextMenuFadeOther: 3,
    contextMenuHideOther: 4,
    selectionControllerSelect: 5,
    centerCamera: 6,
    zoomOutCamera: 7,
    setUIState: 8,
    setMediaCenterItem: 9,

    stringTable: [
        'noOp', 'makeReachable', 'contextMenuSelect', 'contextMenuFadeOther', 'contextMenuHideOther', 'selectionControllerSelect', 'centerCamera',
        'zoomOutCamera', 'setUIState', 'setMediaCenterItem'
    ],
    toString: function(type) {
        return typeof(type) !== 'number' || type < 0 || type >= this.stringTable.length ? undefined : this.stringTable[type];
    }
};

export const OEFunctionFragmentIcons = {
    noOp: '\u00FF',
    makeReachable: '\u00FF',
    contextMenuSelect: '\u00FF',
    contextMenuFadeOther: '\u00FF',
    contextMenuHideOther: '\u00FF',
    selectionControllerSelect: '\u00FF',
    centerCamera: '\u00FF',
    zoomOutCamera: '\u00FF',
    setUIState: '\u00FF',
    setMediaCenterItem: '\uF144',

    iconForType(type)   {
        let str = OEFunctionFragmentType.toString(type);
        return str && this[str] ? this[str] : '\u00FF';
    }
};

export const OEFunctionFragmentParameterTypes = {
    string: 'string',
    integer: 'integer',
    float: 'float'
};

export const OEFunctionFragmentParameterModel = {

    setMediaCenterItem: {
        resourceID: {type: OEFunctionFragmentParameterTypes.string},
        pos: {type: OEFunctionFragmentParameterTypes.integer}
    },

    modelForType(type)   {
        let str = OEFunctionFragmentType.toString(type);
        return str && this[str] ? this[str] : {};
    }
};