import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/app';
import {OEToolbox} from './lib/oe-toolbox';

//import HelloWorld from './docu-samples/hello-world-1';

console.log(OEToolbox.versionString());

ReactDOM.render(
    <App />,
    document.getElementById('root')
);