import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {frefFromRef} from '../../../lib/oe-higher-order-components';
import {OENavigationViewComponent} from '../../../lib/oe-navigation-controller';
import OEFlowNavModal from './oe-flow-nav-modal';
import OEFNElement from '../../elements/oe-flow-nav-elements';

export class OEFNTestView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            titles: ['Flow Nav Test View', 'Flow Nav Test View -> B'],
            selectedTitle: 0,
            subView1Titles: ['Flow Nav Test Sub View 1', 'Flow Nav Test Sub View 1 -> B'],
            selectedSubView1Title: 0,
            subView1MenuElement1Titles: ['Change Title', 'Change Title -> B'],
            selectedSubView1MenuElement1Title: 0,
        };

        this.onNavRef = this.onNavRef.bind(this);

        this.onMenuElementClick = this.onMenuElementClick.bind(this);
    }

    onNavRef(ref)   {
        this.navRef = ref;
        if(this.props.onNavRef) this.props.onNavRef(ref);
    }

    render() {
        const {className, onNavRef, ...rest} = this.props;

        return (
            <OEFlowNavModal
                {...rest}
                className={'start-view ' + className}
                onNavRef={this.onNavRef}
            >
                <OENavigationViewComponent title={{text: this.state.titles[this.state.selectedTitle]}}>
                    <OEFNElement.View>
                        <OEFNElement.Menu
                            id="change_title"
                            title="Change Title"
                            onClick={this.onMenuElementClick}
                        />
                        <OEFNElement.Menu
                            id="open_subview_1"
                            title="Open Subview 1"
                            onClick={this.onMenuElementClick}
                        />
                    </OEFNElement.View>
                </OENavigationViewComponent>

                <OENavigationViewComponent title={{text: this.state.subView1Titles[this.state.selectedSubView1Title]}} compId="subview_1" deferred={true}>
                    <OEFNElement.View>
                        <OEFNElement.Menu
                            id="change_title_sub_view_1"
                            title={this.state.subView1MenuElement1Titles[this.state.selectedSubView1MenuElement1Title]}
                            onClick={this.onMenuElementClick}
                        />
                        <OEFNElement.Menu
                            id="change_title_sub_view_1_menu_element_1"
                            title="Change Menu Element Title"
                            onClick={this.onMenuElementClick}
                        />
                    </OEFNElement.View>
                </OENavigationViewComponent>

            </OEFlowNavModal>
        );
    }

    onMenuElementClick(id, sender)    {
        if(id === 'change_title')   {
            this.setState(prevState => Object.assign({}, prevState, {selectedTitle: (prevState.selectedTitle + 1) % 2}));
        } else if(id === 'open_subview_1')   {
            if(this.navRef) this.navRef.push('subview_1');
        } else if(id === 'change_title_sub_view_1')   {
            this.setState(prevState => Object.assign({}, prevState, {selectedSubView1Title: (prevState.selectedSubView1Title + 1) % 2}));
        } else if(id === 'change_title_sub_view_1_menu_element_1')   {
            this.setState(prevState => Object.assign({}, prevState, {selectedSubView1MenuElement1Title: (prevState.selectedSubView1MenuElement1Title + 1) % 2}));
        }
    }
}

OEFNTestView.defaultProps = Object.assign({}, OEFlowNavModal.defaultProps);

OEFNTestView.propTypes = Object.assign({}, OEFlowNavModal.propTypes);

export default frefFromRef(connectAppEnv((env) => { return {
    appComponent: env.component
}})(OEFNTestView));