
export const OEIconCodes = {

    mainMenu: '\uF0C9',

    home: '\u2009',
    settings: '\uF013',
    tree: '\uF22a',
    substructure: '\uF23B',
    selection: '\u2006',
    search: '\u2005',
    cut: '\u200A',
    label: '\uF02C',
    note: '\u2007',
    arrow: '\uF22F',
    info: '\uF129',
    infoQuestionMark: '\u00FF',
    help: '\u00FF',
    stateBack: '\uF053',
    stateForth: '\uF054',
    animation: '\uF01D',
    presentation: '\u221E',
    mediaCenter: '\uF144',
    screenshot: '\u00C5',
    color: '\uF2B2',
    explode: '\uF29B',

    key: '\uF21A',
    
    werkman: '\uF29E',

    close: '\uF00D',
    compress: '\uF066',
    expand: '\uF065',

    textFieldClear: '\uF057',

    textViewClose: '\uF057',

    contextMenuFade: '\uF297',
    contextMenuHide: '\uF298',
    contextMenuFadeOther: '\uF296',
	contextMenuHideOther: '\uF299',
	contextMenuInfo: '\uF05a',

    cutToolTouch: '\uF2A6',
    cutToolSwipe: '\uF2A5',
    cutToolAll: '\uF29A',
    cutToolTouched: '\uF299',

    cloudDownload: '\uF0ED',
    cloudUpload: '\uF0EE',

    colorPicker:    {
        palette: '\uF23D',
        paletteToggle: '\uF04B'
    },

    pres:   {
        add: '\uF055',
        remove: '\uF014',
        save: '\uF0C7',
        load: '\uF07C',
        set: '\uF021',
        slide: '\u00C6',
        slideAttribute: '\uF0C6',
        slideAnimationAttribute: '\uF008',
        audio: '\uF001',
        resetCompColors: '\uF090',
        preset: '\u2004',
        language: '\uF024'
    },

    presSlideAttribute: {
        add: '\uF07C',
        dump: '\uF014',
        types: {
            dummy: '\uF1B0',
            custom: '\uF135',
            link: '\uF0C1',
            array: ['\uF1B0', '\uF135', '\uF0C1']
        },
        linkTypes: {
            animation: '\uF008',
            array: ['\uF008']
        },
        syncSlideView: '\uF07E'
    },

    audioTrack: {
        open: '\uF07C',
        dump: '\uF014',
        ok: '\uF090',
    },

    assetSelection: {
        ok: '\uF14A',
        typeIcons:  {
            dummy: '\uF1B0',
            animation: '\uF2A9',
            presentation: '\u221E'
        }
    },

    func:   {
        dumpFragment: '\uF014',
    },

    preset: {
        add: '\uF055',
        save: '\uF0C7',
        load: '\uF07C',
        dump: '\uF014',
        apply: '\uF090',
        type: {
            unknown: '\u00FF',
            dummy: '\uF10C',
            note: '\u2007',
            camera: '\uF047', //'\uF030',
            cut: '\u200A',
            color: '\uF2B2'
        }
    },

    refresh: '\uF021',

    reset: '\uF0E2',
    help: '\u00FF',

    stop: '\uF04D',
    play: '\uF04B',
    pause: '\uF04C',

    chevronLeft: '\uF053',
    chevronRight: '\uF054',

    caretLeft: '\uF0D9',
    caretRight: '\uF0DA',

    forward: '\uF04E',
    backward: '\uF04A',

    eye: '\uF06E',

    addArrowNote: '\uF055',
    arrowApplyToAll: '\uF122',
	arrowDump: '\uF014', //'\uF1F8',
	arrowLength: '\uF07E',
	arrowWidth: '\uF07D',
	
	noteApplyToAll: '\uF122',
	noteDump: '\uF014', //'\uF1F8',
	noteShowAlways: '\uF06E',
	noteNotShowAlways: '\uF070',
	noteDecFont: '\uF031',
    noteIncFont: '\uF031',
    noteTextColor: '\uF031',
	noteLineColor: '\uF040',
	noteBackgroundColor: '\uF043',
	noteBorder : '\uF24D',
	noteNoBorder: '\uF096',
    noteCornerRadius: '\uF096',
    notePadding: '\u2002',
	noteShowColor: '\uF068',
    noteIsInDocument: '\uF247',
    noteIsNotInDocument: '\uF248',
    notePreset: '\uF045',
    noteFunction: '\u1FF8',

	noteHAlignmentLeft: '\uF226',
	noteHAlignmentCenter: '\uF225',
	noteHAlignmentRight: '\uF224',
	noteVAlignmentTop: '\uF221',
	noteVAlignmentCenter: '\uF222',
	noteVAlignmentBottom: '\uF223',

    ctSwitchOff: '\uF1B2',
    ctSwitchOn: '\uF1B3',

    ctActivationOn: '\uF00C',
    ctActivationOff: '\uF00C',

    ctCategoriesSwitch: '\uF23B',
    ctCategoriesActivation: '\uF23B',

    ctNodeTerminal: '\uF016',
    ctNodeExpanded: '\uF07C',
    ctNode: '\uF07B',
    ctNodeTerminalDot: '\uF111',

    licensingSettings: '\uF013',
    licensingPlay: '\uF144',
    licensingBuy: '\uF07A',
    licensingEnvelope: '\uF0E0',
    licensingKeyInput: '\uF0A9',
    licensingInfoRefresh: '\uF0E2',
    licensingInfoDeactivate: '\uF057',
    licensingInfoRemove: '\uF014',
    licensingInfoAdd: '\uF067',

    download: '\uF019',

    mcItemWithoutSound: '\uF1F7',
    mcItemWithSound: '\uF0A2',
    mcPresentationSlides: '\u221E',
    mcPresentationDuration: '\uF017',
    mcPlayModeBackward: '\uF053',
    mcPlayModeForward: '\uF054',

    overlayTextBox:    {
        textBoxClose: '\uF057',
        textBoxToLeft: '\uF137',
        textBoxToRight: '\uF138'
     },
     
    helpView: {
        advice: '\uF2b7',
        manual: '\uF2b7',
        quickStart: '\u00C6',
        videoTutorials: '\u00C4',
        tutorReset: '\u00C7',
        dot: '\uF111',
        helpHandling: {
            navigation: {
                rotateTouch: '\u1FF6',
                rotateMouse: '\u1FFB',
                moveTouch: '\u1FF5',
                moveMouse: '\u1FFD',
                zoomTouch: '\u1FF4',
                zoomMouse: '\u1FFC',
                resetTouch: '\u1FF2',
                resetMouse: '\u00C3'
            },
            view: {
                contextTouch: '\u1FF8',
                contextMouse: '\u1FFE'
            },
            cut: {
                touchTouch: '\u1FF8',
                touchMouse: '\u1FFD',
                swipeTouch: '\u1FF6',
                swipeMouse: '\u1FFB',
                doubleClickTouch: '\u1FF7',
                doubleClickMouse: '\u1FFF',
                shiftTouch: '',
                shiftMouse: '\u1FFC'
            }
        }
    },

    zeissOpti: {
        targetSwiffel: '\uF021',
        targetArrow: '\uF116',
        pencil: '\uF21C',
        correction: '\uF21B',
        anatomyTarget: '\uF238',
        UVTarget: '\uF239',
        optiTarget: '\uF23A'
    },

    eyeOCT: {
        anatomyTarget: '\uF238',
        optiTarget: '\uF23A',
        octTarget: '\u00CA'
    },

    philipsIcarus: {
        anatomyTarget: '\uF238',
        philipsTarget: '\uF23A',
        resetCamera: '\uF07E',
        textBox: '\uF0F6'
    },

    implantcast:    {
        opTarget: '\u00CD',
        anatomyTarget: '\u00CE',
        startSelection: '\uF009',
        demoAnimLoop: '\uF021'
    },

    tutorForward: '\uF138',
    tutorBackward: '\uF137',
    tutorClose: '\uF057'
};