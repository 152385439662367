import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {VelocityTransitionGroup} from 'velocity-react';

import {OEToolbox} from './oe-toolbox';

export class OEPortal extends React.PureComponent   {

    constructor(props) {
        super(props);

        this.state = {
            wrapperElement: undefined
        };
    }

    componentWillMount()    {
        this.mounted = true;

        if(!this.props.className) return;

        let parent = $(this.props.selector).get(0);
        if(!parent) parent = document.body;
        this.wrapperElement = document.createElement('div');
        this.wrapperElement.setAttribute('class', this.props.className);
        parent.appendChild(this.wrapperElement);

        this.setState({wrapperElement: this.wrapperElement});
    }

    componentWillUnmount()  {
        this.mounted = false;

        if(!this.wrapperElement)    return;
        this.wrapperElement.parentElement.removeChild(this.wrapperElement);
        this.wrapperElement = undefined;
    }

    componentWillUpdate(nextProps)   {
        let parent = $(this.props.selector).get(0);
        if(!parent) parent = document.body;

        let nextParent = $(nextProps.selector).get(0);
        if(!nextParent) nextParent = document.body;

        if(nextParent !== parent || nextProps.className !== this.props.className)  {

            if(!nextProps.className)    {
                this.setState({wrapperElement: undefined});
            } else if(nextParent !== parent)   {
                let wrapperElement = document.createElement('div');
                wrapperElement.setAttribute('class', nextProps.className);
                nextParent.appendChild(this.wrapperElement);
                this.setState({wrapperElement: wrapperElement});
            } else if(this.wrapperElement)  {
                this.wrapperElement.setAttribute('class', nextProps.className);
            } else {
                this.wrapperElement = document.createElement('div');
                this.wrapperElement.setAttribute('class', nextProps.className);
                nextParent.appendChild(this.wrapperElement);
                this.setState({wrapperElement: this.wrapperElement});
            }
        }
    }

    componentDidUpdate()    {
        if(!this.wrapperElement)    return;

        if(!this.mounted)    {
            this.wrapperElement.parentElement.removeChild(this.wrapperElement);
            this.wrapperElement = undefined;
        } else if(this.wrapperElement !== this.state.wrapperElement) {
            this.wrapperElement.parentElement.removeChild(this.wrapperElement);
            this.wrapperElement = this.state.wrapperElement;
        }
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.state.wrapperElement ? this.state.wrapperElement : $(this.props.selector).get(0)
        );
    }
};

OEPortal.defaultProps  ={
    selector: 'body'
};

OEPortal.propTypes = {
    selector: PropTypes.string,
    className: PropTypes.string
};

export class OEWindow extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.state = {mounted: false}

        this.onWindowWillAppear = this.onWindowWillAppear.bind(this);
        this.onWindowDidAppear = this.onWindowDidAppear.bind(this);
        this.onWindowWillDisappear = this.onWindowWillDisappear.bind(this);
        this.onWindowDidDisappear = this.onWindowDidDisappear.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        if(!this.mounted)	{
            this.mounted = true;
            this.setState({mounted: true});
        }
    }

    onWindowWillAppear()	{
        if(typeof(this.props.enterTransition.begin) === 'function') this.props.enterTransition.begin();
    }

    onWindowDidAppear()	{
        if(typeof(this.props.enterTransition.complete) === 'function')  this.props.enterTransition.complete();
    }

    onWindowWillDisappear()	{
        if(typeof(this.props.leaveTransition.begin) === 'function')	this.props.leaveTransition.begin();
    }

    onWindowDidDisappear()	{
        if(typeof(this.props.leaveTransition.complete) === 'function')	this.props.leaveTransition.complete();

        if(!this.props.isOpen && typeof(this.props.onClosed) === 'function')	this.props.onClosed();
    }

    componentDidUpdate(prevProps, prevState)    {
        if(!this.props.leaveTransition.animation && prevProps.isOpen && !this.props.isOpen && typeof(this.props.onClosed) === 'function')	this.props.onClosed();
    }
     
    render() {
        let enter = Object.assign({}, this.props.enterTransition);
        enter.begin = this.onWindowWillAppear;
        enter.complete = this.onWindowDidAppear;

        let leave = Object.assign({}, this.props.leaveTransition);
        leave.begin = this.onWindowWillDisappear;
        leave.complete = this.onWindowDidDisappear;

        return(
            <OEPortal className={this.props.portalClassName}>
                <VelocityTransitionGroup enter={enter} leave={leave}>
                    {!this.props.isOpen || !this.state.mounted ? null :
                        <div
                            className={'window ' + this.props.className}
                            style={this.props.style}
                            onClick={this.onClick}
                            ref={this.props.elementRef}
                        >
                            {this.props.children}
                        </div>
                    }
                </VelocityTransitionGroup>
            </OEPortal>
        );
    } 

    onClick(e) {
        if(typeof(this.props.onClick) === 'function')   this.props.onClick(e);
    }
}

OEWindow.defaultProps = {
    className: '',
    enterTransition: {animation: 'fadeIn', duration: 666, easing: 'ease-in-out'},
    leaveTransition: {animation: 'fadeOut', duration: 666, easing: 'ease-in-out'},
};

OEWindow.propTypes = {
    className: PropTypes.string,
    portalClassName: PropTypes.string
};

export class OEWindowManager extends React.PureComponent {

    constructor(props) {
        super(props);

        this.counter = 0;

        this.state = {windows: new Object()};

        this.onClosed = this.onClosed.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    addWindow(window) {
        if(typeof(window) === 'function')    {
            return this.addWindow(window(this.counter));
        }

        //
        const id = this.counter; this.counter++;
        let entry = new Object();
        entry[id.toString()] = {id: id, isOpen: true, window: window};

        this.setStateUpdate({windows: {$merge: entry}});
        
        return id;
    }
    
    updateWindow(id, window)	{
        this.setState((prevState, props) => {
            prevState.windows[id.toString()].window = window;
            return prevState;
        });
        this.forceUpdate();
    }

    closeWindow(id)  {
        this.setState((prevState, props) => {
            prevState.windows[id.toString()].isOpen = false;
            return prevState;
        });
        this.forceUpdate();
    }

    onClosed(id)    {
        this.setState((prevState, props) => {
            delete prevState.windows[id.toString()];
            return prevState;
        });
        this.forceUpdate();
    }

    render() {
        let windows = [];
        for(let id in this.state.windows)   {
            if(this.state.windows.hasOwnProperty(id))  {
                windows.push(this.state.windows[id]);
            }
        }

        let windowElements = windows.map((entry) =>
            React.cloneElement(entry.window, {key: entry.id, isOpen: entry.isOpen, onClosed: () => this.onClosed(entry.id)})
        );

        return windowElements;
    }
}

export let oeWindowManager = {
    instance: null	
};

export class OEWindowComponent extends React.Component {

    constructor(props) {
        super(props);

        this.id = null;

        this.onWindowWillAppear = this.onWindowWillAppear.bind(this);
        this.onWindowDidAppear = this.onWindowDidAppear.bind(this);
        this.onWindowWillDisappear = this.onWindowWillDisappear.bind(this);
        this.onWindowDidDisappear = this.onWindowDidDisappear.bind(this);
        this.onClick = this.onClick.bind(this);

        this.createWindow();
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillReceiveProps(nextProps) {
        if(!oeWindowManager.instance || this.id === null) return;

        oeWindowManager.instance.updateWindow(this.id, this.createInstance(nextProps)(this.id));
    }

    createWindow()	{
        if(!oeWindowManager.instance || this.id !== null) return;
        
        this.id = oeWindowManager.instance.addWindow(this.createInstance());
    }

    createInstance(props)	{
        props = props || this.props;
        
        let enter = Object.assign({}, props.enterTransition);
        enter.begin = this.onWindowWillAppear;
        enter.complete = this.onWindowDidAppear;

        let leave = Object.assign({}, props.leaveTransition);
        leave.begin = this.onWindowWillDisappear;
        leave.complete = this.onWindowDidDisappear;

        return ((id) =>
            <OEWindow
                className={props.className}
                portalClassName={props.portalClassName}
                style={props.style}
                isOpen={true}
                enterTransition={enter}
                leaveTransition={leave}
                onClick={this.onClick}
                elementRef={props.elementRef}
            >
                {props.children}
            </OEWindow>
        );
    }

    componentWillUnmount() {
        if(!oeWindowManager.instance || this.id === null) return;
        
        oeWindowManager.instance.closeWindow(this.id);
        this.id = null;
    }

    onWindowWillAppear()	{
        if(typeof(this.props.enterTransition.begin) === 'function') this.props.enterTransition.begin();
    }

    onWindowDidAppear()	{
        if(typeof(this.props.enterTransition.complete) === 'function')  this.props.enterTransition.complete();
    }

    onWindowWillDisappear()	{
        if(typeof(this.props.leaveTransition.begin) === 'function')	this.props.leaveTransition.begin();
    }

    onWindowDidDisappear()	{
        if(typeof(this.props.leaveTransition.complete) === 'function')	this.props.leaveTransition.complete();
    }
     
    render() {
        return null;
    } 

    onClick(e) {
        if(typeof(this.props.onClick) === 'function')   this.props.onClick(e);
    }
}

OEWindowComponent.defaultProps = {
    className: '',
    enterTransition: {animation: 'fadeIn', duration: 333, easing: 'ease-in-out'},
    leaveTransition: {animation: 'fadeOut', duration: 333, easing: 'ease-in-out'},
};

OEWindowComponent.propTypes = {
    className: PropTypes.string,
    portalClassName: PropTypes.string
};

export class OEBackdrop extends React.PureComponent { 

    constructor(props) {
        super(props);

        this.onWindowWillDisappear = this.onWindowWillDisappear.bind(this);
        this.onWindowDidDisappear = this.onWindowDidDisappear.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        if(!this.props.enabled) return null;

        let style = {
            backgroundColor: this.props.options.color,
            pointerEvents: this.props.enabled ? 'initial' : 'none'
        };

        let enterTransition = !this.props.options.animated ? null : {animation: 'fadeIn', duration: this.props.options.duration * 1000, easing: 'ease-in-out'};
        let leaveTransition = !this.props.options.animated ? null : {animation: 'fadeOut', duration: this.props.options.duration * 1000, easing: 'ease-in-out', begin: this.onWindowWillDisappear, complete: this.onWindowDidDisappear};

        return(
            <OEWindowComponent
                className={'back-drop ' + this.props.className}
                style={style}
                onClick={this.onClick}
                enterTransition={enterTransition}
                leaveTransition={leaveTransition}
                elementRef={this.props.elementRef}
            > 
                {this.props.children}
            </OEWindowComponent>
        );
    }

    onWindowWillDisappear() {
        this.isDisappearing = true;
    }

    onWindowDidDisappear() {
        this.isDisappearing = false;
    }

    onClick(e)  {
        if(this.isDisappearing) return;
        if(typeof(this.props.onClick) === 'function')   this.props.onClick(e);
    }
}

OEBackdrop.options = {
    clear: {color: 'rgba(0, 0, 0, 0)', animated: true, duration: 0.333}
};

OEBackdrop.defaultProps = {
    enabled: true,
    className: '',
    options: {color: 'rgba(0, 0, 0, 0.2)', animated: true, duration: 0.333}
};

OEBackdrop.propTypes = {
    enabled: PropTypes.bool,
    className: PropTypes.string,
    options: PropTypes.shape({color: PropTypes.string, animated: PropTypes.bool, duration: PropTypes.number})
};