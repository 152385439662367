import React from 'react';
import PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars';

import {OEToolbox} from '../lib/oe-toolbox';
import OEResizeObserver from '../lib/oe-resize-observer';

export default class OEScrollbars extends React.PureComponent {
    
    constructor(props, ...rest) {
        super(props, ...rest);

        this.state = {
            devicePixelRatio: window.devicePixelRatio || 1,
            scrollbarSize: OEToolbox.getScrollbarSize()
        };

        this.completed = [];

        this.onWindowResized = this.onWindowResized.bind(this);

        this.scrollStartCompleted = this.scrollStartCompleted.bind(this);

        this.onRef = this.onRef.bind(this);

        this.renderTrackHorizontal = this.renderTrackHorizontal.bind(this);
        this.renderTrackVertical = this.renderTrackVertical.bind(this);
        this.renderThumbHorizontal = this.renderThumbHorizontal.bind(this);
        this.renderThumbVertical = this.renderThumbVertical.bind(this);
        
        let unsupportedBrowser = false;
        this.renderView = (unsupportedBrowser ? this.renderViewDefault : this.renderView).bind(this);

        this.onContentResize = this.onContentResize.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onScrollStart = this.onScrollStart.bind(this);
        this.onScrollStop = this.onScrollStop.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.autoUpdate !== nextProps.autoUpdate && nextProps.autoUpdate) {
            this.update();
        }
    }

    componentDidMount()    {
        this.onWindowResized();
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
        this.callCompleted();
    }

    onWindowResized()   {
        this.setState({devicePixelRatio: window.devicePixelRatio || 1, scrollbarSize: OEToolbox.getScrollbarSize()});
        //console.log('devicePixelRatio: ' + window.devicePixelRatio.toString() + ', scrollbarSize: ' + scrollbarSize);
    }

    callCompleted() {
        this.completed.forEach(fn => { fn(); });
        this.completed = [];
    }

    scrollStartCompleted()    {
        if(!this.scrollStart)   this.callCompleted();
        this.scrollStart = false;
    }

    scrollTop(top, completed)   {
        if(!this.ref) return;
        let top_ = typeof(top) === 'number' ? top : 0;
        if(completed) this.completed.push(completed);
        this.ref.scrollTop(top_);
        requestAnimationFrame(this.scrollStartCompleted);
    }

    scrollLeft(left, completed)    {
        if(!this.ref) return;
        let left_ = typeof(left) === 'number' ? left : 0;
        if(completed) this.completed.push(completed);
        this.ref.scrollLeft(left_);
        requestAnimationFrame(this.scrollStartCompleted);
    }

    scrollToTop()   {
        if(!this.ref) return;
        this.ref.scrollToTop();
    }

    scrollToBottom()   {
        if(!this.ref) return;
        this.ref.scrollToBottom();
    }

    scrollToLeft()   {
        if(!this.ref) return;
        this.ref.scrollToLeft();
    }

    scrollToRight()   {
        if(!this.ref) return;
        this.ref.scrollToRight();
    }

    getScrollLeft() {
        return this.ref ? this.ref.getScrollLeft() : 0;
    }

    getScrollTop() {
        return this.ref ? this.ref.getScrollTop() : 0;
    }

    getScrollWidth() {
        return this.ref ? this.ref.getScrollWidth() : 0;
    }

    getScrollHeight() {
        return this.ref ? this.ref.getScrollHeight() : 0;
    }

    getClientWidth() {
        return this.ref ? this.ref.getClientWidth() : 0;
    }

    getClientHeight() {
        return this.ref ? this.ref.getClientHeight() : 0;
    }

    getValues() {
        if(this.ref) return this.ref.getValues();
    }

    update()    {
        if(this.ref) this.ref.update();
    }

    onRef(ref)  {
        if(this.ref == ref) return;
        this.callCompleted();
        this.ref = ref;
        this.container = this.ref ? this.ref.container : null;
        if(this.props.elementRef)   this.props.elementRef(ref);
    }

    renderTrackHorizontal({style, ...props})    {
        return <div style={style} {...props} className="track-horizontal" />;
    }
    
    renderTrackVertical({style, ...props})  {
        return <div style={style} {...props} className="track-vertical" />;
    }
    
    renderThumbHorizontal({style, ...props})    {
        return <div style={style} {...props} className="thumb-horizontal" />;
    }
    
    renderThumbVertical({style, ...props})  {
        return <div style={style} {...props} className="thumb-vertical" />;
    }

    renderView({style, ...props}) {
        const customStyle = {marginRight: -this.state.scrollbarSize, marginBottom: -this.state.scrollbarSize};
        return <div style={{...style, ...customStyle}} {...props} className="scroll-content-view"/>;
    }
    
    renderViewDefault({style, ...props}) {
        return <div style={style} {...props}  className="scroll-content-view"/>;
    }

    render() {
        return (
            <Scrollbars
                className={this.props.className}
                style={this.props.style}
                ref={this.onRef}
                onScroll={this.onScroll}
                onScrollStart={this.onScrollStart}
                onScrollStop={this.onScrollStop}
                renderView={this.renderView}
                renderTrackHorizontal={this.renderTrackHorizontal}
                renderTrackVertical={this.renderTrackVertical}
                renderThumbHorizontal={this.renderThumbHorizontal}
                renderThumbVertical={this.renderThumbVertical}
            >
                <div className="position-relative scrollbars-content">
                    {!this.props.autoUpdate && !this.props.onContentResize ? null :
                        <OEResizeObserver onResize={this.onContentResize}/>
                    }
                    {this.props.children}
                </div>
            </Scrollbars>
        );
    }

    onContentResize(sender, size)   {
        if(this.props.autoUpdate)   this.update();
        if(this.props.onContentResize)  this.props.onContentResize(this, size);
    }

    onScroll()  {
        if(this.props.onScroll) this.props.onScroll(this);
    }

    onScrollStart() {
        if(this.props.onScrollStart) this.props.onScrollStart(this);
        this.scrollStart = true;
    }

    onScrollStop()  {
        if(this.props.onScrollStop) this.props.onScrollStop(this);
        this.callCompleted();
    }
}

OEScrollbars.defaultProps = {
    className: '',
    autoUpdate: true
};

OEScrollbars.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    autoUpdate: PropTypes.bool,
    elementRef: PropTypes.func,
    onScroll: PropTypes.func,
    onScrollStart: PropTypes.func,
    onScrollStop: PropTypes.func,
    onContentResize: PropTypes.func
};