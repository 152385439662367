import OEColor from '../components/color-picker/oe-color';
import {OEColorHelper} from '../components/oe-color-helper';
import {OEVersion} from '../react-oe/oe-version';
import {OETarget} from '../react-oe/oe-target';

class OEStyleSheetTool {
    
    constructor() {
        this.rules = {};
        this.additionalStyles = [];
    }

    printStyleSheets()  {
        for(let i = 0; i < document.styleSheets.length; i++) {
            let sheet = document.styleSheets[i];
            console.log(sheet.title);
        }
    }

    printStyleSheetRules(title, range)  {
        let sheet = this.getStyleSheet(title);
        if(!sheet) return;

        let rules = sheet.cssRules;

        let first = !range || !range.first ? 0 : range.first;
        let last = !range || !range.last ? rules.length - 1 : Math.min(range.last, rules.length - 1);

        for(let i = first; i < last; ++i)   {
            console.log(rules[i].cssText);
        }
    }

    getStyleSheet(title) {
        let title_ = title || 'styles';

        for(let i = 0; i < document.styleSheets.length; i++) {
            let sheet = document.styleSheets[i];
            if(sheet.title === title_) {
                return sheet;
            }
        }
    }

    getRule(index, title)   {
        let sheet = this.getStyleSheet(title);
        if(!sheet || sheet.cssRules.length <= index || index < 0) return;
        return sheet.cssRules[index];
    }

    insertRule(name, rule, index, title) {
        if(this.rules[name]) {
            this.rules[name].refCount++;
            return;
        }

        let sheet = this.getStyleSheet(title);
        if(!sheet) return;

        const index_ = typeof(index) === 'number' ? index : sheet.cssRules.length;
        if(index_ > sheet.cssRules.length || index_ < 0) return;
        
        const ruleIndex = sheet.insertRule(rule, index_);
        if(typeof(ruleIndex) !== 'number')   return;

        //
        let keys = Object.keys(this.rules);
        for(let i = 0; i < keys.length; ++i)  {
            let key = keys[i];
            let rule_ = this.rules[key];
            if(rule_.index >= ruleIndex)   {
                rule_.index++;
            }
        }

        this.rules[name] = {index: ruleIndex, refCount: 1};
        return ruleIndex;
    }

    removeRule(name, title, respectRefCount) {
        if(!this.rules[name])   return;
        let rule = this.rules[name];
        if(respectRefCount && rule.refCount > 1)    {
            rule.refCount--; return;
        }
        let sheet = this.getStyleSheet(title);

        if(sheet && rule.index < sheet.cssRules.length && rule.index >= 0 ) {
            sheet.deleteRule(rule.index);
        }

        //
        delete this.rules[name];

        let keys = Object.keys(this.rules);
        for(let i = 0; i < keys.length; ++i)  {
            let key = keys[i];
            if(this.rules[key].index > rule.index)   {
                this.rules[key].index--;
            }
        }
    }

    setFullscreenRule(enable)   {
        if(enable)  {
            //this.insertRule('fullscreen1', ':not(.fullscreen-element) { visibility: hidden !important; -webkit-transform: none !important; transform: none !important;}', 0);
            this.insertRule('fullscreen1', ':not(.fullscreen-element) { visibility: hidden !important; }', 0);
            this.insertRule('fullscreen2', '.modal-dialog { -webkit-transform: none !important; transform: none !important; }', 0);
            this.insertRule('fullscreen3', 'body { background-color: #000000 !important; }', 0);
        } else {
            this.removeRule('fullscreen1');
            this.removeRule('fullscreen2');
            this.removeRule('fullscreen3');
        }

        //this.printStyleSheetRules(null, {last: 3});
    }

    removeBgColorRule(selector) {
        return this.removeRule('bg-' + selector);
    }

    removeColorRule(selector) {
        return this.removeRule('color-' + selector);
    }

    removeBorderColorRule(selector) {
        return this.removeRule('border-color' + selector);
    }

    removeBorderRule(selector) {
        return this.removeRule('border-' + selector);
    }

    addBgColorRule(selector, color) {
        return this.insertRule('bg-' + selector, selector + ' { background-color: ' + OEColor.toDOMStr(color) + ' !important; }');
    }

    addColorRule(selector, color) {
        return this.insertRule('color-' + selector, selector + ' { color: ' + OEColor.toDOMStr(color) + ' !important; }');
    }

    addBorderColorRule(selector, color) {
        return this.insertRule('border-color' + selector, selector + ' { border-color: ' + OEColor.toDOMStr(color) + ' !important; }');
    }

    addBorderRule(selector, color) {
        return this.insertRule('border-' + selector, selector + ' { border: 1px solid ' + OEColor.toDOMStr(color) + ' !important; }');
    }

    removeColorStyle() {
        const multi = [{pf: ''}, {pf: '-border'}];

        for(let i = 0; i < multi.length; ++i)   {
            const m = multi[i];

            /*
            this.removeBgColorRule('.extra-light-bg' + m.pf);
            this.removeBgColorRule('.extra-light-bg' + m.pf + '.active');
            this.removeBgColorRule('.extra-light-hover-bg' + m.pf);
            this.removeBgColorRule('.extra-light-hover-bg' + m.pf + '.active');
            this.removeBgColorRule('.extra-light-hover-bg' + m.pf + ':hover');
            this.removeBgColorRule('.extra-light-hover-bg' + m.pf + '.active:hover');
            this.removeBgColorRule('.extra-light-bg' + m.pf + '.disabled:hover');
            this.removeBgColorRule('.extra-light-hover-bg' + m.pf + '.disabled:hover');
            this.removeBgColorRule('.extra-light-hover-bg-hover' + m.pf + ':hover');
            */

            this.removeBgColorRule('.light-bg' + m.pf);
            this.removeBgColorRule('.light-bg' + m.pf + '.active');
            this.removeBgColorRule('.light-hover-bg' + m.pf);
            this.removeBgColorRule('.light-hover-bg' + m.pf + '.active');
            this.removeBgColorRule('.light-hover-bg' + m.pf + ':hover');
            this.removeBgColorRule('.light-hover-bg' + m.pf + '.active:hover');
            this.removeBgColorRule('.light-bg' + m.pf + '.disabled:hover');
            this.removeBgColorRule('.light-hover-bg' + m.pf + '.disabled:hover');
            this.removeBgColorRule('.light-hover-bg-hover' + m.pf + ':hover');

            this.removeBgColorRule('.std-bg' + m.pf);
            this.removeBgColorRule('.std-bg' + m.pf + '.active');
            this.removeBgColorRule('.std-hover-bg' + m.pf);
            this.removeBgColorRule('.std-hover-bg' + m.pf + '.active');
            this.removeBgColorRule('.std-hover-bg' + m.pf + ':hover');
            this.removeBgColorRule('.std-hover-bg' + m.pf + '.active:hover');
            this.removeBgColorRule('.std-bg' + m.pf + '.disabled:hover');
            this.removeBgColorRule('.std-hover-bg' + m.pf + '.disabled:hover');
            this.removeBgColorRule('.std-hover-bg-hover' + m.pf + ':hover');

            this.removeBgColorRule('.main-menu-bg' + m.pf);
            this.removeBgColorRule('.std-toolbar-bg' + m.pf);
            this.removeBgColorRule('.view-bg' + m.pf);
            this.removeBgColorRule('.start-view-bg' + m.pf);

            this.removeColorRule('.std-label-text-color' + m.pf);
            this.removeColorRule('.view-port-label-text-color' + m.pf);
            this.removeColorRule('.main-menu-text-color' + m.pf);

            this.removeBgColorRule('.std-separator-color' + m.pf);
            this.removeBgColorRule('.std-overlay-view-bg' + m.pf);

            this.removeBorderColorRule('.std-label-border-color' + m.pf);
            this.removeBorderColorRule('.std-border-color' + m.pf);
            this.removeBorderColorRule('.std-separator-border-color' + m.pf);
            this.removeBorderColorRule('.dark-border-color' + m.pf);
            this.removeBorderColorRule('.dark-separator-border-color' + m.pf);

            this.removeBorderRule('.std-border' + m.pf);
            this.removeBorderRule('.dark-border' + m.pf);
            this.removeBorderRule('.view-port-border' + m.pf);
            this.removeBorderRule('.media-center-slide-preview-magni-border' + m.pf);
        }
    }

    addColorStyle(brightness, borderBrightness) {
        const multi = [{pf: '', brightness: brightness}, {pf: '-border', brightness: borderBrightness}];

        for(let i = 0; i < multi.length; ++i)   {
            const m = multi[i];

            /*
            this.addBgColorRule('.extra-light-bg' + m.pf, OEColorHelper.extraLightBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-bg' + m.pf + '.active', OEColorHelper.extraLightActiveBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-hover-bg' + m.pf, OEColorHelper.extraLightBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-hover-bg' + m.pf + '.active', OEColorHelper.extraLightActiveBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-hover-bg' + m.pf + ':hover', OEColorHelper.extraLightHoverBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-hover-bg' + m.pf + '.active:hover', OEColorHelper.extraLightActiveHoverBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-bg' + m.pf + '.disabled:hover', OEColorHelper.extraLightBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-hover-bg' + m.pf + '.disabled:hover', OEColorHelper.extraLightBackgroundColor(m.brightness));
            this.addBgColorRule('.extra-light-hover-bg-hover' + m.pf + ':hover', OEColorHelper.extraLightHoverBackgroundColor(m.brightness));
            */

            this.addBgColorRule('.light-bg' + m.pf, OEColorHelper.lightBackgroundColor(m.brightness));
            this.addBgColorRule('.light-bg' + m.pf + '.active', OEColorHelper.lightActiveBackgroundColor(m.brightness));
            this.addBgColorRule('.light-hover-bg' + m.pf, OEColorHelper.lightBackgroundColor(m.brightness));
            this.addBgColorRule('.light-hover-bg' + m.pf + '.active', OEColorHelper.lightActiveBackgroundColor(m.brightness));
            this.addBgColorRule('.light-hover-bg' + m.pf + ':hover', OEColorHelper.lightHoverBackgroundColor(m.brightness));
            this.addBgColorRule('.light-hover-bg' + m.pf + '.active:hover', OEColorHelper.lightActiveHoverBackgroundColor(m.brightness));
            this.addBgColorRule('.light-bg' + m.pf + '.disabled:hover', OEColorHelper.lightBackgroundColor(m.brightness));
            this.addBgColorRule('.light-hover-bg' + m.pf + '.disabled:hover', OEColorHelper.lightBackgroundColor(m.brightness));
            this.addBgColorRule('.light-hover-bg-hover' + m.pf + ':hover', OEColorHelper.lightHoverBackgroundColor(m.brightness));

            this.addBgColorRule('.std-bg' + m.pf, OEColorHelper.stdBackgroundColor(m.brightness));
            this.addBgColorRule('.std-bg' + m.pf + '.active', OEColorHelper.stdActiveBackgroundColor(m.brightness));
            this.addBgColorRule('.std-hover-bg' + m.pf, OEColorHelper.stdBackgroundColor(m.brightness));
            this.addBgColorRule('.std-hover-bg' + m.pf + '.active', OEColorHelper.stdActiveBackgroundColor(m.brightness));
            this.addBgColorRule('.std-hover-bg' + m.pf + ':hover', OEColorHelper.stdHoverBackgroundColor(m.brightness));
            this.addBgColorRule('.std-hover-bg' + m.pf + '.active:hover', OEColorHelper.stdActiveHoverBackgroundColor(m.brightness));
            this.addBgColorRule('.std-bg' + m.pf + '.disabled:hover', OEColorHelper.stdBackgroundColor(m.brightness));
            this.addBgColorRule('.std-hover-bg' + m.pf + '.disabled:hover', OEColorHelper.stdBackgroundColor(m.brightness));
            this.addBgColorRule('.std-hover-bg-hover' + m.pf + ':hover', OEColorHelper.stdHoverBackgroundColor(m.brightness));

            this.addBgColorRule('.main-menu-bg' + m.pf, OEColorHelper.mainMenuBackgroundColor(m.brightness));
            this.addBgColorRule('.std-toolbar-bg' + m.pf, OEColorHelper.stdToolbarBackgroundColor(m.brightness));
            this.addBgColorRule('.view-bg' + m.pf, OEColorHelper.viewBackgroundColor(m.brightness));
            this.addBgColorRule('.start-view-bg' + m.pf, OEColorHelper.startViewBackgroundColor(m.brightness));

            this.addColorRule('.std-label-text-color' + m.pf, OEColorHelper.stdLabelTextColor(m.brightness));
            this.addColorRule('.view-port-label-text-color' + m.pf, OEColorHelper.viewPortLabelTextColor(m.brightness));
            this.addColorRule('.main-menu-text-color' + m.pf, OEColorHelper.mainMenuTextColor(m.brightness));

            this.addBgColorRule('.std-separator-color' + m.pf, OEColorHelper.stdSeparatorColor(m.brightness));
            this.addBgColorRule('.std-overlay-view-bg' + m.pf, OEColorHelper.stdOverlayViewBackgroundColor(m.brightness));

            this.addBorderColorRule('.std-label-border-color' + m.pf, OEColorHelper.stdLabelTextColor(m.brightness));
            this.addBorderColorRule('.std-border-color' + m.pf, OEColorHelper.stdSeparatorColor(m.brightness));
            this.addBorderColorRule('.std-separator-border-color' + m.pf, OEColorHelper.stdSeparatorColor(m.brightness));
            this.addBorderColorRule('.dark-border-color' + m.pf, OEColorHelper.darkSeparatorColor(m.brightness));
            this.addBorderColorRule('.dark-separator-border-color' + m.pf, OEColorHelper.darkSeparatorColor(m.brightness));

            this.addBorderRule('.std-border' + m.pf, OEColorHelper.stdSeparatorColor(m.brightness));
            this.addBorderRule('.dark-border' + m.pf, OEColorHelper.darkSeparatorColor(m.brightness));
            this.addBorderRule('.view-port-border' + m.pf, OEColorHelper.viewPortLabelTextColor(m.brightness));

            let color = OEColorHelper.viewPortLabelTextColor(m.brightness); color.w = 0.5;
            this.addBorderRule('.media-center-slide-preview-magni-border' + m.pf, color);
        }
    }

    updateColorStyle(brightness, borderBrightness)  {
        let brightness_ = typeof(brightness) === 'number' ? brightness : 0.6;
        let borderBrightness_ = typeof(borderBrightness) === 'number' ? borderBrightness : 0.6;

        if(this.lastBrightness === brightness_ && this.lastBorderBrightness === borderBrightness_)  return;
        this.lastBrightness = brightness_;
        this.lastBorderBrightness = borderBrightness_;

        this.removeColorStyle();
        this.addColorStyle(brightness_, borderBrightness_);
    }

    updateFontStyle(fontFamily)  {
        this.removeRule('font-body');
        this.removeRule('font-popover');
        this.removeRule('font-textarea');

        let fontFamily_ = fontFamily || 'Roboto,sans-serif';
        let rule = ' font-family: ' + fontFamily_ + ' ; ';

        this.insertRule('font-body', 'body { ' + rule + ' }');
        this.insertRule('font-popover', '.popover { ' + rule + ' }');
        this.insertRule('font-textarea', 'textarea { ' + rule + ' }');
    }

    loadAdditionalStyles(config)  {
        let head = document.head;

        let file = null;
        if(config.version === OEVersion.zeissOpti)  {
            file = 'app/public/style_ext_zeiss_opti.css';
        } else if(config.version === OEVersion.eyeOCT)  {
            file = 'app/public/style_ext_eye_oct.css';
        } else if(config.version === OEVersion.cambridgeCell)  {
            file = 'app/public/style_ext_cambridge.css';
        } else if(config.target === OETarget.philipsIcarus || config.version === OEVersion.philipsIcarus)  {
            file = 'app/public/style_ext_philips_icarus.css';
        } else if(config.version === OEVersion.implantcast)  {
            file = 'app/public/style_ext_implantcast.css';
        }

        this.additionalStyles = this.additionalStyles.filter((style) => {
            if(style.file !== file) head.removeChild(link);
            return style.file === file;
        });

        if(!file || this.additionalStyles.findIndex(style => style.file === file) >= 0) return;

        let link = document.createElement('link');

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = file;

        head.appendChild(link);

        this.additionalStyles.push({file: file, link: link});
    }

    updateForConfig(config)   {
        this.updateFontStyle(config.fontFamily);
        this.loadAdditionalStyles(config);
    }
}
    
export let oeStyleSheetTool = new OEStyleSheetTool();