import React from 'react';
import PropTypes from 'prop-types';

import {OETarget} from '../../../react-oe/oe-target';
import {OEStartViewStdHeaderTutorial, OEZeissUVStartViewHeader} from './oe-start-view-std-header';

export default class OEStartViewStdHeaderFactory extends React.PureComponent {

    render() {

        var key = 0;
        var headers = new Array();

        /*
        if(this.props.target === OETarget.zeissUV) {
            headers.push(( <OEZeissUVStartViewHeader key={key++} moduleId={this.props.moduleId} config={this.props.config}/> ));
        }
        */

        headers.push(( <OEStartViewStdHeaderTutorial key={key++} moduleId={this.props.moduleId} config={this.props.config}/> ));

        return (
            <div
                className={'start-view-header-factory'}
            >
                {headers}
            </div>
        );
    }
}

OEStartViewStdHeaderFactory.defaultProps = {
    moduleId: ''
};

OEStartViewStdHeaderFactory.propTypes = {
    moduleId: PropTypes.string
};