import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEIcon from '../../elements/oe-icon';
import OEButton from '../../elements/oe-button';
import OEAnimationControl from '../../animation-control/oe-animation-control';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEMediaCenterPlayModeControls extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            uiEnabled: false,
            canForward: false,
            canBackward: false
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
        this.onActiveSlideChanged = this.onActiveSlideChanged.bind(this);
        this.onSlideAdded = this.onSlideAdded.bind(this);
        this.onSlideRemoved = this.onSlideRemoved.bind(this);
        this.onSlideMoved = this.onSlideMoved.bind(this);
        this.onSlideDataChanged = this.onSlideDataChanged.bind(this);

        this.updateCanForwardBackwardState = this.updateCanForwardBackwardState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }

        if(nextProps.isPlayerPreview !== this.props.isPlayerPreview || nextProps.wrap !== this.props.wrap || nextProps.noForwarBackwardButtons !== this.props.noForwarBackwardButtons)     {
            this.updateCanForwardBackwardState(nextProps);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationSlideChanged, this.onActiveSlideChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAdded, this.onSlideAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideRemoved, this.onSlideRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideMoved, this.onSlideMoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideDataChanged, this.onSlideDataChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationSlideChanged, this.onActiveSlideChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAdded, this.onSlideAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideRemoved, this.onSlideRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideMoved, this.onSlideMoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideDataChanged, this.onSlideDataChanged);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({ uiEnabled: false, canForward: false, canBackward: false });
            return;
        }

        retardUpdate(this, () => {
            this.setState({ uiEnabled: true });
            this.updateCanForwardBackwardState();
        });
    }

    onActivePresentationChanged()   {
        this.updateCanForwardBackwardState();
    }

    onActiveSlideChanged()  {
        this.updateCanForwardBackwardState();
    }

    onSlideAdded()  {
        this.updateCanForwardBackwardState();
    }

    onSlideRemoved()   {
        this.updateCanForwardBackwardState();
    }

    onSlideMoved()   {
        this.updateCanForwardBackwardState();
    }

    onSlideDataChanged()   {
        this.updateCanForwardBackwardState();
    }

    updateCanForwardBackwardState(props) {
        if(!this.oe.isReady()) return;
        let props_ = props || this.props;
        if(props_.noForwarBackwardButtons)  return;
        let controller = this.oe.sharedInterface.getUIControllerPresentation();
        this.setState({ canForward: controller.isNextSlideAvailable(props_.isPlayerPreview, props_.wrap), canBackward: controller.isPrevSlideAvailable(props_.isPlayerPreview, props_.wrap) });
    }

    render()    {
        const enabled = this.props.enabled && this.state.uiEnabled;

        return (
            <div className="play-mode-controls">
                <div className="control-bar">
                    {this.props.noForwarBackwardButtons ? null :
                        <OEButton
                            className="transparent-btn animation-controls-btn backward"
                            disabled={!(enabled && this.state.canBackward)}
                            onPressed={this.props.onBackwardBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.mcPlayModeBackward}/>
                        </OEButton>
                    }
                    <OEAnimationControl 
                        enabled={enabled}
                        slider={{visible: false, enabled: false}}
                        control={{playBtnVisible: this.props.playBtnVisible, doNotDisableStopBtn: this.props.doNotDisableStopBtn}}
                        adapter={this.props.adapter}
                        onPlayBtnPressed={this.props.onPlayBtnPressed}
                        onPauseBtnPressed={this.props.onPauseBtnPressed}
                        onStopBtnPressed={this.props.onStopBtnPressed}
                    />
                    {this.props.noForwarBackwardButtons ? null :
                        <OEButton
                            className="transparent-btn animation-controls-btn forward"
                            disabled={!(enabled && this.state.canForward)}
                            onPressed={this.props.onForwardBtnPressed}
                        >
                            <OEIcon code={OEIconCodes.mcPlayModeForward}/>
                        </OEButton>
                    }
                </div>
            </div>
        )
    }
}

OEMediaCenterPlayModeControls.defaultProps = {
    moduleId: '',
    enabled: true,
    isPlayerPreview: false,
    wrap: false,
    playBtnVisible: true,
    doNotDisableStopBtn: true,
    noForwarBackwardButtons: false
};

OEMediaCenterPlayModeControls.propTypes = {
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    isPlayerPreview: PropTypes.bool,
    wrap: PropTypes.bool,
    playBtnVisible: PropTypes.bool,
    doNotDisableStopBtn: PropTypes.bool,
    noForwarBackwardButtons: PropTypes.bool
};