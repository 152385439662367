import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEStdModalController from '../../modals/oe-std-modal-controller';
import {OEToolbox} from '../../../lib/oe-toolbox';

export default class OEZeissUVWelcomeView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.target = this.props.target;

        this.isOpen_ = false;

        this.state = {
            isOpen: this.isOpen_,
            strings: {
                welcome: 'Welcome to\nthe',
                explorer: 'ZEISS\nEyeExplorer',
                sneakPreview: 'Sneak Preview*',
                techInnovation: 'Technology & Innovation\n*online until 31 December 2018'
            }
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onToggle = this.onToggle.bind(this);
    }

    setOpen(open = true)   {
        if(typeof(this.props.isOpen) === 'boolean' || this.isOpen_ === open) return;
        this.isOpen_ = open;
        this.setState({isOpen: this.isOpen_});
        if(typeof(this.props.onOpenStateChanged) === 'function')  this.props.onOpenStateChanged(this, this.isOpen_);
    }

    isOpen()    {
        return this.isOpen_;
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        setTimeout(this.onTimeout.bind(this), 500);
    }

    onTimeout() {
        this.setOpen(true);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {
        this.setState({strings: {
            welcome: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_welcome'),
            explorer: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_explorer'),
            sneakPreview: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_sneak_preview'),
            techInnovation: this.oe.sharedInterface.getLocalizedStringEnc('start_view_zeiss_tech_innovation')
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    render() {
        var isOpen = typeof(this.props.isOpen) === 'boolean' ? this.props.isOpen : this.state.isOpen;

        return (
            <OEStdModalController 
                moduleId={this.props.moduleId}
                className="welcome-view-controller"
                isOpen={isOpen}
                onToggle={this.onToggle}
            >

                <div className="content zeiss-uv">
                    <img className="logo" src="images/zeiss_logo.png" />
                    <span className="line line-1">{OEToolbox.stringToHtml(this.state.strings.welcome)}</span>
                    <span className="line line-2">{OEToolbox.stringToHtml(this.state.strings.explorer)}</span>
                    <span className="line line-3">{OEToolbox.stringToHtml(this.state.strings.sneakPreview)}</span>
                    <span className="line line-4">{OEToolbox.stringToHtml(this.state.strings.techInnovation)}</span>
                </div>

            </OEStdModalController>
        );
    }

    onToggle() { 
        if(typeof(this.props.onToggle) === 'function')  {
            this.props.onToggle();
        } else {
            this.setOpen(false);
        }
    }
}

OEZeissUVWelcomeView.defaultProps = {
    moduleId: ''
};

OEZeissUVWelcomeView.propTypes = {
    moduleId: PropTypes.string
};