import React from 'react';  
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEIcon from '../../elements/oe-icon';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEAssetSelectionType} from './oe-asset-selection-model';

export class OEAssetSelectionCell extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.animations = [];
        this.presentations = [];

        this.state = {
            label: '',
            img: null
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onClick = this.onClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.name !== this.props.name)     {
            this.updateLabels(nextProps);
        }
        if(nextProps.thumbImagePath !== this.props.thumbImagePath)     {
            this.updateImage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage()   {
        this.updateLabels();
        this.updateImage();
    }

    updateLabels(props)   {
        props = props || this.props;
        let label = '';
        if(this.oe.isReady() && props.name && props.name.length)    {
            let locName = this.oe.sharedInterface.getLocalizedStringEnc(props.name);
            label = locName !== '#null' ? locName : props.name;
        }
        this.setState({label: label});
    }

    updateImage(props)  {
        props = props || this.props;
        let img = null;
        if(this.oe.isReady() && props.thumbImagePath && props.thumbImagePath.length)    {
            let locImage = this.oe.sharedInterface.getLocalizedStringEnc(props.thumbImagePath);
            if(locImage !== '#null')    img = locImage;
        }
        if(img) img = 'url(' + 'app/oe/' + this.oe.Module.filePackagePathURL + img + ')';
        this.setState({img: img});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({
                label: '',
                img: null
            });
            return;
        }

        retardUpdate(this, () => {
            this.updateLabels();
            this.updateImage();
        });
    }

    render()    {
        let style = this.state.img ? {backgroundImage: this.state.img} : null;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'asset-selection-cell' + (this.props.selected ? ' selected' : '')} onClick={this.onClick}>
                    <div className="cell-border"/>
                    <div className="container">
                        <div className="image" style={style}/>
                        <OEIcon code={OEIconCodes.assetSelection.typeIcons[this.props.type]}/>
                        <div className="label">
                            {this.state.label}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onClick(e) {
        if(this.props.onClick)  this.props.onClick(this.props.type, this.props.ids, this.props.index, e);
    }
};

OEAssetSelectionCell.defaultProps = {
    moduleId: '',
    selected: false,
    type: OEAssetSelectionType.dummy,
    ids: {first: -1, second: -1}
};

OEAssetSelectionCell.propTypes = {
    moduleId: PropTypes.string,
    selected: PropTypes.bool,
    index: PropTypes.number,
    type: PropTypes.oneOf([OEAssetSelectionType.dummy, OEAssetSelectionType.animation, OEAssetSelectionType.presentation]),
    ids: PropTypes.shape({
        first: PropTypes.number.isRequired,
        second: PropTypes.number.isRequired,
    }),
    thumbImagePath: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func
};