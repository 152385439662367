import React, {useContext}  from 'react';
import update from 'immutability-helper';

import {copyComponentKeys} from './oe-higher-order-components';

const OEEnvContextHelper = {

    createContext: function(ContextEnvModel, displayName)    {
        let context = React.createContext({env: new ContextEnvModel(), setEnv: () => {}, updateEnv: () => {}});
        if(typeof(displayName) === 'string') context.displayName = displayName;
        return context;
    },

    contextProviderComponent: function(Context, ContextEnvModel) {
        return (
        class extends React.PureComponent {

            constructor(props) {
                super(props);
        
                this.setEnv = this.setEnv.bind(this);
                this.updateEnv = this.updateEnv.bind(this);
        
                this.state = {
                    env: new ContextEnvModel(),
                    setEnv: this.setEnv,
                    updateEnv: this.updateEnv
                };
            }
        
            setEnv(env) {
                if(typeof(env) === 'function')  {
                    this.setState((prevState) => { return {
                        env: env(prevState.env),
                        setEnv: prevState.setEnv,
                        updateEnv: prevState.updateEnv
                    }});
                } else {
                    this.setState({env: env});
                }
            }

            updateEnv(spec) {
                this.setState((prevState) => { return {
                    env: update(prevState.env, spec),
                    setEnv: prevState.setEnv,
                    updateEnv: prevState.updateEnv
                }});
            }
        
            render() {
                return (
                    <Context.Provider value={this.state}>
                        {this.props.children}
                    </Context.Provider>
                );
            }
        });
    },

    // returns a HOC for embedding given WrappedComponent into an context (ContextProvider)
    withContext: function(ContextProvider, Hooks) {
        return (WrappedComponent) => {
            return copyComponentKeys(function(props) {
                return (
                    <ContextProvider>
                        {Hooks ? <Hooks/> : null}
                        <WrappedComponent {...props}/>
                    </ContextProvider>
                );
            }, WrappedComponent);
        };
    },

    /*
    withContext: function(ContextProvider, Hooks) {
        return (WrappedComponent) => {
            return copyComponentKeys(class extends React.PureComponent {
                render()    {
                    return (
                        <ContextProvider>
                            {Hooks ? <Hooks/> : null}
                            <WrappedComponent {...this.props}/>
                        </ContextProvider>
                    );
                }
            }, WrappedComponent);
        };
    },
    */

    useEnv: function(Context)   {
        // custom hooks for Context environent subscription
        return () => {
            var ctx = useContext(Context);
            return [ctx.env, ctx.setEnv, ctx.updateEnv];
        };
    },

    // returns a HOC for adding environment as env prop
    addEnv: function(Context)   {
        return (WrappedComponent) => {
            return copyComponentKeys((props) => {
                const [env, setEnv, updateEnv] = this.useEnv(Context)();
                return <WrappedComponent contextnv={env} setEnv={setEnv} updateEnv={updateEnv} {...props} />;
            }, WrappedComponent);
        };
    },

    // creates HOCs for context state reduction defined by given function constructState: (ContextEnvModel, set, update) => props for WrappedComponent
    connectEnv: function(Context)  {
        return (constructState) => {
            return (WrappedComponent) => {
                return copyComponentKeys(this.addEnv(Context)(class extends React.PureComponent {
        
                    constructor(props) {
                        super(props);
                        this.state = constructState(this.props.env, this.props.setEnv, this.props.updateEnv);
                    }
        
                    componentWillReceiveProps(nextProps) {
                        if(this.props.env !== nextProps.env, this.props.setEnv !== nextProps.setEnv, this.props.updateEnv !== nextProps.updateEnv) {
                            this.setState(constructState(nextProps.env, nextProps.setEnv, nextProps.updateEnv))
                        }
                    }
        
                    render() {
                        const {env, setEnv, updateEnv, ...rest} = this.props;
        
                        if(WrappedComponent.defaultProps)   {
                            Object.keys(this.state).forEach(key => {
                                if(rest[key] === WrappedComponent.defaultProps[key]) delete rest[key];
                            });
                        } else {
                            Object.keys(this.state).forEach(key => {
                                delete rest[key];
                            });
                        }
        
                        return <WrappedComponent {...this.state} {...rest}/>;
                    }
        
                }), WrappedComponent);
            };
        };
    }
};

export default OEEnvContextHelper;