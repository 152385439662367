import React, {useContext}  from 'react';

import {copyComponentKeys} from '../lib/oe-higher-order-components';
import OEEnvContextHelper from '../lib/oe-env-context-helper';
import AppComponent from './app-component';
import AppConfig from './app-config';
import AppState from './app-state';

// data model for app environment state
export default class AppEnv {
    constructor(component, config, state) {
        this.component = component || new AppComponent();
        this.config = config || new AppConfig();
        this.state = state || new AppState();
    }
}

// define a context holding AppEnv
export const AppEnvContext = OEEnvContextHelper.createContext(AppEnv, 'AppEnv');

export const AppEnvContextProvider = OEEnvContextHelper.contextProviderComponent(AppEnvContext, AppEnv);

export function GlobalAppEnvHooks()    {
    const [state, setState] = useAppState();
    const [config, setConfig] = useAppConfig();
    global.setAppState = setState;
    global.setAppConfig = setConfig;
    return null;
}

// HOC for embedding given App into an AppEnvContext
export const withAppEnv = OEEnvContextHelper.withContext(AppEnvContextProvider, GlobalAppEnvHooks);

// custom hooks for AppEnvContext subscription
export const useAppEnv = OEEnvContextHelper.useEnv(AppEnvContext);

export function useAppComponent() {
    var ctx = useContext(AppEnvContext);
    var setter = function(comp) {
        Object.assign(ctx.env.component, comp);
    }
    return [ctx.env.component, setter];
}

export function useAppConfig() {
    var ctx = useContext(AppEnvContext);
    var setter = function(config) {
        ctx.setEnv(new AppEnv(ctx.env.component, {...ctx.env.config, ...config}, ctx.env.state));
    }
    return [ctx.env.config, setter];
}

export function useAppState() {
    var ctx = useContext(AppEnvContext);
    var setter = function(state) {
        ctx.setEnv(new AppEnv(ctx.env.component, ctx.env.config, {...ctx.env.state, ...state}));
    }
    return [ctx.env.state, setter];
}

// HOCs for adding environment as env prop
export function addAppEnv(WrappedComponent) {
    return copyComponentKeys(function(props)  {
        const [appEnv, setAppEnv, updateAppEnv] = useAppEnv();
        return <WrappedComponent appEnv={appEnv} setAppEnv={setAppEnv} updateAppEnv={updateAppEnv} {...props} />;
    }, WrappedComponent);
}

export function assembleAppEnv(WrappedComponent) {
    return copyComponentKeys(class extends React.PureComponent {

        constructor(props) {
            super(props);
            this.state = this.constructState(this.props);
        }

        constructState(props)   {
            return {
                set: props.setAppEnv,
                update: props.updateAppEnv,
                component: props.appEnv.component,
                setComponent: function(comp) {
                    Object.assign(props.appEnv.component, comp);
                },
                config: props.appEnv.config,
                setConfig: function(config) {
                    props.setAppEnv(new AppEnv(props.appEnv.component, {...props.appEnv.config, ...config}, props.appEnv.state));
                },
                state: props.appEnv.state,
                setState: function(state) {
                    props.setAppEnv(new AppEnv(props.appEnv.component, props.appEnv.config, {...props.appEnv.state, ...state}));
                }
            };
        }

        componentWillReceiveProps(nextProps) {
            if(this.props.appEnv !== nextProps.appEnv || this.props.setAppEnv !== nextProps.setAppEnv || this.props.updateAppEnv !== nextProps.updateAppEnv) {
                this.setState(this.constructState(nextProps))
            }
        }

        render() {
            const props = {...this.props};
            delete props.appEnv;
            delete props.setAppEnv;
            delete props.updateAppEnv;
            return <WrappedComponent appEnv={this.state} {...props} />;
        }

    }, WrappedComponent);
}

export function addAssembledAppEnv(WrappedComponent) {
    return addAppEnv(assembleAppEnv(WrappedComponent));
}

// creates HOCs for global state reduction defined by given function constructState: (assembled AppEnv) => props for WrappedComponent
export function connectAppEnv(constructState) {
    return (WrappedComponent) => {
        return copyComponentKeys(addAssembledAppEnv(class extends React.PureComponent {

            constructor(props) {
                super(props);
                this.state = constructState(this.props.appEnv);
            }

            componentWillReceiveProps(nextProps) {
                if(this.props.appEnv !== nextProps.appEnv) {
                    this.setState(constructState(nextProps.appEnv))
                }
            }

            render() {
                const {appEnv, ...rest} = this.props;

                if(WrappedComponent.defaultProps)   {
                    Object.keys(this.state).forEach(key => {
                        if(rest[key] === WrappedComponent.defaultProps[key]) delete rest[key];
                    });
                } else {
                    Object.keys(this.state).forEach(key => {
                        delete rest[key];
                    });
                }

                return <WrappedComponent {...this.state} {...rest}/>;
            }

        }), WrappedComponent);
    };
}