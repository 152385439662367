
export default class OEFlowNavStartSelectionViewModel  {

    constructor(categories) {
        this.categories = Array.isArray(categories) ? categories : [];
    }

    forEach(fn) {
        if(!Array.isArray(this.categories) || typeof(fn) !== 'function')  return;

        for(let i = 0; i < this.categories.length; ++i) {
            let category = this.categories[i];
            if(!category)   continue;

            let ret = fn(category, 'category');
            if(ret)    return ret;
            
            if(!Array.isArray(category.subcategories))   continue;

            for(let j = 0; j < category.subcategories.length; ++j)  {
                let subcategory = category.subcategories[j];
                if(!subcategory)   continue;
                
                ret = fn(subcategory, 'subcategory');
                if(ret)    return ret;

                if(!Array.isArray(subcategory.items))   continue;
    
                for(let k = 0; k < subcategory.items.length; ++k)   {
                    let item = subcategory.items[k];
                    if(!item)   continue;

                    ret = fn(item, 'item');
                    if(ret)    return ret;
                }
            }
        }
    }

    itemForTarget(target)   {
        if(typeof(target) !== 'string')  return;
        return this.forEach((data, type) => {
            if(type === 'item' && data.target === target)   return data;
        });
    }

    categoryForItem(item)   {
        if(!item)   return;
        let lastCategory;
        return this.forEach((data, type) => {
            if(type === 'category') lastCategory = data;
            if(type === 'item' && data === item)    return lastCategory; 
        });
    }

    indexForCategory(category)  {
        if(!Array.isArray(this.categories) || !category ) return -1;
        return this.categories.indexOf(category);
    }

    categoryIndexForTarget(target)  {
        return this.indexForCategory(this.categoryForItem(this.itemForTarget(target)));
    }

    localizedStrings(sharedInterface)  {
        let ret = {};

        this.forEach((data, type) => {
            if(!data.label || !data.label.id)   return;
            ret[data.label.id] = sharedInterface ? sharedInterface.getLocalizedStringEnc(data.label.id) : '';
        });

        return ret;
    }
}