
export default class KeyedCurve {

    constructor() {
        this.list = new Array();
    }

    add(key, value) {
        this.list.push({key, value});
    }

    pairsForKey(key)    {

        if(this.list.length == 0) return null;

        const length = this.list.length;

        if(key < this.list[0].key)  {
            return {first: this.list[0], second: this.list[0]};
        }

        if(key >= this.list[length-1].key)  {
            return {first: this.list[length-1], second: this.list[length-1]};
        }

        for(var index = 1; index < length; index++) {
            if(key >= this.list[index-1].key && key < this.list[index].key)  {
                return {first: this.list[index-1], second: this.list[index]};
            }
        }

        return null;
    }

    valuesForKey(key)   {
        var pairs = this.pairsForKey(key);
        if(!pairs) return null;
        return {first: pairs.first.value, second: pairs.second.value};
    }

    interpolatedValue(key)  {
        var pairs = this.pairsForKey(key);
        if(!pairs) return null;
        var dis = pairs.second.key - pairs.first.key;
        if(dis <= 0) return pairs.first.value;
        var frac = Math.max(0, Math.min(1, (key - pairs.first.key) / dis));
        return (1.0-frac) * pairs.first.value + frac * pairs.second.value;
    }
};