export const OEMediaCenterItemTypes = {
    none: -1,
    dummy: 0,
    animation: 1,
    accessory: 2,
    image: 3,
    video: 4,
    timeline: 5,
    html: 6,
    pdf: 7,
    presentation: 8,
    separator: 9
};

export const OEMediaCenterItemLayoutType = {
    std: 0,
    nameTop: 1
};

export const OEMediaCenterIconType = {
    icon: 0,
    text: 1,
    std: 0
};

export const OEMediaCenterMode = {
    std: 0,
    bottomBar: 1
};