import React from 'react';
import PropTypes from 'prop-types';

import Plyr from 'react-plyr';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInfoViewItemView from './oe-info-view-item-view';
import OEColor from '../color-picker/oe-color';
import {OEColorHelper} from '../oe-color-helper';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEInfoViewItemTutorials extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            strings: {
                title: 'Tutorials',
                startViewBtn: 'Show Tutorial',
                toolsAndFunctionsLabel: 'Tools and Functions'
            },
            textColor: OEColorHelper.stdLabelTextColor(0.6),
            videos: []
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onBgColorChanged = this.onBgColorChanged.bind(this);

        this.onStartViewBtn = this.onStartViewBtn.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    onRelease()    {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.bgColorChanged, this.onBgColorChanged);
    }

    updateLanguage()   {
        var si = this.oe.sharedInterface;
        var videos = [];
        for(var i = 1; i < 10; ++i) {
            videos.push(si.getLocalizedStringEnc('tutorial_video_' + i.toString() + '_youtube'));
        }

        this.setState({ 
            strings: {
                title: si.getLocalizedStringEnc('info_view_tutorials_label'),
                startViewBtn: si.getLocalizedStringEnc('info_view_tutorials_start_view_button'),
                toolsAndFunctionsLabel: si.getLocalizedStringEnc('info_view_tutorials_tools_functions_label')
            },
            videos: videos
        });
    }

    onBgColorChanged(message, userInfo)  {
        var brightness = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerColorTheme().getBackgroundColorBrightness() : userInfo.brightness;
        this.setState({
            textColor: OEColorHelper.stdLabelTextColor(brightness)
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.onBgColorChanged();
        });
    }

    render() {

        var separator = <div className='info-view-item-separator' style={{ backgroundColor: OEColor.toDOMStr(this.state.textColor) }} />

        var videos = this.state.videos.map((src, index) => 
            <Plyr
                key={index}
                className="react-plyr"
                style={{paddingTop: index > 0 ? '15px' : '0px'}}
                type="youtube"
                videoId={src} 
                controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}
            />);

        return (
            <OEInfoViewItemView
                moduleId={this.props.moduleId}
                elementRef={this.props.elementRef}
                isMaximizeable={true}
                isMaximized={this.props.isMaximized}
                notMinimizeable={this.props.notMinimizeable}
                hasSeparator={this.props.hasSeparator}
                className="ivi-tutorials"
                title={this.state.strings.title}
                onToggle={this.props.onToggle}
                onAnimFinished={this.props.onAnimFinished}
            >
                <div className="tutorials-container">

                    <a className="btn gray-btn tutorials-start-view-btn" role="button" onClick={this.onStartViewBtn}>
                        {this.state.strings.startViewBtn}
                    </a>
                    
                    {separator}
                    
                    <span className = "tutorials-tools-functions-label" >{this.state.strings.toolsAndFunctionsLabel}</span>

                    {videos}

                </div>

            </OEInfoViewItemView>
        );
    }

    onStartViewBtn() {

        if(typeof(this.props.appComponent.uiLayer.infoView) === 'object')   {
            this.props.appComponent.uiLayer.infoView.setOpen(false);
        }

        if(typeof(this.props.appComponent.uiLayer.startView) === 'object')   {
            this.props.appComponent.uiLayer.startView.setOpen(true);
        }
    }
}

OEInfoViewItemTutorials.defaultProps = {
    moduleId: '',
    elementRef: '',
    isMaximized: false,
    notMinimizeable: false,
    hasSeparator: true
};

OEInfoViewItemTutorials.propTypes = {
    moduleId: PropTypes.string,
    isMaximized: PropTypes.bool,
    notMinimizeable: PropTypes.bool,
    hasSeparator: PropTypes.bool
};