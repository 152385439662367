import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';
import FileSaver from 'file-saver';

import {withIsOpenState} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {OEPresetResult, OEPresetType} from '../../lib/oe-types';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import OETextField from '../elements/oe-text-field';
import OEWidgetHeader from '../elements/oe-widget-header';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEPopover from '../oe-popover';
import {OEPopoverMenuButton} from '../oe-popover-menu';
import {OEToolbox} from '../../lib/oe-toolbox';
import OEScrollbars from '../oe-scrollbars';
import {retardUpdate} from '../../lib/update-retarder';

export class OEPresetTableCell extends React.PureComponent {

    constructor(props)  {
        super(props); 

        this.onTextFieldRef = this.onTextFieldRef.bind(this);

        this.onDumpBtnPressed = this.onDumpBtnPressed.bind(this);
        this.onNameChanged = this.onNameChanged.bind(this);
        this.onApplyBtnPressed = this.onApplyBtnPressed.bind(this);
    }

    componentDidMount() {
        if(this.props.checkIfAdded && this.props.checkIfAdded(this.props.id)) {
            this.textFieldRef.focus();
        }
    }

    onTextFieldRef(ref) {
        this.textFieldRef = ref;
    }

    render()    {
        let typeAsString = OEPresetType.toString(this.props.type);
        let applyEnabled = typeAsString && this.props.controllerUIEnabled[typeAsString];

        return(
            <div className="preset-cell">
                <div className="content">
                    <OEButton
                        className="transparent-btn dump-btn"
                        disabled={!this.props.uiEnabled}
                        onPressed={this.onDumpBtnPressed}
                    >
                        <OEIcon code={OEIconCodes.preset.dump}/>
                    </OEButton>
                    <OETextField
                        ref={this.onTextFieldRef}
                        className="std-label-border-color"
                        clearButton={false}
                        value={this.props.name}
                        onChange={this.onNameChanged}
                    />
                    {!this.props.showIcon && typeAsString && OEIconCodes.preset.type[typeAsString] ? null : <OEIcon code={OEIconCodes.preset.type[typeAsString]}/>}
                    <OEButton
                        className="transparent-btn apply-btn"
                        disabled={!applyEnabled}
                        onPressed={this.onApplyBtnPressed}
                    >
                        <OEIcon code={OEIconCodes.preset.apply}/>
                    </OEButton>
                </div>
                {this.props.showSeparator ? <div className="std-separator-border-color separator"/> : null}
            </div>
        );
    }
    
    onDumpBtnPressed()  {
        if(this.props.onDumpBtnPressed)  this.props.onDumpBtnPressed(this.props.id);
    }

    onNameChanged(sender, value)  {
        if(this.props.onNameChanged)  this.props.onNameChanged(this.props.id, value);
    }

    onApplyBtnPressed()   {
        if(this.props.onApplyBtnPressed)  this.props.onApplyBtnPressed(this.props.id);
    }
}

OEPresetTableCell.defaultProps = {
    uiEnabled: true,
    controllerUIEnabled: {
        note: false,
        camera: false,
        cut: false,
        color: false
    },
    selected: false
};

OEPresetTableCell.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
    uiEnabled: PropTypes.bool,
    controllerUIEnabled: PropTypes.shape({
        note: PropTypes.bool,
        camera: PropTypes.bool,
        cut: PropTypes.bool,
        color: PropTypes.bool
    }),
    selected: PropTypes.bool,
    showSeparator: PropTypes.bool,
    showIcon: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    onDumpBtnPressed: PropTypes.func,
    onNameChanged: PropTypes.func,
    onApplyBtnPressed: PropTypes.func,
    checkIfAdded: PropTypes.func
};

export class OEPresetController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.presetSetData = [];
        this.presetType = this.props.presetType;
        this.controllerTypes = [];

        this.menuEntries = this.addMenuEntries();
        this.state = {
            uiEnabled: false,
            presetSetData: [],
            controllerUIEnabled: {
                note: false,
                camera: false,
                cut: false,
                color: false
            },
            btns: {
                dump: false,
                save: false,
                load: false,
                add: false
            },
            menuEntries: clone(this.menuEntries)
        };

        this.onPresetAdded = this.onPresetAdded.bind(this);
        this.onPresetRemoved = this.onPresetRemoved.bind(this);
        this.onPresetChanged = this.onPresetChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onFileLoadInputRef = this.onFileLoadInputRef.bind(this);
        this.onAddMenuRef = this.onAddMenuRef.bind(this);

        this.renderHeaderCenterBar = this.renderHeaderCenterBar.bind(this);

        this.onPresetSelectionChange = this.onPresetSelectionChange.bind(this);
        this.onPresetDumpBtnPressed = this.onPresetDumpBtnPressed.bind(this);
        this.onPresetNameChanged = this.onPresetNameChanged.bind(this);
        this.onPresetApplyBtnPressed = this.onPresetApplyBtnPressed.bind(this);
        this.checkIfAdded = this.checkIfAdded.bind(this);
        this.onAddBtnPressed = this.onAddBtnPressed.bind(this);
        this.onAddMenuSelection = this.onAddMenuSelection.bind(this);
        this.onLoadBtnPressed = this.onLoadBtnPressed.bind(this);
        this.onSaveBtnPressed = this.onSaveBtnPressed.bind(this);
        this.onLoadInputResult = this.onLoadInputResult.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.presetType !== this.props.presetType) {
            this.presetType = nextProps.presetType;
            this.updateAddMenuEntries(nextProps);
            this.updatePresetSet();
        }
        
        if(nextProps.refId !== this.props.refId) {
            this.updateAddMenuEntries(nextProps);
            this.updateUIState(nextProps);
        }
    }

    onConnect() {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presetAdded, this.onPresetAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presetRemoved, this.onPresetRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presetChanged, this.onPresetChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease() {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presetAdded, this.onPresetAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presetRemoved, this.onPresetRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presetChanged, this.onPresetChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    presetTypeToArray(type) {
        if(Array.isArray(type)) return type;
        if(typeof(type) === 'number') return [type];
        return [];
    }

    presetTypeFromCore(type)    {
        if(Array.isArray(type)) return type.map(e => e.value);
        if(type) return type.value;
    }

    presetTypeToCore(type)  {
        if(Array.isArray(type)) return type.map(e =>  {
            return {value: e};
        });

        if(typeof(type) === 'number')   return {value: type};
    }

    addMenuEntries(props) {
        let props_ = props || this.props;
        if(!Array.isArray(this.presetType) && typeof(this.presetType) !== 'number') return [];
        let addableTypes = typeof(props_.refId) === 'number' ? [OEPresetType.note, OEPresetType.camera, OEPresetType.cut, OEPresetType.color] : [OEPresetType.camera, OEPresetType.cut, OEPresetType.color];

        let types = Array.isArray(this.presetType) ? this.presetType : [this.presetType];
        types = types.includes(OEPresetType.any) ? addableTypes : addableTypes.filter(type => types.includes(type));

        return types.map(type => {
            let typeAsString = OEPresetType.toString(type);
            return {id: type, icon: OEIconCodes.preset.type[typeAsString]};
        });
    }

    updateAddMenuEntries(props)  {
        this.menuEntries = this.addMenuEntries(props);
        this.setState({menuEntries: clone(this.menuEntries)});
    }

    updatePresetSet() {
        if(!this.oe.isReady())  return;
        let coreTypes = this.presetTypeToCore(this.presetType);
        let presetSetData = coreTypes ? this.oe.sharedInterface.getUIControllerPreset().getPresetSetData(coreTypes) : [];
        this.presetSetData = presetSetData.map(data => Object.assign({selected: false}, data, {type: this.presetTypeFromCore(data.type), added: false}));
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    updateBtnStates() {
        let uiEnabled = this.oe.sharedInterface.getUIControllerPreset().getUIEnabled();
        let hasSelection = this.presetSetData.findIndex(preset => preset.selected === true) >= 0 ? true : false;
        this.setState({
            btns: {
                save: uiEnabled && this.presetSetData.length > 0,
                load: uiEnabled && this.presetTypeToArray(this.presetType).filter(type => type != OEPresetType.unknown).length > 0,
                add: uiEnabled && this.menuEntries.length > 0
            }
        });
    }

    updateUIState(props) {
        if(!this.oe.isReady())  return;
        
        let props_ = props || this.props;
        let uiEnabled = this.oe.sharedInterface.getUIControllerPreset().getUIEnabled();

        let controllerUIEnabled = {
            note: this.oe.sharedInterface.getUIControllerNote().getUIEnabled() && typeof(props_.refId) === 'number',
            camera: true,
            cut: this.oe.sharedInterface.getUIControllerCut().getUIEnabled(),
            color: this.oe.sharedInterface.getUIControllerComponent().getUIEnabled()
        };

        this.setState({uiEnabled: uiEnabled, controllerUIEnabled: controllerUIEnabled});

        this.updateBtnStates();
    }

    updateState(released) {
        if(!this.oe.isReady() || released === true) {
            this.presetSetData = [];
            this.setState({
                uiEnabled: false,
                presetSetData: [],
                controllerUIEnabled: {
                    note: false,
                    camera: false,
                    cut: false,
                    color: false
                },
                btns: {
                    dump: false,
                    save: false,
                    load: false,
                    add: false
                }
            });
            if(this.addMenuRef) this.addMenuRef.close();
            return;
        }

        retardUpdate(this, () => {
            this.updatePresetSet();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo) {
        if(userInfo.type === this.oe.Module.UIControllerType.preset || userInfo.type === this.oe.Module.UIControllerType.note || userInfo.type === this.oe.Module.UIControllerType.cut || userInfo.type === this.oe.Module.UIControllerType.component) {
            this.updateUIState();
        }
    }

    onPresetAdded(message, userInfo) {
        this.presetSetData.push(Object.assign({selected: false}, userInfo.data, {type: this.presetTypeFromCore(userInfo.data.type), added: true}));
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    onPresetRemoved(message, userInfo) {
        let index = this.presetSetData.findIndex(preset => preset.id === userInfo.ID);
        this.presetSetData.splice(index, 1);
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    onPresetChanged(message, userInfo) {
        let index = this.presetSetData.findIndex(preset => preset.id === userInfo.data.id);
        this.presetSetData[index].name = userInfo.data.name;
        this.setState({presetSetData: Array.from(this.presetSetData)});
    }

    onFileLoadInputRef(ref)    {
        this.fileLoadInput = ref;
    }

    onAddMenuRef(ref)   {
        this.addMenuRef = ref;
    }

    renderHeaderCenterBar(props)    {
        return (
            <React.Fragment>
                <OEButton
                    className="transparent-btn save-btn"
                    onPressed={this.onSaveBtnPressed}
                    disabled={!props.btns.save}
                >
                    <OEIcon code={OEIconCodes.preset.save}/>
                </OEButton>
                <OEButton
                    className="transparent-btn load-btn"
                    onPressed={this.onLoadBtnPressed}
                    disabled={!props.btns.load}
                >
                    <OEIcon code={OEIconCodes.preset.load}/>
                </OEButton>
                <OEPopoverMenuButton
                    ref={this.onAddMenuRef}
                    className="transparent-btn add-btn"
                    placement="top"
                    boundariesElement={props.boundariesElement}
                    hideArrow={false}
                    entries={props.menuEntries}
                    onChange={this.onAddMenuSelection}
                    disabled={!props.btns.add}
                    onPressed={this.onAddBtnPressed}
                >
                    <OEIcon code={OEIconCodes.preset.add}/>
                </OEPopoverMenuButton>
            </React.Fragment>
        );
    }
    
    render() {
        let showCellIcon = Array.isArray(this.props.presetType) && this.props.presetType.length > 1;

        let cells = this.state.presetSetData.map((preset, i) =>
            <OEPresetTableCell
                key={preset.id}
                id={preset.id}
                name={preset.name}
                type={preset.type}
                uiEnabled={this.state.uiEnabled}
                controllerUIEnabled={this.state.controllerUIEnabled}
                selected={preset.selected}
                showSeparator={i + 1 < this.state.presetSetData.length}
                showIcon={showCellIcon}
                onSelectionChange={this.onPresetSelectionChange}
                onDumpBtnPressed={this.onPresetDumpBtnPressed}
                onNameChanged={this.onPresetNameChanged}
                onApplyBtnPressed={this.onPresetApplyBtnPressed}
                checkIfAdded={this.checkIfAdded}
             />
        );

        return  (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'preset-controller ' + this.props.className}>

                    <input id="presetFileLoad"
                        type="file"
                        accept=".xpres"
                        ref={this.onFileLoadInputRef}
                        style={{display: 'none'}}
                        onChange={this.onLoadInputResult}
                    />

                    <OEWidgetHeader
                        moduleId={this.props.moduleId}
                        buttonClassName="transparent-btn"
                        icon={this.props.icon}
                        title={this.props.title}
                        titleId={this.props.titleId}
                        headerSeparator={this.props.headerSeparator}
                        centerBar={this.renderHeaderCenterBar}
                        onToggle={this.props.onToggle}
                        boundariesElement={this.props.boundariesElement}
                        btns={this.state.btns}
                        menuEntries={this.state.menuEntries}
                    />

                    <div className="preset-list">
                        <OEScrollbars>
                            {cells}
                        </OEScrollbars>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onPresetSelectionChange(id) {
        let index = this.presetSetData.findIndex(preset => preset.id === id);
        if(index < 0 || index >= this.presetSetData.length)   return;
        this.presetSetData[index].selected = !this.presetSetData[index].selected;
        this.setState({presetSetData: Array.from(this.presetSetData)});
        this.updateBtnStates();
    }

    onPresetDumpBtnPressed(id) {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerPreset().removePreset(id);
    }

    onPresetNameChanged(id, text) {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerPreset().setPresetName(id, OEToolbox.encode_utf8(text));
    }

    onPresetApplyBtnPressed(id) {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerPreset().applyPreset(id, this.props.refId);
    }

    checkIfAdded(id) {
        let index = this.presetSetData.findIndex(preset => preset.id === id);
        let added = this.presetSetData[index].added;
        this.presetSetData[index].added = false;
        return added;
    }

    onAddBtnPressed() {
        if(this.addMenuRef.isOpen())    {
            this.addMenuRef.close();
            return;
        }
        if(!this.oe.isReady() || this.state.menuEntries.length === 0)  return;
        if(this.state.menuEntries.length > 1)    {
            if(this.addMenuRef) this.addMenuRef.open();
        } else {
            let presetType = this.presetTypeToCore(this.state.menuEntries[0].id);
            this.oe.sharedInterface.getUIControllerPreset().addPreset('', presetType, this.props.refId);
        }
    }

    onAddMenuSelection(id)    {
        if(this.addMenuRef) this.addMenuRef.close();
        if(!this.oe.isReady()) return;
        let presetType = this.presetTypeToCore(id);
        this.oe.sharedInterface.getUIControllerPreset().addPreset('', presetType, this.props.refId);
    }

    onLoadBtnPressed()  {
        if(!this.fileLoadInput) return;
        this.fileLoadInput.value = null; // empties the file list so that onLoadInputResult gets called when loading the same file multiple times successively
        this.fileLoadInput.click();
    }
    
    onSaveBtnPressed() {
        this.props.appComponent.showWaitingController();
        this.savePreset(function(result)    {
            this.props.appComponent.hideWaitingController();
            if(result != OEPresetResult.ok)   console.log('Saving preset failed with error - ' + OEPresetResult.toString(result));
        }.bind(this));
    }

    savePreset(callback) {
        let presetType = this.presetTypeToCore(this.presetType);

        if(!this.oe.isReady() || !presetType) {
            callback(!presetType ? OEPresetResult.unexpected : OEPresetResult.ok);
            return;
        }

        let presetController = this.oe.sharedInterface.getUIControllerPreset();
        let result = presetController.save(presetType);

        if(result.result.value != OEPresetResult.ok) {
            callback(result.result);
            return;
        }

        let types = this.presetTypeToArray(this.presetType)
        let filename = 'presets.xpres';
        if(types.length == 1 && types[0] !== OEPresetType.any) {
            let prefix = '';
            switch(types[0]) {
                case OEPresetType.unknown: prefix = 'unknown'; break;
                case OEPresetType.any: prefix = 'any'; break;
                case OEPresetType.dummy: prefix = 'dummy'; break;
                case OEPresetType.note: prefix = 'note'; break;
                case OEPresetType.camera: prefix = 'camera'; break;
                case OEPresetType.cut: prefix = 'cut'; break;
                case OEPresetType.color: prefix = 'color'; break;
                default: break;
            }
            filename = prefix + '-presets.xpres';
        }

        FileSaver.saveAs(new Blob([new Uint8Array(result.buffer)]), filename);
        callback(OEPresetResult.ok);
    }

    loadPreset(files, callback) {
        let presetType = this.presetTypeToCore(this.presetType);

        if(!presetType) {
            callback(OEPresetResult.unexpected);
            return;
        }

        let allFileProgress = {i: 0, num: files.length, aborted: false};

        let onLoadFunction = function(event) {
            if(allFileProgress.aborted || event.target.readyState != FileReader.DONE) return;

            if(!this.oe.isReady()) {
                allFileProgress.aborted = true;
                callback(OEPresetResult.ok);
                return;
            }

            let arrayBuffer = event.target.result;
            let result = this.oe.sharedInterface.getUIControllerPreset().loadFromBuffer(arrayBuffer, presetType).value;

            if(result != OEPresetResult.ok && result != OEPresetResult.noPresetsFound) {
                allFileProgress.aborted = true;
                callback(result);
                return;
            }

            allFileProgress.i = allFileProgress.i + 1;
            if(allFileProgress.i == allFileProgress.num) callback(OEPresetResult.ok);

        }.bind(this);

        for(let i = 0; i < files.length; ++i)  {
            if(allFileProgress.aborted) break;
            let reader = new FileReader();
            reader.onload = onLoadFunction;
            reader.readAsArrayBuffer(files[i]);
        }
    }

    onLoadInputResult(event) {
        event.stopPropagation();
        event.preventDefault();
        if(event.target.files.length == 0) return;
        this.props.appComponent.showWaitingController();

        this.loadPreset(event.target.files, result => {
            this.props.appComponent.hideWaitingController();
            if(result != OEPresetResult.ok) console.log('Loading presets failed with error - ' + OEPresetResult.toString(result));
        });
    }
}

OEPresetController.defaultProps = {
    moduleId: '',
    className: '',
    titleId: 'presentation_preset_view',
    headerSeparator: true
};

OEPresetController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    titleId: PropTypes.string,
    headerSeparator: PropTypes.bool,
    presetType: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    refId: PropTypes.number,
    onToggle: PropTypes.func
};

export const OEPresetPopoverController = OEPopover.coat(OEPresetController, {
    noHeader: true,
}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OEPresetPopoverController);