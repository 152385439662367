import React from 'react';
import PropTypes from 'prop-types';

import {frefFromRef} from '../../lib/oe-higher-order-components';
import {OEDefaultConfigFactory} from '../oe-default-configs';
import {OENavigationViewComponent} from '../../lib/oe-navigation-controller';
import OEFlowNavModal from '../start-view/flow-nav/oe-flow-nav-modal';
import OEFNHelp from '../start-view/flow-nav/oe-flow-nav-help';

export class OEHelpViewStd extends React.PureComponent {

    render() {
        return (
            <OEFlowNavModal
                moduleId={this.props.moduleId}
                className={'help-view ' + this.props.className}
                config={{
                    logo: this.props.config.logo,
                    showAtStartUp: this.props.config.showAtStartUp,
                }}
                isOpen={this.props.isOpen}
                onOpenStateChanged={this.props.onOpenStateChanged}
                ref={this.props.fref}
            >
                <OENavigationViewComponent title="help_view_title">
                    <OEFNHelp appComponent={this.props.appComponent} moduleId={this.props.moduleId} entries={this.props.config.entries}/>
                </OENavigationViewComponent>
            </OEFlowNavModal>
        );
    }
}

OEHelpViewStd.defaultProps = {
    moduleId: '',
    className: '',
    config: OEDefaultConfigFactory.helpView()
};

OEHelpViewStd.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    config: OEDefaultConfigFactory.helpViewDecl,
    onOpenStateChanged: PropTypes.func
};

export default frefFromRef(OEHelpViewStd);