import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import {OEIconCodes} from '../lib/oe-icon-codes';
import OEButton, {OEIconButton} from './elements/oe-button';
import {OECustomCheckbox} from './elements/oe-checkbox';
import OEIcon from './elements/oe-icon';
import OENumberInput from './elements/oe-number-input';
import OESlider from './elements/oe-slider';
import OETextField from './elements/oe-text-field';
import OEToggle from './elements/oe-toggle';
import {OEColorPickerButton} from './color-picker/oe-color-picker';

export class OEGroupControl extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.collapsed
        };

        this.onHeaderClicked = this.onHeaderClicked.bind(this);
    }

    renderHeader()  {
        if(!this.props.title && this.props.type !== OEGroupControl.type.userCollapsable)   return null;

        const title = this.props.type === OEGroupControl.type.userCollapsable ?
            <a>
                <span className="group-control-title">
                    <div className={'rot-toggle' + (!this.state.collapsed ? ' rot-rotate-toggle' : '')}>
                        <OEIcon code={OEIconCodes.caretRight}/>
                    </div>{this.props.title}
                </span>
            </a> : 
            <span className="group-control-title">{this.props.title}</span>;

        return (
            <div 
                onClick={this.onHeaderClicked} 
                className={'group-control-header ' + (this.props.type === OEGroupControl.type.userCollapsable ? 'user-collapsable ' : '') + this.props.headerClassName}>
                {title}
            </div>
        );
    }

    renderBody()    {
        return (
            <div className={'group-control-body ' + this.props.bodyClassName}>{this.props.children}</div>
        );
    }

    renderSeparator()   {
        return <div className="group-control-separator std-separator-border-color"/>;
    }

    render() {
        const header = this.renderHeader();
        const body = this.renderBody();
        const separator = this.props.separator ? this.renderSeparator() : null;
        const topSeparator = this.props.topSeparator ? this.renderSeparator() : null;
        const popoverTarget = this.props.popoverTargetId ? <div id={this.props.popoverTargetId} className="group-control-popover-target"/> : null;
        const className = 'group-control ' + (!header ? 'no-header ' : '') + this.props.className;

        if(this.props.type !== OEGroupControl.type.fullCollapsable)  {
            return (
                <div id={this.props.id} className={className}>
                    {popoverTarget}
                    {topSeparator}
                    <div>
                        {header} 
                        <Collapse isOpen={this.props.type === OEGroupControl.type.userCollapsable ? !this.state.collapsed : !this.props.collapsed}>
                            {body}
                        </Collapse>
                    </div>
                    {separator}
                </div>
            );
        } else {
            return (
                <div id={this.props.id} className={className}>
                    {popoverTarget}
                    <div>
                        <Collapse isOpen={this.props.type === OEGroupControl.type.userCollapsable ? !this.state.collapsed : !this.props.collapsed}>
                            {topSeparator}
                            {header}
                            {body}
                        </Collapse>
                    </div>
                    {separator}
                </div>
            );
        }
    }

    onHeaderClicked()   {
        this.setState((prevState, props) => { return {collapsed: !prevState.collapsed}; });
    }
}

OEGroupControl.type = {
    standard: 0,
    userCollapsable: 1,
    fullCollapsable: 2
};

OEGroupControl.defaultProps = {
    className: '',
    headerClassName: '',
    bodyClassName: '',
    type: OEGroupControl.type.standard,
    collapsed: false,
    separator: false,
    topSeparator: false
};

OEGroupControl.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    title: PropTypes.node,
    type: PropTypes.number,
    collapsed: PropTypes.bool,
    separator: PropTypes.bool,
    topSeparator: PropTypes.bool,
    popoverTargetId: PropTypes.string,
};

export class OEControl extends React.PureComponent {
    render() {
        return !this.props.title ? <div className={'control-without-label ' + this.props.className}>{this.props.children}</div> :
            <div className={'control ' + this.props.className}>
                <div className="label">{this.props.title}</div>
                <div className="control-widget">{this.props.children}</div>
            </div>;
    }
}

OEControl.defaultProps = {
    className: ''
};

OEControl.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node
};

OEControl.coat = function(WrappedComponent, controlClassName = '') {

    let ret = class extends React.PureComponent {
        render() {
            const {className, title, ...rest} = this.props;
            return (
                <OEControl className={controlClassName + ' ' + className} title={title}>
                    <WrappedComponent {...rest}/>
                </OEControl>
            );
        }
    };

    ret.defaultProps = Object.assign({}, OEControl.defaultProps, WrappedComponent.defaultProps);
    ret.propTypes = Object.assign({}, OEControl.propTypes, WrappedComponent.propTypes);

    return ret;
};

export const OEToggleControl = OEControl.coat(OEToggle, 'control-toogle');

export class OESliderControl extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onSlide = this.onSlide.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    renderInput()   {
        let value = this.props.value;
        if(this.props.step > 0) {
            let n = Math.ceil(Math.log10(1 / this.props.step));
            value = value.toFixed(n)
        }
        return <input type="number" disabled={this.props.disabled} min={this.props.min} max={this.props.max} step={this.props.step} value={value} onChange={this.onChange}/>;
    }

    render() {
        return (
            <OEControl className={'slider ' + this.props.className} title={this.props.title}>
                <OESlider
                    disabled={this.props.disabled}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    value={this.props.value}
                    animate={this.props.animate}
                    onStart={this.props.onStart}
                    onSlide={this.onSlide}
                    onEnd={this.props.onEnd}
                />
                {this.props.showInput ? this.renderInput() : null}
            </OEControl>
        );
    }

    onSlide(value)   {
        if(this.props.onSlide)  this.props.onSlide(value);
        if(this.props.onChange)  this.props.onChange(value);
    }

    onChange(evt)   {
        if(this.props.onChange)  {
            let value = Number.parseFloat(evt.target.value);
            if(Number.isNaN(value))   return;
            this.props.onChange(value);
        }
    }
}

OESliderControl.defaultProps = {
    className: '',
    disabled: false,
    min: 0,
    max: 100,
    step: 1,
    value: 0,
    animate: true,
    showInput: false,
    onStart: function(value)    {},
    onSlide: function(value)    {},
    onEnd: function(value)  {}
};

OESliderControl.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    animate: PropTypes.bool,
    showInput: PropTypes.bool,
    onStart: PropTypes.func,
    onSlide: PropTypes.func,
    onEnd: PropTypes.func,
    onChange: PropTypes.func
};

export const OENumberControl = OEControl.coat(OENumberInput, 'control-number-input');

export class OESegment extends React.PureComponent {

    onClick(key)   {
        this.props.onClick(key);
    }

    render() {

        const items = this.props.entries.map((entry) =>
            <Button 
                key={entry.key} 
                active={entry.key === this.props.chosenKey} 
                disabled={this.props.disabled}
                onClick={() => this.onClick(entry.key)}
            >
                {entry.content}
            </Button>
        );

        return (
            <ButtonGroup>{items}</ButtonGroup>
        );
    }
}

OESegment.defaultProps = {
    disabled: false,
    chosenKey: '',
    entries: [],
    onClick: function(key){}
};

OESegment.propTypes = {
    disabled: PropTypes.bool,
    entries: PropTypes.array,
    onClick: PropTypes.func
};

export class OEColorPickerButtonControl extends React.PureComponent {
    render() {
        const {className, innerClassName, ...rest} = this.props;
        return (
            <OEControl className={this.props.className} title={this.props.title}>
                <OEColorPickerButton
                    className={innerClassName}
                    {...rest}
                />
            </OEControl>
        );
    }
};

OEColorPickerButtonControl.defaultProps = Object.assign({
    innerClassName: ''
}, OEColorPickerButton.defaultProps);

OEColorPickerButtonControl.propTypes = Object.assign({
    innerClassName: PropTypes.string
}, OEColorPickerButton.propTypes)

export const OEButtonControl = OEControl.coat(OEButton, 'control-button');

export const OEIconButtonControl = OEControl.coat(OEIconButton, 'control-icon-button');

export const OECheckboxControl = OEControl.coat(OECustomCheckbox, 'control-checkbox');

export const OETextFieldControl = OEControl.coat(OETextField, 'control-text-field');

export class OEAnimationControls extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.onFirstBtnPressed = this.onFirstBtnPressed.bind(this);
        this.onSecondBtnPressed = this.onSecondBtnPressed.bind(this);
        this.onProgressSliderStart = this.onProgressSliderStart.bind(this);
        this.onProgressSliderChanged = this.onProgressSliderChanged.bind(this);
        this.onProgressSliderEnd = this.onProgressSliderEnd.bind(this);
    }

    onFirstBtnPressed()  {
        this.props.onStopBtnPressed();
    }

    onSecondBtnPressed()  {
        this.props.onPlayPauseBtnPressed();
    }

    onProgressSliderStart(value)  {
        this.props.onProgressSliderStart(value);
    }

    onProgressSliderChanged(value)  {
        this.props.onProgressSliderChanged(value);
    }

    onProgressSliderEnd(value)  {
        this.props.onProgressSliderEnd(value);
    }

    stepSize()  {
        return Math.max(1e-6, Math.abs(this.props.endTime - this.props.startTime)/1000.0);
    }

    render() {

        var slider = Object.assign({}, OEAnimationControls.defaultProps.slider);
        slider = Object.assign(slider, this.props.slider);

        var control = Object.assign({}, OEAnimationControls.defaultProps.control);
        control = Object.assign(control, this.props.control);

        const firstBtnDisabled = this.props.disabled || control.disabled || (!control.doNotDisableStopBtn && (this.props.mode === OEAnimationControls.Mode.disabled || 
                                    (this.props.mode === OEAnimationControls.Mode.pause && this.props.startTime + 1e-6 >= this.props.time)));

        const secondBtnDisabled = this.props.disabled || control.disabled || (!control.doNotDisablePlayBtn && this.props.mode === OEAnimationControls.Mode.disabled);
        
        const sliderDisabled = this.props.disabled || slider.disabled || this.props.mode === OEAnimationControls.Mode.disabled;

        const modeClass = 'play-mode-' + (this.props.mode === OEAnimationControls.Mode.play ? 'play' : (this.props.mode === OEAnimationControls.Mode.pause ? 'pause' : 'disabled'));
        
        const iconCodes = Object.assign({stop: OEIconCodes.stop, play: OEIconCodes.play, pause: OEIconCodes.pause}, this.props.iconCodes || {});

        return (
            <div className={'animation-controls ' + modeClass + ' ' + this.props.className}>

                {!control.visible ? null :
                    <div className="animation-controls-left-btns">
                        {!control.stopBtnVisible ? null :
                            <OEButton
                                className="transparent-btn animation-controls-btn stop"
                                disabled={firstBtnDisabled}
                                onPressed={this.onFirstBtnPressed}
                            >
                                <OEIcon code={iconCodes.stop}/>
                            </OEButton>
                        }
                        {!control.playBtnVisible ? null :
                            <OEButton
                                className="transparent-btn animation-controls-btn play-pause"
                                disabled={secondBtnDisabled}
                                onPressed={this.onSecondBtnPressed}
                            >
                                <OEIcon code={this.props.mode === OEAnimationControls.Mode.play ? iconCodes.pause : iconCodes.play}/>
                            </OEButton>
                        }
                    </div>
                }

                {!slider.visible ? null :
                    <div className="animation-controls-slider-container">
                        <OESlider className="animation-controls-slider" 
                            disabled={sliderDisabled}
                            min={this.props.startTime}
                            max={this.props.endTime}
                            step={this.stepSize() }
                            value={this.props.time}
                            onStart={this.onProgressSliderStart}
                            onSlide={this.onProgressSliderChanged}
                            onEnd={this.onProgressSliderEnd}
                        />
                    </div>
                }

            </div>
        );
    }
}

OEAnimationControls.Mode = {
    disabled: 0,
    play: 1,
    pause: 2
};

OEAnimationControls.defaultProps = {
    className: '',
    mode: OEAnimationControls.Mode.disabled,
    disabled: false,
    slider: {visible: true, disabled: false},
    control: {visible: true, disabled: false, stopBtnVisible: true, playBtnVisible: true, doNotDisableStopBtn: false, doNotDisablePlayBtn: false},
    time: 0,
    startTime: 0,
    endTime: 1,
    onStopBtnPressed: function(){},
    onPlayPauseBtnPressed: function(){},
    onProgressSliderStart: function(value){},
    onProgressSliderChanged: function(value){},
    onProgressSliderEnd: function(value){}
};

OEAnimationControls.propTypes = {
    className: PropTypes.string,
    mode: PropTypes.number,
    disabled: PropTypes.bool,
    slider: PropTypes.shape({visible: PropTypes.bool, disabled: PropTypes.bool}),
    control: PropTypes.shape({visible: PropTypes.bool, disabled: PropTypes.bool, stopBtnVisible: PropTypes.bool, playBtnVisible: PropTypes.bool, doNotDisableStopBtn: PropTypes.bool, doNotDisablePlayBtn: PropTypes.bool}),
    time: PropTypes.number,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    onStopBtnPressed: PropTypes.func,
    onPlayPauseBtnPressed: PropTypes.func,
    onProgressSliderStart: PropTypes.func,
    onProgressSliderChanged: PropTypes.func,
    onProgressSliderEnd: PropTypes.func
};