import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEFNElement from '../../elements/oe-flow-nav-elements';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {OEThemeWaitingControllerWindow} from '../../oe-theme-waiting-controller';
import OEFlowNavStartSelectionViewModel from './oe-flow-nav-start-selection-model';

export class OEFlowNavStartSelectionView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.model = new OEFlowNavStartSelectionViewModel(this.props.categories);

        this.state = {
            selected: this.model.categoryIndexForTarget(this.props.target),
            strings: {
                currentChoice: 'current:',
                model: this.model.localizedStrings()
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onWaitingControllerRef = this.onWaitingControllerRef.bind(this);

        this.onSelectionBarItemClick = this.onSelectionBarItemClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentWillReceiveProps(nextProps)   {
        let categoriesChanged = !OEToolbox.shallowEqual(nextProps.categories, this.props.categories, true);

        if(categoriesChanged)   {
            this.model = new OEFlowNavStartSelectionViewModel(nextProps.categories);
        }

        if(nextProps.locStringPrefix !== this.props.locStringPrefix || nextProps.target !== this.props.target || categoriesChanged)  {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateLanguage(props)   {
        if(!this.oe.isReady())  return;
        props = props || this.props;
        let si = this.oe.sharedInterface;

        let current = si.getLocalizedStringEnc(props.locStringPrefix + 'start_selection_view_current_choice');
        let item = this.model.itemForTarget(props.target);

        let itemTitle = '';
        if(item && item.label)    {
            itemTitle = item.label.id ? si.getLocalizedStringEnc(item.label.id) : (item.label.text ? item.label.text : '');
        }

        let currentChoice = current + ': ' + itemTitle;

        this.setState({strings: {
            currentChoice: currentChoice,
            model: this.model.localizedStrings(si)
        }});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            return;
        }

        this.updateLanguage();
    }

    unpackLabel(label)  {
        if(!label)  return null;
        return label.id ? this.state.strings.model[label.id] : label.text;
    }

    onWaitingControllerRef(ref) {
        this.waitingController = ref;
    }

    renderSelectionBar()    {
        let items = !this.props.categories ? undefined : this.props.categories.map((category) => {
            return {
                label: this.unpackLabel(category.label)
            };
        });

        return <OEFNElement.SelectionBar items={items} selected={this.state.selected} disabled={this.props.disabled || this.props.selectionBarDisabled} onItemClick={this.onSelectionBarItemClick}/>;
    }

    renderCategory()    {
        let selectedCategory = this.state.selected >= 0 && this.state.selected < this.props.categories.length ? this.props.categories[this.state.selected] : null;
        if(!selectedCategory || !Array.isArray(selectedCategory.subcategories))   return null;

        let ret = [];
        let key = 0;

        ret.push(<OEFNElement.Block key={key++} className="category" content={this.unpackLabel(selectedCategory.label)}/>);

        selectedCategory.subcategories.forEach(subcategory => {
            ret.push(<OEFNElement.Block key={key++} className="subcategory" content={this.unpackLabel(subcategory.label)}/>);
            if(!Array.isArray(subcategory.items))   return;

            ret.push(<OEFNElement.Spacer key={key++} height={16}/>);

            subcategory.items.forEach(item => {
                ret.push(
                    <OEFNElement.Block key={key++} id={item.target} className="item" item={item} disabled={this.props.disabled || this.props.itemsDisabled} onClick={this.onItemClick}>
                        <span className="dot">{'\u2022'}</span><span>{this.unpackLabel(item.label)}</span>
                    </OEFNElement.Block>);
            });

            ret.push(<OEFNElement.Spacer key={key++} height={16}/>);
        });

        return ret;
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFNElement.View className="start-selection">
                    <OEFNElement.Spacer height={24}/>
                    <OEFNElement.Block className="current-choice" content={this.state.strings.currentChoice}/>
                    <OEFNElement.Spacer height={22}/>
                    {this.renderSelectionBar()}
                    <OEFNElement.Spacer height={30}/>
                    {this.renderCategory()}
                    <OEFNElement.Spacer height={14}/>
                </OEFNElement.View>
                <OEThemeWaitingControllerWindow className="start-selection" ref={this.onWaitingControllerRef} options={{animated: false}}/>
            </React.Fragment>
        );
    }

    onSelectionBarItemClick(sender, index)    {
        if(typeof(index) !== 'number')  return;
        this.setState({selected: index});
    }

    onItemClick(id, sender) {
        if(!this.props.appComponent)    return;

        this.waitingController.show(() => {
            window.requestAnimationFrame(() => {
                if(this.props.onItemClick && this.props.onItemClick(this, sender.props.item))  return;
                this.props.appComponent.app.changeTarget(id);
                if(this.waitingController)  this.waitingController.hide();
            });
        });
    }
}

OEFlowNavStartSelectionView.defaultProps = {
    moduleId: '',
    locStringPrefix: '',
    target: '',
    categories: []
};

OEFlowNavStartSelectionView.propTypes = {
    moduleId: PropTypes.string,
    locStringPrefix: PropTypes.string,
    target: PropTypes.string,
    disabled: PropTypes.bool,
    selectionBarDisabled: PropTypes.bool,
    itemsDisabled: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.shape({
            text: PropTypes.string,
            id: PropTypes.string,
        }),
        subcategories: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.shape({
                text: PropTypes.string,
                id: PropTypes.string,
            }),
            items: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.shape({
                    text: PropTypes.string,
                    id: PropTypes.string,
                }),
            }))
        }))
    })),
    onItemClick: PropTypes.func
};

export default connectAppEnv((env) => { return {
    target: env.config.target,
    appComponent: env.component
}})(OEFlowNavStartSelectionView);