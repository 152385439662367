import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';

import {withIsOpenState} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OEControlState} from '../../../lib/oe-types';
import OEButton from '../../elements/oe-button';
import OEIcon from '../../elements/oe-icon';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import OEColor from '../../color-picker/oe-color';
import {OEColorPickerNumericInputType, OEColorPickerButton} from '../../color-picker/oe-color-picker';
import OEPopover from '../../oe-popover';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {OEFunctionFragmentParameterModel} from './oe-function-model';
import {OEFunctionController} from './oe-function-controller';
import {retardUpdate} from '../../../lib/update-retarder';

export class OENoteFunctionController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.id = this.props.noteID;
        this.style = null;

        this.controlState = OEControlState.normal;

        this.defaultColors = {
            textColor: {x: 0, y: 0, z: 0, w: 1},
            backgroundColor: {x: 1, y: 1, z: 1, w: 1},
            lineColor: {x: 0, y: 0, z: 0, w: 1},
        };

        this.defaultStateColors = {
            textColor: {
                normal: {x: 0, y: 0, z: 0, w: 1},
                values: {
                    2: {x: 0, y: 0, z: 0, w: 0.6}
                }
            },
            backgroundColor: {
                normal: {x: 1, y: 1, z: 1, w: 1},
                values: {
                    1: {x: 0, y: 1, z: 0, w: 1},
                    2: {x: 1, y: 1, z: 1, w: 0.6},
                    4: {x: 0, y: 1, z: 0, w: 1}
                }
            },
            lineColor: {
                normal: {x: 0, y: 0, z: 0, w: 1},
                values: {
                    2: {x: 0, y: 0, z: 0, w: 0.6}
                }
            },
        };

        this.state = {
            id: this.id,
            controlState: this.controlState,
            colors: this.defaultColors,
            stateColors: this.defaultStateColors,
            func: undefined
        };

        this.onNoteStyleChanged = this.onNoteStyleChanged.bind(this);
        this.onFunctionChanged = this.onFunctionChanged.bind(this);

        this.onTextColorBtnChange = this.onTextColorBtnChange.bind(this);
        this.onBackgroundColorBtnChange = this.onBackgroundColorBtnChange.bind(this);
        this.onLineColorBtnChange = this.onLineColorBtnChange.bind(this);
        this.onStateButtonPressed = this.onStateButtonPressed.bind(this);
        this.onFunctionControllerChange = this.onFunctionControllerChange.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteStyleChanged, this.onNoteStyleChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.noteFunctionChanged, this.onFunctionChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteStyleChanged, this.onNoteStyleChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.noteFunctionChanged, this.onFunctionChanged);
    }

    componentWillReceiveProps(nextProps)    {
        if(nextProps.noteID != this.id)   {
            this.updateID(nextProps.noteID);
        }
    }

    updateID(id)    {
        if(typeof(id) != 'number')  id = null;
        if(this.id == id)   return;
        this.id = id;
        this.setState({id: this.id});
        this.updateStyle();
        this.updateFunction();
    }

    updateControlState(state)   {
        if(this.controlState === state)    return;
        this.controlState = state;
        this.setState({controlState: this.controlState});
        this.updateColors();
    }

    valueForState(stateValue, state, defValue) {
        if(!stateValue) return defValue;
        return OEControlState.normal ? stateValue.normal : (stateValue.values && stateValue.values[state] ? stateValue.values[state] : defValue);
    }

    appliedValueForState(stateValue, state) {
        if(!stateValue) return undefined;
        let ret = this.valueForState(stateValue, state);
        return ret ? ret : stateValue.normal;
    }

    updateColors()  {
        let colors = {};
        let stateColors = {};
        if(this.style)  {
            colors.textColor = this.appliedValueForState(this.style.textStateColors, this.controlState);
            colors.backgroundColor = this.appliedValueForState(this.style.backgroundStateColors, this.controlState);
            colors.lineColor = this.appliedValueForState(this.style.lineStateColors, this.controlState);

            stateColors.textColor = this.style.textStateColors;
            stateColors.backgroundColor = this.style.backgroundStateColors;
            stateColors.lineColor = this.style.lineStateColors;
        }

        colors.textColor = colors.textColor || this.defaultColors.textColor;
        colors.backgroundColor = colors.backgroundColor || this.defaultColors.backgroundColor;
        colors.lineColor = colors.lineColor || this.defaultColors.lineColor;

        stateColors.textColor = stateColors.textColor || this.defaultStateColors.textColor;
        stateColors.backgroundColor = stateColors.backgroundColor || this.defaultStateColors.backgroundColor;
        stateColors.lineColor = stateColors.lineColor || this.defaultStateColors.lineColor;

        this.setState({
            colors: colors,
            stateColors: stateColors
        });
    }

    updateStyle(style)  {
        if(!style && (this.oe.isReady() && this.id !== null))  style = this.oe.sharedInterface.getUIControllerNote().getNoteStyle(this.id);
        let oldStyle = this.style;
        this.style = style ? style : null;
        if(OEToolbox.shallowEqual(this.style, oldStyle))    return;
        this.updateColors();
    }

    updateFunction()    {
        let func = this.oe.isReady() && this.id !== null ? this.oe.sharedInterface.getUIControllerNote().getNoteFunction(this.id) : undefined;
        this.setState({func: func});
    }

    onNoteStyleChanged(message, userInfo)   {
        if(userInfo.ID !== this.id)  return;
        this.updateStyle(userInfo.style);
    }

    onFunctionChanged(message, userInfo) {
        if(userInfo.ID !== this.id)  return;
        this.updateFunction();
    }

    updateState(released)   {
        retardUpdate(this, () => {
            if(!this.oe.isReady() || released === true)   {
                this.style = null;
                this.setState({
                    colors: this.defaultColors,
                    stateColors: this.defaultStateColors,
                    func: undefined
                });
                return;
            }

            this.updateStyle();
            this.updateFunction();
        });
    }

    render() {
        return  (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFunctionController {...this.props} className="note-function-controller" func={this.state.func} onChange={this.onFunctionControllerChange}>
                    <div className="state-color-bar std-label-text-color">
                        <div className="color-btns">
                            <OEColorPickerButton
                                moduleId={this.props.moduleId}
                                className="transparent-btn"
                                boundariesElement={this.props.boundariesElement}
                                noHeader={true}
                                backdrop={true}
                                backdropOptions={{color: 'rgba(0, 0, 0, 0)'}}
                                color={this.state.colors.textColor}
                                defaultColor={this.valueForState(this.defaultStateColors.textColor, this.state.controlState, this.state.stateColors.textColor.normal)}
                                hasAlphaControl={true}
                                numericInput={this.props.devFeatures ? OEColorPickerNumericInputType.textField : OEColorPickerNumericInputType.rgbInput}
                                hasPaletteButton={this.props.devFeatures}
                                onChange={this.onTextColorBtnChange}
                            >
                                <OEIcon code={OEIconCodes.noteTextColor}/>

                                <OEIcon className="color-bar" style={{color: OEColor.toDOMStr(this.state.colors.textColor)}} code={OEIconCodes.noteShowColor}/>
                            </OEColorPickerButton>

                            <OEColorPickerButton
                                moduleId={this.props.moduleId}
                                className="transparent-btn"
                                boundariesElement={this.props.boundariesElement}
                                noHeader={true}
                                backdrop={true}
                                backdropOptions={{color: 'rgba(0, 0, 0, 0)'}}
                                color={this.state.colors.lineColor}
                                defaultColor={this.valueForState(this.defaultStateColors.lineColor, this.state.controlState, this.state.stateColors.lineColor.normal)}
                                hasAlphaControl={true}
                                numericInput={this.props.devFeatures ? OEColorPickerNumericInputType.textField : OEColorPickerNumericInputType.rgbInput}
                                hasPaletteButton={this.props.devFeatures}
                                onChange={this.onLineColorBtnChange}
                            >
                                <OEIcon code={OEIconCodes.noteLineColor}/>

                                <OEIcon className="color-bar" style={{color: OEColor.toDOMStr(this.state.colors.lineColor)}} code={OEIconCodes.noteShowColor}/>
                            </OEColorPickerButton>

                            <OEColorPickerButton
                                moduleId={this.props.moduleId}
                                className="transparent-btn"
                                boundariesElement={this.props.boundariesElement}
                                noHeader={true}
                                backdrop={true}
                                backdropOptions={{color: 'rgba(0, 0, 0, 0)'}}
                                color={this.state.colors.backgroundColor}
                                defaultColor={this.valueForState(this.defaultStateColors.backgroundColor, this.state.controlState, this.state.stateColors.backgroundColor.normal)}
                                hasAlphaControl={true}
                                numericInput={this.props.devFeatures ? OEColorPickerNumericInputType.textField : OEColorPickerNumericInputType.rgbInput}
                                hasPaletteButton={this.props.devFeatures}
                                onChange={this.onBackgroundColorBtnChange}
                            >
                                <OEIcon code={OEIconCodes.noteBackgroundColor}/>

                                <OEIcon className="color-bar" style={{color: OEColor.toDOMStr(this.state.colors.backgroundColor)}} code={OEIconCodes.noteShowColor}/>
                            </OEColorPickerButton>
                        </div>
                        <div className="state-btns">
                            <OEButton userData={OEControlState.highlighted} activated={this.state.controlState === OEControlState.highlighted} onPressed={this.onStateButtonPressed}>
                                :high
                            </OEButton>
                            <OEButton userData={OEControlState.selected} activated={this.state.controlState === OEControlState.selected} onPressed={this.onStateButtonPressed}>
                                :sel
                            </OEButton>
                            <OEButton userData={OEControlState.disabled} activated={this.state.controlState === OEControlState.disabled} onPressed={this.onStateButtonPressed}>
                                :dis
                            </OEButton>
                        </div>
                    </div>
                </OEFunctionController>
            </React.Fragment>
        );
    }

    onStateButtonPressed(e, sender) {
        let controlState = sender.props.userData;
        if(controlState === this.state.controlState)    controlState = OEControlState.normal;
        this.updateControlState(controlState);
    }

    onFunctionControllerChange(sender, func_)    {
        if(!this.oe.isReady() || this.id === null)  return;
        let func = clone(func_);
        if(func && func.fragments)  {
            func.fragments.forEach(fragment => {
                if(fragment.params) fragment.params.$__model__ = OEFunctionFragmentParameterModel.modelForType(fragment.type);
            });
        }

        this.oe.sharedInterface.getUIControllerNote().setNoteFunction(this.id, func);
    }

    onTextColorBtnChange(color, colorHSV, isDefault)	{
        if(!this.oe.isReady() || this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteTextStateColor(this.id, !isDefault ? color : this.valueForState(this.defaultStateColors.textColor, this.state.controlState), this.state.controlState);
    }

    onBackgroundColorBtnChange(color, colorHSV, isDefault)	{
        if(!this.oe.isReady() || this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteBackgroundStateColor(this.id, !isDefault ? color : this.valueForState(this.defaultStateColors.backgroundColor, this.state.controlState), this.state.controlState);
    }

    onLineColorBtnChange(color, colorHSV, isDefault)	{
        if(!this.oe.isReady() || this.id === null) return;
        this.oe.sharedInterface.getUIControllerNote().setNoteLineStateColor(this.id, !isDefault ? color : this.valueForState(this.defaultStateColors.lineColor, this.state.controlState), this.state.controlState);
    }
}

OENoteFunctionController.defaultProps = Object.assign({}, OEFunctionController.defaultProps);

OENoteFunctionController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    titleId: PropTypes.string,
    headerSeparator: PropTypes.bool,
    onToggle: PropTypes.func
};

export const OENoteFunctionPopoverController = OEPopover.coat(OENoteFunctionController, {
    noHeader: true,
}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
});

export default withIsOpenState(OENoteFunctionPopoverController);