import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {connectModuleEnv} from '../../oe-module-env';
import {frefFromRef, frefToRef} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEIcon from '../../elements/oe-icon';
import OEButton from '../../elements/oe-button';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import OEResizeObserver from '../../../lib/oe-resize-observer';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEPhilipsIcarusCustomPropKey, OEPhilipsIcarusMode, OEPhilipsIcarusModel, OEPhilipsIcarusOverlayNotifications} from './oe-philips-icarus-model';
import OEOverlayTextBox from '../../overlays/elements/oe-overlay-text-box';
import OEPhilipsIcarusTextBox from './oe-philips-icarus-text-box';

export class OEPhilipsIcarusOverlay extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.model = OEPhilipsIcarusModel.shared();
        if(!this.model.data) this.model.setData(OEPhilipsIcarusModel.stdData());

        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.visible = false;
        this.mode = OEPhilipsIcarusMode.uninitialized;

        this.xRayContainerSize = null;

        this.textBox = {
            available: false,
            isOpen: false,
            layoutType: OEOverlayTextBox.ExtLayoutType.right
        };

        this.state = {
            visible: this.visible,
            mode: this.mode,
            xRayImage: null,
            xRayContainerSize: this.xRayContainerSize,
            textBox: clone(this.textBox)
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);

        this.onWindowResized = this.onWindowResized.bind(this);
        this.onXRayContainerResize = this.onXRayContainerResize.bind(this);

        this.onResetCameraBtnPressed = this.onResetCameraBtnPressed.bind(this);
        this.onTextBoxBtnPressed = this.onTextBoxBtnPressed.bind(this);
        this.onTextBoxToggle = this.onTextBoxToggle.bind(this);
        this.onTextBoxToLeftRightBtnPressed = this.onTextBoxToLeftRightBtnPressed.bind(this);
    }

    componentDidMount()    {
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()  {
        this.props.uiStateManager.updateState({contextMenu: {areaRestriction: { right: {$set: 0}}}});
        window.removeEventListener('resize', this.onWindowResized);
    }

    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.jsonEqual(this.props.ui, nextProps.ui))     {
            this.updateVPFrame(nextProps);
        }
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    getEdgeOffsets()    {
        return this.edgeOffsets;
    }

    setTextBoxOpen(open)    {
        if((!this.textBox.available && open) || this.textBox.isOpen == open)    return;
        this.textBox.isOpen = open;
        this.setStateUpdate({textBox: {isOpen: {$set: open}}});
    }

    setTextBoxLayoutType(layoutType)    {
        if(this.textBox.layoutType == layoutType)    return;
        this.textBox.layoutType = layoutType;
        this.setStateUpdate({textBox: {layoutType: {$set: layoutType}}});
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);

        // for debugging pupose -> start immediately in x-ray modus (with text box)
        /*
        this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItemByCategoryIndex(8);
        this.oe.sharedInterface.setCustomIntProperty(OEPhilipsIcarusCustomPropKey.mode, OEPhilipsIcarusMode.xRay);
        this.setTextBoxOpen(true);
        */
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    updateLanguage()   {
        this.update();
    }

    updateVPFrame(props) {
        if(!this.oe.isReady() || !this.xRayContainerSize || !window.innerWidth) return;
        props = props || this.props;
        let wSize = {w: window.innerWidth, h: window.innerHeight};
        let frame = {x: props.ui.mainMenuWidth, y: props.ui.bottomEdgeOffset};
        frame.z = wSize.w - this.xRayContainerSize.w - frame.x;
        frame.w = wSize.h - props.ui.capBarSize.h - frame.y;
        frame = { x: 2 * frame.x / wSize.w - 1, y: 2 * frame.y / wSize.h - 1, z: 2 * frame.z / wSize.w, w: 2 * frame.w / wSize.h };
        this.oe.sharedInterface.setCustomFloatProperty(OEPhilipsIcarusCustomPropKey.vpFrame, {vec: frame});
    }

    updateContextMenuContainerRestriction() {
        let right = this.xRayContainerSize && this.visible && this.mode === OEPhilipsIcarusMode.xRay ? this.xRayContainerSize.w : 0;
        this.props.uiStateManager.updateState({contextMenu: {areaRestriction: { right: {$set: right}}}});
    }

    updateEdgeOffsets()    {
        let edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};
        if(OEToolbox.shallowEqual(this.edgeOffsets, edgeOffsets)) return;
        this.edgeOffsets = edgeOffsets;
        if(this.props.onEdgeOffsetsChanged) this.props.onEdgeOffsetsChanged(this, this.edgeOffsets, true);
    }

    update()   {
        if(!this.oe.isReady())  return;
        const si = this.oe.sharedInterface;

        let mode = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;
        let animationId = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.animationId).value;
        let animation = this.model.getAnimation(animationId);

        this.visible = mode != OEPhilipsIcarusMode.uninitialized;
        this.mode = mode;

        retardUpdate(this, () => {
            this.setState({visible: this.visible, mode: this.mode});

            if((animation && animation.xRayImage) || !this.model.isIdleOrNull(animationId))   {
                let xRayImage = animation && animation.xRayImage ? animation.xRayImage : null;
                this.setState({xRayImage: 'images/philipsIcarus/Xray/' + (xRayImage ? xRayImage : si.getLocalizedStringEnc('no_xray_image'))});
            }

            //
            let textSrc = animation ? (mode == OEPhilipsIcarusMode.std ? animation.textSrc : (mode == OEPhilipsIcarusMode.xRay ? animation.xRayTextSrc : undefined)) : undefined;
            let textAvailable = !!textSrc;

            if(this.textBox.available !== textAvailable) {
                this.textBox.available = textAvailable;
                if(!textAvailable)  this.textBox.isOpen = false;
                this.setState({textBox: clone(this.textBox)});

            }
        });

        //
        this.updateContextMenuContainerRestriction();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.visible = false;
            this.textBox = {
                available: false,
                isOpen: false,
                layoutType: OEOverlayTextBox.ExtLayoutType.right
            };
            this.setState({
                visible: false,
                textBox: clone(this.textBox)
            });
            this.updateEdgeOffsets();
            this.updateContextMenuContainerRestriction();
            return;
        }

        this.updateVPFrame();

        retardUpdate(this, () => {
            this.updateLanguage();
            this.update();
        });
    }

    onCustomPropertyChanged(message, userInfo)   {
        if(userInfo.key === OEPhilipsIcarusCustomPropKey.mode || userInfo.key === OEPhilipsIcarusCustomPropKey.animationId)   {
            this.update();
        }
    }

    onWindowResized()   {
        this.updateVPFrame();
    }

    onXRayContainerResize(sender, size) {
        this.xRayContainerSize = size;
        this.setState({xRayContainerSize: size});
    }

    renderResetCameraBtn(visible = true)  {
        return (
            <OEButton
                className="transparent-btn view-port-label-text-color reset-camera center"
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
                onPressed={this.onResetCameraBtnPressed}
            >
                <OEIcon code={OEIconCodes.philipsIcarus.resetCamera}/>
            </OEButton>
        );
    }

    renderTextBoxBtn(visible = true, className = '')  {
        visible = visible && this.state.visible && this.state.textBox.available;
        return (
            <OEButton
                className={'transparent-btn view-port-label-text-color text-box ' + className + ' screenshot-filter-show'}
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
                onPressed={this.onTextBoxBtnPressed}
            >
                <OEIcon code={OEIconCodes.philipsIcarus.textBox}/>
            </OEButton>
        );
    }

    renderTextBox(visibility = true) {
        return (
            <OEPhilipsIcarusTextBox
                moduleId={this.props.moduleId}
                isHidden={!visibility}
                isOpen={this.state.textBox.isOpen}
                extLayoutType={this.state.textBox.layoutType}
                onToggle={this.onTextBoxToggle}
                onToLeftRightBtnPressed={this.onTextBoxToLeftRightBtnPressed}
            />
        );
    }

    renderXRayContainer()  {
        const visible = this.state.visible && this.state.mode == OEPhilipsIcarusMode.xRay;

        return (
            <div
                className="screenshot-filter-show"
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0'}}
            >
                <div
                    className="x-ray-container-backdrop"
                    style={this.state.xRayContainerSize ? {width: this.state.xRayContainerSize.w} : null}
                />

                <div className="x-ray-container">
                    <OEResizeObserver onResize={this.onXRayContainerResize} />

                    {/*this.renderResetCameraBtn(visible)*/}
                    {/*this.renderTextBoxBtn(visible, 'center')*/}
                    {/*this.renderTextBox(this.state.textBox.layoutType === OEOverlayTextBox.ExtLayoutType.right)*/}

                    <img src={this.state.xRayImage}/>
                </div>
            </div>
        );
    }

    renderTextBoxContainer() {
        const visible = this.state.visible;
        const width = this.state.mode == OEPhilipsIcarusMode.xRay && this.state.xRayContainerSize ? this.state.xRayContainerSize.w : undefined;

        return (
            <div
                className={'text-box-container layout-type-' + this.state.textBox.layoutType + ' screenshot-filter-show'}
                style={{visibility: visible ? 'visible' : 'hidden', opacity: visible ? '1' : '0', width: width}}
            >
                {this.renderTextBox(this.state.mode != OEPhilipsIcarusMode.xRay || this.state.textBox.layoutType === OEOverlayTextBox.ExtLayoutType.left)}
            </div>
        );
    }

    render() {
        const modeClassName = OEPhilipsIcarusMode.modeToString(this.state.mode);

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'overlay philips-icarus '+ modeClassName + ' std-label-text-color'}>

                    {this.renderXRayContainer()}
                    {/*this.renderTextBoxContainer()*/}
                    {/*this.renderTextBoxBtn(this.state.mode != OEPhilipsIcarusMode.xRay)*/}

                </div>
            </React.Fragment>
        );
    }

    onResetCameraBtnPressed()   {
        this.oe.sharedInterface.postNotification(OEPhilipsIcarusOverlayNotifications.cameraResetBtnPressed, null);
    }

    onTextBoxBtnPressed()   {
        this.setTextBoxOpen(!this.textBox.isOpen);
    }

    onTextBoxToggle()   {
        this.setTextBoxOpen(false);
    }

    onTextBoxToLeftRightBtnPressed(layoutType)    {
        this.setTextBoxLayoutType(layoutType);
    }
}

OEPhilipsIcarusOverlay.defaultProps = {
    moduleId: ''
};

OEPhilipsIcarusOverlay.propTypes = {
    moduleId: PropTypes.string
};

export default frefFromRef(connectModuleEnv((env) => {
    return {
        uiStateManager: env.component.uiStateManager,
        ui: {
            mainMenuWidth: env.ui.mainMenu.width,
            capBarSize: env.ui.capBar.size,
            bottomEdgeOffset: env.ui.bottomEdgeOffset.value
        }
    };
})(frefToRef(OEPhilipsIcarusOverlay)));
