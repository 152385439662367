import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEIcon from '../../elements/oe-icon';
import OEColor from '../../color-picker/oe-color';

export default class OEMediaCenterCategoryController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            categories: [],
            actualCategory: -1
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);

        this.onActualMediaCenterCategoryChanged = this.onActualMediaCenterCategoryChanged.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterCategoryChanged, this.onActualMediaCenterCategoryChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterCategoryChanged, this.onActualMediaCenterCategoryChanged);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({categories: [], actualCategory: -1});
            return;
        }

        var mediaCenter = this.oe.sharedInterface.getUIControllerMediaCenter();

        this.setState({categories: mediaCenter.getCategories(), actualCategory: mediaCenter.getActualCategory()});
    }

    onActualMediaCenterCategoryChanged(message, userInfo)    {
        this.setState({actualCategory: userInfo.category});
    }

    render() {
        var disabled = !(this.props.enabled);

        var categories = [];
        for(var i = 0; i < this.state.categories.length; ++i)   {
            const index = i; // do not remove this otherwise parameter index in onClick revers to some reference to i, yielding index === this.state.categories.length
            const category = this.state.categories[index];
            if(category.categoryFlags == 0) continue;

            categories.push(<div
                key={index}
                className={'media-center-category-item light-hover-bg' + (index === this.state.actualCategory ? ' selected' : '') + (disabled ? ' disabled' : '')}
                onClick={disabled ? () => {}: () => this.onClick(index)}
            >
                <OEIcon
                    style={{color: OEColor.toDOMStr(category.icon.color)}}
                    code={category.icon.code}
                />
            </div>);
        }

        if(this.props.hideIfNotNeeded && (categories.length === 0 || (categories.length === 1 && this.state.actualCategory >= 0)))  {
            return null;
        }

        return (
            <div 
                className={this.props.className + ' media-center-category-collection'} 
                style={this.props.style}
            >
                {categories}
            </div>
        );
    }

    onClick(index)   {
        this.oe.sharedInterface.getUIControllerMediaCenter().setActualCategory(index);
    }
}

OEMediaCenterCategoryController.defaultProps = {
    className: '',
    moduleId: '',
    style: null,
    enabled: true
};

OEMediaCenterCategoryController.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
};