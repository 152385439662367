import React from 'react';  
import PropTypes from 'prop-types';

let InstanceIDCounter = 0;

export default class OEMouseTracker extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.instanceID = '-ID' + InstanceIDCounter.toString();
        InstanceIDCounter++;

        this.mouseState = 0;

        //console.log('OEMouseTracker instance ID - ' + this.instanceID);

        this.onMouseDown = this.onMouseDown.bind(this);
    }

    changeMouseState(state, props) {
        if(this.mouseState === state)   return;
        this.mouseState = state;
        props = props || this.props;
        if(props.onMouseState) props.onMouseState(state);
    }

    componentWillUnmount()    {
        this.mouseDestroy();
    }

    componentDidMount()    {
        this.mouseInit();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.element !== this.props.element || nextProps.name !== this.props.name)     {
            this.mouseDestroy();
            this.mouseInit(nextProps);
        } else if(nextProps.disabled != this.props.disabled && nextProps.disabled) {
            if(nextProps.disabled)  {
                this.mouseDestroy();
            } else {
                this.mouseInit(nextProps);
            }
        }
    }

    mouseInit(props)    {
        if(this.props.disabled) return;

        props = props || this.props;
        if(props.element)   {
            $(props.element).on('mousedown.' + props.name + this.instanceID, this.onMouseDown);
        }

        this.mouseStarted = false;
        this.changeMouseState(0, props);
    }

    mouseDestroy(props) {
        props = props || this.props;
        if(props.element)   {
            $(props.element).off( '.' + props.name + this.instanceID);
        }
        
        if(this.mouseMoveDelegate)  $(document).off('mousemove.' + props.name + this.instanceID, this.mouseMoveDelegate);
        if(this.mouseUpDelegate)  $(document).off('mouseup.' + props.name + this.instanceID, this.mouseUpDelegate);

        this.mouseMoveDelegate = undefined;
        this.mouseUpDelegate = undefined;

        this.changeMouseState(0, props);
    }

    onMouseDown(event)    {
        if(this.mouseStarted)   {
            this.changeMouseState(0);
            this.onMouseUp(event);
        }

        if(event.buttons !== 1)  return;

        this.mouseStarted = true;

        this.mouseMoveDelegate = this.onMouseMove.bind(this);
        this.mouseUpDelegate = this.onMouseUp.bind(this);

        $(document).on('mousemove.' + this.props.name + this.instanceID, this.mouseMoveDelegate);
        $(document).on('mouseup.' + this.props.name + this.instanceID, this.mouseUpDelegate);

        this.changeMouseState(1);

        if(this.props.onMouseDown)  this.props.onMouseDown(event);
    }

    onMouseMove(event)  {
        if(!this.mouseStarted)   {
            if(this.props.onMouseDown)  this.props.onMouseDown(event);
            return;
        }

        if(this.props.onMouseMove)  this.props.onMouseMove(event);
    }

    onMouseUp(event)    {
        if(this.mouseMoveDelegate)  $(document).off('mousemove.' + this.props.name + this.instanceID, this.mouseMoveDelegate);
        if(this.mouseUpDelegate)  $(document).off('mouseup.' + this.props.name + this.instanceID, this.mouseUpDelegate);

        this.mouseMoveDelegate = undefined;
        this.mouseUpDelegate = undefined;

        this.changeMouseState(0);

        if(this.mouseStarted)   {
            this.mouseStarted = false;
            if(this.props.onMouseUp)  this.props.onMouseUp(event);
        }
    }

    render()    {
        return null;
    }
}

OEMouseTracker.defaultProps = {
    disabled: false
};

OEMouseTracker.propTypes = {
    element: PropTypes.object,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseState: PropTypes.func
};