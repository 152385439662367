import PropTypes from 'prop-types';
import clone from 'clone';

import {OEViewType, OEMainMenuStdBtnType, OEContextMenuContentType, OEStartViewTypes, OEHelpViewTypes, OEHelpViewEntryType, OEManualViewLinks, OEManualViewLinkType, OECutControllerStyle, OEFeature, OESettingsControllerDefs, OEMainMenuControlBarEntryTypes, OERightTopBarEntryTypes} from '../lib/oe-types';
import {OETargetMenuDefs} from './elements/target-menu/oe-target-menu-defs';
import {OEMediaCenterItemTypes, OEMediaCenterMode} from './controller/media-center/oe-media-center-model';
import {BottomWidgetToolbarConfig} from './bars/oe-bottom-widget-toolbar';
import {OEMainMenuBtnAdapterView} from './oe-main-menu-btn-adapter';

export const OEDefaultConfigFactory = {};
    
OEDefaultConfigFactory.mainMenu = function()  {
    return {
        initiallyVisible: false
    };
};

OEDefaultConfigFactory.mainMenuStdButton = function()  {
    let buttons = {};
    Object.keys(OEMainMenuStdBtnType).forEach(key => {
        let value = OEMainMenuStdBtnType[key]; buttons[value] = {type: value, enabled: true};
    });

    return type => {
        return type !== OEMainMenuStdBtnType.screenshot ? clone(buttons[type]) : {type: OEMainMenuStdBtnType.screenshot, enabled: true, adapter: new OEMainMenuBtnAdapterView(OEViewType.screenshot, null, OEFeature.screenshot)};
    }
}();

OEDefaultConfigFactory.mainMenuButtonArray = function(buttons)  {
    return buttons.map(btn => typeof(btn) === 'string' ? OEDefaultConfigFactory.mainMenuStdButton(btn) : btn);
};

OEDefaultConfigFactory.mainMenuTopButtonsWithoutSubstructure = function()  {
    let Type = OEMainMenuStdBtnType;
    let buttons = [Type.home, Type.search, Type.tree, Type.label, Type.note, Type.arrow, Type.cut, Type.color, Type.explode, Type.presentation, Type.mediaCenter, Type.screenshot, Type.info, Type.settings];
    return () => OEDefaultConfigFactory.mainMenuButtonArray(buttons);
}();

OEDefaultConfigFactory.mainMenuTopButtons = function()  {
    let Type = OEMainMenuStdBtnType;
    let buttons = [Type.home, Type.search, Type.tree, Type.label, Type.note, Type.arrow, Type.cut, Type.substructure, Type.color, Type.explode, Type.presentation, Type.mediaCenter, Type.screenshot, Type.info, Type.settings];
    return () => OEDefaultConfigFactory.mainMenuButtonArray(buttons);
}();

OEDefaultConfigFactory.mainMenuBottomButtons = function()  {
    return [
        {type: OEMainMenuStdBtnType.back, enabled: true},
        {type: OEMainMenuStdBtnType.forth, enabled: true}
    ];
};

OEDefaultConfigFactory.mainMenuButtons = function()  {
    return {
        top: this.mainMenuTopButtons(),
        bottom: this.mainMenuBottomButtons()
    };
};

OEDefaultConfigFactory.mainMenuButtonsSubstructure = function()  {
    return {
        top: this.mainMenuTopButtonsSubstructure(),
        bottom: this.mainMenuBottomButtons()
    };
};

OEDefaultConfigFactory.mainMenuControlBar = function()  {
    return {
        enabled: true,
        entries: [
            {type: OEMainMenuControlBarEntryTypes.mainMenuControlButton}
        ]
    };
};

OEDefaultConfigFactory.contextMenu = function() {
    return {
        content: {
            type: OEContextMenuContentType.none,
            showReferences: false
        }
    };
};

OEDefaultConfigFactory.targetMenu = function()  {
    return {
        enabled: true,
        entries: OETargetMenuDefs.all
    };
};

OEDefaultConfigFactory.startView = function()  {
    return {
        type: OEStartViewTypes.std,
        logo: null,
        showAtStartUp: true,
        targetViewShowedAtStartFlag: false,
        videoContent: 'NnqI0x9lr6M'
    };
};

OEDefaultConfigFactory.helpView = function()  {
    return {
        type: OEHelpViewTypes.std,
        logo: null,
        showAtStartUp: false,
        entries: [
            {type: OEHelpViewEntryType.advice},
            {type: OEHelpViewEntryType.quickStart},
            {type: OEHelpViewEntryType.tutorReset},
            {type: OEHelpViewEntryType.credits},
            {type: OEHelpViewEntryType.feedback}
        ]
    };
};

OEDefaultConfigFactory.helpViewManual = function()  {
    return {
        type: OEHelpViewTypes.std,
        logo: null,
        showAtStartUp: false,
        entries: [
            {type: OEHelpViewEntryType.manual, link: OEManualViewLinks.app},
            {type: OEHelpViewEntryType.quickStart},
            {type: OEHelpViewEntryType.tutorReset},
            {type: OEHelpViewEntryType.credits},
            {type: OEHelpViewEntryType.feedback}
        ]
    };
};

OEDefaultConfigFactory.helpViewManualVideoTutorial = function()  {
    return {
        type: OEHelpViewTypes.std,
        logo: null,
        showAtStartUp: false,
        entries: [
            {type: OEHelpViewEntryType.manual, link: OEManualViewLinks.app},
            {type: OEHelpViewEntryType.quickStart},
            {type: OEHelpViewEntryType.videoTutorials},
            {type: OEHelpViewEntryType.tutorReset},
            {type: OEHelpViewEntryType.credits},
            {type: OEHelpViewEntryType.feedback}
        ]
    };
};

OEDefaultConfigFactory.helpViewDecl = PropTypes.shape({
    type: PropTypes.oneOf([OEHelpViewTypes.std, OEHelpViewTypes.start, OEHelpViewTypes.manual]),
    logo: PropTypes.string,
    showAtStartUp: PropTypes.bool,
    entries: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf([
            OEHelpViewEntryType.advice,
            OEHelpViewEntryType.manual,
            OEHelpViewEntryType.quickStart,
            OEHelpViewEntryType.videoTutorials,
            OEHelpViewEntryType.tutorReset,
            OEHelpViewEntryType.credits,
            OEHelpViewEntryType.feedback
        ]).isRequired
    })).isRequired
});

OEDefaultConfigFactory.manualView = function()  {
    return {
        logo: null,
        showAtStartUp: false,
        pdfURL: '',
        links: {
            cut: {type: OEManualViewLinkType.youtube, ref: 'tutorial_video_2_youtube'}
        }
    };
};

OEDefaultConfigFactory.manualViewCambridgeCell = function()  {
    return {
        logo: null,
        showAtStartUp: false,
        pdfURL: '',
        links: {
            app: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 1},
            welcome: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 1},
            controls: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 2},
            functions: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 3},
            contextMenu: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 4},
            search: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 5},
            component: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 6},
            note: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 7},
            arrow: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 8},
            color: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 9},
            presentation: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 10},
            mediaCenter: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 11},
            settings: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 12}
        }
    };
};

OEDefaultConfigFactory.manualViewPDF = function(withSubstructure)  {

    let links = {
        app: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 1},
        welcome: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 1},
        controls: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 2},
        functions: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 3},
        contextMenu: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 4},
        search: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 5},
        component: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 6},
        label: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 7},
        note: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 8},
        arrow: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 9},
        cut: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 10},
        color: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 11},
        presentation: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 12},
        mediaCenter: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 13},
        settings: {type: OEManualViewLinkType.pdf, ref: 'manual', page: 14}
    };
    
    if(withSubstructure)    {
        links.substructure = {type: OEManualViewLinkType.pdf, ref: 'manual', page: 11};

        Object.keys(links).forEach(key => {
            if(key === OEManualViewLinks.substructure)  return;
            let link = links[key];
            if(typeof(link.page) === 'number' && link.page >= links.substructure.page)   link.page++;
        });
    }

    return {
        logo: null,
        showAtStartUp: false,
        pdfURL: '',
        links: links
    };
};

OEDefaultConfigFactory.componentController = function(params)  {
    return Object.assign({
        enabled: true,
        searchBar: true,
        showHelpBtn: true,
        colorBtnEnabled: true,
        colorPurityControl: true,
        catSwitchBtnEnabled: true,
        catActivationBtnEnabled: true
    }, params);
};

OEDefaultConfigFactory.labelController = function()  {
    return {
        enabled: true,
        showHelpBtn: true
    };
};

OEDefaultConfigFactory.cutController = function()  {
    return {
        enabled: true,
        showHelp: true,
        showHelpBtn: true,
        style: OECutControllerStyle.std
    };
};

OEDefaultConfigFactory.settingsController = function()  {
    return {
        enabled: true,
        showHelpBtn: true,
        controls: [clone(OESettingsControllerDefs.defaultLanguageControl), clone(OESettingsControllerDefs.defaultBackgroundControl), clone(OESettingsControllerDefs.defaultInputSensitivityControl), clone(OESettingsControllerDefs.defaultGraphicsControl)]
    };
};

OEDefaultConfigFactory.substructureController = function()  {
    return {
        enabled: true,
        showHelpBtn: true
    };
};

OEDefaultConfigFactory.searchController = function()  {
    return {
        enabled: true,
        showHelpBtn: true
    };
};

OEDefaultConfigFactory.presentationController = function()  {
    return {
        enabled: true,
        showHelpBtn: true
    };
};

OEDefaultConfigFactory.noteController = function()  {
    return {
        enabled: true,
        showHelpBtn: true
    };
};

OEDefaultConfigFactory.arrowController = function()  {
    return {
        enabled: true,
        showHelpBtn: true
    };
};

OEDefaultConfigFactory.screenshotController = function()  {
    return {
        enabled: true,
        showHelpBtn: true
    };
};

OEDefaultConfigFactory.textViewController = function(adapter)  {
    return {
        enabled: !!adapter,
        adapter: adapter
    };
};

OEDefaultConfigFactory.combineShowHelpState = function(config, showHelp = true, links, link)  {
    if(links && link && !links[link])   showHelp = false;
    return (showHelp || !config) ? config : Object.assign({}, config, {showHelpBtn: showHelp && config.showHelpBtn, showHelp: showHelp && config.showHelp});
};

OEDefaultConfigFactory.mediaCenter = function(bigPlayBtnStyle)  {
    let res = {
        showHelpBtn: true,
        mode: OEMediaCenterMode.std,
        hideBottomBarControlsForItems: [],
        hideSliderForItems: [],
        bigPlayBtn: false,
        hideCategoryBarIfNotNeeded: false,
        doNotPlayPresentationWithoutAudio: false,
        presPreviewOnlyInPlayMode: false,
        hideLeftSlideInInPlayMode: false
    };

    if(bigPlayBtnStyle) {
        res.hideBottomBarControlsForItems = [OEMediaCenterItemTypes.presentation, OEMediaCenterItemTypes.animation];
        res.hideSliderForItems = [OEMediaCenterItemTypes.presentation];
        res.bigPlayBtn = true;
        res.hideCategoryBarIfNotNeeded = true;
        res.doNotPlayPresentationWithoutAudio = true;
        res.presPreviewOnlyInPlayMode = true;
    }

    return res;
};

OEDefaultConfigFactory.widget = function()  {
    return {
        showHelp: true,
        labelController: this.labelController(),
        cutController: this.cutController(),
        settingsController: this.settingsController(),
        substructureController: this.substructureController(),
        searchController: this.searchController(),
        presentationController: this.presentationController(),
        noteController: this.noteController(),
        arrowController: this.arrowController(),
        bottomWidgetToolbar: new BottomWidgetToolbarConfig(),
        componentController: this.componentController(),
        screenshotController: this.screenshotController(),
        textViewController: this.textViewController()
    };
};

OEDefaultConfigFactory.infoView = function()  {
    return {
        logo: null,
        header: true,
        tutorials: true,
        mediaContent: null
    };
};

OEDefaultConfigFactory.rightTopBar = function()  {
    return {
        enabled: true,
        entries: [
            {type: OERightTopBarEntryTypes.targetMenu}
        ]
    };
};

OEDefaultConfigFactory.uiLayer = function() {
    return {
        mainMenuButtons: this.mainMenuButtons(),
        mainMenuConfig: this.mainMenu(),
        mainMenuControlBar: this.mainMenuControlBar(),
        contextMenu: this.contextMenu(),
        widgetConfig: this.widget(),
        startViewConfig: this.startView(),
        infoViewConfig: this.infoView(),
        helpViewConfig: this.helpView(),
        manualViewConfig: this.manualView(),
        hasMediaCenter: true,
        targetMenuConfig: this.targetMenu(),
        mediaCenterConfig: this.mediaCenter(),
        rightTopBar: this.rightTopBar(),
        disableTutor: false
    };
};