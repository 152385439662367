import React from 'react';  
import PropTypes from 'prop-types';
import clone from 'clone';

import {withIsOpenState} from '../../../lib/oe-higher-order-components';
import OEIcon from '../../elements/oe-icon';
import {OEIconButton} from '../../elements/oe-button';
import OEWidgetHeader from '../../elements/oe-widget-header';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import OEPopover from '../../oe-popover';
import {OEPopoverMenuButton} from '../../oe-popover-menu';
import {OEToolbox} from '../../../lib/oe-toolbox';
import OEScrollbars from '../../oe-scrollbars';
import {OEFunctionFragmentType, OEFunctionFragmentIcons, OEFunctionFragmentParameterModel} from './oe-function-model';
import OEFunctionFragmentParameterCellFactory from './oe-function-parameter-cells';

export class OEFunctionFragmentParameterEdit extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            entries: this.createEntries()
        };

        this.onParamterCellChange = this.onParamterCellChange.bind(this);
    }

    createEntries(props)    {
        props = props || this.props;

        let ret = [];

        for(let key in props.model)   {
            let modelEntry = props.model[key];
            let entry = {
                name: key,
                type: modelEntry.type,
                label: modelEntry.label || key,
                value:  props.params[key]
            };
            ret.push(entry);
        }

        return ret;
    }

    componentWillReceiveProps(nextProps)    {
        if(!OEToolbox.shallowEqual(nextProps.params, this.props.params) || !OEToolbox.shallowEqual(nextProps.model, this.props.model))    {
            this.setState({entries: this.createEntries(nextProps)});
        }
    }

    render() {
        let parameterCells = this.state.entries.map((entry, index) => <OEFunctionFragmentParameterCellFactory key={index} {...entry} onChange={this.onParamterCellChange}/>);
        return  (
            <div className="fragment-parameter-edit">
                <div className="content">
                    {parameterCells}
                </div>
            </div>
        );
    }

    onParamterCellChange(sender, name, value)  {
        let params = clone(this.props.params);
        params[name] = value;
        if(!this.props.onChange || OEToolbox.shallowEqual(params, this.props.params))   return;
        this.props.onChange(this, params);
    }
};

OEFunctionFragmentParameterEdit.defaultProps = {
    model: {},
    params: {}
};

OEFunctionFragmentParameterEdit.propTypes = {
    model: PropTypes.object,
    params: PropTypes.object,
    onChange: PropTypes.func
};

export class OEFunctionFragmentCell extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            parameterModel: OEFunctionFragmentParameterModel.modelForType(this.props.type)
        };

        this.onDumpBtnPressed = this.onDumpBtnPressed.bind(this);
        this.onParameterChange = this.onParameterChange.bind(this);
    }

    componentWillReceiveProps(nextProps)    {
        if(nextProps.type !== this.props.type)    {
            this.setState({parameterModel: OEFunctionFragmentParameterModel.modelForType(this.props.type)});
        }
    }

    render() {
        return  (
            <div className="fragment-cell">
                <div className="content">
                    <div className="bar">
                        <OEIcon className="fragment-type" code={OEFunctionFragmentIcons.iconForType(this.props.type)}/>
                        <OEIconButton className="transparent-btn dump-btn" icon={OEIconCodes.func.dumpFragment} onPressed={this.onDumpBtnPressed}/>
                    </div>
                    <OEFunctionFragmentParameterEdit model={this.state.parameterModel} params={this.props.params} onChange={this.onParameterChange}/>
                </div>
                {this.props.showSeparator ? <div className="std-separator-border-color separator"/> : null}
            </div>
        );
    }

    onDumpBtnPressed()  {
        if(this.props.onDumpBtnPressed) this.props.onDumpBtnPressed(this, this.props.id);
    }

    onParameterChange(sender, params)   {
        if(this.props.onChange) this.props.onChange(this, this.props.id, params);
    }
}

OEFunctionFragmentCell.defaultProps = {
};

OEFunctionFragmentCell.propTypes = {
    id: PropTypes.number,
    type: PropTypes.number,
    params: PropTypes.object,
    showSeparator: PropTypes.bool,
    onChange: PropTypes.func,
    onDumpBtnPressed: PropTypes.func
};

export class OEFunctionController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.addFragmentMenuEntries = [
            {id: OEFunctionFragmentType.setMediaCenterItem, icon: OEFunctionFragmentIcons.iconForType(OEFunctionFragmentType.setMediaCenterItem)}
        ];

        this.func = this.sanitizeFunc(this.props.func);

        this.state = {
            addFragmentMenuEntries: this.filteredAddFragmentMenuEntries(),
            func: clone(this.func)
        };

        this.onAddFragmentMenuRef = this.onAddFragmentMenuRef.bind(this);

        this.renderHeaderCenterBar = this.renderHeaderCenterBar.bind(this);

        this.onAddFragmentBtnPressed = this.onAddFragmentBtnPressed.bind(this);
        this.onAddFragmentMenuSelection = this.onAddFragmentMenuSelection.bind(this);

        this.onFragmentChange = this.onFragmentChange.bind(this);
        this.onDumpFragmentBtnPressed = this.onDumpFragmentBtnPressed.bind(this);
    }

    filteredAddFragmentMenuEntries(entries)    {
        entries = clone(this.addFragmentMenuEntries || entries);
        if(!this.func || !this.func.fragments.length || !entries.length)   return entries;
        return entries.filter(entry => this.func.fragments.findIndex(fragment => fragment.type === entry.id) < 0);
    }

    sanitizeFunc(func)  {
        func = clone(func);
        if(!func || Array.isArray(func.fragments))      return func;
        func.fragments = [];
        return func;
    }

    setFunction(func_)   {
        if(typeof(func_) !== 'undefined' && typeof(func_) !== 'object')   return;
        let func = this.sanitizeFunc(func_);
        if(OEToolbox.shallowEqual(func, this.func)) return;
        this.func = func;
        this.setState({func: clone(this.func)});
        this.updateAddFragmentMenuEntriesState();
    }

    componentWillReceiveProps(nextProps)    {
        if(!OEToolbox.shallowEqual(nextProps.func, this.props.func))    {
            let func = this.sanitizeFunc(nextProps.func);
            if(!OEToolbox.shallowEqual(func, this.props.func))    {
                this.func = func;
                this.setState({func: clone(this.func)});
                this.updateAddFragmentMenuEntriesState();
            }
        }
    }

    updateAddFragmentMenuEntriesState(entries) {
        let addFragmentMenuEntries = this.filteredAddFragmentMenuEntries(entries);
        this.setState({addFragmentMenuEntries: this.filteredAddFragmentMenuEntries()});
        if(!addFragmentMenuEntries.length && this.addFragmentMenuRef)   this.addFragmentMenuRef.close();
    }

    onAddFragmentMenuRef(ref)   {
        this.addFragmentMenuRef = ref;
    }

    renderHeaderCenterBar(props)    {
        return (
            <React.Fragment>
                <OEPopoverMenuButton
                    ref={this.onAddFragmentMenuRef}
                    className="transparent-btn add-fragment-btn"
                    popoverClassName="popover-control with-backdrop"
                    placement="top"
                    boundariesElement={props.boundariesElement}
                    hideArrow={false}
                    entries={props.addFragmentMenuEntries}
                    onChange={this.onAddFragmentMenuSelection}
                    disabled={!this.state.addFragmentMenuEntries.length}
                    onPressed={this.onAddFragmentBtnPressed}
                >
                    <OEIcon code={OEIconCodes.preset.add}/>
                </OEPopoverMenuButton>
            </React.Fragment>
        );
    }

    renderFragments()   {
        if(!this.state.func)    return null;
        return this.state.func.fragments.map((fragment, index) => <OEFunctionFragmentCell key={index} id={index} type={fragment.type} params={fragment.params} showSeparator={index < this.state.func.fragments.length - 1} onChange={this.onFragmentChange} onDumpBtnPressed={this.onDumpFragmentBtnPressed}/>);
    }
    
    render() {
        return  (
            <React.Fragment>
                <div className={'function-controller ' + this.props.className}>

                    <OEWidgetHeader
                        moduleId={this.props.moduleId}
                        buttonClassName="transparent-btn"
                        icon={this.props.icon}
                        title={this.props.title}
                        titleId={this.props.titleId}
                        headerSeparator={this.props.headerSeparator}
                        centerBar={this.renderHeaderCenterBar}
                        onToggle={this.props.onToggle}
                        boundariesElement={this.props.boundariesElement}
                        addFragmentMenuEntries={this.state.addFragmentMenuEntries}
                    />

                    {this.props.children}

                    <div className="fragment-list">
                        <OEScrollbars>
                            {this.renderFragments()}
                        </OEScrollbars>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onChange(func)  {
        if(OEToolbox.shallowEqual(func, this.func)) return;

        if(this.props.onChange)   {
            this.props.onChange(this, func);
            return;
        }

        this.func = func
        this.setState({func: clone(this.func)});
        this.updateAddFragmentMenuEntriesState();
    }

    onAddFragmentBtnPressed()   {
        this.addFragmentMenuRef.toggle();
    }

    onAddFragmentMenuSelection(id)    {
        if(this.addFragmentMenuRef) this.addFragmentMenuRef.close();
        let func = clone(this.func);
        if(!func)  func = {fragments: []};
        func.fragments.push({type: id});
        this.onChange(func);
    }

    onDumpFragmentBtnPressed(sender, index) {
        if(!this.func || index < 0 || index >= this.func.fragments.length)  return;
        let func = clone(this.func);
        func.fragments.splice(index, 1);
        this.onChange(func);
    }

    onFragmentChange(sender, index, params) {
        if(!this.func || index < 0 || index >= this.func.fragments.length)  return;
        let func = clone(this.func);
        func.fragments[index].params = clone(params);
        this.onChange(func);
    }
}

OEFunctionController.defaultProps = {
    moduleId: '',
    className: '',
    titleId: 'function_view',
    headerSeparator: true
};

OEFunctionController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    titleId: PropTypes.string,
    headerSeparator: PropTypes.bool,
    onToggle: PropTypes.func,
    func: PropTypes.shape({
        fragments: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.number.isRequired,
        })),
    }),
    onChange: PropTypes.func
};

export const OEFunctionPopoverController = OEPopover.coat(OEFunctionController, {
    noHeader: true,
}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OEFunctionPopoverController);