import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEPopover from '../../oe-popover';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEIconButton} from '../../elements/oe-button';
import OEWidgetHeader from '../../elements/oe-widget-header';
import {OEGroupControl, OEControl} from '../../oe-controls';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEAssetSelectionType} from '../../controller/asset-selection/oe-asset-selection-model';
import {OEAssetSelectionPopover} from '../../controller/asset-selection/oe-asset-selection-controller';
import OEPresentationAttributeHelper from './attribute/oe-presentation-attribute-helper';

export class OEAddPresentationController extends React.PureComponent {

    constructor(props)  {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.isAssetSelectionControllerOpen = false;

        this.state = {
            uiEnabled: false,
            //strings: {}
            isAssetSelectionControllerOpen: this.isAssetSelectionControllerOpen
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onAnimationAssetBtnPressed = this.onAnimationAssetBtnPressed.bind(this);
        this.onAssetSelectionControllerToggle = this.onAssetSelectionControllerToggle.bind(this);
        this.onAssetSelectionOkBtnPressed = this.onAssetSelectionOkBtnPressed.bind(this);
    }

    setAssetSelectionControllerOpen(open)   {
        if(this.isAssetSelectionControllerOpen === open)    return;
        this.isAssetSelectionControllerOpen = open;
        this.setState({isAssetSelectionControllerOpen: this.isAssetSelectionControllerOpen});
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLanguage()   {
        /*
        let si = this.oe.sharedInterface;
        this.setState({strings: {}});
        */
    }

    updateUIState()   {
        this.setState({
            uiEnabled: this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled()
        });
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.isAssetSelectionControllerOpen = false;
            this.setState({
                uiEnabled: false,
                isAssetSelectionControllerOpen: this.isAssetSelectionControllerOpen
            });
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.updateUIState();
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation) {
            this.updateUIState();
        }
    }

    render()    {
        const disabled = !this.state.uiEnabled;
        return(
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'add-presentation-controller' + this.props.className}>
                    <OEWidgetHeader
                        moduleId={this.props.moduleId}
                        buttonClassName="transparent-btn"
                        icon={this.props.icon}
                        title={this.props.title}
                        titleId={this.props.titleId}
                        headerSeparator={this.props.headerSeparator}
                        onToggle={this.props.onToggle}
                    />

                    <OEGroupControl>
                        <OEControl id="add-presentation-controller-func-btn-bar-id" className="func-btn-bar">
                            <OEIconButton
                                className="transparent-btn animation-asset-btn"
                                disabled={disabled}
                                activated={this.state.isAssetSelectionControllerOpen}
                                onPressed={this.onAnimationAssetBtnPressed}
                                icon={OEIconCodes.pres.slideAnimationAttribute}
                            />
                        </OEControl>
                    </OEGroupControl>

                    <OEAssetSelectionPopover
                        moduleId={this.props.moduleId}
                        target={this.props.popoverTargetId ? this.props.popoverTargetId : 'add-presentation-controller-func-btn-bar-id'}
                        placement="right-start"
                        hideArrow={this.props.popoverTargetId !== undefined}
                        isOpen={this.state.isAssetSelectionControllerOpen}
                        onToggle={this.onAssetSelectionControllerToggle}
                        backdrop={false}
                        types={[OEAssetSelectionType.animation]}
                        allowsMultipleSelection={true}
                        onOkBtnPressed={this.onAssetSelectionOkBtnPressed}
                    />

                </div>
            </React.Fragment>
        );
    }

    onAnimationAssetBtnPressed()    {
        this.setAssetSelectionControllerOpen(!this.isAssetSelectionControllerOpen);
    }

    onAssetSelectionControllerToggle()  {
        this.setAssetSelectionControllerOpen(false);
    }

    onAssetSelectionOkBtnPressed(selection)  {
        this.setAssetSelectionControllerOpen(false);
        if(!this.oe.isReady())  return;
        let attributes = OEPresentationAttributeHelper.buildSlideAttributes(this.oe, selection);
        this.oe.sharedInterface.getUIControllerPresentation().addPresentationWithAttributes(attributes);
        if(this.props.onToggle)    this.props.onToggle();
    }
}

OEAddPresentationController.defaultProps = {
    moduleId: '',
    className: '',
    icon: OEIconCodes.pres.add,
    titleId: 'presentation_add_view',
    headerSeparator: true
};

OEAddPresentationController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    titleId: PropTypes.string,
    headerSeparator: PropTypes.bool,
    onToggle: PropTypes.func,
};

export const OEAddPresentationPopover = OEPopover.coat(OEAddPresentationController,  {
    noHeader: true,
}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OEAddPresentationPopover);