import React from 'react';
import PropTypes from 'prop-types';

export default class OEBrowserBar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            entries: this.entriesForPlatform(props)
        };
    }

    entriesForPlatform(props)        {

        var entries = typeof(props.entries) === 'string' ? [ 'firefox', 'chrome' ] : props.entries;

        if(props.entries === 'platform')    {
            if(bowser.ios === true || bowser.mac === true) {
                entries = [ 'firefox', 'chrome', 'safari' ];
            } else if(bowser.windows === true)  {
                entries = [ 'firefox', 'chrome'/*, 'edge'*/ ];
            }
        }
        return entries;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.entries != this.props.entries)     {
            this.setState({entries: this.entriesForPlatform(nextProps)})
        }
    }

    imageForType(type)  {
        switch(type)    {
            case OEBrowserBar.BrowserTypes.safari: return 'images/browser/safari.png';
            case OEBrowserBar.BrowserTypes.firefox: return 'images/browser/firefox.png';
            case OEBrowserBar.BrowserTypes.chrome: return 'images/browser/chrome.png';
            case OEBrowserBar.BrowserTypes.edge: return 'images/browser/edge.png';
            case OEBrowserBar.BrowserTypes.ie: return 'images/browser/ie.png';
        }
    }

    labelForType(type)  {
        switch(type)    {
            case OEBrowserBar.BrowserTypes.safari: return 'Safari';
            case OEBrowserBar.BrowserTypes.firefox: return 'Firefox';
            case OEBrowserBar.BrowserTypes.chrome: return 'Chrome';
            case OEBrowserBar.BrowserTypes.edge: return 'Edge';
            case OEBrowserBar.BrowserTypes.ie: return 'Internet Explorer';
        }
    }

    urlForType(type)  {
        switch(type)    {
            case OEBrowserBar.BrowserTypes.safari: return 'https://www.apple.com/safari/';
            case OEBrowserBar.BrowserTypes.firefox: return 'https://www.mozilla.org/firefox/';
            case OEBrowserBar.BrowserTypes.chrome: return 'https://www.google.com/chrome/';
            case OEBrowserBar.BrowserTypes.edge: return 'https://www.microsoft.com/windows/microsoft-edge';
            case OEBrowserBar.BrowserTypes.ie: return 'https://www.microsoft.com/download/internet-explorer.aspx';
        }
    }

    onClick(type)   {
        var url = this.urlForType(type);
        if(url == null) return;
        var win = window.open(url, '_blank');
        win.focus();
    }

    render() {

        var buttons = this.state.entries.map((type, index) =>
            <button key={index} type="button" onClick={() => this.onClick(type)}>
                <img src={this.imageForType(type)}/>
                <span>{this.labelForType(type)}</span>
            </button>
        );

        return (
            <div className={'browser-bar ' + (this.props.className ? this.props.className : '')}>
                {buttons}
            </div>
        );
    }
}

OEBrowserBar.BrowserTypes = {
    safari: 'safari',
    firefox: 'firefox',
    chrome: 'chrome',
    edge: 'edge',
    ie: 'ie',

    sophisticated: [ 'safari', 'firefox', 'chrome', 'edge' ],
    all: [ 'safari', 'firefox', 'chrome', 'edge', 'ie' ]
};

OEBrowserBar.defaultProps = {
    className: '',
    entries: 'platform'
};

OEBrowserBar.propTypes = {
    className: PropTypes.string,
    entries: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
      ])
};