import React from 'react';  
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OETextFieldControl} from '../../oe-controls';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {retardUpdate} from '../../../lib/update-retarder';

export const OEComponentSearchFlags = {
    none: 0,
    limitPerWord: 1,
    limit: 2,
    matchAllWords: 4,
    enabled: 8,
    reachable: 16,
    categoryEnabled: 32,
    notHidden: 64,
    secondaryLabel : 128,
    default: 250 // secondaryLabel onlyCategoryEnabled onlyReachable limitResults
};

export class OEComponentSearchControl extends React.PureComponent {
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.searchString = typeof(this.props.searchString) === 'string' ? this.props.searchString : '';
        this.searchResult = [];

        this.state = {
            searchString: this.searchString,
            strings: {
                searchLabel: ''
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onComponentsChanged = this.onComponentsChanged.bind(this);
        this.onReset = this.onReset.bind(this);

        this.onSearchInputChanged = this.onSearchInputChanged.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        if(!OEToolbox.jsonEqual(nextProps.options, this.props.options) || this.searchString !== nextProps.searchString)     {
            let searchStringChanged = typeof(nextProps.searchString) === 'string' && this.searchString !== nextProps.searchString;
            if(searchStringChanged)    {
                this.searchString = nextProps.searchString;
                this.setState({ searchString: this.searchString });
            }
            this.updateSearchResult(nextProps, searchStringChanged);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsEnabledStateChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsIsHiddenStateChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsCategoryEnabledStateChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsReachabilityChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.reset, this.onReset);
    }
    
    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsEnabledStateChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsIsHiddenStateChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsCategoryEnabledStateChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsReachabilityChanged, this.onComponentsChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.reset, this.onReset);
    }
    
    updateLanguage() {
        const si = this.oe.sharedInterface;
        this.setState({
            strings: {
                searchLabel: si.getLocalizedStringEnc('search_view_text_field_placeholder')
            }
        });

        this.updateSearchResult();
    }

    updateSearchResult(props, searchStringChanged)    {
        if(!this.oe.isReady()) return;
        let props_ = props || this.props;
        let searchResult = !this.searchString || this.searchString === '' ? [] : this.oe.sharedInterface.getUIControllerSearchTool().search(this.searchString, props_.options.limit, props_.options.flags);
        if(!searchStringChanged && OEToolbox.jsonEqual(searchResult, this.searchResult))     return;
        this.searchResult = searchResult;
        if(this.props.onSearchResultChanged)    this.props.onSearchResultChanged(this, this.searchResult, this.searchString);
    }

    setSearchString(searchString)   {
        if(typeof(this.props.searchString) === 'string' || searchString === this.searchString) return;
        if(searchString === '') {
            this.resetSearchString(); return;
        }
        this.searchString = searchString;
        this.setState({ searchString: this.searchString });
        this.updateSearchResult(null, true);
    }

    resetSearchString() {
        if(typeof(this.props.searchString) === 'string' || this.searchString === '') return;
        this.searchString = '';
        this.setState({ searchString: this.searchString });
        let searchResult = [];
        this.searchResult = searchResult;
        if(this.props.onSearchResultChanged)    this.props.onSearchResultChanged(this, this.searchResult, this.searchString);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            if(this.props.options.autoReset) this.resetSearchString();
            return;
        }
        
        retardUpdate(this, () => {
            if(this.props.options.autoReset) this.resetSearchString();
            this.updateLanguage();
        });
    }

    onComponentsChanged()   {
        this.updateSearchResult();
    }

    onReset()   {
        if(this.props.options.autoReset === true)  this.resetSearchString();
    }
   
    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OETextFieldControl 
                    className="std-label-border-color search-input"
                    disabled={!this.props.enabled}
                    placeholder={this.state.strings.searchLabel}
                    value={this.state.searchString}
                    onChange={this.onSearchInputChanged}
                />
            </React.Fragment>
        );
    }

    onSearchInputChanged(sender, value)   {
        if(!this.props.enabled) return;
        if(typeof(this.props.searchString) === 'string') {
            if(this.props.onSearchInputChanged) this.props.onSearchInputChanged(this, value);
            return;
        }
        this.setSearchString(value);
    }
}

OEComponentSearchControl.defaultProps = {
    moduleId: '',
    enabled: true,
    options: {
        autoReset: true,
        limit: 50,
        flags: OEComponentSearchFlags.default
    }
};

OEComponentSearchControl.propTypes = {
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    options: PropTypes.shape({
        autoReset: PropTypes.bool,
        limit: PropTypes.number.isRequired,
        flags: PropTypes.number.isRequired
    }).isRequired,
    searchString: PropTypes.string,
    onSearchInputChanged: PropTypes.func,
    onSearchResultChanged: PropTypes.func
};