import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';

import {connectAppEnv} from '../../app-env';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {UIControllerType, OEPresetType, OEManualViewLinks, OELanguageType} from '../../../lib/oe-types';
import OEPopover from '../../oe-popover';
import OEIcon from '../../elements/oe-icon';
import {OEIconButton} from '../../elements/oe-button';
import OETextField from '../../elements/oe-text-field';
import {OEGroupControl, OEControl} from '../../oe-controls';
import OEPresentationList from './oe-presentation-list';
import OEPresentationSlideList from './oe-presentation-slide-list';
import OEPresentationAnimationController from './oe-presentation-animation-controller';
import OEPresentationSlidePopover from './oe-presentation-slide-popover';
import OEPresentationAttributePopover from './attribute/oe-presentation-attribute-controller';
import OEPresetPopoverController from '../../controller/oe-preset-controller';
import OEAudioTrackPopoverController from '../../controller/oe-audio-track-controller';
import OELanguagePickerPopover from '../../elements/oe-language-picker';
import OEAddPresentationPopover from './oe-add-presentation-popover';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import {retardUpdate} from '../../../lib/update-retarder';

export class OEPresentationController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.activePresID = -2;
        this.activeSlideID = -1;

        this.isViewerLevel = false;
        this.isProLevel = false;

        this.state = {
            uiEnabled: false,
            activePresID: -2,
            activeSlideID: -1,
            strings: {
                title: 'Presentation Tool',
                presentationSection: 'Presentations',
                slideSection: 'Slides',
                presentationFileName: '',
                presentationFileNameWithExt: ''
            },
            buttons: {
                addPres: { enabled: false },
                removePres: { enabled: false },
                addSlide: { enabled: false },
                removeSlide: { enabled: false },
                set: { enabled: false },
                slide: { enabled: false },
                slideAttribute : { enabled: false },
                preset: {enabled: false},
                load: { enabled: false },
                save: { enabled: false },
                audio: { enabled: false },
                presLang: { enabled: false }
            },
            presLang: OELanguageType.unset,
            isViewerLevel: this.isViewerLevel,
            isProLevel: this.isProLevel,
            presentationFileNameTextField: {
                hasFocus: false
            }
        };

        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onPresentationAnimationModeChanged = this.onPresentationAnimationModeChanged.bind(this);
        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
        this.onActivePresentationSlideChanged = this.onActivePresentationSlideChanged.bind(this);
        this.onPresentationDataChanged = this.onPresentationDataChanged.bind(this);
        this.onPresentationAdded = this.onPresentationAdded.bind(this);
        this.onPresentationSlideAdded = this.onPresentationSlideAdded.bind(this);
        this.onFeatureAccessChanged = this.onFeatureAccessChanged.bind(this);

        this.onAudioTrackPopoverRef = this.onAudioTrackPopoverRef.bind(this);
        this.onPresLanguagePickerPopoverRef = this.onPresLanguagePickerPopoverRef.bind(this);
        this.onSlidePopoverRef = this.onSlidePopoverRef.bind(this);
        this.onFileLoadInputRef = this.onFileLoadInputRef.bind(this);
        this.onSlideAttributeControllerRef = this.onSlideAttributeControllerRef.bind(this);
        this.onPresetControllerRef = this.onPresetControllerRef.bind(this);
        this.onAddPresentationPopoverRef = this.onAddPresentationPopoverRef.bind(this);

        this.onPresentationFileNameTextFieldChanged = this.onPresentationFileNameTextFieldChanged.bind(this);
        this.onPresentationFileNameTextFieldFocus = this.onPresentationFileNameTextFieldFocus.bind(this);
        this.onPresentationFileNameTextFieldBlur = this.onPresentationFileNameTextFieldBlur.bind(this);
        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onLoadBtnPressed = this.onLoadBtnPressed.bind(this);
        this.onSaveBtnPressed = this.onSaveBtnPressed.bind(this);
        this.onRemovePresBtnPressed = this.onRemovePresBtnPressed.bind(this);
        this.onAddPresBtnPressed = this.onAddPresBtnPressed.bind(this);
        this.onAddPresBtnLongPressed = this.onAddPresBtnLongPressed.bind(this);
        this.onAudioBtnPressed = this.onAudioBtnPressed.bind(this);
        this.onPresLangBtnPressed = this.onPresLangBtnPressed.bind(this);
        this.onSlideBtnPressed = this.onSlideBtnPressed.bind(this);
        this.onSlideAttributeBtnPressed = this.onSlideAttributeBtnPressed.bind(this);
        this.onPresetBtnPressed = this.onPresetBtnPressed.bind(this);
        this.onSetBtnPressed = this.onSetBtnPressed.bind(this);
        this.onRemoveSlideBtnPressed = this.onRemoveSlideBtnPressed.bind(this);
        this.onAddSlideBtnPressed = this.onAddSlideBtnPressed.bind(this);
        this.onAudioTrackPopoverToggle = this.onAudioTrackPopoverToggle.bind(this);
        this.onAudioTrackPopoverCompletion = this.onAudioTrackPopoverCompletion.bind(this);
        this.onPresLanguageChange = this.onPresLanguageChange.bind(this);
        this.onLoadInputResult = this.onLoadInputResult.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAnimationModeChanged, this.onPresentationAnimationModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationSlideChanged, this.onActivePresentationSlideChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationDataChanged, this.onPresentationDataChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAnimationModeChanged, this.onPresentationAnimationModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationSlideChanged, this.onActivePresentationSlideChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationDataChanged, this.onPresentationDataChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.featureAccessChanged, this.onFeatureAccessChanged);
    }

    onLanguageChanged()   {
        let si = this.oe.sharedInterface;
        this.setState({
            strings: {
                title: si.getLocalizedStringEnc('presentation_view'),
                presentationSection: si.getLocalizedStringEnc('presentation_view_pres_section'),
                slideSection: si.getLocalizedStringEnc('presentation_view_slide_section'),
                presentationFileName: this.getPresentationFilename(),
                presentationFileNameWithExt: this.getPresentationFilename(true, true),
            }
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type === this.oe.Module.UIControllerType.presentation || userInfo.type === this.oe.Module.UIControllerType.preset) {
            this.updateUIState();
        }
    }

    onPresentationAnimationModeChanged()    {
        this.updateBtnState();
    }

    onActivePresentationChanged(message, userInfo)   {
        let activePresID = this.oe.sharedInterface.getUIControllerPresentation().getActivePresentationID();

        if(activePresID !== this.activePresID)  {
            this.activePresID = activePresID;
            this.setState({activePresID: this.activePresID});

            // enable animation if a actual presentation is set
            this.oe.sharedInterface.getUIControllerPresentation().stopAnimation();
        
            this.onActivePresentationSlideChanged();

            this.closeAudioTrackPopover();
        }

        if(this.activePresID >= 0)  {
            let presData = this.oe.sharedInterface.getUIControllerPresentation().getPresentationDataID(this.activePresID);
            this.setState({presLang: presData.language.value});
        } else {
            this.setState({presLang: OELanguageType.unset});
        }

        this.onLanguageChanged();
    }

    onActivePresentationSlideChanged(message, userInfo)  {
        this.activeSlideID = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerPresentation().getActiveSlideID() : userInfo.slideID;
        this.setState({activeSlideID: this.activeSlideID});

        this.updateBtnState();
    }

    onPresentationDataChanged(message, userInfo)  {
        if(this.activePresID != userInfo.presID)    return;
        this.onLanguageChanged();
        this.setState({presLang: userInfo.presData.language.value});
    }

    onPresentationAdded(message, userInfo)  {
        this.oe.sharedInterface.getUIControllerPresentation().setActivePresentationID(userInfo.presID);
    }

    onPresentationSlideAdded(message, userInfo)  {
        if(this.activePresID === userInfo.presID)   {
            this.oe.sharedInterface.getUIControllerPresentation().setActiveSlideID(userInfo.slideID);
        }
    }

    onFeatureAccessChanged()    {
        let featureSet = this.oe.sharedInterface.getUIControllerFeatureAccess().getFeatureSet();
        this.isViewerLevel = featureSet.hasFeature(this.oe.Module.Feature.play_pres);
        this.isProLevel = featureSet.hasFeature(this.oe.Module.Feature.edit_pres);
        featureSet.delete();

        this.setState({
            isViewerLevel: this.isViewerLevel,
            isProLevel: this.isProLevel,
        });

        this.updateBtnState();
    }

    updateUIState()   {
        this.setState({ uiEnabled: this.oe.sharedInterface.getUIControllerEnabled(this.oe.Module.UIControllerType.presentation) });
        this.updateBtnState();
    }

    updateBtnState(reset)    {
        let uiEnabled = false;
        let uiPresetEnabled = false;
        let isPlaying = false;
        let hasActivePresentation = false;
        let hasActiveSlide = false;
        let isProLevel = true, isViewerLevel = true;

        if(reset !== true)  {
            let presController = this.oe.sharedInterface.getUIControllerPresentation();
            uiEnabled = presController.getUIEnabled();
            uiPresetEnabled = this.oe.sharedInterface.getUIControllerEnabled(this.oe.Module.UIControllerType.preset);
            isPlaying = presController.getAnimationMode() === this.oe.Module.AnimationMode.play;
            
            hasActivePresentation = this.activePresID >= 0;
            hasActiveSlide = hasActivePresentation && this.activeSlideID >= 0;
        
            isProLevel = this.isProLevel, isViewerLevel = this.isViewerLevel;
        }

        let uiEnabledNotPlaying = uiEnabled && !isPlaying;
        let uiEnabledNotPlayingPro = uiEnabledNotPlaying && isProLevel;

        let buttons = {
            addPres: { enabled: uiEnabledNotPlayingPro },
            removePres: { enabled: uiEnabledNotPlayingPro && hasActivePresentation },
            addSlide: { enabled: uiEnabledNotPlayingPro && hasActivePresentation },
            removeSlide: { enabled: uiEnabledNotPlayingPro && hasActiveSlide },
            set: { enabled: uiEnabledNotPlayingPro && hasActiveSlide },
            preset: { enabled: uiPresetEnabled && !isPlaying && isProLevel},
            slide: { enabled: uiEnabledNotPlayingPro && hasActiveSlide },
            slideAttribute: { enabled: uiEnabledNotPlayingPro && hasActiveSlide },
            load: { enabled: uiEnabledNotPlaying && isViewerLevel },
            save: { enabled: uiEnabledNotPlayingPro && hasActivePresentation },
            audio: { enabled: uiEnabledNotPlayingPro && hasActivePresentation },
            presLang: { enabled: uiEnabledNotPlayingPro && hasActivePresentation }
        };

        this.setState({ buttons: buttons });

        this.closeViewsForBtns(buttons);
    }

    closeAudioTrackPopover()    {
        if(this.oe.isReady())   this.oe.sharedInterface.getUIControllerAudioPlayer().clearAudioData();
        if(this.audioTrackPopoverRef)    this.audioTrackPopoverRef.close();
    }

    closeViewsForBtns(buttons, excluded)  {
        if(this.slidePopoverRef && !buttons.slide.enabled && this.slidePopoverRef !== excluded)    this.slidePopoverRef.close();
        if(this.slideAttributeControllerRef && !buttons.slideAttribute.enabled && this.slideAttributeControllerRef !== excluded)    this.slideAttributeControllerRef.close();
        if(this.presetControllerRef && !buttons.preset.enabled && this.presetControllerRef !== excluded)    this.presetControllerRef.close();
        if(!buttons.audio.enabled && this.audioTrackPopoverRef !== excluded)    this.closeAudioTrackPopover();
        if(this.presLanguagePickerPopoverRef && !buttons.presLang.enabled && this.presLanguagePickerPopoverRef !== excluded)    this.presLanguagePickerPopoverRef.close();
        if(this.addPresentationPopoverRef && !buttons.addPres.enabled && this.addPresentationPopoverRef !== excluded)    this.addPresentationPopoverRef.close();
    }

    closeViews(excluded)    {
        if(this.slidePopoverRef && this.slidePopoverRef !== excluded)   this.slidePopoverRef.close();
        if(this.slideAttributeControllerRef && this.slideAttributeControllerRef !== excluded)    this.slideAttributeControllerRef.close();
        if(this.presetControllerRef && this.presetControllerRef !== excluded)   this.presetControllerRef.close();
        if(this.audioTrackPopoverRef !== excluded)   this.closeAudioTrackPopover();
        if(this.presLanguagePickerPopoverRef !== excluded)   this.presLanguagePickerPopoverRef.close();
        if(this.addPresentationPopoverRef !== excluded)   this.addPresentationPopoverRef.close();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            
            this.activePresID = -2;
            this.activeSlideID = -1;

            this.isViewerLevel = false;
            this.isProLevel = false;

            this.setState({
                uiEnabled: false,
                activePresID: -2,
                activeSlideID: -1,
                presLang: OELanguageType.unset,
                isViewerLevel: this.isViewerLevel,
                isProLevel: this.isProLevel,
            });

            this.updateBtnState(true);
            return;
        }

        retardUpdate(this, () => {
            this.onLanguageChanged();
            this.onActivePresentationChanged();
            this.onFeatureAccessChanged();
            this.updateUIState();
        });
    }

    onAudioTrackPopoverRef(ref) {
        this.audioTrackPopoverRef = ref;
    }

    onPresLanguagePickerPopoverRef(ref)   {
        this.presLanguagePickerPopoverRef = ref;
    }

    onSlidePopoverRef(ref)   {
        this.slidePopoverRef = ref;
    }

    onFileLoadInputRef(ref)    {
        this.fileLoadInput = ref;
    }

    onSlideAttributeControllerRef(ref)  {
        this.slideAttributeControllerRef = ref;
    }

    onPresetControllerRef(ref)  {
        this.presetControllerRef = ref;
    }

    onAddPresentationPopoverRef(ref)    {
        this.addPresentationPopoverRef = ref;
    }

    render() {
        if(!this.props.config.enabled) return null;
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover
                    className="popover-control"
                    moduleId={this.props.moduleId}
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    uiControllerType={UIControllerType.presentation}
                    headerSeparator={true}
                    title={this.state.strings.title}
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >

                    <input
                        id="presFileLoad"
                        type="file"
                        accept=".xbin"
                        ref={this.onFileLoadInputRef}
                        style={{display: 'none'}}
                        onChange={this.onLoadInputResult}
                    />

                    <div className="presentation-controller">

                        <OEGroupControl className="presentation" title={this.state.strings.presentationSection} separator={true} popoverTargetId="presentation-controller-presentation-control-popover-target">
                            <OEControl className="presentation-name d-flex justify-content-end align-items-center">
                                <OETextField 
                                    className={'presentation-name-input std-label-border-color flex-grow-1'}
                                    disabled={this.state.activePresID < 0 || !this.state.isProLevel}
                                    value={this.state.presentationFileNameTextField.hasFocus ? this.state.strings.presentationFileName : this.state.strings.presentationFileNameWithExt}
                                    clearButton={false}
                                    onChange={this.onPresentationFileNameTextFieldChanged}
                                    onFocus={this.onPresentationFileNameTextFieldFocus}
                                    onBlur={this.onPresentationFileNameTextFieldBlur}
                                />

                                <OEIconButton className="transparent-btn save-btn" disabled={!this.state.buttons.save.enabled} onPressed={this.onSaveBtnPressed} icon={OEIconCodes.pres.save}/>
                            </OEControl>

                            <OEControl className="presentations">
                                <OEPresentationList className="view-bg dark-border" moduleId={this.props.moduleId}/>
                            </OEControl>
                            
                            <OEControl className="presentation-button-bar two-row-button-bar">

                                <div className="row-one d-flex justify-content-between align-items-center">
                                    <div className="left">
                                        <OEIconButton className="transparent-btn" disabled={!this.state.buttons.removePres.enabled} onPressed={this.onRemovePresBtnPressed} icon={OEIconCodes.pres.remove}/>
                                    </div>
                                    <div className="center p-absolute-center">
                                        <OEIconButton className="transparent-btn" disabled={!this.state.buttons.load.enabled} onPressed={this.onLoadBtnPressed} icon={OEIconCodes.pres.load}/>
                                    </div>
                                    <div className="right">
                                        <OEIconButton className="transparent-btn" disabled={!this.state.buttons.addPres.enabled} onPressed={this.onAddPresBtnPressed} onLongPressed={this.props.devFeatures ? this.onAddPresBtnLongPressed : null} icon={OEIconCodes.pres.add}/>
                                    </div>
                                </div>

                                <div className="std-separator-border-color separator"/>

                                <div className="row-two d-flex justify-content-between align-items-center">
                                    <div className="left">
                                        <OEIconButton
                                            className={'full-transparent-btn'}
                                            disabled={!this.state.buttons.presLang.enabled}
                                            onPressed={this.onPresLangBtnPressed}
                                            icon={OEIconCodes.pres.language}
                                        />
                                        <OEIconButton
                                            className={'full-transparent-btn'}
                                            disabled={!this.state.buttons.audio.enabled}
                                            onPressed={this.onAudioBtnPressed}
                                            icon={OEIconCodes.pres.audio}
                                        />
                                    </div>

                                    <div className="center p-absolute-center">
                                    </div>

                                    <div className="right">
                                    </div>
                                </div>

                            </OEControl>
                        </OEGroupControl>

                        <OEGroupControl className="slides" title={this.state.strings.slideSection} separator={true} popoverTargetId="presentation-controller-slide-control-popover-target">
                            <OEControl>
                                <OEPresentationSlideList className="view-bg dark-border" moduleId={this.props.moduleId} presID={this.state.activePresID} showIndices={this.props.devFeatures}/>
                            </OEControl>
                            
                            <OEControl className="slide-button-bar two-row-button-bar">

                                <div className="row-one d-flex justify-content-between align-items-center">
                                    <div className="left">
                                        <OEIconButton className="transparent-btn" disabled={!this.state.buttons.removeSlide.enabled} onPressed={this.onRemoveSlideBtnPressed} icon={OEIconCodes.pres.remove}/>
                                    </div>
                                    <div className="center p-absolute-center">
                                        <OEIconButton className="transparent-btn" disabled={!this.state.buttons.set.enabled} onPressed={this.onSetBtnPressed} icon={OEIconCodes.pres.set}/>
                                    </div>
                                    <div className="right">
                                        <OEIconButton className="transparent-btn" disabled={!this.state.buttons.addSlide.enabled} onPressed={this.onAddSlideBtnPressed} icon={OEIconCodes.pres.add}/>
                                    </div>
                                </div>

                                <div className="std-separator-border-color separator"/>

                                <div className="row-two d-flex justify-content-between align-items-center">
                                    <div className="left">
                                        <OEIconButton
                                            className={'full-transparent-btn' + (this.props.devFeatures ? '' : ' d-none')}
                                            disabled={!this.state.buttons.preset.enabled}
                                            onPressed={this.onPresetBtnPressed}
                                            icon={OEIconCodes.pres.preset}
                                        />
                                        <OEIconButton
                                            className={'full-transparent-btn'}
                                            disabled={!this.state.buttons.slide.enabled}
                                            onPressed={this.onSlideBtnPressed}
                                            icon={OEIconCodes.pres.slide}
                                        />
                                        <OEIconButton
                                            className={'full-transparent-btn' + (this.props.devFeatures ? '' : ' d-none')}
                                            disabled={!this.state.buttons.slideAttribute.enabled}
                                            onPressed={this.onSlideAttributeBtnPressed}
                                            icon={OEIconCodes.pres.slideAnimationAttribute}
                                        />
                                    </div>
                                    <div className="center p-absolute-center">
                                    </div>
                                    <div className="right">
                                    </div>
                                </div>
                            </OEControl>
                        </OEGroupControl>

                        <OEGroupControl>
                            <OEControl>
                                <OEPresentationAnimationController moduleId={this.props.moduleId} />
                            </OEControl>
                        </OEGroupControl>
                    
                    </div>

                    <OEAudioTrackPopoverController
                        ref={this.onAudioTrackPopoverRef}
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        target="presentation-controller-presentation-control-popover-target"
                        placement="right-start"
                        hideArrow={true}
                        onToggle={this.onAudioTrackPopoverToggle}
                        icon={OEIconCodes.pres.audio}
                        backdrop={false}
                        onCompletion={this.onAudioTrackPopoverCompletion}
                    />

                    <OELanguagePickerPopover
                        ref={this.onPresLanguagePickerPopoverRef}
                        moduleId={this.props.moduleId}
                        target="presentation-controller-presentation-control-popover-target"
                        placement="right-start"
                        hideArrow={true}
                        icon={OEIconCodes.pres.language}
                        titleId="pres_lang_btn_view"
                        backdrop={false}
                        disabled={!this.state.buttons.presLang.enabled}
                        onlyAvailable={!this.props.devFeatures}
                        allowUnsetLanguage={true}
                        language={this.state.presLang}
                        onChange={this.onPresLanguageChange}
                    />
                    
                    <OEPresentationSlidePopover
                        ref={this.onSlidePopoverRef}
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        target="presentation-controller-slide-control-popover-target"
                        ids={{pres: this.state.activePresID, slide: this.state.activeSlideID}}
                        devFeatures={this.props.devFeatures}
                        placement="right-start"
                        hideArrow={true}
                        icon={OEIconCodes.pres.slide}
                        backdrop={false}
                    />

                    <OEPresentationAttributePopover
                        ref={this.onSlideAttributeControllerRef}
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        target="presentation-controller-slide-control-popover-target"
                        ids={{pres: this.state.activePresID, slide: this.state.activeSlideID}}
                        devFeatures={this.props.devFeatures}
                        showAttributeIcons={false}
                        placement="right-start"
                        hideArrow={true}
                        icon={OEIconCodes.pres.slideAnimationAttribute}
                        titleId="presentation_animation_attribute_view"
                        backdrop={false}
                    />

                    <OEPresetPopoverController
                        ref={this.onPresetControllerRef}
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        target="presentation-controller-slide-control-popover-target"
                        placement="right-start"
                        hideArrow={true}
                        icon={OEIconCodes.pres.preset}
                        titleId="presentation_preset_view"
                        backdrop={false}
                        presetType={[OEPresetType.cut, OEPresetType.color, OEPresetType.camera]}
                    />

                    <OEAddPresentationPopover
                        ref={this.onAddPresentationPopoverRef}
                        moduleId={this.props.moduleId}
                        appComponent={this.props.appComponent}
                        target="presentation-controller-presentation-control-popover-target"
                        placement="right-start"
                        hideArrow={true}
                        backdrop={false}
                    />

                </OEPopover>
            </React.Fragment>
        );
    }

    onPresentationFileNameTextFieldChanged(sender, value)   {
        if(!this.oe.isReady() || this.activePresID < 0)  return;
        this.oe.sharedInterface.getUIControllerPresentation().setPresentationNameID(this.activePresID, value, null);
    }

    onPresentationFileNameTextFieldFocus(sender)  {
        this.setState({presentationFileNameTextField: {hasFocus: true}});
    }

    onPresentationFileNameTextFieldBlur(sender)  {
        this.setState({presentationFileNameTextField: {hasFocus: false}});
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.presentation});
    }

    onLoadBtnPressed()  {
        if(!this.fileLoadInput) return;
        this.fileLoadInput.value = null; // empties the file list so that onLoadInputResult gets called when loading the same file multiple times successively
        this.fileLoadInput.click();
    }

    onSaveBtnPressed()  {
        this.props.appComponent.showWaitingController();

        this.savePresentation(function(result)  {
            this.props.appComponent.hideWaitingController();

            if(result !== this.oe.Module.PresentationSerializerResult.ok)   {
                console.log("Saving presentation failed with error - " + result.constructor.name);
            }

        }.bind(this));
    }

    onRemovePresBtnPressed()    {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerPresentation().removePresentation();
    }

    onAddPresBtnPressed()  {
        if(this.addPresentationPopoverRef.isOpen()) {
            //this.addPresentationPopoverRef.close();
            return;
        }

        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerPresentation().addNewPresentation(true);
    }

    onAddPresBtnLongPressed()  {
        if(!this.oe.isReady() || !this.props.devFeatures)  return;

        this.addPresentationPopoverRef.open();
        this.closeViews(this.addPresentationPopoverRef);
    }

    onAudioBtnPressed() {
        if(this.activePresID < 0 || !this.oe.isReady() || !this.audioTrackPopoverRef)  return;

        let track = this.oe.sharedInterface.getUIControllerPresentation().getAudioTrackID(this.activePresID);
        this.oe.sharedInterface.getUIControllerAudioPlayer().setAudioData(track);

        this.audioTrackPopoverRef.open();
        this.closeViews(this.audioTrackPopoverRef);
    }

    onPresLangBtnPressed()  {
        if(this.activePresID < 0 || !this.oe.isReady() || !this.presLanguagePickerPopoverRef)  return;

        this.presLanguagePickerPopoverRef.open();
        this.closeViews(this.presLanguagePickerPopoverRef);
    }

    onSlideBtnPressed() {
        if(this.slidePopoverRef)    this.slidePopoverRef.open();
        this.closeViews(this.slidePopoverRef);
    }

    onSlideAttributeBtnPressed()    {
        if(this.slideAttributeControllerRef)    this.slideAttributeControllerRef.open();
        this.closeViews(this.slideAttributeControllerRef);
    }

    onPresetBtnPressed()    {
        if(this.presetControllerRef)    this.presetControllerRef.open();
        this.closeViews(this.presetControllerRef);
    }

    onSetBtnPressed()   {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerPresentation().set();
    }

    onRemoveSlideBtnPressed()   {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerPresentation().removeSlide();
    }

    onAddSlideBtnPressed()  {
        if(!this.oe.isReady())  return;
        //this.oe.sharedInterface.getUIControllerPresentation().addSlide();
        this.oe.sharedInterface.getUIControllerPresentation().insertSlideEx(this.props.devFeatures ? this.oe.Module.PresentationAddSlideFlags.adopt_attributes.value : 0);
    }

    onAudioTrackPopoverToggle() {
        this.closeAudioTrackPopover();
    }

    onAudioTrackPopoverCompletion() {
        if(this.oe.isReady() && this.activePresID >= 0) {
            let track = this.oe.sharedInterface.getUIControllerAudioPlayer().getAudioData();
            this.oe.sharedInterface.getUIControllerPresentation().setAudioTrackID(this.activePresID, track);
        }
        this.closeAudioTrackPopover();
    }

    onPresLanguageChange(language)  {
        if(!this.oe.isReady() || this.activePresID < 0)  return;
        let pres = this.oe.sharedInterface.getUIControllerPresentation();
        pres.setPresentationLanguageID(this.activePresID, {value: language});
    }

    onLoadInputResult(event)  {
        event.stopPropagation();
        event.preventDefault();

        if(event.target.files.length == 0)  return;

        this.props.appComponent.showWaitingController();

        let allFileProgress = {i: 0, num: event.target.files.length};

        let onLoadFunc = function(event)  {
            if(event.target.readyState != FileReader.DONE)  return;

            if(!this.oe.isReady())    {
                allFileProgress.i = allFileProgress.i + 1;
                if(allFileProgress.i == allFileProgress.num)    {
                    this.props.appComponent.hideWaitingController();
                }
                return;
            }

            let arrayBuffer = event.target.result;
            let res = this.oe.sharedInterface.getUIControllerPresentation().addPresentationFromBuffer(arrayBuffer);

            if(res.result !== this.oe.Module.PresentationSerializerResult.ok)   {
                console.log('Loading presentation failed with error - ' + res.result.constructor.name);
            }

            allFileProgress.i = allFileProgress.i + 1;
            if(allFileProgress.i == allFileProgress.num)    {
                this.props.appComponent.hideWaitingController();
            }

         }.bind(this);

        for(let i = 0; i < event.target.files.length; ++i)  {
            let reader = new FileReader();
            reader.onload = onLoadFunc;
            reader.readAsArrayBuffer(event.target.files[i]);
        }
    }

    getPresentationFilename(withExt, withFallback)   {
        if(!this.oe.isReady())  return '';
        let presController = this.oe.sharedInterface.getUIControllerPresentation();
        let fallback = this.oe.sharedInterface.getLocalizedStringEnc('presentation_filename');

        let activePresentation = presController.getActivePresentation();
        if(activePresentation < 0)    return "";    //fallback;

        let presData = presController.getPresentationData(activePresentation);
        let name = presData.name.get();
        name = name || !withFallback ? name : fallback;

        return withExt ? name + '.xbin' : name;
    }

    savePresentation(callback)  {
        let resultType = this.oe.Module.PresentationSerializerResult;

        if(!this.oe.isReady())  {
            callback(resultType.unexpected);
            return;
        }

        let presController = this.oe.sharedInterface.getUIControllerPresentation();

        let activePresentation = presController.getActivePresentation();
        if(activePresentation < 0)    {
            callback(resultType.unexpected);
            return;
        }

        let result = presController.savePresentation();

        if(result.result !== resultType.ok)    {
            callback(result.result);
            return;
        }

        let presData = presController.getPresentationData(activePresentation);
        let name = presData.name.get() ? presData.name.get() : this.oe.sharedInterface.getLocalizedStringEnc('presentation_filename');

        FileSaver.saveAs(new Blob([new Uint8Array(result.buffer)]), name + '.xbin');
        callback(resultType.ok);
    }
}

OEPresentationController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.presentationController(),
    devFeatures: false
};

OEPresentationController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired,
    devFeatures: PropTypes.bool
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        devFeatures: env.config.module.flags && env.config.module.flags.includes('import'),
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.presentationController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.presentation)
    }
})(OEPresentationController);