import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import OELicensingTitleBar from './oe-licensing-title-bar';
import OELicensingProductCollection from './oe-licensing-product-collection';
import OELicensingFooter from './oe-licensing-footer';
import {slInterface} from '../../react-oe/sl-interface';
import {OEToolbox} from '../../lib/oe-toolbox';
import {OEModalContainer} from '../modals/oe-modal';
import OEThemeWaitingController from '../oe-theme-waiting-controller';
import {OEProductId} from '../../react-oe/oe-product-id';
import {OEStatus} from '../../react-oe/oe-surface';
import OEStatusDlgHandler from '../modals/oe-status-dlg-handler';
import OEIcon from '../elements/oe-icon';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {retardUpdate} from '../../lib/update-retarder';

export const OELicensingAppConfigFactory = {
    defaultConfig: function()   {
        return {
            availableProducts: [ 
                OEProductId.humanSkull, OEProductId.humanEye, OEProductId.humanEar, OEProductId.humanSkin, OEProductId.humanKnee, OEProductId.humanFoot,
                OEProductId.snail/*, OEProductId.equineHoof*/
            ],
    
            availableProductsInShop: [
                OEProductId.humanEye, OEProductId.humanEar, OEProductId.humanSkin, OEProductId.humanKnee
            ]
        };
    }
};

export default class OELicensingApp extends React.PureComponent {

    constructor(props) {
        super(props);

        this.restored = false;

        this.state = {
            newKey: '',
            strings: {
                keyInputHead: 'Please insert licensing key here:',
                keyInputPlaceholder: 'Licensing key',
                keyInputBtn: 'Activate',
                productCollection: {
                    start: 'Start',
                    buy: 'Buy license',
                    demo: 'Demo',
                    contactUs: 'Contact us'
                }
            },
            onlineProducts: [],
            offlineProducts: []
        };

        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onConnect = this.onConnect.bind(this);

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onLicensingServiceRestore = this.onLicensingServiceRestore.bind(this);
        this.onLicensingServiceProductActivationChanged = this.onLicensingServiceProductActivationChanged.bind(this);
        this.onLicensingServiceLicenseRemoved = this.onLicensingServiceLicenseRemoved.bind(this);
    
        this.onModalContainerRef = this.onModalContainerRef.bind(this);

        this.onNewKeyChanged = this.onNewKeyChanged.bind(this);
        this.onNewValidate = this.onNewValidate.bind(this);
    }

    componentDidMount()    {
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
    }

    componentDidUpdate(prevProps) {
        if(this.props.config !== prevProps.config) {
            this.updateState();
        }
    }
  
    connect() {
        if(slInterface.isPreReady())   {
            if(slInterface.Module.envWarningStatus) {
                this.statusChanged(new OEStatus(slInterface.Module.envWarningStatus));
                slInterface.Module.envWarningStatus = null;
            }
            this.statusChanged(new OEStatus(slInterface.Module.status));
            slInterface.sharedNotificationCenter.register(slInterface.NotificationName.statusChanged, this.onStatusChanged);
        }

        slInterface.register(this.onConnect);
        if(slInterface.isReady() && slInterface.isOnConnectCalled())   this.onConnect();
    }

    release()   {

        if(slInterface.isPreReady())   {
            slInterface.sharedNotificationCenter.unregister(slInterface.NotificationName.statusChanged, this.onStatusChanged);
        }

        slInterface.unregister(this.onConnect);
        if(slInterface.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onStatusChanged(message, userInfo) {
        this.statusChanged(new OEStatus(userInfo.status));
    }

    statusChanged(status)   {
        this.statusHandler = this.statusHandler || new OEStatusDlgHandler();
        this.statusHandler.onStatusChanged(status, this.modalContainer);
    }

    onConnect()  {
        this.onLicensingServiceRestore();
        slInterface.sharedNotificationCenter.register(slInterface.NotificationName.languageChanged, this.updateLanguage);
        slInterface.sharedNotificationCenter.register(slInterface.NotificationName.licensingServiceRestore, this.onLicensingServiceRestore);
        slInterface.sharedNotificationCenter.register(slInterface.NotificationName.licensingServiceProductActivationChanged, this.onLicensingServiceProductActivationChanged);
        slInterface.sharedNotificationCenter.register(slInterface.NotificationName.licensingServiceLicenseRemoved, this.onLicensingServiceLicenseRemoved);
    }

    onRelease()    {
        this.updateState(true);
        slInterface.sharedNotificationCenter.unregister(slInterface.NotificationName.languageChanged, this.updateLanguage);
        slInterface.sharedNotificationCenter.unregister(slInterface.NotificationName.licensingServiceRestore, this.onLicensingServiceRestore);
        slInterface.sharedNotificationCenter.unregister(slInterface.NotificationName.licensingServiceProductActivationChanged, this.onLicensingServiceProductActivationChanged);
        slInterface.sharedNotificationCenter.unregister(slInterface.NotificationName.licensingServiceLicenseRemoved, this.onLicensingServiceLicenseRemoved);
    }

    updateState_(released)   {
        if(!slInterface.isReady() || released === true) {
            return;    
        }

        if(!this.restored )   {
            this.updateLanguage();
            return;
        }

        let licensingService = slInterface.sharedInterface.getLicensingService();
        let licensingManager = slInterface.sharedInterface.getLicensingManager();

        let config = this.props.config;
        let productIds = config.availableProducts;
        let productIdsInShop = config.availableProductsInShop;

        let licenses = licensingService.getLicenses();

        let offlineProducts = [];
        let onlineProducts = [];
        let licensedProductIds = [];

        for(let i = 0; i < licenses.size(); i++) {
            let license = licenses.get(i);
            let id = license.getProductId();
            let moduleType = license.getModuleType();

            onlineProducts.push({
                id: id, 
                isInShop: false,
                moduleType: moduleType, 
                name: OEToolbox.decode_utf8(licensingManager.getProductName(id)), 
                moduleName: OEToolbox.decode_utf8(licensingManager.moduleName(moduleType)),
                edition: license.getEdition(),
                expDate: OEToolbox.stdTMToData(license.getExpirationDate()).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'})
            });

            licensedProductIds.push(id);
        }

        for(let i = 0; i < productIds.length; i++) {
            let id = productIds[i];
            if(licensedProductIds.includes(id)) continue;
            let moduleType = slInterface.Module.LicenseModuleType.none;

            offlineProducts.push({
                id: id, 
                isInShop: productIdsInShop.includes(id), 
                moduleType: moduleType, 
                name: OEToolbox.decode_utf8(licensingManager.getProductName(id)), 
                moduleName: OEToolbox.decode_utf8(licensingManager.moduleName(moduleType)),
                edition: '',
                expDate: ''
            });
        }

        this.setState({onlineProducts: onlineProducts, offlineProducts: offlineProducts});

        this.updateLanguage();
    }

    updateState(released)   {
        retardUpdate(this, () => {
            this.updateState_(released)
        });
    }

    updateLanguage()    {
        let si = slInterface.sharedInterface;
        let licensingManager = si.getLicensingManager();

        this.setState((prevState, props) => {
            let newState = clone(prevState);

            // be careful here, moduleType of cloned products has to be overwritten, because it is an object (enum) from emscripten binding and cannot be properly cloned!
            for(var i = 0; i < prevState.onlineProducts.length; i++) {
                var prod = prevState.onlineProducts[i];
                Object.assign(newState.onlineProducts[i], {moduleType: prod.moduleType, name: OEToolbox.decode_utf8(licensingManager.getProductName(prod.id)), moduleName: OEToolbox.decode_utf8(licensingManager.moduleName(prod.moduleType))});
            }

            for(var i = 0; i < newState.offlineProducts.length; i++) {
                var prod = prevState.offlineProducts[i];
                Object.assign(newState.offlineProducts[i], {moduleType: prod.moduleType, name: OEToolbox.decode_utf8(licensingManager.getProductName(prod.id)), moduleName: OEToolbox.decode_utf8(licensingManager.moduleName(prod.moduleType))});
            }

            newState.strings = {
                keyInputHead: si.getLocalizedStringEnc('licensing_start_web_key_input_head'),
                keyInputPlaceholder: si.getLocalizedStringEnc('licensing_start_web_key_input_placeholder'),
                keyInputBtn: si.getLocalizedStringEnc('licensing_start_web_key_input_btn'),
                productCollection: {
                    start: si.getLocalizedStringEnc('licensing_start_web_key_prod_collection_start'),
                    buy: si.getLocalizedStringEnc('licensing_start_web_key_prod_collection_buy'),
                    demo: si.getLocalizedStringEnc('licensing_start_web_key_prod_collection_demo'),
                    contactUs: si.getLocalizedStringEnc('licensing_start_web_key_prod_collection_contact')
                }
            };

            return newState;
        });
    }

    onLicensingServiceRestore() {
        if(this.restored)   {
            this.updateState();
            return;
        }

        let si = slInterface.sharedInterface;
        let licensingService = si.getLicensingService();
        let restoreResult = licensingService.getRestoreResult();
        this.restored = restoreResult.getIsSet();

        if(!this.restored)  {
            return;
        }

        if(restoreResult.getError() !== slInterface.Module.LicensingRestoreResultError.no_error)    {
            let title = si.getLocalizedStringEnc('licensing_alert_restore_failed_title');
            let message = OEToolbox.decode_utf8(result.getLocalizedInfo());
            let buttons = (id, container) => [ {title: si.getLocalizedStringEnc('alert_ok'), onClick: () => {container.closeModal(id)}, className: 'btn-primary'} ];

            this.addModal(title, message, buttons);
        }

        this.updateState();
    }

    onLicensingServiceProductActivationChanged(message, userInfo)    {
        this.updateState();

        let isValid = userInfo.isValid;
        let changeReason = userInfo.changeReason;

        if(isValid || changeReason === slInterface.Module.LicenseActivationChangeReason.deactivated) return;

        let si = slInterface.sharedInterface;
        let strBtnOk = si.getLocalizedStringEnc('alert_ok');

        if(changeReason === slInterface.Module.LicenseActivationChangeReason.sessionExpired)    {
            let title = si.getLocalizedStringEnc('licensing_deactivated');
            let message = si.getLocalizedStringEnc('licensing_message_session_expired');
            let buttons = (id, container) => [ {title: strBtnOk, onClick: () => {container.closeModal(id)}, className: 'btn-primary'} ];
            this.addModal(title, message, buttons);
        } else {
            let title = si.getLocalizedStringEnc('licensing_deactivated');
            let message = si.getLocalizedStringEnc('licensing_message_license_expired');
            let buttons = (id, container) => [ {title: strBtnOk, onClick: () => {container.closeModal(id)}, className: 'btn-primary'} ];
            this.addModal(title, message, buttons);
        }
    }

    onLicensingServiceLicenseRemoved()  {
        this.updateState();
    }

    addModal(title, msg, buttonFunc, options)  {
         this.modalContainer.addModal(title, msg, buttonFunc, options);
    }

    onModalContainerRef(ref)    {
        this.modalContainer = ref;
    }

    render() {

        return (
            <div className="oe-licensing-start-app">
                <OEThemeWaitingController ref={e => this.waitingController = e}/>

                <div className="background"/>
                <div className="container">
                    <OELicensingTitleBar onDeactivateAll={() => this.onDeactivateAll()}/>
                    <hr className="my-4" />
                    <OELicensingProductCollection
                        products={this.state.onlineProducts}
                        onPlayBtnPressed={(index, id) => this.onItemPlayButton(false, index, id)}
                        onBuyBtnPressed={(index, id) => this.onItemBuyButton(false, index, id)}
                        strings={this.state.strings.productCollection}
                    />
                    <hr className="my-4" />
                    <div className="p-4 mb-2 bg-light">
                        <div className="key-input">
                            <h4>{this.state.strings.keyInputHead}</h4>
                            <input
                                className="form-control col-sm-8"
                                type="text" 
                                value={this.state.newKey}
                                placeholder={this.state.strings.keyInputPlaceholder}
                                onChange={this.onNewKeyChanged}
                            />
                            <a  
                                role="button"
                                className={'btn themecolor activate-key-btn' + (this.state.newKey === '' ? ' disabled' : '')}
                                onClick={this.onNewValidate}
                            >
                                <OEIcon code={OEIconCodes.licensingKeyInput}/>{this.state.strings.keyInputBtn}
                            </a>
                        </div>
                    </div>
                    <hr className="my-4" />
                    <OELicensingProductCollection
                        products={this.state.offlineProducts}
                        onPlayBtnPressed={(index, id) => this.onItemPlayButton(false, index, id)}
                        onBuyBtnPressed={(index, id) => this.onItemBuyButton(false, index, id)}
                        strings={this.state.strings.productCollection}
                    />
                    <hr className="my-4" />
                    <OELicensingFooter />

                </div>

                <OEModalContainer ref={this.onModalContainerRef}/>

            </div>
        );
    }

    onItemPlayButton(online, index, id) {
        if(!this.props.onShouldPlayProduct)   return;    

        if(!online) {
            this.props.onShouldPlayProduct(id);
            return;
        }

        if(!slInterface.isReady()) return;
        
        let si = slInterface.sharedInterface;
        let licensingService = si.getLicensingService();

        this.waitingController.show();

        licensingService.validateProduct(id, slInterface.Module.LicenseValidationType.std, function(result) {
            this.waitingController.hide();

            if(result.getError() !== slInterface.Module.LicensingValidationRequestResultError.no_error)  {
                let title = si.getLocalizedStringEnc('licensing_alert_validation_failed_title');
                let message = OEToolbox.decode_utf8(result.getLocalizedInfo());
                let buttons = (id, container) => [ {title: si.getLocalizedStringEnc('alert_ok'), onClick: () => {container.closeModal(id)}, className: 'btn-primary'} ];
                this.addModal(title, message, buttons);
                return;
            }

            this.props.onShouldPlayProduct(id);

        }.bind(this), true);
    }

    onItemBuyButton(online, index, id) {
         window.open(slInterface.sharedInterface.getLocalizedStringEnc('url_for_'+id), '_blank');
    }

    onNewKeyChanged(event) {
        this.setState({newKey: event.target.value.toUpperCase()});
    }

    onNewValidate() {
        if(!slInterface.isReady()) return;

        let si = slInterface.sharedInterface;
        let licensingService = si.getLicensingService();

        this.waitingController.show();
        
        let completion = function(result) {
            
            if(result.getError() !== slInterface.Module.LicensingValidationRequestResultError.no_error)  {
                this.waitingController.hide();

                let title = si.getLocalizedStringEnc('licensing_alert_validation_failed_title');
                let message = OEToolbox.decode_utf8(result.getLocalizedInfo());
                let buttons = (id, container) => [ {title: si.getLocalizedStringEnc('alert_ok'), onClick: () => {container.closeModal(id)}, className: 'btn-primary'} ];
                this.addModal(title, message, buttons);
                return;
            }

            this.setState({newKey: ''});
            
            let id = result.getLicense().getProductId();

            licensingService.validateProduct(id, slInterface.Module.LicenseValidationType.release_session, function(result) {
                this.waitingController.hide();
            }.bind(this), true);

        }.bind(this);

        licensingService.validateBroadcastWithKey(this.state.newKey, slInterface.Module.LicenseValidationType.std, completion, true);
    }

    onDeactivateAll()   {
        if(!slInterface.isReady()) return;

        let si = slInterface.sharedInterface;
        let licensingService = si.getLicensingService();

        this.waitingController.show();

        licensingService.validateBroadcast(slInterface.Module.LicenseValidationType.remove, function(result) {
            this.waitingController.hide();

            // no error handling - the license is completely removed from the local storage in any case
            // since web seassons are short-lived the license will be released contemporarily 

        }.bind(this), true);
    }
}

OELicensingApp.defaultProps = {
    config: OELicensingAppConfigFactory.defaultConfig()
};

OELicensingApp.propTypes = {
    config: PropTypes.shape({
        availableProducts: PropTypes.arrayOf(PropTypes.string),
        availableProductsInShop: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    onShouldPlayProduct: PropTypes.func
};