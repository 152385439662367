import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEPresentationItem from './oe-presentation-item';
import OEScrollbars from '../../oe-scrollbars';
import {OEToolbox} from '../../../lib/oe-toolbox';
import OEResizeObserver from '../../../lib/oe-resize-observer';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEPresentationSlideList extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.shouldScrollToActualNext = false;

        this.isPlayerPreview = this.props.isPlayerPreview;

        this.presID = -2;
        this.slides = [];
        this.activePresID = -2;
        this.activeSlideID = -1;
        this.magnificationID = -1;

        this.state = {
            uiEnabled: false,
            presID: -2,
            slides: [],
            activePresID: -2,
            activeSlideID: -1,
            magnification: null
        };

        this.itemRectRequest = {};

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
        this.onActiveSlideChanged = this.onActiveSlideChanged.bind(this);
        this.onSlideSet = this.onSlideSet.bind(this);
        this.onSlideAdded = this.onSlideAdded.bind(this);
        this.onSlideRemoved = this.onSlideRemoved.bind(this);
        this.onSlideMoved = this.onSlideMoved.bind(this);
        this.onSlideDataChanged = this.onSlideDataChanged.bind(this);
        
        this.onScroll = this.onScroll.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.onItemMouseEnter = this.onItemMouseEnter.bind(this);
        this.onItemMouseLeave = this.onItemMouseLeave.bind(this);

        this.onRef = this.onRef.bind(this);
        this.onElementRef = this.onElementRef.bind(this);
        this.onScrollbarRef = this.onScrollbarRef.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.presID !== this.props.presID)     {
            this.updatePres(nextProps.presID, nextProps);
        }

        if(nextProps.isPlayerPreview !== this.props.isPlayerPreview)     {
            this.isPlayerPreview = nextProps.isPlayerPreview;
            this.updateSlidesInState();
        }

        if(nextProps.magnification !== this.props.magnification)  {
            this.resetMagnification();
        }
    }

    componentDidMount()    {
        this.componentDidUpdate();
    }

    componentDidUpdate()    {
        if(this.shouldScrollToActualNext === true)    {
            this.scrollToActual();
            this.shouldScrollToActualNext = false;
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationSlideChanged, this.onActiveSlideChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideSet, this.onSlideSet);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAdded, this.onSlideAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideRemoved, this.onSlideRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideMoved, this.onSlideMoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideDataChanged, this.onSlideDataChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationSlideChanged, this.onActiveSlideChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideSet, this.onSlideSet);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAdded, this.onSlideAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideRemoved, this.onSlideRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideMoved, this.onSlideMoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideDataChanged, this.onSlideDataChanged);
    }

    updateLanguage()   {
        this.slides.forEach((slide) => { slide.name = slide.data.name.get(); slide.desc = slide.data.description.get(); });
        this.updateSlidesInState();
    }

    updateUIState()   {
        this.setState({ uiEnabled: this.oe.sharedInterface.getUIControllerPresentation().getUIEnabled() });
    }

    updatePres(id, props)  {
        props = props || this.props;
        let id_;

        if(typeof(id) === 'number') {
            id_ = id;
        } else if(typeof(props.presID) === 'number')   {
            id_ = props.presID;
        } else if(this.oe.isReady())    {
            id_ = this.oe.sharedInterface.getUIControllerPresentation().getActivePresentationID();
        }

        if(typeof(id_) === 'undefined' || this.presID === id_) return;

        this.presID = id_;
        this.setState({presID: id_});
        this.updateSlides();
    }

    updateSlides()   {
        this.slides = [];

        if(this.presID < 0 || !this.oe.isReady()) {
            this.setState({ slides: [] });
            return;
        }

        let presController = this.oe.sharedInterface.getUIControllerPresentation();
        let presentation = presController.getPresentationDataID(this.presID);

        if(!presentation)  {
            this.setState({ slides: [] });
            return;
        }

        let slides_ = presentation.slides;
        for(let i = 0; i < slides_.length; i++) {
            const slideID = slides_[i];
            const data = presController.getSlideDataID(this.presID, slideID);
            this.slides.push({id: slideID, data: data, name: data.name.get(), desc: data.description.get()});
        }

        this.updateSlidesInState();
        this.scrollToActualNext();
        this.resetMagnification();
    }

    updateSlidesInState()   {
        if(!this.isPlayerPreview) {
            this.setState({ slides: Array.from(this.slides) });
        } else {
            this.setState({ slides: this.slides.filter(slide => slide.data.showInPlayerPreview) })
        }
        this.updateActiveSlideInState();
    }

    updateActiveSlideInState()  {
        this.setState({activeSlideID: this.nearestSlidePreviewIdFor(this.activeSlideID)});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.presID = -2;
            this.slides = [];
            this.activePresID = -2;
            this.activeSlideID = -1;
            this.magnificationID = -1;
            this.setState({ uiEnabled: false, presID: -2, slides: [], activePresID: -2, activeSlideID: -1, magnification: null });
            return;
        }

        retardUpdate(this, () => {
            this.updatePres();
            this.onActivePresentationChanged();
            this.updateLanguage();
            this.updateUIState();
        });
    }

    resetMagnification()    {
        this.magnificationID = -1;
        this.setState({magnification: null});
    }

    updateMagnification(id) {
        let id_ = typeof(id) === 'number' ? id : this.magnificationID;
        if(!this.oe.isReady() || this.props.magnification <= 1 || typeof(id_) !== 'number' || !this.ref)   {
            this.resetMagnification();
            return;
        }
        
        let itemElement = this['item' + id_.toString() + 'Element'];
        let slide = this.slides.find((slide) => slide.id === id_);
        if(!itemElement || !slide)    {
            this.resetMagnification();
            return;
        }

        const scale = this.props.magnification;

        const rect = this.ref.getBoundingClientRect();
        const slideRect = itemElement.getBoundingClientRect();
        const width =  Math.min(scale * (slideRect.right - slideRect.left), rect.right - rect.left);
        const height = scale * (slideRect.bottom - slideRect.top);
        const y = slideRect.top - rect.top - (height + 12);
        const x = Math.min(Math.max(slideRect.left - rect.left - 0.25 * width, 0), rect.right - rect.left - width);

        this.setState({magnification: {
            rect: {x: x, y: y, w: width, h: height},
            img: null
        }});

        this.magnificationID = id_;

        this.updateMagnificationImage();
    }

    updateMagnificationImage() {
        if(this.magnificationID < 0) return;

        this.oe.sharedInterface.getUIControllerPresentation().getThumbnailID(this.presID, this.magnificationID, (thumb, presID, slideID) => {
            if(this.presID !== presID || this.magnificationID !== slideID)   return;
            this.setStateUpdate({ magnification: { img: {$set: thumb} } });
        });
    }

    onUIControllerStateChanged(message, userInfo)    {
        if(userInfo.type !== this.oe.Module.UIControllerType.presentation)  return;
        this.updateUIState();
    }

    onActivePresentationChanged(message, userInfo)   {
        let activePresID = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerPresentation().getActivePresentationID() : userInfo.presID;

        if(typeof(this.props.presID) !== 'number' && this.presID !== activePresID)  {
            this.updatePres(activePresID);
        }

        if(activePresID !== this.activePresID)   {
            this.activePresID = activePresID;
            this.setState({activePresID: activePresID});
            this.props.onActivePresentationChanged(activePresID);
            this.onActiveSlideChanged();
            this.resetMagnification();
            this.scrollToActualNext();
        }
    }

    onActiveSlideChanged(message, userInfo)   {
        let activeSlideID = typeof(userInfo) === 'undefined' ? this.oe.sharedInterface.getUIControllerPresentation().getActiveSlideID() : userInfo.slideID;

        if(activeSlideID !== this.activeSlideID)   {
            this.activeSlideID = activeSlideID;
            this.updateActiveSlideInState();
            this.props.onActiveSlideChanged(activeSlideID);
            this.scrollToActualNext();
        }
    }

    onSlideSet(message, userInfo)   {
        if(this.presID !== userInfo.presID) return;

        let presController = this.oe.sharedInterface.getUIControllerPresentation();
        let data = presController.getSlideDataID(this.presID, userInfo.slideID);
        this.slides.splice(userInfo.slide, 1, {id: userInfo.slideID, data: data, name: data.name.get(), desc: data.description.get()});
        this.updateSlidesInState();

        this.resetMagnification();
    }

    onSlideAdded(message, userInfo)  {
        if(this.presID !== userInfo.presID) return;

        let presController = this.oe.sharedInterface.getUIControllerPresentation();
        let data = presController.getSlideDataID(this.presID, userInfo.slideID);
        this.slides.splice(userInfo.slide, 0, {id: userInfo.slideID, data: data, name: data.name.get(), desc: data.description.get()});
        this.updateSlidesInState();

        this.scrollToActualNext();

        this.resetMagnification();
    }

    onSlideRemoved(message, userInfo)    {
        if(this.presID !== userInfo.presID)   return;

        this.slides.splice(userInfo.slide, 1);
        this.updateSlidesInState();

        this.scrollToActualNext();

        this.resetMagnification();
    }

    onSlideMoved(message, userInfo)  {
        let updated = false;
        if(this.presID === userInfo.srcPresID)   {
            this.slides.splice(userInfo.srcSlide, 1);
            updated = true;
        }

        if(this.presID === userInfo.dstPresID)   {
            let presController = this.oe.sharedInterface.getUIControllerPresentation();
            let data = presController.getSlideDataID(this.presID, userInfo.slideID);
            this.slides.splice(userInfo.dstSlide, 0, {id: userInfo.dstSlideID, data: data, name: data.name.get(), desc: data.description.get()});
            updated = true;
        }

        if(updated) {
            this.updateSlidesInState();
            this.resetMagnification();
        }
    }

    onSlideDataChanged(message, userInfo)   {
        if(this.presID !== userInfo.presID) return;
        this.slides[userInfo.slide].data = userInfo.data;
        this.slides[userInfo.slide].name = userInfo.data.name.get();
        this.slides[userInfo.slide].desc = userInfo.data.description.get();
        this.updateSlidesInState();
    }

    slideIndexFor(id)   {
        for(let i = 0; i < this.slides.length; i++) {
            if(this.slides[i].id === id) return i;
        }
        return -1;
    }

    slidePreviewIndexFor(id) {
        if(!this.isPlayerPreview) return this.slideIndexFor(id);

        let count = 0;
        for(let i = 0; i < this.slides.length; i++) {
            const slide = this.slides[i];
            if(!slide.data.showInPlayerPreview) continue;
            if(slide.id === id) return count;
            count++;
        }
        return -1;
    }
    
    nearestSlidePreviewIndexFor(id) {
        if(!this.isPlayerPreview) return this.slideIndexFor(id);

        let count = 0
        for(let i = 0; i < this.slides.length; i++) {
            const slide = this.slides[i];
            if(slide.data.showInPlayerPreview)  {
                if(slide.id === id) return count 
                count++;
            } else if(slide.id === id)  {
                return count - 1;
            }
        }
        return -1;
    }

    nearestSlidePreviewIdFor(id) {
        if(!this.isPlayerPreview) return id;

        let lastId = -1;
        for(let i = 0; i < this.slides.length; i++) {
            const slide = this.slides[i];
            if(slide.data.showInPlayerPreview)  {
                lastId = slide.id;
            }
            if(slide.id === id) return lastId 
        }
        return -1;
    }

    scrollTo(slideID)    {
        let id = this.nearestSlidePreviewIdFor(slideID);
        if(id < 0 || typeof(this.scrollbar) === 'undefined') return;

        let item = this['item' + id.toString() + 'Element'];
        if(!item) return;

        let itemCenter = item.offsetLeft + 0.5 * item.offsetWidth;
        let scrollViewCenter = 0.5*this.scrollbar.getClientWidth();
        let scrollLeft = itemCenter - scrollViewCenter;
        this.scrollbar.scrollLeft(scrollLeft);
        this.resetMagnification();
    }

    scrollToActual()    {
        if(this.activeSlideID < 0 || this.presID !== this.activePresID) return;
        this.scrollTo(this.activeSlideID);
    }

    scrollToActualNext()    {
        this.shouldScrollToActualNext = true;
        this.forceUpdate();
    }

    itemRect(id, fn)  {
        if(id === null || typeof(id) !== 'number' || id < 0) return;
        let item = this['item' + id.toString() + 'Element'];
        if(!item) {
            if(fn) {
                let ar = this.itemRectRequest[id.toString()] || [];
                ar.push(fn);
                this.itemRectRequest[id.toString()] = ar;
            }
            return;
        }
        const res = {x: item.offsetLeft, y: item.offsetTop, width: item.offsetWidth, height: item.offsetHeight};
        if(fn) fn(res);
        return res;
    }

    slideIdForIndex(index)  {
        if(index >= 0 && index < this.slides.length) return this.slides[index].id;
    }

    isActivePresentation()  {
        return this.presID === this.activePresID;
    }

    onRef(ref) {
        this.ref = ref;
    }

    onElementRef(ref, id) {
        this['item' + id.toString() + 'Element'] = ref;
        if(this.itemRectRequest[id.toString()]) {
            const res = this.itemRect(id);
            this.itemRectRequest[id.toString()].forEach(fn => { fn(res); });
            delete this.itemRectRequest[id.toString()];
        }
    }

    onScrollbarRef(ref) {
        this.scrollbar = ref;
    }

    render() {
        let disabled = !((this.state.uiEnabled || this.props.overrideUIEnabled) && this.props.enabled);

        const slides = !this.state.slides ? null : this.state.slides.map((slide, index) =>
            <OEPresentationItem
                key={slide.id}
                className={this.props.itemClassName}
                moduleId={this.props.moduleId}
                disabled={disabled}
                selected={slide.id === this.state.activeSlideID && this.state.activePresID === this.state.presID}
                id={slide.id}
                presID={this.state.presID}
                slideID={slide.id}
                index={index}
                showIndex={this.props.showIndices}
                name={slide.name}
                desc={slide.desc}
                onClick={this.onItemClicked}
                onMouseEnter={this.onItemMouseEnter}
                onMouseLeave={this.onItemMouseLeave}
                elementRef={this.onElementRef}
                renderContent={this.props.renderItemContent}
            />
        );

        const magnification = !this.state.magnification || !this.state.magnification.img ? null :
            <div 
                className={'magnification ' + this.props.magnificationClassName}
                style={{
                    backgroundImage: 'url(' + this.state.magnification.img + ')',
                    left: this.state.magnification.rect.x.toString() + 'px',
                    top: this.state.magnification.rect.y.toString() + 'px',
                    width: this.state.magnification.rect.w.toString() + 'px',
                    height: this.state.magnification.rect.h.toString() + 'px',
                }}
            />;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div 
                    className={'presentation-slides-view ' + this.props.className} 
                    style={this.props.style}
                    ref={this.onRef}
                >
                    <OEResizeObserver onResize={this.onResize} />

                    {this.props.noScrollbar ? <ul>{slides}</ul> :
                        <OEScrollbars
                            onScroll={this.onScroll}
                            ref={this.onScrollbarRef}
                        >
                            <ul>{slides}</ul>
                        </OEScrollbars>
                    }
                    {magnification}
                </div>
            </React.Fragment>
        );
    }

    onResize(sender, size)  {
        this.resetMagnification();
        if(this.props.onResize)  this.props.onResize(size);
    }

    onScroll()  {
        this.resetMagnification();
    }

    onItemClicked(id)   {
        this.props.onItemClicked(id);   // call this in front of own handling for OEMediaCenterItemPres

        if(this.state.presID !== this.state.activePresID) {
            this.oe.sharedInterface.getUIControllerPresentation().setActivePresentationID(this.state.presID);
        }
        this.oe.sharedInterface.getUIControllerPresentation().setActiveSlideID(id);

        this.oe.sharedInterface.getUIControllerPresentation().apply(true);
    }

    onItemMouseEnter(id)    {
        this.updateMagnification(id);
    }

    onItemMouseLeave(id)    {
        this.resetMagnification();
    }
}

OEPresentationSlideList.defaultProps = {
    className: '',
    itemClassName: '',
    magnificationClassName: '',
    style: null,
    moduleId: '',
    enabled: true,
    isPlayerPreview: false,
    magnification: 1,
    showIndices: false,
    onItemClicked: (id) => {},
    onActivePresentationChanged: (id) => {},
    onActiveSlideChanged: (id) => {}
};

OEPresentationSlideList.propTypes = {
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    magnificationClassName: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    isPlayerPreview: PropTypes.bool,
    magnification: PropTypes.number,
    showIndices: PropTypes.bool,
    onResize: PropTypes.func,
    onItemClicked: PropTypes.func,
    onActivePresentationChanged: PropTypes.func,
    onActiveSlideChanged: PropTypes.func
};