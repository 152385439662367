import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import {delegateOpenState} from '../../lib/oe-higher-order-components';
import {OEViewType} from '../../lib/oe-types';
import OENotification from '../../lib/oe-notification';
import {OEVersion} from '../../react-oe/oe-version';
import {OETarget} from '../../react-oe/oe-target';
import OEZeissUVWelcomeView from '../custom/zeiss/oe-zeiss-uv-welcome-view';

export function OEWelcomeViewFactory(props) {
    let ret = null;
    if(props.target === OETarget.zeissUV && props.version !== OEVersion.zeissOpti)  {
        const {fref, ...rest} = props;

        const openFn = (sender, isOpen) => {
            if(!props.appComponent || !props.appComponent.uiLayer) return;
            props.appComponent.uiLayer.notificationCenter.post(OENotification.viewOpenStateChanged, {
                type: OEViewType.welcome,
                isOpen: isOpen,
                sender: sender
            });
        };

        ret = <OEZeissUVWelcomeView {...rest} ref={fref} onOpenStateChanged={openFn}/>
    }
    return ret;
}

OEWelcomeViewFactory.defaultProps = {
    moduleId: ''
};

OEWelcomeViewFactory.propTypes = {
    moduleId: PropTypes.string,
};

export const OEWelcomeViewFactoryConnected = connectAppEnv((env) => { return {
    version: env.config.version,
    target: env.config.target
}})(OEWelcomeViewFactory);

export default delegateOpenState(OEWelcomeViewFactoryConnected);