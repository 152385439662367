import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../lib/oe-higher-order-components';
import OEIcon from './elements/oe-icon';
import OEButton from './elements/oe-button';
import OEPopover from './oe-popover';
import {oeUniqueIdGen} from '../lib/oe-unique-id-gen';

export class OEPopoverMenu extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.onBtnPressed = this.onBtnPressed.bind(this);
    }

    render()    {
        const {controllerClassName, entries, onChange, ...rest} = this.props;

        const leftRight = this.props.placement.indexOf('left') >= 0 || this.props.placement.indexOf('right') >= 0;
        const className = (leftRight ? 'd-block ' : '') + this.props.buttonClassName;

        const items = !entries ? null : entries.map((entry) =>
            <OEButton
                key={entry.id}
                className={className}
                disabled={this.props.disabled}
                userData={entry.id}
                onPressed={this.onBtnPressed}
            >
                {entry.icon ? <OEIcon code={entry.icon}/> : null}
                {entry.title ? entry.title : null}
            </OEButton>
        );

		return (
			<OEPopover
                {...rest}
            >
				<div className={controllerClassName}>
					<div>{items}</div>
				</div>
			</OEPopover>
		);
    }
    
    onBtnPressed(e, sender) {
        if(this.props.onChange) this.props.onChange(sender.props.userData);
    }
}

OEPopoverMenu.defaultProps = Object.assign({}, OEPopover.defaultProps, {
    className: 'popover-control',
    placement: 'right',
    noHeader: true,
    controllerClassName: 'popover-menu',
    buttonClassName: 'transparent-btn',
    backdrop: true,
    backdropOptions: {color: 'rgba(0, 0, 0, 0)', animated: false, duration: 0.333},
    disabled: false
});

OEPopoverMenu.propTypes = Object.assign({}, OEPopover.propTypes, {
    controllerClassName: PropTypes.string,
    entries: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string,
        icon: PropTypes.string
    })),
    disabled: PropTypes.bool
});

export default withIsOpenState(OEPopoverMenu);

export class OEPopoverMenuButtonUnmanaged extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.uid = oeUniqueIdGen.get();

        this.onBtnPressed = this.onBtnPressed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.disabled !== this.props.disabled && nextProps.disabled && nextProps.isOpen && nextProps.onToggle) {
            nextProps.onToggle();
        }
    }

    render()    {
        const {className, popoverClassName, elementRef, children, ...rest} = this.props;
        const id = 'popover-menu-button-target-id-' + this.uid;
		return (
            <React.Fragment>
                <OEButton
                    id={id}
                    className={className}
                    disabled={this.props.disabled}
                    activated={this.props.isOpen}
                    onPressed={this.onBtnPressed}
                    elementRef={elementRef}
                >
                    {children}
                </OEButton>
                <OEPopoverMenu target={id} {...rest} className={popoverClassName}/>
            </React.Fragment>
		);
    }
    
    onBtnPressed(e) {
        if(this.props.onPressed)    {
            this.props.onPressed();
            return;
        }
        if(this.props.onToggle) this.props.onToggle();
    }
}

OEPopoverMenuButtonUnmanaged.defaultProps = Object.assign({}, OEPopoverMenu.defaultProps, {
    className: '',
    popoverClassName: 'popover-control',
    backdrop: false
});

OEPopoverMenuButtonUnmanaged.propTypes = Object.assign({}, OEPopoverMenu.propTypes, {
    popoverClassName: PropTypes.string,
    onPressed: PropTypes.func
});

export const OEPopoverMenuButton = withIsOpenState(OEPopoverMenuButtonUnmanaged);
