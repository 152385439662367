import React from 'react';

import {OECheckEnvResultType, OEStatusType} from '../../react-oe/oe-surface';
import OEBrowserBar from '../elements/oe-browser-bar'

export default class OEStatusDlgHandler {

    constructor(modalContainer) {
        this.modalContainer = modalContainer;
    }

    onStatusChanged(status, modalContainer) {

        while(status.type === OEStatusType.aborted || status.type === OEStatusType.envWarning)   {

            var checkEnvResult = status.userInfo.checkEnvResult;

            if(checkEnvResult.type === OECheckEnvResultType.ok) {
                break;
            }

            var modContainer = modalContainer || this.modalContainer;
            if(modContainer == null)    {
                console.log('Problem detected with no modal container in place. ' + checkEnvResult.msg);
                break;
            }

            var content = function()    {

                var showBrowserBar = true;

                return <div className="text-center">
                    <span className="d-block mt-4 mr-4 mb-4 ml-4 h5">{checkEnvResult.msg}</span>

                    {!showBrowserBar ? null : (
                        <div>
                            <span className="d-block mt-4 mr-4 mb-4 ml-4">Please upgrade your browser and launch the app again or use one of the following browser:</span>
                            <OEBrowserBar className="mr-4 mb-4 ml-4"/>
                        </div>
                    )}

                </div>
            };

            var zIndex = 1101; // over other modals (1050) & over theme indicator view (1100)
            modContainer.addModal('Problem detected!', content, (id, container) => [], {zIndex: zIndex, hasCloseBtn: !checkEnvResult.fatal});

            break;
        }
    }
};