import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';

import {frefFromRef} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OEFeatureAccessTypes, OELicensingEdition, OEFNWelcomeEntryTypes} from '../../../lib/oe-types';
import {OENavigationViewComponent} from '../../../lib/oe-navigation-controller';
import OEFNStartView from '../../start-view/flow-nav/oe-flow-nav-start-view';
import OEFlowNavStartSelectionViewModel from '../../start-view/flow-nav/oe-flow-nav-start-selection-model';
import OEFlowNavStartSelectionView from '../../start-view/flow-nav/oe-flow-nav-start-selection-view';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {OEImplantcastStartSelectionData} from './oe-implantcast-types';
import {OEToolbox} from '../../../lib/oe-toolbox';

export class OEImplantcastStartView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.options = this.props.options;

        this.onMenuEntryClick = this.onMenuEntryClick.bind(this);

        this.entries = [
            {type: OEFNWelcomeEntryTypes.spacer, height: 38},
            {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text', fontSize: 17, mask: {licenseValid: true}},
            {type: OEFNWelcomeEntryTypes.text, stringId: 'welcome_view_text_no_license', fontSize: 17, mask: {licenseValid: false}},
            {type: OEFNWelcomeEntryTypes.spacer, height: 30},
            {type: OEFNWelcomeEntryTypes.licensing, mask: {licenseValid: false}},
            {id: 'selection', type: OEFNWelcomeEntryTypes.menu, stringId: 'welcome_view_implantcast_selection', icon: OEIconCodes.implantcast.startSelection, onClick: this.onMenuEntryClick},
            {type: OEFNWelcomeEntryTypes.spacer, height: 24},
            {id: 'selection_choice', type: OEFNWelcomeEntryTypes.text, string: 'Current:', fontSize: 17},
            {type: OEFNWelcomeEntryTypes.spacer, height: 17},
            {type: OEFNWelcomeEntryTypes.home, stringId: 'welcome_view_home_v2'},
            {type: OEFNWelcomeEntryTypes.mediaCenter, stringId: 'welcome_view_pres_v2'},
            //{type: OEFNWelcomeEntryTypes.quickStart},
            {type: OEFNWelcomeEntryTypes.manual},
            {type: OEFNWelcomeEntryTypes.spacer, height: 20},
            {type: OEFNWelcomeEntryTypes.dontShowCheckbox, noSubtitle: true, stringId: 'welcome_view_dont_show_again_title_v2'},
            {type: OEFNWelcomeEntryTypes.spacer, height: 12}
        ];

        let data = OEImplantcastStartSelectionData.choose(this.props.version, this.props.flags, this.props.target);

        this.selectionModel = new OEFlowNavStartSelectionViewModel(data);

        this.isLicensing = config.accessType == OEFeatureAccessTypes.licensing;

        this.state = {
            options: this.options,
            entries: clone(this.entries),
            categories: this.selectionModel.categories,
            startSelectionDisabled: this.isLicensing
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onLicensingProductActivationChanged = this.onLicensingProductActivationChanged.bind(this);

        this.onNavRef = this.onNavRef.bind(this);
        this.onRef = this.onRef.bind(this);

        this.onOptionsChanged = this.onOptionsChanged.bind(this);
        
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.version !== this.props.version || !OEToolbox.arrayShallowEqual(nextProps.flags, this.props.flags) || nextProps.target !== this.props.target)  {
            let data = OEImplantcastStartSelectionData.choose(nextProps.version, nextProps.flags, nextProps.target);
            if(!OEToolbox.shallowEqual(data, this.selectionModel.categories, true)) {
                this.selectionModel = new OEFlowNavStartSelectionViewModel(data);
                this.setState({categories: this.selectionModel.categories})
                this.updateLanguage(nextProps);
            }
        } else if(nextProps.target !== this.props.target)  {
            this.updateLanguage(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.licensingProductActivationChanged, this.onLicensingProductActivationChanged);
    }

    updateLanguage(props)   {
        if(!this.oe.isReady())  return;
        props = props || this.props;
        let si = this.oe.sharedInterface;

        let current = si.getLocalizedStringEnc('welcome_view_implantcast_current_choice');
        let item = this.selectionModel.itemForTarget(props.target);

        let itemTitle = '';
        if(item && item.label)    {
            itemTitle = item.label.id ? si.getLocalizedStringEnc(item.label.id) : (item.label.text ? item.label.text : '');
        }

        let currentChoice = current + ': ' + itemTitle;

        let selectionChoiceEntry = this.entries.find(entry => entry.id === 'selection_choice');
        if(selectionChoiceEntry && selectionChoiceEntry.string !== currentChoice)   {
            selectionChoiceEntry.string = currentChoice;
            this.setState({entries: clone(this.entries)});
        }
    }

    onLicensingProductActivationChanged()   {
        this.updateLicenseValidity();
    }

    updateLicenseValidity()    {
        let si = this.oe.sharedInterface;

        if(!this.isLicensing || !si.getUIControllerFeatureAccess().isAccessType(this.oe.Module.FeatureAccessType.licensing)) {
            this.setState({startSelectionDisabled: false});
            return;
        }

        let licenseValid = si.getUIControllerLicensingManager().isValid();
        let edition = si.getUIControllerLicensingManager().getEdition();

        let startSelectionDisabled = true;
        if(licenseValid)    {
            if([OELicensingEdition.basic, OELicensingEdition.viewer, OELicensingEdition.singleUser].includes(edition))  {
                startSelectionDisabled = false;
            }
            if([OELicensingEdition.extended, OELicensingEdition.professional, OELicensingEdition.branchOffice, OELicensingEdition.master].includes(edition))  {
                startSelectionDisabled = false;
            }
        }

        this.setState({startSelectionDisabled: startSelectionDisabled});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({startSelectionDisabled: this.isLicensing});
            return;
        }
        this.updateLanguage();
        this.updateLicenseValidity();
    }

    onNavRef(ref)   {
        if(this.props.onNavRef)   this.props.onNavRef(ref);
        this.navRef = ref;
    }

    onRef(ref)  {
        if(this.props.fref)   this.props.fref(ref);
        this.ref = ref;
        if(ref && this.options !== ref.options)    {
            this.options = ref.options;
            this.setState({options: this.options});
        }
    }

    renderSelectionView(deferred = false)   {
        return (
            <OENavigationViewComponent
                title={{titleId: 'welcome_view_implantcast_selection', icon: OEIconCodes.implantcast.startSelection}}
                compId="start-selection"
                deferred={deferred}
                showLogo={true}
            >
                <OEFlowNavStartSelectionView
                    moduleId={this.props.moduleId}
                    itemsDisabled={this.state.startSelectionDisabled}
                    categories={this.state.categories}
                    onItemClick={this.onItemClick}
                />
            </OENavigationViewComponent>
        );
    }

    render()    {
        const {fref, target, appComponent, ...rest} = this.props;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEFNStartView
                    {...rest}
                    onNavRef={this.onNavRef}
                    onOptionsChanged={this.onOptionsChanged}
                    entries={this.state.entries}
                    ref={this.onRef}
                >
                    {this.renderSelectionView(this.state.options !== 'start-selection')}
                </OEFNStartView>;
            </React.Fragment>
        );
    }

    onOptionsChanged(sender, options)  {
        if(this.options !== options)    {
            this.options = options;
            this.setState({options: this.options});
        }
    }

    onMenuEntryClick(id, sender, navigationController)  {
        if(id == 'selection')   {
            navigationController.push('start-selection');
        }
    }

    onItemClick(sender, item)   {
        if(!item.target)    return;

        if(this.ref && this.ref.options === 'start-selection')  {
            this.ref.close();
            return;
        }
        
        if(this.navRef && this.navRef.top() && this.navRef.top().view.props.compId === 'start-selection')   {
            this.navRef.pop('start-selection');
        }
    }
};

OEImplantcastStartView.defaultProps = {
    moduleId: '',
    target: '',
    version: '',
    flags: []
};

OEImplantcastStartView.propTypes = {
    moduleId: PropTypes.string,
    version: PropTypes.string,
    target: PropTypes.string,
    flags: PropTypes.arrayOf(PropTypes.string)
};

export default frefFromRef(OEImplantcastStartView);
