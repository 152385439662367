import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import {OEMediaCenterItemFactory} from './oe-media-center-item-factory';
import OEScrollbars from '../../oe-scrollbars';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEMediaCenterItemCollection extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.stateItems = [];

        this.state = {
            items: this.stateItems
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onPresentationAdded = this.onPresentationAdded.bind(this);
        this.onPresentationRemoved = this.onPresentationRemoved.bind(this);
        this.onPresentationDataChanged = this.onPresentationDataChanged.bind(this);

        this.onScrollbarRef = this.onScrollbarRef.bind(this);
        this.onElementRef = this.onElementRef.bind(this);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.onItem = this.onItem.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.items !== this.props.items)     {
            this.updateStateItems(nextProps.items);
        }
    }

    componentDidUpdate()    {
        if(this.shouldScrollNextTo)    {
            this.scrollTo(this.shouldScrollNextTo.itemID, this.shouldScrollNextTo.rect);
            delete this.shouldScrollNextTo;
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationRemoved, this.onPresentationRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationDataChanged, this.onPresentationDataChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAdded, this.onPresentationAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationRemoved, this.onPresentationRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationDataChanged, this.onPresentationDataChanged);
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.presentations = {};
            this.stateItems = [];
            this.setState({items: this.stateItems});
            return;
        }

        retardUpdate(this, () => {
            this.updatePresentations();
            this.updateLanguage();
        });
    }

    updateLanguage()   {
        this.updateStateItems();
    }

    updatePresentations()   {
        const controller = this.oe.sharedInterface.getUIControllerPresentation();
        const presentations = controller.getPresentationDataList();

        this.presentations = {};

        for(let i = 0; i < presentations.length; i++) {
            this.presentations[presentations[i].id.toString()] = {id: presentations[i].id, name: presentations[i].name, desc: presentations[i].description};
        }

        this.updateStateItemsForPresentations();
    }

    updateStateItemsForPresentations()  {
        const Type = this.oe.Module.MediaCenterItemType;
        this.stateItems = this.stateItems.map((item) =>  {
            let pres = item.type === Type.presentation ? this.presentations[item.item.index.toString()] : null;
            return {
                id: item.id,
                type: item.type,
                layoutType: item.layoutType,
                showNumber: item.showNumber,
                showLabel: item.showLabel,
                icon: item.icon,
                label: pres && pres.name.get() ? pres.name.get() : item.label_,
                desc: pres ? pres.desc.get() : null,
                img: pres ? null : item.img,
                item: item.item
            }
        });

        this.setState({items: this.stateItems});
    }

    onPresentationAdded(message, userInfo)   {
        let pres = userInfo.presData;
        this.presentations[pres.id.toString()] = {id: pres.id, name: pres.name, desc: pres.description};
        this.updateStateItemsForPresentations();
    }

    onPresentationRemoved(message, userInfo)   {
        delete this.presentations[userInfo.presID.toString()];
    }

    onPresentationDataChanged(message, userInfo)   {
        // if(!(userInfo.changeFlags & (this.oe.Module.PresentationDataChangeFlags.name.value | this.oe.Module.PresentationDataChangeFlags.desc.value)))  return;   // there seems to be some problem with PresentationDataChangeFlags.name 

        let pres = userInfo.presData;
        this.presentations[pres.id.toString()] = {id: pres.id, name: pres.name, desc: pres.description};
        this.updateStateItemsForPresentations();
    }

    updateStateItems(items)   {
        if(!this.oe.isReady())  {
            this.stateItems = [];
            this.setState({ items: this.stateItems });
            return;
        }

        items = items || this.props.items;

        const si = this.oe.sharedInterface;
        const Type = this.oe.Module.MediaCenterItemType;

        let resourcePath = 'app/oe/' + this.oe.Module.filePackagePathURL;

        this.stateItems = items.map((item) =>  { 
            let img = null;
            let label = si.getLocalizedStringEnc(item.label);
            let pres = item.type === Type.presentation ? this.presentations[item.index.toString()] : null;
            
            if(item.type !== Type.presentation)    {
                img = item.thumbImagePath !== '' ? item.thumbImagePath : (item.previewImagePath !== '' ? item.previewImagePath : (item.type === Type.image ? item.path : null));
                if(img) img = resourcePath +  si.getLocalizedStringEnc(img);
            }

            let icon = {
                type: item.icon.type.value,
                code: item.icon.type === this.oe.Module.MediaCenterIconType.text ? si.getLocalizedStringEnc(item.icon.code) : item.icon.code,
                color: item.icon.color
            };

            return {
                id: item.id,
                type: item.type,
                layoutType: item.layoutType.value,
                showNumber: item.showNumber,
                showLabel: item.showLabel,
                icon: icon,
                label: pres && pres.name.get() ? pres.name.get() : label,
                label_: label,
                desc: pres && pres.desc.get() ? pres.desc.get() : null,
                img: img,
                item: item
            };
        });

        this.setState({items: this.stateItems});
    }

    scrollTo(itemID, rect)    {
        if(itemID < 0 || !this.scrollbar) return;
        let item = this['item' + itemID.toString() + 'Element'];
        if(!item) return;

        let additionalOffset = rect ? (rect.x + rect.width * 0.5) : 0.5 * item.offsetWidth;

        let itemCenter = item.offsetLeft + additionalOffset;
        let scrollViewCenter = 0.5*this.scrollbar.getClientWidth();
        this.scrollbar.scrollLeft(itemCenter - scrollViewCenter);

        additionalOffset = rect ? (rect.y + rect.height * 0.5) : 0.5 * item.offsetHeight;

        itemCenter = item.offsetTop + additionalOffset;
        scrollViewCenter = 0.5*this.scrollbar.getClientHeight();
        this.scrollbar.scrollTop(itemCenter - scrollViewCenter);
    }

    scrollNextTo(itemID, rect)    {
        this.shouldScrollNextTo = {itemID: itemID, rect: rect};
        this.forceUpdate();
    }

    onScrollbarRef(ref) {
        this.scrollbar = ref;
    }

    onElementRef(ref, id) {
        this['item' + id.toString() + 'Element'] = ref;
    }

    render() {
        const itemViews = this.state.items.map((item, index) =>
            <OEMediaCenterItemFactory
                moduleId={this.props.moduleId}
                config={this.props.config}
                key={item.id}
                index={index}
                item={item}
                className={this.props.itemClassName}
                style={this.props.itemStyle}
                enabled={this.props.enabled}
                selected={index === this.props.selectedItemIndex}
                onClick={this.onItemClicked}
                onItem={this.onItem}
                elementRef={this.onElementRef}
            />
        );

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEScrollbars 
                    className={'media-center-item-collection ' + this.props.className}
                    style={this.props.style}
                    ref={this.onScrollbarRef}
                >
                    <div className='media-center-item-collection-container'>{itemViews}</div>
                </OEScrollbars>
            </React.Fragment>
        );
    }

    onItemClicked(index, id, subId)   {
        this.props.onClick(index, id, subId);
    }

    onItem(index, id, subId, rect) {
        // this function gets also called while some update process of the content is happening and the scroll view size being reported by the DOM is misleading
        // as a workaround we defer the scrolling to the next componentDidUpdate
        this.scrollNextTo(id, rect);
    }
}

OEMediaCenterItemCollection.defaultProps = {
    className: '',
    moduleId: '',
    enabled: true,
    items: [],
    selectedItemIndex: -1,
    onClick: (index, id) => {},
    config: {
        usePresItems: true,
        showDurationInSlidesForPresWithoutAudio: false
    }
};

OEMediaCenterItemCollection.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    items: PropTypes.array,
    selectedItemIndex: PropTypes.number,
    onClick: PropTypes.func,
    config: PropTypes.shape({
        usePresItems: PropTypes.bool,
        showDurationInSlidesForPresWithoutAudio: PropTypes.bool
    })
};