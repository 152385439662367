import CustomEvent from 'custom-event';

class OEEventRouter {

    constructor() {
        this.onEvent = this.onEvent.bind(this);
        this.events = ['keydown', 'keyup', 'keypress', 'focus', 'blur'];
        this.routes = [];

        this.connect();
    }

    addRoute(route) {
        if(this.routes.findIndex(r => r.window === route.window && r.element === route.element) >= 0) return;
        this.routes.push(route);
    }

    removeRoute(route)  {
        var index = this.routes.findIndex(r => r.window === route.window && r.element === route.element);
        if(index < 0) return;
        this.routes.splice(index, 1);
    }

    connect()   {
        this.events.forEach((type) => {
            window.addEventListener(type, this.onEvent);
        });
    }

    release()   {
        this.events.forEach((type) => {
            window.removeEventListener(type, this.onEvent);
        });
    }

    createEvent(win, element, event) {
        var e = null;

        if(['keydown', 'keyup', 'keypress'].findIndex((type) => type === event.type) >= 0)   {
            e = new KeyboardEvent(event.type, {
                bubbles : true,
                cancelable : false,
                view: win || window,
                detail: event.detail,
                key : event.key,
                code : event.code,
                location : event.location,
                ctrlKey : event.ctrlKey,
                shiftKey : event.shiftKey,
                altKey : event.altKey,
                metaKey : event.metaKey,
                repeat : event.repeat,
                isComposing : event.isComposing,
                charCode : event.charCode,
                keyCode : event.charCode,
                which : event.charCode
            });
        } else  {
            e = new CustomEvent(event.type);
            e.view = win || window;
        }

        element.dispatchEvent(e);
    }

    routeEvent(event)    {
        this.routes.forEach((route) => {
            this.createEvent(route.window, route.element, event);
        });
    }

    onEvent(event)  {
        //console.log('OEEventRouter - ' + event.type);
        this.routeEvent(event);
    }
};

export let oeEventRouter = new OEEventRouter();