
import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEOverlayTextBox from '../../overlays/elements/oe-overlay-text-box';
import {retardUpdate} from '../../../lib/update-retarder';
import {OEPhilipsIcarusCustomPropKey, OEPhilipsIcarusMode, OEPhilipsIcarusModel} from './oe-philips-icarus-model';

export default class OEPhilipsIcarusTextBox extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            src: ''
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    updateSrc(src)   {
        if(typeof(src) === 'string') {
            this.setState({src: src});
            return;
        }

        if(!this.oe.isReady())  return;
        const si = this.oe.sharedInterface;

        let animationId = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.animationId).value;
        let animation = OEPhilipsIcarusModel.shared().getAnimation(animationId);

        let mode = si.getCustomProperty(OEPhilipsIcarusCustomPropKey.mode).value;

        let textSrc = animation ? (mode == OEPhilipsIcarusMode.std ? animation.textSrc : (mode == OEPhilipsIcarusMode.xRay ? animation.xRayTextSrc : undefined)) : undefined;

        if(!textSrc)  {
            this.updateSrc('');
            return;
        }

        this.updateSrc('app/oe/' + this.oe.Module.filePackagePathURL + 'philipsIcarusMedia/texts/' + textSrc);
    }

    updateLanguage()   {
        this.updateSrc();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({
                src: ''
            });
            return;
        }

        retardUpdate(this, () => {
            this.updateSrc();
        });
    }

    onCustomPropertyChanged(message, userInfo)   {
        if(userInfo.key === OEPhilipsIcarusCustomPropKey.mode || userInfo.key === OEPhilipsIcarusCustomPropKey.animationId)   {
            this.updateSrc();
        }
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEOverlayTextBox
                    {...this.props}
                    src={this.state.src}
                />
            </React.Fragment>
        );
    }
}

OEPhilipsIcarusTextBox.defaultProps = {
    className: '',
    moduleId: '',
    isOpen: true,
    isHidden: false,
    extLayoutType: OEOverlayTextBox.ExtLayoutType.right
};

OEPhilipsIcarusTextBox.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    isOpen: PropTypes.bool,
    isHidden: PropTypes.bool,
    extLayoutType: PropTypes.oneOf([OEOverlayTextBox.ExtLayoutType.right, OEOverlayTextBox.ExtLayoutType.left]),
    onToggle: PropTypes.func,
    onToLeftRightBtnPressed: PropTypes.func
};

