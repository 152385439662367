import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import {OEToolbox} from '../../lib/oe-toolbox';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OEAnimationControlAdapterStd} from '../animation-control/oe-animation-adapter';
import OEZeissOverlayAnimationControl from '../custom/zeiss/oe-zeiss-overlay-animation-control';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEZeissOptiOverlay extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.edgeOffsets = {top: 0, right: 0, bottom: 0, left: 0};

        this.distancePropKey = 'zeiss_opti_distance';
        this.opticalPowerPropKey = 'zeiss_opti_optical_power';
        this.addPropKey = 'zeiss_opti_add';
        this.prevFramePropKey = 'zeiss_opti_prev_frame';
        this.zoomFramePropKey = 'zeiss_opti_zoom_frame';
        this.numZoomPerspectivesPropKey = 'zeiss_opti_num_zoom_perspectives';
        this.activeZoomPerspectivePropKey = 'zeiss_opti_active_zoom_perspective';
        this.correctiveProgressPropKey = 'zeiss_opti_corrective_progress';

        this.state = {
            visible: false,
            animationControl: {
                visible: true,
                enabled: false,
                adapter: null
            },
            previewView:    {
                visible: false,
                showOpticalPower: true,
                showAddOpticalPower: false,
                frame: null,
                strings: {
                    distance: '4 m',
                    opticalPower: '-2.5 dpt',
                    addOpticalPowerTitle: 'add',
                    addOpticalPower: '+2.7 dpt'
                }
            },
            zoomView: {
                visible: false,
                numPerspectives: 0,
                activePerspective: 0
            },
            hideLensButton: {
                visible: false,
                activated: false
            },
            correctionButton: {
                visible: false
            }
        };

        this.updateLanguage = this.updateLanguage.bind(this);
        this.onAnimationModeChanged = this.onAnimationModeChanged.bind(this);
        this.onAnimationTimeChanged = this.onAnimationTimeChanged.bind(this);
        this.onActiveAnimationChanged = this.onActiveAnimationChanged.bind(this);
        this.onComponentsRenderModeChanged = this.onComponentsRenderModeChanged.bind(this);
        this.onCustomPropertyChanged = this.onCustomPropertyChanged.bind(this);

        this.onAnimationControlPlay = this.onAnimationControlPlay.bind(this);
        this.onAnimationControlPause = this.onAnimationControlPause.bind(this);
        this.onAnimationControlStop = this.onAnimationControlStop.bind(this);
        this.onProgressSliderChanged = this.onProgressSliderChanged.bind(this);

        this.onPrevPerspectiveBtnPressed = this.onPrevPerspectiveBtnPressed.bind(this);
        this.onNextPerspectiveBtnPressed = this.onNextPerspectiveBtnPressed.bind(this);

        this.onHideLensBtnPressed = this.onHideLensBtnPressed.bind(this);
        this.onCorrectionBtnPressed = this.onCorrectionBtnPressed.bind(this);
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    getEdgeOffsets()    {
        return this.edgeOffsets;
    }

    updateEdgeOffsets(animationControlVisible)    {
        let edgeOffsets = {top: 0, right: 0, bottom: animationControlVisible ? 50 : 0, left: 0};
        if(OEToolbox.shallowEqual(this.edgeOffsets, edgeOffsets)) return;
        this.edgeOffsets = edgeOffsets;
        if(this.props.onEdgeOffsetsChanged) this.props.onEdgeOffsetsChanged(this, this.edgeOffsets, true);
    }

    hideShowLens(show, animated)  {
        if(!this.oe.isReady())  return;
        let animation = this.oe.sharedInterface.getUIControllerAnimation();
        let component = this.oe.sharedInterface.getUIControllerComponent();
        let activeAnimation = animation.getActiveAnimation();
        if(activeAnimation <= 1) return;
        let rm = show ? this.oe.Module.RenderMode.opaque : this.oe.Module.RenderMode.hide;
        component.setComponentRenderMode(activeAnimation != 5 ? "X014" : "X200", rm, typeof(animated) === 'boolean' ? animated : true, 0.333);
    }

    updateForDistance() {
        if(!this.oe.isReady())  {   return; }

        let property = this.oe.sharedInterface.getCustomProperty(this.distancePropKey);
        if(!property)   { return; }

        let controller = this.oe.sharedInterface.getUIControllerAnimation();
        
        let animationMode = controller.getAnimationMode();
        let activeAnimation = controller.getActiveAnimation();
        
        let showPreviewView = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 1;

        if(showPreviewView) {
            let distance = property.value.toFixed(1) + ' m';
            this.setStateUpdate({previewView: { strings: { distance: {$set: distance} } }});
        }
    }

    updateForOpticalPower()  {
        if(!this.oe.isReady())  {   return; }

        let property = this.oe.sharedInterface.getCustomProperty(this.opticalPowerPropKey);
        if(!property)   { return; }

        let controller = this.oe.sharedInterface.getUIControllerAnimation();
        
        let animationMode = controller.getAnimationMode();
        let activeAnimation = controller.getActiveAnimation();

        let showOpticalPower = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 1;

        if(showOpticalPower) {
            let opticalPower = property.value;
            opticalPower = (opticalPower > 0 ? '+' : (opticalPower < 0 ? '-' : '')) + opticalPower.toFixed(2) + ' dpt';
            this.setStateUpdate({previewView: { strings: { opticalPower: {$set: opticalPower} } }});
        }
    }

    updateForAddOpticalPower()  {
        if(!this.oe.isReady())  {   return; }

        let property = this.oe.sharedInterface.getCustomProperty(this.addPropKey);
        if(!property)   { return; }

        let controller = this.oe.sharedInterface.getUIControllerAnimation();

        let animationMode = controller.getAnimationMode();
        let activeAnimation = controller.getActiveAnimation();

        let showAddOpticalPower = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 1;

        if(showAddOpticalPower) {
            let addOpticalPower = property.value;
            addOpticalPower = (addOpticalPower > 0 ? '+' : '') + addOpticalPower.toFixed(2) + ' dpt';
            this.setStateUpdate({previewView: { strings: { addOpticalPower: {$set: addOpticalPower} } }});
        }
    }

    updateForPrevFrame()    {
        if(!this.oe.isReady())  return;
        let property = this.oe.sharedInterface.getCustomProperty(this.prevFramePropKey);
        if(!property)   return;

        let frameVec = property.vec;
        let frame = {x: frameVec.x, y: frameVec.y, width: frameVec.z, height: frameVec.w};
        let dprInv = 1.0 / this.oe.Module.devicePixelRatio();
        frame = {x: dprInv * frame.x, y: dprInv * frame.y, width: dprInv * frame.width, height: dprInv * frame.height}; 
    
        //console.log('updateForPrevFrame - ' + frame.x.toString() + ' ' + frame.y.toString() + ' ' + frame.width.toString() + ' ' + frame.height.toString());
    
        this.setStateUpdate({previewView: { frame: {$set: frame } }});
    }

    updateForZoomFrame()    {
        if(!this.oe.isReady())  return;
        let property = this.oe.sharedInterface.getCustomProperty(this.zoomFramePropKey);
        if(!property)   return;

        let frameVec = property.vec;
        let frame = {x: frameVec.x, y: frameVec.y, width: frameVec.z, height: frameVec.w};
        let dprInv = 1.0 / this.oe.Module.devicePixelRatio();
        frame = {x: dprInv * frame.x, y: dprInv * frame.y, width: dprInv * frame.width, height: dprInv * frame.height}; 
    
        //console.log('updateForZoomFrame - ' + frame.x.toString() + ' ' + frame.y.toString() + ' ' + frame.width.toString() + ' ' + frame.height.toString());
    
        this.setStateUpdate({zoomView: { frame: {$set: frame } }});
    }

    updateForPerspective()    {
        if(!this.oe.isReady())  return;
        let propertyNum = this.oe.sharedInterface.getCustomProperty(this.numZoomPerspectivesPropKey);
        let propertyActive = this.oe.sharedInterface.getCustomProperty(this.activeZoomPerspectivePropKey);
        if(!propertyNum || !propertyActive)   return;
        this.setStateUpdate({zoomView: { numPerspectives: {$set: propertyNum.value }, activePerspective: {$set: propertyActive.value } }});
    }

    updateForTime() { }

    updateHideLensButton()  {
        if(!this.oe.isReady())  return;
        let animation = this.oe.sharedInterface.getUIControllerAnimation();
        let component = this.oe.sharedInterface.getUIControllerComponent();
        let activeAnimation = animation.getActiveAnimation();
        let visible = activeAnimation > 1;
        let isLensHidden = visible && ((activeAnimation != 5 && component.getComponentRenderMode("X014") === this.oe.Module.RenderMode.hide) || (activeAnimation == 5 && component.getComponentRenderMode("X200") === this.oe.Module.RenderMode.hide));
        this.setStateUpdate({
            hideLensButton: {
                visible: {$set: visible},
                activated: {$set: isLensHidden}
            }
        });
    }

    update()   {
        if(!this.oe.isReady())  return;
        let animation = this.oe.sharedInterface.getUIControllerAnimation();
        let component = this.oe.sharedInterface.getUIControllerComponent();

        let animationMode = animation.getAnimationMode();
        let activeAnimation = animation.getActiveAnimation();
        
        let visible = animationMode !== this.oe.Module.AnimationMode.disabled;
        let showAnimationControl = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 0 && activeAnimation != 1;// && activeAnimation < 5;
        let enableAnimationControl = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 0 && activeAnimation != 1 && activeAnimation < 5;
        let showPreviewView = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 1;
        let showZoomView = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 1;
        
        let isLensHidden = activeAnimation < 2 || ((activeAnimation != 5 && component.getComponentRenderMode("X014") === this.oe.Module.RenderMode.hide) || (activeAnimation == 5 && component.getComponentRenderMode("X200") === this.oe.Module.RenderMode.hide));

        this.setStateUpdate({
            visible: {$set: visible},
            animationControl: {
                visible: {$set: showAnimationControl},
                enabled: {$set: enableAnimationControl}
            },
            previewView: {
                visible: {$set: showPreviewView},
                showOpticalPower: {$set: !isLensHidden},
                showAddOpticalPower: {$set: activeAnimation == 5 && !isLensHidden}
            },
            zoomView: {
                visible: {$set: showZoomView}
            },
            correctionButton: {
                visible: {$set: activeAnimation >= 2 && activeAnimation < 5}
            }
        });

        this.updateEdgeOffsets(visible && showAnimationControl);
        
        this.updateForTime();
        this.updateForDistance();
        this.updateForOpticalPower();
        this.updateForAddOpticalPower();
        this.updateForPrevFrame();
        this.updateForZoomFrame();
        this.updateForPerspective();
        this.updateHideLensButton();
    }

    updateAnimationControlAdapter()  {
        if(!this.oe.isReady())  return;
        let controller = this.oe.sharedInterface.getUIControllerAnimation();
        let animationMode = controller.getAnimationMode();
        let activeAnimation = controller.getActiveAnimation();
        let enableAnimationControl = animationMode !== this.oe.Module.AnimationMode.disabled && activeAnimation >= 0 && activeAnimation != 1 && activeAnimation < 5;

        if(activeAnimation < 0)   {
            this.setStateUpdate({animationControl: { enabled: {$set: enableAnimationControl}, adapter: {$set: null} }});
        } else {
            let adapter = new OEAnimationControlAdapterStd();
            adapter.disabledWhenStop = false;
            adapter.setBinding(this.oe, OEAnimationControlAdapterStd.Binding.animation, activeAnimation);

            this.setStateUpdate({animationControl: { enabled: {$set: enableAnimationControl}, adapter: {$set: adapter} }});
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.animationModeChanged, this.onAnimationModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.animationTimeChanged, this.onAnimationTimeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activeAnimationChanged, this.onActiveAnimationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.componentsRenderModeChanged, this.onComponentsRenderModeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.animationModeChanged, this.onAnimationModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.animationTimeChanged, this.onAnimationTimeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activeAnimationChanged, this.onActiveAnimationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.componentsRenderModeChanged, this.onComponentsRenderModeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.customPropertyChanged, this.onCustomPropertyChanged);
    }

    updateLanguage()   {
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setState({visible: false});
            this.updateEdgeOffsets(false);
            return;
        }

        retardUpdate(this, () => {
            this.updateLanguage();
            this.update();
            this.updateAnimationControlAdapter();
        });

        this.hideShowLens(false, false);
    }

    onAnimationModeChanged() {
        retardUpdate(this, () => {
            this.update();
        });
    }
    
    onAnimationTimeChanged() {
        this.updateForTime();
    }
    
    onActiveAnimationChanged() {
        retardUpdate(this, () => {
            this.update();
            this.updateAnimationControlAdapter();
        });

        this.hideShowLens(false, false);
    }

    onComponentsRenderModeChanged()   {
        retardUpdate(this, () => {
            this.updateHideLensButton();
            this.update();
        });

        let animation = this.oe.sharedInterface.getUIControllerAnimation();
        let component = this.oe.sharedInterface.getUIControllerComponent();
        let activeAnimation = animation.getActiveAnimation();
        let isLensHidden = animation.getAnimationMode() === this.oe.Module.AnimationMode.play && activeAnimation > 1 && ((activeAnimation != 5 && component.getComponentRenderMode("X014") === this.oe.Module.RenderMode.hide) || (activeAnimation == 5 && component.getComponentRenderMode("X200") === this.oe.Module.RenderMode.hide));
        if(isLensHidden) animation.setAnimationMode(this.oe.Module.AnimationMode.pause);
    }
    
    onCustomPropertyChanged(message, userInfo)   {
        if(userInfo.key === this.distancePropKey)   {
            this.updateForDistance();
        } else if(userInfo.key === this.opticalPowerPropKey)   {
            this.updateForOpticalPower();
        } else if(userInfo.key === this.addPropKey)   {
            this.updateForAddOpticalPower();
        } else if(userInfo.key === this.prevFramePropKey)   {
            this.updateForPrevFrame();
        } else if(userInfo.key === this.zoomFramePropKey)   {
            this.updateForZoomFrame();
        } else if(userInfo.key === this.numZoomPerspectivesPropKey || userInfo.key === this.activeZoomPerspectivePropKey) {
            this.updateForPerspective();
        }
    }

    renderAnimationControl()  {
        return (
            <OEZeissOverlayAnimationControl
                visible={this.state.animationControl.visible && this.state.visible}
                enabled={true}
                animationControlEnabled={this.state.animationControl.enabled && !this.state.hideLensButton.activated}
                adapter={this.state.animationControl.adapter}
                header={true ? null :
                    <div className="header">
                        <div className="icon-label light-bg">
                            <img src="images/icons/glasses.png"/>
                        </div>
                    </div>
                }
                hideLens={{
                    disabled: false,
                    active: !this.state.hideLensButton.activated,
                    hidden: !this.state.hideLensButton.visible,
                    onClick: this.onHideLensBtnPressed
                }}
                correctionButton={{
                    disabled: this.state.hideLensButton.activated,
                    hidden: !this.state.correctionButton.visible,
                    onPressed: this.onCorrectionBtnPressed
                }}
                rightLabel={this.state.previewView.visible && this.state.previewView.showOpticalPower ? this.state.previewView.strings.opticalPower : null}
                onPlayBtnPressed={this.onAnimationControlPlay}
                onPauseBtnPressed={this.onAnimationControlPause}
                onStopBtnPressed={this.onAnimationControlStop}
                onProgressSliderChanged={this.onProgressSliderChanged}
            />
        );
    }

    renderPreviewView()  {
        const frame = this.state.previewView.frame;
        if(!frame) return null;
        const visible = this.state.previewView.visible && this.state.visible;

        return ( 
            <div 
                className="view container preview screenshot-filter-show"
                style={{
                    left: frame.x.toString() + 'px',
                    top: frame.y.toString() + 'px',
                    width: frame.width.toString() + 'px',
                    height: frame.height.toString() + 'px',
                    visibility: visible ? 'visible' : 'hidden', 
                    opacity: visible ? '1' : '0'
                }}
            >
                <div className="preview">
                    <div className="first-line light-bg">
                        <img src="images/icons/double-arrow.png"/>
                        {this.state.previewView.strings.distance}
                    </div>

                    {!this.state.previewView.showOpticalPower ? null :
                        <div className="second-line light-bg">
                            <img src="images/icons/glasses.png"/>
                            {this.state.previewView.strings.opticalPower}
                        </div>
                    }

                    {!this.state.previewView.showAddOpticalPower ? null :
                        <div className="third-line light-bg">
                            <span>{this.state.previewView.strings.addOpticalPowerTitle}</span>
                            {this.state.previewView.strings.addOpticalPower}
                        </div>
                    }
                </div>
            </div>
        );
    }

    renderZoomView()  {
        const frame = this.state.zoomView.frame;
        if(!frame) return null;
        const visible = this.state.zoomView.visible && this.state.visible;

        return ( 
            <div 
                className="view container zoom screenshot-filter-show"
                style={{
                    left: frame.x.toString() + 'px',
                    top: frame.y.toString() + 'px',
                    width: frame.width.toString() + 'px',
                    height: frame.height.toString() + 'px',
                    visibility: visible ? 'visible' : 'hidden', 
                    opacity: visible ? '1' : '0'
                }}
            >
                {/*
                <div className="zoom">
                    <OEButton
                        className="transparent-btn prev light-bg"
                        disabled={this.state.zoomView.activePerspective <= 0}
                        onPressed={this.onPrevPerspectiveBtnPressed}
                    >
                        <OEIcon code={OEIconCodes.backward}/>
                    </OEButton>
                    <OEButton
                        className="transparent-btn next light-bg"
                        disabled={this.state.zoomView.activePerspective >= this.state.zoomView.numPerspectives - 1}
                        onPressed={this.onNextPerspectiveBtnPressed}
                    >
                        <OEIcon code={OEIconCodes.forward}/>
                    </OEButton>
                </div>
                */}
            </div>
        );
    }

    renderZoomSelection()  {
        const frame = this.state.zoomView.frame;
        if(!frame || !this.state.previewView.frame) return null;
        let padding = this.state.previewView.frame.x - (frame.x + frame.width);
        let width = 3 * padding;

        let items = [];
        for(let i = 0; i < this.state.zoomView.numPerspectives; i++)  {
            const index = i;
            items.push(
                <a
                    key={i}
                    className={'item ' + (i === 0 ? 'first ' : '') + (i === this.state.zoomView.numPerspectives - 1 ? 'last ' : '') + (i === this.state.zoomView.activePerspective ? 'selected ' : '')}
                    style={{backgroundImage: 'url(images/zeissOpti/zoomThumb' + OEToolbox.numberToString(i, 2) + '.jpg)'}}
                    onClick={() => this.onZoomSelectionClick(index)}
                />
            );
        }

        const visible = this.state.zoomView.visible && this.state.visible;

        return ( 
            <div 
                className="view container zoom-perspectives screenshot-filter-show"
                style={{
                    left: (frame.x - width - padding).toString() + 'px',
                    top: frame.y.toString() + 'px',
                    width: width.toString() + 'px',
                    height: frame.height.toString() + 'px',
                    visibility: visible ? 'visible' : 'hidden', 
                    opacity: visible ? '1' : '0'
                }}
            >
                {items}
            </div>
        );
    }

    renderHideLensBtn() {
        const visible = this.state.hideLensButton.visible && this.state.visible;
        return ( 
            <div 
                className="view right-bottom"
                style={{
                    visibility: visible ? 'visible' : 'hidden', 
                    opacity: visible ? '1' : '0'
                }}
            >
                <OEButton
                    className="transparent-btn light-bg hide-lens"
                    disabled={false}
                    activated={/*this.state.hideLensButton.activated*/false}
                    onPressed={this.onHideLensBtnPressed}
                >
                    <img src={this.state.hideLensButton.activated ? 'images/icons/glasses.png' : 'images/icons/no-glasses.png'}/>
                </OEButton>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className="overlay zeiss-opti std-label-text-color">
                    {this.renderAnimationControl()}
                    {this.renderPreviewView()}
                    {this.renderZoomView()}
                    {this.renderZoomSelection()}
                    {/*this.renderHideLensBtn()*/}
                </div>
            </React.Fragment>
        );
    }

    onAnimationControlPlay()    {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterPlayBtnPressed, null);
    }

    onAnimationControlPause()   {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterPauseBtnPressed, null);
    }

    onAnimationControlStop()    {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterStopBtnPressed, null);
    }

    onProgressSliderChanged(progress)   {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceMediaCenterProgressSliderChanged, null);
    }

    onPrevPerspectiveBtnPressed()   {
        if(!this.oe.isReady())  return;
        let newPerspective = Math.max(0, this.state.zoomView.activePerspective - 1);
        this.oe.sharedInterface.setCustomIntProperty(this.activeZoomPerspectivePropKey, newPerspective);
    }

    onNextPerspectiveBtnPressed()   {
        if(!this.oe.isReady())  return;
        let property = this.oe.sharedInterface.getCustomProperty(this.numZoomPerspectivesPropKey);
        if(!property)   return;
        let newPerspective = Math.min(property.value - 1, this.state.zoomView.activePerspective + 1);
        this.oe.sharedInterface.setCustomIntProperty(this.activeZoomPerspectivePropKey, newPerspective);
    }

    onHideLensBtnPressed()  {
        this.hideShowLens(this.state.hideLensButton.activated);
    }

    onCorrectionBtnPressed()    {
        if(!this.oe.isReady())  return;

        let animation = this.oe.sharedInterface.getUIControllerAnimation();

        let activeAnimation = animation.getActiveAnimation();

        if(activeAnimation <= 1 || activeAnimation > 4) return;

        if(animation.getAnimationMode() === this.oe.Module.AnimationMode.play) {
            animation.setAnimationMode(this.oe.Module.AnimationMode.pause);
        }

        let property = this.oe.sharedInterface.getCustomProperty(this.correctiveProgressPropKey);
        if(!property)   { return; }

        animation.setAnimationProgress(property.value);
    }

    onZoomSelectionClick(index)  {
        if(!this.oe.isReady())  return;
        let property = this.oe.sharedInterface.getCustomProperty(this.numZoomPerspectivesPropKey);
        if(!property)   return;
        let newPerspective = Math.max(0, Math.min(property.value - 1, index));
        this.oe.sharedInterface.setCustomIntProperty(this.activeZoomPerspectivePropKey, newPerspective);
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.customUIInstanceZeissOptiZoomPerspectiveSelect, null);
    }
}

OEZeissOptiOverlay.defaultProps = {
    moduleId: ''
};

OEZeissOptiOverlay.propTypes = {
    moduleId: PropTypes.string
};