export const OETarget = {
    humanEarMacro:                  'humanEarMacro',
    humanEarMacroAmboss:            'humanEarMacroAmboss',
    humanEarMicro:                  'humanEarMicro',
    skinMacroIONTO:                 'skinMacroIONTO',
    skinMicroIONTO:                 'skinMicroIONTO',
    humanEye:                       'humanEye',
    humanEyeMacro:                  'humanEyeMacro',
    humanEyeMicro:                  'humanEyeMicro',
    humanEyeCornea:                 'humanEyeCornea',
    snail:                          'snail',
    toxNetz:                        'toxNetz',
    zeissOpti:                      'zeissOpti',
    zeissOpti2:                     'zeissOpti2',
    zeissUV:                        'zeissUV',
    sensorySystems:                 'sensorySystems',
    sensorySystemsEar:              'sensorySystemsEar',
    sensorySystemsEye:              'sensorySystemsEye',
    hoofDynamics:                   'hoofDynamics',
    hoofVessels:                    'hoofVessels',
    hoofWeb:                        'hoofWeb',
    hoofMicro:                      'hoofMicro',
    hoofCarpal:                     'hoofCarpal',
    humanArm:                       'humanArm',
    humanArmPathways:               'humanArmPathways',
    humanBodyMuscles:               'humanBodyMuscles',
    humanUpperArmElbow:             'humanUpperArmElbow',
    humanForearm:                   'humanForearm',
    humanShoulder:                  'humanShoulder',
    humanElbow:                     'humanElbow',
    humanHand:                      'humanHand',
    humanLeg:                       'humanLeg',
    humanAnkleFoot:                 'humanAnkleFoot',
    humanHip2:                      'humanHip2',
    humanLowerLeg:                  'humanLowerLeg',
    humanLegPathways:               'humanLegPathways',
    humanThighKnee:                 'humanThighKnee',
    humanHip:                       'humanHip',
    humanKnee:                      'humanKnee',
    humanKneeImplantcast:           'humanKneeImplantcast',
    implantcastACSMB4in1:           'implantcastACSMB4in1',
    implantcastACSSCFB:             'implantcastACSSCFB',
    implantcastAGILONMI:            'implantcastAGILONMI',
    implantcastACSSCMB:             'implantcastACSSCMB',
    implantcastMutarsDistFemurMK:   'implantcastMutarsDistFemurMK',
    implantcastMUTARSGenuXMK:       'implantcastMUTARSGenuXMK',
    implantcastMUTARSKRIMK:         'implantcastMUTARSKRIMK',
    implantcastEcoFitHipStem133123: 'implantcastEcoFitHipStem133123',
    implantcastEcoFitHipStem:       'implantcastEcoFitHipStem',
    implantcastEcoFitShortStem:     'implantcastEcoFitShortStem',
    implantcastEcoFitCup:           'implantcastEcoFitCup',
    implantcastActiniaStem:         'implantcastActiniaStem',
    implantcastShoulderApproachAnterior: 'implantcastShoulderApproachAnterior',
    implantcastTestTarget:          'implantcastTestTarget',
    humanFoot:                      'humanFoot',
    humanTrunk:                     'humanTrunk',
    humanSkull:                     'humanSkull',
    humanCranialNerves:             'humanCranialNerves',
    humanSpine:                     'humanSpine',
    humanSpineFracture:             'humanSpineFracture',
    humanAbdominalWall:             'humanAbdominalWall',
    humanChestWall:                 'humanChestWall',
    humanNeckBack:                  'humanNeckBack',
    humanPelvis:                    'humanPelvis',
    humanShoulderGirdle:            'humanShoulderGirdle',
    humanHead:                      'humanHead',
    humanHeadWIP:                   'humanHeadWIP',
    humanEye2:                      'humanEye2',
    humanEyeRetina:                 'humanEyeRetina',
    humanEyeOCT:                    'humanEyeOCT',
    humanLarynx:                    'humanLarynx',
    humanLiverMicro:                'humanLiverMicro',
    humanHeart:                     'humanHeart',
    animalCell:                     'animalCell',
    plantCell:                      'plantCell',
    humanCellEpithelium:            'humanCellEpithelium',
    philipsIcarus:                  'philipsIcarus',
    void:                           'void',
//  @ADD_TARGET_ALL_TARGETID
    humanHeadLeFx                  : 'humanHeadLeFx',
    implantcastAidaShortStem       : 'implantcastAidaShortStem',
    humanBrainSynapse              : 'humanBrainSynapse',
    implantcastAGILONTraumalongfit : 'implantcastAGILONTraumalongfit',
    implantcastMutarsRS            : 'implantcastMutarsRS',
    implantcastDirectAnteriorApproach : 'implantcastDirectAnteriorApproach',
    implantcastEcoFitCupEPORE      : 'implantcastEcoFitCupEPORE',
    implantcastMUTARSPRS           : 'implantcastMUTARSPRS',
    implantcastAGILONOmarthrosisLongFit : 'implantcastAGILONOmarthrosisLongFit',
};

export const OEResourceTarget = Object.assign({
    zeiss:                      'zeiss',
    hoof:                       'hoof',
    cell:                       'cell',
    amboss:                     'amboss',
    ambossImport:               'ambossImport',
    anatomy:                    'anatomy',
    philipsIcarusAnatomy:       'philipsIcarusAnatomy',
    skinIONTO:                  'skinIONTO',
    humanEyeRes:                'humanEyeRes',
    implantcast:                'implantcast',

    isPureResourceTarget: function(target)  {
        if(!this.allResourceTargets)    {
            this.allResourceTargets = [
                OEResourceTarget.zeiss,
                OEResourceTarget.hoof,
                OEResourceTarget.cell,
                OEResourceTarget.amboss,
                OEResourceTarget.ambossImport,
                OEResourceTarget.anatomy,
                OEResourceTarget.philipsIcarusAnatomy,
                OEResourceTarget.skinIONTO,
                OEResourceTarget.humanEyeRes,
                OEResourceTarget.implantcast
            ];
        }

        return this.allResourceTargets.includes(target);
    },

    defaultTargetForResourceTarget: function(target)    {
        switch(target)  {
            case OEResourceTarget.zeiss: return OETarget.zeissOpti2;
            case OEResourceTarget.hoof: return OETarget.hoofWeb;
            case OEResourceTarget.cell: return OETarget.animalCell;
            case OEResourceTarget.amboss: return OETarget.humanTrunk;
            case OEResourceTarget.anatomy: return OETarget.humanSkull;
            case OEResourceTarget.philipsIcarusAnatomy: return OETarget.philipsIcarus;
            case OEResourceTarget.skinIONTO: return OETarget.skinMacroIONTO;
            case OEResourceTarget.humanEyeRes: return OETarget.humanEye;
            case OEResourceTarget.implantcast: return OETarget.humanKneeImplantcast;
        }
        return target;
    }

}, OETarget);