
export default class OETextViewAdapter {

    constructor(src) {
        let src_ = src;

        this.getSrc = function()   {
            return src_;
        };

        this.setSrc = function(src__)   {
            if(src_ === src__)  return;
            src_ = src__;
            this.onSrcChanged();
        };
    }

    onConnect(oe, controller)   {
    }

    onRelease(oe, controller)   {
    }

    onSrcChanged()  {
        if(this.controller && this.controller.onTextViewSrcChanged) this.controller.onTextViewSrcChanged(this, this.getSrc());
    }
};