import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import {OEToolbox} from '../../lib/oe-toolbox';
import {retardUpdate} from '../../lib/update-retarder';

export default class OEWidgetHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        if(this.props.moduleId) this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.uiControllerType = typeof(this.props.uiControllerType) === 'number' ? this.props.uiControllerType : null;

        this.state = {
            title: ''
        };

        this.updateLanguage = this.updateLanguage.bind(this);

        this.onToggle = this.onToggle.bind(this);
    }

    setUIControllerType(uiControllerType, props)   {
        let uiControllerType_ = uiControllerType;
        if(typeof(uiControllerType_) === 'object')    {
            uiControllerType_ = uiControllerType_ === null ? uiControllerType_ : uiControllerType_.value;
        }
        if(uiControllerType_ !== null && typeof(uiControllerType_) !== 'number')    return;
        if(this.uiControllerType === uiControllerType_) return;
        this.uiControllerType = uiControllerType_;
        this.updateTitle(props);
    }

    componentWillReceiveProps(nextProps) {
		if(nextProps.uiControllerType !== this.props.uiControllerType)     {
            this.setUIControllerType(nextProps.uiControllerType, nextProps);
        }
        if(nextProps.title !== this.props.title || nextProps.titleId !== this.props.titleId)    {
            this.updateTitle(nextProps);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.updateLanguage);
    }

    updateTitle(props)   {
        props = props || this.props;

        let titleId;
        if(!props.title && this.oe && this.oe.isReady())    {
            titleId = props.titleId ? props.titleId : (!props.title && this.uiControllerType !== null ? OEToolbox.getTitleStringIdForUIControllerType(this.uiControllerType) : undefined);
        }

        let title = titleId ? this.oe.sharedInterface.getLocalizedStringEnc(titleId) : (props.title ? props.title : '');
        this.setState({title: title});
    }

    updateLanguage()  {
        this.updateTitle();
    }

    updateState(released)   {
        if(!this.oe || !this.oe.isReady() || released === true) return;

        retardUpdate(this, () => {
            this.updateLanguage();
        });
    }

    renderHeader()  {
        let props;
        if(this.props.renderHeader || this.props.additionalButtons || this.props.centerBar)  {
            props = {...this.props};
            props.onToggle = this.onToggle;
        }

        if(this.props.renderHeader) return this.props.renderHeader(props);

        return (
            <div className="content">
                <div className="title">
                    {this.props.icon ? <OEIcon code={this.props.icon}/> : null}
                    <span className="title">{this.state.title}</span>
                </div>

                {this.props.centerBar ? <div className="center-bar">{this.props.centerBar(props)}</div> : null}

                <div className="buttons">
                    {!this.props.onHelpBtnPressed ? null :
                        <OEButton className={this.props.buttonClassName + ' help-btn'} onPressed={this.props.onHelpBtnPressed}>
                            <OEIcon code={OEIconCodes.help}/>
                        </OEButton>
                    }

                    {this.props.additionalButtons ? this.props.additionalButtons(props) : null}

                    <OEButton className={this.props.buttonClassName + ' close-btn'} onPressed={this.onToggle}>
                        <OEIcon code={OEIconCodes.close}/>
                    </OEButton>
                </div>
            </div>
        );
    }

    render()   {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'std-label-text-color widget-header ' + this.props.className}>
                    {this.renderHeader()}
                    {this.props.headerSeparator ? <div className="std-separator-border-color separator"/> : null}
                </div>
            </React.Fragment>
        );
    }

    onToggle() {
        if(this.props.onToggle)    this.props.onToggle();

        if(this.uiControllerType === null || !this.oe || !this.oe.isReady()) return;
        this.oe.sharedInterface.setUIControllerVisible(false, {value: this.uiControllerType});
    }
}

OEWidgetHeader.defaultProps = {
    className: '',
    moduleId: '',
    buttonClassName : '',
    headerSeparator: false
};

OEWidgetHeader.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    buttonClassName: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    titleId: PropTypes.string,
    headerSeparator: PropTypes.bool,
    renderHeader: PropTypes.func,
    additionalButtons: PropTypes.func,
    centerBar: PropTypes.func,
    onToggle: PropTypes.func,
    onHelpBtnPressed: PropTypes.func,
};