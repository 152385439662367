import React from 'react';
import PropTypes from 'prop-types';

import {oeStyleSheetTool} from '../lib/oe-style-sheet-tool';

export class OEBlurLayer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.blurring = false;
        this.count = this.props.apply ? 1 : 0;
        this.props_ = Object.assign({}, this.props);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.radius !== this.props.radius || nextProps.ruleName !== this.props.ruleName || nextProps.selector !== this.props.selector)     {
            this.apply(false);
            this.props_ = Object.assign({}, nextProps);
            this.update();
        } else if(nextProps.apply !== this.props.apply)     {
            this.props_ = Object.assign({}, nextProps);
            this.update();
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.update();
    }

    componentWillUnmount()    {
        this.apply(false);
        this.mounted = false;
    }

    setBlur(blur)   {
        this.count += blur ? 1 : -1;
        this.update();
    }

    resetBlur() {
        this.count = 0;
        this.update();
    }

    shouldBlur()    {
        return this.count > 0 || (typeof(this.props_.apply) === 'boolean' ? this.props_.apply : false);
    }

    update()    {
        this.apply(this.shouldBlur());
    }

    apply(blur) {
        if(!this.mounted || this.blurring === blur) return;

        let ruleName = this.props_.ruleName;

        if(!blur)   {
            oeStyleSheetTool.removeRule(ruleName, null, true);
        } else {
            let selector =  this.props_.selector;
            let rule = selector + ' { filter: blur(' + this.props_.radius.toString() + 'px); pointer-events: none; }';
            oeStyleSheetTool.insertRule(ruleName, rule);
        }

        this.blurring = blur;
    }

    render() {
        return (
            <div style={{ display: 'none' }}/>
        );
    }
}

OEBlurLayer.defaultProps = {
    apply: false,
    radius: 12,
    ruleName: 'blur-rule-name',
    selector: 'selector'
};

OEBlurLayer.propTypes = {
    apply: PropTypes.bool,
    radius: PropTypes.number,
    ruleName: PropTypes.string,
    selector: PropTypes.string
};

export class OEBlurCoreLayer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onRef = this.onRef.bind(this);
    }

    onRef(ref)  {
        this.props.appComponent.blurCoreLayer = ref;
    }

    render() {
        return (
            <OEBlurLayer
                radius={this.props.radius}
                ruleName={'blur-core-embed-id-' + this.props.moduleId}
                selector={'iframe#' + this.props.moduleId + ', #context-menu-controller'}
                ref={this.onRef}
            />
        );
    }
}

OEBlurCoreLayer.defaultProps = {
    moduleId: '',
    radius: 12
};

OEBlurCoreLayer.propTypes = {
    moduleId: PropTypes.string,
    radius: PropTypes.number
};

export class OEBlurUILayer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onRef = this.onRef.bind(this);
    }

    onRef(ref)  {
        this.props.appComponent.blurUILayer = ref;
    }

    render() {
        return (
            <OEBlurLayer
                radius={this.props.radius}
                ruleName={'blur-ui-embed-id-' + this.props.moduleId}
                selector={'div#' + this.props.moduleId + '-ui-layer'}
                ref={this.onRef}
            />
        );
    }
}

OEBlurUILayer.defaultProps = {
    moduleId: '',
    radius: 12
};

OEBlurUILayer.propTypes = {
    moduleId: PropTypes.string,
    radius: PropTypes.number
};

export class OEBlurAllLayer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onRef = this.onRef.bind(this);
    }

    onRef(ref)  {
        this.props.appComponent.blurAllLayer = ref;
    }

    render() {
        return (
            <OEBlurLayer
                radius={this.props.radius}
                ruleName={'blur-all-embed-id-' + this.props.moduleId}
                selector={'div#' + this.props.moduleId + '-container'}
                ref={this.onRef}
            />
        );
    }
}

OEBlurAllLayer.defaultProps = {
    moduleId: '',
    radius: 12
};

OEBlurAllLayer.propTypes = {
    moduleId: PropTypes.string,
    radius: PropTypes.number
};

export function OEBlurModalBackdropLayer(props) {
    return (
        <OEBlurLayer apply={props.apply} radius={props.radius} ruleName={'blur-modal-backdrop'} selector={'body > div:not(.oe-modal-root)'}/>
    );
}

OEBlurModalBackdropLayer.defaultProps = {
    apply: true,
    radius: 12
};

OEBlurModalBackdropLayer.propTypes = {
    apply: PropTypes.bool,
    radius: PropTypes.number
};

export class OEBlur extends React.PureComponent {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.applied = false;

        this.type = this.props.type;
        this.doApply = this.props.doApply;
    }

    blurLayer() {
        if(!this.props.appComponent) return;
        let type = this.type;
        return type === OEBlur.types.core ? this.props.appComponent.blurCoreLayer : (type === OEBlur.types.ui ? this.props.appComponent.blurUILayer : this.props.appComponent.blurAllLayer);
    }

    apply(blur_)    {
        let blur = typeof(blur_) === 'boolean' ? blur_ : this.shouldBlur();
        if(!this.mounted || this.applied === blur) return;
        let layer = this.blurLayer();
        if(!layer) return;
        this.applied = blur;
        layer.setBlur(blur);
    }

    shouldBlur()    {
        return this.mounted && this.doApply;
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.type !== this.props.type || nextProps.doApply !== this.props.doApply)     {
            this.apply(false);
            this.type = this.props.type;
            this.doApply = this.props.doApply;
            this.apply();
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.apply();
    }

    componentWillUnmount()    {
        this.apply(false);
        this.mounted = false;
    }

    render() {
        return (
            <div style={{ display: 'none' }}/>
        );
    }
}

OEBlur.types = {
    core: 'core',
    ui: 'ui',
    all: 'all'
};

OEBlur.defaultProps = {
    type: OEBlur.types.core,
    doApply: true
};

OEBlur.propTypes = {
    type: PropTypes.string,
    doApply: PropTypes.bool
};