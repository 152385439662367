import React from 'react';
import PropTypes from 'prop-types';

import {OEToolbox} from './oe-toolbox';

export class OENavigationController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.counter = 0;
        this.title = '';

        this.components = new Map();

        this.stack = [];
        this.state = {stack: this.stack.slice()};
    }

    setStateUpdate(spec)   {
        OEToolbox.updateComponentState(this, spec);
    }

    register(component, props)  {
        const id = this.counter++;
        this.components.set(id, component);

        if(!component.props.deferred) {
            this.push(id, undefined, props);
        }

        return id;
    }

    unregister(id)  {
        this.close(id);
        this.components.delete(id);
    }

    idForCompId(compId) {
        if(typeof(compId) !== 'string') return -1;
        for(const it of this.components.entries())  {
            if(it[1].props.compId === compId)   return it[0];
        }
        return -1;
    }

    compIdFromId(id)   {
        if(typeof(id) !== 'number' || id < 0) return;
        let component = this.components.get(id);
        if(!component)  return;
        return component.props.compId;
    }

    translateId(id) {
        if(typeof(id) === 'number') return id;
        if(typeof(id) === 'string') return this.idForCompId(id);
        return -1;
    }

    stackPos(id)    {
        id = this.translateId(id);
        if(id < 0)  return -1;
        return this.stack.findIndex(entry => entry.id === id);
    }

    isOnStack(id)   {
        return this.stackPos(id) >= 0;
    }

    includes(id)    {
        return this.isOnStack(id);
    }

    topId()    {
        return this.stack.length ? this.stack[this.stack.length - 1].id : -1;
    }

    topCompId()    {
        return this.compIdFromId(this.topId());
    }

    isTopId(id) {
        id = this.translateId(id);
        let topId = this.topId();
        return topId >= 0 && id >= 0 && id === topId;
    }

    top()   {
        if(this.stack.length)   return this.stack[this.stack.length - 1];
    }

    stackSize() {
        return this.stack.length;
    }

    push(view, id, props) {
        if(typeof(view) === 'string')   {
            let compId = view;
            id = this.idForCompId(compId);
            if(id < 0) return;
            return this.push(id);
        }

        //
        if(typeof(view) === 'number')   {
            id = view;
            let component = this.components.get(id);
            if(!component)  return;
            return this.push(component.createInstance(props)(id), id);
        }

        //
        id = typeof(id) === 'number' ? id : this.counter++;

        //
        if(typeof(view) === 'function') return this.push(view(id), id);

        if(this.isOnStack(id))  {
            this.stack = this.stack.filter(entry => entry.id !== id);
        }

        this.stack.push({id: id, view: view, title: view.props.title});
		this.setState({stack: this.stack.slice()});
        
        this.onStackChanged();
        this.onStackTopChanged();

        return id;
    }

    update(id, props)	{
        let stackIndex = this.stackPos(id);
        if(stackIndex < 0)  return;

        let component = this.components.get(id);
        if(!component)  return;

        let isTopId = this.isTopId(id);
        
        let view = component.createInstance(props)(id);
        this.stack[stackIndex] = {id: id, view: view, title: view.props.title};

        this.setState({stack: this.stack.slice()});

        if(isTopId && view.props.title !== this.title) {
            this.title = view.props.title;
            if(this.props.onTitleChanged)    this.props.onTitleChanged(this, this.title);
        }
    }

    close(id)  {
        id = this.translateId(id);

        let stackIndex = this.stackPos(id);
        if(stackIndex < 0)  return;

        let isTopId = this.isTopId(id);

        this.stack.splice(stackIndex, 1);

        this.setState({stack: this.stack.slice()});

        this.onStackChanged();
        if(isTopId) this.onStackTopChanged();
    }

    pop(id)   {
        if(typeof(id) === 'undefined')  {
            id = this.topId();
        } else {
            id = this.translateId(id);
        }

        let stackIndex = this.stackPos(id);
        if(stackIndex < 0)  return;

        this.stack.splice(stackIndex, this.stack.length - stackIndex);

        this.setState({stack: this.stack.slice()});

        this.onStackChanged();
        this.onStackTopChanged();
    }

    onStackTopChanged() {
        let top = this.top();
        let title = top ? top.title : '';

        if(title !== this.title) {
            this.title = title;
            if(this.props.onTitleChanged)    this.props.onTitleChanged(this, title);
        }

        if(this.props.onStackTopChanged)    this.props.onStackTopChanged(this);
    }

    onStackChanged()    {
        if(this.props.onStackChanged)    this.props.onStackChanged(this, this.stackSize());
    }

    render() {
        let viewElements = this.state.stack.map((entry, index) =>
            React.cloneElement(entry.view, {key: entry.id, onTop: index === this.state.stack.length - 1})
        );

        return (
            <div className={'navigation-controller ' + this.props.className}>
                {viewElements}
            </div>
        );
    }
}

OENavigationController.defaultProps = {
	className: ''
};

OENavigationController.propTypes = {
	className: PropTypes.string,
    onTitleChanged: PropTypes.func,
    onStackTopChanged: PropTypes.func
};

export class OENavigationView extends React.PureComponent {
    render() {
        return (
            <div className={'navigation-view ' + (this.props.onTop ? 'on-top ' : 'not-on-top ') + this.props.className}>
                {this.props.children}
            </div>
        );
    }
}

OENavigationView.defaultProps = {
    className: '',
    title: ''
};

OENavigationView.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export class OENavigationViewComponent extends React.Component {

    constructor(props) {
        super(props);

        this.id = null;

        this.register();
    }

    register(props)  {
        if(this.id !== null)    return;
        props = props || this.props;
        if(!props.navigationController) return;
        this.id = props.navigationController.register(this, props);
    }

    unregister(props)    {
        if(this.id === null)    return;
        props = props || this.props;
        if(!props.navigationController) return;
        props.navigationController.unregister(this.id);
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.navigationController !== nextProps.navigationController)  {
            this.unregister();
            this.register(nextProps);
            return;
        } else if(this.props.navigationController && this.id !== null) {
            this.props.navigationController.update(this.id, nextProps);
        }
    }

    componentWillUnmount() {
        if(!this.props.navigationController || this.id === null) return;
        
        this.props.navigationController.unregister(this.id);
        this.id = null;
    }

    createInstance(props)	{
        props = props || this.props;

        const {navigationController, ref, children, ...rest} = props;

        let childrenArray = React.Children.toArray(children);

        let childrenElements = childrenArray.map(child => React.cloneElement(child, {navigationController: navigationController}));
        
        return ((id) =>
            <OENavigationView {...rest} id={id}>
                {childrenElements}
            </OENavigationView>
        );
    }

    render() {
        return null;
    }
}

OENavigationViewComponent.defaultProps = {
    className: '',
    title: '',
    deferred: false
};

OENavigationViewComponent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    compId: PropTypes.string,
    deferred: PropTypes.bool
};

