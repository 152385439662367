import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../../react-oe/oe-interface-adapter';
import {retardUpdate} from '../../../../lib/update-retarder';
import {OEIconCodes} from '../../../../lib/oe-icon-codes';
import OEIcon from '../../../elements/oe-icon';
import OEButton, {OEIconButton} from '../../../elements/oe-button';
import {OETargetMenu, OETargetConnected} from '../../../elements/target-menu/oe-target-menu';
import OETargetMenuDef from '../../../elements/target-menu/oe-target-menu';
import OEResizeObserver from '../../../../lib/oe-resize-observer';
import {OEToolbox} from '../../../../lib/oe-toolbox';

export default class OEStdCapBar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.connectFlag = false;

        this.layoutType = {
            min: 'min',
            singleRow: 'single-row',
            twoRow: 'two-row',
            threeRow: 'three-row'
        };

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            layoutType: this.layoutType.min,
        };

        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);
        
        this.onWindowResized = this.onWindowResized.bind(this);
        this.onTargetTitleResize = this.onTargetTitleResize.bind(this);
        this.onTargetLabelResize = this.onTargetLabelResize.bind(this);
        this.onTargetMenuResize = this.onTargetMenuResize.bind(this);
        this.onTargetBtnBarContentResize = this.onTargetBtnBarContentResize.bind(this);
        this.onTargetMenuRef = this.onTargetMenuRef.bind(this);
        this.onMiddleRef = this.onMiddleRef.bind(this);
        this.onTargetBtnBarContentRef = this.onTargetBtnBarContentRef.bind(this);

        this.onMainMenuControlBtnPressed = this.onMainMenuControlBtnPressed.bind(this);
        this.onTargetMenuToggle = this.onTargetMenuToggle.bind(this);
        this.onFullscreenBtnPressed = this.onFullscreenBtnPressed.bind(this);
    }

    componentDidMount()    {
        this.onWindowResized();
        window.addEventListener('resize', this.onWindowResized);
    }

    componentWillUnmount()    {
        window.removeEventListener('resize', this.onWindowResized);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }

    updateLayout()  {
        if(!this.connectFlag)   return;
        
        if(!this.middleRef || !this.targetBtnBarContentRef) {
            this.setState({layoutType: this.layoutType.singleRow});
            return;
        }

        if(!this.targetTitleSize)   this.targetTitleSize = {w: 0, h: 0};
        if(!this.targetLabelSize)   this.targetLabelSize = {w: 0, h: 0};
        if(!this.targetMenuSize)   this.targetMenuSize = {w: 0, h: 0};

        const middleWidth = $(this.middleRef).outerWidth();
        const targetBtnBarContentWidth = $(this.targetBtnBarContentRef).outerWidth();
        const targetBarContentWidth = this.targetTitleSize.w + this.targetLabelSize.w + this.targetMenuSize.w;
        const targetTitlePaddingComp = {
            multiSingle: this.state.layoutType === this.layoutType.singleRow ? 0 : 24,
            singleMulti: this.state.layoutType === this.layoutType.singleRow ? -24 : 0
        };

        let layoutType = this.layoutType.singleRow;
        if(targetBarContentWidth - targetTitlePaddingComp.singleMulti > middleWidth - 16) {
            layoutType = this.layoutType.threeRow;
        } else if(targetBarContentWidth + targetTitlePaddingComp.multiSingle + 4 >= 0.5 * (middleWidth - targetBtnBarContentWidth))  {
            layoutType = this.layoutType.twoRow;
        }

        //console.log('layoutType: ' + layoutType);
        this.setState({layoutType: layoutType});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.compControllerWasVisible = false;
            return;
        }

        this.connectFlag = true;

        this.compControllerWasVisible = false;
        
        retardUpdate(this, () => {
            this.updateLayout();
        });
    }

    onUIControllerStateChanged(message, userInfo)   {
        if(userInfo.type === this.oe.Module.UIControllerType.component && userInfo.visible) {
            this.compControllerWasVisible = false;
            if(this.targetMenuRef) this.targetMenuRef.setOpen(false);
        }
    }

    onWindowResized()   {
        this.updateLayout();
    }

    onTargetTitleResize(sender, size)  {
        if(OEToolbox.jsonEqual(this.targetTitleSize, size)) return;
        this.targetTitleSize = size;
        this.updateLayout();
    }

    onTargetLabelResize(sender, size)  {
        if(OEToolbox.jsonEqual(this.targetLabelSize, size)) return;
        this.targetLabelSize = size;
        this.updateLayout();
    }

    onTargetMenuResize(sender, size)  {
        if(OEToolbox.jsonEqual(this.targetMenuSize, size)) return;
        this.targetMenuSize = size;
        this.updateLayout();
    }

    onTargetBtnBarContentResize(sender, size)   {
        this.setState({targetBtnBarWidth: size.w});
    }

    onTargetMenuRef(ref)    {
        this.targetMenuRef = ref;
    }

    onMiddleRef(ref)   {
        if(this.middleRef === ref) return;
        this.middleRef = ref;
        this.updateLayout();
    }

    onTargetBtnBarContentRef(ref) {
        if(this.targetBtnBarContentRef === ref) return;
        this.targetBtnBarContentRef = ref;
        this.updateLayout();
    }

    renderTargetMenu()  {
        if(this.props.targetMenuHidden) return null;
        return this.props.targetMenuEntries ?
            <OETargetConnected
                moduleId={this.props.moduleId}
                disabled={this.props.disabled || this.props.targetMenuDisabled}
                fadeInOut={false}
                entries={this.props.targetMenuEntries}
                onToggle={this.onTargetMenuToggle}
                ref={this.onTargetMenuRef}
            /> :
            <OETargetMenuDef
                moduleId={this.props.moduleId}
                disabled={this.props.disabled || this.props.targetMenuDisabled}
                fadeInOut={false}
                onToggle={this.onTargetMenuToggle}
                ref={this.onTargetMenuRef}
            />;
    }

    renderTargetBar()   {
        const layoutType = this.state.layoutType === this.layoutType.min ? this.layoutType.singleRow : this.state.layoutType;
        const targetBarStyle = layoutType === this.layoutType.singleRow && this.state.targetBtnBarWidth ? {width: 'calc(50% - ' + (0.5 * this.state.targetBtnBarWidth).toString() + 'px)'} : null;
        const hasTitle = this.props.targetBarTitle && this.props.targetBarTitle.length;
        const hasLabel = this.props.targetBarLabel && this.props.targetBarLabel.length;
        const hasButton = !!this.props.targetBarButton;

        if(this.state.layoutType === this.layoutType.min)   {
            return (
                <div className="target-bar" style={targetBarStyle}>
                    <div className="light-bg-border spacer-flex"/>
                </div>
            );
        }

        return (
            <div className={'target-bar' + (hasTitle ? ' has-title' : '') + (hasLabel ? ' has-label' : '') + (hasButton ? ' has-button' : '')} style={targetBarStyle}>

                {layoutType === this.layoutType.twoRow && hasTitle ? <div className="light-bg-border spacer-flex"/> : null} {/*with out target title the target menu should be aligned to the left for two row layout*/}

                <div className="content">

                    {!(hasTitle || (!hasLabel && hasButton)) ? null :
                        <div className="target-title-container">

                            {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}

                            <div>
                                <OEResizeObserver onResize={this.onTargetTitleResize} />

                                <span className="light-bg-border target-title">
                                    {this.props.targetBarTitle}
                                </span>

                                {!this.props.targetBarButton || hasLabel ? null :
                                    <OEIconButton
                                        {...this.props.targetBarButton}
                                        className={'btn light-hover-bg-border target-bar-btn ' + (this.props.targetBarButton.className ? this.props.targetBarButton.className : '')}
                                        disabled={this.props.disabled || this.props.targetBarButton.disabled}
                                    />
                                }
                            </div>

                            {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}

                        </div>
                    }

                    {hasLabel ?
                        <div className="target-label-container">

                            {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}

                            <div>
                                <OEResizeObserver onResize={this.onTargetLabelResize} />

                                <span className="light-bg-border target-label">
                                    {this.props.targetBarLabel}
                                </span>

                                {!this.props.targetBarButton ? null :
                                    <OEIconButton
                                        {...this.props.targetBarButton}
                                        className={'btn light-hover-bg-border target-bar-btn ' + (this.props.targetBarButton.className ? this.props.targetBarButton.className : '')}
                                        disabled={this.props.disabled || this.props.targetBarButton.disabled}
                                    />
                                }
                            </div>

                            {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}

                        </div>
                    :
                        <div className="target-menu-container">

                            {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}

                            <div>
                                <OEResizeObserver onResize={this.onTargetMenuResize} />
                                {this.renderTargetMenu()}
                            </div>

                            {layoutType === this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}

                        </div>
                    }

                </div>

                {layoutType !== this.layoutType.threeRow ? <div className="light-bg-border spacer-flex"/> : null}

            </div>
        );
    }

    renderTargetButtonBar() {
        const layoutType = this.state.layoutType === this.layoutType.min ? this.layoutType.singleRow : this.state.layoutType;
        const targetBtnBarStyle = layoutType === this.layoutType.singleRow && this.state.targetBtnBarWidth ? {left: 'calc(50% - ' + (0.5 * this.state.targetBtnBarWidth).toString() + 'px)'} : null;

        const buttons = !this.props.targetButtonBar ? null : this.props.targetButtonBar.map((btn, i) => {
                let nextBtn = i + 1 < this.props.targetButtonBar.length ? this.props.targetButtonBar[i + 1] : null;

                return (
                    <React.Fragment key={2*i}>
                        <OEButton
                            key={2*i}
                            className={'light-hover-bg-border ' + (btn.label ? 'str' : '')}
                            disabled={btn.disabled || this.props.disabled}
                            activated={btn.activated}
                            userData={btn.userData}
                            tooltip={btn.tooltip}
                            disabledTooltip={btn.disabledTooltip}
                            tooltipPlacement="bottom"
                            onPressed={btn.onPressed}
                        >
                            {btn.label ? btn.label : <OEIcon code={btn.icon} />}
                        </OEButton>
                        {btn.label && nextBtn && nextBtn.label && i < this.props.targetButtonBar.length - 1 ? <div key={2*i + 1} className="light-bg-border spacer"/> : null}
                    </React.Fragment>
                );
            }
        );

        return (
            <div className="target-btn-bar" style={targetBtnBarStyle}>

                {layoutType !== this.layoutType.singleRow ? <div className="light-bg-border spacer-flex"/> : null}

                <div className="content" ref={this.onTargetBtnBarContentRef}>

                    <OEResizeObserver onResize={this.onTargetBtnBarContentResize} />

                    {buttons}

                </div>

                <div className="light-bg-border spacer-flex"/>

            </div>
        );
    }

    render() {
        const layoutType = this.state.layoutType === this.layoutType.min ? this.layoutType.singleRow : this.state.layoutType;
        
        const fullscreenBtn = !this.props.showFullscreenBtn ? null :
            <OEButton className="btn light-hover-bg-border" onPressed={this.onFullscreenBtnPressed}>
                <OEIcon code={this.props.isFullscreen ? OEIconCodes.compress : OEIconCodes.expand} />
            </OEButton>;

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'std-label-text-color std-cap-bar ' + this.props.className + ((layoutType === this.layoutType.singleRow ? ' ' : ' multi-row ') + layoutType)}>
                    
                    <div className="left">
                        <OEButton className="btn light-hover-bg-border" onPressed={this.onMainMenuControlBtnPressed}>
                            <OEIcon code={OEIconCodes.mainMenu} />
                        </OEButton>
                        <div className="light-bg-border spacer-flex-v"/>
                    </div>

                    <div className="middle" ref={this.onMiddleRef}>
                        {this.renderTargetBar()}
                        {this.renderTargetButtonBar()}
                    </div>

                    <div className="right">
                        {fullscreenBtn}
                        {fullscreenBtn ? <div className="light-bg-border spacer-flex-v"/> : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onMainMenuControlBtnPressed()   {
        if(!this.props.appComponent)    return;
        this.props.appComponent.uiLayer.onMainMenuControlBtnPressed();
    }

    onTargetMenuToggle(isOpen)    {
        if(!this.oe.isReady()) return;
        if(!isOpen) {   // going to open
            this.compControllerWasVisible = this.oe.sharedInterface.getUIControllerVisible(this.oe.Module.UIControllerType.component);
            this.oe.sharedInterface.setUIControllerVisible(false, this.oe.Module.UIControllerType.component);
        } else {    // going to close
            if(this.compControllerWasVisible)   this.oe.sharedInterface.setUIControllerVisible(true, this.oe.Module.UIControllerType.component);
            this.compControllerWasVisible = false;
        }
    }

    onFullscreenBtnPressed()    {
        if(!this.props.appComponent)    return;
        this.props.appComponent.app.setFullscreen(!this.props.isFullscreen);
    }
}

OEStdCapBar.defaultProps = {
    className: '',
    moduleId: '',
    showFullscreenBtn: true,
    isFullscreen: false,
    targetButtonBar: [
        {icon: OEIconCodes.zeissOpti.anatomyTarget, disabled: false, activated: false},
        {icon: OEIconCodes.zeissOpti.optiTarget, disabled: false, activated: false},
        {icon: OEIconCodes.zeissOpti.UVTarget, disabled: false, activated: false}
    ]
};

OEStdCapBar.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    disabled: PropTypes.bool,
    showFullscreenBtn: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    appComponent: PropTypes.object,
    targetBarTitle: PropTypes.string,
    targetBarLabel: PropTypes.string,
    targetBarButton: PropTypes.shape({
        className: PropTypes.string,
        disabled: PropTypes.bool,
        activated: PropTypes.bool,
        onPressed: PropTypes.func,
        icon: PropTypes.string
    }),
    targetButtonBar: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        activated: PropTypes.bool,
        onPressed: PropTypes.func,
        userData: PropTypes.any,
        tooltip: PropTypes.string,
        disabledTooltip: PropTypes.string,
    })),
    targetMenuEntries: OETargetMenu.propTypes.entries,
    targetMenuDisabled: PropTypes.bool,
    targetMenuHidden: PropTypes.bool
};