import ReactHtmlParser from 'react-html-parser';
import update from 'immutability-helper';

import {UIControllerType} from './oe-types';
import {OETarget} from '../react-oe/oe-target';
import {OEProductId} from '../react-oe/oe-product-id';

export const OEToolbox = new class {

    constructor() {
        // somehow browser detection with bowser may extremly slow down chrome, hence we hold a static variable
        this.isChrome = bowser.chrome;
        this.isFirefox = bowser.firefox;
        this.isSafari = bowser.safari;
        this.isMsIE = bowser.msie;
        this.isMsEdge = bowser.msedge;
    }
    
    encode_utf8(s)  {
        // new emscripten version changes string conversation between c++ and js -> no reencoding necessary
        return s; //unescape(encodeURIComponent(s));
    }
    
    decode_utf8(s)  {
        // see encode_utf8
        return s; //decodeURIComponent(escape(s));
    }

    stringToHtml(s)  {
        var mask = '(\n|\r|\r\n|\\\\n)';
        var re = new RegExp(mask,'g');
        var transformed = s.replace(re, '<br/>');

        mask = '(&)';
        re = new RegExp(mask,'g');
        transformed = transformed.replace(re, '&amp;');

        return ReactHtmlParser(transformed);
    }

    updateComponentState(component, spec)  {
        component.setState((prevState, props) => { return update(prevState, spec); });
    }

    toBool(str) {
        if(typeof(str) === 'boolean') return str;
        if(str === null) return null;
        return str === 'true' ? true : (str === 'false' ? false : undefined);
    }

    toInt(str) {
        if(typeof(str) === 'number') return str;
        if(str === null) return null;
        if(typeof(str) !== 'string') return undefined;
        let res = Number.parseInt(str);
        return Number.isNaN(res) ? undefined : res;
    }

    toFloat(str) {
        if(typeof(str) === 'number') return str;
        if(str === null) return null;
        if(typeof(str) !== 'string') return undefined;
        let res = Number.parseFloat(str);
        return Number.isNaN(res) ? undefined : res;
    }

    getUrlParameter(sParam, defaultValue) {
        let pageURL = decodeURIComponent(window.location.search.substring(1));
        let URLVariables = pageURL.split('&');

        for(var i = 0; i < URLVariables.length; i++) {
            var sParameterName = URLVariables[i].split('=');
            if(sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
        return defaultValue;
    }

    getUrlParameterBool(sParam, defaultValue) {
        return this.toBool(this.getUrlParameter(sParam, defaultValue));
    }

    getUrlParameterInt(sParam, defaultValue) {
        return this.toInt(this.getUrlParameter(sParam, defaultValue));
    }

    getUrlParameterFloat(sParam, defaultValue) {
        return this.toFloat(this.getUrlParameter(sParam, defaultValue));
    }

    targetForProductId(id)   {
        const tgt = OETarget;
        var target = tgt.humanSkull;

        switch(id)  {
            case OEProductId.humanEye: target = tgt.humanEyeMacro; break;
            case OEProductId.humanSkull: target = tgt.humanSkull; break;
            case OEProductId.humanCranialNerves: target = tgt.humanCranialNerves; break;
            case OEProductId.humanSkin: target = tgt.skinMacroIONTO; break;
            case OEProductId.humanEar: target = tgt.humanEarMacro; break;
            case OEProductId.equineHoof: target = tgt.hoofWeb; break;
            case OEProductId.equineHead: target = tgt.void; break;
            case OEProductId.mouseBrain: target = tgt.void; break;
            case OEProductId.combustionEngine: target = tgt.void; break;
            case OEProductId.snail: target = tgt.snail; break;
            case OEProductId.toxNetz: target = tgt.toxNetz; break;
            case OEProductId.zeissOpti: target = tgt.zeissOpti2; break;
            case OEProductId.zeissUV: target = tgt.zeissUV; break;
            case OEProductId.sensorySystems: target = tgt.sensorySystems; break;
            case OEProductId.humanArm: target = tgt.humanArm; break;
            case OEProductId.humanArmPathways: target = tgt.humanArmPathways; break;
            case OEProductId.humanBodyMuscles: target = tgt.humanBodyMuscles; break;
            case OEProductId.humanUpperArmElbow: target = tgt.humanUpperArmElbow; break;
            case OEProductId.humanForearm: target = tgt.humanForearm; break;
            case OEProductId.humanShoulder: target = tgt.humanShoulder; break;
            case OEProductId.humanElbow: target = tgt.humanElbow; break;
            case OEProductId.humanHand: target = tgt.humanHand; break;
            case OEProductId.humanLeg: target = tgt.humanLeg; break;
            case OEProductId.humanAnkleFoot: target = tgt.humanAnkleFoot; break;
            case OEProductId.humanHip2: target = tgt.humanHip2; break;
            case OEProductId.humanLegPathways: target = tgt.humanLegPathways; break;
            case OEProductId.humanLowerLeg: target = tgt.humanLowerLeg; break;
            case OEProductId.humanThighKnee: target = tgt.humanThighKnee; break;
            case OEProductId.humanHip: target = tgt.humanHip; break;
            case OEProductId.humanKnee: target = tgt.humanKnee; break;
            case OEProductId.humanKneeImplantcast: target = tgt.humanKneeImplantcast; break;
            case OEProductId.implantcastACSMB4in1: target = tgt.implantcastACSMB4in1; break;
            case OEProductId.implantcastACSSCFB: target = tgt.implantcastACSSCFB; break;
            case OEProductId.implantcastAGILONMI: target = tgt.implantcastAGILONMI; break;
            case OEProductId.implantcastACSSCMB: target = tgt.implantcastACSSCMB; break;
            case OEProductId.implantcastMutarsDistFemurMK: target = tgt.implantcastMutarsDistFemurMK; break;
            case OEProductId.implantcastMUTARSGenuXMK: target = tgt.implantcastMUTARSGenuXMK; break;
            case OEProductId.implantcastMUTARSKRIMK: target = tgt.implantcastMUTARSKRIMK; break;
            case OEProductId.implantcastEcoFitHipStem133123: target = tgt.implantcastEcoFitHipStem133123; break;
            case OEProductId.implantcastEcoFitHipStem: target = tgt.implantcastEcoFitHipStem; break;
            case OEProductId.implantcastEcoFitShortStem: target = tgt.implantcastEcoFitShortStem; break;
            case OEProductId.implantcastEcoFitCup: target = tgt.implantcastEcoFitCup; break;
            case OEProductId.implantcastActiniaStem: target = tgt.implantcastActiniaStem; break;
            case OEProductId.implantcastShoulderApproachAnterior: target = tgt.implantcastShoulderApproachAnterior; break;
            case OEProductId.implantcastTestTarget: target = tgt.implantcastTestTarget; break;
            case OEProductId.humanFoot: target = tgt.humanFoot; break;
            case OEProductId.humanTrunk: target = tgt.humanTrunk; break;
            case OEProductId.humanSpine: target = tgt.humanSpine; break;
            case OEProductId.humanSpineFracture: target = tgt.humanSpineFracture; break;
            case OEProductId.humanAbdominalWall: target = tgt.humanAbdominalWall; break;
            case OEProductId.humanChestWall: target = tgt.humanChestWall; break;
            case OEProductId.humanPelvis: target = tgt.humanPelvis; break;
            case OEProductId.humanNeckBack: target = tgt.humanNeckBack; break;
            case OEProductId.humanShoulderGirdle: target = tgt.humanShoulderGirdle; break;
            case OEProductId.humanHead: target = tgt.humanHead; break;
            case OEProductId.humanHeadWIP: target = tgt.humanHeadWIP; break;
            case OEProductId.humanLarynx: target = tgt.humanLarynx; break;
            case OEProductId.humanEye2: target = tgt.humanEye2; break;
            case OEProductId.humanEyeRetina: target = tgt.humanEyeRetina; break;
            case OEProductId.humanEyeOCT: target = tgt.humanEyeOCT; break;
            case OEProductId.humanLiverMicro: target = tgt.humanLiverMicro; break;
            case OEProductId.humanHeart: target = tgt.humanHeart; break;
            case OEProductId.animalCell: target = tgt.animalCell; break;
            case OEProductId.plantCell: target = tgt.plantCell; break;
            case OEProductId.humanCellEpithelium: target = tgt.humanCellEpithelium; break;
            case OEProductId.philipsIcarus: target = tgt.philipsIcarus; break;
// @ADD_TARGET_ALL_PRODUCTID
            case OEProductId.humanHeadLeFx: target = tgt.humanHeadLeFx; break;
            case OEProductId.implantcastAidaShortStem: target = tgt.implantcastAidaShortStem; break;
            case OEProductId.humanBrainSynapse: target = tgt.humanBrainSynapse; break;
            case OEProductId.implantcastAGILONTraumalongfit: target = tgt.implantcastAGILONTraumalongfit; break;
            case OEProductId.implantcastMutarsRS: target = tgt.implantcastMutarsRS; break;
            case OEProductId.implantcastDirectAnteriorApproach: target = tgt.implantcastDirectAnteriorApproach; break;
            case OEProductId.implantcastEcoFitCupEPORE: target = tgt.implantcastEcoFitCupEPORE; break;
            case OEProductId.implantcastMUTARSPRS: target = tgt.implantcastMUTARSPRS; break;
            case OEProductId.implantcastAGILONOmarthrosisLongFit: target = tgt.implantcastAGILONOmarthrosisLongFit; break;
        }

        return target;
    }

    jsonEqual(a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    shallowEqual(a, b, recursive)  {
        if(typeof(a) !== 'object' || typeof(b) !== 'object' || Array.isArray(a) || Array.isArray(b))    {
            if(Array.isArray(a) && Array.isArray(b))    return a.length === b.length && a.every((e, index) => this.shallowEqual(e, b[index], recursive));
            return a === b;
        }
        
        if(a === b || (!a && !b)) return true;
        if(!a || !b) return false;
        if(Object.keys(a).length != Object.keys(b).length)  return false;

        if(recursive == true)  {
            for(var key in a)   if(!(key in b) || !this.shallowEqual(a[key], b[key], true)) return false;
        } else {
            for(var key in a)   if(!(key in b) || a[key] !== b[key])    return false;
        }

        return true;
    }

    arrayShallowEqual(a, b, recursive) {
        if((a === null && b === null) || (a === undefined && b === undefined)) return true;
        if(!Array.isArray(a) || !Array.isArray(b) || a.length !== b.length) return false;

        for(let i = 0; i < a.length; ++i)   {
            if(!this.shallowEqual(a[i], b[i], recursive)) return false;
        }

        return true;
    }

    numberToString(num, length) {
        var r = num.toString();
        while(r.length < length) {
            r = '0' + r;
        }
        return r;
    }

    bubbleIframeMouseEvents(iframe) {
        if(!iframe || iframe.contentWindow.isBubblingMouseEvents) return;

        function constructEventHandler(type, eventType)  {
            return function(e)  {
                let evt = document.createEvent(eventType);
                let boundingClientRect = iframe.getBoundingClientRect();
                //console.log('mouse event - ' + type);
                
                evt.initMouseEvent( 
                    type, 
                    true, // bubbles
                    false, // not cancelable 
                    window,
                    e.detail,
                    e.screenX,
                    e.screenY, 
                    e.clientX + boundingClientRect.left, 
                    e.clientY + boundingClientRect.top, 
                    e.ctrlKey, 
                    e.altKey,
                    e.shiftKey, 
                    e.metaKey,
                    e.button, 
                    e.relatedTarget
                );
                
                iframe.dispatchEvent(evt);
            };
        };

        iframe.contentWindow.isBubblingMouseEvents = true;

        const listener = ['click', 'dblclick', 'mousedown', 'mouseup', 'mouseover', 'mousemove', 'mouseout', 'mouseenter', 'mouseleave'];
        if(!iframe._bubbleIframeMouseEventsHandlers) iframe._bubbleIframeMouseEventsHandlers = {};

        for(let i = 0; i < listener.length; ++i)    {
            let type = listener[i];
            if(iframe._bubbleIframeMouseEventsHandlers[type]) iframe.contentWindow.removeEventListener(type, iframe._bubbleIframeMouseEventsHandlers[type]);
            iframe._bubbleIframeMouseEventsHandlers[type] = constructEventHandler(type, 'MouseEvents');
            iframe.contentWindow.addEventListener(type, iframe._bubbleIframeMouseEventsHandlers[type]);
        }
    }

    routeMouseEvents(src, options)  {
        if(!src) return;
        
        function constructEventHandler(type, eventType)  {
            return function(e){
    
                var opt = options || {};

                var clientShift = {x: 0, y: 0};

                var dst = opt.dst;

                if(!dst)    {

                    var savedStyle;

                    if(opt.hideElement) {
                        var savedStyle = opt.hideElement.style.display;
                        opt.hideElement.style.display = 'none';
                    }

                    dst = document.elementFromPoint(e.clientX, e.clientY);
                    if(!dst)    return;

                    if(dst.tagName.toLowerCase() === 'iframe')  {
                        //console.log('dst is iframe');

                        var iframe = dst;
                        var boundingClientRect = iframe.getBoundingClientRect();
                        clientShift = {x: -boundingClientRect.left, y: -boundingClientRect.top};
                        dst =  iframe.contentWindow.document.elementFromPoint(e.clientX + clientShift.x, e.clientY + clientShift.y);

                        if(!dst)    {
                            dst = iframe.contentWindow;
                        }
                    }

                    if(opt.hideElement) {
                        opt.hideElement.style.display = savedStyle;
                    }
                }

                var mouseEventInit = {
                    bubbles: true, // bubbles
                    cancelable: false, // not cancelable 
                    view: e.view,
                    detail: e.detail,
                    screenX: e.screenX,
                    screenY: e.screenY, 
                    clientX: e.clientX + clientShift.x, 
                    clientY: e.clientY + clientShift.y, 
                    ctrlKey: e.ctrlKey, 
                    altKey: e.altKey,
                    shiftKey: e.shiftKey, 
                    metaKey: e.metaKey,
                    button: e.button, 
                    relatedTarget: e.relatedTarget
                };

                var evt = null;

                if(eventType === 'MouseEvents')  {
                    evt = new MouseEvent(type, mouseEventInit);
                } else if(eventType === 'WheelEvent')   {
                    mouseEventInit.modifiersListArg = e.modifiersListArg;
                    mouseEventInit.deltaX = e.deltaX;
                    mouseEventInit.deltaY = e.deltaY;
                    mouseEventInit.deltaZ = e.deltaZ;
                    mouseEventInit.deltaMode = e.deltaMode;
                    evt = new WheelEvent(type, mouseEventInit);
                }
                
                dst.dispatchEvent(evt);
            };
        };

        function addEventHandler(type, eventType)  {
            src.addEventListener(type, constructEventHandler(type, eventType));
        };

        addEventHandler('click', 'MouseEvents');
        addEventHandler('dblclick', 'MouseEvents');
        addEventHandler('mousedown', 'MouseEvents');
        addEventHandler('mouseup', 'MouseEvents');
        addEventHandler('mouseover', 'MouseEvents');
        addEventHandler('mousemove', 'MouseEvents');
        addEventHandler('mouseout', 'MouseEvents');

        addEventHandler('wheel', 'WheelEvent');
    }

    normalizedWheelParams(event)    {
        var ret = {deltaX: event.deltaX, deltaY: event.deltaY, deltaZ: event.deltaZ};

        if(event.deltaMode)   {
            const f = event.deltaMode === 1 ? 33 : 800;
            ret.deltaX *= f; ret.deltaY *= f; ret.deltaZ *= f;
        }

        return ret;
    }

    easeTiming(value)   {
        return 0.5 * (Math.sin(Math.PI*Math.min(Math.max(value, 0), 1) - 0.5 * Math.PI) + 1);
    }

    versionString()  {
        var date = new Date(versioning.date);
        return "Version: " + versioning.version + " - " + versioning.commithash + " - " + date.toLocaleDateString();
    }

    sepFront(str) {
        return str === '' ? str : (', ' + str);
    }

    transitionForInsets(insets, duration)    {
        const dur = typeof(duration) === 'number' ? (duration.toString() + 's') : '0.333s';
        var ret = '';
        if(insets.top) ret += 'top ' + dur + ' ease';
        if(insets.right) ret += (ret !== '' ? ', ' : '') + 'right ' + dur + ' ease';
        if(insets.bottom) ret += (ret !== '' ? ', ' : '') + 'bottom ' + dur + ' ease';
        if(insets.left) ret += (ret !== '' ? ', ' : '') + 'left ' + dur + ' ease';
        return ret;
    }

    isFunctionalComponent(comp) {
        return typeof(comp) === 'function' && !(comp.prototype && comp.prototype.isReactComponent);
    }
      
    isClassComponent(comp) {
        return typeof(comp) === 'function' && comp.prototype && comp.prototype.isReactComponent;
    }

    capitalize(str) {
        if(typeof(str) !== 'string' && str.length == 0) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    lowercaseUnderscoreToLowerCamelCase(str)   {
        if(typeof(str) !== 'string' && str.length == 0) return str;
        let capitalizeFirst = str.indexOf('_') === 0;
        let words = str.split('_');
        let ret = '';
        words.forEach((word, i) => {
            ret = ret + (i > 0 || capitalizeFirst ? this.capitalize(word) : word);
        });
        return ret;
    }

    lowerCamelCaseToLowercaseUnderscore(str, separator = '_')   {
        if(typeof(str) !== 'string' && str.length == 0) return str;
        let ret = str;
        let i = 0;
        while(i < ret.length)   {
            let char = ret.charAt(i);
            let isCapital = char !== '-' && char.toUpperCase() == char;
            if(!isCapital)  {
                i++; continue;
            }
            ret = (i > 0 ? ret.substring(0, i) : '') + separator + char.toLowerCase() + (i + 1 < ret.length ? ret.substring(i + 1) : '');
            i = i + 2;
        }
        return ret;
    }

    lowercaseUnderscoreToUpperCamelCase(str)    {
        let ret = this.lowercaseUnderscoreToLowerCamelCase(str);
        return this.capitalize(ret);
    }

    upperCamelCaseToLowercaseUnderscore(str)   {
        if(typeof(str) !== 'string' && str.length == 0) return str;
        let ret = str.charAt(0).toLowerCase() + str.slice(1);
        return this.lowerCamelCaseToLowercaseUnderscore(ret);
    }

    getScrollbarSize_() {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        outer.style.msOverflowStyle = 'scrollbar';
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        const scrollbarSize = outer.offsetWidth - inner.offsetWidth + 1;

        outer.parentNode.removeChild(outer);
        return scrollbarSize;
    }

    getScrollbarSize()  {
        // if we have a cached value return it
        if(this.scrollbarSize) return this.scrollbarSize;

        // otherwise initialize cached value and setup a listener to appropriate events to update it
        this.scrollbarSize = this.getScrollbarSize_();

        window.addEventListener('resize', () => {
            this.scrollbarSize = this.getScrollbarSize_();
        });

        return this.scrollbarSize;
    }
    
    stdTMToData(tm) {
        const y = tm.year < 100 ? tm.year : tm.year + 1900;
        //return new Date(y.toString() + '-' + (tm.mon + 1).toString() + '-' + tm.mday.toString() + 'T' + tm.hour.toString() + ':' + tm.min.toString() + ':' + tm.sec.toString);
        return new Date(Date.UTC(y, tm.mon, tm.mday, tm.hour, tm.min, tm.sec));
    }

    iterateChildNodes(node, fn)  {
        node.childNodes.forEach(n => { fn(n); this.iterateChildNodes(n, fn); })
    }

    iterateRelatedNodes(node, fn)    {
        let n = node;
        while(n)    {
            fn(n);
            n = n.parentNode;
        }
        this.iterateChildNodes(node, fn);
    }

    getTitleStringIdForUIControllerType(type)  {
        const uit = UIControllerType;
        if(!this.uiControllerTypeTitleStringIdMap)  {
            this.titleStringIdMap = [
                {type: uit.cut, id: 'cut_view'},
                {type: uit.note, id: 'note_view'},
                {type: uit.component, id: 'tree_view'},
                {type: uit.substructure, id: 'substructure_view'},
                {type: uit.search_tool, id: 'search_view'},
                {type: uit.selection, id: 'selection_view'},
                {type: uit.label, id: 'label_view'},
                {type: uit.presentation, id: 'presentation_view'},
                {type: uit.animation, id: 'animations_view'},
                {type: uit.media_center, id: 'media_center_view'},
                {type: uit.settings, id: 'settings_view'},
                {type: uit.search_controller, id: 'search_view'}
            ];
        }
        let res = this.titleStringIdMap.find(e => e.type === type);
        return res ? res.id : undefined;
    }

    filterObject(obj, allowedKeys, notAllowedKeys) {
        if(typeof(obj) !== 'object')    return obj;
        let ret = Object.keys(obj).filter(key => (!allowedKeys || allowedKeys.includes(key)) && (!notAllowedKeys || !notAllowedKeys.includes(key))).reduce((newObj, key) => { return {...newObj, [key]: obj[key]}; }, {});
        return ret;
    }
};