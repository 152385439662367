import {slInterface} from './sl-interface';
import {SafeList} from '../lib/safe-list';

export class OEInterface {

    constructor(moduleId) {
        this.moduleId = moduleId;

        this.preConnectCallbacks = new SafeList();
        this.connectCallbacks = new SafeList();
        this.releaseCallbacks = new SafeList();

        this.onInitialized = this.onInitialized.bind(this);
        this.onLicensingModuleConnected = this.onLicensingModuleConnected.bind(this);

        this.reset();
    }

    reset() {

        if(this.sharedNotificationCenter && this.sharedNotificationCenter.isRegistered(this.NotificationName.initialized, this.onInitialized))  {
            this.sharedNotificationCenter.unregister(this.NotificationName.initialized, this.onInitialized);
        }

        // we can not make sure that the content window of the embeded iframe loading the core module does not leak at this time
        // but at least we can prevent the leakage of the big array buffers of the core module by setting all references to null explicitely
        if(this.element)    {
            this.element.contentWindow.buffer = null;
            this.element.contentWindow.HEAP8 = null;
            this.element.contentWindow.HEAP16 = null;
            this.element.contentWindow.HEAP32 = null;
            this.element.contentWindow.HEAPF32 = null;
            this.element.contentWindow.HEAPF64 = null;
            this.element.contentWindow.HEAPU8 = null;
            this.element.contentWindow.HEAPU16 = null;
            this.element.contentWindow.HEAPU32 = null;
            this.element.contentWindow.wasmMemory = null;
            this.element.contentWindow.memory = null;
            this.element.contentWindow.asmLibraryArg = null;
            this.element.contentWindow.wasmTable = null;
            this.element.contentWindow.asm = null;
            this.element.contentWindow.FS = null;
        }

        if(this.Module) {
            this.Module.HEAP8 = null;
            this.Module.HEAP16 = null;
            this.Module.HEAP32 = null;
            this.Module.HEAPF32 = null;
            this.Module.HEAPF64 = null;
            this.Module.HEAPU8 = null;
            this.Module.HEAPU16 = null;
            this.Module.HEAPU32 = null;
            this.Module.asm = null;
        }

        this.preReady = false;
        this.ready = false;
        this.onConnectCalled = false;
        this.sharedNotificationCenter = null;
        this.NotificationName = null;
        this.ComponentTreeConnector = null;
        this.sharedInterface = null;
        this.Module = null;
        this.element = null;
    }

    registerOnPreConnect(callback) {
        this.preConnectCallbacks.addUnique(callback);
    }

    unregisterOnPreConnect(callback) {
        this.preConnectCallbacks.remove(callback)
    }

    registerOnConnect(callback) {
        this.connectCallbacks.addUnique(callback);
    }

    unregisterOnConnect(callback) {
        this.connectCallbacks.remove(callback);
    }

    registerOnRelease(callback) {
        this.releaseCallbacks.addUnique(callback);
    }

    unregisterOnRelease(callback) {
        this.releaseCallbacks.remove(callback)
    }

    register(onConnect, onRelease)  {
        this.registerOnConnect(onConnect);
        this.registerOnRelease(onRelease);
    }

    unregister(onConnect, onRelease)  {
        this.unregisterOnConnect(onConnect);
        this.unregisterOnRelease(onRelease);
    }

    connect()    {
        if(!this.moduleId) {
            this.reset();
            return false;
        }

        var element = document.getElementById(this.moduleId);
        if(!element) {
            this.reset();
            return false;
        }

        this.element = element;
        var contentWindow = this.element.contentWindow;

        if(typeof(contentWindow.Module) === 'undefined')    {
            this.reset();
            return false;
        }

        this.sharedNotificationCenter = contentWindow.sharedNotificationCenter;
        this.NotificationName = contentWindow.NotificationName;
        this.ComponentTreeConnector = contentWindow.ComponentTreeConnector;
        this.Module = contentWindow.Module;
        
        this.preReady = true;
        this.preConnectCallbacks.forEach((callback) => callback(this));

        if(this.Module.statusManager.isInitialized()) {
            this.onInitialized();
            return true;
        }

        this.ready = false;
        this.onConnectCalled = false;
        this.sharedInterface = null;

        this.connectLicensingModule();

        if(!this.sharedNotificationCenter.isRegistered(this.NotificationName.initialized, this.onInitialized))  {
            this.sharedNotificationCenter.register(this.NotificationName.initialized, this.onInitialized);
        }

        return true;
    }

    connectLicensingModule()    {
        if(!slInterface.isPreReady())  return;
            
        if(slInterface.isReady())  {

            if(!this.licensingServiceInjected)  {
                console.log('Injecting shared licensing module.');
                this.element.contentWindow.licensingModule = slInterface.Module;
                this.element.contentWindow.licensingService = slInterface.sharedInterface.getLicensingService();
                this.licensingServiceInjected = true;
            }

            if(this.ready && !this.licensingServiceConnected)   {
                this.Module.onLicensingServiceConnect();
                this.licensingServiceConnected = true;
            }

        } else {
            slInterface.register(this.onLicensingModuleConnected);
        }
    }

    onLicensingModuleConnected()  {

        if(!this.licensingServiceInjected)  {
            console.log('Injecting shared licensing module on licensing module connect.');
            this.element.contentWindow.licensingModule = slInterface.Module;
            this.element.contentWindow.licensingService = slInterface.sharedInterface.getLicensingService();
            this.licensingServiceInjected = true;
        }

        if(this.ready && !this.licensingServiceConnected)   {
            this.Module.onLicensingServiceConnect();
            this.licensingServiceConnected = true;
        }
        
        slInterface.unregister(this.onLicensingModuleConnected);
    }

    release()   {
        if(slInterface.isPreReady()) slInterface.unregister(this.onLicensingModuleConnected);
        this.licensingServiceInjected = false;
        this.licensingServiceConnected = false;
        
        if(this.isReady())    {
            this.Module.onOEInterfaceRelease();
            this.releaseCallbacks.forEach((callback) => callback(this));
        }

        this.reset();
    }

    onInitialized() {
        var contentWindow = this.element.contentWindow;
        
        this.ready = true;
        this.sharedInterface = contentWindow.sharedInterface;

        this.connectLicensingModule();

        this.Module.onOEInterfaceConnect();
        
        this.connectCallbacks.forEach((callback) => callback(this));
        this.onConnectCalled = true;

        console.log("Initialized with final heap size " + this.Module.HEAP8.length.toString());
    }

    changeTarget(nextTarget)  {
        if(!this.isReady()) return;

        this.Module.onOEInterfaceRelease();
        this.releaseCallbacks.forEach((callback) => callback(this));

        this.ready = false;
        this.onConnectCalled = false;

        this.preConnectCallbacks.forEach((callback) => callback(this));

        this.sharedInterface.changeTarget(coreTargetTranslation(nextTarget));
    }

    isPreReady()   {
        return this.preReady;
    }

    isReady()   {
        return this.ready;
    }

    isOnConnectCalled()   {
        return this.onConnectCalled;
    }

    startUp()   {
        if(this.element)    {
            var contentWindow = this.element.contentWindow;
            contentWindow.startUp();
        }
    }

    injectSentry()  {
        if(!Sentry || !this.isPreReady()) return false;
        this.element.contentWindow.Sentry = Sentry;
        return true;
    }
};

class OEInterfaceManager {

    constructor() {
        this.interfaces = new Object();
    }

    getInterface(moduleId)   {
        if(!(moduleId in this.interfaces)) {
            this.interfaces[moduleId] = new OEInterface(moduleId);
        }

        return this.interfaces[moduleId];
    }

    connect(moduleId) {
        var iface = this.getInterface(moduleId);
        return iface.connect();
    }

    release(moduleId)    {
        var iface = this.getInterface(moduleId);
        iface.release();
    }
};

export let oeInterfaceManager = new OEInterfaceManager();
