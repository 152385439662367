import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {OEVersion} from '../../../react-oe/oe-version';
import {OETarget} from '../../../react-oe/oe-target';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import OEStdCapBarController from './std/oe-std-cap-bar-controller';
import OEZeissOptiCapBar from './oe-zeiss-opti-cap-bar';
import OEPhilipsIcarusCapBar from '../../custom/philipsIcarus/oe-philips-icarus-cap-bar';
import OEImplantcastCapBar from '../../custom/implantcast/oe-implantcast-cap-bar';

export function OECapBarFactory(props)  {
    if(props.opaque)    return null;

    if(props.version === OEVersion.zeissOpti)    {
        return <OEZeissOptiCapBar {...props}/>;
    } else if(props.version === OEVersion.eyeOCT)   {
        return (
            <OEStdCapBarController 
                {...props}
                targetTypes={[
                    {id: OETarget.humanEye2, target: OETarget.humanEye2, icon: OEIconCodes.eyeOCT.anatomyTarget, titleId: 'target_menu_anatomy'},
                    {id: OETarget.zeissOpti2, target: OETarget.zeissOpti2, icon: OEIconCodes.eyeOCT.optiTarget, titleId: 'target_menu_zeissOpti2'},
                    {id: OETarget.humanEyeOCT, target: OETarget.humanEyeOCT, icon: OEIconCodes.eyeOCT.octTarget, titleId: 'target_menu_humanEyeOCT'}
                ]}
                targetTypeForTarget = {(target) => { return target === OETarget.zeissOpti2 || target === OETarget.humanEyeOCT ? target : OETarget.humanEye2; }}
                targetTypeTargetForTarget = {(targetType, target) => targetType.target}
            />);
    } else if(props.version === OEVersion.philipsIcarus)   {
        return <OEPhilipsIcarusCapBar {...props}/>
    } else if(props.version === OEVersion.implantcast)   {
        return <OEImplantcastCapBar {...props}/>
    }

    return null;
}

OECapBarFactory.defaultProps = {
    moduleId: ''
};

OECapBarFactory.propTypes = {
    moduleId: PropTypes.string,
};

export default connectAppEnv((env) => { return {
    version: env.config.version,
    target: env.config.target
}})(OECapBarFactory);