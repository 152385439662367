import {OEAssetSelectionType} from '../../../controller/asset-selection/oe-asset-selection-model';

export const OEPresentationAttributeHelper = {

    buildSlideAttribute(oe, assetSelection) {
        if(!oe || !oe.isReady() || !assetSelection || Array.isArray(assetSelection))  return;
        if(assetSelection.type === OEAssetSelectionType.animation)   {
            let anim = oe.sharedInterface.getUIControllerAnimation().getAnimation(assetSelection.ids.first);
            if(!anim)   return;
            return {
                type: oe.Module.PresentationAttrType.link,
                interval: {x: 0, y: anim.duration},
                timeFac: 1,
                linkType: oe.Module.PresentationAttrLinkType.animation,
                index: assetSelection.ids.first
            };
        }
    },

    buildSlideAttributes(oe, assetSelection)    {
        if(!oe || !oe.isReady() || !assetSelection) return [];
        let assetSelection_ = Array.isArray(assetSelection) ? assetSelection : [assetSelection];

        let ret = [];
        assetSelection_.forEach(item => {
            let attribute = this.buildSlideAttribute(oe, item);
            if(!attribute)  return;
            ret.push(attribute);
        });

        return ret;
    },

    addSlideAttribute(oe, ids, assetSelection)  {
        if(!ids || !oe || !oe.isReady())  return;
        if(!oe.sharedInterface.getUIControllerPresentation().getUIEnabled())   return;
        let attribute = this.buildSlideAttribute(oe, assetSelection);
        if(!attribute)  return;
        oe.sharedInterface.getUIControllerPresentation().addSlideAttributeID(ids.pres, ids.slide, attribute);
    }
};

export default OEPresentationAttributeHelper;