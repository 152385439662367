import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'reactstrap';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEIcon from '../../elements/oe-icon';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import OEPresentationSlideList from '../../controller/presentation/oe-presentation-slide-list';
import {OEToolbox} from '../../../lib/oe-toolbox';
import {retardUpdate} from '../../../lib/update-retarder';
import {OETransitionGroup} from '../../../lib/oe-transitions';

export class OEMediaCenterItemPresSlideItemContent extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        };

        this.onClicked = this.onClicked.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.onRef = this.onRef.bind(this);
    }

    startTooltip()   {
        if(this.timer) return;
        this.timer = setTimeout(() => { this.setState({tooltipOpen: true}); this.timer = null; }, 1000);
    }

    finishTooltip()   {
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.setState({tooltipOpen: false});
    }

    onClicked()   {
        this.finishTooltip();
    }

    onMouseEnter()    {
        this.startTooltip();
    }

    onMouseLeave()    {
        this.finishTooltip();
    }

    onRef(ref) {
        this.setState({ref: ref});
    }

    render()    {
        var data = this.props.data;

        var tooltip = !this.state.ref || !this.state.ref.parentElement || !data.desc || data.desc === '' ? null :
            <Tooltip
                boundariesElement="body"
                placement="right"
                isOpen={this.state.tooltipOpen}
                target={this.state.ref.parentElement}
                delay={{show: 500, hide: 0}}
            >
                {data.desc}
            </Tooltip>;

        return (
            <div className="container" onClick={this.onClicked} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} ref={this.onRef}>
                {tooltip}
                <div className="detail">
                    <div className="index">
                        {(data.index + 1).toString() + '.'}
                    </div>
                    <div className="name">
                        {data.name}
                    </div>
                </div>
                <div className="preview" style={{backgroundImage: 'url(' + data.img + ')'}}></div>
            </div>
        );
    }
};

export default class OEMediaCenterItemPres extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.activePres = -1;
        this.activeSlide = -1;
        this.expanded = false;
        this.duration = {duration: 0, slides: 0};

        this.state = {
            expanded: this.expanded,
            hasAudioTrack: false,
            tooltipOpen: false,
            duration: this.duration
        };

        this.onActivePresentationChanged = this.onActivePresentationChanged.bind(this);
        this.onActiveSlideChanged = this.onActiveSlideChanged.bind(this);
        this.onPresentationSlideAdded = this.onPresentationSlideAdded.bind(this);
        this.onPresentationSlideRemoved = this.onPresentationSlideRemoved.bind(this);
        this.onPresentationSlideMoved = this.onPresentationSlideMoved.bind(this);
        this.onPresentationSlideDataChanged = this.onPresentationSlideDataChanged.bind(this);
        this.onPresentationAudioTrackChanged = this.onPresentationAudioTrackChanged.bind(this);

        this.onHeaderClicked = this.onHeaderClicked.bind(this);
        this.onHeaderMouseEnter = this.onHeaderMouseEnter.bind(this);
        this.onHeaderMouseLeave = this.onHeaderMouseLeave.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onLabelClicked = this.onLabelClicked.bind(this);
        this.onRef = this.onRef.bind(this);
        this.onHeaderRef = this.onHeaderRef.bind(this);
        this.onPresentationSlideListRef = this.onPresentationSlideListRef.bind(this);
        this.onContentRef = this.onContentRef.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onPresentationSlideItemClick = this.onPresentationSlideItemClick.bind(this);
        this.onPresentationSlideListResize = this.onPresentationSlideListResize.bind(this);
        this.renderPresentationSlideItemContent = this.renderPresentationSlideItemContent.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.item.id !== this.props.item.id)     {
            this.props.elementRef(null, this.props.item.id);
            this.props.elementRef(this.ref, nextProps.item.id);
        }
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.activePresentationSlideChanged, this.onActiveSlideChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideRemoved, this.onPresentationSlideRemoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideMoved, this.onPresentationSlideMoved);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationSlideDataChanged, this.onPresentationSlideDataChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationAudioTrackChanged, this.onPresentationAudioTrackChanged);
    }

    onRelease()  {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationChanged, this.onActivePresentationChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.activePresentationSlideChanged, this.onActiveSlideChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideAdded, this.onPresentationSlideAdded);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideRemoved, this.onPresentationSlideRemoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideMoved, this.onPresentationSlideMoved);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationSlideDataChanged, this.onPresentationSlideDataChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationAudioTrackChanged, this.onPresentationAudioTrackChanged);
    }

    updateDuration()    {
        if(!this.oe.isReady()) return;
        this.duration = {
            duration: this.oe.sharedInterface.getUIControllerPresentation().getPresentationDurationID(this.props.item.item.index),
            slides: this.oe.sharedInterface.getUIControllerPresentation().getNumSlidesID(this.props.item.item.index)
        }
        this.setState({duration: this.duration});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.activePres = -1;
            this.activeSlide = -1;
            this.expanded = false;
            this.duration = {duration: 0, slides: 0};

            if(this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.setState({
                expanded: this.expanded,
                hasAudioTrack: false,
                tooltipOpen: false,
                duration: this.duration
            });

            return;
        }

        this.activePres = this.oe.sharedInterface.getUIControllerPresentation().getActivePresentationID();
        this.activeSlide = this.oe.sharedInterface.getUIControllerPresentation().getActiveSlideID();

        retardUpdate(this, () => {
            this.updateDuration();
            this.onPresentationAudioTrackChanged();
        });
    }

    setExpanded(expanded)   {
        this.expanded = expanded;
        this.setState({ expanded: this.expanded });
    }

    onActivePresentationChanged(message, userInfo)   {
        this.activePres = userInfo.presID;
        this.activeSlide = this.oe.sharedInterface.getUIControllerPresentation().getActiveSlideID();
        if(!this.props.enabled || this.activePres !== this.props.item.item.index)   return;
        if(!this.expanded)  {
            this.props.onItem(this.props.index, this.props.item.id, null, {x: 0, y: 0, width: 0, height: 0});
        } else {
            this.onSlideChanged(this.activeSlide);
        }
    }

    onActiveSlideChanged(message, userInfo)    {
        this.onSlideChanged(userInfo.slideID);
    }

    onPresentationAudioTrackChanged(message, userInfo)   {
        var userInfo = userInfo || {presID: this.props.item.item.index, hasTrack: this.oe.isReady() && this.oe.sharedInterface.getUIControllerPresentation().hasAudioTrackID(this.props.item.item.index)};
        if(userInfo.presID !== this.props.item.item.index) return;
        this.setState({hasAudioTrack: userInfo.hasTrack});
    }

    onSlideChanged(slideID)    {
        this.activeSlide = slideID;
        if(!this.props.enabled || this.activePres !== this.props.item.item.index) return;
        var id = slideID;

        if(!this.slideListRef)   {
            this.props.onItem(this.props.index, this.props.item.id, id, null);
        } else {
            this.slideListRef.itemRect(id, rect => {
                if(this.contentRef && rect) {
                    rect.x += this.contentRef.offsetLeft;
                    rect.y += this.contentRef.offsetTop;
                }
                this.props.onItem(this.props.index, this.props.item.id, id, rect);
            });
        }
    }

    onPresentationSlideAdded(message, userInfo)  {
        if(userInfo.presID !== this.props.item.item.index) return;
        this.updateDuration();
    }

    onPresentationSlideRemoved(message, userInfo)  {
        if(userInfo.presID !== this.props.item.item.index) return;
        this.updateDuration();
    }

    onPresentationSlideMoved(message, userInfo)  {
        if(userInfo.presSrcID !== this.props.item.item.index && userInfo.presDstID !== this.props.item.item.index) return;
        this.updateDuration();
    }

    onPresentationSlideDataChanged(message, userInfo)    {
        if(userInfo.presID !== this.props.item.item.index) return;
        this.updateDuration();
    }

    startTooltip()   {
        if(this.timer) return;
        this.timer = setTimeout(() => { this.setState({tooltipOpen: true}); this.timer = null; }, 1000);
    }

    finishTooltip()   {
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.setState({tooltipOpen: false});
    }

    onHeaderClicked()   {
        this.finishTooltip();
    }

    onHeaderMouseEnter()    {
        this.startTooltip();
    }

    onHeaderMouseLeave()    {
        this.finishTooltip();
    }

    onToggle()  {
        this.setExpanded(!this.expanded);
    }

    onLabelClicked()   {
        this.onClick();
    }

    onRef(ref) {
        if(this.ref === ref) return;
        this.ref = ref;
        this.props.elementRef(ref, this.props.item.id);
    }

    onHeaderRef(ref)    {
        this.setState({headerRef: ref});
    }

    onPresentationSlideListRef(ref) {
        this.slideListRef = ref;
    }

    onContentRef(ref) {
        this.contentRef = ref;
    }

    onClick()   {
        if(!this.props.enabled) return;
        this.props.onItem(this.props.index, this.props.item.id, null, {x: 0, y: 0, width: 0, height: 0});
        this.props.onClick(this.props.index, this.props.item.id, null);
    }

    onPresentationSlideItemClick(id)   {
        if(!this.props.enabled) return;
        var rect = this.slideListRef ? this.slideListRef.itemRect(id) : null;
        if(this.contentRef && rect) {
            rect.x += this.contentRef.offsetLeft;
            rect.y += this.contentRef.offsetTop;
        }
        this.props.onItem(this.props.index, this.props.item.id, id, rect);
        this.props.onClick(this.props.index, this.props.item.id, id);
    }

    onPresentationSlideListResize() {
        if(!this.oe.isReady() || !this.slideChangeAfterResize) return;
        this.slideChangeAfterResize = false;
        this.onSlideChanged(this.activeSlide);
    }

    renderPresentationSlideItemContent(data)    {
        return <OEMediaCenterItemPresSlideItemContent data={data}/>
    }

    renderExpandedContent() {
        if(!this.state.expanded) return null;

        return (
            <div className="content" ref={this.onContentRef}>
                <OEPresentationSlideList
                    moduleId={this.props.moduleId}
                    enabled={this.props.enabled}
                    overrideUIEnabled={true}
                    presID={this.props.item.item.index}
                    isPlayerPreview={true}
                    noScrollbar={true}
                    renderItemContent={this.renderPresentationSlideItemContent}
                    onItemClicked={this.onPresentationSlideItemClick}
                    ref={this.onPresentationSlideListRef}
                />
            </div>
        );
    }

    render() {
        let tooltip = !this.state.headerRef || !this.props.item.desc || this.props.item.desc === '' ? null :
            <Tooltip
                boundariesElement="body"
                placement="right"
                isOpen={this.state.tooltipOpen}
                target={this.state.headerRef}
                delay={{show: 500, hide: 0}}
            >
                {this.props.item.desc}
            </Tooltip>;

        let durationLabel = this.props.config.showDurationInSlidesForPresWithoutAudio && !this.state.hasAudioTrack ?
            <div className="duration">
                <OEIcon code={OEIconCodes.mcPresentationSlides}/>{this.state.duration.slides.toString()}
            </div> :
            <div className="duration">
                <OEIcon code={OEIconCodes.mcPresentationDuration}/>{OEToolbox.numberToString(Math.floor(this.state.duration.duration / 60), 2) + ':' + OEToolbox.numberToString(Math.floor(this.state.duration.duration % 60), 2)}
            </div>;
        
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div
                    className={'media-center-item-pres ' + this.props.className + (this.props.selected ? ' selected' : '') + (!this.props.enabled ? ' disabled' : '')}
                    style={this.props.style}
                    ref={this.onRef}
                >
                    {tooltip}

                    <div className="header" onClick={this.onHeaderClicked} onMouseEnter={this.onHeaderMouseEnter} onMouseLeave={this.onHeaderMouseLeave} ref={this.onHeaderRef} >
                        
                        <div className="toggle clicktarget" onClick={this.onToggle}>
                            <div className={'rot-toggle' + (this.state.expanded ? ' rot-rotate-toggle' : '')}>
                                <OEIcon code={OEIconCodes.caretRight}/>
                            </div>
                        </div>

                        <div className="separator vertical" />

                        <div className="right clicktarget" onClick={this.onLabelClicked}>
                            <div className="label">
                                {this.props.item.label}
                            </div>

                            {durationLabel}

                            <OEIcon className="sound-icon" code={this.state.hasAudioTrack ? OEIconCodes.mcItemWithSound : OEIconCodes.mcItemWithoutSound} onClick={this.onLabelClicked}/>
                        </div>

                    </div>
                        
                    <OETransitionGroup mountOnEnter={true} unmountOnExit={true} onAnimFinished={this.onPresentationSlideListResize}>
                        {this.renderExpandedContent()}
                    </OETransitionGroup>

                </div>
            </React.Fragment>
        );
    }
}

OEMediaCenterItemPres.defaultProps = {
    className:'',
    moduleId: '',
    enabled: true,
    selected: false,
    elementRef: function(ref, id)   {},
    onItem: function(index, id, subId, frame) {},
    onClick: function(index, id, subId) {}
};

OEMediaCenterItemPres.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    selected: PropTypes.bool,
    elementRef: PropTypes.func,
    onItem: PropTypes.func,
    onClick: PropTypes.func
};