import React from 'react';  
import PropTypes from 'prop-types';

import {withIsOpenState} from '../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import {AMErrorType} from '../../lib/oe-types';
import OEIcon from '../elements/oe-icon';
import OEButton from '../elements/oe-button';
import OEWidgetHeader from '../elements/oe-widget-header';
import {OEGroupControl, OEControl, OEAnimationControls} from '../oe-controls';
import {OEIconCodes} from '../../lib/oe-icon-codes';
import OEPopover from '../oe-popover';
import {retardUpdate} from '../../lib/update-retarder';

export class OEAudioTrackController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.hasTrack = false;
        this.isReady = false;
        this.isPlaying = false;
        this.time = 0;
        this.duration = 0;

        this.state = {
            uiEnabled: false,
            hasTrack: this.hasTrack,
            isReady: this.isReady,
            isPlaying: this.isPlaying,
            time: this.time,
            duration: this.duration
        }

        this.onStateChanged = this.onStateChanged.bind(this);
        this.onPlayStateChanged = this.onPlayStateChanged.bind(this);
        this.onTimeChanged = this.onTimeChanged.bind(this);
        this.onConfigChanged = this.onConfigChanged.bind(this);

        this.onFileLoadInputRef = this.onFileLoadInputRef.bind(this);

        this.renderHeaderCenterBar = this.renderHeaderCenterBar.bind(this);

        this.onOpenBtnPressed = this.onOpenBtnPressed.bind(this);
        this.onDumpBtnPressed = this.onDumpBtnPressed.bind(this);
        this.onSliderStart = this.onSliderStart.bind(this);
        this.onSliderChanged = this.onSliderChanged.bind(this);
        this.onSliderEnd = this.onSliderEnd.bind(this);
        this.onPlayPauseBtnPressed = this.onPlayPauseBtnPressed.bind(this);
        this.onStopBtnPressed = this.onStopBtnPressed.bind(this);
        this.onOkBtnPressed = this.onOkBtnPressed.bind(this);
        this.onLoadInputResult = this.onLoadInputResult.bind(this);
    }

    onConnect() {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.audioPlayerStateChanged, this.onStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.audioPlayerPlayStateChanged, this.onPlayStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.audioPlayerTimeChanged, this.onTimeChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.audioPlayerConfigChanged, this.onConfigChanged);
        
    }

    onRelease() {
        if(this.oe.isReady())  this.oe.sharedInterface.getUIControllerAudioPlayer().pause();

        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.audioPlayerStateChanged, this.onStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.audioPlayerPlayStateChanged, this.onPlayStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.audioPlayerTimeChanged, this.onTimeChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.audioPlayerConfigChanged, this.onConfigChanged);
    }

    updateUIState() {
        let uiEnabled = this.oe.sharedInterface.getUIControllerAudioPlayer().getUIEnabled();
        this.setState({uiEnabled: uiEnabled});
    }

    updatePlayerState(state) {
        let audio = this.oe.sharedInterface.getUIControllerAudioPlayer();
        state = state || audio.getState();

        if(state !== this.oe.Module.AudioPlayerState.ready) {
            this.hasTrack = false;
            this.isReady = false;
            this.isPlaying = false;
            this.time = 0;
            this.duration = 0;
        } else {
            this.hasTrack = true;
            this.isReady = true;
            this.isPlaying = audio.isPlaying();
            this.time = audio.getTime();
            this.duration = audio.getDuration();
        }

        this.setState({
            hasTrack: this.hasTrack,
            isReady: this.isReady,
            isPlaying: this.isPlaying,
            time: this.time,
            duration: this.duration
        });
    }

    updatePlayingState(playing) {
        this.isPlaying = playing || this.oe.sharedInterface.getUIControllerAudioPlayer().isPlaying();

        this.setState({
            isPlaying: this.isPlaying
        });
    }

    updateTime(time) {
        this.time = time || this.oe.sharedInterface.getUIControllerAudioPlayer().getTime();

        this.setState({
            time: this.time
        });
    }

    updateState(released) {
        if(!this.oe.isReady() || released === true) {
            this.setState({uiEnabled: false});
            return;
        }

        retardUpdate(this, () => {
            this.updateUIState();
            this.updatePlayerState();
        });
    }

    onUIControllerStateChanged(message, userInfo) {
        if(userInfo.type === this.oe.Module.UIControllerType.audio_player) {
            this.updateUIState();
        }
    }

    onStateChanged(message, userInfo) {
        this.updatePlayerState(userInfo.state);
    }

    onPlayStateChanged(message, userInfo) {
        this.updatePlayingState(userInfo.playing);
    }

    onTimeChanged(message, userInfo) {
        this.updateTime(userInfo.time);
    }

    onConfigChanged(message, userInfo)   {

    }

    onFileLoadInputRef(ref) {
        this.fileLoadInput = ref;
    }

    renderHeaderCenterBar(props)    {
        return (
            <React.Fragment>
                <OEButton
                    className="transparent-btn open-btn"
                    onPressed={this.onOpenBtnPressed}
                    disabled={!props.uiEnabled}
                >
                    <OEIcon code={OEIconCodes.audioTrack.open}/>
                </OEButton>
                <OEButton
                    className="transparent-btn dump-btn"
                    onPressed={this.onDumpBtnPressed}
                    disabled={!props.uiEnabled || !props.hasTrack}
                >
                    <OEIcon code={OEIconCodes.audioTrack.dump}/>
                </OEButton>
            </React.Fragment>
        );
    }

    render() {
        return  (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <div className={'audio-track-controller ' + this.props.className}>

                    <input id="audioTrackFileLoad"
                        type="file"
                        accept=".wav,.ogg"
                        ref={this.onFileLoadInputRef}
                        style={{display: 'none'}}
                        onChange={this.onLoadInputResult}
                    />

                    <OEWidgetHeader
                        moduleId={this.props.moduleId}
                        buttonClassName="transparent-btn"
                        icon={this.props.icon}
                        title={this.props.title}
                        titleId={this.props.titleId}
                        headerSeparator={this.props.headerSeparator}
                        centerBar={this.renderHeaderCenterBar}
                        onToggle={this.props.onToggle}
                        uiEnabled={this.state.uiEnabled}
                        hasTrack={this.state.hasTrack}
                    />

                    <OEGroupControl>
                        <OEControl className="animation-ok">
                            <OEAnimationControls 
                                mode={this.state.isReady ? ( (this.sliderIsBeingDragged ? this.playingWhenSlideDragStarted : this.state.isPlaying) ? OEAnimationControls.Mode.play : OEAnimationControls.Mode.pause) : OEAnimationControls.Mode.disabled}
                                time={this.state.isReady ? this.state.time : 0}
                                endTime={this.state.isReady ? this.state.duration : 1}
                                onStopBtnPressed={this.onStopBtnPressed}
                                onPlayPauseBtnPressed={this.onPlayPauseBtnPressed}
                                onProgressSliderStart={this.onSliderStart}
                                onProgressSliderChanged={this.onSliderChanged}
                                onProgressSliderEnd={this.onSliderEnd}
                            />

                            <OEButton
                                className="transparent-btn ok-btn"
                                onPressed={this.onOkBtnPressed}
                            >
                                <OEIcon code={OEIconCodes.audioTrack.ok}/>
                            </OEButton>
                        </OEControl>
                    </OEGroupControl>

                </div>
            </React.Fragment>
        );
    }

    onOpenBtnPressed()  {
        if(!this.fileLoadInput) return;
        this.fileLoadInput.value = null; // empties the file list so that onLoadInputResult gets called when loading the same file multiple times successively
        this.fileLoadInput.click();
    }

    onDumpBtnPressed()  {
        this.oe.sharedInterface.getUIControllerAudioPlayer().clearAudioData();
    }

    onPlayPauseBtnPressed()  {
        if(!this.state.isPlaying)   {
            this.oe.sharedInterface.getUIControllerAudioPlayer().play();
        } else {
            this.oe.sharedInterface.getUIControllerAudioPlayer().pause();
        }
    }

    onStopBtnPressed()  {
        this.oe.sharedInterface.getUIControllerAudioPlayer().stop();
    }

    onSliderStart(value)    {
        this.sliderIsBeingDragged = true;
        this.playingWhenSlideDragStarted = this.isPlaying;
        if(this.isPlaying)  this.oe.sharedInterface.getUIControllerAudioPlayer().pause();
    }

    onSliderChanged(value)  {
        if(!this.sliderIsBeingDragged)  return;
        this.oe.sharedInterface.getUIControllerAudioPlayer().seek(value);
    }

    onSliderEnd(value)  {
        if(!this.sliderIsBeingDragged)  return;
        this.sliderIsBeingDragged = false;
        if(this.playingWhenSlideDragStarted)  this.oe.sharedInterface.getUIControllerAudioPlayer().play();
    }

    onOkBtnPressed()  {
        if(this.props.onCompletion) this.props.onCompletion(this);
    }

    loadAudioFiles(files, callback) {
        let allFileProgress = {i: 0, num: files.length, aborted: false};

        let onLoadFunction = function(event) {
            if(allFileProgress.aborted || event.target.readyState != FileReader.DONE) return;

            if(!this.oe.isReady())  {
                allFileProgress.aborted = true;
                callback(AMErrorType.noError);
                return;
            }

            let audioData = {
                buffer:  event.target.result
            };

            let res = this.oe.sharedInterface.isAudioFileSupported(audioData).value;
            res = res == AMErrorType.couldNotOpenFile ? AMErrorType.unexpected : res

            if(res == AMErrorType.noError)  {
                let result = this.oe.sharedInterface.getUIControllerAudioPlayer().setAudioData(audioData);
                if(!result) res = AMErrorType.unexpected;
            }

            allFileProgress.aborted = true;
            callback(res);

        }.bind(this);

        for(let i = 0; i < files.length; ++i)   {
            if(allFileProgress.aborted) break;
            let reader = new FileReader();
            reader.onload = onLoadFunction;
            reader.readAsArrayBuffer(files[i]);
        }
    }

    onLoadInputResult(event)    {
        event.stopPropagation();
        event.preventDefault();
        if(event.target.files.length == 0)  return;
        this.props.appComponent.showWaitingController();

        this.loadAudioFiles(event.target.files, result => {
            this.props.appComponent.hideWaitingController();
            if(result != AMErrorType.noError) console.log('Loading audio track failed with error - ' + AMErrorType.toString(result));
        });
    }
}

OEAudioTrackController.defaultProps = {
    moduleId: '',
    className: '',
    titleId: 'audio_track_view',
    headerSeparator: true
};

OEAudioTrackController.propTypes = {
    moduleId: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    titleId: PropTypes.string,
    headerSeparator: PropTypes.bool,
    onToggle: PropTypes.func,
    onCompletion: PropTypes.func
};

export const OEAudioTrackPopoverController = OEPopover.coat(OEAudioTrackController, {
    noHeader: true,
}, {
    placement: 'right',
    buttonClassName: 'transparent-btn',
    controllerClassName: ''
}, {
    controllerClassName: PropTypes.string
});

export default withIsOpenState(OEAudioTrackPopoverController);