import React from 'react';
import PropTypes from 'prop-types';

import {connectAppEnv} from '../app-env';
import OERendererPresetPanel from './oe-renderer-preset-panel';

export class OEDevTools extends React.PureComponent {
    render() {
        if(!this.props.enabled) return null;
        return (
            <div
                className={'light-bg dev-tools std-label-text-color' + this.props.className}
                style={this.props.style}
            >
                <OERendererPresetPanel moduleId={this.props.moduleId}/>
            </div>
        );
    }
}

OEDevTools.defaultProps = {
    className: '',
    moduleId: '',
    enabled: false
};

OEDevTools.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool
};

export default connectAppEnv((env) => { return {
    enabled: env.state.showDevTools
}})(OEDevTools);