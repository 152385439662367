import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../react-oe/oe-interface-adapter';
import OEResizeObserver from '../../lib/oe-resize-observer';
import OEScrollbars from '../oe-scrollbars';
import {OEToolbox} from '../../lib/oe-toolbox';

export default class OETextView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.contentSize = {w: 0, h: 0};

        this.state = {
            style: null
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onIFrameRef = this.onIFrameRef.bind(this);
    }

    getContentSize()    {
        return this.contentSize;
    }

    componentWillUnmount()  {
        this.setCompId(null);
    }

    onConnect()  {}

    onRelease()  {}

    setCompId(id)   {
        if(this.compId == id || !this.oe.isReady())   return;
        let highlight = this.oe.sharedInterface.getUIControllerHighlightingTooltip();

        if(this.compId) highlight.removeCustomHighlightedComponent(this.compId);
        this.compId = id;
        if(this.compId) highlight.addCustomHighlightedComponent(this.compId);
    }

    updateContentSize() {
        if(!this.$iFrameDocumentBody)    return;
        let contentSize = {w: this.$iFrameDocumentBody.innerWidth(), h: this.$iFrameDocumentBody.innerHeight()};
        if(OEToolbox.shallowEqual(contentSize, this.contentSize))   return;
        this.contentSize = contentSize;
        this.updateStyle();
        if(this.props.onContentSizeChanged) this.props.onContentSizeChanged(this, this.contentSize);
    }

    updateStyle()   {
        let style = {height: this.contentSize.h};
        this.setState({style: style});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.setCompId(null);
            this.setState({src: '', style: null});
            return;
        }

        this.setCompId(null);
    }

    onMouseEnter(e) {
        this.setCompId(e.target.dataset.id);
    }

    onMouseLeave(e) {
        if(this.compId == e.target.dataset.id)  this.setCompId(null);
    }

    onLoad()    {
        if(!this.iFrameRef || !this.iFrameRef.contentWindow)    return;
        this.iFrameDocument = this.iFrameRef.contentWindow.document;

        let host = window.location.host;
        let path = window.location.pathname;
        let url = 'http://' + host + path;

        let cssFiles = [
            'fonts/effigos-symbol.css',
            'fonts/roboto/roboto.css'
        ];

        cssFiles.forEach(path => {
            let cssLink = document.createElement('link');
            cssLink.rel = 'stylesheet';
            cssLink.type = 'text/css';
            cssLink.href = url + path;
            document.head.appendChild(cssLink);
        });

        $(this.iFrameDocument.documentElement).find('span.comp-ref').hover(this.onMouseEnter, this.onMouseLeave);
        this.$iFrameDocumentBody = $(this.iFrameDocument.documentElement).find('body');

        this.updateContentSize();
    }

    onResize() {
        this.updateContentSize();
    }

    onIFrameRef(ref)   {
        if(this.iFrameRef === ref) return;
        this.iFrameRef = ref;
        if(!ref && this.iFrameDocument)    {
            $(this.iFrameDocument.documentElement).find('span.comp-ref').off('mouseenter mouseleave');
            this.iFrameDocument = null;
            this.$iFrameDocumentBody = null;
        }
        this.updateContentSize();
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>

                <div className={'text-view ' + this.props.className} style={this.props.style}>
                    <OEResizeObserver onResize={this.onResize}/>

                    <OEScrollbars>
                        <iframe
                            style={this.state.style}
                            src={this.props.src}
                            ref={this.onIFrameRef}
                            onLoad={this.onLoad}
                        />
                    </OEScrollbars>
                </div>

            </React.Fragment>
        );
    }
}

OETextView.defaultProps = {
    className: '',
    moduleId: ''
};

OETextView.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    moduleId: PropTypes.string,
    src: PropTypes.string,
    onContentSizeChanged: PropTypes.func
};