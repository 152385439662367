import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import {VelocityTransitionGroup} from 'velocity-react';

import OEThemeIndicatorView from './oe-theme-indicator-view';
import {OEWindowComponent} from '../lib/oe-window';
import {OEToolbox} from '../lib/oe-toolbox';

export default class OEThemeWaitingController extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            show: 0,
            progress: 0
        };
    }

    show() {
        let oldShow = 0;

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            oldShow = prevState.show;
            newState.show = prevState.show + 1;
            return newState;
        });

        if(oldShow === 0)    {
            this.setProgress(0);
        }
    }

    hide() {
        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.show = Math.max(0, prevState.show - 1);
            return newState;
        });
    }

    setProgress(progress)   {
        this.setState({progress: progress});
    }

    render() {
        let show = typeof(this.props.show) === 'boolean' ? this.props.show : this.state.show > 0;
        let progress = typeof(this.props.progress) === 'number' ? this.props.progress : this.state.progress;
        let element = !show ? null : 
            <div className={'theme-indicator-controller ' + this.props.className}>
                <OEThemeIndicatorView progress={progress}/>
            </div>;

        if(!this.props.animate)    return element;

        return (
            <VelocityTransitionGroup enter={{animation: 'fadeIn', duration: 333, easing: 'ease-in-out'}} leave={{animation: 'fadeOut', duration: 333, easing: 'ease-in-out'}}>
                {element}
            </VelocityTransitionGroup>
        );
    }

};

OEThemeWaitingController.defaultProps = {
    className: '',
    animate: true
};

OEThemeWaitingController.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    progress: PropTypes.number,
    animate: PropTypes.bool
};

export class OEThemeWaitingControllerWindow extends React.PureComponent { 

    constructor(props) {
        super(props);

        this.state = {
            show: 0,
            progress: 0
        };

        this.onWillAppear = this.onWillAppear.bind(this);
        this.onDidAppear = this.onDidAppear.bind(this);
        this.onWillDisappear = this.onWillDisappear.bind(this);
        this.onDidDisappear = this.onDidDisappear.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.options.animate && this.props.animate)  {
            if(this.showComplete)   {
                this.showComplete();
                this.showComplete = undefined;
            }
        }
    }

    show(complete) {
        let oldShow = 0;

        this.setState((prevState, props) => {
            let newState = clone(prevState);
            oldShow = prevState.show;
            newState.show = prevState.show + 1;

            if(newState.show && !prevState.show)    {
                if(this.props.options.animated) {
                    this.showComplete = complete;
                } else {
                    complete();
                }
            } else {
                if(complete)    complete();
            }

            return newState;
        });

        if(oldShow === 0)    {
            this.setProgress(0);
        }
    }

    hide() {
        this.setState((prevState, props) => {
            let newState = clone(prevState);
            newState.show = Math.max(0, prevState.show - 1);
            return newState;
        });
    }

    setProgress(progress)   {
        this.setState({progress: progress});
    }

    onWillAppear()  {
        if(this.props.onWillAppear) this.props.onWillAppear();
    }

    onDidAppear()  {
        if(this.props.onDidAppear) this.props.onDidAppear();
        if(this.showComplete)   {
            this.showComplete();
            this.showComplete = undefined;
        }
    }

    onWillDisappear()  {
        if(this.props.onWillDisappear) this.props.onWillDisappear();
    }

    onDidDisappear()  {
        if(this.props.onDidDisappear) this.props.onDidDisappear();
    }

    render() {
        let show = typeof(this.props.show) === 'boolean' ? this.props.show : this.state.show > 0;
        if(!show) return null;

        let progress = typeof(this.props.progress) === 'number' ? this.props.progress : this.state.progress;

        let style = {
            pointerEvents: show ? this.props.pointerEvents : 'none'
        };

        let enterTransition = !this.props.options.animated ? null : {animation: 'fadeIn', duration: this.props.options.duration * 1000, easing: 'ease-in-out', begin: this.onWillAppear, complete: this.onDidAppear};
        let leaveTransition = !this.props.options.animated ? null : {animation: 'fadeOut', duration: this.props.options.duration * 1000, easing: 'ease-in-out', begin: this.onWillDisappear, complete: this.onDidDisappear};

        return(
            <OEWindowComponent
                className={'theme-indicator-controller-window ' + this.props.className}
                portalClassName={'theme-indicator-controller-window-portal'}
                style={style}
                enterTransition={enterTransition}
                leaveTransition={leaveTransition}
            > 
                <div className={'theme-indicator-controller ' + this.props.className}>
                    <OEThemeIndicatorView progress={progress} animate={false}/>
                </div>;
            </OEWindowComponent>
        );
    }
}

OEThemeWaitingControllerWindow.defaultProps = {
    className: '',
    options: {animated: true, duration: 0.333},
    pointerEvents: 'initial'
};

OEThemeWaitingControllerWindow.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    progress: PropTypes.number,
    options: PropTypes.shape({animated: PropTypes.bool, duration: PropTypes.number}),
    pointerEvents: PropTypes.string,
    onWillAppear: PropTypes.func,
    onDidAppear: PropTypes.func,
    onWillDisappear: PropTypes.func,
    onDidDisappear: PropTypes.func,
};