import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEIcon from '../../elements/oe-icon';
import OEButton from '../../elements/oe-button';
import {OEIconCodes} from '../../../lib/oe-icon-codes';
import {retardUpdate} from '../../../lib/update-retarder';

export default class OEMediaCenterControlBarPanel extends React.PureComponent {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.actualItemId = {item: -1, subItem: -1};
        this.actualItem = null;

        this.actualCategoryMediaItemIndex = -1;

        this.state = {
            numItemsInCategory: 0,
            actualCategoryMediaItemIndex: this.actualCategoryMediaItemIndex,
            strings: {
                label: '',
                number: ''
            },
            showNumber: false
        };

        this.onConnect = this.onConnect.bind(this);
        this.onRelease = this.onRelease.bind(this);
        
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.updateForMediaCenterCategoryChange = this.updateForMediaCenterCategoryChange.bind(this);
        this.onActualMediaCenterItemChanged = this.onActualMediaCenterItemChanged.bind(this);
        this.onActualMediaCenterSubItemChanged = this.onActualMediaCenterSubItemChanged.bind(this);
        this.onPresentationDataChanged = this.onPresentationDataChanged.bind(this);

        this.onBackBtnPressed = this.onBackBtnPressed.bind(this);
        this.onForthBtnPressed = this.onForthBtnPressed.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.mounted && nextProps.moduleId !== this.props.moduleId)     {
            this.release(); 
            this.connect(nextProps.moduleId);
        }
    }

    componentDidMount()    {
        this.mounted = true;
        this.connect();
    }

    componentWillUnmount()    {
        this.release();
        this.mounted = false;
    }

    connect(moduleId) {
        this.oe = oeInterfaceManager.getInterface(moduleId || this.props.moduleId);
        this.oe.register(this.onConnect, this.onRelease);
        if(this.oe.isReady() && this.oe.isOnConnectCalled())   this.onConnect();
    }

    release()   {
        this.oe.unregister(this.onConnect, this.onRelease);
        if(this.oe.isReady())   {
            this.onRelease();
        } else {
            this.updateState();
        }   
    }

    onConnect()  {
        this.updateState();
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterCategoryChanged, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.addedToActualMediaCenterCategoryMediaItems, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.removedFromActualMediaCenterCategoryMediaItems, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterCategoryMediaItemMoved, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterItemChanged, this.onActualMediaCenterItemChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.actualMediaCenterSubItemChanged, this.onActualMediaCenterSubItemChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.presentationDataChanged, this.onPresentationDataChanged);
    }

    onRelease()  {
        this.updateState(true);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterCategoryChanged, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.addedToActualMediaCenterCategoryMediaItems, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.removedFromActualMediaCenterCategoryMediaItems, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterCategoryMediaItemMoved, this.updateForMediaCenterCategoryChange);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterItemChanged, this.onActualMediaCenterItemChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.actualMediaCenterSubItemChanged, this.onActualMediaCenterSubItemChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.presentationDataChanged, this.onPresentationDataChanged);
    }

    updateForMediaCenterCategoryChange()    {
        const controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        const numItemsInCategory = controller.getNumActualCategoryMediaItems();
        let index = controller.getActualMediaItemCategoryIndex();
        if(index >= numItemsInCategory)   index = -1;
        this.actualCategoryMediaItemIndex = index;
        this.setState({numItemsInCategory: numItemsInCategory, actualCategoryMediaItemIndex: index})
        this.updateLabel();
    }

    updateActualMediaCenterItem()   {
        let controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        this.actualItemId = {item: controller.getActualMediaItemID(), subItem: -1};
        this.updateActualMediaCenterSubItem();
    }

    updateActualMediaCenterSubItem()    {
        let controller = this.oe.sharedInterface.getUIControllerMediaCenter();
        this.actualItemId.subItem = controller.getActualMediaSubItemID();
        this.actualItem = controller.mediaItemID(this.actualItemId.item, this.actualItemId.subItem);
        this.updateForMediaCenterCategoryChange();
    }

    updateLabel(presData) {
        let label = '';
        let number = '';
        let showNumber = false;

        if(this.actualItem) {
            let si = this.oe.sharedInterface;
            
            if(this.actualItem.type === this.oe.Module.MediaCenterItemType.presentation)    {
                let data = presData || si.getUIControllerPresentation().getPresentationDataID(this.actualItem.index);
                label = data && data.name.get() ? data.name.get() : si.getLocalizedStringEnc(this.actualItem.label);
            } else {
                label = si.getLocalizedStringEnc(this.actualItem.label);
            }

            if(this.actualCategoryMediaItemIndex >= 0 && this.actualItem.showNumberInActualItemLabel)   {
                number = (this.actualCategoryMediaItemIndex + 1).toString();
                showNumber = this.actualItem.showNumberInActualItemLabel;
            }
        }

        this.setState({strings: {label: label, number: number}, showNumber: showNumber});
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.actualItemId = {item: -1, subItem: -1};
            this.actualItem = null;
            this.actualCategoryMediaItemIndex = -1;
            this.setState({
                numItemsInCategory: 0,
                actualCategoryMediaItemIndex: this.actualCategoryMediaItemIndex,
                strings: {
                    label: '',
                    number: ''
                },
                showNumber: false
            });
            return;
        }

        retardUpdate(this, () => {
            this.updateForMediaCenterCategoryChange();
            this.updateActualMediaCenterItem();
        });
    }

    onLanguageChanged() {
        this.updateLabel();
    }

    onActualMediaCenterItemChanged(message, userInfo)    {
        this.updateActualMediaCenterItem();
    }

    onActualMediaCenterSubItemChanged(message, userInfo)    {
        this.updateActualMediaCenterSubItem();
    }

    onPresentationDataChanged(message, userInfo)    {
        if(!this.actualItem || this.actualItem.type !== this.oe.Module.MediaCenterItemType.presentation || userInfo.presID != this.actualItem.index)    return;
        this.updateLabel(userInfo.presData);
    }

    render() {
        let enabled = this.state.uiEnabled && this.state.enabled;

        return (
            <div className={'media-center-control-bar-panel ' + this.props.className}>

                <OEButton
                    className="transparent-btn"
                    disabled={!this.props.enabled || this.state.actualCategoryMediaItemIndex <= 0}
                    onPressed={this.onBackBtnPressed}
                >
                    <OEIcon code={OEIconCodes.backward} />
                </OEButton>

                <div className="label-panel">
                    {!this.state.showNumber ? null : <span className="number">{this.state.strings.number}</span>}
                    <span className="label">{this.state.strings.label}</span>
                </div>

                <OEButton
                    className="transparent-btn"
                    disabled={!this.props.enabled || this.state.actualCategoryMediaItemIndex < 0 || this.state.actualCategoryMediaItemIndex >= this.state.numItemsInCategory-1}
                    onPressed={this.onForthBtnPressed}
                >
                    <OEIcon code={OEIconCodes.forward} />
                </OEButton>

            </div>
        );
    }

    onBackBtnPressed()   {

        if(this.oe.isReady()) {
            this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItemByCategoryIndex(this.actualCategoryMediaItemIndex - 1);
        }

        this.props.onBackBtnPressed(this.actualCategoryMediaItemIndex);
    }

    onForthBtnPressed()   {

        if(this.oe.isReady()) {
            this.oe.sharedInterface.getUIControllerMediaCenter().setActualMediaItemByCategoryIndex(this.actualCategoryMediaItemIndex + 1);
        }

        this.props.onForthBtnPressed(this.actualCategoryMediaItemIndex);
    }
}

OEMediaCenterControlBarPanel.defaultProps = {
    className: '',
    moduleId: '',
    enabled: true,
    onBackBtnPressed: (index) => {},
    onForthBtnPressed: (index) => {}
};

OEMediaCenterControlBarPanel.propTypes = {
    className: PropTypes.string,
    moduleId: PropTypes.string,
    enabled: PropTypes.bool,
    onBackBtnPressed: PropTypes.func,
    onForthBtnPressed: PropTypes.func
};