import React from 'react';
import PropTypes from 'prop-types';

import OEColor from './color-picker/oe-color';
import {OEColorHelper} from './oe-color-helper';

export default class OEThemeIndicatorView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};
    }

    polarToCartesian(centerX, centerY, radius, angleInDegrees) {

        var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
      
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    renderArc(x, y, radius, startAngle, endAngle, stroke, strokeWidth, fill, className, filter) {
        
        var start = this.polarToCartesian(x, y, radius, endAngle);
        var end = this.polarToCartesian(x, y, radius, startAngle);
        
        var arcSweep = endAngle - startAngle <= 180 ? "0" : "1";
        
        var d = [
            "M", start.x, start.y, 
            "A", radius, radius, 0, arcSweep, 0, end.x, end.y
        ].join(" ");
            
        return <path className={className} d={d} stroke={stroke} strokeWidth={strokeWidth} fill={fill} filter={filter}/>;
    }

    render() {

        var dimension = 100;
        var dimHalf = 0.5*dimension;

        var shadowRadius = 0.5*this.props.shadowRadius * dimension;
        var shadowOffset = {x: this.props.shadowOffset.x * dimension, y: this.props.shadowOffset.y * dimension};

        var shadowInset = 2*shadowRadius;
        var dim = dimension - 2*shadowInset;

        var activityWidth = this.props.activityWidth * dim;
        var progressWidth = this.props.progressWidth * dim;
        
        var activityInset = progressWidth + 0.5 * activityWidth + shadowInset;
        var progressInset = 0.5 * progressWidth + shadowInset;

        return (

            <div className={"theme-indicator-view " + this.props.className}>

                <svg className="activity-ring" viewBox="0 0 100 100">

                    <defs>
                        <filter id="f3" width="150%" height="150%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation={shadowRadius} edgeMode="none"/>
                            <feOffset dx={shadowOffset.x} dy={shadowOffset.y} result="offsetblur"/>
                            <feComponentTransfer>
                                <feFuncA type="linear" slope={this.props.shadowOpacity}/>
                            </feComponentTransfer>
                            <feMerge>
                                <feMergeNode/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>

                    {this.renderArc(50, 50, dimHalf-activityInset, 0, 180, this.props.activityColor, activityWidth, 'none', null, 'url(#f3)')}
                </svg>

                <svg className="progress-ring" viewBox="0 0 100 100">
                    {this.renderArc(50, 50, dimHalf-progressInset, 0, Math.min(360*this.props.progress, 359.9999), this.props.progressColor, progressWidth, 'none')}
                </svg>

            </div>
        );
    }
}

OEThemeIndicatorView.defaultProps = {
    className: '',
    animating: true,
    progress: 0.0,
    activityColor: OEColor.toDOMStr(OEColorHelper.themeColor()),
    activityWidth: 0.1,
    progressColor: "rgba(255,255,255,1)",
    progressWidth: 0.02,
    shadowColor: "rgba(0,0,0,1)",
    shadowRadius: 0.1,
    shadowOpacity: 0.4,
    shadowOffset: {x: 0.02, y: 0.02}
};

OEThemeIndicatorView.propTypes = {
    className: PropTypes.string,
    animating: PropTypes.bool,
    progress: PropTypes.number,
    activityColor: PropTypes.string,
    activityWidth: PropTypes.number,
    progressColor: PropTypes.string,
    progressWidth: PropTypes.number,
    shadowColor: PropTypes.string,
    shadowRadius: PropTypes.number,
    shadowOpacity: PropTypes.number,
    shadowOffset: PropTypes.object
};