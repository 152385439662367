import React from 'react';
import PropTypes from 'prop-types';

import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';
import {OEPortal} from '../lib/oe-window'

export default class OEBlockingView extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.state = {
            show: false,
            showSpinner: false,
            progress:   {
                show: false,
                value: 0,
                max: 100
            }
        };

        this.onAppStatusChanged = this.onAppStatusChanged.bind(this);
    }

    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.appStatusChanged, this.onAppStatusChanged);
    }

    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.appStatusChanged, this.onAppStatusChanged);
    }

    updateForStatus(status) {
        if(!this.oe.isReady()) return;
         status = status || this.oe.sharedInterface.getStatus();

        const isRunning = status.primaryStatus === this.oe.Module.AppPrimaryStatusType.running;
        const progress = status.blockingProgress;
        const numBlocking = status.numBlockingTasks;

        this.setState({
            show: isRunning && numBlocking > 0,
            showSpinner: isRunning && numBlocking > 0,
            progress:   {
                show: false,
                value: progress,
                max: 100
            }
        });
    }

    updateState(released, props_)   {
        if(this.unmounted) return;
        if(!this.oe.isReady() || released === true)   {
            this.setState({
                show: false,
                showSpinner: false,
                progress:   {
                    show: false,
                    value: 0,
                    max: 100
                }
            });
        }

        this.updateForStatus();
    }

    onAppStatusChanged(message, userInfo)   {
        let status = userInfo.status;
        this.updateForStatus(status);
    }

    renderContent() {
        if(!this.state.show) return null;
        return (
            <OEPortal>
                <div className="blocking-view">
                </div>
            </OEPortal>
        );
    }

    render() {
        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                {this.renderContent()}
            </React.Fragment>
        );
    }
}

OEBlockingView.defaultProps = {
    moduleId: ''
};

OEBlockingView.propTypes = {
    moduleId: PropTypes.string
};