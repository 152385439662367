import React from 'react';  
import PropTypes from 'prop-types';

import {connectAppEnv} from '../../app-env';
import {withUILevel} from '../../../lib/oe-higher-order-components';
import {oeInterfaceManager} from '../../../react-oe/oe-interface';
import OEInterfaceAdapter from '../../../react-oe/oe-interface-adapter';
import OEPopover from '../../oe-popover';
import {OEIconButton} from '../../elements/oe-button';
import {OEGroupControl, OEControl, OETextFieldControl} from '../../oe-controls';
import {UIControllerType, OEManualViewLinks} from '../../../lib/oe-types';
import {OEDefaultConfigFactory} from '../../oe-default-configs';
import {OEComponentSearchFlags} from '../component/oe-component-search-control';
import {retardUpdate} from '../../../lib/update-retarder';
import OEScrollbars from '../../oe-scrollbars';
import {OEToolbox} from '../../../lib/oe-toolbox';

export const OEStdSearchBtnNames = {
    centerCameraContextMenu: 'center_camera_context_menu',
    componentTree: 'component_tree',
    select: 'select',
};

export class OESearchControllerTableCell extends React.Component {
    
    constructor(props) {
        super(props);

        this.onBtnPressed = this.onBtnPressed.bind(this);
    }

    render() {
        let buttons = [];
        let addedIndex = 0;
        for(let i = 0; i < this.props.buttons.length; ++i) {
            let btn = this.props.buttons[i];
            if(btn.isHidden) continue;
            
            if(addedIndex != 0)  buttons.push(<div key={2*i} className="btn-spacer"/>);
            addedIndex++;

            buttons.push(
                <OEIconButton
                    key={2*i + 1}
                    className={'transparent-btn btn-' + i.toString()}
                    index={i}
                    icon={btn.icon}
                    disabled={!btn.enabled || !this.props.enabled}
                    onPressed={this.onBtnPressed}
                />
            );
        }

        // buttons indexed from right to left
        buttons = buttons.reverse();

        return(
            <div className={'search-cell ' + (this.props.isReachable ? 'reachable' : 'non-reachable')}>
                <div className="content">
                    <div className="label">
                        <div className="primary"><span>{this.props.primaryLabel}</span></div>
                        <div className="secondary">{this.props.secondaryLabel}</div>
                    </div>
                    <div className="btn-container">
                        {buttons}
                    </div>
                </div>
                {!this.props.isLast ? <div className="separator"/> : null}
            </div>
        )
    }

    onBtnPressed(e, sender)  {
        if(this.props.onBtnPressed) this.props.onBtnPressed(this, this.props.id, sender.props.index);
    }
}

OESearchControllerTableCell.propTypes = {
    id: PropTypes.string,
    primaryLabel: PropTypes.string,
    secondaryLabel: PropTypes.string,
    reachable: PropTypes.bool,
    enabled: PropTypes.bool,
    buttons: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        isHidden: PropTypes.bool,
        enabled: PropTypes.bool
    })),
    isLast: PropTypes.bool,
    onBtnPressed: PropTypes.func
};

export class OESearchController extends React.PureComponent {
    constructor(props) {
        super(props);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);

        this.searchResult = [];

        this.state = {
            uiEnabled: false,
            searchString: '',
            searchResult: [],
            strings: {
                searchLabel: ''
            }
        };

        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.onSearchQueryChanged = this.onSearchQueryChanged.bind(this);
        this.onSearchResultChanged = this.onSearchResultChanged.bind(this);
        this.onSearchItemBtnStateChanged = this.onSearchItemBtnStateChanged.bind(this);
        this.onUIControllerStateChanged = this.onUIControllerStateChanged.bind(this);

        this.onHelpBtnPressed = this.onHelpBtnPressed.bind(this);
        this.onSearchInputChanged = this.onSearchInputChanged.bind(this);
        this.onCellBtnPressed = this.onCellBtnPressed.bind(this);
    }
    
    onConnect()  {
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.searchQueryChanged, this.onSearchQueryChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.searchResultChanged, this.onSearchResultChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.searchItemBtnStateChanged, this.onSearchItemBtnStateChanged);
        this.oe.sharedNotificationCenter.register(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }
    
    onRelease()    {
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.languageChanged, this.onLanguageChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.searchQueryChanged, this.onSearchQueryChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.searchResultChanged, this.onSearchResultChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.searchItemBtnStateChanged, this.onSearchItemBtnStateChanged);
        this.oe.sharedNotificationCenter.unregister(this.oe.NotificationName.uiControllerStateChanged, this.onUIControllerStateChanged);
    }
    
    updateLanguage(onlyStrings) {
        const si = this.oe.sharedInterface;
        this.setState({
            strings: {
                searchLabel: si.getLocalizedStringEnc('search_view_text_field_placeholder')
            }
        });

        if(!onlyStrings)    this.updateSearchResultState();
    }

    updateSearchResultState()   {
        if(!this.oe.isReady()) return;
        const comp = this.oe.sharedInterface.getUIControllerComponent();

        this.setState({
            searchResult: this.searchResult.map((item, index) => {
                return {
                    id: item.id,
                    primaryLabel: comp.getComponentName(item.id),
                    secondaryLabel: comp.getComponentSecondaryName(item.id),
                    isReachable: item.isReachable,
                    buttons: item.buttons.map((button) => {
                        return {
                            icon: button.icon,
                            isHidden: button.isHidden,
                            enabled: button.enabled
                        };
                    })
                };
            })
        });
    }

    updateUIState() {
        this.setState({uiEnabled: this.oe.sharedInterface.getUIControllerSearch().getUIEnabled()});
    }

    updateSearchString()    {
        this.setState({searchString: this.oe.sharedInterface.getUIControllerSearch().getSearchString()});
    }

    updateSearchResult()    {
        let searchResult = this.oe.sharedInterface.getUIControllerSearch().getResultData();
        if(OEToolbox.shallowEqual(searchResult, this.searchResult, true))   return;
        this.searchResult = searchResult;
        this.updateSearchResultState();
    }

    updateState(released)   {
        if(!this.oe.isReady() || released === true)   {
            this.searchResult = [];
            retardUpdate(this, () => {
                this.setState({uiEnabled: false, searchString: '', searchResult: []});
            });
            return;
        }
        
        retardUpdate(this, () => {
            this.updateLanguage(true);
            this.updateSearchString();
            this.updateSearchResult();
            this.updateUIState();
        });
    }

    onLanguageChanged() {
        this.updateLanguage();
    }

    onSearchQueryChanged()  {
        this.updateSearchString();
    }

    onSearchResultChanged()  {
        this.updateSearchResult();
    }

    onSearchItemBtnStateChanged()  {
        this.updateSearchResult();
    }

    onUIControllerStateChanged(message, userInfo) {
        if(userInfo.type === this.oe.Module.UIControllerType.search_controller)   {
            this.updateUIState();
        }
    }
   
    render() {
        if(!this.props.config.enabled) return null;

        const searchCells = this.state.searchResult.map((item, index) =>
            <OESearchControllerTableCell
                key={index}
                isLast={index === this.state.searchResult.length - 1}
                onBtnPressed={this.onCellBtnPressed}
                enabled={this.state.uiEnabled}
                {...item}
            />
        )

        return (
            <React.Fragment>
                <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
                <OEPopover 
                    className="popover-control" 
                    placement="right-start"
                    buttonClassName="transparent-btn"
                    moduleId={this.props.moduleId}
                    uiControllerType={UIControllerType.search_controller}
                    boundariesElement={this.props.boundariesElement}
                    target={this.props.target}
                    titleId="search_view"
                    onHelpBtnPressed={this.props.config.showHelpBtn ? this.onHelpBtnPressed : null}
                >
                    <div className="search-controller">
                        <OEGroupControl>

                            <OETextFieldControl 
                                className="std-label-border-color search-input"
                                disabled={!this.state.uiEnabled}
                                placeholder={this.state.strings.searchLabel}
                                value={this.state.searchString}
                                onChange={this.onSearchInputChanged}
                            />

                            <OEControl className="search-list">
                                <OEScrollbars>
                                    {searchCells}
                                </OEScrollbars>
                            </OEControl>

                        </OEGroupControl>
                    </div>
                </OEPopover>
            </React.Fragment>
        );
    }

    onHelpBtnPressed()  {
        if(this.props.appComponent)    this.props.appComponent.uiLayer.manualView.setOpen(true, {link: OEManualViewLinks.search});
    }

    onSearchInputChanged(sender, value)   {
        if(!this.oe.isReady())  return;
        this.oe.sharedInterface.getUIControllerSearch().setQuery(value ? value : '', 50, OEComponentSearchFlags.limit | OEComponentSearchFlags.enabled | OEComponentSearchFlags.categoryEnabled | OEComponentSearchFlags.notHidden | OEComponentSearchFlags.secondaryLabel);
    
        let userInfo = new this.oe.Module.Dictionary();
        this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceSearchStringChanged, userInfo.setValue('string', value));
        userInfo.delete();
    }

    onCellBtnPressed(sender, id, index) {
        if(!this.oe.isReady()) return;
        const si = this.oe.sharedInterface;
        const buttonData = si.getUIControllerSearch().getSearchItemBtnData(id, index);
        if(!buttonData) return;
        si.getUIControllerSearch().applySearchItemBtnFunc(id, index);

        if(buttonData.name === OEStdSearchBtnNames.centerCameraContextMenu) {
            let userInfo = new this.oe.Module.Dictionary();
            this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceSearchContextMenu, userInfo.setValue('componentID', id));
            userInfo.delete();
        } else if(buttonData.name === OEStdSearchBtnNames.componentTree) {
            this.props.appComponent.componentController.findComponent(id);

            let userInfo = new this.oe.Module.Dictionary();
            this.oe.sharedInterface.postNotification(this.oe.NotificationName.uiInstanceSearchFindInComponentTree, userInfo.setValue('componentID', id));
            userInfo.delete();
        }
    }
}

OESearchController.defaultProps = {
    moduleId: '',
    target: '',
    config: OEDefaultConfigFactory.searchController()
};

OESearchController.propTypes = {
    moduleId: PropTypes.string,
    config: PropTypes.shape({
        enabled: PropTypes.bool,
        showHelpBtn: PropTypes.bool
    }).isRequired
};

export default connectAppEnv((env) => {
    const ui = env.config.module.uiLayerConfig;
    return {
        appComponent: env.component,
        config: OEDefaultConfigFactory.combineShowHelpState(ui.widgetConfig.searchController, ui.widgetConfig.showHelp, ui.manualViewConfig.links, OEManualViewLinks.search)
    }
})(withUILevel(OESearchController));