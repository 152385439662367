import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import update from 'immutability-helper';

import {addModuleEnv} from './oe-module-env';
import {oeInterfaceManager} from '../react-oe/oe-interface';
import OEInterfaceAdapter from '../react-oe/oe-interface-adapter';

export class OEModuleUIStateManager extends React.PureComponent {

    constructor(props) {
        super(props);

        this.props.moduleEnv.component.uiStateManager = this;

        this.state = clone(this.props.moduleEnv.ui);

        this.oe = oeInterfaceManager.getInterface(this.props.moduleId);
    }

    setState(state) {
        if(typeof(state) == 'function')  {
            this.setState(state(this.state));
            return;
        }

        if(typeof(state) != 'object')   return;

        this.state = Object.assign(this.state, state);

        this.updateEnvState();
        this.updateCoreState();
    }

    updateState(spec) {
        if(typeof(spec) == 'function' || typeof(spec) != 'object')  return;
        this.setState(update(this.state, spec));
    }

    onConnect()  {
        this.updateCoreState();
    }

    onRelease()    {
    }

    updateEnvState()    {
        this.props.setModuleEnv((prevEnv) => {
            let env = {component: prevEnv.component, strings: prevEnv.strings, ui: clone(this.state)};
            return env;
        });
    }

    scaleState(state, scale)    {
        if(typeof(state) !== 'object')   return;
        Object.keys(state).forEach(key => {
            let type = typeof(state[key]);
            if(type === 'object')   {
                this.scaleState(state[key], scale);
            } else if(type === 'number')   {
                state[key] *= scale;
            }
        });
    }

    updateCoreState()   {
        if(!this.oe.isReady())  return;
        let uiStateInterface = this.oe.sharedInterface.getAppUIState();

        let dpr = this.oe.Module.devicePixelRatio();
        let scaledState = clone(this.state);
        this.scaleState(scaledState, dpr);

        uiStateInterface.setValue(scaledState, '');
    }

    render() {
        return (
            <OEInterfaceAdapter moduleId={this.props.moduleId} receiver={this}/>
        );
    }
}

OEModuleUIStateManager.defaultProps = {
    moduleId: 'oe-embed'
};

OEModuleUIStateManager.propTypes = {
    moduleId: PropTypes.string
};

export default addModuleEnv(OEModuleUIStateManager);