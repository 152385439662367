export const OEProductId = {
    humanFoot:                      '1000100000',
    humanEye:                       '1000200000',
    humanSkull:                     '1000300000',
    humanCranialNerves:             '1000310000',
    humanSkin:                      '1000500000',
    humanKnee:                      '1000600000',
    humanKneeImplantcast:           '1000610000',
    implantcastACSMB4in1:           '1000630000',
    implantcastACSSCFB:             '1000640000',
    implantcastAGILONMI:            '1000650000',
    implantcastACSSCMB:             '1000660000',
    implantcastMutarsDistFemurMK:   '1000620000',
    implantcastMUTARSGenuXMK:       '1000670000',
    implantcastMUTARSKRIMK:         '1200730000',
    implantcastEcoFitHipStem133123: '1000680000',
    implantcastEcoFitHipStem:       '1200690000',
    implantcastEcoFitShortStem:     '1200700000',
    implantcastEcoFitCup:           '1200730000',
    implantcastActiniaStem:         '1200710000',
    implantcastShoulderApproachAnterior: '1200720000',
    implantcastTestTarget:          '1200000001',
    humanEar:                       '1000700000',
    humanLeg:                       '1000800000',
    humanAnkleFoot:                 '1000810000',
    humanHip2:                      '1000820000',
    humanLegPathways:               '1000830000',
    humanLowerLeg:                  '1000840000',
    humanThighKnee:                 '1000850000',
    humanArm:                       '1000900000',
    humanArmPathways:               '1000910000',
    humanBodyMuscles:               '1000010000',
    humanForearm:                   '1000920000',
    humanUpperArmElbow:             '1000930000',
    humanHip:                       '1001000000',
    humanShoulder:                  '1001100000',
    humanElbow:                     '1001200000',
    humanHand:                      '1001300000',
    humanTrunk:                     '1001400000',
    humanAbdominalWall:             '1001410000',
    humanChestWall:                 '1001420000',
    humanNeckBack:                  '1001430000',
    humanPelvis:                    '1001440000',
    humanShoulderGirdle:            '1001450000',
    humanSpine:                     '1001460000',
    humanSpineFracture:             '1001470000',
    humanHead:                      '1001500000',
    humanHeadWIP:                   '1001530000',
    humanLarynx:                    '1001510000',
    humanEye2:                      '1001520000',
    humanEyeRetina:                 '1001540000',
    humanEyeOCT:                    '1001550000',
    humanLiverMicro:                '1002010000',
    humanHeart:                     '1002100000',
    equineHoof:                     '1010100000',
    equineHead:                     '1010800000',
    mouseBrain:                     '1040900000',
    combustionEngine:               '1212000000',
    snail:                          '1055000000',
    toxNetz:                        '1989800000',
    zeissUV:                        '2000000001',
    zeissOpti:                      '2000000002',
    sensorySystems:                 '3000000001',
    optics:                         '4000000001',
    animalCell:                     '1500000000',
    plantCell:                      '1600000000',
    humanCellEpithelium:            '1700000000',
    philipsIcarus:                  '4000000002',
// @ADD_TARGET_ALL_PRODUCTID (see object_explorer/ObjExplorer/Classes/Misc/OEProductId.h)
    humanHeadLeFx                  : '1001531000',
    implantcastAidaShortStem       : '1200800000',
    humanBrainSynapse              : '1000320000',
    implantcastAGILONTraumalongfit : '1200790000',
    implantcastMutarsRS            : '1200780000',
    implantcastDirectAnteriorApproach : '1200770000',
    implantcastEcoFitCupEPORE      : '1200760000',
    implantcastMUTARSPRS           : '1200750000',
    implantcastAGILONOmarthrosisLongFit : '1200740000',
};